import React, { memo, useState, useEffect } from 'react';
import UserProfileComponent from '@pages/common/Layout/components/UserProfileComponent';
import { useLocation } from 'react-router-dom';
import ParallelRunExecutionStatus from '@pages/common/parallelRunExecutionStatus/parallel_run_executionStatus';
import { Icon, Tooltip } from 'fireflink-ui';
import { colors } from '@src/css_config/colorConstants';
import { ReactComponent as FireFlinkAI } from '@assets/ff-svgs/ff-ai.svg';

function Header({ privilegeData }) {
  const location = useLocation();
  const [showParallelRunStatus, setShowParallelRunStatus] = useState(true);
  const [showFireflinkAI, setShowFireflinkAI] = useState(false);

  useEffect(() => {
    const search = window.location.search;
    const hideParentLevRun = new URLSearchParams(search).get('hideParentLevelParallelRun');
    if (hideParentLevRun) {
      setShowParallelRunStatus(false);
    } else {
      setShowParallelRunStatus(true);
    }
  }, [location.search]);

  const isClient = navigator?.userAgent?.includes('fireFlinkClient') || false;
  const enableHelpPage = !isClient && (privilegeData?.additionalKeys?.nlpHelpEnabled || privilegeData?.nlpHelpEnabled);
  const navigateToHelp = () => {
    window.open(process.env.REACT_APP_FIREFLINK_NLP_HELP, '_blank');
  };
  const navigateToFFAI = () => {
    const userData = JSON.parse(localStorage.getItem('test-optimize-user'));
    if (userData?.access_token) {
      const authToken = userData.access_token;
      window.open(`https://ai.fireflink.com/fireflink-ai?data={"token":"${authToken}"}`);
    }
  };
  return (
    <>
      {showFireflinkAI && <FireFlinkAI className="ff-ai-btn" onClick={navigateToFFAI} />}
      {showParallelRunStatus && <ParallelRunExecutionStatus setShowParallelRunStatus={setShowParallelRunStatus} />}
      {enableHelpPage && (
        <Tooltip title="NLP's Help" placement="bottom">
          <Icon
            name="help"
            className="mt-3 ml-4 cursor-pointer"
            color={colors.rs_primary}
            onClick={navigateToHelp}
            height={24}
            width={24}
          />
        </Tooltip>
      )}
      {!isClient && <UserProfileComponent />}
    </>
  );
}

export default memo(Header);
