import { useState, useEffect, useContext, useMemo } from 'react';
import CommonDrawerJs from '@src/common/atoms/CommonDrawer';
import UserDetailsPage from './user_details_page';
import MachineDetailsPage from './machine_details_page';
import CommonTableWithSearch from '../common_table_with_search/common_table_with_search';
import { getSingleClientSystemReq, terminateExecutionSuiteReq, getOnGoingExecutionReq } from '@api/api_services';
import { Tooltip } from '@mui/material';
import backArrowBtn from '@assets/backArrowIcon.svg';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { beforeRunningStatuses } from '@pages/test-development/execution-dashboard/execution-dashboard-util';
import TerminateWarningComponent from '@pages/test-development/execution-dashboard/modals/terminate-warning-component';

const MachineDetailsLandingPage = (props) => {
  const [executionDetails, setExecutionDetails] = useState(props?.executionData);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [isLoading, setIsLoading] = useState(true);
  const [beforeRunningStatusLocal, setBeforeRunningStatusLocal] = useState([...beforeRunningStatuses, 'Running']);
  const [showTerminateModal, setshowTerminateModal] = useState(false);
  const [selectedExecutionObj, setSelectedExecutionObj] = useState();
  const { hasUserViewAccessInExecution } = useMemo(
    () => ({
      hasUserViewAccessInExecution: window.permission?.isOnlyViewAccess('execution'),
    }),
    []
  );

  const [tableColumnArray, setTableColumnArray] = useState([
    {
      Header: <span className="pl-3">EXECUTION ID</span>,
      accessor: '_id',
      width: '20%',
    },
    {
      Header: <span className="pl-3">EXECUTION NAME</span>,
      accessor: 'name',
      width: '20%',
      Cell: (e) => {
        return (
          <div className="flex flex-row">
            <Tooltip title={e?.data[e?.cell?.row?.id]?.name} placement="top">
              <span className="suite_badge_width">{e?.data[e?.cell?.row?.id]?.name}</span>
            </Tooltip>
            {e?.data[e?.cell?.row?.id]?.type?.toLowerCase() === 'suite' && (
              <span class="badge-font-style inline-flex items-center justify-center w-4 h-4 mr-2 ml-2  bg-blue-700 rounded-full">
                <Tooltip title={`Suite : ${e?.data[e?.cell?.row?.id]?.name}`} placement="right">
                  s
                </Tooltip>
              </span>
            )}
          </div>
        );
      },
    },

    {
      Header: <span className="pl-3 ">PROJECT</span>,
      accessor: 'projectName',
      width: '20%',
      Cell: ({ value }) => {
        return (
          <Tooltip title={value} placement="top">
            <div className="suite_badge_width">{value}</div>
          </Tooltip>
        );
      },
    },
    {
      Header: <span className="pl-3 ">{props?.selectedModalType === 'user' ? 'MACHINE NAME' : 'INITIATED BY'}</span>,
      accessor: props?.selectedModalType === 'user' ? 'selectedMachines' : 'createdByUnameWithTooltip',
      width: '25%',
    },
    {
      Header: <span className="pl-3 ">STATUS</span>,
      accessor: 'executionStatus',
      width: '20%',
      Cell: ({ value }) => {
        return (
          <div className="block table_content_text_style ">{value?.toLowerCase() === 'pending' ? 'Queued' : value}</div>
        );
      },
    },
    {
      Header: <span className="pl-3 ">ACTIONS</span>,
      accessor: 'actions',
      width: '20%',
    },
  ]);

  const columns = useMemo(() => tableColumnArray, [tableColumnArray]);

  useEffect(() => {
    getSingleClientSystemReqFunction();
    if (props?.selectedModalType === 'user' && props?.userObject?.userId !== props?.loggedInUser) {
      let tempArray = [...tableColumnArray];
      tempArray.pop();
      setTableColumnArray([...tempArray]);
    }
  }, []);

  function terminateExecution(exeId, name) {
    terminateExecutionSuiteReq(exeId).then((results) => {
      const response = results?.data?.message;
      let tempExeArray = [...executionDetails];
      let tempUSerSpecExeArray = [];
      if (tempExeArray?.length) {
        tempExeArray?.map((obj) => {
          if (obj?._id === exeId) {
            obj.executionStatus = 'Terminated';
            delete obj['actions'];
          }
          if (props?.loggedInUser === obj?.createdBy) {
            tempUSerSpecExeArray?.push(obj);
          }
        });

        if (props?.selectedModalType === 'user') {
          setExecutionDetails([...tempUSerSpecExeArray]);
        } else {
          setExecutionDetails([...tempExeArray]);
        }
      }
    });
  }

  const getSingleClientSystemReqFunction = () => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    setIsLoading(true);
    getOnGoingExecutionReq()
      .then((res) => {
        stopProgress();
        if (res.data.responseObject) {
          let tempExedata = res.data.responseObject;
          let tempExedataArray = [];
          if (props?.selectedModalType === 'user') {
            tempExedata?.map((resObj) => {
              if (resObj?.createdBy === props?.userObject?.userId) {
                let tempArray = resObj?.preExecution?.selectedSystem;
                let tempArraySpanArray = [];
                tempArray?.map((obj) => {
                  tempArraySpanArray.push(
                    <Tooltip title={obj?.machineInfo.hostName} placement="top">
                      <span className="block table_content_text_style suite_badge_width">
                        {obj?.machineInfo.hostName}
                      </span>
                    </Tooltip>
                  );
                });
                resObj['selectedMachines'] = [...tempArraySpanArray];
                if (
                  props?.loggedInUser === resObj?.createdBy &&
                  beforeRunningStatusLocal.includes(resObj?.executionStatus) &&
                  !hasUserViewAccessInExecution
                ) {
                  resObj['actions'] = (
                    <span>
                      <Tooltip title="Terminate" placement="top">
                        <img
                          className="mr-3 show_action cursor"
                          src="\assets\execution_logos\terminate.svg"
                          alt="Terminate Execution"
                          onClick={() => {
                            setshowTerminateModal(true);
                            setSelectedExecutionObj(resObj);
                          }}
                        />
                      </Tooltip>
                    </span>
                  );
                }
                tempExedataArray.push(resObj);
              }
            });
            setExecutionDetails([...tempExedataArray]);
          } else {
            let tempExecutioArray = [];
            tempExedata?.map((resObj) => {
              resObj['createdByUnameWithTooltip'] = (
                <Tooltip title={resObj?.createdByUname} placement="top">
                  <span className="block table_content_text_style suite_badge_width">{resObj?.createdByUname}</span>
                </Tooltip>
              );
              if (resObj?.clients?.length) {
                let tempClientObj = resObj?.clients;
                tempClientObj?.map((clientObj) => {
                  if (clientObj === props?.selectedClientObj?.clientId) {
                    tempExecutioArray?.push(resObj);
                    if (
                      props?.loggedInUser === resObj?.createdBy &&
                      beforeRunningStatusLocal.includes(resObj?.executionStatus) &&
                      !hasUserViewAccessInExecution
                    ) {
                      resObj['actions'] = (
                        <span>
                          <Tooltip title="Terminate" placement="top">
                            <img
                              className="mr-3 show_action cursor"
                              src="\assets\execution_logos\terminate.svg"
                              alt="Terminate Execution"
                              onClick={() => {
                                setshowTerminateModal(true);
                                setSelectedExecutionObj(resObj);
                              }}
                            />
                          </Tooltip>
                        </span>
                      );
                    }
                  }
                });
              }
            });
            setExecutionDetails([...tempExecutioArray]);
          }
        } else {
          setExecutionDetails([]);
        }

        resetProgress();
        setIsLoading(false);
      })
      .catch((error) => {
        resetProgress();
        setIsLoading(false);
      });
  };

  return (
    <>
      <CommonDrawerJs
        headerContent={
          <div className="flex flex-row">
            {' '}
            <img
              src={backArrowBtn}
              onClick={() => {
                props?.setShowDetailsModal(false);
              }}
              alt="Arrow Back"
              width="16px"
              height="16px"
              className="mr-3 cursor"
            />{' '}
            Ongoing executions - {props?.licenseName}
          </div>
        }
        isDrawerOpen={true}
        drawerWidth="850px"
        rightButtonText="Close"
        isLeftButtonVisible={false}
        onDrawerClose={() => {
          props?.setShowParentModal(false);
          props?.setShowDetailsModal(false);
        }}
        onRightButtonClick={() => {
          props?.setShowParentModal(false);
          props?.setShowDetailsModal(false);
        }}
      >
        <div>
          <div class="details_header_box_style">
            <label className="details_header_text_style">
              {props?.selectedModalType === 'user'
                ? `User Details - ${props?.userObject?.userName}`
                : `Machine Details - ${props?.selectedClientObj?.machineInfo?.hostName}`}
            </label>
          </div>
          {props?.selectedModalType === 'user' ? (
            <div className="pl-3">
              <UserDetailsPage userObj={props?.userObject} />
            </div>
          ) : (
            <div className="pl-3">
              <MachineDetailsPage
                selectedClientData={props?.selectedClientObj}
                ownerName={props?.userObject?.userName}
              />
            </div>
          )}

          <CommonTableWithSearch
            columns={columns}
            data={executionDetails || []}
            onSyncBtnClick={getSingleClientSystemReqFunction}
            header="EXECUTION DETAILS"
            message="No Ongoing Executions"
            compSpecificClassName={
              props?.selectedModalType === 'user' ? `exe-table-height-user-details` : `exe-table-height-device-details`
            }
            noDataClassStyle={props?.selectedModalType === 'user' ? ' mt-2 ml-72' : null}
          />
        </div>
      </CommonDrawerJs>
      {showTerminateModal && (
        <TerminateWarningComponent
          executionData={selectedExecutionObj}
          setShowWarningModal={setshowTerminateModal}
          terminateExecution={terminateExecution}
          actionKey="Terminate"
        />
      )}
    </>
  );
};

export default MachineDetailsLandingPage;
