import { Chip, Table } from 'fireflink-ui';
import React, { useEffect, useMemo, useState } from 'react';

const MappedJiraIdTable = ({ data }) => {
  const [tableData, setTableData] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});

  const toggleExpand = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const chunkArray = (array, chunkSize) =>
    array.reduce((chunks, _, i) => (i % chunkSize === 0 ? [...chunks, array.slice(i, i + chunkSize)] : chunks), []);

  const columns = useMemo(
    () => [
      {
        header: 'Jira ID',
        accessor: 'id',
        width: '20%',
      },
      {
        header: 'Type',
        accessor: 'type',
        width: '20%',
      },
      {
        header: 'Linked Issues',
        accessor: 'linkIds',
        width: '60%',
        cell: ({ row }) => {
          const linkIdsArray = Object.keys(row?.linkIds);
          const displayCount = 5;
          const isExpanded = expandedRows[row?.id];
          const initialLinkIds = linkIdsArray.slice(0, displayCount);
          const hiddenLinkIds = linkIdsArray.slice(displayCount);
          const groupedHiddenLinkIds = chunkArray(hiddenLinkIds, 5);

          return (
            <div className="flex">
              <div>
                <div className="flex flex-wrap">
                  {initialLinkIds.map((linkId) => (
                    <span className="mr-1 mb-1 fontPoppinsRegularSm">
                      <Chip key={linkId} variant="default" label={linkId} size="small" withBackground={false} />
                    </span>
                  ))}
                </div>
                <div className={`expandable-content ${isExpanded ? 'expanded' : ''}`}>
                  {groupedHiddenLinkIds.map((group, index) => (
                    <div key={index} className="flex flex-wrap w-max">
                      {group.map((linkId) => (
                        <span className="mr-1 my-1 fontPoppinsRegularSm">
                          <Chip key={linkId} variant="default" label={linkId} size="small" withBackground={false} />
                        </span>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                {hiddenLinkIds.length > 0 && (
                  <div className="fontPoppinsSemiboldMd cursor-pointer w-max">
                    {linkIdsArray?.length > displayCount && (
                      <div onClick={() => toggleExpand(row?.id)} className="ml-2">
                        {!isExpanded && (
                          <span className="text-rs-primary">{`+ ${linkIdsArray.length - displayCount}`}</span>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
    ],
    [expandedRows]
  );

  const processJiraScriptDetails = (mapJiraScriptDetails) => {
    const { automationMapped, manualMapped, automationIssueId, manualIssueId, linkIds } =
      mapJiraScriptDetails || {};

    return [
      automationMapped && { id: automationIssueId, type: 'Automation', linkIds },
      manualMapped && { id: manualIssueId, type: 'Manual', linkIds },
    ].filter(Boolean);
  };

  useEffect(() => {
    const updatedTableData = processJiraScriptDetails(data?.mapJiraScriptDetails);
    setTableData(updatedTableData);
  }, [data?.mapJiraScriptDetails]);

  return (
    tableData && <Table columns={columns} data={tableData} headerType="primary" height="130px" withFixedHeader={true} />
  );
};

export default MappedJiraIdTable;
