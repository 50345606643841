import React, { useState, useEffect, useContext } from 'react';
import { ReactComponent as AddIcon } from '@assets/svg-imports/plus.svg';
import CreateEditGraph from './CreateEditCustomGraph';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { DASHBOARD_CONSTANTS } from '@src/common/ui-constants';
import { getGraphsOnDashboard } from '@src/api/api_services';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { ReactComponent as QuickStart } from '@assets/svg-imports/QuickStartLanding.svg';
import { Button, Icon, Tooltip } from 'fireflink-ui';
import CustomGraph from './CustomGraph';
import { getCurrentProjectStatus } from '@src/util/common_utils';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function CustomGraphTable(props) {
  const { AlertContatiner, MyAlert } = useAlert();
  const [openGraph, setOpenGraph] = useState(false);
  const [showFiltersList, setShowFiltersList] = useState(false);
  const [actionType, setActionType] = useState();
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [isLoading, setIsLoading] = useState(false);
  const [reloadTree, setReloadTree] = useState(true);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');
  const dashboardName = new URLSearchParams(search).get('name');
  const tosterMessage = new URLSearchParams(search).get('message');
  const [isCustomGraphs, setIsCustomGraphs] = useState(false);
  const [metaData, setMetaData] = useState();
  const [hasRefresh, setHasRefresh] = useState(false);
  const projectStatus = getCurrentProjectStatus();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const url = location.search;
    let splitString = url.split('&message');
    let parsedResult = splitString[0];
    if (tosterMessage) {
      MyAlert.success(tosterMessage);
      history.push(`/dashboard/custom-dashboard/${dashboardName}/customiseGraph${parsedResult}`);
    }
  }, []);

  const reloadpage = () => {
    setReloadTree(true);
  };
  const getAllGraphs = async () => {
    setIsFetchingData(true);
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });

    try {
      const response = await getGraphsOnDashboard(id);
      stopProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      if (response?.data?.responseCode === 200) {
        setMetaData(response?.data?.responseObject);
        setIsCustomGraphs(true);
      } else if (response?.data?.responseCode === 404) {
        setIsCustomGraphs(false);
      }
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setIsFetchingData(false);
    }
  };

  const handleListOfGraphs = (data, label) => {
    if (label === 'Add') {
      if (metaData?.length) {
        setMetaData([...metaData, data]);
      } else {
        getAllGraphs();
      }
    } else if (label === 'Delete') {
      if (metaData?.length >= 1) {
        let graphData = metaData;
        let objIndex = graphData.findIndex((temp) => temp['id'] === data);
        graphData.splice(objIndex, 1);
        setMetaData([...graphData]);
      } else {
        getAllGraphs();
      }
    } else {
      let graphData = metaData;
      let objIndex = graphData.findIndex((temp) => temp['id'] === data.id);
      graphData[objIndex] = data;
      setMetaData([...graphData]);
    }
  };
  useEffect(() => {
    if (reloadTree) {
      setIsLoading(true);
      if (id) {
        getAllGraphs();
      }
    }
    setReloadTree(false);
  }, [reloadTree]);
  const toggleFiltersList = () => {
    setShowFiltersList(!showFiltersList);
  };

  const openGraphDrawer = (actionType) => {
    setOpenGraph(true);
    setActionType(actionType);
  };
  const closeGraphDrawer = () => {
    setOpenGraph(false);
  };
  const accessKey = new URLSearchParams(search).get('access');
  return (
    <>
      <div className="page-list-body">
        <div className="alert-position-style">
          <AlertContatiner></AlertContatiner>
        </div>
        <div className="page-table-body mt-2">
          <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
            <label className="rs-text-black flex-auto fontPoppinsSemiboldSm ml-2">
              {DASHBOARD_CONSTANTS.CUSTOMISE_GRAPH}-{dashboardName}
            </label>
            <span className="absolute cursor-pointer mt-1 mr-2 right-44">
              <Tooltip title={DASHBOARD_CONSTANTS.REFRESH}>
                <Icon
                  name={'sync_icon'}
                  hoverEffect={true}
                  height={24}
                  width={24}
                  onClick={() => {
                    setHasRefresh(true);
                    getAllGraphs();
                  }}
                />
              </Tooltip>
            </span>
            {accessKey !== 'View' && projectStatus === 'Open' && (
              <div className=" flex flex-wrap items-center">
                <span className="custom-graph-common custom-graph-Btn">
                  <Button
                    label="Customise Graph"
                    variant="primary"
                    iconName="add_icon"
                    withIcon={true}
                    type="button"
                    onClick={() => openGraphDrawer('Add')}
                  />
                </span>
              </div>
            )}
          </div>
          <div className="content_area_style_Int overflow-scroll">
            <div>
              {!isFetchingData && isCustomGraphs ? (
                <>
                  {metaData?.map((data) => {
                    return (
                      <CustomGraph
                        data={data}
                        handleChange={handleListOfGraphs}
                        MyAlert={MyAlert}
                        reloadTree={reloadpage}
                        accessData={accessKey}
                        hasRefresh={hasRefresh}
                        setHasRefreshFalse={() => {
                          setHasRefresh(false);
                        }}
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  {accessKey !== 'View' && projectStatus === 'Open' && (
                    <div className="fontPoppinsRegularMd no-steps mb-5">
                      <div>
                        <span className=" ml-10 mb-5">
                          <QuickStart className="dashBoard-start mb-2" />
                        </span>
                        <div className="flex flex-row">
                          <div className="rs-text-black fontPoppinsSemiboldSm mt-2">
                            {DASHBOARD_CONSTANTS.STEP_1}
                            <span className="fontPoppinsRegularSm rs-text-black"> {DASHBOARD_CONSTANTS.CLICK}</span>
                          </div>
                          <div
                            className="custom-graph-common QuickStartCustomGraphAddBtn  cursor-pointer ml-4"
                            onClick={() => openGraphDrawer('Add')}
                          >
                            <span>
                              <AddIcon className="custom-Graph-Add-DefectIcon" />
                            </span>
                            <span className="customGraph-title">{DASHBOARD_CONSTANTS.CUSTOMISE_GRAPH}</span>
                          </div>
                          <div className="rs-text-black fontPoppinsRegularSm mt-2 ml-1">
                            {DASHBOARD_CONSTANTS.START_CREATING_GRAPH}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {openGraph && (
        <CreateEditGraph
          isOpen={openGraph}
          onClose={closeGraphDrawer}
          MyAlert={MyAlert}
          actionType={actionType}
          handleChange={handleListOfGraphs}
          reloadTree={reloadpage}
        />
      )}
    </>
  );
}

export default CustomGraphTable;
