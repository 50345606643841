import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { signInRequest, validateAzureToken } from '@api/api_services';
import { createTheme, MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import LabeledInput from '@pages/common/Inputs/LabeledInput';
import LabeledPasswordInput from '@pages/common/Inputs/LabeledPasswordInput';
import { getWebSiteHostName } from '@util/common_utils';
import { alertLicenseExpiredUser } from '@src/util/licenseUtils';
import { serverDownTimeMessage, SIGN_IN_CONSTANTS } from '@src/common/ui-constants';
import cx from 'classnames';
import commonStyles from '@pages/auth/common.module.scss';
import CommonBorder from '../common/common-border';
import CommonPage from '../common/CommonPage';
import Keycloakconfig from './keycloak-config';
import { isEmailValid, getUserStatus } from '@util/common_utils';
import { parseAndDecryptData, getEncryptData } from '@common/security';
import { Icon } from 'fireflink-ui';
import ENVIRONMENT_URL_CONFIG from '@src/common/environment-config';
import { colors } from '@src/css_config/colorConstants';

const theme = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        background: 'transparent',
        padding: 0,
        minWidth: 320,
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  name: {
    '& .MuiInputBase-input': {
      background: 'transparent',
      minWidth: '320px !important',
    },
    ' &.MuiInput-underline': {
      minWidth: '330px !important',
    },
  },
  pass: {
    '& .MuiInputBase-input': {
      background: 'transparent',

      minWidth: '320px !important',
    },
    ' &.MuiInput-underline': {
      minWidth: '330px !important',
    },
  },
}));

export default function LoginPage({ setToken }) {
  const classes = useStyles();
  let history = useHistory();
  const [errorContent, setErrorContent] = React.useState(null);
  const [showError, setShowError] = React.useState(false);
  const [createUpdateCalled, setCreateUpdateCalled] = React.useState(true);
  const [isSignInClicked, setSignInClick] = React.useState(false);
  const [keepBlank, setKeepBlank] = useState(false);
  const isAzureSignInType = process?.env?.REACT_APP_SIGN_IN_TYPE === 'azure';
  const URLPathname = window.location.pathname;
  const search = window.location.search;
  const SignInResponse = new URLSearchParams(search).get('data');
  const isFromWebsite = new URLSearchParams(search).get('from');
  const [accountLocked, setAccountLocked] = useState(false);
  const [waitTime, setWaitTime] = useState(0);

  let parsedSignInResponse;
  try {
    parsedSignInResponse = JSON.parse(SignInResponse);
  } catch (error) {
    console.error(error);
    parsedSignInResponse = SignInResponse;
  }
  const userStatus = getUserStatus();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const dataParam = urlParams.get('data');
    if (dataParam) {
      parseAndDecryptData(dataParam)
        .then((result) => {
          if (result) {
            if (isFromWebsite || userStatus) {
              localStorage.clear();
              localStorage.setItem('test-optimize-user', JSON.stringify(result));
              setToken({ token: 'test123' });
            }
          } else {
            console.error('Failed to decrypt data, result is null');
          }
        })
        .catch((error) => {
          console.error('Error during decryption:', error);
        });
    }
  }, [isFromWebsite]);

  // useEffect(() => {
  //   const data = {
  //     hostname: getHostname(),
  //   };
  //   if (process?.env?.REACT_APP_ENVIRONMENT !== "onprem") {
  //     signInSiteKey(data)
  //       .then((res) => {
  //         setSitekeyValue(res.data.responseObject.sitekey);
  //         localStorage.setItem("siteKeyValue", siteKeyValue);
  //       })
  //       .catch((error) => {
  //         console.error("Sign In SiteKey API Failed!", error);
  //       });
  //   }
  // }, []);

  useEffect(() => {
    if (['/', '/signin'].includes(URLPathname) && process.env.REACT_APP_ENVIRONMENT !== 'onprem') {
      localStorage.clear();
      window.location.replace(getWebSiteHostName() + `/signin`);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      emailId: '',
      password: '',
    },
    validationSchema: Yup.object({
      emailId: Yup.string()
        .test('emailId', 'Enter valid email Id', async (value) => {
          return isEmailValid(value);
        })
        .required('Email is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: (values) => {
      if (createUpdateCalled) {
        setCreateUpdateCalled(false);
        const data = {
          emailId: values.emailId.toLocaleLowerCase(),
          password: getEncryptData(values.password),
          lastSessionData: URLPathname,
        };
        setShowError(false);
        setSignInClick(true);
        signInRequest(data)
          .then((res) => {
            try {
              if (res.data && res.data.responseObject) {
                const response = res.data.responseObject;
                if (
                  response.userStatus !== 'DISABLED' &&
                  response.access_token &&
                  response.licenseStatus !== 'EXPIRED' &&
                  !alertLicenseExpiredUser(response.billingCycle, response.expires_in)
                ) {
                  localStorage.setItem('test-optimize-user', JSON.stringify(response));
                  localStorage.setItem('license-level-timezone', res?.data?.responseMap?.licenseTimeZone);

                  history.push('/projectmenu');
                  setToken({ token: 'test123' });
                } else if (
                  response.access_token &&
                  (response.licenseStatus === 'EXPIRED' || response.userStatus === 'DISABLED')
                ) {
                  localStorage.setItem('test-optimize-user', JSON.stringify(response));
                  history.push('/licenses/fireflink-license');

                  setToken({ token: 'test123' });
                } else if (
                  response.access_token &&
                  response.licenseStatus !== 'EXPIRED' &&
                  alertLicenseExpiredUser(response.billingCycle, response.expires_in)
                ) {
                  localStorage.setItem('test-optimize-user', JSON.stringify(response));

                  history.push('/licenses/fireflink-license');

                  setToken({ token: 'test123' });
                } else if (
                  res.data.responseCode === 400 ||
                  res.data.message === 'Email or Password is invalid, please try again.'
                ) {
                  setShowError(true);
                  setErrorContent(res.data.message);
                  setSignInClick(false);
                } else {
                  const error = response && response.length ? response[0] : {};
                  setShowError(true);
                  setErrorContent(error.message ? error.message : 'Username or Password is invalid. Please try again');
                  setSignInClick(false);
                }
                setAccountLocked(false);
              } else {
                if (res.data.message.includes(SIGN_IN_CONSTANTS.ACCOUNT_LOCKED)) {
                  setAccountLocked(true);
                  setWaitTime(res.data.message?.split(',')[1]);
                  setSignInClick(false);
                } else {
                  setAccountLocked(false);
                  setShowError(true);
                  setErrorContent(res.data.message);
                  setSignInClick(false);
                }
              }
            } catch (e) {
              console.error(e);
            }

            if (res.data && res.data.message === 'User is not activated') {
              history.push('/user-activation?email=' + values.emailId);
            }
          })
          .catch((error) => {
            console.error('Sign In API Failed!', error);
          });
      }
    },
  });

  const check = ({ target }) => {
    if (formik.values.password.length === 0) {
      setShowError(false);
    }
  };

  const doubleError = (e) => {
    if (e.target.value.length === 0) {
      setShowError(false);
    }
  };

  const redirectToSignUp = () => {
    history.push('/signup');
  };
  useEffect(() => {
    if (window.location.hash) {
      let result = window.location.hash
        .substr(1)
        .split('&')
        .reduce(function (res, item) {
          let parts = item.split('=');
          res[parts[0]] = parts[1];
          return res;
        }, {});

      const getToken = async () => {
        setKeepBlank(true);
        const keycloakTokenUrl = process?.env?.REACT_APP_KEYCLOAK_TOKEN_URL;
        const requestBody = new URLSearchParams();
        requestBody.append('grant_type', process?.env?.REACT_APP_KEYCLOAK_GRANT_TYPE);
        requestBody.append('client_id', process?.env?.REACT_APP_KEYCLOAK_CLIENT_ID);
        requestBody.append('code', result?.code);
        requestBody.append('client_secret', process?.env?.REACT_APP_KEYCLOAK_CLIENT_SECRET);
        requestBody.append('redirect_uri', process?.env?.REACT_APP_KEYCLOAK_REDIRECT_URI);

        // Add other required parameters as needed
        try {
          const response = await fetch(keycloakTokenUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: requestBody,
          });
          if (!response.ok) {
            throw new Error('Failed to get access token');
          }
          const data = await response.json();
          const formData = new FormData();
          formData.append('data', JSON.stringify(data));
          formData.append('clientMetaData', null);

          validateAzureToken(formData)
            .then((res) => {
              try {
                if (res?.data?.responseObject && res?.data?.responseObject?.licenseId) {
                  localStorage.setItem('test-optimize-user', JSON.stringify(res?.data?.responseObject));

                  history.push('/projectmenu');
                  setToken({ token: 'test123' });
                } else {
                  history.push('/signup');
                }
              } catch (e) {
                console.error(e);
              }
            })
            .catch((error) => {
              console.error('SignIn with azure API Failed!', error);
            });
        } catch (error) {
          console.error('Error fetching access token:', error);
          // Handle the error
        }
      };

      if (result?.code) {
        getToken();
      }
    }
  }, [window.location.hash]);

  // TODO :: until we get the response from BE when we signin through azure, as of now we are keeping the page as blank
  if (keepBlank) {
    return <></>;
  }
  // TODO :: azure signin page :: START

  if (isAzureSignInType) {
    return (
      <CommonPage wrapperClassname={`azure-signin-padding`}>
        <div className={cx('flex justify-center', commonStyles['content-header'])}>{SIGN_IN_CONSTANTS.SIGN_IN}</div>
        <CommonBorder />
        <div className="mt-16 mb-16">
          <button className={cx('fontPoppinsSemiboldLg', commonStyles['azure-signin-btn'])} onClick={Keycloakconfig}>
            Sign In with Azure
          </button>
          <div className={cx(commonStyles['divide-or'])}>
            <span className="fontPoppinsSemiboldXlg">or</span>
          </div>
          <button className={cx('fontPoppinsSemiboldLg', commonStyles['azure-signin-btn'])} onClick={redirectToSignUp}>
            Create An Account
          </button>
        </div>
      </CommonPage>
    );
  }

  // TODO :: azure signin page :: END

  return accountLocked ? (
    <CommonPage wrapperClassname={'common-padding-account-locked'} containerStyle={'blocked-content-container'}>
      <div className={cx('flex justify-center"', commonStyles['content-header'])}>
        <div className={cx(commonStyles['lock-icon'])}>
          <Icon color={colors.icon_orange} height={24} name="padlock" width={24} />
        </div>
        {SIGN_IN_CONSTANTS.ACCOUNT_LOCKED}
      </div>
      <CommonBorder />
      <div className={`${cx(commonStyles['account-blocked-error'])}`}>
        <div>
          <p>Your account has been temporarily locked due to multiple failed login attempts.</p>
          <p className={cx(commonStyles['highlight'])}>
            Please try again in <strong>{waitTime}</strong>. If you need immediate assistance, please click the{' '}
            <strong>"Contact Us"</strong> button.
          </p>
          <p>Thank you for your understanding and cooperation.</p>
        </div>
        <button
          className={cx(
            'fontPoppinsSemiboldLg',
            commonStyles['common-btn'],
            commonStyles['btn-bg-orange'],
            commonStyles['contact-us-btn']
          )}
          onClick={(e) => {
            const environment = process.env.REACT_APP_ENVIRONMENT;
            window.location.href = `${
              environment !== 'onprem' ? getWebSiteHostName() : serverDownTimeMessage.CONTACT_US
            }`;
          }}
        >
          {SIGN_IN_CONSTANTS.CONTACT_US}
        </button>
      </div>
    </CommonPage>
  ) : (
    <CommonPage wrapperClassname={`common-padding`}>
      <div className={cx('flex justify-center"', commonStyles['content-header'])}>{SIGN_IN_CONSTANTS.SIGN_IN}</div>
      <CommonBorder />
      <form onSubmit={formik.handleSubmit} className="w-full pt-4">
        <div className="">
          <div className="pb-4 h-20">
            <LabeledInput
              label="Email"
              className={cx(commonStyles['common-input-label'])}
              required
              placeholder="Enter your email"
              error={
                (formik.errors.emailId && formik.touched.emailId) || (formik.touched.password && !formik.values.emailId)
              }
              value={formik.values.emailId}
              onBlur={(e) => {
                formik.handleBlur(e);
                setCreateUpdateCalled(true);
              }}
              onChange={formik.handleChange}
              autoComplete="off"
              onFocus={() => setShowError(false)}
              name="emailId"
              autoFocus
              helperText={
                <>
                  {(formik.touched.emailId && formik.errors.emailId) ||
                    (formik.touched.password && !formik.values.emailId)}
                </>
              }
            />
          </div>
          <div className="pb-4 h-24">
            <MuiThemeProvider theme={theme}>
              <LabeledPasswordInput
                className={cx(commonStyles['common-input-label'], classes.pass)}
                label="Password"
                required
                placeholder="Enter your password"
                error={formik.errors.password && formik.touched.password}
                value={formik.values.password}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  setCreateUpdateCalled(true);
                }}
                onChange={(e) => {
                  formik.handleChange(e);
                  doubleError(e);
                }}
                onFocus={() => setShowError(false)}
                name="password"
                onKeyUp={(e) => check(e)}
                helperText={<>{formik.touched.password && formik.errors.password}</>}
              />
            </MuiThemeProvider>
            <div className="flex justify-between ">
              <p> {showError && <span className="fontPoppinsRegularXs8px fixed redText">{errorContent}</span>}</p>
              <span className={formik.errors.password && formik.touched.password ? '-mt-5' : '-mt-2'}>
                <Link to={`/forget-password`}>
                  <span className={cx('fontPoppinsSemiboldSm', 'text-rs-primary')}>
                    {SIGN_IN_CONSTANTS.FORGOT_PASSWORD}
                  </span>
                </Link>
              </span>
            </div>
          </div>

          <div className="flex justify-center flex-col items-center gap-4">
            <button
              type="submit"
              className={cx('fontPoppinsSemiboldLg', commonStyles['common-btn'], commonStyles['btn-bg-orange'])}
            >
              {isSignInClicked ? SIGN_IN_CONSTANTS.SIGNING_IN : SIGN_IN_CONSTANTS.SIGN_IN}
            </button>

            <div className="fontPoppinsSemiboldSm ">
              {SIGN_IN_CONSTANTS.DO_YOU_HAVE_ACCOUNT}
              <Link to="/signup" className={cx('fontPoppinsSemiboldSm', 'text-rs-primary')}>
                &nbsp; {SIGN_IN_CONSTANTS.CREATE_ACCOUNT}
              </Link>
            </div>
          </div>
        </div>
      </form>
    </CommonPage>
  );
}
