import React, { useCallback, useEffect, useState } from 'react';
import './step_result_modal.scss';
import { HiBan, HiOutlineDownload } from 'react-icons/hi';
import { MdCheck, MdClose } from 'react-icons/md';
import MatModal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { styled } from '@mui/material/styles';
import { Tooltip } from '@material-ui/core';
import { tooltipClasses } from '@mui/material/Tooltip';
import {
  downloadFileFromCloud,
  downloadScreenshotVideoReq,
  getModuleResultTreeReq,
  getScriptReq,
  getStepGroupReq,
} from '@api/api_services';
import MyTable from '../../../licence_management/shared/Table';
import OnShowEditor from '../../../repository/components/program_elements/code_Editor/Js/Ace_Editor';
import MyResultTable from './MyResultTable';
import { FiInfo } from 'react-icons/fi';
import { useAlert } from '@src/pages/common/alert_service/useAlert';
import { getIDB, setIDB } from '@src/util/localForage_idb_controller';
import RequestBodyNone from '@src/assets/running_with_errors_black_24dp.svg';
import { makeStyles } from '@material-ui/core/styles';
import GenerateCurl from '@src/pages/common/generate_curl/generate_curl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Alert from '@mui/material/Alert';
import { encodeSpecialCharater } from '@src/pages/test-development/shared/common-methods';
import { isEmptyValue, isEmptyObject } from '@src/util/common_utils';
import { WEBSERVICE } from '@src/pages/test-development/script/scripts/webservice/restapi/constants/constants';
import { Icon } from 'fireflink-ui';
import { STEP_RESULTS } from '@src/common/ui-constants';
import { getExceptionSuggestionForFailedStep } from '@src/pages/test-development/shared/common-methods';
import { EXCEPTION_SUGGESTION, GO_TO_STEP } from '@src/common/ui-constants';
import ExceptionSuggestion from '@src/pages/results/components/ExceptionSuggestion/ExceptionSuggestion';
const ErrorTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
  },
});

const useStyles = makeStyles({
  root: {
    width: '275px',
    height: '35px',
    top: 6,
    marginLeft: '420px',
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 'auto',
    position: 'absolute',
    fontSize: '12px',
    overflow: 'hidden',
  },
});
const StepModal = (props) => {
  let [urlData, setUrlData] = useState({
    moduleName: null,
    scriptName: null,
  });
  const [showModal, setShowModal] = useState(true);
  let [selectedTab, setSelectedTab] = useState('Elements');
  let [selectedWebServiceSubTab, setSelectedWebServiceSubTab] = useState('Pre Requisite');
  let [selectedOutputSubTab, setSelectedOutputSubTab] = useState('Response Body');
  let [assertResultTab, setAssertResultTab] = useState('All');
  let message, page, pageCheck;
  const [isDownloading, setIsDownloading] = useState(false);
  const screenShotsDeleted = JSON.parse(localStorage.getItem('screenShotsDeleted'));
  const videosDeleted = JSON.parse(localStorage.getItem('videosDeleted'));
  if (props.stepResult && props.stepResult.message) {
    message = props.stepResult.message.split(' ');
    page = message.length - 3;
    pageCheck = page + 1;
  }
  const [scriptDetails, setScriptDetails] = useState({});
  const [screenType, SetscreenType] = useState('viewOnly');
  const { AlertContatiner, MyAlert } = useAlert();
  let stepResultData = {
    variablesCount: 0,
    totalElements: 0,
    passedElements: 0,
    passed: 0,
  };
  const nlpName = props?.stepResult?.nlpName?.toLowerCase();
  const containsContinueOrBreak = nlpName?.includes('continue') || nlpName?.includes('break');
  const [curl, setCurl] = useState(null);
  const [isCurlCopy, setCurlCopy] = useState(false);
  const classes = useStyles();
  const [webserviceStepId, setWebserviceStepId] = useState('');
  const [curlError, setCurlError] = useState('');
  const [resourceExists, setResourceExists] = useState(false);
  const [navigationState, setNavigationState] = useState({
    navigateToStepData: null,
    stepGroupResponse: null,
    showStepGroupLink: false,
    stepGroupToNavigate: null,
    navigateToScriptData: null,
  });
  const [exceptionSuggestion, setExceptionSuggestion] = useState(null);
  const { STEP_RESULT, SUGGESTIONS_TO_FIX, SUGGESTIONS } = EXCEPTION_SUGGESTION;
  const { FOR_LOOP_DISPLAY_VALUE, FOR_LOOP_TITLE, EXPORT_CURL_TEXT } = WEBSERVICE;
  // for Error message view
  function ReadMore({ children, maxCharacterCount = 300 }) {
    const text = children;
    const [isTruncated, setIsTruncated] = useState(true);
    const resultString = text ? (isTruncated ? text.slice(0, 350) : text) : 'None';

    function toggleIsTruncated() {
      setIsTruncated(!isTruncated);
    }
    return (
      <div>
        <p className="has-text-left">
          {resultString}
          {resultString.length > 100 ? (
            <button onClick={toggleIsTruncated} className="btn btn-light text-primary btn-sm float-right">
              {text ? (isTruncated ? '...view more' : 'view less') : ''}
            </button>
          ) : (
            ''
          )}
        </p>
      </div>
    );
  }

  function ReadMoreDemo(props) {
    return (
      <div className="text-justify">
        <ReadMore>{props.errorLog?.cause}</ReadMore>
      </div>
    );
  }

  useEffect(() => {
    validateStep();
  }, []);

  useEffect(() => {
    let treeData;
    const getTreeData = async () => {
      treeData = await getIDB('tree-data');
    };
    getTreeData();
    if (!treeData && props.executionId) {
      getModuleResultTree(props.executionId);
    }
    if (props?.stepResult?.nlpReportPath) {
      setSelectedTab('Report');
    }
    if (props?.stepResult?.isWebServiceRequest) {
      setSelectedTab('Input');
    }
    let tempScriptData;
    const getScriptData = async () => {
      tempScriptData = await getIDB('script-data');
      setScriptDetails(tempScriptData?.[0]);
    };
    getScriptData();
  }, []);

  const textColor = (status) => {
    switch (status) {
      case 'PASS':
      case 'USED':
        return 'result-passed';
      case 'FAIL':
      case 'NOT FOUND':
        return 'result-failed';
      case 'WARNING':
        return 'result-warning';
      case 'SKIP':
      case 'NOT USED':
        return 'result-skipped';
    }
  };

  const elementStatsColor = (status) => {
    switch (status) {
      case 'PASS':
        return 'passed-elements';
      case 'FAIL':
        return 'failed-elements';
      case 'WARNING':
        return 'warned-elements';
      case 'SKIP':
        return 'skipped-elements';
      case 'SKIPPED':
        return 'skipped-elements';
      case 'FAILURE':
        return 'failed-elements';
      case 'SUCCESS':
        return 'passed-elements';
    }
  };

  const stepInputType = (type) => {
    switch (type) {
      case 'WEBSERVICE':
        return 'Webservice';
      case 'LOCAL':
        return 'Local Variable';
      case 'GLOBAL':
        return 'Global Variable';
      case 'PROJECTENVIRONMENT':
      case 'PROJECT_ENVIRONMENT':
        return 'Projectenvironment Variable';
      case 'STEPGROUP':
      case 'STEPGROUPINPUT':
        return 'StepGroup Variable';
      default:
        return 'Global Variable';
    }
  };

  let statusIcon = (elementStatus) => {
    return (
      <span>
        {elementStatus === 'USED' ? (
          <span className="result-passed" title="Found">
            <MdCheck className="mt-2.5" />
          </span>
        ) : elementStatus === 'NOT_USED' ? (
          <span className="result-skipped" title="Not Used">
            <HiBan className="mt-2.5" />
          </span>
        ) : (
          <span className="result-failed" title="Not Found">
            <MdClose className="mt-2.5" />
          </span>
        )}
      </span>
    );
  };

  const getIdentifierValue = (isRecorded) => {
    if (isRecorded === 'Y') {
      return 'Recorded';
    } else if (isRecorded === 'N') {
      return 'Manual';
    } else if (isRecorded === undefined) {
      return 'Manual';
    }
    return isRecorded;
  };
  const ecolumns = React.useMemo(
    () => [
      {
        Header: 'Locator Type',
        accessor: 'name',
        width: '20%',
      },
      {
        Header: 'Value Type',
        accessor: 'type',
        width: '20%',
      },
      {
        Header: 'Locator Value',
        accessor: 'locator',
        width: '20%',
      },
      {
        Header: 'Captured',
        accessor: 'identifier',
        width: '20%',
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '20%',
      },
    ],
    []
  );
  const reportTab = () => {
    return (
      <div className="section-style m-6" id="journal-scroll">
        <span
          className="mt-2 text-primary flex cursor-pointer"
          title="Download Report"
          onClick={() => downloadFile(props?.stepResult?.nlpReportPath, 'nlpReport')}
        >
          <small> Download Report</small>
          {isDownloading ? (
            <img src="\assets\Spin-1s-22px.svg" height="16px" width="16px" className="ml-2" alt="" />
          ) : (
            <HiOutlineDownload className="download-icon ml-2" />
          )}
        </span>
      </div>
    );
  };
  function elementsTab(elementDetails) {
    return (
      <div className="section-style m-6" id="journal-scroll">
        {elementDetails && elementDetails.length ? (
          elementDetails.map((element, index) => (
            <>
              <div>
                <div
                  className={`mb-3 mt-2 ml-4 unselected-tab ${elementStatus(
                    element?.locators,
                    props?.stepResult?.status
                  )}`}
                  onClick={() => {
                    displayElement(`element${index}`);
                  }}
                  id={`elementData${index}`}
                >
                  {index + 1}.{element?.name} {message[pageCheck] == 'page' ? ` in ${message[page]} page` : ''}{' '}
                  {props?.stepResult?.status !== 'SKIP' && elementStatusText(element?.locators)}{' '}
                  {iconForAccordion(`element${index}`)}
                </div>
                <div className="userRoleMapping" id="journal-scroll">
                  <MyResultTable
                    data={element?.locators.map((locator, index) => ({
                      name: <span className="text-gray-700 fontPoppinsRegularMd">{locator?.name}</span>,
                      type: <span className="text-gray-700 fontPoppinsRegularMd"> {locator?.type}</span>,
                      locator: (
                        <span className="text-gray-700 fontPoppinsRegularMd table-Value-truncate ">
                          <Tooltip
                            title={getLocatorValue(locator, element?.dynamicLocatorArray)}
                            placement="bottom-start"
                          >
                            <div className="truncate">{getLocatorValue(locator, element?.dynamicLocatorArray)}</div>
                          </Tooltip>
                        </span>
                      ),
                      identifier: (
                        <span className="text-gray-700 fontPoppinsRegularMd">
                          {' '}
                          {getIdentifierValue(locator?.isRecorded)}
                        </span>
                      ),
                      status: (
                        <span className="text-gray-700 fontPoppinsRegularMd"> {statusIcon(locator?.status)}</span>
                      ),
                    }))}
                    columns={ecolumns}
                  />
                </div>
              </div>
            </>
          ))
        ) : (
          <div className="column-value not-used-data">No elements are used in this step.</div>
        )}
      </div>
    );
  }

  function elementStatus(locators, status) {
    if (status !== 'SKIP') {
      let locatorStatus = locators.find((loc) => loc?.status === 'USED');
      if (locatorStatus) {
        return 'result-passed';
      } else {
        return 'result-failed';
      }
    } else {
      return 'result-skipped';
    }
  }

  function elementStatusText(locators) {
    let locatorStatus = locators.find((loc) => loc?.status === 'USED');
    if (locatorStatus) {
      return ' is found';
    } else {
      return ' is not found';
    }
  }

  function getLocatorValue(locator, dynamicLocators) {
    let locatorObj = {};
    locatorObj['locatorAdded'] = locator['value'];
    let updatedLocatorValue = locator['value'];
    let locatorWithVariableValue = locator['value'];
    if (dynamicLocators && dynamicLocators.length > 0 && locator['value'].includes('{')) {
      dynamicLocators.map((locatorData) => {
        let dynamicKey = '{' + locatorData['name'] + '}';
        if (locatorData['name'] && locator['value'].includes(dynamicKey)) {
          if (locatorData['reference']) {
            updatedLocatorValue = updatedLocatorValue.replace(dynamicKey, '/$' + locatorData['value']);
            locatorWithVariableValue = locatorWithVariableValue.replace(dynamicKey, '/' + locatorData['actualValue']);
          } else {
            updatedLocatorValue = updatedLocatorValue.replace(dynamicKey, '/' + locatorData['actualValue']);
            locatorWithVariableValue = locatorWithVariableValue.replace(dynamicKey, '/' + locatorData['actualValue']);
          }
        }
      });
      locatorObj['locatorUsingVariable'] =
        updatedLocatorValue.indexOf('/') === 0 ? updatedLocatorValue.substring(1) : updatedLocatorValue;
      locatorObj['locatorWithActualValue'] =
        locatorWithVariableValue.indexOf('/') === 0 ? locatorWithVariableValue.substring(1) : locatorWithVariableValue;
    }
    return (
      <span>
        <div>{locatorObj?.locatorAdded}</div>
        <div className={textColor(locator?.status)}>{locatorObj?.locatorUsingVariable}</div>
        <div className={textColor(locator?.status)}>{locatorObj?.locatorWithActualValue}</div>
      </span>
    );
  }

  function displayElement(elementId) {
    let elementExpand = document.getElementById(elementId + '-expand');
    let elementCollapse = document.getElementById(elementId + '-collapse');
    let element = document.getElementById(elementId);
    elementStyle(elementExpand, 'inline-block');
    elementStyle(elementCollapse, 'inline-block');
    elementStyle(element, 'block');
  }

  function elementStyle(element, displayStyle) {
    if (element && element.style) {
      if (element.style.display === 'none' || element.style.display == '') {
        element.style.display = displayStyle;
      } else if (element.style.display !== 'none') {
        element.style.display = 'none';
      }
    }
  }

  const vcolumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: '20%',
      },
      {
        Header: 'Type',
        accessor: 'type',
        width: '20%',
      },
      {
        Header: 'Value',
        accessor: 'value',
        width: '20%',
      },
    ],
    []
  );
  let getVariableTblData = (stepInputs, elementDetails, returnObj) => {
    let variables = [];
    if (elementDetails) {
      elementDetails.forEach((element) => {
        element.locators?.forEach((value) => {
          value.dynamicLocatorArray?.forEach((vari) => {
            variables.push(vari);
          });
        });
      });

      if (stepInputs?.length) {
        stepInputs.forEach((vari) => {
          variables.unshift(vari);
        });
      }
    } else if (stepInputs?.length) {
      stepInputs.forEach((vari) => {
        variables.unshift(vari);
      });
    }
    if (returnObj) {
      let tempObj = {
        reference: returnObj?.type,
        value: returnObj?.name,
        actualValue: returnObj?.value,
      };
      variables.push(tempObj);
    }

    let tempVar = variables
      .filter((input) =>
        ['LOCAL', 'GLOBAL', 'PROJECTENVIRONMENT', 'PROJECT_ENVIRONMENT', 'STEPGROUP', 'STEPGROUPINPUT'].includes(
          input?.reference === 'STEPGROUPINPUT' ? input?.inputReference : input?.reference
        )
      )
      .filter(
        (v) =>
          !v.value.includes('WSWB') ||
          !v.value.includes('VAR') ||
          !(!v.inputReference && v.reference === 'STEPGROUPINPUT')
      );
    return tempVar;
  };

  function variablesTab(stepInputs, elementDetails, returnObj) {
    let varData = getVariableTblData(stepInputs, elementDetails, returnObj)?.map((input, index) =>
      input?.reference === 'STEPGROUPINPUT'
        ? input?.inputName || input?.inputReference
          ? {
              name: <span className="text-gray-700 fontPoppinsRegularMd">{input.inputName || input?.value}</span>,
              type: (
                <span className="text-gray-700 fontPoppinsRegularMd">
                  {' '}
                  {stepInputType(input.inputReference || input?.reference)}
                </span>
              ),
              value: (
                <span className="text-gray-700 fontPoppinsRegularMd table-Value-truncate">
                  <Tooltip title={input?.actualValue} placement="bottom-start">
                    <div className="truncate">{input?.actualValue}</div>
                  </Tooltip>
                </span>
              ),
            }
          : {}
        : {
            name: <span className="text-gray-700 fontPoppinsRegularMd">{input.inputName || input?.value}</span>,
            type: (
              <span className="text-gray-700 fontPoppinsRegularMd">
                {' '}
                {stepInputType(input.inputReference || input?.reference)}
              </span>
            ),
            value: (
              <span className="text-gray-700 fontPoppinsRegularMd table-Value-truncate">
                <Tooltip title={input?.actualValue} placement="bottom-start">
                  <div className="truncate">{input?.actualValue}</div>
                </Tooltip>
              </span>
            ),
          }
    );

    return (
      <div className="section-style m-6">
        {varData?.length ? (
          <MyResultTable data={varData} columns={vcolumns} />
        ) : (
          <div className="column-value not-used-data">No variables are used in this step.</div>
        )}
      </div>
    );
  }

  useEffect(() => {
    handleOnClickSuggestionsTab(props?.stepResult?.errorLog?.name, props?.stepResult?.platform);
  }, []);

  const handleOnClickSuggestionsTab = async (exceptionName, platform) => {
    if (!exceptionSuggestion && platform) {
      setExceptionSuggestion(await getExceptionSuggestionForFailedStep(exceptionName, platform));
    }
  };

  function logs(stepLogs) {
    return (
      <div className="section-style">
        {stepLogs && stepLogs !== undefined ? (
          <div className="mx-4 mt-4">
            <div className="mt-2">
              <div className="step-columns"> Exception </div>
              <ErrorTooltip title={stepLogs?.cause}>
                <div className="column-value mt-2 line-clamp-5"> {stepLogs?.cause} </div>
              </ErrorTooltip>
            </div>
          </div>
        ) : (
          <div className="column-value not-used-data">
            {props?.stepResult?.status === 'SKIP' ? (
              <div>No logs are recorded for skipped steps.</div>
            ) : (
              <div>No logs are recorded for this step.</div>
            )}
          </div>
        )}
      </div>
    );
  }

  function ifCheckPointIsFailed(stepInputs) {
    if (stepInputs) {
      let value = '';
      let ifFailedCheckpoint = stepInputs.find((input) => input?.name === 'ifCheckPointIsFailed');
      if (ifFailedCheckpoint && ifFailedCheckpoint['value']) {
        value = ifFailedCheckpoint.value;
        const replacedStringValue = value.replaceAll('_', ' ');
        return replacedStringValue.charAt(0).toUpperCase() + replacedStringValue.slice(1).toLowerCase();
      }
    }
  }

  function stepReturnInfo(stepReturnData) {
    if (stepReturnData) {
      return (
        <span>
          {stepInputType(stepReturnData?.type)} : {stepReturnData?.name} : {stepReturnData?.value}
        </span>
      );
    } else {
      return <span>none</span>;
    }
  }

  function getModuleResultTree() {
    if (props.executionId) {
      getModuleResultTreeReq(props.executionId)
        .then((response) => {
          if (response.data.responseObject) {
            getTreeData(response.data);
          }
        })
        .catch((error) => {
          console.error('error', error);
        });
    }
  }
  const getTreeData = (response) => {
    let returned_data = response?.responseObject?.executionResponses[0]?.children;

    if (response.responseObject.executionResponses[0].executionId) {
      returned_data[0]['executionId'] = response?.responseObject?.executionResponses[0]?.executionId;
    }
    if (response.responseObject.executionResponses[0].storageDetails) {
      returned_data[0]['storageDetails'] = response?.responseObject?.executionResponses[0]?.storageDetails;
    }
    setIDB('tree-data', returned_data[0]);
    localStorage.setItem('moduleId', response?.responseObject?.executionResponses[0]?.children[0]?.id);
  };

  async function downloadFile(path, type) {
    setIsDownloading(true);
    const scriptData = await getIDB('script-data');
    const treeData = await getIDB('tree-data');
    if (treeData) {
      let reqBody = {};
      if (treeData && treeData.storageDetails) {
        reqBody = {
          bucketName: treeData?.storageDetails
            ? treeData?.storageDetails?.inputs?.bucketName
            : treeData[0].storageDetails.inputs.bucketName,
          filePath: [path],
          type: treeData?.storageDetails ? treeData?.storageDetails?.type : treeData[0]?.storageDetails?.type,
        };
      } else {
        if (scriptData && scriptData.length && treeData?.length) {
          let obj = treeData?.find((o) => o.title === scriptData[0].selectedSystem.machineInfo.hostName);
          reqBody = {
            bucketName: obj?.storageDetails
              ? obj?.storageDetails?.inputs?.bucketName
              : obj[0].storageDetails.inputs.bucketName,
            filePath: [path],
            type: obj?.storageDetails ? obj?.storageDetails?.type : obj[0]?.storageDetails?.type,
          };
        }
      }
      const filename = path.split('/').pop();
      reqBody['contentType'] = type;
      downloadScreenshotVideoReq(reqBody)
        .then((response) => {
          if (response.data && response.data.size > 0) {
            if (type === 'video') {
              saveFileFromBlob(response.data, 'video.zip');
            } else if (type === 'nlpReport') {
              saveFileFromBlob(response.data, filename);
            } else {
              saveFileFromBlob(response.data, 'screenshot.jpg');
            }
          } else MyAlert.danger(`The ${type} cannot be downloaded. It has been deleted from the storage system`);
          setIsDownloading(false);
        })
        .catch((error) => {
          MyAlert.danger(`The ${type} cannot be downloaded. It has been deleted from the storage system`);
          console.error('screenshot download error:', error);
          setIsDownloading(false);
        });
    }
  }

  function saveFileFromBlob(blob, filename) {
    if (blob?.size === 22) {
      MyAlert.danger(`The Video cannot be downloaded. It has been deleted from the storage system`);
      return;
    }
    if (blob) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }, 0);
      }
    }
  }

  function checkIfVariablesUsed(stepInputs, elementDetails, returnObj) {
    let VarDet = getVariableTblData(stepInputs, elementDetails, returnObj);
    stepResultData.variablesCount = VarDet?.length;
    return (
      <div className="h-5 w-8 variables-count ml-1 rounded-3xl unselected-tab">
        <div className="text-center selected-tab">{VarDet?.length}</div>
      </div>
    );
  }

  function returnVariableCountTooltipMessage(stepInputs, elementDetails, returnObj) {
    let varData = getVariableTblData(stepInputs, elementDetails, returnObj);
    return <div>Variables - {varData?.length}</div>;
  }

  function elementsStatistic(elementDetails, status) {
    if (elementDetails?.length) {
      stepResultData.totalElements = elementDetails.length;
      stepResultData.passedElements = stepResultData.passedElements + 1;
    }

    return (
      <div className={`h-5 w-8 ml-1 rounded-3xl unselected-tab ${elementStatsColor(status)}`}>
        <div className="text-center elements-stat">
          {stepResultData?.passedElements}/{stepResultData?.totalElements}
        </div>
      </div>
    );
  }

  function elementsStatsCountTooltipMessage(elementDetails, status) {
    if (elementDetails?.length) {
      stepResultData.passed = stepResultData.passed + 1;
    }
    return `Elements - ${stepResultData?.passed}`;
  }

  let iconForAccordion = (elementId) => {
    return (
      <span>
        {/* <RiArrowRightSFill className="expand" id={`${elementId}-expand`}/>
                <RiArrowDownSFill className="collapse" id={`${elementId}-collapse`}/> */}
      </span>
    );
  };

  function inputTab(stepInputs) {
    return (
      <>
        <div className="grid grid-cols-5 mt-4 ml-4 w-3/4">
          <div
            className={`flex cursor-pointer ${
              selectedWebServiceSubTab === 'Pre Requisite' ? 'highlight-border w-24 selected-tab' : 'unselected-tab'
            }`}
            onClick={() => setSelectedWebServiceSubTab('Pre Requisite')}
          >
            <div className="info-tab mb-1 w-24"> Pre Requisite </div>
          </div>
          <div
            className={`flex info-tab cursor-pointer mrleft ${
              selectedWebServiceSubTab === 'HTTP Request' ? 'highlight-border w-46 selected-tab' : 'unselected-tab'
            }`}
            onClick={() => setSelectedWebServiceSubTab('HTTP Request')}
          >
            <div className="mb-1 w-46">HTTP Request - Actual URI</div>
          </div>
          <div
            className={`flex info-tab cursor-pointer ${
              selectedWebServiceSubTab === 'Request Header' ? 'highlight-border w-3/4 selected-tab' : 'unselected-tab'
            }`}
            onClick={() => setSelectedWebServiceSubTab('Request Header')}
          >
            <div className="mb-1 ml-3 w-full"> Request Header </div>
          </div>
          <div
            className={`flex info-tab cursor-pointer ${
              selectedWebServiceSubTab === 'Request Body' ? 'highlight-border w-2/3 selected-tab' : 'unselected-tab'
            }`}
            onClick={() => setSelectedWebServiceSubTab('Request Body')}
          >
            <div className="mb-1 w-full"> Request Body </div>
          </div>
          <div
            className={`flex info-tab cursor-pointer ${
              selectedWebServiceSubTab === 'cURL' ? 'highlight-border w-2/3 selected-tab' : 'unselected-tab'
            }`}
            onClick={() => setSelectedWebServiceSubTab('cURL')}
          >
            <div className="mb-1 w-full"> {EXPORT_CURL_TEXT} </div>
          </div>
        </div>
        <div className="tab-border mx-4"></div>
        <div>
          {selectedWebServiceSubTab === 'Pre Requisite' && (
            <div className="px-15px">{preRequisiteTab(props?.stepResult?.elementDetails)}</div>
          )}
          {selectedWebServiceSubTab === 'HTTP Request' && (
            <div className="px-15px">{httpRequestTab(props?.stepResult?.errorLog)}</div>
          )}
          {selectedWebServiceSubTab === 'Request Header' && (
            <div className="px-15px">{requestHeaderTab(props?.stepResult?.errorLog)}</div>
          )}
          {selectedWebServiceSubTab === 'Request Body' && (
            <section className="px-15px">{requestBodyTab(props?.stepResult?.stepInputs)}</section>
          )}
          {selectedWebServiceSubTab === 'cURL' && <section className="px-15px">{cURLTab()}</section>}
        </div>
      </>
    );
  }
  function preRequisiteTab(stepInputs) {
    return (
      <>
        <div className="mt-2">
          <OnShowEditor
            codeviewValue={getUserVariableName(
              props?.stepResult?.webServiceRequest?.preStep?.program?.codeSnippet?.code
            )}
            screenType={screenType}
          />
        </div>
      </>
    );
  }

  function responseBody(stepInputs) {
    let formattedCode = props?.stepResult?.webserviceResponse?.responseBody;
    if (/^\s*{/s.test(props?.stepResult?.webserviceResponse?.responseBody)) {
      let dataJson;
      try {
        dataJson = JSON?.parse(props?.stepResult?.webserviceResponse?.responseBody);
        formattedCode = JSON?.stringify(dataJson, null, 2);
      } catch (e) {
        console.error('error', e);
      }
    }
    return (
      <>
        <div>
          <OnShowEditor codeviewValue={formattedCode} screenType={screenType} />
        </div>
      </>
    );
  }

  function responseHeader(stepInputs) {
    return (
      <>
        <div className="ml-5 mr-5">
          <MyTable data={responseHeaderData} columns={columns} />
        </div>
      </>
    );
  }

  function assertResults(stepInputs) {
    const tabResult = props?.stepResult?.webserviceResponse?.responseAssertionResults;
    const tabResultPost = props?.stepResult?.webserviceResponse?.postStepResult?.assertionResult?.assertionResults;

    var AllResponse = tabResult?.map((value) => {
      return (
        <div>
          <div className="flex mt-2 ml-2">
            <div className={`h-4 mt-1 w-12 ml-1 rounded-3xl unselected-tab ${elementStatsColor(`${value.state}`)}`}>
              <div className="text-center elements-stat" style={{ fontSize: 'x-small' }}>
                {value.state}
              </div>
            </div>
            <div className="ml-2">{value.assertionRequestDto.group}</div>
            <Tooltip title={value.resultMessage} placement="right-end">
              <div className="mt-1 ml-4">
                <FiInfo />
              </div>
            </Tooltip>
          </div>
        </div>
      );
    });
    var AllResponsePost = tabResultPost?.map((value) => {
      return (
        <div>
          <div className="flex mt-2 ml-2">
            <div className={`h-4 mt-1 w-12 ml-1 rounded-3xl unselected-tab ${elementStatsColor(`${value.state}`)}`}>
              <div className="text-center elements-stat" style={{ fontSize: 'x-small' }}>
                {value.state}
              </div>
            </div>
            <div className="ml-2">{value.assertionRequestDto.group}</div>
            <Tooltip title={value.resultMessage} placement="right-end">
              <div className="mt-1 ml-4">
                <FiInfo />
              </div>
            </Tooltip>
          </div>
        </div>
      );
    });
    return (
      <>
        <div className="state-col-wrp mt-3 ml-4 mb-3 assertBackgroung  fontPoppinsRegularXs">
          <button
            className={`${assertResultTab === 'All' ? 'state-active' : 'state-button'} fontSize tabColour`}
            onClick={() => setAssertResultTab('All')}
          >
            All
          </button>
          <button
            className={`${assertResultTab === 'Passed' ? 'state-active' : 'state-button'} py-0 px-1 rounded-l fontSize`}
            onClick={() => setAssertResultTab('Passed')}
          >
            Passed
          </button>
          <button
            className={`${assertResultTab === 'Failed' ? 'state-active' : 'state-button'} py-0 px-1 rounded-l fontSize`}
            onClick={() => setAssertResultTab('Failed')}
          >
            Failed
          </button>
          <button
            className={`${
              assertResultTab === 'Skipped' ? 'state-active' : 'state-button'
            } py-0 px-1 rounded-l fontSize`}
            onClick={() => setAssertResultTab('SKIPPED')}
          >
            Skipped
          </button>
        </div>
        <div>
          {assertResultTab ? (
            assertResultTab === 'All' ? (
              <div>
                {AllResponse}
                {AllResponsePost}
              </div>
            ) : assertResultTab === 'Passed' ? (
              <div>
                {tabResult
                  ?.filter((v) => v.state == 'SUCCESS')
                  .map((value) => {
                    return (
                      <div>
                        <div className="flex mt-2 ml-2">
                          <div
                            className={`h-4 mt-1 w-12 ml-1 rounded-3xl unselected-tab ${elementStatsColor(
                              `${value.state}`
                            )}`}
                          >
                            <div className="text-center elements-stat" style={{ fontSize: 'x-small' }}>
                              {value.state}
                            </div>
                          </div>
                          <div className="ml-2">{value.assertionRequestDto.group}</div>
                          <Tooltip title={value.resultMessage} placement="right-end">
                            <div className="mt-1 ml-4">
                              <FiInfo />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    );
                  })}
                {tabResultPost
                  ?.filter((v) => v.state == 'SUCCESS')
                  .map((value) => {
                    return (
                      <div>
                        <div className="flex mt-2 ml-2">
                          <div
                            className={`h-4 mt-1 w-12 ml-1 rounded-3xl unselected-tab ${elementStatsColor(
                              `${value.state}`
                            )}`}
                          >
                            <div className="text-center elements-stat" style={{ fontSize: 'x-small' }}>
                              {value.state}
                            </div>
                          </div>
                          <div className="ml-2">{value.assertionRequestDto.group}</div>
                          <Tooltip title={value.resultMessage} placement="right-end">
                            <div className="mt-1 ml-4">
                              <FiInfo />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : assertResultTab === 'Failed' ? (
              <div>
                {tabResult
                  ?.filter((v) => v.state === 'FAILURE')
                  .map((value) => {
                    return (
                      <div>
                        <div className="flex mt-2 ml-2">
                          <div
                            className={`h-4 mt-1 w-12 ml-1 rounded-3xl unselected-tab ${elementStatsColor(
                              `${value.state}`
                            )}`}
                          >
                            <div className="text-center elements-stat" style={{ fontSize: 'x-small' }}>
                              {value.state}
                            </div>
                          </div>
                          <div className="ml-2">{value.assertionRequestDto.group}</div>
                          <Tooltip title={value.resultMessage} placement="right-end">
                            <div className="mt-1 ml-4">
                              <FiInfo />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    );
                  })}
                {tabResultPost
                  ?.filter((v) => v.state == 'FAILURE')
                  .map((value) => {
                    return (
                      <div>
                        <div className="flex mt-2 ml-2">
                          <div
                            className={`h-4 mt-1 w-12 ml-1 rounded-3xl unselected-tab ${elementStatsColor(
                              `${value.state}`
                            )}`}
                          >
                            <div className="text-center elements-stat" style={{ fontSize: 'x-small' }}>
                              {value.state}
                            </div>
                          </div>
                          <div className="ml-2">{value.assertionRequestDto.group}</div>
                          <Tooltip title={value.resultMessage} placement="right-end">
                            <div className="mt-1 ml-4">
                              <FiInfo />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : assertResultTab === 'SKIPPED' ? (
              <div>
                {tabResult
                  ?.filter((v) => v.state == 'SKIPPED')
                  .map((value) => {
                    return (
                      <div>
                        <div className="flex mt-2 ml-2">
                          <div
                            className={`h-4 mt-1 w-12 ml-1 rounded-3xl unselected-tab ${elementStatsColor(
                              `${value.state}`
                            )}`}
                          >
                            <div className="text-center elements-stat" style={{ fontSize: 'x-small' }}>
                              {value.state}
                            </div>
                          </div>
                          <div className="ml-2">{value.assertionRequestDto.group}</div>
                          <Tooltip title={value.resultMessage} placement="right-end">
                            <div className="mt-1 ml-4">
                              <FiInfo />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    );
                  })}
                {tabResultPost
                  ?.filter((v) => v.state == 'SKIPPED')
                  .map((value) => {
                    return (
                      <div>
                        <div className="flex mt-2 ml-2">
                          <div
                            className={`h-4 mt-1 w-12 ml-1 rounded-3xl unselected-tab ${elementStatsColor(
                              `${value.state}`
                            )}`}
                          >
                            <div className="text-center elements-stat" style={{ fontSize: 'x-small' }}>
                              {value.state}
                            </div>
                          </div>
                          <div className="ml-2">{value.assertionRequestDto.group}</div>
                          <Tooltip title={value.resultMessage} placement="right-end">
                            <div className="mt-1 ml-4">
                              <FiInfo />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }

  var returnValue = props?.stepResult?.webserviceResponse?.updatedVariables
    ? Object.keys(props?.stepResult?.webserviceResponse?.updatedVariables)
    : [];
  const returndata = React.useMemo(
    () =>
      returnValue.map((proVal) => ({
        name: (
          <span className="text-blue-700 cursor-pointer fontPoppinsRegularMd">
            {' '}
            {props?.stepResult?.webserviceResponse?.updatedVariables[proVal].group}{' '}
          </span>
        ),
        variableType: (
          <span className="text-gray-700 fontPoppinsRegularMd">
            {props?.stepResult?.webserviceResponse?.updatedVariables[proVal].variable.type}{' '}
          </span>
        ),
        variableName: (
          <span className="text-gray-700 fontPoppinsRegularMd">
            {' '}
            {props?.stepResult?.webserviceResponse?.updatedVariables[proVal].variable.name}{' '}
          </span>
        ),
        returnWebValue: (
          <span className="text-gray-700 fontPoppinsRegularMd table-Value-truncate">
            <Tooltip
              title={
                props?.stepResult?.webserviceResponse?.updatedVariables[proVal]?.variable.masked
                  ? ''
                  : props?.stepResult?.webserviceResponse?.updatedVariables[proVal]?.variable?.value
              }
              placement="bottom-start"
            >
              <div className="truncate">
                {props?.stepResult?.webserviceResponse?.updatedVariables[proVal]?.variable?.masked
                  ? '****'
                  : props?.stepResult?.webserviceResponse?.updatedVariables[proVal]?.variable?.value}
              </div>
            </Tooltip>
          </span>
        ),
      })),
    []
  );

  const returnColumns = React.useMemo(
    () => [
      {
        Header: 'Lable Name',
        accessor: 'name',
        width: '20%',
      },
      {
        Header: 'Variable Type',
        accessor: 'variableType',
        width: '20%',
      },
      {
        Header: 'Variable Name',
        accessor: 'variableName',
        width: '20%',
      },
      {
        Header: 'Return Value',
        accessor: 'returnWebValue',
        width: '20%',
      },
    ],
    []
  );
  function returnData(stepInputs) {
    return (
      <>
        <div className="ml-5 mr-5">
          <MyTable data={returndata} columns={returnColumns} />
        </div>
      </>
    );
  }

  function httpRequestTab(stepInputs) {
    let httpReqStr = props?.stepResult?.webServiceRequest?.url;
    let stepName = '';
    props.stepResult.stepInputs.map((steps) => {
      stepName = '${' + steps.name + '}';
      httpReqStr = httpReqStr.replace(stepName, steps.actualValue);
    });
    return (
      <>
        <Tooltip title={httpReqStr} placement="bottom">
          <div className="mt-3 ml-3 cursor-pointer fontPoppinsMediumLg">
            {httpReqStr?.length < 70 ? httpReqStr : httpReqStr.substring(0, 70)}
            {httpReqStr?.length < 70 ? ' ' : '...'}
          </div>
        </Tooltip>
      </>
    );
  }

  const responseHeaderData = React.useMemo(
    ////
    () =>
      (props?.stepResult?.webserviceResponse?.headers ? props?.stepResult?.webserviceResponse?.headers : []).map(
        (proVal) => ({
          name: (
            <Tooltip title={proVal.name} placement="bottom">
              <span className="text-gray-700 cursor-pointer fontPoppinsRegularMd">{proVal.name}</span>
            </Tooltip>
          ),
          value: (
            <Tooltip title={proVal.value} placement="bottom">
              <span className="text-gray-700 fontPoppinsRegularMd">
                {' '}
                {proVal.value?.length < 70 ? proVal.value : proVal.value.substring(0, 70)}
                {proVal.value?.length < 70 ? ' ' : '...'}
              </span>
            </Tooltip>
          ),
        })
      ),
    []
  );

  const getUserVariableName = useCallback(
    (proVal) => {
      let tempValue = '';
      let stepValue = '';
      tempValue = JSON.stringify(proVal);
      if (tempValue) {
        props?.stepResult?.stepInputs.map((steps) => {
          switch (steps?.reference) {
            case 'LOCAL':
              stepValue = `LV_${steps?.value}`;
              break;
            case 'GLOBAL':
              stepValue = `GV_${steps?.value}`;
              break;
            case 'STEPGROUPINPUT':
              stepValue = `SGP_${steps?.value}`;
              break;
            case 'STEPGROUP':
              stepValue = `SGV_${steps?.value}`;
              break;
            default:
              stepValue = `PEV_${steps?.value}`;
          }
          tempValue = tempValue.replaceAll(steps?.name, stepValue);
        });
      }
      const tempValueObj = tempValue && JSON.parse(tempValue);
      if (tempValueObj?.value !== undefined) return tempValueObj?.value;
      else {
        return tempValueObj;
      }
    },
    [props?.stepResult?.stepInputs]
  );

  const requestHeaderData = React.useMemo(() => {
    let data = {};
    const { DISABLE_REQUEST_HEADER_INDEXES } = WEBSERVICE;

    props?.stepResult?.stepInputs?.forEach((steps) => {
      data = (props?.stepResult?.webServiceRequest?.headers ? props?.stepResult?.webServiceRequest?.headers : [])
        .map((proVal, index) => {
          const requestHeaderName = getUserVariableName(proVal.name);
          const requestHeaderValue = getUserVariableName(proVal.value);
          if (DISABLE_REQUEST_HEADER_INDEXES.includes(index) || proVal.isEnabled) {
            return {
              name: (
                <Tooltip title={requestHeaderName}>
                  <span className="text-gray-700 cursor-pointer fontPoppinsRegularMd">{requestHeaderName}</span>
                </Tooltip>
              ),
              value: (
                <Tooltip title={requestHeaderValue} placement="bottom">
                  <span className="text-gray-700 fontPoppinsRegularMd">
                    {proVal.value?.length < 70 ? requestHeaderValue : requestHeaderValue.substring(0, 70)}
                    {proVal.value?.length < 70 ? ' ' : '...'}
                  </span>
                </Tooltip>
              ),
            };
          }
          return null;
        })
        .filter((item) => item !== null);
    });
    return data;
  }, [getUserVariableName, props?.stepResult?.stepInputs, props?.stepResult?.webServiceRequest?.headers]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Key',
        accessor: 'name',
        width: '50%',
      },
      {
        Header: 'Value',
        accessor: 'value',
        width: '50%',
      },
    ],
    []
  );

  function requestHeaderTab(stepInputs) {
    return (
      <>
        <div className="ml-5 mr-5">
          <MyTable data={requestHeaderData} columns={columns} />
        </div>
      </>
    );
  }

  let fileParamMapObj = props?.stepResult?.webServiceRequest?.fileParamMap;
  const requestBodyData = React.useMemo(() => {
    let ReqBodVal = [];
    for (const key in fileParamMapObj) {
      fileParamMapObj[key].forEach((proVal) => {
        ReqBodVal.push({
          keytype: <span className="text-gray-700 fontPoppinsRegularMd">{proVal.type}</span>,
          key: <span className="text-gray-700 fontPoppinsRegularMd">{key}</span>,
          value:
            proVal.type === 'file' ? (
              <span className="text-gray-700 fontPoppinsRegularMd">
                {' '}
                {proVal.name}
                <span
                  onClick={() =>
                    downloadFileFromCloud(proVal._id || proVal.id).then((results) => {
                      saveFileFromBlob(results.data, proVal.name);
                    })
                  }
                >
                  <img
                    style={{
                      cursor: 'pointer',
                    }}
                    className="inline text-lg"
                    src="/assets/images/file_download.svg"
                    alt=""
                    height="20px"
                    width="20px"
                  />
                </span>
              </span>
            ) : (
              <span className="text-gray-700 fontPoppinsRegularMd">{getUserVariableName(proVal?.value)}</span>
            ),
        });
      });
    }
    return ReqBodVal;
  }, []);
  const requestBodyColumns = React.useMemo(
    () => [
      {
        Header: 'Key Type',
        accessor: 'keytype',
        width: '20%',
      },
      {
        Header: 'Key',
        accessor: 'key',
        width: '20%',
      },
      {
        Header: 'Value',
        accessor: 'value',
        width: '20%',
      },
    ],
    []
  );
  const requestBodyColumnsUrl = React.useMemo(
    () => [
      {
        Header: 'Key',
        accessor: 'key',
        width: '50%',
      },
      {
        Header: 'Value',
        accessor: 'value',
        width: '50%',
      },
    ],
    []
  );
  let requestBodyDataUrl = React.useMemo(() => {
    let reqData = {};
    reqData = props?.stepResult?.webServiceRequest?.urlEncoded?.map((element) => {
      return {
        key: <span className="text-gray-700 fontPoppinsRegularMd">{element.name}</span>,
        value: <span className="text-gray-700 fontPoppinsRegularMd">{getUserVariableName(element.value)}</span>,
      };
    });
    return reqData;
  }, []);
  function requestBodyTab(stepInputs) {
    const requestBody = props.stepResult.webServiceRequest.requestBody;
    const requestBodyRawData = requestBody?.type === 'file' ? requestBody?.name : requestBody?.value || requestBody;
    return (
      <>
        {props?.stepResult?.webServiceRequest?.bodyType === 'form-data' ? (
          <>
            <MyTable data={requestBodyData} columns={requestBodyColumns} />
          </>
        ) : props?.stepResult?.webServiceRequest?.bodyType === 'x-www-form-urlencoded' ? (
          <>
            <div className="ml-5 mr-5">
              <MyTable data={requestBodyDataUrl} columns={requestBodyColumnsUrl} />
            </div>
          </>
        ) : props?.stepResult?.webServiceRequest?.bodyType === 'none' ? (
          <>
            <div className="pt-1 pb-1 border_style flex flex-wrap items-center gap-of-request-form-data-header">
              <span className="ml-5"></span>
            </div>

            <div className="m-2 text-sm request-body-none-center fontPoppinsRegularLg">
              <div>
                <img src={RequestBodyNone} width="150" />
              </div>
              <div>This request does not have any body.</div>
            </div>
          </>
        ) : (
          <>
            {requestBody?.type === 'file' && (
              <div className="step-results-download-section">
                <span
                  onClick={() =>
                    downloadFileFromCloud(requestBody.id).then((results) => {
                      saveFileFromBlob(results.data, requestBody.name);
                    })
                  }
                >
                  <img
                    className="inline text-lg cursor-pointer"
                    src="/assets/images/file_download.svg"
                    alt=""
                    height="20px"
                    width="20px"
                  />
                </span>
              </div>
            )}
            <div className="mt-1">
              <OnShowEditor codeviewValue={getUserVariableName(requestBodyRawData)} screenType={screenType} />
            </div>
          </>
        )}
      </>
    );
  }

  const cURLTab = () => {
    let formData = new FormData();
    const requestBody = props?.stepResult?.webServiceRequest;
    let stringifyPayload = JSON.stringify(requestBody);
    props.stepResult.stepInputs.map((steps) => {
      let strigifiedData = JSON.stringify(steps.actualValue);
      strigifiedData = strigifiedData?.substring(1, strigifiedData.length - 1);
      if (steps?.reference === 'DATAPROVIDER') {
        stringifyPayload = stringifyPayload.replaceAll('${DPV_' + steps.value + '}', strigifiedData);
      } else if (steps?.reference === FOR_LOOP_TITLE) {
        stringifyPayload = stringifyPayload.replaceAll(FOR_LOOP_DISPLAY_VALUE, strigifiedData);
      } else {
        stringifyPayload = stringifyPayload.replaceAll('${' + steps.name + '}', strigifiedData);
      }
    });
    formData.append('body', stringifyPayload);
    return (
      <GenerateCurl
        formData={formData}
        curl={curl}
        setCurl={setCurl}
        stepResult={props?.stepResult?.webServiceRequest}
        setWebserviceStepId={setWebserviceStepId}
        webserviceStepId={webserviceStepId}
        curlError={curlError}
        setCurlError={setCurlError}
        id={props?.scriptId}
      />
    );
  };

  const handleCurlCopy = () => {
    setCurlCopy(true);
    setTimeout(() => {
      setCurlCopy(false);
    }, 3000);
  };

  function outputTab(stepInputs) {
    return (
      <>
        <div className="grid grid-cols-4 mt-4 ml-4 w-3/4">
          <div
            className={`flex cursor-pointer ${
              selectedOutputSubTab === 'Response Body' ? 'highlight-border w-2/3 selected-tab' : 'unselected-tab'
            }`}
            onClick={() => setSelectedOutputSubTab('Response Body')}
          >
            <div className="info-tab mb-1 w-full"> Response Body </div>
          </div>
          <div
            className={`flex  cursor-pointer ${
              selectedOutputSubTab === 'Response Header' ? 'highlight-border w-2/3 selected-tab' : 'unselected-tab'
            }`}
            onClick={() => setSelectedOutputSubTab('Response Header')}
          >
            <div className="info-tab mb-1 w-full"> Response Header </div>
          </div>
          <div
            className={`flex cursor-pointer ${
              selectedOutputSubTab === 'Assert Results' ? 'highlight-border w-2/4 selected-tab' : 'unselected-tab'
            }`}
            onClick={() => setSelectedOutputSubTab('Assert Results')}
          >
            <div className=" info-tab mb-1 w-full"> Assert Results </div>
          </div>
          <div
            className={`flex  cursor-pointer ${
              selectedOutputSubTab === 'Return Data' ? 'highlight-border w-2/4 selected-tab' : 'unselected-tab'
            }`}
            onClick={() => setSelectedOutputSubTab('Return Data')}
          >
            <div className="info-tab mb-1 w-full"> Return Data </div>
          </div>
        </div>
        <div className="tab-border mx-4"></div>
        <div>
          {selectedOutputSubTab === 'Response Body' && (
            <div className="px-15px fontPoppinsMediumLg">{responseBody(props?.stepResult?.elementDetails)}</div>
          )}
          {selectedOutputSubTab === 'Response Header' && (
            <div className="px-15px">{responseHeader(props?.stepResult?.errorLog)}</div>
          )}
          {selectedOutputSubTab === 'Assert Results' && (
            <div className="px-15px">{assertResults(props?.stepResult?.errorLog)}</div>
          )}
          {selectedOutputSubTab === 'Return Data' && (
            <div className="px-15px">{returnData(props?.stepResult?.stepInputs)}</div>
          )}
        </div>
      </>
    );
  }

  const validateStep = async () => {
    try {
      let { data } = await getScriptReq(props?.moduleId, props?.scriptId);
      let scriptData = data?.responseObject;
      setUrlData({
        moduleName: scriptData?.parentName,
        scriptName: `${scriptData?.prefix} - ${scriptData?.name}`,
      });

      if (scriptData) {
        setNavigationState({ ...navigationState, navigateToScriptData: scriptData });
        if (props?.parentStep) {
          if (!isEmptyValue(props?.stepTypeCondition)) {
            let [stepData] = scriptData?.conditions?.filter(
              (condition) => condition?.stepId === props?.parentStep?.stepId
            );
            setResourceExists(true);
            setNavigationState({ ...navigationState, navigateToStepData: stepData });
            let [parentStepData] = scriptData?.steps?.filter((step) => step?.stepId === props?.parentStep?.stepId);
            if (parentStepData) {
              setResourceExists(true);
              setNavigationState({ ...navigationState, navigateToStepData: parentStepData });
            } else {
              setResourceExists(false);
            }
          }

          // Commented for future usage

          // if(parentStepData&&parentStepData?.libraryId&&parentStepData?.stepGroupId){
          //   let {data}=await getStepGroupReq(parentStepData?.libraryId,parentStepData?.stepGroupId)
          //   let stepGroupData=data?.responseObject
          //   if(stepGroupData){
          //     setStepGroupResponse(stepGroupData)
          //     let [stepExists]=stepGroupData?.steps?.filter(step=>props?.stepResult?.stepId?.includes(step?.stepId)&&step?.name===props?.stepResult?.name)
          //     if(stepExists){
          //         setResourceExists(true)
          //         setShowStepGroupLink(true)
          //         setStepGroupToNavigate(stepExists)
          //     }
          //     else{
          //         setResourceExists(false)
          //         setShowStepGroupLink(false)
          //     }
          //   }
          //   else{
          //     setResourceExists(false)
          //   }
          // }
        } else {
          let [verifyStepExists] = scriptData?.steps?.filter((step) => step?.stepId === props?.stepResult?.stepId);
          if (verifyStepExists) {
            setResourceExists(true);
            setNavigationState({ ...navigationState, navigateToStepData: verifyStepExists });
          } else {
            setResourceExists(false);
          }
        }
      } else {
        setResourceExists(false);
      }
    } catch (error) {
      console.error('Error fetcing script details...', error);
    }
  };

  const navigateBackToSteps = async (stepId) => {
    let { data } = await getScriptReq(props?.moduleId, props?.scriptId);
    let scriptData = data?.responseObject;
    let [verifyStepExists] = scriptData?.steps?.filter((step) => step?.stepId === props?.stepResult?.stepId);
    try {
      if (resourceExists && verifyStepExists) {
        if (navigationState?.navigateToStepData) {
          window.open(
            `/testdevelopment/Script/${encodeSpecialCharater(urlData?.moduleName)}_${
              props?.moduleId
            }/${encodeSpecialCharater(urlData?.scriptName)}_${props?.scriptId}/steps?moduleId=${
              props?.moduleId
            }&scriptId=${props?.scriptId}&manualScriptIdRequired=true${
              stepId &&
              `&stepId=${JSON.stringify(navigationState?.navigateToStepData?.stepId)}${
                props?.stepTypeCondition
                  ? props?.stepTypeCondition === 'Postconditions'
                    ? `&condition=post`
                    : '&condition=pre'
                  : ''
              }`
            }`,
            '_blank'
          );
          setNavigationState({
            navigateToStepData: null,
            stepGroupResponse: null,
            showStepGroupLink: false,
            stepGroupToNavigate: null,
            navigateToScriptData: null,
          });
          validateStep();
          // props?.closeStepModal(false);
        }
      } else {
        props?.MyAlert?.info(GO_TO_STEP?.ERROR_MSG);
        props?.closeStepModal(false);
      }
    } catch (error) {
      console.error('ERROR IN VERIFIYING SCRIPT EXISTANCE', error);
    }
  };

  const navigateToStepGroup = async (stepId) => {
    if (resourceExists) {
      if (navigationState?.stepGroupResponse && navigationState?.stepGroupToNavigate) {
        let parentName = `${navigationState?.stepGroupResponse?.parentName}_${navigationState?.stepGroupResponse?.parentId}`;
        let stepGroupName = `${navigationState?.stepGroupResponse?.name}_${navigationState?.stepGroupResponse?.id}`;

        window.open(
          `/repository/step_group/${parentName}/${stepGroupName}/Steps?id=${
            navigationState?.stepGroupResponse?.id
          }&parentId=${navigationState?.stepGroupResponse?.parentId}&stepGroup=${
            navigationState?.stepGroupResponse?.name
          }&defaultStepGroup=${navigationState?.stepGroupResponse?.defaultStepGroup}&stepId=${JSON.stringify(
            navigationState?.stepGroupToNavigate?.stepId
          )}`,
          '_blank'
        );
      }
    } else {
      props?.MyAlert?.info(`Resource not found...`);
      props?.closeStepModal(false);
    }
  };

  let tooltipMessage;
  if (message) {
    tooltipMessage =
      message[pageCheck] === 'page'
        ? ` in ${message[page]} page`
        : ''
        ? message[pageCheck] === 'page'
          ? ` in ${message[page]} page`
          : ''
        : '';
  }
  return (
    <>
      <MatModal open={showModal} className="modal-dialog">
        <div className="step-result-modal modal-container step-modal-size">
          <div className="modal-header">
            <div className="flex flex-1">
              <div className="fontPoppinsSemiboldLg w-11/12 flex items-center justify-between">Step Result</div>
              {isCurlCopy && (
                <Alert
                  classes={{ root: `${classes.root}` }}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setCurlCopy(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  severity="success"
                >
                  <span className="fontPoppinsMediumSm h-1 flex">cURL copied successfully.</span>
                </Alert>
              )}
              {props?.moduleId &&
                !global.permission.isNoAccess('testDevelopment') &&
                !props?.parentStep &&
                !props?.hideGoToSteps && (
                  <span
                    className="float-right hyper_link_color-common fontPoppinsRegularMd cursor-pointer"
                    onClick={() => navigateBackToSteps(props?.stepResult?.stepId)}
                  >
                    {GO_TO_STEP?.GO_TO_STEP}
                  </span>
                )}
              &nbsp;
              {/* Commented for future implementation */}
              {/* {showStepGroupLink&&<span className='cursor-pointer' onClick={() => navigateToStepGroup(props?.stepResult?.stepId)}>Go to step group</span>} */}
              <span className="step-alert-position-style">
                <AlertContatiner></AlertContatiner>
              </span>
              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  props.closeStepModal(false);
                  setShowModal(false);
                }}
              >
                <CloseIcon className="close-button" />
              </IconButton>
            </div>
          </div>
          <div className="modal-body" id="journal-scroll">
            <div>
              <div className="grid ">
                <div className="col-span-2 sm:col-span-2 md:col-span-2">
                  <div className="">
                    <div className="">
                      <div className="grid grid-cols-3 px-4 gap-4" id="stepData">
                        {props?.stepResult?.name && (
                          <div>
                            <div className="step-columns"> Description </div>
                            <Tooltip title={`${props?.stepResult?.name} ${tooltipMessage}`} placement="bottom-start">
                              <div className="column-value" id="wrapId">
                                {props?.stepResult?.name}
                                {message[pageCheck] == 'page' ? ` in ${message[page]} page` : ''}
                              </div>
                            </Tooltip>
                          </div>
                        )}
                        {props?.stepResult?.message && (
                          <div>
                            <div className="step-columns"> Result </div>
                            <div className={` ${textColor(props?.stepResult?.status)}`} id="wrapId">
                              {props?.stepResult?.status !== 'SKIP' ? (
                                <Tooltip title={props?.stepResult?.message} placement="bottom-start">
                                  <span className="fontPoppinsMediumMd column-value-err">
                                    {props?.stepResult?.message}
                                  </span>
                                </Tooltip>
                              ) : (
                                <div> Skipped </div>
                              )}
                            </div>
                          </div>
                        )}
                        {props?.stepResult?.status !== 'SKIP' && (
                          <div>
                            <div className="step-columns"> Return </div>
                            <div className="column-value mt-2 truncate">
                              {props?.stepResult?.stepReturn ? (
                                <Tooltip title={props?.stepResult?.stepReturn?.value} placement="bottom-start">
                                  {stepReturnInfo(props?.stepResult?.stepReturn)}
                                </Tooltip>
                              ) : (
                                stepReturnInfo(props?.stepResult?.stepReturn)
                              )}
                            </div>
                          </div>
                        )}
                        {props?.stepResult?.status !== 'SKIP' && props?.stepResult?.status !== 'PASS' && (
                          <div>
                            <div className="step-columns"> If Failed </div>
                            <Tooltip
                              title={ifCheckPointIsFailed(props?.stepResult?.stepInputs)}
                              placement="bottom-start"
                            >
                              <div className="column-value mt-2 text-overflow-ellipsis">
                                {ifCheckPointIsFailed(props?.stepResult?.stepInputs)}
                              </div>
                            </Tooltip>
                          </div>
                        )}
                        {props?.stepResult?.status !== 'SKIP' &&
                          (!containsContinueOrBreak || containsContinueOrBreak === false) && (
                            <div>
                              <div className="step-columns"> Screenshot </div>
                              <div className="column-value mt-2">
                                {props.screenshotDeleted ? (
                                  <span className="fontPoppinsRegularMd">Not Enough Memory</span>
                                ) : props?.stepResult?.screenshotPath && props?.stepResult?.platform !== 'Common' ? (
                                  !screenShotsDeleted && props?.stepResult?.screenshotPath ? (
                                    <span
                                      className="mt-2 text-primary flex cursor-pointer"
                                      title="Download Screenshot"
                                      onClick={() => downloadFile(props?.stepResult?.screenshotPath, 'screenshot')}
                                    >
                                      <small> Download Screenshot</small>
                                      {isDownloading ? (
                                        <img
                                          src="\assets\Spin-1s-22px.svg"
                                          height="16px"
                                          width="16px"
                                          className="ml-2"
                                          alt=""
                                        />
                                      ) : (
                                        <HiOutlineDownload className="download-icon ml-2" />
                                      )}
                                    </span>
                                  ) : (
                                    <span
                                      className="mt-2 text-primary flex cursor-pointer"
                                      title="Download Screenshot"
                                      onClick={() => downloadFile(props?.stepResult?.screenshotPath, 'screenshot')}
                                    >
                                      <small> Download Screenshot</small>
                                      {isDownloading ? (
                                        <img
                                          src="\assets\Spin-1s-22px.svg"
                                          height="16px"
                                          width="16px"
                                          className="ml-2"
                                          alt=""
                                        />
                                      ) : (
                                        <HiOutlineDownload className="download-icon ml-2" />
                                      )}
                                    </span>
                                  )
                                ) : props?.stepResult?.platform === 'Common' ? (
                                  <div className="flex gap-2">
                                    <span>{STEP_RESULTS.NOT_APPLICABLE}</span>
                                    <span className="mt-1 cursor-pointer">
                                      <Tooltip title={STEP_RESULTS.SCREENSHOT_INSTANCE} placement="bottom">
                                        <span>
                                          <Icon name="info_icon" height={14} width={14} />
                                        </span>
                                      </Tooltip>
                                    </span>
                                  </div>
                                ) : (
                                  <span>{STEP_RESULTS.NOT_CONFIGURED}</span>
                                )}
                              </div>
                            </div>
                          )}
                        {/* COMMENTED FOR FUTURE REQUIRMENTS */}
                        {props?.stepResult?.status !== 'SKIP' &&
                          (!containsContinueOrBreak || containsContinueOrBreak === false) && (
                            <div>
                              <div className="step-columns"> Video </div>
                              <div className="column-value mt-2">
                                {props?.videoDeleted ? (
                                  <span className="fontPoppinsRegularMd">Not Enough Memory</span>
                                ) : !videosDeleted && scriptDetails?.videoRecorderPath ? (
                                  <span
                                    className="mt-2 text-primary flex cursor-pointer"
                                    title="Download Video"
                                    onClick={() => downloadFile(scriptDetails?.videoRecorderPath, 'video')}
                                  >
                                    <small> Download Video</small> <HiOutlineDownload className="download-icon ml-2" />
                                  </span>
                                ) : videosDeleted ? (
                                  <span>
                                    {' '}
                                    The Video cannot be downloaded. It has been deleted from the storage system
                                  </span>
                                ) : (
                                  <span> Not configured </span>
                                )}
                              </div>
                            </div>
                          )}
                      </div>
                      <div className="grid grid-cols-1 mt-4 px-4 gap-4" id="stepData">
                        {/* Added Error */}
                        {props?.stepResult?.status !== 'PASS' && (
                          <div>
                            <div className="step-columns"> Error</div>
                            <div className="column-value column-value-errors content-error mt-2 card card-body">
                              <ReadMoreDemo errorLog={props?.stepResult?.errorLog} />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex mt-4 ml-4 gap-3">
                      {props?.stepResult?.isWebServiceRequest ? (
                        ''
                      ) : !isEmptyValue(props?.stepResult?.nlpReportPath) ? (
                        <div
                          className={`flex cursor-pointer ${
                            selectedTab === 'Report' ? 'highlight-border w-24 selected-tab' : 'w-24 unselected-tab'
                          }`}
                          onClick={() => setSelectedTab('Report')}
                        >
                          <div className="info-tab mb-1"> Report </div>
                        </div>
                      ) : (
                        <div
                          className={`flex cursor-pointer ${
                            selectedTab === 'Elements' ? 'highlight-border w-24 selected-tab' : 'w-24 unselected-tab'
                          }`}
                          onClick={() => setSelectedTab('Elements')}
                        >
                          <div className="info-tab mb-1"> Elements </div>

                          <Tooltip
                            title={elementsStatsCountTooltipMessage(
                              props?.stepResult?.elementDetails,
                              props?.stepResult?.status
                            )}
                            placement="bottom-start"
                          >
                            <div>
                              <div>
                                {elementsStatistic(props?.stepResult?.elementDetails, props?.stepResult?.status)}
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      )}
                      {props?.stepResult?.isWebServiceRequest ? (
                        <>
                          <div
                            className={`flex info-tab cursor-pointer ${
                              selectedTab === 'Input' ? 'highlight-border w-24 selected-tab' : 'w-24 unselected-tab'
                            }`}
                            onClick={() => setSelectedTab('Input')}
                          >
                            <div className="mb-1"> Input </div>
                          </div>
                          <div
                            className={`flex info-tab cursor-pointer ${
                              selectedTab === 'Output' ? 'highlight-border w-24 selected-tab' : 'w-24 unselected-tab'
                            }`}
                            onClick={() => setSelectedTab('Output')}
                          >
                            <div className="mb-1"> Output </div>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                      <div
                        className={`flex info-tab cursor-pointer ${
                          selectedTab === 'Variables' ? 'highlight-border w-24 selected-tab' : 'w-24 unselected-tab'
                        }`}
                        onClick={() => setSelectedTab('Variables')}
                      >
                        <div className="mb-1"> Variables </div>
                        <Tooltip
                          title={returnVariableCountTooltipMessage(
                            props?.stepResult?.stepInputs,
                            props?.stepResult?.elementDetails,
                            props?.stepResult?.stepReturn
                          )}
                          placement="bottom-start"
                        >
                          <div>
                            <div>
                              {checkIfVariablesUsed(
                                props?.stepResult?.stepInputs,
                                props?.stepResult?.elementDetails,
                                props?.stepResult?.stepReturn
                              )}
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                      {props?.stepResult?.status !== 'PASS' && !props?.stepResult?.nlpReportPath && (
                        <div
                          className={`info-tab cursor-pointer ${
                            selectedTab === 'Logs' ? 'selected-tab highlight-border w-8' : 'unselected-tab'
                          }`}
                          onClick={() => setSelectedTab('Logs')}
                        >
                          <div className="mb-1"> Logs </div>
                        </div>
                      )}
                      {props?.stepResult?.status !== 'PASS' && props?.stepResult?.errorLog && exceptionSuggestion && (
                        <div
                          className={`info-tab cursor-pointer ${
                            selectedTab === SUGGESTIONS ? 'selected-tab highlight-border w-[108px]' : 'unselected-tab'
                          }`}
                          onClick={() => setSelectedTab(SUGGESTIONS)}
                        >
                          {SUGGESTIONS_TO_FIX}
                        </div>
                      )}
                    </div>
                    <div className="tab-border mx-4"></div>
                    <div>
                      {selectedTab === 'Elements' && (
                        <section>{elementsTab(props?.stepResult?.elementDetails)}</section>
                      )}
                      {selectedTab === 'Input' && <div>{inputTab(props?.stepResult?.errorLog)}</div>}
                      {selectedTab === 'Output' && <div>{outputTab(props?.stepResult?.errorLog)}</div>}
                      {selectedTab === 'Variables' && (
                        <section>
                          {variablesTab(
                            props?.stepResult?.stepInputs,
                            props?.stepResult?.elementDetails,
                            props?.stepResult?.stepReturn
                          )}
                        </section>
                      )}
                      {selectedTab === 'Logs' && <div>{logs(props?.stepResult?.errorLog)}</div>}
                      {selectedTab === 'Report' && <section>{reportTab()}</section>}
                      {selectedTab === SUGGESTIONS && (
                        <ExceptionSuggestion exceptionSuggestion={exceptionSuggestion} openFrom={STEP_RESULT} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {selectedWebServiceSubTab === 'cURL' && selectedTab === 'Input' && (
              <CopyToClipboard text={curl} onCopy={handleCurlCopy}>
                <button disabled={!curl} className="fontPoppinsMediumXs modal-save-btn">
                  Copy cURL
                </button>
              </CopyToClipboard>
            )}
            <button
              className="mr-3 gray-btn"
              onClick={() => {
                props.closeStepModal(false);
                setShowModal(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </MatModal>
    </>
  );
};

export default StepModal;
