import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Modal from 'react-modal';
import IconButton from '@material-ui/core/IconButton';
import { postLibraryReq, putLibraryReq } from '@src/api/api_services';
import { checkPageExistParentPage, LIBRARY_MODAL } from '@src/common/ui-constants';

import { TextareaAutosize, TextField } from '@material-ui/core';
import { useEffect } from 'react';
import TreeWithRadioButton from '@src/pages/common/table_tree/table-tree-with-radio-button';
const LibraryModal = (props) => {
  const nameFieldRef = useRef();
  const treeData = props?.treeData;
  if (treeData[0]?.title === 'Root library') {
    treeData[0].title = 'Root Library';
  }
  const { children, ...default_node } = treeData[0] || {};
  const [showModal, setShowModal] = useState(true);
  const [selectedPage, setSelectedPage] = useState('');
  const [descCount, setdescCount] = React.useState(0);
  let [noOfRows, setNoOfRows] = useState(1);
  const [createUpdateCalled, setCreateUpdateCalled] = useState(true);
  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');
  const [rootLibrary, setRootLibrary] = useState(false);

  let [executionOrder, setExecutionOrder] = useState(
    props.nodeToAdd ? (props.nodeToAdd === 0 ? 1 : findExecutionOrder(props.nodeToAdd)) : 1
  );
  function findExecutionOrder(node, rootNode = false) {
    if (rootNode) {
      return node?.lastExecutionOrder + 1;
    }
    return node?.data?.stepGroupCount + node?.data?.subLibraryCount + 1;
  }
  let initialValues;
  useEffect(() => {
    if (props.mode === 'EDIT') {
      setdescCount(props?.data?.desc?.length);
    }
    if (!props?.treeData[0]?.children?.length > 0) {
      setRootLibrary(true);
    }
  }, []);

  if (props.data) {
    initialValues = {
      libraryName: props.data.name,
      description: props.data.desc,
      parentPage: props.data.parentName,
    };
  } else if (props?.parentName) {
    initialValues = {
      libraryName: '',
      description: '',
      parentPage: props?.parentName,
    };
  } else if (treeData[0]?.children?.length > 0) {
    initialValues = {
      libraryName: '',
      description: '',
      parentPage: '',
    };
  } else {
    initialValues = {
      libraryName: '',
      description: '',
      parentPage: 'Root',
    };
  }

  const treeNodeSelected = ([data]) => {
    if (!data) {
      formikDetails.setFieldValue('parentPage', '');
    } else if (data && data.data.isRootNode) {
      setExecutionOrder(findExecutionOrder(data.data, true));
      setSelectedPage({ data: data.data, ...data.data });
      formikDetails.setFieldValue('parentPage', data.data.title);
    } else if (data && data.data.key) {
      setExecutionOrder(findExecutionOrder(data.node));
      setSelectedPage({ ...data.node, data: data.data });
      formikDetails.setFieldValue('parentPage', data.node.title);
    }
    nameFieldRef?.current?.focus();
  };
  async function createContainerReq(reqBody) {
    let response;
    try {
      response = await postLibraryReq(reqBody);
      if (response.data.responseObject) {
        props.closeModal(false);
        props.reloadTree(true);
        props.MyAlert.success(
          `${
            reqBody.name.length > 22 ? reqBody.name.substring(0, 22) + '...' : reqBody.name
          } Library created successfully`
        );
      } else {
        if (response.data.responseCode === 400 && response.data.message.includes('already exists')) {
          formikDetails.setFieldError('libraryName', 'Name already exists');
          setCreateUpdateCalled(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function updateContainerReq(reqBody) {
    let response;
    try {
      response = await putLibraryReq(reqBody.id, reqBody);
      if (response.data.responseObject) {
        props.closeModal(false);
        props.reloadTree(true);
        props.MyAlert.success(
          `${reqBody.name.length > 22 ? reqBody.name.substring(0, 22) + '...' : reqBody.name} ${
            reqBody.name.toUpperCase().includes('library') ? '' : 'Library'
          } updated successfully`
        );
      } else {
        if (response.data.responseCode === 400 && response.data.message.includes('already exists')) {
          formikDetails.setFieldError('libraryName', 'Name already exists');
          setCreateUpdateCalled(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  const updateLibrary = () => {
    const values = formikDetails.values;
    let exist = false;
    let noupdate = false;
    if (values.libraryName === props.data.name && values.description === props.data.desc) {
      noupdate = true;
    } else if (values.libraryName === props.data.name && values.description !== props.data.desc) {
      exist = false;
    } else if (values.libraryName.toLowerCase() === props.data.name.toLowerCase()) {
      exist = false;
    } else if (values.libraryName !== props.data.name) {
      exist = checkPageExistParentPage(props.treeData, props.data.parentId, values.libraryName);
    }
    if (noupdate) {
      props.closeModal(false);
      props.MyAlert.info(`nothing there  to update`);
    } else if (exist) {
      formikDetails.setFieldError('libraryName', 'Name already exists');
      setCreateUpdateCalled(true);
    } else {
      props.data.name = values.libraryName;
      props.data.desc = values.description;
      updateContainerReq(props.data);
    }
  };
  const createLibrary = () => {
    const values = formikDetails.values;
    let requestBody;
    let exist = false;
    if (props.treeData[0]?.children?.length > 0) {
      if (props.parentId) {
        exist = checkPageExistParentPage(props.treeData, props.parentId, values.libraryName);
      } else if (!selectedPage) {
        exist = checkPageExistParentPage(props.treeData, default_node.key, values.libraryName);
      } else {
        exist = checkPageExistParentPage(props.treeData, selectedPage.data.key, values.libraryName);
      }
    }

    if (exist) {
      formikDetails.setFieldError('libraryName', 'Name already exists');
      setCreateUpdateCalled(true);
    } else {
      if (values) {
        requestBody = {
          name: values.libraryName,
          desc: values.description,
          executionOrder: executionOrder,
        };
        requestBody['stepGroups'] = [];
        if (props.parentId) {
          requestBody['parentId'] = props.parentId;
          requestBody['parentName'] = props.parentName;
        } else if (!selectedPage) {
          requestBody['parentId'] = default_node.key;
          requestBody['parentName'] = default_node.title;
        } else {
          requestBody['parentId'] = selectedPage.data.key;
          requestBody['parentName'] = selectedPage.title;
        }
        createContainerReq(requestBody);
      }
    }
  };

  const validationSchema = yup.object({
    libraryName: yup
      .string()
      .required('Name is required')
      .min(2, 'Name must be minimum 2 characters')
      .matches(/.*\S.*/, 'Only whitespaces are not allowed')
      .matches(/^[-a-zA-Z0-9-()_]+(\s+[-a-zA-Z0-9-()_]+)*$/, 'Space is not allowed in the beginning and at the end')
      .matches(/^[a-zA-Z0-9-() ]*$/, 'Name should accept only ( ,) ,- special characters'),
    parentPage: yup.string().required('Parent library is required'),
    description: yup.string(),
  });

  const onSubmit = () => {
    if (
      formikDetails.values.libraryName !== '' &&
      formikDetails.values.parentPage !== '' &&
      formikDetails.values.libraryName.length >= 1 &&
      formikDetails.errors.libraryName !== 'Name should accept only ( ,) ,- special characters' &&
      formikDetails.errors.libraryName !== 'Space is not allowed at starting and at the end' &&
      formikDetails.errors.libraryName !== 'Only whitespaces are not allowed'
    ) {
      if (['ADD', 'ADD_SUB'].includes(props.mode)) {
        if (createUpdateCalled) {
          setCreateUpdateCalled(false);
          createLibrary();
        }
      } else {
        if (createUpdateCalled) {
          setCreateUpdateCalled(false);
          updateLibrary();
        }
      }
    }
  };

  const formikDetails = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  let errorExistForParentPage = false;
  if (formikDetails.values.parentPage === '' && formikDetails.touched.parentPage) {
    errorExistForParentPage = true;
  } else {
    errorExistForParentPage = false;
  }
  const eliminateMultipleSpace = (event) => {
    let regexPattern = /\s+/g;
    event.target.value = event.target.value.replace(regexPattern, ' ');
    formikDetails.handleChange(event);
    formikDetails.handleBlur(event);
  };

  const getPlaceHolderText = () => {
    return !props.parentId
      ? props.parentName || LIBRARY_MODAL.ROOT_LIBRARY_CONSTANT
      : props.parentId
      ? props.parentName
      : LIBRARY_MODAL.REQUIRE_PARENT_MODULE;
  };
  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => {
        setShowModal(false);
        props.closeModal(false);
      }}
      className="p-px modal-dialog"
    >
      <form onSubmit={formikDetails.handleSubmit} className="modal-position">
        <div className="modal-container page_modal_size">
          <div className="modal-header">
            {props.mode === 'ADD' || props.mode === 'ADD_SUB' ? (
              <h2 className="title"> Create Library</h2>
            ) : (
              <div className="title-trim fontPoppinsMediumLg" title={props.data.name}>
                Edit Library - {props.data.name}
              </div>
            )}
            <IconButton
              color="primary"
              component="span"
              className="close"
              onClick={() => {
                props.closeModal(false);
                setShowModal(false);
              }}
            >
              <img
                style={{
                  cursor: 'pointer',
                }}
                src="/assets/images/close_black.svg"
                alt=""
                height="25px"
                width="25px"
              />
            </IconButton>
          </div>
          <div className="modal-body mx-3" id="journal-scroll">
            <div className="relative">
              <div className="rs-user-input-container">
                <TextField
                  error={formikDetails.errors.libraryName && formikDetails.touched.libraryName}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={
                    <>
                      {' '}
                      <span className="text-red-400 mr-1">&#42;</span>Name
                    </>
                  }
                  inputRef={nameFieldRef}
                  size="40"
                  className="w-1/2 mui-input-text-field"
                  type="text"
                  autoFocus={true}
                  autoComplete="off"
                  name="libraryName"
                  id="libraryName"
                  placeholder={`Enter library name`}
                  onBlur={eliminateMultipleSpace}
                  onChange={formikDetails.handleChange}
                  value={formikDetails.values.libraryName}
                />
              </div>
              {formikDetails.errors.libraryName && formikDetails.touched.libraryName ? (
                <div className="errorMessage absolute">{formikDetails.errors.libraryName}</div>
              ) : null}
            </div>
            <div className="mt-5 w-full">
              <label htmlFor="description" className="input-filed-label-style-common">
                Description
              </label>
              <TextareaAutosize
                rowsMax={noOfRows}
                onBlur={(e) => {
                  setNoOfRows(1);
                  e.target.classList.add('descriptionStyle');
                }}
                onFocus={(e) => {
                  setNoOfRows(null);
                  e.target.classList.remove('descriptionStyle');
                }}
                id="description"
                name="description"
                maxLength="200"
                className="-mt-1 block w-full rs-input-style-textarea popup-input-bg  border-0 border-b input-field-color descriptionStyle resize-none"
                onKeyUp={(e) => setdescCount(e.target.value.length)}
                value={formikDetails.values.description}
                placeholder={
                  props.mode === 'ADD' || props.mode === 'ADD_SUB'
                    ? 'Your description goes here...'
                    : 'Your description goes here...'
                }
                onChange={formikDetails.handleChange}
              />
              <div className="mt-1 text-sm text-gray-500 text-right">{descCount}/200</div>
              {formikDetails.errors.description && formikDetails.touched.description && (
                <p style={{ color: 'red' }}>{formikDetails.errors.description}</p>
              )}
            </div>
            <div className="mt-2">
              <label
                htmlFor="parentModule"
                className={
                  errorExistForParentPage ? 'fontPoppinsRegularMd text-red-500' : 'input-filed-label-style-common'
                }
              >
                <span className="text-red-400">&#42;</span>Parent Library
              </label>
              <div className="popup-input-bg">
                <TreeWithRadioButton
                  moduleSelection={true}
                  individualTree={id ? true : false}
                  data={treeData ? treeData : []}
                  operation={['ADD_SUB', 'EDIT'].includes(props.mode) ? 'edit' : null}
                  placeholder={getPlaceHolderText()}
                  nodeSelected={treeNodeSelected.bind(this)}
                  hideElements={true}
                  hideElementsBtn={true}
                  buttonLabel="Library"
                  rootLibrary={rootLibrary}
                />
              </div>
              {formikDetails.errors.parentPage &&
              formikDetails.touched.parentPage &&
              formikDetails.values.parentPage === '' ? (
                <div className="errorMessage">{formikDetails.errors.parentPage}</div>
              ) : null}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="gray-btn mr-3"
              onClick={() => {
                props.closeModal(false);
                setShowModal(false);
              }}
            >
              Cancel
            </button>
            <button type="submit" className="primary-btn">
              {props.mode === 'ADD' || props.mode === 'ADD_SUB' ? <span>Create</span> : <span>Update</span>}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default LibraryModal;
