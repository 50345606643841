import React, { useEffect, useState } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import PostCondition from './post-condition';
import PreCondition from './pre-condition';
import { isEmptyValue } from '@src/util/common_utils';

const ModulePrePostConditionRouter = (props) => {
  const splitedpathArray = props.location.pathname.split('/');
  let activeTab = splitedpathArray[splitedpathArray.length - 1];
  const index = props.location.pathname.lastIndexOf('/');
  const path = props.location.pathname.slice(0, index);
  const parentPath = path.slice(0, path.lastIndexOf('/'));
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  let [createCalled, setCreateCalled] = useState(false);
  let [disableCreate, setDisableCreate] = useState(false);
  let [postCondition, setPostCondition] = useState(0);
  let [preCondition, setPreCondition] = useState(0);
  const DisableCreateButton = (value) => {
    setDisableCreate(value);
  };
  const [postConditionData, setPostConditionData] = useState([]);
  const [postExeOrder, setPostExeOrder] = useState(1);
  const [preConditionData, setPreConditionData] = useState([]);
  const [preExeOrder, setPreExeOrder] = useState(1);
  const getPostExecutionOrder = () => {
    if (!isEmptyValue(postConditionData)) {
      setPostExeOrder(postConditionData?.length + 1);
    }
  };
  const getPreExecutionOrder = () => {
    if (!isEmptyValue(preConditionData)) {
      setPreExeOrder(preConditionData?.length + 1);
    }
  };
  useEffect(() => {
    getPostExecutionOrder();
  }, [postConditionData?.length]);

  useEffect(() => {
    getPreExecutionOrder();
  }, [preConditionData?.length]);
  const setCountPill = (obj) => {
    if (obj.type === 'PreCondition') {
      setPreCondition(obj.value);
    }
    if (obj.type === 'PostCondition') {
      setPostCondition(obj.value);
    }
  };
  return (
    <div className="page-table-body">
      <div className="content_area_style">
        <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
          <label className="project_label flex-auto">Pre-Post Conditions</label>
          <div className="pr-4">
            <div className="flex flex-row ml-32">
              <div className="ml-auto pl-4">
                {global.permission.isEditAccess('testDevelopment') && (
                  <button
                    disabled={disableCreate}
                    onClick={() => {
                      setCreateCalled(true);
                      if (activeTab === 'Pre Condition') {
                        getPreExecutionOrder();
                      } else if (activeTab === 'Post Condition') {
                        getPostExecutionOrder();
                      }
                      setTimeout(() => {
                        setCreateCalled(false);
                      }, 200);
                    }}
                    className="ml-3 primary-btn"
                  >
                    +{' '}
                    {!activeTab ? 'Pre Condition' : activeTab === 'Pre Condition' ? 'Pre Condition' : 'Post Condition'}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="pre-post-bordr-style-common pt-2">
          <label className="">
            <Link
              className={
                activeTab === 'Pre Condition'
                  ? '  ml-10 selected_pre_post_common  mb-px '
                  : 'pre_post_style_common  ml-10 pb-px'
              }
              to={`${path}/Pre Condition?moduleId=${moduleId}`}
              onClick={() => {
                DisableCreateButton(false);
              }}
            >
              Pre Conditions
            </Link>
          </label>
          <label
            className={`${
              activeTab === 'Post Condition' ? ' selected_pre_post_common' : 'pre_post_style_common '
            }  ml-8 mb-px`}
          >
            <Link
              to={`${path}/Post Condition?moduleId=${moduleId}`}
              onClick={() => {
                DisableCreateButton(false);
              }}
            >
              Post Conditions
            </Link>
            {/* <span class="proj-count-style  px-2 py-0.5 ml-2 font-bold rounded-lg">{postCondition}</span> */}
          </label>
        </div>
        <div className="table-height overflow-auto" id="journal-scroll">
          <Switch>
            <Route
              path={`${parentPath.slice(0, parentPath.lastIndexOf('/'))}/:moduleName/Settings/Pre Condition`}
              exact={true}
              strict={true}
              key="pre-condition-page"
            >
              <PreCondition
                MyAlert={props.MyAlert}
                DisableCreateButton={DisableCreateButton}
                setCountPill={setCountPill}
                createCalled={createCalled}
                setPreConditionData={setPreConditionData}
                preExeOrder={preExeOrder}
                setPreExeOrder={setPreExeOrder}
              />
            </Route>
            <Route
              path={`${parentPath.slice(0, parentPath.lastIndexOf('/'))}/:moduleName/Settings/Post Condition`}
              exact={true}
              strict={true}
              key="post-condition-page"
            >
              <PostCondition
                MyAlert={props.MyAlert}
                DisableCreateButton={DisableCreateButton}
                setCountPill={setCountPill}
                createCalled={createCalled}
                setPostConditionData={setPostConditionData}
                postExeOrder={postExeOrder}
                setPostExeOrder={setPostExeOrder}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default ModulePrePostConditionRouter;
