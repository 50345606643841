import React, { useEffect } from 'react';
import cx from 'classnames';
import styles from './fileUpload.module.scss';
import { Tooltip } from '@material-ui/core';
import CommonButton from '@src/common/button/Button';
import { FILE_UPLOAD_BUTTON_CONSTANTS } from '@src/common/ui-constants';

export default function FileUploadButton({
  showBtn,
  setShowBtn,
  value,
  onChange,
  id,
  fileRemoved = () => {},
  fromFileDragAndDrop,
  dragDropFilesLength,
  setLocalFileName = () => {},
  setLocalFileError = () => {},
  localFileEvent = {},
  webServiceUploadBtn,
  importManualDragAndDrop,
  dragAndDrop,
  ...rest
}) {
  const { disabled } = rest;
  const handelClick = () => {
    document.getElementById(id).value = null;
    setShowBtn(false);
    fileRemoved();
    setLocalFileName('');
    setLocalFileError('');
  };
  const uploadBtnClick = (event) => {
    event.preventDefault();
  };

  const buttonContent =
    fromFileDragAndDrop && value
      ? FILE_UPLOAD_BUTTON_CONSTANTS.REPLACE_FILE
      : value && showBtn
      ? FILE_UPLOAD_BUTTON_CONSTANTS.REPLACE_FILE
      : FILE_UPLOAD_BUTTON_CONSTANTS.CHOOSE_FILE;

  useEffect(() => {
    if (value) {
      if (!localFileEvent) setLocalFileName(localFileEvent);
      setShowBtn(true);
    }
  }, []);

  return (
    <>
      <div className={cx(styles[webServiceUploadBtn ? 'file-upload-container-for-website' : 'file-upload-container'])}>
        {webServiceUploadBtn ? (
          <CommonButton
            btnType={webServiceUploadBtn ? 'webServiceUpload' : 'upload'}
            disabled={disabled}
            label={buttonContent}
          />
        ) : (
          <button
            onClick={uploadBtnClick}
            className={cx(dragAndDrop ? styles['drag-file-btn'] : styles['file-upload-btn'], disabled && 'opacity-50')}
          >
            {rest?.called === 'image-Data' ? rest?.placeholder : buttonContent}
          </button>
        )}
        {importManualDragAndDrop ? null : value && showBtn ? (
          <>
            <span
              title={rest?.testDataFilesLength > 0 && rest?.called === 'test-Data' ? null : value}
              className={cx(styles['upload-file'])}
            >
              {value && value?.length >= 15 ? value.substring(0, 15) + '...' : value}
            </span>
          </>
        ) : (rest?.testDataFilesLength > 0 && rest?.called === 'test-Data') || dragDropFilesLength ? null : (
          rest?.called !== 'image-Data' && (
            <span className={cx(styles['choose-file'], 'cursor-default')}>
              {FILE_UPLOAD_BUTTON_CONSTANTS.NO_FILE_CHOSEN}
            </span>
          )
        )}
        <input
          type="file"
          disabled={disabled}
          id={id}
          onChange={(e) => {
            onChange(e);
          }}
          {...rest}
          className={cx(
            styles['file-upload-hidden-input'],
            value && showBtn
              ? styles['file-upload-hidden-input-r-width']
              : importManualDragAndDrop
              ? 'w-24'
              : styles['file-upload-hidden-input-c-width']
          )}
          title=""
        />
        {importManualDragAndDrop
          ? null
          : value &&
            showBtn && (
              <span className="ml-2">
                <Tooltip title="Remove file" placement="bottom">
                  <button
                    type="button"
                    onClick={handelClick}
                    disabled={disabled}
                    className="float-right rounded-md text-blue-500 hover:text-gray-500 focus:outline-none "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3 w-3 z-100"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </Tooltip>
              </span>
            )}
      </div>
    </>
  );
}
