import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { Formik, useFormik, Form as BaseForm } from 'formik';
import * as yup from 'yup';
import { FormControl, InputLabel, NativeSelect, Switch, TextField, withStyles } from '@material-ui/core';
import { cascadeConditionToScriptReq, updateModulePrePostConditionReq, getStepGroupReq } from '@api/api_services';
import { ifFailedOptionsModuleLevel } from '../utils/constants';
import { Tooltip } from '@material-ui/core';
import VariableSuggestion from '@src/pages/test-development/script/scripts/steps/VariableSuggestion';
import { isEmptyValue, getTruncatedText, isEmptyObject } from '@src/util/common_utils';
import { Button, Icon, Toggle } from 'fireflink-ui';
import { LABELS_CONSTANTS, EDIT_PRE_POST_CONDITION } from '@src/common/ui-constants';

const EditPrePostCondition = ({ data, edit, closeModal, reload, MyAlert, updatedValue, moduleName }) => {
  const [stepInputs, setStepInputs] = useState([]);
  const [onCancelReloadRequired, setOnCancelReloadRequired] = useState(false);
  const [stepGroupResponse, setStepGroupResponse] = useState({});
  const [returnTypeReference, setReturnTypeReference] = useState();
  const [returnValuePayload, setReturnValuePayload] = useState();
  const [initialValue, setInitialValue] = useState();
  const [validationsSchema, setValidationsSchema] = useState();
  const [apiCompleteFlag, setApiCompleteFlag] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [editData, setEditData] = useState(data);
  const [isDisable, setIsDisable] = useState(false);
  const [formReload, setFormReload] = useState(false);

  const formRef = useRef();

  const customStylesPage = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '640px',
      height: 'fit-content',
      background: '#fbfcfd 0% 0% no-repeat padding-box',
      boxShadow: '0px 0px 11px #000000a1',
      borderRadius: '4px',
      opacity: '1',
    },
  };

  const initialValues = {
    name: editData.stepGroupName,
    type: editData.platform.toUpperCase(),
    status: editData.status.toLowerCase() === 'enabled',
    cascade: editData.cascaded.toLowerCase() === 'cascaded',
    ifFailed: editData.ifCheckPointIsFailed,
    mustExecute: editData.mustExecute,
  };

  useEffect(() => {
    setStepInputs(data.stepInputs);
    getSteps(data);
  }, [data, edit]);

  useEffect(() => {
    if (apiCompleteFlag) {
      handleInitialization();
    }
    setApiCompleteFlag(false);
  }, [apiCompleteFlag]);

  useEffect(() => {
    getSteps(data);
  }, []);

  const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    type: yup.string().required('Type is required'),
    ifFailed: yup.string(),
  });

  const setVariableValue = (data, stepInputName, type) => {
    const stepInputData = [...stepInputs];
    stepInputData?.forEach((stepInput) => {
      if (stepInput.name === stepInputName) {
        if (type === 'variable') {
          stepInput.value = data.name;
          stepInput.reference = data?.reference;
        } else {
          stepInput.value = data;
          stepInput.reference = null;
        }
      }
    });
    setStepInputs(stepInputData);
  };

  const initizationForFormik = (dataValue, type) => {
    let valuesForInitilization = {};
    let validationfield = [];
    const stepInputs = dataValue.stepInputs;
    const addValidationField = (name) => {
      validationfield.push({
        id: name,
        type: 'text',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: [`${name} is required`],
          },
        ],
      });
    };

    if (!isEmptyValue(stepInputs)) {
      stepInputs.forEach((stepInput, index) => {
        const { name, value, reference } = stepInput;
        if (type === 'update') {
          valuesForInitilization[name] = value || '';
        } else {
          valuesForInitilization[name] = (reference && value) || '';
        }
        addValidationField(name);
      });
    }

    if (dataValue.returnType !== 'void' && type === 'create') {
      valuesForInitilization['returnValue'] = '';
      addValidationField('returnValue');
    } else if (dataValue.stepGroupReferenceInfo && type === 'update') {
      const stepReferenceInfo = dataValue.stepGroupReferenceInfo;
      setReturnTypeReference(stepReferenceInfo.type);
      valuesForInitilization['returnValue'] = stepReferenceInfo.name;
      addValidationField('returnValue');
    } else if (dataValue.returnType !== 'void' && type === 'update') {
      valuesForInitilization['returnValue'] = '';
      addValidationField('returnValue');
    }

    if (valuesForInitilization && !isEmptyValue(validationfield)) {
      const yepSchema = validationfield.reduce(createYupSchema, {});
      setInitialValue(valuesForInitilization);
      setValidationsSchema(yup.object().shape(yepSchema));
    } else {
      setInitialValue({});
      setValidationsSchema({});
    }
  };

  const createYupSchema = (schema, config) => {
    const { id, validationType, validations = [] } = config;
    if (!yup[validationType]) {
      return schema;
    }
    let validator = yup[validationType]();
    validations.forEach((validation) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });
    schema[id] = validator;
    return schema;
  };

  async function getSteps(value) {
    try {
      const libId = value.libraryId;
      const stepGrpId = value?.stepGroupId || value?.parentId;
      const res = await getStepGroupReq(libId, stepGrpId);
      if (res.data && res.data.responseObject) {
        setStepGroupResponse(res.data.responseObject);
        setApiCompleteFlag(true);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleInitialization = () => {
    let returnType;
    let stepRefData;

    if (stepGroupResponse.returnType === 'void') {
      returnType = 'void';
      stepRefData = null;
    } else if (stepGroupResponse.returnType !== 'void' && data?.returnType !== 'void' && edit) {
      returnType = data?.returnType;
      stepRefData = data?.stepReferenceInfo;
    } else {
      returnType = stepGroupResponse.returnType;
      stepRefData = null;
    }

    setReturnValuePayload({
      returnType: returnType,
      stepGroupReferenceInfo: stepRefData,
    });

    const stepValues = {
      stepInputs: stepInputs,
      returnType: returnType,
      stepGroupReferenceInfo: stepRefData,
    };

    if (edit) {
      initizationForFormik(stepValues, 'update');
      return;
    }
    initizationForFormik(stepValues, 'create');
  };

  const setReturnValue = (data, stepInputName, type) => {
    setReturnTypeReference(type);
    setReturnValuePayload((previousValue) => ({
      returnType: previousValue.returnType,
      stepGroupReferenceInfo: {
        type: type,
        name: data?.name,
      },
    }));
  };

  const removeStepRef = () => {
    if (returnValuePayload.stepGroupReferenceInfo) {
      setReturnTypeReference();
      setReturnValuePayload((previousValue) => ({
        returnType: previousValue.returnType,
      }));
    }
  };

  const onCancelReloadStateHandler = () => {
    setOnCancelReloadRequired(true);
  };

  const updatePrePostCondition = async (data) => {
    try {
      await cascadeConditionToScriptReq(editData.parentId.slice(editData.parentId.indexOf('_') + 1), data);
      const response = await updateModulePrePostConditionReq(
        editData.parentId.slice(editData.parentId.indexOf('_') + 1),
        data.id,
        data
      );
      if (response.data && response.data.responseObject) {
        const updateData = {
          ...response.data.responseObject,
          name: response.data.responseObject.stepGroupName,
        };

        updatedValue(updateData);
        MyAlert.success(`${getTruncatedText(data.stepGroupName, 50)} updated successfully`);
      } else {
        MyAlert.warning(`${response.data.message}`);
      }
      closeModal(false);
    } catch (err) {
      console.error(err);
    }
  };
  const onSubmit = (values) => {
    let requestBody;
    if (values) {
      requestBody = {
        ...editData,
        id: editData._key,
        mustExecute: values.mustExecute,
        stepGroupName: values.name,
        cascaded: values.cascade ? 'cascaded' : 'not_cascaded',
        moduleName: values.cascade ? moduleName : '',
        cascadedFrom: values.cascade ? editData.parentId.slice(editData.parentId.indexOf('_') + 1) : '',
        status: values.status ? 'enabled' : 'disabled',
        ifCheckPointIsFailed: values.ifFailed,
        stepReferenceInfo: returnValuePayload.stepGroupReferenceInfo,
      };
      if (!isDisable) {
        updatePrePostCondition(requestBody);
      }
    }
  };

  const returnTouchedField = (values) => {
    let touchedFields = {};
    for (let key in values) {
      if (values.hasOwnProperty(key) && !values[key]) {
        touchedFields[key] = true;
      }
    }
    return touchedFields;
  };

  useEffect(() => {
    if (formReload) {
      disableFormButton();
      setFormReload(false);
    }
  }, [formReload]);
  const handleClearField = (stepInputName) => {
    formRef.current.setFieldValue(stepInputName, '');
    setFormReload(true);
  };
  const disableFormButton = () => {
    const hasEmptyParameter = stepInputs?.some((parameter) => isEmptyValue(parameter));
    if (returnValuePayload?.hasOwnProperty('returnType') && returnValuePayload?.returnType !== 'void') {
      if (!returnValuePayload?.stepGroupReferenceInfo) {
        formRef.current.setFieldError('returnValue', 'Always use Variable for Return');
      }
    }

    if (initialValues && validationsSchema) {
      let isFieldTouched = returnTouchedField(formRef.current?.values);
      let isValid =
        !hasEmptyParameter && (returnValuePayload?.stepGroupReferenceInfo || returnValuePayload?.returnType === 'void');
      if (!isEmptyObject(isFieldTouched)) {
        formRef.current.setTouched(isFieldTouched);
        setIsDisable(true);
      } else {
        if (isValid) {
          setIsDisable(false);
        } else {
          setIsDisable(true);
        }
      }
    } else {
      setIsDisable(true);
    }
  };

  useEffect(() => {
    disableFormButton();
  }, [returnValuePayload, stepInputs, onCancelReloadRequired]);

  const changeToggle = (name, event) => {
    formikDetails.setFieldValue(name, event.target.checked);
  };
  const formikDetails = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <Modal
      isOpen={openModal}
      style={customStylesPage}
      className="p-px"
      onRequestClose={() => {
        closeModal(false);
        setOpenModal(false);
      }}
    >
      <div className="mt-2 p-1">
        <span className="Popup-header-common pl-3">
          <Tooltip title={editData?.stepGroupName} placement="right">
            <span className="ml-1"> Edit Step Group - {getTruncatedText(editData?.stepGroupName, 30)}</span>
          </Tooltip>
        </span>
        <div className="float-right">
          <Icon
            name="toast_close_icon_defult"
            className="mr-2 cursor-pointer"
            onClick={() => {
              setOpenModal(false);
              closeModal(false);
              reload(true);
            }}
            hoverEffect={true}
          />
        </div>
      </div>
      <form onSubmit={formikDetails.handleSubmit}>
        <div className="test-dev-modal pre-post-modal">
          <div className="border-b border-t border-blue-100 mt-px mb-px pb-3">
            <div className="overflow-y-auto test-dev-popup" id="journal-scroll">
              <div className="ml-6 mr-6 mt-3 grid grid-cols-2 gap-4">
                <div className="w-4/5 mt-4">
                  <TextField
                    disabled={true}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="text"
                    autoComplete="off"
                    name="name"
                    id="name"
                    onBlur={formikDetails.handleBlur}
                    onChange={formikDetails.handleChange}
                    value={formikDetails?.values?.name}
                    label={
                      <>
                        {' '}
                        <span className="text-red-400">*</span> Name
                      </>
                    }
                  />
                </div>

                <div className="w-4/5 float-right ml-auto p-1 mt-4">
                  <FormControl className="w-full" disabled={true}>
                    <InputLabel shrink htmlFor="type" className="mb-px">
                      <span className="text-red-400">&#42;</span> Type
                    </InputLabel>
                    {
                      <NativeSelect
                        className=""
                        name="type"
                        id="type"
                        value={formikDetails?.values?.type}
                        onBlur={formikDetails.handleBlur}
                        onChange={formikDetails.handleChange}
                      >
                        <option className="pl-px" value={editData.platform}>
                          {editData.platform.toUpperCase()}
                        </option>
                      </NativeSelect>
                    }
                  </FormControl>
                </div>
                <div className="flex mt-4 w-full col-span-2 space-x-4">
                  <div>
                    <label htmlFor="status" className={'input-filed-label-style-common block'}>
                      {EDIT_PRE_POST_CONDITION.STATUS}
                    </label>
                    <Toggle
                      disableLabel={EDIT_PRE_POST_CONDITION.DISABLED}
                      enableLabel={EDIT_PRE_POST_CONDITION.ENABLED}
                      id="status"
                      name="status"
                      checked={formikDetails?.values?.status}
                      onChange={(e) => changeToggle('status', e)}
                      label="with adjacent label"
                      type="withIcon"
                      variant="primary"
                    />
                  </div>

                  {data?.type === 'POST' && (
                    <div>
                      <label htmlFor="mustExecute" className={'input-filed-label-style-common block'}>
                        {EDIT_PRE_POST_CONDITION.MUST_EXECUTE}
                      </label>
                      <Toggle
                        disableLabel={EDIT_PRE_POST_CONDITION.DISABLED}
                        enableLabel={EDIT_PRE_POST_CONDITION.ENABLED}
                        id="mustExecute"
                        name="mustExecute"
                        checked={formikDetails.values.mustExecute}
                        onChange={(e) => changeToggle('mustExecute', e)}
                        label="with adjacent label"
                        type="withIcon"
                        variant="primary"
                      />
                    </div>
                  )}

                  <div className={`${data?.type !== 'POST' ? 'flex-grow' : ''}`}>
                    <label htmlFor="status" className={'input-filed-label-style-common block'}>
                      {EDIT_PRE_POST_CONDITION.CASCADE}
                    </label>
                    <Toggle
                      disableLabel={EDIT_PRE_POST_CONDITION.NOT_CASCADED}
                      enableLabel={EDIT_PRE_POST_CONDITION.CASCADED}
                      id="cascade"
                      name="cascade"
                      checked={formikDetails.values.cascade}
                      onChange={(e) => changeToggle('cascade', e)}
                      label="with adjacent label"
                      type="withIcon"
                      variant="primary"
                    />
                  </div>
                </div>

                {initialValue && validationsSchema ? (
                  <Formik
                    initialValues={initialValue}
                    validationSchema={validationsSchema}
                    innerRef={formRef}
                    enableReinitialize={true}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleReset,
                      setTouched,
                      setFieldValue,
                      setFieldError,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                    }) => {
                      return (
                        <BaseForm>
                          <div>
                            <div>
                              {!isEmptyValue(stepInputs) && (
                                <div className="mt-3 grid grid-cols-2 gap-4 input-parameter-container">
                                  {stepInputs?.map(
                                    ({ name = '', type = '', value = '', reference = '' }, inputIndex) => {
                                      return (
                                        <div key={name} className="mt-2">
                                          <div>
                                            <VariableSuggestion
                                              reloadStepsTable={onCancelReloadStateHandler}
                                              createVariableFromModuleCondition={true}
                                              stepInputType={type}
                                              moduleId={data?.moduleId}
                                              id={'variables' + inputIndex}
                                              stepInputName={name}
                                              setVariableValue={setVariableValue}
                                              editData={value}
                                              type={data?.type}
                                              reference={reference}
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                              setFieldValue={setFieldValue}
                                              buttonDisable={disableFormButton}
                                              onClearField={handleClearField}
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </div>
                            {stepGroupResponse.returnType ? (
                              stepGroupResponse.returnType !== 'void' ? (
                                <div className="mt-2">
                                  <div className="ml-7 mt-3">
                                    {
                                      <VariableSuggestion
                                        reloadStepsTable={onCancelReloadStateHandler}
                                        moduleId={data?.moduleId}
                                        id={'return-value'}
                                        createVariableFromModuleCondition={true}
                                        stepInputType={stepGroupResponse.returnType}
                                        stepInputName={'returnValue'}
                                        setVariableValue={setVariableValue}
                                        editData={returnValuePayload?.stepGroupReferenceInfo?.name}
                                        type={data?.type}
                                        reference={returnTypeReference}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        setFieldValue={setFieldValue}
                                        removeStepRef={removeStepRef}
                                        setReturnValue={setReturnValue}
                                        buttonDisable={disableFormButton}
                                        onClearField={handleClearField}
                                      />
                                    }
                                  </div>
                                </div>
                              ) : (
                                ''
                              )
                            ) : (
                              ''
                            )}
                          </div>
                        </BaseForm>
                      );
                    }}
                  </Formik>
                ) : null}
                <div className="col-span-2 mt-4">
                  <FormControl className="w-full">
                    <InputLabel shrink htmlFor="ifFailed">
                      <span className="text-red-400">&#42;</span>{' '}
                      <span className={formikDetails.errors.type && formikDetails.touched.type ? 'text-red-400' : null}>
                        If Failed
                      </span>
                    </InputLabel>
                    {
                      <NativeSelect
                        className=""
                        name="ifFailed"
                        id="ifFailed"
                        value={formikDetails.values.ifFailed}
                        onBlur={formikDetails.handleBlur}
                        onChange={formikDetails.handleChange}
                      >
                        {ifFailedOptionsModuleLevel?.map((values) => (
                          <option className="" value={values.value}>
                            {values.label}
                          </option>
                        ))}
                      </NativeSelect>
                    }
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="float-right my-3 mx-5">
          <Button
            label={LABELS_CONSTANTS.CANCEL}
            variant="secondary"
            onClick={() => {
              closeModal(false);
              reload(true);
            }}
            className="mr-3"
          />
          <Button disabled={isDisable} label={LABELS_CONSTANTS.UPDATE} variant="primary" type="submit" />
        </div>
      </form>
    </Modal>
  );
};

export default EditPrePostCondition;
