import { Popup } from 'fireflink-ui';
import { getUserName, handleExportClick } from '@src/util/common_utils';
import { LABELS_CONSTANTS } from '@common/ui-constants';

const InfoPopupForExport = ({ isInfoPopupOpen, setIsInfoPopupOpen, project, MyAlert }) => {
  return (
    <Popup
      isOpen={isInfoPopupOpen}
      type="info"
      headerTitle="Info!"
      continueButtonLabel="Export"
      cancelButtonLabel="Cancel"
      onContinueClick={() => handleExportClick(project, setIsInfoPopupOpen, MyAlert)}
      onCancelClick={() => setIsInfoPopupOpen(false)}
    >
      <div>
        <p className="fontPoppinsRegularMd">Hi {getUserName()},</p>
        <p className="fontPoppinsRegularMd mt-4">
          Are you sure you want to export the resources of the {project?.name}?
        </p>
        <p className="fontPoppinsRegularMd">{LABELS_CONSTANTS.PROCEED}</p>
      </div>
    </Popup>
  );
};

export default InfoPopupForExport;
