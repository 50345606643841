import React, { Fragment, useMemo } from 'react';
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table';
import matchSorter from 'match-sorter';

import { FaStopCircle } from 'react-icons/fa';
import { Select, Tooltip } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import UserDetailsPage from '../../common/user-details-page';
import {
  cancelExecutionReq,
  deleteExecutionDataReq,
  getExecutionMachineDetailsReq,
  getSuiteSpecificExeutionReq,
  reExecuteSuiteReq,
  terminateExecutionReq,
  terminateExecutionSuiteReq,
} from '@api/api_services';
import MachineDetails from '@pages/test-development/execution-dashboard/modals/machine-details';
import { Delete, SearchOutlined, Sync } from '@material-ui/icons';
import { useHistory } from 'react-router';

const SuiteOfSuiteDashboard = ({ MyAlert, ...props }) => {
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const [dashboardData, setDashboardData] = React.useState([]);
  let deleteOption = ['Delete all.', 'Delete only screenshots.', 'Delete only videos.'];
  const [userData, setModifiedId] = React.useState();
  let history = useHistory();
  const { hasUserViewAccessInExecution } = useMemo(
    () => ({
      hasUserViewAccessInExecution: window.permission?.isOnlyViewAccess('execution'),
    }),
    []
  );

  function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);

    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);

    return (
      <span>
        Search:{' '}
        <input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`Type here...`}
          style={{
            fontSize: '1.1rem',
            border: '0',
          }}
        />
      </span>
    );
  }

  // Define a default UI for filtering
  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search...`}
      />
    );
  }

  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  // This is a custom filter UI that uses a
  // slider to set the filter value between a column's
  // min and max values
  function SliderColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      preFilteredRows.forEach((row) => {
        min = Math.min(row.values[id], min);
        max = Math.max(row.values[id], max);
      });
      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <>
        <input
          type="range"
          min={min}
          max={max}
          value={filterValue || min}
          onChange={(e) => {
            setFilter(parseInt(e.target.value, 10));
          }}
        />
        <button onClick={() => setFilter(undefined)}>Off</button>
      </>
    );
  }

  // This is a custom UI for our 'between' or number range
  // filter. It uses two number boxes and filters rows to
  // ones that have values between the two
  function NumberRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
    const [min, max] = useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      preFilteredRows.forEach((row) => {
        min = Math.min(row.values[id], min);
        max = Math.max(row.values[id], max);
      });
      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <input
          value={filterValue[0] || ''}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
          }}
          placeholder={`Min (${min})`}
          style={{
            width: '70px',
            marginRight: '0.5rem',
          }}
        />
        to
        <input
          value={filterValue[1] || ''}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
          }}
          placeholder={`Max (${max})`}
          style={{
            width: '70px',
            marginLeft: '0.5rem',
          }}
        />
      </div>
    );
  }

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  function executeFunction(obj) {
    reExecuteSuiteReq(obj?._id).then((results) => {
      const response = results?.data?.responseObject;
      if (response) {
        getAllDashboard();
      } else if (results?.data?.message && results?.data?.message.toLowerCase().includes('please connect a device')) {
        MyAlert.info('Device is not available. Please connect a device');
      } else if (results?.data?.message && results?.data?.message.toLowerCase().includes('please install a browser')) {
        MyAlert.info(`${results?.data?.message}`);
      } else if (
        results?.data?.message &&
        results?.data?.message.startsWith('Client') &&
        results?.data?.message.toLowerCase().includes('is not available')
      ) {
        MyAlert.info(`${results?.data?.message}`);
      } else {
        console.error(results?.data?.message);
      }
    });
  }

  function cancelExecutionFunction(obj) {
    let statusObj = {
      executionStatus: 'Cancelled',
    };

    cancelExecutionReq(obj?._id, statusObj).then((results) => {
      const response = results?.data?.responseObject;
      if (response) {
        getAllDashboard();
        MyAlert.success(`${obj?._id} Suite Cancelled successfully`);
      } else {
        console.error(results?.data?.message);
      }
    });
  }

  function terminateFunction(obj) {
    terminateExecutionSuiteReq(obj?._id).then((results) => {
      const response = results?.data?.message;
      if (response === 'SUCCESS') {
        MyAlert.success(`${obj?._id} Suite Terminated successfully`);
        getAllDashboard();
      } else {
        console.error(results?.data?.message);
      }
    });
  }

  function closeMachineDetailsModal() {
    setShowModal(false);
    setOperation();
  }

  const getMachineDetails = async (data, execStatus, resultStatus) => {
    try {
      if (data) {
        let reqData = {
          machineName: data?.sysData?.machineInfo?.hostName,
          os: data?.sysData?.machineInfo?.osName,
          browser: data?.sysData?.browserName,
          device:
            data?.sysData?.deviceInfo[0] && data?.sysData?.deviceInfo[0]?.name
              ? data.sysData.deviceInfo[0]?.name
              : '--',
          status: data?.status,
          owner: data?.owner,
        };

        getExecutionMachineDetailsReq(data.sysData.clientId).then((res) => {
          if (res.data && res.data.responseObject) {
            reqData.executions = res.data.responseObject;
          } else {
            reqData.executions = [];
          }
          setSelectedSystem(reqData);
          openModalfun('systemDetails');
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  function navigateToModuleListpage(e) {
    let exeId = e?.data[e?.row?.id]._id;
    const exec_name = window.location.pathname.split('/')[3];
    history.push(
      `/execution/suiteofsuite/${exec_name}/dashboard/${exeId}?suiteId=${
        e?.data[e?.row?.id].suiteOfSuiteId
      }&detailsPage=moduleDetails_sos`
    );
  }

  React.useEffect(() => {
    getAllDashboard();
  }, []);

  function getAllDashboard() {
    let search = window.location.search;
    let urlSuiteId = new URLSearchParams(search).get('suiteId');
    getSuiteSpecificExeutionReq(project.id, urlSuiteId, 'SUITE_OF_SUITES')
      .then((results) => {
        const response = results?.data?.responseObject;
        if (response) {
          // setIsLoaded(true);
          response.map((res, i) => {
            // stateIndex = i;

            res.Actions = [];
            if (res.executionStatus === 'Terminated') {
              res['executionStatusTemp'] = res.executionStatus;
              res.Actions.push(
                <span className="flex flex-row project-action delete-icon-style mr-7">
                  {!hasUserViewAccessInExecution && (
                    <img
                      src="\assets\execution_logos\execution.svg"
                      title="Run"
                      className="flex flex-row icon-blue-style float-left cursor-hand mr-3 "
                      height="17px"
                      width="17px"
                      onClick={() => executeFunction(res)}
                      alt="Run"
                    />
                  )}
                  {/* <img src="\assets\execution_logos\edit.svg" className="flex flex-row icon-blue-style float-left cursor-hand mr-3" height="17px" width="17px"
                                    title="Edit" onClick={() => { setSelectedSuite(res?.suite); editSuiteFun(res); }}></img> */}
                  <img
                    src="\assets\execution_logos\delete.svg"
                    className="flex flex-row icon-blue-style float-left cursor-hand "
                    height="17px"
                    width="17px"
                    onClick={() => {
                      setOpen(true);
                      setSelectedDataObj(res);
                    }}
                    title="Delete"
                    alt="Delete"
                  />
                </span>
              );
            } else if (res.executionStatus === 'Pending') {
              res['executionStatusTemp'] = res.executionStatus;
              res.Actions.push(
                <span className="flex flex-row project-action delete-icon-style mr-10">
                  <img
                    src="\assets\execution_logos\cancel.svg"
                    className="flex flex-row icon-blue-style float-left cursor-hand mr-2"
                    height="17px"
                    width="17px"
                    title="Cancel"
                    onClick={() => cancelExecutionFunction(res)}
                  ></img>
                  {/* <img src="\assets\execution_logos\edit.svg" className="flex flex-row icon-blue-style float-left cursor-hand mr-2  " height="17px" width="17px"
                                    title="Edit" onClick={() => { setSelectedSuite(res?.suite); editSuiteFun(res); }}></img> */}
                  <img
                    src="\assets\execution_logos\deleteDisable.svg"
                    className="flex flex-row icon-blue-style float-left cursor-hand "
                    height="17px"
                    width="17px"
                    title="Delete"
                  ></img>
                </span>
              );
            } else if (res.executionStatus === 'Running' && !hasUserViewAccessInExecution) {
              res['executionStatusTemp'] = res.executionStatus;
              res.Actions.push(
                <span className="flex flex-row project-action delete-icon-style mr-10">
                  {/* <Tooltip title="Terminate">
                                <FaStopCircle className="FaStop-style float-left cursor-hand" onClick={() => terminateFunction(res)} />
                            </Tooltip> */}
                  <img
                    src="\assets\execution_logos\terminate.svg"
                    title="Terminate"
                    className="flex flex-row icon-blue-style float-left cursor-hand ml-1 mr-2"
                    height="17px"
                    width="17px"
                    onClick={() => terminateFunction(res)}
                    alt="Terminate"
                  />
                  {/* <img src="\assets\execution_logos\edit.svg" className="flex flex-row icon-blue-style float-left cursor-hand mr-3" height="17px" width="17px"
                                    title="Edit" onClick={() => { setSelectedSuite(res?.suite); editSuiteFun(res); }}></img> */}
                  <img
                    src="\assets\execution_logos\deleteDisable.svg"
                    className="flex flex-row icon-blue-style float-left cursor-hand mr-3"
                    height="17px"
                    width="17px"
                    title="Delete"
                    alt="Delete"
                  />
                </span>
              );
            } else if (res.executionStatus === 'Aborted') {
              res['executionStatusTemp'] = res.executionStatus;
              res.Actions.push(
                <span className="flex flex-row project-action delete-icon-style mr-14">
                  {/* <img src="\assets\execution_logos\edit.svg" className="flex flex-row icon-blue-style float-left cursor-hand mr-3" height="17px" width="17px"
                                    title="Edit" onClick={() => { setSelectedSuite(res?.suite); editSuiteFun(res); }}></img> */}
                  <img
                    src="\assets\execution_logos\delete.svg"
                    className="flex flex-row icon-blue-style float-left cursor-hand mr-3"
                    height="17px"
                    width="17px"
                    onClick={() => {
                      setOpen(true);
                      setSelectedDataObj(res);
                    }}
                    title="Delete"
                    alt="Delete"
                  />
                </span>
              );
            } else if (res.executionStatus === 'Cancelled') {
              res['executionStatusTemp'] = res.executionStatus;
              res.Actions.push(
                <span className="flex flex-row project-action delete-icon-style mr-7">
                  {!hasUserViewAccessInExecution && (
                    <img
                      src="\assets\execution_logos\execution.svg"
                      title="Run"
                      className="flex flex-row icon-blue-style float-left cursor-hand mr-2"
                      height="17px"
                      width="17px"
                      onClick={() => executeFunction(res)}
                      alt="Run"
                    />
                  )}
                  {/* <img src="\assets\execution_logos\edit.svg" className="flex flex-row icon-blue-style float-left cursor-hand mr-2" height="17px" width="17px"
                                    title="Edit" onClick={() => { setSelectedSuite(res?.suite); editSuiteFun(res); }}></img> */}
                  <img
                    src="\assets\execution_logos\delete.svg"
                    className="flex flex-row icon-blue-style float-left cursor-hand mr-2"
                    height="17px"
                    width="17px"
                    onClick={() => {
                      setOpen(true);
                      setSelectedDataObj(res);
                    }}
                    title="Delete"
                    alt="Delete"
                  />
                </span>
              );
            } else if (res.executionStatus === 'Completed') {
              if (res.resultStatus === 'WARNING') {
                res['executionStatusTemp'] = 'Warning';
                res.Actions.push(
                  <div className="flex flex-row delete-icon-style display-flex-class mr-10">
                    {!hasUserViewAccessInExecution && (
                      <img
                        src="\assets\execution_logos\execution.svg"
                        title="Run"
                        className="flex flex-row icon-blue-style float-left cursor-hand mr-3"
                        height="17px"
                        width="17px"
                        onClick={() => executeFunction(res)}
                        alt="Run"
                      />
                    )}
                    {/* <img src="\assets\execution_logos\edit.svg" className="flex flex-row icon-blue-style float-left cursor-hand mr-3" height="17px" width="17px"
                                        title="Edit" onClick={() => { setSelectedSuite(res?.suite); editSuiteFun(res); }}></img> */}
                    <img
                      src="\assets\execution_logos\delete.svg"
                      className="flex flex-row icon-blue-style float-left cursor-hand mr-3"
                      height="17px"
                      width="17px"
                      onClick={() => {
                        setOpen(true);
                        setSelectedDataObj(res);
                      }}
                      title="Delete"
                      alt="Delete"
                    />
                    {/* <DeleteOutlinedIcon className=" icon-blue-style ml-2"  /> */}
                  </div>
                );
              } else if (res.resultStatus === 'Aborted') {
                res['executionStatusTemp'] = res.resultStatus;
                res.Actions.push(
                  <span className="flex flex-row project-action delete-icon-style">
                    {/* <img src="\assets\execution_logos\edit.svg" className="flex flex-row icon-blue-style float-left cursor-hand mr-3" height="17px" width="17px"
                                        title="Edit" onClick={() => { setSelectedSuite(res?.suite); editSuiteFun(res); }}></img> */}
                    <img
                      src="\assets\execution_logos\delete.svg"
                      className="flex flex-row icon-blue-style float-left cursor-hand mr-3"
                      height="17px"
                      width="17px"
                      onClick={() => {
                        setOpen(true);
                        setSelectedDataObj(res);
                      }}
                      title="Delete"
                      alt="Delete"
                    />
                  </span>
                );
              } else if (res.resultStatus === 'FAIL' || res.resultStatus === 'SKIP') {
                res['executionStatusTemp'] = 'Failed';
                res.Actions.push(
                  <div className="flex flex-row delete-icon-style display-flex-class">
                    {/* <img src="\assets\execution_logos\Rerun.svg" title="Rerun" className="flex flex-row icon-blue-style float-left cursor-hand mr-3" height="17px" width="17px"
                                        onClick={() => reRunFailed(res)}></img> */}
                    {!hasUserViewAccessInExecution && (
                      <img
                        src="\assets\execution_logos\execution.svg"
                        title="Run"
                        className="flex flex-row icon-blue-style float-left cursor-hand mr-3"
                        height="17px"
                        width="17px"
                        onClick={() => executeFunction(res)}
                        alt="Run"
                      />
                    )}
                    {/* <img src="\assets\execution_logos\edit.svg" className="flex flex-row icon-blue-style float-left cursor-hand mr-3" height="17px" width="17px"
                                        title="Edit" onClick={() => { setSelectedSuite(res?.suite); editSuiteFun(res); }}></img> */}
                    <img
                      src="\assets\execution_logos\delete.svg"
                      className="flex flex-row icon-blue-style float-left cursor-hand mr-3"
                      height="17px"
                      width="17px"
                      onClick={() => {
                        setOpen(true);
                        setSelectedDataObj(res);
                      }}
                      title="Delete"
                      alt="Delete"
                    />
                    {/* <DeleteOutlinedIcon className=" icon-blue-style ml-2"  /> */}
                  </div>
                );
              } else if (res.resultStatus === 'PASS') {
                res['executionStatusTemp'] = 'Passed';
                res.Actions.push(
                  <span className="flex flex-row project-action delete-icon-style mr-10">
                    {!hasUserViewAccessInExecution && (
                      <img
                        src="\assets\execution_logos\execution.svg"
                        title="Run"
                        className="flex flex-row icon-blue-style float-left cursor-hand mr-3"
                        height="17px"
                        width="17px"
                        onClick={() => executeFunction(res)}
                        alt="Run"
                      />
                    )}
                    {/* <img src="\assets\execution_logos\edit.svg" className="flex flex-row icon-blue-style float-left cursor-hand mr-3" height="17px" width="17px"
                                        title="Edit" onClick={() => { setSelectedSuite(res?.suite); editSuiteFun(res); }}></img> */}
                    <img
                      src="\assets\execution_logos\delete.svg"
                      className="flex flex-row icon-blue-style float-left cursor-hand mr-3"
                      height="17px"
                      width="17px"
                      onClick={() => {
                        setOpen(true);
                        setSelectedDataObj(res);
                      }}
                      title="Delete"
                      alt="Delete"
                    />
                  </span>
                );
              } else if (res.resultStatus.toLowerCase() === 'n/a') {
                res['executionStatusTemp'] = 'N/A';
                res.Actions.push(
                  <div className="flex flex-row delete-icon-style display-flex-class">
                    {!hasUserViewAccessInExecution && (
                      <img
                        src="\assets\execution_logos\execution.svg"
                        title="Run"
                        className="flex flex-row icon-blue-style float-left cursor-hand mr-3"
                        height="17px"
                        width="17px"
                        onClick={() => executeFunction(res)}
                        alt="Run"
                      />
                    )}
                    {/* <img src="\assets\execution_logos\edit.svg" className="flex flex-row icon-blue-style float-left cursor-hand mr-3" height="17px" width="17px"
                                        title="Edit" onClick={() => { setSelectedSuite(res?.suite); editSuiteFun(res); }}></img> */}
                    <img
                      src="\assets\execution_logos\delete.svg"
                      className="flex flex-row icon-blue-style float-left cursor-hand mr-3"
                      height="17px"
                      width="17px"
                      onClick={() => {
                        setOpen(true);
                        setSelectedDataObj(res);
                      }}
                      title="Delete"
                      alt="Delete"
                    />
                    {/* <DeleteOutlinedIcon className=" icon-blue-style ml-2"  /> */}
                  </div>
                );
              }
            }

            res.machineHostName = res?.suiteOfSuites.selectedMachines;
            if (res?.executionStatus === 'Completed') {
              res.machineHostNames['status'] = res?.resultStatus;
              res.machineHostName['createdByUname'] = res?.createdByUname;
            } else {
              res.machineHostName['status'] = res?.executionStatus;
              res.machineHostName['createdByUname'] = res?.createdByUname;
            }
          });
          setDashboardData((d) => response);
        }
      })

      .catch((error) => {
        console.log(error);
        // setIsLoaded(true);
        // setError(error);
      });
    return [];
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  // Our table component
  function Table({ columns, data }) {
    const filterTypes = useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );

    const defaultColumn = useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      nextPage,
      previousPage,
      state,
      visibleColumns,
      preGlobalFilteredRows,
      setGlobalFilter,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
        initialState: { pageIndex: 0, pageSize: 7 },
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useSortBy,
      usePagination
    );
    return (
      <>
        <table class="relative w-full" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr class="mt-4" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    class="sticky top-0 px-6 py-3 text-current text-xs bg-gray-100 ml-5"
                    {...column.getHeaderProps()}
                    width={column.width}
                  >
                    <span className="flex flex-row">
                      {column.render('Header')}
                      {/* Render the columns filter UI */}
                      <div>
                        <span>
                          {/* {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <AiOutlineArrowDown className="mt-1 ml-1" />
                                                            : <AiOutlineArrowUp className="mt-1 ml-1" />
                                                        : ''} */}
                        </span>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr className="project-row" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td class="px-3 py-2 text-center" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {operation === 'details' ? (
          <div className="pagecountborder" id="schedule-footer-sos-details-popup">
            <div className="float-right pr-4">
              <span className="pagecount">
                {page[0]?.index + 1} - {page[page.length - 1]?.index + 1} of {suiteArray.length}
              </span>
              <span className="pageborderleft ml-2 pl-2">
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className={canPreviousPage ? 'pagination-clickable-style ' : 'pageArrow'}
                >
                  {'<'}
                </button>{' '}
                <span className="currentpagecount ml-2 mr-2">
                  {' '}
                  <span className="pl-2 pr-2">{pageIndex + 1}</span>
                </span>
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className={canNextPage ? 'pagination-clickable-style ' : 'pageArrow'}
                >
                  {'>'}
                </button>{' '}
              </span>
            </div>
          </div>
        ) : (
          <div className="pagecountborder" id="schedule-footer-suiteofsuite">
            <div className="float-right pr-4">
              <span className="pagecount">
                {page[0]?.index + 1} - {page[page.length - 1]?.index + 1} of {dashboardData.length}
              </span>
              <span className="pageborderleft ml-2 pl-2">
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className={canPreviousPage ? 'pagination-clickable-style ' : 'pageArrow'}
                >
                  {'<'}
                </button>{' '}
                <span className="currentpagecount ml-2 mr-2">
                  {' '}
                  <span className="pl-2 pr-2">{pageIndex + 1}</span>
                </span>
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className={canNextPage ? 'pagination-clickable-style ' : 'pageArrow'}
                >
                  {'>'}
                </button>{' '}
              </span>
            </div>
          </div>
        )}
        <br />
      </>
    );
  }

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue >= filterValue;
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [],
      },
      // {
      //     Header: 'Sl.No',
      //     accessor: 'slNo',
      //     disableFilters: true,
      //     Cell: ({ value }) => {
      //         return (
      //             <div className="table-non-link-color-common float-left ml-3">{value}</div>
      //         )
      //     }
      // },
      {
        Header: 'Execution ID',
        accessor: '_id',
        disableFilters: true,
        width: '9%',
        Cell: (e) => (
          <a
            href=""
            onClick={(event) => {
              event.preventDefault();
              getData(e.cell.row.original, 'details');
            }}
            className="hyper_link_color-common float-left ml-3 dashboard-font-style-common"
          >
            {' '}
            {e.value}{' '}
          </a>
        ),
      },
      {
        Header: 'Parent ID',
        accessor: 'parentReferenceId',
        disableFilters: true,
        width: '9%',
        Cell: (e, index) => (
          <>
            <span className="table-non-link-color-common float-left ml-3 dashboard-font-style">
              {' '}
              {e?.value ? e?.value : 'N/A'}{' '}
            </span>
            {e?.data[e?.row?.id]?.autoRunExecution ? (
              <img
                src="\assets\execution_logos\autoRun.svg"
                title="Run"
                className="flex flex-row icon-blue-style float-left cursor-hand ml-1 "
                height="17px"
                width="17px"
              ></img>
            ) : null}
          </>
        ),
      },
      {
        Header: 'Machine',
        accessor: 'machineHostName',
        disableFilters: true,
        width: '11%',
        Cell: ({ value }) => {
          let title = ' ';
          value.forEach((system, index) => {
            title +=
              (system?.machineInfo?.hostName ? system?.machineInfo?.hostName : '') +
              `${index < value?.length - 1 ? ',' : ' '}`;
          });
          return (
            <Tooltip title={title} placement="top">
              <span className=" hyper_link_color-common float-left ml-3  dashboard-font-style-common text-overflow-style">
                {value.map((system, index) => {
                  if (index < 2) {
                    return (
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          getMachineDetails(
                            {
                              sysData: system,
                              status: value?.status ? value?.status : 'N/A',
                              owner: value?.createdByUname,
                            },
                            value?.status,
                            value?.createdByUname
                          );
                        }}
                        className="cursor-pointer"
                      >
                        {system.machineInfo.hostName}
                        {value[index + 1] ? ',' : null}
                      </span>
                    );
                  } else {
                    return null;
                  }
                })}
                {value?.length > 2 ? '...' : null}
              </span>
            </Tooltip>
          );
        },
      },
      {
        Header: 'Execution Type',
        accessor: 'suite.machines.executionType',
        disableFilters: true,
        width: '12%',
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left ml-3 fontPoppinsRegularMd">
              {value === 'SEND_SUITE_TO_ALL' ? 'Parallel' : value === 'DISTRIBUTE' ? 'Distribute' : 'Sequential'}
            </div>
          );
        },
      },
      {
        Header: 'Run Initiated By',
        accessor: 'createdByUname',
        disableFilters: true,
        width: '12%',
        Cell: (e) => (
          <a
            href=""
            onClick={(event) => {
              event.preventDefault();
              openModalfun('createdBy', e.cell.row.original);
            }}
            className="hyper_link_color-common float-left ml-3 cursor-hand dashboard-font-style-common"
          >
            {' '}
            {e.value}{' '}
          </a>
        ),
        // Cell: ({ value }) => {
        //     return (
        //         <div className="hyper_link_color float-left ml-3 cursor-hand table-font-style-common" onClick={() => openModalfun("createdBy")}>{value}</div>
        //     )
        // }
      },
      {
        Header: 'Run Initiated On',
        accessor: 'createdOn',
        disableFilters: true,
        width: '14%',
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-3 fontPoppinsRegularMd">{value}</div>;
        },
      },
      {
        Header: 'Execution Start Time',
        accessor: 'executionStartTime',
        disableFilters: true,

        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-3 fontPoppinsRegularMd">{value}</div>;
        },
      },
      {
        Header: 'Status',
        accessor: 'executionStatusTemp',
        disableFilters: true,
        width: '9%',
        Cell: (e) => {
          return e.value === 'Passed' ? (
            <div
              style={{ color: '#79B62F' }}
              className="float-left ml-2 text-xs cursor-pointer"
              onClick={() => navigateToExecutionResult(e)}
            >
              {e.value}
            </div>
          ) : e.value === 'Running' ? (
            <div
              className=" hyper_link_color-common float-left  text-xs ml-2 cursor-pointer"
              onClick={() => navigateToModuleListpage(e)}
            >
              {e.value}
            </div>
          ) : e.value === 'Terminated' || e.value === 'Failed' ? (
            <div
              style={{ color: '#C50303' }}
              className=" float-left ml-2 text-xs cursor-pointer"
              onClick={() => navigateToExecutionResult(e)}
            >
              {e.value}
            </div>
          ) : e.value === 'Aborted' ? (
            <div
              style={{ color: '#C50303' }}
              className=" float-left ml-2 text-xs cursor-pointer"
              onClick={() => navigateToExecutionResult(e)}
            >
              {e.value}
            </div>
          ) : e.value === 'Scheduled' ? (
            <div style={{ color: 'yellow' }} className=" float-left ml-2 text-xs">
              {e.value}
            </div>
          ) : e.value === 'Warning' ? (
            <div
              className=" text-yellow-400 float-left ml-3 text-xs cursor-pointer"
              onClick={() => navigateToExecutionResult(e)}
            >
              {e.value}
            </div>
          ) : e.value === 'N/A' ? (
            <div className=" text-grey-400 float-left ml-3 text-xs">{e.value}</div>
          ) : e.value === 'Pending' ? (
            <div
              style={{ color: '#EFBB07' }}
              className=" float-left ml-2 flex flex-row  text-xs delete-icon-style cursor-pointer"
              onClick={() => navigateToModuleListpage(e)}
            >
              {e.value}
              <Tooltip
                placement="top-start"
                title="Execution is already triggered on this machine. Your execution order priority is 2. For more details click on machine name."
              >
                <InfoOutlinedIcon className="float-right  flex flex-row ml-2  hyper_link_color-common" />
              </Tooltip>
            </div>
          ) : (
            <div className="table-non-link-color-common  float-left ml-2 text-xs">{e.value}</div>
          );
        },
      },
      {
        Header: 'Actions',
        accessor: 'Actions',
        disableFilters: true,
        width: '10%',
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common fontPoppinsRegularMd mt-2  ">{value}</div>;
        },
      },
    ],
    []
  );

  const navigateToExecutionResult = (event) => {
    const exec_name = 'Suite5';
    const exec_id = 'EXEC0015';
    const multiple = false;
    // if(multiple){
    //     history.push(`/execution/suites/${exec_name}/execution dashboard/overview/machines/results?executionId=${exec_id}&multiple=${multiple}`);
    // } else {
    //     history.push(`/execution/suites/${exec_name}/execution dashboard/Root Module/results?executionId=${exec_id}&multiple=${multiple}`);
    // }
  };

  const detailsColumns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [],
      },

      {
        Header: 'Name',
        accessor: 'suite.name',
        disableFilters: true,
        Cell: (e) => (
          <a
            href=""
            onClick={(event) => {
              event.preventDefault();
            }}
            className="hyper_link_color float-left ml-2 table-font-style-common"
          >
            {' '}
            {e.value}{' '}
          </a>
        ),
      },
      {
        Header: 'No. of Scripts',
        accessor: 'suite.noOfScripts',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-3 table-font-style-common">{value}</div>;
        },
      },
      // {
      //     Header: 'Machine',
      //     accessor: 'suite',
      //     disableFilters: true,
      //     Cell: ({ value }) => {

      //         let title = ' ';
      //         value.machines.selectedMachines.forEach((system, index) => { title += (system?.machineInfo?.hostName ? system?.machineInfo?.hostName : "" ) + `${index < value.machines.selectedMachines.length - 1 ? "," : " "}` });
      //         return (
      //             <Tooltip  title={title}   placement="top">
      //             <span   className=" table-non-link-color-common float-left ml-3  dashboard-font-style-common text-overflow-style">
      //                     {value.machines.selectedMachines.map((system, index) => {

      //                     if (index < 2) {
      //                         return <span
      //                             onClick={(e) => {
      //                                 e.preventDefault();

      //                             }
      //                             } className='cursor-pointer'>{index > 0 ? "," : ''} {system.machineInfo.hostName}
      //                         </span>
      //                     } else {
      //                         return null;
      //                     }
      //                 }
      //                 )
      //                 }
      //                 {value.machines.selectedMachines.length > 2 ? "..." : null}
      //             </span>
      //             </Tooltip>
      //         )
      //     }
      // },
      {
        Header: 'Access',
        accessor: 'suite.access',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-3 table-font-style-common">{value}</div>;
        },
      },
      {
        Header: 'Run In',
        accessor: 'suite.machines.multiple',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left ml-3 table-font-style-common">
              {value === 'false' ? 'Single Machine' : 'Multiple Machine'}
            </div>
          );
        },
      },
      {
        Header: 'Execution Type',
        accessor: 'suite.machines.executionType',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="hyper_link_color float-left ml-3 table-font-style-common">
              {value === 'SEND_SUITE_TO_ALL' ? 'Parallel' : value === 'DISTRIBUTE' ? 'Distribute' : 'Sequential'}
            </div>
          );
        },
      },

      // {
      //     Header: 'Execution Type',
      //     accessor: 'exeType',
      //     disableFilters: true,
      //     Cell: ({ value }) => {
      //         return (
      //             <div className="table-non-link-color-common float-left ml-3 table-font-style-common">{value}</div>

      //         )
      //     }
      // },
    ],
    []
  );

  const [open, setOpen] = React.useState(false);
  const [selectedDataObj, setSelectedDataObj] = React.useState({});
  const [selectedDeleteOptions, setSelectedDeleteOptions] = React.useState([]);
  const [operation, setOperation] = React.useState();
  const [showModal, setShowModal] = React.useState(false);
  const [selectedSystem, setSelectedSystem] = React.useState();
  const [suiteArray, setSuiteArrayData] = React.useState([]);
  const [deleteAllSelected, setDeleteAllSelected] = React.useState('');

  function getData(data, type) {
    setShowModal(true);
    setSelectedDataObj(data);
    setOperation(type);
    setSuiteArrayData(data?.suiteExecutions);
  }

  function selectDeleteOption(e, value) {
    let tempArray = [];
    if (e.target.checked) {
      tempArray = selectedDeleteOptions;
      tempArray.push(value);

      setSelectedDeleteOptions(tempArray);
      if (value === 'Delete all.') {
        setDeleteAllSelected('deleteAll');
        let htmlEleScreenshot = document.getElementById(deleteOption[1]);
        let htmlEleVideo = document.getElementById(deleteOption[2]);
        if (htmlEleScreenshot) {
          htmlEleScreenshot.checked = true;
        }
        if (htmlEleVideo) {
          htmlEleVideo.checked = true;
        }
      }
    } else {
      const ind = selectedDeleteOptions.findIndex((tempObj) => tempObj === value);
      selectedDeleteOptions.splice(ind, 1);
      if (value === 'Delete all.') {
        setDeleteAllSelected('');
        let htmlEleScreenshot = document.getElementById(deleteOption[1]);
        let htmlEleVideo = document.getElementById(deleteOption[2]);
        if (htmlEleScreenshot) {
          htmlEleScreenshot.checked = false;
        }
        if (htmlEleVideo) {
          htmlEleVideo.checked = false;
        }
      }

      setSelectedDeleteOptions(selectedDeleteOptions);
    }
  }

  let handelDlt = (suiteObj) => {
    let payload = {};
    if (suiteObj) {
      if (suiteObj?.suiteOfSuites.storageDetails) {
        let storageDetails = suiteObj?.suiteOfSuites.storageDetails;
        if (storageDetails?.type === 'sharedDrive') {
          payload['type'] = storageDetails?.type;
          payload['sharedIpAddress'] = storageDetails?.outputs?.bucketName;
          payload['filePath'] = [storageDetails?.outputs?.dirName + '/' + suiteObj?._id + '/'];
          payload['updateSrc'] = selectedDeleteOptions.includes('Delete all.');
        } else {
          payload['type'] = storageDetails?.type;
          payload['bucketName'] = storageDetails?.outputs?.bucketName;
          payload['filePath'] = [
            storageDetails?.outputs?.dirName ? storageDetails?.outputs?.dirName + '/' + suiteObj?._id : suiteObj?._id,
          ];
          payload['updateSrc'] = selectedDeleteOptions.includes('Delete all.');
        }

        if (selectedDeleteOptions.length === 1) {
          if (selectedDeleteOptions.includes('Delete only screenshots.') && storageDetails?.type === 'sharedDrive') {
            payload.filePath = [storageDetails?.outputs?.dirName + '/' + suiteObj?._id + '/screenshots/'];
            payload.updateSrc = false;
          } else if (
            selectedDeleteOptions.includes('Delete only screenshots.') &&
            storageDetails?.type !== 'sharedDrive'
          ) {
            payload.filePath = [
              storageDetails?.outputs?.dirName
                ? storageDetails?.outputs?.dirName + '/' + suiteObj._id + '/screenshots'
                : suiteObj._id + '/screenshots',
            ];
            payload.updateSrc = false;
          } else if (selectedDeleteOptions.includes('Delete only videos.') && storageDetails?.type === 'sharedDrive') {
            payload.filePath = [storageDetails?.outputs?.dirName + '/' + suiteObj?._id + '/videos/'];
            payload.updateSrc = false;
          } else if (selectedDeleteOptions.includes('Delete only videos.') && storageDetails?.type !== 'sharedDrive') {
            payload.filePath = [
              storageDetails?.outputs?.dirName
                ? storageDetails?.outputs?.dirName + '/' + suiteObj._id + '/videos'
                : suiteObj._id + '/videos',
            ];
            payload.updateSrc = false;
          }
        } else {
          if (selectedDeleteOptions.includes('Delete all.')) {
          } else {
            if (
              selectedDeleteOptions.includes('Delete only screenshots.') &&
              selectedDeleteOptions.includes('Delete only videos.') &&
              storageDetails?.type === 'sharedDrive'
            ) {
              payload.filePath = [
                storageDetails?.outputs?.dirName + '/' + suiteObj._id + '/screenshots',
                storageDetails?.outputs?.dirName + '/' + suiteObj?._id + '/videos/',
              ];
            } else if (
              selectedDeleteOptions.includes('Delete only screenshots.') &&
              selectedDeleteOptions.includes('Delete only videos.') &&
              storageDetails?.type === 'cloudS3'
            ) {
              payload.filePath = [
                storageDetails?.outputs?.dirName
                  ? storageDetails?.outputs?.dirName + '/' + suiteObj._id + '/screenshots'
                  : suiteObj._id + '/screenshots',
                storageDetails?.outputs?.dirName
                  ? storageDetails?.outputs?.dirName + '/' + suiteObj._id + '/videos'
                  : suiteObj._id + '/videos',
              ];
            }
          }
        }

        deleteExecutionDataReq(suiteObj?._id, payload).then((res) => {
          if (res?.data?.message === 'SUCCESS') {
            setOpen(false);
            setSelectedDeleteOptions([]);
            MyAlert.success(`${suiteObj._id} suite deleted successfully`);

            setDeleteAllSelected('');
            getAllDashboard();
          } else {
            // createAlertFun('danger', res.data.message)
            // setOpen(false)
          }
        });
      }
    }
    setOpen(false);
  };

  function openModalfun(btn, data) {
    if (btn === 'createdBy') {
      setShowModal(true);
      setOperation('createdBy');
      setModifiedId(data);
    } else if (btn === 'systemDetails') {
      setOperation('systemDetails');
    }
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4">
                <div className="inline-block">
                  <div className="flex flex-start ml-2">
                    <span className="pt-5 pl-3">
                      <ExclamationIcon className="h-6 w-6 text-yellow-600 " />
                    </span>
                    <Dialog.Title as="h3" className="text-sm leading-6 ml-4 pt-3 font-small text-gray-900">
                      <span className="fontPoppinsSemiboldLg block">Warning </span>
                      <span className="fontPoppinsMediumMd ">Delete </span>
                    </Dialog.Title>
                  </div>
                </div>

                <hr className="line mt-px" />
                <div className="text-sm font-sans mt-3 text-gray-500 ml-16 mr-8">
                  <p className=""> Your data will be lost. Are you sure you want to delete {selectedDataObj._id}? </p>
                  <p className="mt-2"> How do you want to proceed?</p>
                  {deleteOption.map((option, i) => (
                    <div className="mt-1">
                      <label>
                        <input
                          type="checkbox"
                          id={option}
                          className={
                            deleteAllSelected === 'deleteAll' && i > 0
                              ? 'rounded opacity-30 mr-2 disabled-action'
                              : 'rounded mr-2'
                          }
                          onChange={(e) => selectDeleteOption(e, option)}
                        ></input>
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
                <hr className="line mt-3" />
                <div className="mt-2 pb-2 flex flex-row float-right">
                  <button type="button" className="mt-3 gray-btn ml-1" onClick={() => handelDlt(selectedDataObj)}>
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 ml-4 primary-btn "
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Modal open={showModal} className="modal-dialog">
        {operation === 'details' ? (
          <div className="modal-container sos-details-modal-style">
            <div className="modal-header ">
              <label className="Popup-header-common Popup-header-layout-style-user">{selectedDataObj._id}</label>
              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  setShowModal(false);
                  setOperation();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className="modal-body" id="journal-scroll">
              <div className="flex flex-row">
                <div className="w-2/5">
                  <label htmlFor="suiteName" className="input-filed-label-style-common">
                    <span class="text-red-400">*</span> Name
                  </label>
                  <br></br>
                  <label className="create-set-header-label ml-3">{selectedDataObj.name}</label>
                </div>
                <div className="w-3/5">
                  <label htmlFor="Description" className="input-filed-label-style-common">
                    Description
                  </label>
                  <br></br>
                  <label>--</label>
                </div>
              </div>
              <div className="suite-of-suite-deails-area-style-popup ">
                <div className="grid grid-cols-2 mt-3 p-3 ">
                  <div className="suite-header-style">
                    <label className="module-script-label-style float-left"> Suites</label>
                  </div>
                </div>
                <Table columns={detailsColumns} data={suiteArray} />
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="gray-btn"
                onClick={() => {
                  setShowModal(false);
                  setOperation();
                }}
              >
                Close
              </button>
            </div>
          </div>
        ) : operation === 'createdBy' ? (
          <div className="modal-container page-modal-size">
            <div className="modal-header ">
              <label className="Popup-header-common Popup-header-layout-style-user">
                {' '}
                <label> User Details -{userData?.createdByUname}</label>
              </label>
              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  setShowModal(false);
                  setOperation();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className="modal-body" id="journal-scroll">
              <UserDetailsPage modifiedBy={userData?.createdBy} />
            </div>
          </div>
        ) : null}
      </Modal>

      {operation === 'systemDetails' ? (
        <MachineDetails data={selectedSystem} closeModal={closeMachineDetailsModal} />
      ) : null}

      <div className="content_area_style overflow-auto" id="journal-scroll">
        <div>
          <div className="mt-3 pb-2 suite-of-suite-header-style  flex flex-wrap items-center">
            {/* <div className=" flex flex-wrap items-center"> */}
            <label className="project_label text-sm flex-auto">Execution Dashboard</label>
            <div className="pr-4">
              <div className="flex flex-row ml-32">
                {/* <SearchOutlined className="text-gray-500 ml-3 mt-1 cursor-pointer hover:text-blue-700" /> */}
                <Sync
                  className="text-gray-500 ml-3 mt-1 cursor-pointer hover:text-blue-700"
                  onClick={getAllDashboard}
                />
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>

        {/* <div className="suite-of-suite-deails-area-style"> */}
        <div className="table-height-executionDashboard overflow-auto dashboard-table-height ">
          <Table columns={columns} data={dashboardData} />
        </div>
      </div>
    </>
  );
};

export default SuiteOfSuiteDashboard;
