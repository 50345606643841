import React, { useState, useEffect } from 'react';
import Navigation from './Navigation';
import Header from './Header';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getUserStatus,
  getLicenseStatus,
  getLicenseExpirationDays,
  getCurrentLicenseId,
  getAccessToken,
  getWebSiteHostName,
  getActivationStatus,
} from '@util/common_utils';
import { UNAUTHENTICATED_ROUTES, SIDE_NAVIGATION_CONSTANTS } from '@src/common/ui-constants';
import { signOutReq, getPrivilegeReq } from '@src/api/api_services';
import { getLicenseBillingCycle } from '@src/util/licenseUtils';
import { parseAndDecryptData } from '@common/security';

const AppLayout = ({ children }) => {
  const [navOpen, setNavOpen] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const userStatus = getUserStatus();
  const licenseStatus = getLicenseStatus();
  const isDisabled =
    licenseStatus === 'EXPIRED' || userStatus === 'DISABLED' || licenseStatus == null || userStatus == null;
  const search = window.location.search;
  const emailId = new URLSearchParams(search).get('emailId');
  const activationStatus = new URLSearchParams(search).get('activationStatus');
  const userActivationStatus = getActivationStatus();
  const token = new URLSearchParams(search).get('token');
  const [isAuthenticated, setAuthenticated] = useState(true);
  const isLicenseUpdatePath = location.pathname.includes('licenses/');
  const currentLicenseId = getCurrentLicenseId();
  const accessToken = getAccessToken();
  const billingCycle = getLicenseBillingCycle();
  const expiryRemainingDays = getLicenseExpirationDays();
  let licenseTransactionAllowed = JSON.parse(localStorage.getItem('allowedProjects'))?.features?.licenses;
  const isFromWebsite = new URLSearchParams(search).get('from');
  const urlParams = new URLSearchParams(window.location.search);
  const dataParam = urlParams.get('data');
  const [privilegeData, setPrivilegeData] = useState(null);

  let NavigateTo = (route) => {
    if (route === '/change-password' && userStatus === 'ACTIVE' && ['ACTIVE', 'PENDING'].includes(activationStatus)) {
      setAuthenticated(false);
      history.push(`/change-password?&emailId=${emailId}&token=${token}&activationStatus=${activationStatus}`);
      pageReload();
    } else if (
      route === '/otp-verification' &&
      userStatus === 'ACTIVE' &&
      process.env.REACT_APP_ENVIRONMENT === 'onprem'
    ) {
      history.push(`/token-expired?emailId=${emailId}&activationStatus=ACTIVE`);
      pageReload();
    } else {
      history.push(route);
      if (route !== '/signin') {
        signOut();
      }
    }
  };

  let signOut = (pathname) => {
    let payload = {
      emailId,
      currentLicenseId,
    };

    signOutReq(payload)
      .then((response) => {
        if (response.data.status === 'Success' && process?.env?.REACT_APP_ENVIRONMENT !== 'onprem') {
          pageReload();
          window.location.replace(getWebSiteHostName() + pathname);
        }
      })
      .catch((error) => {
        console.error('SignOut API Failed!', error);
      });
  };
  const pageReload = () => {
    localStorage.clear();
    window.location.reload();
  };

  let setLoginSessionData = () => {
    if (dataParam) {
      parseAndDecryptData(dataParam)
        .then((result) => {
          if (result) {
            if (isFromWebsite && userStatus === 'ACTIVE') {
              localStorage.setItem('test-optimize-user', JSON.stringify(result));
            }
          } else {
            console.error('Failed to decrypt data, result is null');
          }
        })
        .catch((error) => {
          console.error('Error during decryption:', error);
        });
    }
  };

  const getPrivilegeData = async () => {
    const allowedProject = await getPrivilegeReq();
    setPrivilegeData(allowedProject?.data);
    localStorage.setItem(
      SIDE_NAVIGATION_CONSTANTS?.ALLOWED_PROJECTS,
      JSON.stringify(allowedProject?.data?.responseObject)
    );
  };

  useEffect(() => {
    // if ((location?.pathname === "/signin" && getLicenseExpirationDays() <= 5 && !isLicenseUpdatePath) || (isDisabled && !isLicenseUpdatePath)) {
    //   history.push("/licenses");
    // }
    // else if (location.pathname.includes("otp-verification")){
    //   pageReload()
    // }
    // else
    if (UNAUTHENTICATED_ROUTES.includes(location?.pathname)) {
      NavigateTo(location?.pathname);
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (userStatus === 'ACTIVE') {
      if (['/', '/signin'].includes(location?.pathname)) {
        signOut('/signin');
      } else if (location?.pathname === '/signup') {
        signOut('/signup');
      } else if (location?.pathname === '/forget-password') {
        signOut('/forgot-password');
      } else if (
        isFromWebsite ||
        ['/projectmenu/project', '/projectmenu/user', '/projectmenu/role'].includes(location.pathname)
      ) {
        localStorage.setItem(
          'selected-project',
          JSON.stringify({
            id: '',
            name: 'All Projects',
          })
        );
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    let getURLLastPathName = location.pathname.split('/');
    let lastSegment = getURLLastPathName[getURLLastPathName.length - 1];
    if (!licenseTransactionAllowed && ['payment-history', 'renew', 'buy', 'upgrade'].includes(lastSegment)) {
      history.push('/licenses');
    }
    if (isDisabled && userActivationStatus === 'ACTIVE') {
      history.push('/licenses/fireflink-license');
    }
  }, [location.pathname]);
  const SignInResponse = new URLSearchParams(search).get('data');
  let parsedSignInResponse;
  try {
    parsedSignInResponse = JSON.parse(SignInResponse);
  } catch (error) {
    console.error(error);
    parsedSignInResponse = SignInResponse;
  }
  useEffect(() => {
    setLoginSessionData();
    getPrivilegeData();
  }, []);

  return (
    <>
      {isAuthenticated && privilegeData && (
        <>
          <Header navOpen={navOpen} privilegeData={privilegeData?.responseObject} />
          {children}
          <Navigation navOpen={navOpen} setNavOpen={setNavOpen} privilegeData={privilegeData?.responseObject} />
        </>
      )}
    </>
  );
};

export default AppLayout;
