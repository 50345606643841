import React, { useState, useRef } from 'react';
import { Field, Formik, Form } from 'formik';
import Modal from 'react-modal';
import * as yup from 'yup';
import '../testData.scss';
import { TextField } from '@material-ui/core';
import { createExcelFile, getFilesReq } from '@api/api_services';
import TreeWithRadioButton from '@src/pages/common/table_tree/table-tree-with-radio-button';
import { Icon } from 'fireflink-ui';
import { colors } from '@src/css_config/colorConstants';
import { getFileExtension } from '@src/util/common_utils';
import { useHistory } from 'react-router-dom';
import { encodeSpecialCharater } from '@src/pages/test-development/shared/common-methods';

function CreateFileModal(props) {
  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');
  let accessToken = JSON.parse(localStorage.getItem('test-optimize-user')).access_token;
  const [openModal, setOpenModal] = useState(true);
  const [selectedFolder, setSelectedFolder] = useState('');
  const formRef = useRef();
  const history = useHistory();
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const validationSchema = yup.object({
    parentFolder: yup.string().required('Parent Folder is required'),
    fileName: yup
      .string()
      .required('Name is required')
      .trim('Space is not allowed at starting and at the end')
      .strict(true)
      .min(2, 'Name must be at least 2 characters')
      .max(25, 'Name must contains maximum 25 characters'),
  });
  async function delayTime(time) {
    await new Promise((resolve) => setTimeout(resolve, time));
  }
  const treeNodeSelected = ([data]) => {
    if (!data) {
      formRef.current.setFieldValue('parentFolder', '');
    } else if (data && data.data.isRootNode) {
      setSelectedFolder({ ...data.data, data: data.data });
      formRef.current.setFieldValue('parentFolder', data.data.title);
    } else if (data && data.data.key) {
      setSelectedFolder({ ...data.node, data: data.data });
      formRef.current.setFieldValue('parentFolder', data.data.title);
    }
  };
  const onSubmit = async (values, { setSubmitting }) => {
    let folderId;
    let file = {};

    if (props?.parentId) {
      folderId = props.parentId;
    } else {
      folderId = selectedFolder.data.key;
    }

    file = {
      id: folderId,
      folderName: 'testdata',
      name:
        getFileExtension(values.fileName) === 'xlsx' || getFileExtension(values.fileName) === 'xls'
          ? values.fileName
          : `${values.fileName}.xlsx`,
      projectId: project?.id,
    };

    const headers = {
      Accept: 'application/json, text/plain, */*',
      Authorization: `Bearer ${accessToken}`,
      projectId: project.id,
      sectionName: 'Test Data',
    };

    try {
      const response = await createExcelFile(file, headers);
      if (response.data.responseCode === 200) {
        const fileId = response.data?.responseObject[0]?.id;
        const parentId = response.data?.responseObject[0]?.parentId;
        props.MyAlert.success(`${values.fileName} File Created successfully.`);
        props.closeModal(false);
        await delayTime(1000);
        getFilesReq(fileId).then((response) => {
          if (response.data.responseCode === 200) {
            let tempPath = response.data.responseObject.actualPath.split('/').slice(5);
            let tempKey = response.data.responseObject.searchKey.slice(1).split('/');
            let tempParentAndSonJSON = [];
            let tempURLPath = '';
            tempPath.forEach((tempValues, index) => {
              if (index === 0) {
                tempParentAndSonJSON = [];
              } else {
                tempParentAndSonJSON.push({
                  Name: index === 0 ? 'Root Folder' : tempValues,
                  id: tempKey[index],
                  parentId: index === tempPath.length - 1 ? tempPath[index - 1] : null,
                });
                tempURLPath +=
                  index === 0
                    ? `Root Folder_${tempKey[index]}`
                    : (tempURLPath === '' ? '' : '/') + encodeSpecialCharater(tempValues) + `_${tempKey[index]}`;
              }
            });
            localStorage.setItem('parentAndSonJson', JSON.stringify(tempParentAndSonJSON));
            history.push(`/testData/${tempURLPath}/ExcelFile?id=${fileId}&parentId=${parentId}`);
          }
        });
      } else {
        props.MyAlert.info(response.data.message);
        props.closeModal(false);
      }
    } catch (err) {
      props.MyAlert.info(err.message || 'An error occurred.');
      props.closeModal(false);
    } finally {
      setSubmitting(false);
      props.reloadTree(true);
    }
  };

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={() => {
        setOpenModal(false);
        props.closeModal(false);
      }}
      className="p-px modal-dialog"
    >
      <div className="modal-container page-modal-size modal-position">
        <div className="modal-header flex justify-between">
          <h2 className="title Popup-header-common">Create File</h2>
          <Icon
            className="cursor-pointer"
            color={colors.black}
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            name="close"
            height={15}
            width={15}
          />
        </div>
        <div className="modal-body" id="journal-scroll">
          <Formik
            initialValues={{
              fileName: '',
              parentFolder: props.parentName || '',
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            innerRef={formRef}
          >
            {({ values, handleSubmit, setFieldValue, errors, touched }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mt-2">
                  <TextField
                    error={touched.fileName && !!errors.fileName}
                    size="40"
                    InputLabelProps={{ shrink: true }}
                    className="w-2/3 mui-input-text-field input-filed-label-style-common"
                    autoFocus
                    type="text"
                    name="fileName"
                    id="fileName"
                    autoComplete="off"
                    placeholder="Enter file name"
                    onBlur={() => setFieldValue('fileName', values.fileName.trim())}
                    onChange={(e) => setFieldValue('fileName', e.target.value)}
                    value={values.fileName}
                    label={
                      <>
                        <span className="text-red-400">&#42;</span>Name
                      </>
                    }
                  />
                  {touched.fileName && errors.fileName && <div className="errorMessage">{errors.fileName}</div>}
                </div>
                <div className="mt-4 text-sm text-blue-700">
                  <Field name="parentFolder">
                    {({ meta }) => (
                      <div>
                        <label
                          htmlFor="parentFolder"
                          className={
                            meta.touched && meta.error
                              ? 'text-xs text-red-500 input-filed-label-style-common'
                              : 'input-filed-label-style-common'
                          }
                        >
                          <span className="text-red-400">&#42;</span>
                          Parent Folder
                        </label>
                        <div className="popup-input-bg">
                          <TreeWithRadioButton
                            moduleSelection
                            individualTree={!!id}
                            hideRootRadioBtn
                            data={props.treeData || []}
                            operation={['ADD_SUB', 'EDIT'].includes(props.mode) ? 'edit' : null}
                            placeholder={
                              props.data
                                ? props.data.parentName
                                : props.parentId
                                ? props.parentName
                                : 'Search and select parent folder'
                            }
                            nodeSelected={treeNodeSelected}
                            hideElements
                            hideElementsBtn
                            buttonLabel="Folder"
                          />
                        </div>
                        {meta.touched && meta.error && <div className="errorMessage">{meta.error}</div>}
                      </div>
                    )}
                  </Field>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="gray-btn"
                    onClick={() => {
                      setOpenModal(false);
                      props.closeModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="ml-4 primary-btn" id="uploadTestDataFile">
                    Create
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
}

export default CreateFileModal;
