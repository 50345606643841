import React, { useContext } from 'react';
import Modal from 'react-modal';
import ProjectCreatePage from './create_component_session/project_create_page';
import { MdEdit } from 'react-icons/md';
import { getSingleProjectRequest, downloadProjectFile } from '@api/api_services';
import { Tooltip } from '@material-ui/core';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import './ProjectConfigPage.scss';
import { IMPORT_EXPORT_CONSTANTS, LABELS_CONSTANTS } from '@src/common/ui-constants';
import { saveFileFromBlob } from '@pages/test-development/shared/common-methods';
import { getLicensePlans, getUserPrivilege } from '@src/util/common_utils';
import InfoPopupForExport from '@src/common/InfoPopupForExport';
import { Button } from 'fireflink-ui';

const ProjectConfigPage = ({ setconfig, MyAlert }) => {
  const customStylesProject = {
    content: {
      position: 'absolute',
      top: '1%',
      left: '25%',
      right: '35%',
      bottom: '5%',
      overflow: 'hidden',
      width: '640px',
      height: '520px',
    },
  };

  const [showModal, setShowModal] = React.useState(false);
  const [popupHeader, setpopupHeader] = React.useState();
  const [popupBtn, setpopupbtn] = React.useState();

  const setpopupHeaderAndBtn = (header, btn) => {
    setpopupHeader(header);
    setpopupbtn(btn);
  };

  function downloadFile(fileId, fileName) {
    downloadProjectFile(fileId).then((d) => {
      saveFileFromBlob(d.data, fileName);
    });
  }

  const [project, setProjectData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isInfoPopupOpen, setIsInfoPopupOpen] = React.useState(false);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);

  function getProjectDetails(projectId) {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    setIsLoading(true);
    getSingleProjectRequest(projectId)
      .then((results) => {
        stopProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
        if (results && results.data && results.data.responseObject) {
          setProjectData(results.data.responseObject);
          localStorage.setItem('selected-project', JSON.stringify(results.data.responseObject));
        }

        setIsLoading(false);
      })
      .catch((error) => {
        resetProgress();
        console.error('GET_SINGLE_PROJECT :', error);
        setIsLoading(false);
      });
  }

  const search = window.location.search;
  const projectId = new URLSearchParams(search).get('id');

  React.useEffect(() => {
    const search = window.location.search;
    let projectId = new URLSearchParams(search).get('id') || JSON.parse(localStorage.getItem('selected-project'));

    if (new URLSearchParams(search).get('id')) {
      projectId = new URLSearchParams(search).get('id');
    } else {
      projectId = JSON.parse(localStorage.getItem('selected-project')).id;
    }
    if (projectId) {
      getProjectDetails(projectId);
    }
  }, [projectId]);

  function closePopup() {
    let projectId = JSON.parse(localStorage.getItem('selected-project')).id;
    setShowModal(false);
    getProjectDetails(projectId);
  }

  function closePopupOnCancel() {
    setShowModal(false);
  }

  if (isLoading) {
    return <CommonLoader />;
  }

  return (
    <div>
      <Modal
        isOpen={showModal}
        style={customStylesProject}
        onRequestClose={(e) => {
          if (e.key === 'Escape') {
            setShowModal(false);
          }
        }}
      >
        <div>
          <div className="Popup-header-common Popup-header-layout-style-project">
            {popupHeader === 'Edit Project' ? (
              <label>
                {' '}
                {popupHeader} - {project.name}
              </label>
            ) : null}
          </div>
          <button
            onClick={() => setShowModal(false)}
            type="button"
            className="float-right -mt-6 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        {popupHeader === 'Edit Project' ? (
          <div className="mt-3.5 project-create-header">
            <ProjectCreatePage
              func={closePopup}
              MyAlert={MyAlert}
              project={project}
              button={popupBtn}
              setShowModal={setShowModal}
              closePopupOnCancel={closePopupOnCancel}
              popupType={popupHeader}
            />
          </div>
        ) : null}
      </Modal>
      <div className="content_area_style">
        <div className="project-config-header-style border_bottom_style">
          <div className="flex flex-wrap clearfix">
            <label className="flex-auto project_label">Project Details - {project.name}</label>
            {!getUserPrivilege() && getLicensePlans() !== 'Defects' && (
              <Button
                variant="primary"
                label={IMPORT_EXPORT_CONSTANTS.EXPORT}
                size="medium"
                className="fontPoppinsMediumSm -mt-2 mr-3"
                onClick={() => setIsInfoPopupOpen(true)}
              />
            )}
            <button
              className="primary-btn icon-btn mr-3 -mt-2"
              onClick={() => {
                setShowModal(true);
                setpopupHeaderAndBtn('Edit Project', 'Update');
              }}
            >
              <MdEdit className="float-left mr-1 individual-user-edit" /> Edit
            </button>
          </div>

          {isLoading && <div className="loading">Loading...</div>}
        </div>

        <div className="grid grid-cols-3 ml-4 mt-3 p-4">
          <div>
            <label htmlFor="userName" className="details-key-style-common block">
              Name
            </label>
            <label className="details-data-style-common" title={project?.name}>
              {project.name}
            </label>
          </div>

          <div>
            <label htmlFor="type" className="details-key-style-common block">
              Type
            </label>
            <label className="details-data-style-common">{project.type}</label>
          </div>
          <div>
            <label htmlFor="emailId" className="details-key-style-common block">
              Description
            </label>
            {project.desc ? (
              <label className="details-data-style-common" title={project?.desc}>
                {project.desc}
              </label>
            ) : (
              <label className="details-data-style-common">--</label>
            )}
          </div>
          <div className="mt-6">
            <label htmlFor="status" className="details-key-style-common block">
              Status
            </label>
            {project.status === 'Open' ? (
              <label className="label-success fontPoppinsRegularMd">{project.status}</label>
            ) : (
              <label className="label-error fontPoppinsRegularMd">{project.status}</label>
            )}
          </div>

          {(String(project.type)?.includes('Web') || String(project.type)?.includes('Salesforce')) &&
            !String(project.type)?.includes('Web Service') && (
              <div className="mt-4">
                <label htmlFor="emailId" className="details-key-style-common block text-gray-700 sm:mt-px sm:pt-2">
                  Web Url
                </label>
                {project.url ? (
                  <label className="details-data-style-common truncate max-width-text" title={project?.url}>
                    {project.url}
                  </label>
                ) : (
                  <label className="details-data-style-common">--</label>
                )}
              </div>
            )}
          {String(project.type)?.includes('Web Service') && (
            <>
              <div className="mt-4">
                <label htmlFor="emailId" className="block sm:mt-px sm:pt-2 details-key-style-common">
                  Base URI
                </label>
                {project.baseUri ? (
                  <label className="details-data-style-common" title={project?.baseUri}>
                    {project.baseUri}
                  </label>
                ) : (
                  <label className="details-data-style-common">--</label>
                )}
              </div>
              <div className="mt-4">
                <label htmlFor="emailId" className="block sm:mt-px sm:pt-2 details-key-style-common">
                  Port
                </label>
                {project.port ? (
                  <label className="details-data-style-common" title={project?.port}>
                    {project.port}
                  </label>
                ) : (
                  <label className="details-data-style-common">--</label>
                )}
              </div>
            </>
          )}

          {String(project.type)?.includes('Mobile') && (
            <>
              {' '}
              <div className="mt-4">
                <label htmlFor="appType" className="block sm:mt-px sm:pt-2 details-key-style-common">
                  AppType
                </label>
                <label className="details-data-style-common" title={project?.appType}>
                  {project.appType}
                </label>
              </div>
            </>
          )}
          {(String(project.platform)?.includes('Android') || String(project.type)?.includes('Salesforce')) && (
            <>
              {' '}
              <div className="mt-4">
                <label htmlFor="appActivity" className="block sm:mt-px sm:pt-2 details-key-style-common">
                  App Package
                </label>
                {project.appPackage ? (
                  <label className="details-data-style-common truncate max-width-text" title={project?.appPackage}>
                    {project.appPackage}
                  </label>
                ) : (
                  <label className="details-data-style-common">--</label>
                )}
              </div>
              <div className="mt-4">
                <label htmlFor="appActivity" className="block sm:mt-px sm:pt-2 details-key-style-common">
                  App Activity
                </label>
                {project.appActivity ? (
                  <label className="details-data-style-common truncate max-width-text" title={project?.appActivity}>
                    {project.appActivity}
                  </label>
                ) : (
                  <label className="details-data-style-common">--</label>
                )}
              </div>
            </>
          )}
          {String(project.platform)?.toLowerCase()?.includes('IOS'.toLowerCase()) && (
            <>
              <div className="mt-4">
                <label htmlFor="bundleId" className="block sm:mt-px sm:pt-2 details-key-style-common">
                  Bundle Id
                </label>
                {project.bundleId ? (
                  <label className="details-data-style-common truncate max-width-text" title={project?.bundledId}>
                    {project.bundleId}
                  </label>
                ) : (
                  <label className="details-data-style-common">--</label>
                )}
              </div>
            </>
          )}
          <div className="mt-6">
            <label htmlFor="userName" className="details-key-style-common block">
              Created By
            </label>
            <label className="details-data-style-common" title={project?.createdByUname}>
              {project.createdByUname}
            </label>
          </div>

          <div className="mt-6">
            <label htmlFor="createdOn" className="details-key-style-common block">
              Created On
            </label>
            <label className="details-data-style-common" title={project?.createdOn}>
              {project.createdOn}
            </label>
          </div>
          <div className="mt-6">
            <label htmlFor="modifiedBy" className="details-key-style-common block">
              Modified By
            </label>
            <label className="details-data-style-common" title={project?.modifiedByUname}>
              {project.modifiedByUname}
            </label>
          </div>
          <div className="mt-6">
            <label htmlFor="modifiedOn" className="details-key-style-common block   ">
              Modified On
            </label>
            <label className="details-data-style-common" title={project?.modifiedOn}>
              {project.modifiedOn}
            </label>
          </div>
          <div className="mt-6">
            <label htmlFor="modifiedOn" className="details-key-style-common block">
              Time Zone
            </label>
            <label className="details-data-style-common" title={project?.timeZoneValue}>
              {project.timeZoneValue}
            </label>
          </div>
          {String(project.type)?.includes('Salesforce') ? null : <div className="mt-6"></div>}

          {String(project.type)?.includes('Web & Mobile') && !String(project.type)?.includes('Mobile') ? (
            <>
              {' '}
              <div className="mt-6"></div>
              <div className="mt-6"></div>
            </>
          ) : null}
          {String(project.type)?.includes('Mobile') && !String(project.type)?.includes('Web & Mobile') ? (
            <div className="mt-6"></div>
          ) : null}

          <div className="flex  gap-40">
            {String(project?.platform)?.toLowerCase()?.includes('Android'.toLowerCase()) &&
              String(project?.platform)?.toLowerCase()?.includes('iOS'.toLowerCase()) && (
                <>
                  {project?.apkFiles.length && project?.apkFiles[0].name ? (
                    <>
                      <div className="mt-6  ">
                        <label className="mt-0.5 flex flex-row file-download-label-style inline-block relative -left-4 -top-3.5">
                          <img
                            alt=""
                            src="/assets/images/Attach_icon.svg"
                            data-title="Download"
                            className="fie-icon-style "
                          />
                          <label title={project?.apkFiles[0].name}>&nbsp; {project?.apkFiles[0].name}</label>
                          <Tooltip title="Download" placement="top">
                            <img
                              alt=""
                              src="/assets/images/Download_icon.svg"
                              onClick={() => downloadFile(project?.apkFiles[0]?.id, project?.apkFiles[0].name)}
                              className="ml-4 mt-1 download-icon-style inline-block"
                            />
                          </Tooltip>
                        </label>
                      </div>
                    </>
                  ) : null}
                </>
              )}

            {String(project?.platform)?.toLowerCase()?.includes('iOS'.toLowerCase()) &&
              String(project?.platform)?.toLowerCase()?.includes('Android'.toLowerCase()) && (
                <div className="mt-6 ">
                  <label className="mt-0.5 flex flex-row file-download-label-style inline-block relative -left-4 -top-3.5">
                    {project?.ipaFiles.length && project?.ipaFiles[0].name ? (
                      <>
                        <img
                          alt=""
                          src="/assets/images/Attach_icon.svg"
                          data-title="Download"
                          className="fie-icon-style "
                        />
                        <label title={project?.ipaFiles[0].name}>&nbsp; {project?.ipaFiles[0].name}</label>
                        <Tooltip title="Download" placement="top">
                          <img
                            alt=""
                            src="/assets/images/Download_icon.svg"
                            onClick={() => downloadFile(project?.ipaFiles[0]?.id, project?.ipaFiles[0].name)}
                            className="ml-4 mt-1 download-icon-style inline-block"
                          />
                        </Tooltip>
                      </>
                    ) : (
                      <span></span>
                    )}
                  </label>
                </div>
              )}
          </div>

          <div className="mt-6"></div>
        </div>
        <div className="grid grid-cols-3 ml-4 -mt-6">
          {String(project?.platform)?.toLowerCase()?.includes('Android'.toLowerCase()) &&
            !String(project?.platform)?.toLowerCase()?.includes('iOS'.toLowerCase()) && (
              <div className="mt-6  ">
                <label className="flex flex-row file-download-label-style inline-block -ml-3 absolute -mt-10">
                  {' '}
                  {project?.apkFiles.length && project?.apkFiles[0].name ? (
                    <>
                      {' '}
                      <img alt="" src="/assets/images/Attach_icon.svg" className="fie-icon-style " />
                      <label title={project?.apkFiles[0].name}>{project?.apkFiles[0].name}</label>
                      <Tooltip title="Download" placement="top">
                        <img
                          alt=""
                          src="/assets/images/Download_icon.svg"
                          onClick={() => downloadFile(project?.apkFiles[0]?.id, project?.apkFiles[0].name)}
                          className=" mt-1 download-icon-style inline-block"
                        />
                      </Tooltip>{' '}
                    </>
                  ) : null}
                </label>
              </div>
            )}
          {String(project?.platform)?.toLowerCase()?.includes('iOS'.toLowerCase()) &&
            !String(project?.platform)?.toLowerCase()?.includes('Android'.toLowerCase()) && (
              <div className="mt-6 ">
                <label className="flex flex-row file-download-label-style inline-block absolute -mt-10">
                  {' '}
                  {project?.ipaFiles.length && project?.ipaFiles[0].name ? (
                    <>
                      {' '}
                      <img
                        alt=""
                        src="/assets/images/Attach_icon.svg"
                        data-title="Download"
                        className="fie-icon-style "
                      />
                      <label title={project?.ipaFiles[0].name}> {project?.ipaFiles[0].name}</label>
                      <Tooltip title="Download" placement="top">
                        <img
                          alt=""
                          src="/assets/images/Download_icon.svg"
                          onClick={() => downloadFile(project?.ipaFiles[0]?.id, project?.ipaFiles[0].name)}
                          className="ml-4 mt-1 download-icon-style inline-block"
                        />
                      </Tooltip>{' '}
                    </>
                  ) : null}
                </label>
              </div>
            )}
        </div>
      </div>
      {isInfoPopupOpen && (
        <InfoPopupForExport
          isInfoPopupOpen={isInfoPopupOpen}
          setIsInfoPopupOpen={setIsInfoPopupOpen}
          project={project}
          MyAlert={MyAlert}
        />
      )}
    </div>
  );
};

export default ProjectConfigPage;
