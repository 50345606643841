import { colors } from '@src/css_config/colorConstants';
export default function ImportExcelStyles(data) {
  let getTable = document.querySelector('.Spreadsheet__table');

  if (!(getTable instanceof HTMLElement)) {
    return;
  }

  let getTableRows = getTable.querySelectorAll('tr');
  let getTableHeader = getTable.querySelectorAll('th');

  getTableHeader.forEach((cell) => {
    const cellText = cell.innerText.trim();
    const isNumber = !isNaN(Number(cellText));
    cell.style.width = isNumber ? '50px' : '150px';
    cell.style.fontSize = '12px';
    cell.style.color = colors.text_black;
  });

  if (data) {
    data.forEach((row, i) => {
      let getRowData = getTableRows[i + 1];

      if (!getRowData) return;

      let getColData = getRowData.querySelectorAll('.Spreadsheet__cell');

      row.forEach((column, j) => {
        if (getColData[j] && column.hasOwnProperty('style')) {
          let { color, backgroundColor, bold, italic, name, border, alignment } = column.style;

          getColData[j].style.color = `#${color}`;
          getColData[j].style.backgroundColor = backgroundColor ? `#${backgroundColor}` : colors.text_white;
          getColData[j].style.fontWeight = bold ? 'bolder' : 'normal';
          getColData[j].style.fontStyle = italic ? 'italic' : 'normal';
          getColData[j].style.fontFamily = name;
          getColData[j].style.borderLeft = border.left === 'MEDIUM' ? `2px solid ${colors.text_black}` : '';
          getColData[j].style.borderRight = border.right === 'MEDIUM' ? `2px solid ${colors.text_black}` : '';
          getColData[j].style.borderBottom = border.bottom === 'MEDIUM' ? `2px solid ${colors.text_black}` : '';
          getColData[j].style.borderTop = border.top === 'MEDIUM' ? `2px solid ${colors.text_black}` : '';
          getColData[j].style.textAlign =
            alignment.horizontal === 'GENERAL' ? 'left' : alignment.horizontal.toLowerCase();
        }
      });
    });
  }
}
