export const colors = {
  ff_light_blue: '#E5ECFC',
  ff_cream: '#FBFCFD',
  rs_primary: '#434db8',
  rs_border: '#E0E0E0',
  rs_error: '#C50303',
  apexChartColors: ['#79B62F', '#C50303', '#D9A903', '#727171', '#6AB187', '#CED2CC'],
  modal_backdrop: 'rgba(0, 0, 0, 0.05)',
  red_columnChart: '#EB6161',
  grey_dark: '#727171',
  sky_blue_dark: ' #f0f3f9',
  blue_dark: '#1648c6',
  light_dark: '#3377F9',
  scriptTrendLine: ['#5CA700', '#C50303', '#FFC600', '#727171', '#E57300'],
  rs_sussess: '#5CA700',
  rs_failed: '#C50300',
  rs_waring: '#FFC600',
  rs_skipped: '#727171',
  rs_flaky: '#E57300',
  bg_failureReasonTrack: '#7388A95C',
  exe_radial_color: ['#5CA700', '#C50300', '#FDC400', '#727171'],
  bg_track_radial: '#D6D1B1',
  black: '#111',
  yellow_failure: '#FFD149',
  orange_failure: '#F7A24D',
  text_gray: '#7C828A',
  bg_white: '#F9F1F2',
  cyan: '#18C1C1',
  blue_border: '#1648C652',
  black_border: '#3C3838',
  success_iconBg: '#E3FAC8',
  fail_iconBg: '#F7CFCA',
  warninig_iconBg: '#FCF1C9',
  skipped_iconBg: '#DBDBDB',
  flaky_iconBg: '#F3D1B0',
  grey_light: '#707070',
  grey_light_alpha: '#70707090',
  text_white: '#ffffff',
  alice_blue: '#F6F7F9 ',
  shadow_black: '#00000029',
  grey_dark_50: '#7F7C7C',
  light_blue_50: '#E5ECFC',
  rs_disabled_color: '#F7F7F7',
  blue_text: '#0027A7',
  blue_bg: '#434DB84D',
  aqua_green: '#03C582',
  aqua_green_bg: '#03C58240',
  green_bg: '#5CA7006E',
  severityGraphColor: ['#FA1B0D', '#DEC834', '#434DB8', '#16A95B'],
  priorityGraphColor: ['#FA1B0DBF', '#DEC834BF', '#434DB8BF'],
  defectscycleTimeColor: ['#434db8', '#C40304', '#5CA700'],
  red_dark: '#B32C4A',
  yellow_dirty: '#EFBB07',
  blue_bright: '#2862FF',
  blue_saleforce: '#147AD6',
  golden_Poppy: '#FFC600',
  rs_red: '#C50303',
  bright_blue: '#007dbc',
  rs_grey: '#525252',
  text_black: '#000000',
  button_green_50: '#43B84F',
  button_green_60: '#079328',
  button_red_50: '#D11414',
  button_red_60: '#C50303',
  button_blue_50: '#434DB8',
  silver: '#C5C5C5',
  light_grey: '#BABABA',
  light_gray_50: '#F2F2F2',
  light_gray_60: '#6F7070',
  blue_light_50: '#3a62c9',
  tooltip_shadow: 'rgba(0, 0, 0, 0.85)',
  gray_radio_button: '#A6A6A65E',
  light_gray_100: '#aaa',
  soft_blue: '#a0aec0',
  gray: '#808080',
  soft_grey: '#CCCCCC',
  icon_orange: '#f8981c',
  video_play_background: '#F7F8F8',
  ff_cobalt_blue: '#434db81c',
  ff_lavender_purple: ' #4d48bd',
  ff_white_shade: '#00000040',
  ff_black_shade: '#0a0a0a',
};

export const statisticsModuleChartColor = [
  '#BA90C6',
  '#E8A0BF',
  '#C0DBEA',
  '#FA9884',
  '#6AB187',
  '#009FBD',
  '#77037B',
  '#FF6000',
  '#C7E9B0',
  '#FFA559',
  '#FFD93D',
  '#4F200D',
  '#89375F',
  '#BACDDB',
  '#27E1C1',
  '#19376D',
  '#B2A4FF',
  '#FEFF86',
  '#ABC4AA',
  '#E11299',
  '#7149C6',
  '#245953',
  '#660000',
  '#BCB485',
  '#D5B4B4',
  '#FFBF9B',
  '#B46060',
  '#E4DCCF',
  '#BBD6B8',
  '#B303D0',
  '#655DBB',
  '#E30B5D',
  '#006400',
  '#B8E0D2',
  '#0081C9',
  '#DFABE4',
  '#FFDB58',
  '#DFA8E4',
  '#CEEDC7',
  '#86C8BC',
  '#FF597B',
  '#F9B5D0',
  '#1B4E4F',
  '#CCD6A6',
  '#f4978e',
  '#82c0cc',
  '#9f86c0',
  '#00ff7e',
  '#dc2f02',
  '#4cc9f0',
  '#d4e09b',
  '#168aad',
];

export const getColorCode = (name) => {
  switch (name?.toLowerCase()) {
    case 'blocker':
      return `#F62817`;
    case 'critical':
      return `#F87217`;
    case 'major':
      return `#659ec7`;
    case 'minor':
      return `#41A317`;
    case 's1 - catastrophic':
      return `#660000`;
    case 's2 - serious':
      return `#FFAD34`;
    case 's3 - normal':
      return `#00008B`;
    case 's4 - small/trivial':
      return `#006400`;
    case 'high':
      return `#FA1B0DBF`;
    case 'medium':
      return `#DEC834BF`;
    case 'low':
      return `#434DB8BF`;
    case 'block':
      return `#FF6347`;
    case 'crash':
      return `#B303D0`;
    case 'tweak':
      return `#DE7ABD`;
    case 'trivial':
      return `#686A6C`;
    case 'text':
      return `#FFDB58`;
    case 'feature':
      return `#22E6D2`;
    case 'new':
      return `#434db8`;
    case 'assigned':
      return `#05A3A4`;
    case 'duplicate':
      return `#ff1600`;
    case 'rejected':
      return `#8C8F94`;
    case 'deffered':
      return `#FA5081`;
    case 'not a defect':
      return `#008337`;
    case 'reopened':
      return `#C06C84`;
    case 'fixed':
      return `#C5DE42`;
    case 'closed':
      return `#1B4E4F`;
    case 'resolved':
      return `#00ff7e`;
    case 'verified':
      return `#00C6FE`;
    case 'unconfirmed':
      return `#FCD535`;
    case 'confirmed':
      return `#BCB485`;
    case 'acknowledge':
      return `#B303D0`;
    case 'feedback':
      return `#898FC0`;
    case 'none':
      return `#b48395`;
    case 'urgent':
      return `#E30B5D`;
    case 'normal':
      return `#8ECAE6`;
    case 'immediate':
      return `#ffdf00`;
    default:
      return '#111';
  }
};
