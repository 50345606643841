import React, { useState, useRef, useEffect } from 'react';
import {
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  TextareaAutosize,
  makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { useAlert } from '@src/pages/common/alert_service/useAlert';
import { fontPoppins } from '@src/css_config/fontConstants';
import TreeWithRadioButton from '@src/pages/common/table_tree/table-tree-with-radio-button';
import { getElementTypeOptions, getLocatorTypeOptions } from './ElementModal.utils';
import LocatorTableForm from '@src/pages/repository/components/project_elements/modals/LocatorTableForm';
import {
  addEleAsSharedEleReq,
  createVisualTestingElementReq,
  delElementReq,
  postElementReq,
  putElementReq,
  unShareSharedEleReq,
  updateSharedElementReq,
  updateVisualTestingElementReq,
  updateVisualTestingSharedElementReq,
} from '@src/api/api_services';
import ImageLocator from '@src/pages/repository/components/project_elements/modals/ImageLocator';
import { getTruncatedText, isEmptyObject, isEmptyValue } from '@src/util/common_utils';
import { colors } from '@src/css_config/colorConstants';
import { UI_VALIDATIONS } from '@src/util/validations';
import { VISUAL_TESTING_IMAGE_FILE_EXTENTION } from '@src/common/ui-constants';

const useStylesElementType = makeStyles((theme) => ({
  select: {
    minWidth: 295,
    borderRadius: 8,
    ...fontPoppins.rMd,
    '&:focus': {
      borderRadius: 12,
      background: 'none',
    },
  },
  menuPaper: {
    maxHeight: 300,
    fontSize: fontPoppins.rMd,
  },
  menulist: {
    maxWidth: 390,
    margin: 0,
    padding: 0,
    listStyle: 'none',
    height: '200px',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colors.soft_blue,
    },
    paddingTop: 15,
    paddingBottom: 0,
    fontSize: fontPoppins.rMd,
    background: 'none',
    '& li': {
      ...fontPoppins.rMd,
      color: colors.grey_dark,
    },
    '& li:hover': {
      background: colors.sky_blue_dark,
      color: colors.blue_dark,
    },
    '& li.Mui-selected': {
      color: colors.blue_dark,
      background: colors.sky_blue_dark,
    },
    '& li.Mui-selected:hover': {
      background: colors.sky_blue_dark,
      color: colors.blue_dark,
    },
  },
}));

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: colors.light_gray_100,
    fontSize: 12,
  },
}));

export const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

const initialSuggestionValues = ['Dynamic Val1', 'Dynamic Val2', 'Dynamic Val3', 'Dynamic Val4', 'Dynamic Val5'];

const ElementModal = (props) => {
  let createAndContinue = false;
  const classesElementType = useStylesElementType();
  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const [showModal, setShowModal] = useState(true);
  const label =
    props.projectType === 'Mobile'
      ? 'Screen'
      : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) && props.platForm === 'Web'
      ? 'Page'
      : ['Salesforce', 'Web & Mobile', 'MS Dynamics']?.includes(props?.projectType) &&
        ['Android', 'iOS', 'Ios', 'MobileWeb', 'Mobile']?.includes(props?.platForm)
      ? 'Screen'
      : 'Page';
  const [elementTypeOptions] = useState(getElementTypeOptions(props.platForm));
  const [executionOrder, setExecutionOrder] = useState(
    props.nodeToAdd ? (props.nodeToAdd === 0 ? 1 : props.nodeToAdd.data.lastExecutionOrder + 1) : null
  );
  const [hierarchy, setHierarchy] = useState(
    props.nodeToAdd ? (props.nodeToAdd === 0 ? 1 : props.nodeToAdd.data.hierarchy + 1) : 1
  );
  const [selectedParentNode, setSelectedParentNode] = useState();
  const [copyToSharedElement, setCopyToSharedElement] = useState(false);
  const [resetFormState, setResetFormState] = useState(false);
  const formRef = useRef();
  const [elementInitialData, setElementInitialData] = useState();
  const [locatorIndexs, setLocatorIndexs] = useState([0]);
  const [iosLocatorIndexes, setIosLocatorIndexes] = useState(props?.platForm === 'Mobile' ? [0] : []);
  const [table1DisabledOptions, setTable1DisabledOptions] = useState([]);
  const [table2DisabledOptions, setTable2DisabledOptions] = useState([]);
  const [dynamicValSuggestion, setDynamicValSuggestion] = useState(initialSuggestionValues);
  const [previousData, setPreviousData] = useState();
  const [createUpdateCalled, setCreateUpdateCalled] = useState(false);
  const [noOfRows, setNoOfRows] = useState(1);
  const [descCount, setDescCount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedType, setSelectedType] = useState('');
  const platform = props?.platForm;
  const sectionName = 'Visual Testing';

  const resetDynamicValSuggestion = (usedValue) => {
    let numArray = dynamicValSuggestion.map((val) => Number(val?.slice(11)));
    const maxNumber = Math.max(...numArray) + 1;
    let tempValSuggestion = [...dynamicValSuggestion];
    tempValSuggestion = tempValSuggestion.filter((val) => val !== usedValue);
    tempValSuggestion.push(`Dynamic Val${maxNumber}`);
    setDynamicValSuggestion([...tempValSuggestion]);
  };

  const checkForDynamicVal = (inpvalue, indexedLoc) => {
    if (formRef.current.values?.[indexedLoc]?.locType === 'dynamic') {
      const regex = /{([^}]+)}/g;
      const matches = inpvalue?.match(regex);
      if (matches) {
        let isValid = true;
        const extractedValues = matches?.map((match) => match?.slice(1, -1));
        extractedValues?.forEach((data) => {
          const placeholderPattern = /Dynamic Val[0-9]+/g;
          if (!placeholderPattern.test(data)) {
            isValid = false;
          }
        });
        return isValid;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const checkForvalidbracket = (inpvalue, indexedLoc) => {
    if (formRef.current.values?.[indexedLoc]?.locType === 'dynamic') {
      const regex = /\{Dynamic Val[A-Za-z0-9\s]+\}/g;
      const matches = inpvalue?.split(regex);
      if (matches) {
        let isValid = true;
        const extractedValues = matches?.filter((part) => part.trim() !== '');
        extractedValues?.map((data) => {
          if (data?.includes('{') || data?.includes('}')) {
            isValid = false;
          }
        });
        return isValid;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

 

  const hasDynamicVal = (inpvalue, indexedLoc) => {
    if (formRef.current?.values?.[indexedLoc]?.locType === 'dynamic') {
      const placeholderPattern = /\{Dynamic Val[0-9]+\}/g;
      return placeholderPattern.test(inpvalue);
    } else {
      return true;
    }
  };

  const checkForUniqueLocValue = (inpvalue, indexedLoc) => {
    const formValues = formRef.current?.values;

    const table1LocatorValues = Object.keys(formValues)
      .filter((key) => key?.startsWith('table1Locator'))
      .filter((key) => formValues[key]?.locName === formValues?.[indexedLoc]?.locName)
      .map((key) => formValues[key]?.locValue);

    const count = table1LocatorValues?.filter((value) => value === inpvalue)?.length;
    return count <= 1;
  };

  let initialSchema = {
    name: yup
      .string()
      .required('Name is required')
      .matches(/^\S.*\S$/gm, 'Space is not allowed in the beginning and at the end')
      .matches(/.*\S.*/, 'Only whitespaces are not allowed')
      .min(2, 'Name must be minimum 2 characters'),
    type: yup.string().ensure().required('Type is required'),
    parentName: yup.string().required(`Parent ${label.toLowerCase()} is required `),
    [`table1Locator${locatorIndexs[0]}`]:
      props?.platForm === 'Mobile'
        ? yup.object().shape({
            locName: yup.string().when(['table2Locator' + iosLocatorIndexes[0]], {
              is: (value) => {
                return value;
              },
              then: yup.string().required('Locator type is required'),
              otherwise: yup.string().optional(),
            }),
            locType: yup.string().when(['table2Locator' + iosLocatorIndexes[0]], {
              is: (value) => value,
              then: yup.string().required('Value type is required'),
              otherwise: yup.string().optional(),
            }),
            locValue: yup
              .string()
              .when(['table2Locator' + iosLocatorIndexes[0]], {
                is: (value) => value,
                then: yup.string().required('Locator value is required'),
                otherwise: yup.string().optional(),
              })
              .matches(/.*\S.*/, 'Only whitespaces are not allowed')
              .test('dynamic-validation', 'Dynamic value is required', (inpvalue, context) => {
                return hasDynamicVal(inpvalue, `table1Locator${locatorIndexs[0]}`);
              })
              .test('is-dynamic-value', 'Proper Dynamic value is required', (inpvalue, context) => {
                return checkForDynamicVal(inpvalue, `table1Locator${locatorIndexs[0]}`);
              })
              .test('invalid-entry', 'Proper Dynamic value is required', (inpvalue, context) => {
                return checkForvalidbracket(inpvalue, `table1Locator${locatorIndexs[0]}`);
              })
              .test('unique-option', 'Locator value should be unique', (inpvalue, context) => {
                return checkForUniqueLocValue(inpvalue, `table1Locator${locatorIndexs[0]}`);
              }),
          })
        : yup.object().shape({
            locName: yup.string().required('Locator type is required'),
            locType: yup.string().required('Value type is required'),
            locValue: yup
              .string()
              .required('Locator value is required')
              .matches(/.*\S.*/, 'Only whitespaces are not allowed')
              .test('dynamic-validation', 'Dynamic value is required', (inpvalue) => {
                return hasDynamicVal(inpvalue, `table1Locator${locatorIndexs[0]}`);
              })
              .test('is-dynamic-value', 'Proper Dynamic value is required', (inpvalue) => {
                return checkForDynamicVal(inpvalue, `table1Locator${locatorIndexs[0]}`);
              })
              .test('invalid-entry', 'Proper Dynamic value is required', (inpvalue) => {
                return checkForvalidbracket(inpvalue, `table1Locator${locatorIndexs[0]}`);
              })
              .test('unique-option', 'Locator value should be unique', (inpvalue) => {
                return checkForUniqueLocValue(inpvalue, `table1Locator${locatorIndexs[0]}`);
              }),
          }),
  };
  let initValues = {
    name: '',
    type: '',
    description: '',
    parentName: '',
    parentId: '',
    radioTypeForAndroid: 'radio-path-local',
    androidLocatorFromLocalPath: [],
    androidLocatorFromTestData: [],
    [`table1Locator${locatorIndexs[0]}`]: {
      locName: '',
      locType: '',
      locValue: '',
      data: {
        value: '',
        type: '',
        name: '',
        status: 'NOT_USED',
        priority: '',
        isRecorded: 'N',
        defaultAddedFirst: false,
        defaultAddedSecond: false,
      },
    },
  };
  if (props?.platForm === 'Mobile') {
    initValues = {
      ...initValues,
      radioTypeForIOS: 'radio-path-local',
      radioTypeForAndroid: 'radio-path-local',
      iosLocatorFromLocalPath: [],
      iosLocatorFromTestData: [],
      [`table2Locator${iosLocatorIndexes[0]}`]: {
        locName: '',
        locType: '',
        locValue: '',
        data: {
          value: '',
          type: '',
          name: '',
          status: 'NOT_USED',
          isRecorded: 'N',
          priority: '',
          defaultAddedFirst: false,
          defaultAddedSecond: false,
        },
      },
    };

    initialSchema = {
      ...initialSchema,
      [`table2Locator${iosLocatorIndexes[0]}`]: yup.object().shape({
        locName: yup.string().when(`table1Locator${locatorIndexs[0]}`, {
          is: (value) => {
            return value;
          },
          then: yup.string().required('Locator type is required'),
          otherwise: yup.string().optional(),
        }),
        locType: yup.string().when(`table1Locator${locatorIndexs[0]}`, {
          is: (value) => value,
          then: yup.string().required('Value type is required'),
          otherwise: yup.string().optional(),
        }),
        locValue: yup
          .string()
          .when(`table1Locator${locatorIndexs[0]}`, {
            is: (value) => value,
            then: yup.string().required('Locator value is required'),
            otherwise: yup.string().optional(),
          })
          .matches(/.*\S.*/, 'Only whitespaces are not allowed')
          .test('dynamic-validation', 'Dynamic value is required', (inpvalue, context) => {
            return hasDynamicVal(inpvalue, `table2Locator${locatorIndexs[0]}`);
          })
          .test('is-dynamic-value', 'Proper Dynamic value is required', (inpvalue, context) => {
            return checkForDynamicVal(inpvalue, `table2Locator${locatorIndexs[0]}`);
          })
          .test('invalid-entry', 'Proper Dynamic value is required', (inpvalue, context) => {
            return checkForvalidbracket(inpvalue, `table2Locator${locatorIndexs[0]}`);
          })
          .test('unique-option', 'Locator value should be unique', (inpvalue, context) => {
            return checkForUniqueLocValue(inpvalue, `table2Locator${locatorIndexs[0]}`);
          }),
      }),
    };
  }
  if (props.mode === 'ADD_SUB') {
    initValues = {
      ...initValues,
      parentName: props.parentName,
      parentId: props.parentId,
    };
  }
  const [validationSchema, setValidationSchema] = useState(yup.object(initialSchema));
  const [currentSchema, setCurrentSchema] = useState({ ...initialSchema });
  const [initialValues, setInitialValues] = useState({ ...initValues });
  useEffect(() => {
    const schema = selectedType === 'visual testing' ? visualTestingSchema(label) : initialSchema;

    setValidationSchema(yup.object(schema));
    setCurrentSchema(schema);
  }, [label, selectedType]);

  const visualTestingSchema = (label) => {
    return {
      name: yup
        .string()
        .required('Name is required')
        .matches(/^\S.*\S$/gm, 'Space is not allowed in the beginning and at the end')
        .matches(/.*\S.*/, 'Only whitespaces are not allowed')
        .min(2, 'Name must be minimum 2 characters'),
      type: yup.string().ensure().required('Type is required'),
      parentName: yup.string().required(`Parent ${label.toLowerCase()} is required `),
      description: yup.string().optional(),
    };
  };

  const buildSchemaWithvalue = (datatype, value, fieldKey) => {
    return {
      [`${fieldKey + value}`]: yup.object().shape({
        locName: yup.string().required('Locator type is required'),
        locType: yup.string().required('Value type is required'),
        locValue: yup
          .string()
          .required('Locator value is required')
          .matches(/.*\S.*/, 'Only whitespaces are not allowed')
          .test('dynamic-validation', 'Dynamic value is required', (inpvalue) => {
            return hasDynamicVal(inpvalue, `${fieldKey + value}`);
          })
          .test('is-dynamic-value', 'Proper Dynamic value is required', (inpvalue) => {
            return checkForDynamicVal(inpvalue, `${fieldKey + value}`);
          })
          .test('invalid-entry', 'Proper Dynamic value is required', (inpvalue) => {
            return checkForvalidbracket(inpvalue, `${fieldKey + value}`);
          })
          .test('unique-option', 'Locator value should be unique', (inpvalue) => {
            return checkForUniqueLocValue(inpvalue, `${fieldKey + value}`);
          }),
      }),
    };
  };
  const buildInitialValues = (datatype, value, fieldKey) => {
    return {
      [`${fieldKey + value}`]: {
        locName: '',
        locType: '',
        locValue: '',
        data: {
          value: '',
          type: '',
          name: '',
          status: 'NOT_USED',
          isRecorded: 'N',
          priority: '',
          defaultAddedFirst: false,
          defaultAddedSecond: false,
        },
      },
    };
  };
  const addLocator = (data, index, fieldKey) => {
    let tempLocatorsIndex = fieldKey === 'table1Locator' ? [...locatorIndexs] : [...iosLocatorIndexes];
    const isFieldHasValue = () => {
      const { locName, locType, locValue } = formRef.current.values[fieldKey + data] || {};
      return ![locName, locType, locValue].includes('');
    };

    if (!formRef.current.errors[fieldKey + data] && isFieldHasValue()) {
      let updatedSchema;
      let updateInitialValues;
      tempLocatorsIndex.push(data + 1);
      if (fieldKey === 'table1Locator') {
        setLocatorIndexs([...tempLocatorsIndex]);
      } else {
        setIosLocatorIndexes([...tempLocatorsIndex]);
      }
      updatedSchema = {
        ...currentSchema,
        ...buildSchemaWithvalue('locators', data + 1, fieldKey),
      };
      updateInitialValues = {
        ...formRef.current.values,
        ...buildInitialValues('locators', data + 1, fieldKey),
      };
      setCurrentSchema({ ...updatedSchema });
      setInitialValues({ ...updateInitialValues });
      setValidationSchema(yup.object(updatedSchema));
      formRef.current.values = updateInitialValues;
    } else {
      formRef.current.setTouched(
        {
          ...formRef.current.touched,
          [`${fieldKey + data}`]: {
            locName: true,
            locType: true,
            locValue: true,
            data: true,
          },
        },
        true
      );
      formRef.current.setFieldError('locator value is required', true);
    }
  };
  const deleteLocator = (data, index, tableName) => {
    let tempLocatorsIndex = tableName === 'table1Locator' ? [...locatorIndexs] : [...iosLocatorIndexes];
    let updatedSchema = { ...currentSchema };
    let updateInitialValues = { ...formRef.current.values };
    tempLocatorsIndex.splice(index, 1);
    if (tableName === 'table1Locator') {
      setLocatorIndexs([...tempLocatorsIndex]);
    } else {
      setIosLocatorIndexes([...tempLocatorsIndex]);
    }
    delete updatedSchema?.[`${data}`];
    delete updateInitialValues?.[`${data}`];
    setCurrentSchema({ ...updatedSchema });
    setInitialValues({ ...updateInitialValues });
    setValidationSchema(yup.object(updatedSchema));
    formRef.current.setFieldValue('table1Locator', false);
    formRef.current.setFieldError('locator value is required', false);
  };

  const resetForm = () => {
    setValidationSchema(yup.object(initialSchema));
    setCurrentSchema({ ...initialSchema });
    if (props.mode === 'ADD') {
      let perviousInitvalues = {
        ...initValues,
        parentId: formRef?.current?.values?.parentId,
        parentName: formRef?.current?.values?.parentName,
      };
      formRef.current.resetForm({ values: perviousInitvalues });
      setInitialValues(perviousInitvalues);
    } else {
      formRef.current.resetForm({ values: initValues });
      setInitialValues({ ...initValues });
    }
    setDynamicValSuggestion(initialSuggestionValues);
    setCopyToSharedElement(false);
    setLocatorIndexs([0]);
    setIosLocatorIndexes(props?.platForm === 'Mobile' ? [0] : []);
    setTable1DisabledOptions([]);
    setTable2DisabledOptions([]);
    setDescCount(0);
    setNoOfRows(1);
    setResetFormState(false);
  };
  const { MAX_CHARACTER_COUNT } = UI_VALIDATIONS;
  const createElementReq = async (payload, pageKey, button) => {
    try {
      if (copyToSharedElement) {
        const response =
          selectedType === 'visual testing'
            ? await createVisualTestingElementReq(payload, pageKey, sectionName)
            : await addEleAsSharedEleReq(pageKey, payload);
        if (response.data?.status === 'SUCCESS') {
          if (button === 'create') {
            props.closeModal(false);
            props.reloadTree(true);
            if (response?.data?.responseObject) {
              const { name, type } = response?.data?.responseObject;
              props.MyAlert.success(
                `${getTruncatedText(
                  name,
                  MAX_CHARACTER_COUNT
                )} ${type} created and shared to shared elements page successfully`
              );
            }
            setIsDisabled(true);
          } else {
            setCreateUpdateCalled(false);
            if (props.mode === 'ADD_SUB') {
              setResetFormState(true);
            }
            props.reloadTree(true);
            resetForm();
            if (response?.data?.responseObject) {
              const { name, type } = response?.data?.responseObject;
              props.MyAlert.success(
                `${getTruncatedText(
                  name,
                  MAX_CHARACTER_COUNT
                )} ${type} created and shared to shared elements page successfully`
              );
            }
            setIsDisabled(true);
          }
        } else if (
          response.data?.responseCode === 400 &&
          response.data?.message ===
            'Their is a conflict in Shared element would you like to crete New element or Merge into shared element'
        ) {
          props.closeModal(false);
          const convertFormDataToObject = (formData) => {
            const obj = {};
            formData.forEach((value, key) => {
              obj[key] = value;
            });
            if (obj?.element && typeof obj.element === 'string') {
              try {
                obj.element = JSON.parse(obj.element);
              } catch (error) {
                console.error('Error parsing JSON string:', error);
              }
            }
            return obj?.element;
          };
          const payloadData = payload instanceof FormData ? convertFormDataToObject(payload) : payload;
          props.createAsSharedElement(payloadData, pageKey, 'create', response);
        } else if (
          response.data?.responseCode === 400 &&
          (response.data?.message ===
            `Element ${getTruncatedText(payload?.name, MAX_CHARACTER_COUNT)} and type ${payload.type} with page Id ${
              payload.parentId
            } already exist in Shared Element` ||
            response.data?.message ===
              `Element ${getTruncatedText(payload?.name, MAX_CHARACTER_COUNT)} and type ${
                payload.type
              } with page name ${payload.parentName} already exist` ||
            response.data?.message ===
              `SharedElement :${getTruncatedText(payload?.name, MAX_CHARACTER_COUNT)} and type ${
                payload.type
              } already exist in Project Elements`)
        ) {
          MyAlert.info(
            `Element ${getTruncatedText(payload?.name, MAX_CHARACTER_COUNT)} and ${
              payload?.type
            } already exist within selected page`
          );
          setCreateUpdateCalled(false);
        } else if (response.data.responseCode === 400 && response.data.status) {
          MyAlert.info(`${response.data.status}`);
        } else {
          props.MyAlert.warning(`${response.data.message}`);
          props.closeModal(false);
          props.reloadTree(true);
        }
      } else {
        const response =
          selectedType === 'visual testing'
            ? await createVisualTestingElementReq(payload, pageKey, sectionName)
            : await postElementReq(payload, pageKey);
        if (response?.data?.responseObject) {
          if (button === 'create') {
            props.closeModal(false);
            props.reloadTree(true);
            if (response?.data?.responseObject) {
              const { name, type } = response?.data?.responseObject;
              props.MyAlert.success(`${getTruncatedText(name, MAX_CHARACTER_COUNT)} ${type} created successfully`);
            }
            setIsDisabled(true);
          } else {
            setCreateUpdateCalled(false);
            setExecutionOrder(executionOrder + 1);
            if (response?.data?.responseObject) {
              const { name, type } = response?.data?.responseObject;
              props.MyAlert.success(`${getTruncatedText(name, MAX_CHARACTER_COUNT)} ${type} created successfully`);
            }
            setIsDisabled(true);
            if (props.mode === 'ADD_SUB') {
              setResetFormState(true);
            }
            resetForm();
            props.reloadTree(true);
          }
        } else if (
          response?.data?.responseCode === 400 &&
          response?.data?.status &&
          response.data?.message?.includes('Exceed')
        ) {
          props.MyAlert.info(`Exceeded allocated limit, please upgrade or buy new license`);
          props.closeModal(false);
        } else {
          const baseMessage = `Element ${getTruncatedText(payload?.name, MAX_CHARACTER_COUNT)} and type ${
            payload.type
          } already exist within selected page`;
          let finalMessage = baseMessage;
          if (selectedType === 'visual testing') {
            const fullMessage = response?.data?.message;
            const croppedMessage = fullMessage.substring(0, fullMessage.indexOf(selectedType) + selectedType.length);
            finalMessage = `${croppedMessage} already exist within selected page`;
          }
          MyAlert.info(finalMessage);
          setCreateUpdateCalled(false);
        }
      }
    } catch (error) {
      console.error('CREATE_ELEMENT_REQ :', error);
    }
  };

  async function updateElementReq(pageKey, elementId, payload, data) {
    try {
      let response;
      const changingToVisualTesting = payload.type === 'visual testing' && props.data.type !== 'visual testing';
      const headerData = {
        sourceName: payload?.name,
        sourceType: payload?.type,
      };
      if (['YES', 'Y'].includes(payload.isShared)) {
        const { executionOrders, parentIds } = props?.data;
        payload['parentIds'] = parentIds;
        payload['executionOrders'] = executionOrders;
        if (changingToVisualTesting) {
          await unShareSharedEleReq(payload?.parentId, props?.data?.id);
          await delElementReq(payload?.parentId, payload?.elementId, headerData);
          delete payload.id;
          delete payload.elementId;
          data.set('element', JSON.stringify(payload));
          response = await createVisualTestingElementReq(data, pageKey, sectionName);
          if (response?.data?.status === 'SUCCESS' && response?.data?.responseCode === 200) {
            payload.id = response?.data?.responseObject?.id;
            payload.elementId = response?.data?.responseObject?.elementId;
            data.set('sharedElement', JSON.stringify(payload));
            response = await updateVisualTestingSharedElementReq(props.data.id, data, sectionName);
            if (response?.data?.status === 'SUCCESS' && response?.data?.responseCode === 200) {
              props.MyAlert.success(
                `${getTruncatedText(payload?.name, MAX_CHARACTER_COUNT)} ${payload?.type} updated successfully`
              );
            }
            setIsDisabled(true);
          }
        } else if (selectedType === 'visual testing') {
          data.delete('element');
          data.set('sharedElement', JSON.stringify(payload));
          response = await updateVisualTestingSharedElementReq(props.data.id, data, sectionName);
        } else {
          response = await updateSharedElementReq(props.data.id, payload);
        }
      } else if (changingToVisualTesting) {
        await delElementReq(payload?.parentId, payload?.elementId, headerData);
        delete payload.id;
        delete payload.elementId;
        data.set('element', JSON.stringify(payload));
        response = await createVisualTestingElementReq(data, pageKey, sectionName);
        if (response?.data?.status === 'SUCCESS' && response?.data?.responseCode === 200) {
          payload['id'] = response?.data?.responseObject?.id;
          payload['elementId'] = response?.data?.responseObject?.elementId;
          data.set('element', JSON.stringify(payload));
          response = await updateVisualTestingElementReq(pageKey, payload.id, data, sectionName);
          if (response?.data?.status === 'SUCCESS' && response?.data?.responseCode === 200) {
            props.MyAlert.success(
              `${getTruncatedText(payload?.name, MAX_CHARACTER_COUNT)} ${payload?.type} updated successfully`
            );
          }
          setIsDisabled(true);
        }
      } else if (selectedType === 'visual testing') {
        data.set('element', JSON.stringify(payload));
        response = await updateVisualTestingElementReq(pageKey, elementId, data, sectionName);
      } else {
        response = await putElementReq(pageKey, elementId, payload);
      }
      if (response?.data?.responseObject) {
        props.closeModal(false);
        props.reloadTree(true);
        if (props.updateEditedElement) {
          props.updateEditedElement(response.data.responseObject);
        }
        props.MyAlert.success(
          `${getTruncatedText(payload?.name, MAX_CHARACTER_COUNT)} ${payload?.type} updated successfully`
        );
        setIsDisabled(true);
      } else {
        MyAlert.info(`Element ${payload.name} and type ${payload.type} already exist within selected page`);
        setCreateUpdateCalled(false);
        setIsDisabled(false);
      }
    } catch (error) {
      console.error('UPDATE_ELEMENT_REQ :', error);
    }
  }
  const checkValuesAreChanged = (values) => {
    if (previousData.name !== values.name) {
      return true;
    } else if (previousData.type !== values.type) {
      return true;
    } else if (previousData.desc !== values.desc) {
      return true;
    } else if (previousData.locators?.length !== values.locators?.length) {
      return true;
    } else {
      for (let index = 0; index < previousData.locators.length; index++) {
        if (
          previousData.locators[index].name !== values.locators[index].name ||
          previousData.locators[index].type !== values.locators[index].type ||
          previousData.locators[index].value !== values.locators[index].value ||
          previousData.locators[index].os !== values.locators[index].os
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const updateElement = (data) => {
    let payload;
    if (selectedType === 'visual testing') {
      payload = JSON.parse(data.get('element'));
    } else {
      payload = data;
    }
    if (checkValuesAreChanged(payload)) {
      const { isRecorded, isShared, platform, hierarchy, executionOrder, id, elementId, parentId } = previousData || {};
      payload = {
        ...payload,
        isShared,
        isRecorded,
        platform,
        hierarchy,
        executionOrder,
        id,
        elementId,
      };
      updateElementReq(parentId, id, payload, data);
    } else {
      props.MyAlert.info(`Nothing there to update`);
      props.closeModal(false);
    }
  };

  const onSubmit = (values) => {
    if (createUpdateCalled) {
      return;
    }
    const { name, description: desc, type, parentName, parentId } = values || {};
    let requestBody = {
      name,
      desc,
      type,
      parentName,
      parentId,
      isRecorded: 'N',
      folder: false,
      isShared: copyToSharedElement ? 'YES' : 'N',
      projectType: props.projectType,
      projectId: project.id,
      platform: props?.platForm,
      executionOrder: executionOrder || '',
      hierarchy: hierarchy || '',
    };
    let locators = [];
    let isLocatorsValid = true;
    locatorIndexs.forEach((indexKey) => {
      const key = 'table1Locator' + indexKey;
      if (values[key].locType === 'dynamic' && !values[key]?.locValue?.includes('{')) {
        formRef.current?.setFieldError(`${key}.locValue`, 'Dynamic value is required', true);
        isLocatorsValid = false;
        return;
      }
      const inputValue =
        values[key].locType === 'dynamic'
          ? values[key]?.locValue?.replaceAll('Dynamic Val', 'dynamic')
          : values[key]?.locValue;
      let locator = {
        ...values[key]?.data,
        name: values[key]?.locName,
        type: values[key]?.locType,
        value: inputValue,
        fieldName: `${key}.locValue`,
      };
      if (props?.platForm === 'Mobile') {
        locator['os'] = 'Android';
      }
      if (values[key]?.locName && values[key]?.locType && inputValue) {
        locators.push(locator);
      }
    });
    if (props?.platForm === 'Mobile') {
      iosLocatorIndexes.forEach((indexKey) => {
        const key = 'table2Locator' + indexKey;
        if (values[key]?.locType === 'dynamic' && !values[key]?.locValue?.includes('{')) {
          formRef.current?.setFieldError(`${key}.locValue`, 'Dynamic value is required', true);
          isLocatorsValid = false;
          return;
        }
        const inputValue =
          values[key]?.locType === 'dynamic'
            ? values[key]?.locValue?.replaceAll('Dynamic Val', 'dynamic')
            : values[key]?.locValue;
        if (values[key]?.locName && values[key]?.locType && inputValue) {
          locators.push({
            ...values[key]?.data,
            name: values[key]?.locName,
            type: values[key]?.locType,
            value: inputValue,
            fieldName: `${key}.locValue`,
            os: 'iOS',
          });
        }
      });
    }
    if (!isLocatorsValid) {
      setCreateUpdateCalled(false);
      return;
    }
    if (!Object.keys(formRef.current.errors).length) {
      requestBody['locators'] = [...locators];
      if (['ADD', 'ADD_SUB'].includes(props.mode)) {
        const createMode = createAndContinue ? 'createAndContinue' : 'create';
        createElementReq(requestBody, requestBody.parentId, createMode);
      } else {
        updateElement(requestBody);
      }
    }
  };

  const getImageExtention = (file, testData) => {
    if (testData) {
      let extension = file?.name?.split('.');
      return extension[extension.length - 1];
    } else {
      let extension = file?.type?.split('/');
      return extension[1]?.toLowerCase();
    }
  };

  const onSubmitImageLocator = (values) => {
    let {
      name,
      description: desc,
      type,
      parentName,
      parentId,
      radioTypeForAndroid,
      radioTypeForIOS,
      androidLocatorFromLocalPath,
      iosLocatorFromLocalPath,
      androidLocatorFromTestData,
      iosLocatorFromTestData,
    } = values || {};
    const androidVisualTestingLocatorArray =
      radioTypeForAndroid === 'radio-path-local' ? androidLocatorFromLocalPath : androidLocatorFromTestData;
    const iosVisualTestingLocatorArray =
      props?.platForm === 'Mobile'
        ? radioTypeForIOS === 'radio-path-local'
          ? iosLocatorFromLocalPath
          : iosLocatorFromTestData
        : [];

    function getFilteredLocators(mode, locators) {
      return mode === 'EDIT' ? locators?.filter((locator) => !locator?.filePath) : locators;
    }
    const androidFilteredLocators = getFilteredLocators(props?.mode, androidLocatorFromLocalPath || []);
    const iosFilteredLocators = getFilteredLocators(props?.mode, iosLocatorFromLocalPath || []);

    let formData = new FormData();
    if (!isEmptyObject(androidFilteredLocators) || !isEmptyObject(iosFilteredLocators)) {
      if (props?.platForm === 'Mobile') {
        formData.append('file', null);
        if (!isEmptyObject(androidFilteredLocators)) {
          androidFilteredLocators?.forEach((file) => {
            formData?.append('androidFile', file);
          });
        } else {
          formData?.append('androidFile', null);
        }
        if (!isEmptyObject(iosFilteredLocators)) {
          iosFilteredLocators?.forEach((file) => {
            formData?.append('iosFile', file);
          });
        } else {
          formData?.append('iosFile', null);
        }
      } else {
        androidFilteredLocators?.forEach((file) => {
          formData?.append('file', file);
        });
        formData?.append('androidFile', null);
        formData?.append('iosFile', null);
      }
    }

    let requestBody = {
      name,
      desc,
      type: type,
      parentName,
      parentId,
      isRecorded: 'N',
      folder: false,
      isShared: copyToSharedElement ? 'YES' : 'N',
      projectType: props.projectType,
      projectId: project.id,
      platform: props?.platForm,
      executionOrder: executionOrder || '',
      hierarchy: hierarchy || '',
      testData: radioTypeForAndroid !== 'radio-path-local' ? true : false,
    };

    const createLocator = (file, index, platform, os, testData) => {
      const filename = file?.name;
      const extension = filename.match(/\.([^.@]+)$/)?.[1] || '';
      const fileExtension = VISUAL_TESTING_IMAGE_FILE_EXTENTION.includes(extension);
      const key = platform === 'iOS' ? 'table2Locator' : 'table1Locator';
      const locType = ['ADD', 'ADD_SUB'].includes(props.mode)
        ? fileExtension && getImageExtention(file, testData)
        : VISUAL_TESTING_IMAGE_FILE_EXTENTION.includes(file?.type)
        ? file?.type
        : getImageExtention(file, testData);

      const locator = {
        type: locType,
        name: file.name,
        status: 'NOT_USED',
        priority: '',
        isRecorded: 'N',
        defaultAddedFirst: false,
        defaultAddedSecond: false,
        fieldName: `${key}${index}.locValue`,
        filePath: file?.actualPath || file?.filePath || '',
      };

      if (platform === 'Mobile') {
        locator.os = os;
        locator.testData = testData;
      }
      return locator;
    };

    let locators = [];

    androidVisualTestingLocatorArray?.forEach((file, index) => {
      const os = 'Android';
      const testData = radioTypeForAndroid !== 'radio-path-local' ? true : false;
      const locator = createLocator(file, index, props?.platForm, os, testData);
      locators.push(locator);
    });

    if (props?.platForm === 'Mobile') {
      iosVisualTestingLocatorArray?.forEach((file, index) => {
        const os = 'iOS';
        const testData = radioTypeForIOS !== 'radio-path-local' ? true : false;
        const locator = createLocator(file, index, props?.platForm, os, testData);
        locators.push(locator);
      });
    }

    requestBody['locators'] = [...locators];
    formData.append('element', JSON.stringify(requestBody));
    if (!formRef.current?.errors?.name && !formRef.current?.errors?.type) {
      if (['ADD', 'ADD_SUB'].includes(props.mode)) {
        const createMode = createAndContinue ? 'createAndContinue' : 'create';
        createElementReq(formData, requestBody.parentId, createMode);
      } else {
        updateElement(formData);
      }
    }
  };

  const treeNodeSelected = ([node]) => {
    if (!node) {
      formRef.setFieldValue('parentName', '');
      formRef.setFieldValue('parentId', '');
    } else if (node && node.data.key) {
      setSelectedParentNode(node);
      setExecutionOrder(node.data.lastExecutionOrder + 1);
      setHierarchy(node.data.hierarchy + 1);
      formRef.current.setFieldValue('parentName', node.data.title);
      formRef.current.setFieldValue('parentId', node.data.key);
    }
  };

  const { AlertContatiner, MyAlert } = useAlert();

  useEffect(() => {
    if (props.data) {
      const oldData = JSON.parse(JSON.stringify(props.data));
      let schemaObject = {};
      let valueObject = {
        radioTypeForAndroid: 'radio-path-local',
        radioTypeForIOS: 'radio-path-local',
      };
      let iOSIndexArray = [];
      let androidIndexArray = [];
      let androidTableOptions = [];
      let iOSTableOptions = [];
      let tempDynamicValSuggestion = [...dynamicValSuggestion];
      let dynamicMaxVal = 5;
      let localPathImageArrayForAndroid = [];
      let testDataImageArrayForAndroid = [];
      let localPathImageArrayForIos = [];
      let testDataImageArrayForIos = [];
      if (oldData?.type === 'visual testing') {
        setSelectedType('visual testing');
        oldData?.locators?.forEach((locator) => {
          const idKey = iOSIndexArray.length;
          if (locator?.type !== 'png') {
            setIsDisabled(true);
          }
          locator['label'] = locator?.name;
          if (locator?.os === 'iOS' && props?.platForm?.toLowerCase() === 'mobile') {
            const radio = locator?.testData ? 'radio-path-testdata' : 'radio-path-local';
            locator?.testData === true
              ? testDataImageArrayForIos.push(locator)
              : localPathImageArrayForIos.push(locator);
            valueObject = {
              ...valueObject,
              radioTypeForIOS: radio,
              iosLocatorFromTestData: testDataImageArrayForIos,
              iosLocatorFromLocalPath: localPathImageArrayForIos,
              [`table2Locator${idKey}`]: {
                locName: '',
                locType: '',
                locValue: '',
                data: '',
              },
            };
          } else {
            const idKey = androidIndexArray.length;
            const radio = locator['testData']
              ? locator?.testData === true
                ? 'radio-path-testdata'
                : 'radio-path-local'
              : oldData.testData === true
              ? 'radio-path-testdata'
              : 'radio-path-local';
            locator['testData']
              ? locator?.testData === true
                ? testDataImageArrayForAndroid.push(locator)
                : localPathImageArrayForAndroid.push(locator)
              : oldData.testData === true
              ? testDataImageArrayForAndroid.push(locator)
              : localPathImageArrayForAndroid.push(locator);
            valueObject = {
              ...valueObject,
              radioTypeForAndroid: radio,
              androidLocatorFromTestData: testDataImageArrayForAndroid,
              androidLocatorFromLocalPath: localPathImageArrayForAndroid,
              [`table1Locator${idKey}`]: {
                locName: '',
                locType: '',
                locValue: '',
                data: '',
              },
            };
          }
        });
      } else {
        oldData?.locators?.forEach((locator) => {
          setIsDisabled(true);
          locator.type = locator.type?.toLowerCase();
          if (locator?.os === 'iOS' && props?.platForm?.toLowerCase() === 'mobile') {
            const idKey = iOSIndexArray.length;
            let inputValue = locator.value;
            if (locator?.type === 'dynamic') {
              inputValue = locator.value?.replaceAll('dynamic', 'Dynamic Val');
              const usedDynamicValues = inputValue
                .replaceAll('}', '{')
                .split('{')
                .filter((val, index) => index % 2 !== 0);
              usedDynamicValues.forEach((value) => {
                const index = tempDynamicValSuggestion?.indexOf(value);
                if (index !== -1) {
                  tempDynamicValSuggestion?.splice(index, 1);
                  dynamicMaxVal++;
                  tempDynamicValSuggestion?.push(`Dynamic Val${dynamicMaxVal}`);
                }
              });
            }
            valueObject = {
              ...valueObject,
              iosLocatorFromLocalPath: [],
              [`table2Locator${idKey}`]: {
                locName: locator.name,
                locType: locator.type,
                locValue: inputValue,
                data: locator,
              },
            };
            schemaObject = {
              ...schemaObject,
              [`table2Locator${iosLocatorIndexes[0]}`]: yup.object().shape({
                locName: yup.string().when(`table1Locator${locatorIndexs[0]}`, {
                  is: (value) => {
                    return value;
                  },
                  then: yup.string().required('Locator type is required'),
                  otherwise: yup.string().optional(),
                }),
                locType: yup.string().when(`table1Locator${locatorIndexs[0]}`, {
                  is: (value) => value,
                  then: yup.string().required('Value type is required'),
                  otherwise: yup.string().optional(),
                }),
                locValue: yup
                  .string()
                  .when(`table1Locator${locatorIndexs[0]}`, {
                    is: (value) => value,
                    then: yup.string().required('Locator value is required'),
                    otherwise: yup.string().optional(),
                  })
                  .matches(/.*\S.*/, 'Only whitespaces are not allowed')
                  .test('dynamic-validation', 'Dynamic value is required', (inpvalue, context) => {
                    return hasDynamicVal(inpvalue, `table2Locator${locatorIndexs[0]}`);
                  })
                  .test('is-dynamic-value', 'Proper Dynamic value is required', (inpvalue, context) => {
                    return checkForDynamicVal(inpvalue, `table2Locator${locatorIndexs[0]}`);
                  })
                  .test('invalid-entry', 'Proper Dynamic value is required', (inpvalue, context) => {
                    return checkForvalidbracket(inpvalue, `table2Locator${locatorIndexs[0]}`);
                  })
                  .test('unique-option', 'Locator value should be unique', (inpvalue, context) => {
                    return checkForUniqueLocValue(inpvalue, `table2Locator${locatorIndexs[0]}`);
                  }),
              }),
            };
            iOSIndexArray.push(idKey);
            if (['name', 'id', 'className'].includes(locator.name)) {
              iOSTableOptions.push(locator.name);
            }
          } else {
            const idKey = androidIndexArray.length;
            let inputValue = locator.value;
            if (locator.type === 'dynamic') {
              inputValue = locator.value?.replaceAll(/\bdynamic\b/g, 'Dynamic Val');
              const usedDynamicValues = inputValue
                .replaceAll('}', '{')
                .split('{')
                .filter((val, index) => index % 2 !== 0);
              usedDynamicValues.forEach((value) => {
                const index = tempDynamicValSuggestion?.indexOf(value);
                if (index !== -1) {
                  tempDynamicValSuggestion?.splice(index, 1);
                  dynamicMaxVal++;
                  tempDynamicValSuggestion?.push(`Dynamic Val${dynamicMaxVal}`);
                }
              });
            }
            valueObject = {
              ...valueObject,
              androidLocatorFromLocalPath: [],
              [`table1Locator${idKey}`]: {
                locName: locator.name,
                locType: locator.type,
                locValue: inputValue,
                data: locator,
              },
            };
            schemaObject = {
              ...schemaObject,
              [`table1Locator${idKey}`]:
                props?.platForm === 'Mobile'
                  ? yup.object().shape({
                      locName: yup.string().when(['table2Locator' + iosLocatorIndexes[0]], {
                        is: (value) => {
                          return value;
                        },
                        then: yup.string().required('Locator type is required'),
                        otherwise: yup.string().optional(),
                      }),
                      locType: yup.string().when(['table2Locator' + iosLocatorIndexes[0]], {
                        is: (value) => value,
                        then: yup.string().required('Value type is required'),
                        otherwise: yup.string().optional(),
                      }),
                      locValue: yup
                        .string()
                        .when(['table2Locator' + iosLocatorIndexes[0]], {
                          is: (value) => value,
                          then: yup.string().required('Locator value is required'),
                          otherwise: yup.string().optional(),
                        })
                        .matches(/.*\S.*/, 'Only whitespaces are not allowed')
                        .test('dynamic-validation', 'Dynamic value is required', (inpvalue, context) => {
                          return hasDynamicVal(inpvalue, `table1Locator${locatorIndexs[0]}`);
                        })
                        .test('is-dynamic-value', 'Proper Dynamic value is required', (inpvalue, context) => {
                          return checkForDynamicVal(inpvalue, `table1Locator${locatorIndexs[0]}`);
                        })
                        .test('invalid-entry', 'Proper Dynamic value is required', (inpvalue, context) => {
                          return checkForvalidbracket(inpvalue, `table1Locator${locatorIndexs[0]}`);
                        })
                        .test('unique-option', 'Locator value should be unique', (inpvalue, context) => {
                          return checkForUniqueLocValue(inpvalue, `table1Locator${locatorIndexs[0]}`);
                        }),
                    })
                  : yup.object().shape({
                      locName: yup.string().required('Locator type is required'),
                      locType: yup.string().required('Value type is required'),
                      locValue: yup
                        .string()
                        .required('Locator value is required')
                        .matches(/.*\S.*/, 'Only whitespaces are not allowed')
                        .test('dynamic-validation', 'Dynamic value is required', (inpvalue) => {
                          return hasDynamicVal(inpvalue, `table1Locator${idKey}`);
                        })
                        .test('is-dynamic-value', 'Proper Dynamic value is required', (inpvalue) => {
                          return checkForDynamicVal(inpvalue, `table1Locator${idKey}`);
                        })
                        .test('invalid-entry', 'Proper Dynamic value is required', (inpvalue) => {
                          return checkForvalidbracket(inpvalue, `table1Locator${idKey}`);
                        })
                        .test('unique-option', 'Locator value should be unique', (inpvalue) => {
                          return checkForUniqueLocValue(inpvalue, `table1Locator${idKey}`);
                        }),
                    }),
            };
            androidIndexArray.push(idKey);
            if (['name', 'id', 'className'].includes(locator.name)) {
              androidTableOptions.push(locator.name);
            }
          }
        });
      }
      const emptyLocatorObject = {
        locName: '',
        locType: '',
        locValue: '',
        data: {
          value: '',
          type: '',
          name: '',
          status: 'NOT_USED',
          isRecorded: 'N',
          priority: '',
          defaultAddedFirst: false,
          defaultAddedSecond: false,
        },
      };

      if (oldData.type === 'visual testing' && isEmptyValue(localPathImageArrayForAndroid)) {
        valueObject = {
          ...valueObject,
          androidLocatorFromLocalPath: [],
          table1Locator0: emptyLocatorObject,
        };
      } else if (oldData.type === 'visual testing' && isEmptyValue(testDataImageArrayForAndroid)) {
        valueObject = {
          ...valueObject,
          androidLocatorFromTestData: [],
          table1Locator0: emptyLocatorObject,
        };
      } else if (isEmptyValue(androidIndexArray)) {
        valueObject = {
          ...valueObject,
          table1Locator0: emptyLocatorObject,
        };
      }

      if (
        props.platForm?.toLowerCase() === 'mobile' &&
        oldData?.type === 'visual testing' &&
        isEmptyValue(localPathImageArrayForIos)
      ) {
        valueObject = {
          ...valueObject,
          iosLocatorFromLocalPath: [],
          table2Locator0: emptyLocatorObject,
        };
      } else if (
        props.platForm?.toLowerCase() === 'mobile' &&
        oldData?.type === 'visual testing' &&
        isEmptyValue(testDataImageArrayForIos)
      ) {
        valueObject = {
          ...valueObject,
          iosLocatorFromTestData: [],
          table2Locator0: emptyLocatorObject,
        };
      } else if (isEmptyValue(iOSIndexArray) && props.platForm?.toLowerCase() === 'mobile') {
        valueObject = {
          ...valueObject,
          table2Locator0: emptyLocatorObject,
        };
      }
      setInitialValues({
        name: oldData.name,
        type: oldData.type,
        description: oldData.desc,
        parentName: oldData.parentName,
        parentId: oldData.parentId,
        ...valueObject,
      });
      setElementInitialData({
        name: oldData.name,
        type: oldData.type,
        description: oldData.desc,
        parentName: oldData.parentName,
        parentId: oldData.parentId,
        ...valueObject,
      });
      const updatedScheema = {
        ...initialSchema,
        ...schemaObject,
      };
      setPreviousData(oldData);
      setDynamicValSuggestion([...tempDynamicValSuggestion]);
      setCurrentSchema({ ...updatedScheema });
      setValidationSchema(yup.object(updatedScheema));
      setLocatorIndexs(androidIndexArray.length ? androidIndexArray : [0]);
      setIosLocatorIndexes(iOSIndexArray.length ? iOSIndexArray : [0]);
      setTable1DisabledOptions(androidTableOptions);
      setTable2DisabledOptions(iOSTableOptions);
    }
  }, []);

  const checkValue = (initialValue) => {
    let local = JSON.parse(JSON.stringify(initialValues || {}));
    if (props.platForm === 'Mobile') {
      for (let val in initialValue) {
        if (typeof initialValue[val] == 'object' && val !== 'data') {
          for (let keys in initialValue[val]) {
            local = {
              ...local,
              [val]: {
                ...local[val],
                [keys]: initialValue[val][keys] === '' ? true : false,
              },
            };
          }
          local = {
            ...local,
            [val]:
              !local[val] || !Object?.entries(local[val])
                ? false
                : !Object?.entries(local[val])
                    .filter((e) => e[0] !== 'data')
                    .every((e) => e[1] === false),
          };
        } else {
          local = {
            ...local,
            [val]: initialValue[val] === '' ? true : false,
          };
        }
      }
      let locaterTable1 = !Object?.entries(local)
        ?.filter((e) => e[0]?.includes('table1'))
        .every((e) => e[1] === false);
      let locaterTable2 = !Object?.entries(local)
        ?.filter((e) => e[0]?.includes('table2'))
        .every((e) => e[1] === false);

      let dq = !Object?.entries(local)
        ?.filter((e) => ['name', 'parentId', 'parentName', 'type']?.includes(e[0]))
        .every((e) => e[1] === false);
      if (!locaterTable1 && locaterTable2) {
        let res = 0;
        let checkTable2 = Object?.entries(initialValue || {})?.filter((e) => e[0]?.includes('table2'));
        Object?.values(checkTable2[checkTable2.length - 1][1] || {})?.map((e) => {
          if (e === '') res++;
        });
        const errors = formRef.current.errors;
        if (isEmptyObject(errors) || Object.keys(errors)[0] === 'parentName') {
          return [dq, res < 3].every((e) => e === false);
        } else {
          return false;
        }
      }

      if (!locaterTable2 && locaterTable1) {
        let res = 0;
        let checkTable2 = Object?.entries(initialValue || {})?.filter((e) => e[0]?.includes('table1'));
        Object?.values(checkTable2[checkTable2.length - 1][1] || {})?.map((e) => {
          if (e === '') res++;
        });
        const errors = formRef.current.errors;
        if (isEmptyObject(errors) || Object.keys(errors)[0] === 'parentName') {
          return [dq, res < 3].every((e) => e === false);
        } else {
          return false;
        }
      }
      let eq = ![locaterTable1, locaterTable2]?.some((e) => e === false);
      return [dq, eq].every((e) => e === false);
    } else {
      for (let val in initialValue) {
        if (typeof initialValue[val] == 'object' && val !== 'data') {
          for (let keys in initialValue[val]) {
            local = {
              ...local,
              [val]: {
                ...local[val],
                [keys]: initialValue[val][keys] === '' ? true : false,
              },
            };
          }
          local = {
            ...local,
            [val]:
              !local[val] || !Object?.entries(local[val])
                ? false
                : !Object.entries(local[val])
                    .filter((e) => e[0] !== 'data')
                    .every((e) => e[1] === false),
          };
        } else {
          local = {
            ...local,
            [val]: initialValue[val] === '' ? true : false,
          };
        }
      }
      let dq = !Object?.entries(local)
        ?.filter((e) => ['name', 'parentId', 'parentName', 'type'].includes(e[0]))
        .every((e) => e[1] === false);
      let locaterTable1 = !Object?.entries(local)
        ?.filter((e) => e[0].includes('table1'))
        .every((e) => e[1] === false);
      return [dq, locaterTable1].every((e) => e === false);
    }
  };

  const checkUpdates = (updateValue) => {
    let res = JSON.stringify(updateValue) === JSON.stringify(elementInitialData);
    if (!res) {
      return checkValue(updateValue);
    } else {
      return false;
    }
  };

  const disableCreate = () => {
    return ['ADD', 'ADD_SUB']?.includes(props.mode)
      ? checkValue(formRef.current?.values)
      : checkUpdates(formRef.current?.values);
  };

  const disableOther = () => {
    return ['ADD', 'ADD_SUB']?.includes(props.mode)
      ? checkValue(formRef.current?.values)
      : checkUpdates(formRef.current?.values);
  };
  const handleCreateAndContinueClick = async () => {
    createAndContinue = true;
    if (selectedType === 'visual testing') {
      onSubmitImageLocator(formRef.current?.values);
    } else {
      formRef.current.handleSubmit();
    }
  };

  const handleCreate = async () => {
    if (selectedType === 'visual testing') {
      onSubmitImageLocator(formRef.current?.values);
    } else {
      formRef.current.handleSubmit();
    }
  };

  const isCreateAndContinueButtonDisabled = () => {
    if (props.platform === 'Mobile' && selectedType !== 'visual testing') {
      return !disableCreate() && isDisabled;
    } else if (selectedType !== 'visual testing') {
      return !disableOther() && isDisabled;
    } else {
      if (props.mode === 'EDIT' && selectedType === 'visual testing') {
        if (formRef.current.dirty === false) {
          return true;
        } else {
          return isDisabled;
        }
      } else {
        return isDisabled;
      }
    }
  };
  return (
    <Modal
      open={showModal}
      onClose={(e) => {
        if (e.key === 'Escape') {
          setShowModal(false);
          props.closeModal(false);
        }
      }}
      className="modal-dialog  grid justify-center"
      id="element-modal"
    >
      <div className="modal-container element-modal-size">
        <div className="flex justify-center ">
          <div className="alert-position-style ">
            <AlertContatiner />
          </div>
        </div>
        <div className="modal-header">
          {['ADD', 'ADD_SUB'].includes(props.mode) ? (
            <h2 className="title"> Create Element</h2>
          ) : (
            <div className="title title-trim fontPoppinsMediumLg" title={props.data.name}>
              Edit Element - {props.data.name}{' '}
            </div>
          )}
          <IconButton
            color="primary"
            component="span"
            className="close"
            onClick={() => {
              props.closeModal(false);
              setShowModal(false);
            }}
          >
            <Close />
          </IconButton>
        </div>
        {!resetFormState && (
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            innerRef={formRef}
            validateOnMount={true}
          >
            {({ handleSubmit, values, setFieldValue }) => {
              return (
                <form onSubmit={handleSubmit} className="contents relative">
                  <div className="modal-body paddingStyle" id="journal-scroll">
                    <div>
                      <div className="grid grid-cols-2 -mt-2">
                        <div className="relative">
                          <Field name="name">
                            {({ field, form, meta }) => {
                              return (
                                <div className="mt-3">
                                  <TextField
                                    {...field}
                                    autoFocus={true}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    label={
                                      <>
                                        <span className="text-red-400 mr-1">&#42;</span>
                                        Name
                                      </>
                                    }
                                    error={meta.touched && meta.error}
                                    autoComplete="off"
                                    className="w-60	block mui-input-text-field"
                                    type="text"
                                    placeholder="Enter element name"
                                    onChange={(e) => {
                                      form.setFieldValue(e.target.name, e.target.value);
                                    }}
                                  />
                                  {meta.touched && meta.error && (
                                    <div className="errorMessage absolute">{meta.error}</div>
                                  )}
                                </div>
                              );
                            }}
                          </Field>
                        </div>
                        <div className="ml-14 mt-1.5">
                          <Field name="type">
                            {({ field, form, meta }) => {
                              return (
                                <div className="">
                                  <FormControl className="w-full">
                                    <InputLabel shrink htmlFor="type">
                                      <span className="text-red-500">&#42;</span>{' '}
                                      <span className={meta.touched && meta.error ? 'text-red-500' : null}>Type</span>
                                    </InputLabel>
                                    <div className=" mt-4">
                                      <Select
                                        classes={{
                                          root: classesElementType.select,
                                        }}
                                        MenuProps={{
                                          anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                          },
                                          classes: {
                                            paper: classesElementType.menuPaper,
                                            list: classesElementType.menulist,
                                          },
                                          getContentAnchorEl: null,
                                        }}
                                        {...field}
                                        error={meta.touched && meta.error}
                                        name="type"
                                        fullWidth
                                        displayEmpty
                                        onChange={(e) => {
                                          form.setFieldValue(e.target.name, e.target.value);
                                          setSelectedType(e.target.value);
                                          if (e.target.value === 'visual testing') {
                                            form.setFieldValue('radioTypeForAndroid', 'radio-path-local');
                                            form.setFieldValue('radioTypeForIOS', 'radio-path-local');
                                            form.setFieldValue('androidLocatorFromLocalPath', []);
                                            form.setFieldValue('androidLocatorFromTestData', []);
                                            form.setFieldValue('iosLocatorFromLocalPath', []);
                                            form.setFieldValue('iosLocatorFromTestData', []);
                                          }
                                          setIsDisabled(true);
                                        }}
                                        renderValue={
                                          form.values.type !== ''
                                            ? undefined
                                            : () => <Placeholder>Select element type</Placeholder>
                                        }
                                      >
                                        {elementTypeOptions.map((data) => {
                                          return <MenuItem value={data.value}>{data.label}</MenuItem>;
                                        })}
                                      </Select>
                                    </div>
                                  </FormControl>
                                  {meta.touched && meta.error && (
                                    <div className="text-red-500 text-xs md:mt-1">{meta.error}</div>
                                  )}
                                </div>
                              );
                            }}
                          </Field>
                        </div>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="desc" className="input-filed-label-style-common">
                          Description
                        </label>

                        <Field name="description">
                          {({ field, form, meta }) => {
                            return (
                              <div className="">
                                <TextareaAutosize
                                  {...field}
                                  maxRows={noOfRows}
                                  onBlur={(e) => {
                                    setNoOfRows(1);
                                    e.target.classList.add('descriptionStyle');
                                  }}
                                  onFocus={(e) => {
                                    setNoOfRows(null);
                                    e.target.classList.remove('descriptionStyle');
                                  }}
                                  maxLength="200"
                                  className="block w-full rs-input-style-textarea popup-input-bg pl-px border-0 border-b input-field-color descriptionStyle resize-none"
                                  onKeyUp={(e) => setDescCount(e.target.value.length)}
                                  placeholder={'Your description goes here...'}
                                />
                                <div className="mt-1 text-sm text-gray-500 text-right">{descCount}/200</div>
                              </div>
                            );
                          }}
                        </Field>
                      </div>
                      <div className="mt-2">
                        <Field name="parentName">
                          {({ field, form, meta }) => {
                            return (
                              <div className="">
                                <div className="mt-2 text-sm text-blue-700">
                                  <label
                                    htmlFor="parentModule"
                                    className={
                                      meta.touched && meta.error
                                        ? 'fontPoppinsRegularMd text-red-500'
                                        : 'input-filed-label-style-common'
                                    }
                                  >
                                    <span className="text-red-400">&#42;</span>Parent {label}
                                  </label>
                                  <div className="popup-input-bg">
                                    <TreeWithRadioButton
                                      moduleSelection={true}
                                      {...field}
                                      individualTree={id ? true : false}
                                      data={props?.treeData ? props?.treeData : []}
                                      operation={['ADD_SUB', 'EDIT'].includes(props.mode) ? 'edit' : null}
                                      placeholder={
                                        props.data
                                          ? props.data.parentName
                                          : props.parentId
                                          ? props.parentName
                                          : `Search and select parent ${label}`
                                      }
                                      nodeSelected={treeNodeSelected.bind(this)}
                                      hideElements={true}
                                      hideElementsBtn={true}
                                      hideRootRadioBtn={true}
                                      buttonLabel={label}
                                      scriptType={props.scriptType}
                                    />
                                  </div>
                                  {meta.touched && meta.error && form.values.parentName === '' && (
                                    <div className="errorMessage">{meta.error}</div>
                                  )}
                                </div>
                              </div>
                            );
                          }}
                        </Field>
                      </div>
                      <hr className="divider mt-5" />
                      {values.type !== 'visual testing' ? (
                        <LocatorTableForm
                          isDisabled={setIsDisabled}
                          tableName={'table1Locator'}
                          locatorIndexs={locatorIndexs}
                          setLocatorIndexsState={setLocatorIndexs}
                          addLocator={addLocator}
                          deleteLocator={deleteLocator}
                          tableLabel={props?.platForm === 'Mobile' ? 'Android Locators List' : 'Locators List'}
                          locatorTypeList={
                            props?.platForm === 'Mobile'
                              ? getLocatorTypeOptions('Android')
                              : getLocatorTypeOptions(props?.platForm)
                          }
                          disabledLocatorsList={table1DisabledOptions}
                          dynamicValSuggestion={dynamicValSuggestion}
                          resetDynamicValSuggestion={resetDynamicValSuggestion}
                        />
                      ) : (
                        <ImageLocator
                          tableName={'table1Locator'}
                          tableLabel={props?.platForm === 'Mobile' ? 'Android Locators List' : 'Locators List'}
                          MyAlert={MyAlert}
                          setIsDisabled={setIsDisabled}
                          values={values}
                          setFieldValue={setFieldValue}
                          Placeholder={Placeholder}
                          classesElementType={classesElementType}
                          platform={platform}
                        />
                      )}
                      {props?.platForm === 'Mobile' && values.type === 'visual testing' ? (
                        <>
                          <hr className="divider mt-5" />
                          <ImageLocator
                            tableName={'table2Locator'}
                            tableLabel={'iOS Locators List'}
                            MyAlert={MyAlert}
                            setIsDisabled={setIsDisabled}
                            values={values}
                            setFieldValue={setFieldValue}
                            Placeholder={Placeholder}
                            classesElementType={classesElementType}
                            platform={platform}
            
                          />
                        </>
                      ) : (
                        props.platForm === 'Mobile' && (
                          <>
                            <hr className="divider mt-5" />
                            <LocatorTableForm
                              isDisabled={setIsDisabled}
                              locatorIndexs={iosLocatorIndexes}
                              setLocatorIndexsState={setIosLocatorIndexes}
                              tableName={'table2Locator'}
                              tableLabel={'iOS Locators List'}
                              addLocator={addLocator}
                              deleteLocator={deleteLocator}
                              locatorTypeList={getLocatorTypeOptions('iOS')}
                              disabledLocatorsList={table2DisabledOptions}
                              dynamicValSuggestion={dynamicValSuggestion}
                              resetDynamicValSuggestion={resetDynamicValSuggestion}
                            />
                          </>
                        )
                      )}
                      <hr className="divider mt-5" />
                    </div>
                  </div>
                  <div className="modal-footer">
                    {['ADD', 'ADD_SUB'].includes(props.mode) && (
                      <span>
                        <Checkbox
                          color="primary"
                          checked={copyToSharedElement}
                          onChange={(e) => {
                            setCopyToSharedElement(e.target.checked);
                          }}
                          inputProps={{ 'aria-label': 'sharedElement', name: 'sharedElement', id: 'sharedElement' }}
                        />
                        <label htmlFor="sharedElement" className="input-filed-label-style-common">
                          Copy to Shared Elements{' '}
                          {props.projectType === 'Mobile'
                            ? 'Screen'
                            : props.projectType === 'Web'
                            ? 'Page'
                            : ['Web & Mobile', 'Salesforce', 'MS Dynamics']?.includes(props?.projectType) &&
                              props.platForm === 'Web'
                            ? 'Page'
                            : ['Web & Mobile', 'Salesforce', 'MS Dynamics']?.includes(props?.projectType) &&
                              ['Android', 'iOS', 'Mobile', 'Ios', 'MobileWeb']?.includes(props?.platForm)
                            ? 'Screen'
                            : 'Page'}
                        </label>
                      </span>
                    )}
                    <button
                      type="button"
                      className="gray-btn"
                      onClick={() => {
                        props.closeModal(false);
                        setShowModal(false);
                      }}
                    >
                      Cancel
                    </button>
                    {['ADD', 'ADD_SUB'].includes(props.mode) && (
                      <button
                        type="button"
                        className="gray-btn ml-3"
                        disabled={isCreateAndContinueButtonDisabled()}
                        onClick={handleCreateAndContinueClick}
                      >
                        Create & Continue
                      </button>
                    )}
                    <button
                      type="button"
                      className="primary-btn ml-3 mr-4"
                      disabled={isCreateAndContinueButtonDisabled()}
                      onClick={handleCreate}
                    >
                      <span>{['ADD', 'ADD_SUB'].includes(props.mode) ? 'Create' : 'Update'}</span>
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        )}
      </div>
    </Modal>
  );
};

export default ElementModal;
