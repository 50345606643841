import React, { useEffect, useState, useRef } from 'react';
import { Chart, Tooltip } from 'fireflink-ui';
import EditIcon from '@mui/icons-material/Edit';
import { ReactComponent as FilterOutline } from '@assets/analytics/FilterOutline.svg';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { CustomFilterTimePeriod } from './Custom-filter';
import { DASHBOARD_CONSTANTS } from '@src/common/ui-constants';
import { getAllLabelsByProjectId, getSearchedGraphData } from '@src/api/api_services';
import dateFormat from 'dateformat';
import { getCurrentProjectId, getCurrentProjectStatus, isEmptyValue } from '@src/util/common_utils';
import { getScriptOptions } from '@src/pages/test-development/script/modules/module/utils/common-functions';
import CreateEditGraph from './CreateEditCustomGraph';
import GraphDelete from './DeleteGraph';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import '../dashBoard.scss';
import colors from '@src/css_config/variable.module.scss';
import { getCurrentProjectType } from '@util/common_utils';
import {
  CUSTOM_GRAPH_PLATFORM,
  CUSTOM_GRAPH_WEB_PLATFORM,
  CUSTOM_GRAPH_MOBILE_PLATFORM,
} from '../custom_graph/custom_graph_constant';

const CustomGraph = (props) => {
  const [showFiltersList, setShowFiltersList] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [hasData, sethasData] = useState(false);
  const [chartData, setChartData] = useState({});
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterOptionsLabels, setFilterOptionsLabels] = useState([]);
  const [label, setLabel] = useState();
  const [labelsOption, setLabelsOption] = useState();
  const [currentTimePeriod, setCurretTimePeriod] = useState('7');
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: null,
    end: null,
  });
  const [xAxisLabel, setXAxisLabel] = useState('');
  const [yAxisLabel, setYAxisLabel] = useState('');
  const value = props.data.name;
  const [xAxisValue, setXAxisValue] = useState(value.split('v/s')[1].trim());
  const [yAxisValue, setYAxisValue] = useState(props?.data?.option);
  const [filterLabels, setFilterLabels] = useState([]);
  const [filterGroup, setFilterGroup] = useState('');
  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const [selectedGraph, setSelectedGraph] = useState();
  const [selectedDate, setSelectedDate] = useState({
    start: null,
    end: null,
  });
  const [openGraph, setOpenGraph] = useState(false);
  const [title, setTitle] = useState('');
  const [isUser, setUser] = useState(false);
  const [subTitle, setSubTitle] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const projectStatus = getCurrentProjectStatus();
  const [filterLableOne,setFilterLabelOne]=useState([])
  const multiFilterRef=useRef({});
  const resultStatus = [
    {
      name: 'Pass',
      value: 'Pass',
    },
    {
      name: 'Fail',
      value: 'Fail',
    },
    {
      name: 'Warning',
      value: 'Warning',
    },
    {
      name: 'Skip',
      value: 'Warning',
    },
  ];

  const projectType = getCurrentProjectType();

  useEffect(() => {
    getSearchedDataforGraph(props?.data);
  }, [props?.data]);

  useEffect(() => {
    if (props.hasRefresh) {
      setCurretTimePeriod('7');
      setSelectedDateRange({
        start: null,
        end: null,
      });
      setFilterLabels([]);
      setFilterLabelOne([])
      props.setHasRefreshFalse();
    }
  }, [props?.hasRefresh]);

  const closeGraphDrawer = () => {
    setOpenGraph(false);
  };

  const getDates = () => {
    let currentDate = new Date();

    let today = currentDate.getDate();

    let sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(today - 7);

    let year = sevenDaysAgo.getFullYear();
    let month = sevenDaysAgo.getMonth() + 1;
    let day = sevenDaysAgo.getDate();

    let formattedDate = year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    return {
      fromDate: dateFormat(formattedDate, 'dd/mm/yyyy'),
      toDate: dateFormat(currentDate, 'dd/mm/yyyy'),
    };
  };
  const openModalDelete = (value) => {
    setOpenDeleteModal(true);
    setSelectedGraph(value);
  };
  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const toggleFiltersList = () => {
    setShowFiltersList(!showFiltersList);
  };
  const getTotalCount = (data) => {
    const sum = data.reduce((acc, num) => acc + num, 0);
    setTotalCount(sum);
  };

  const getSecondOption = (dta) => {
    switch (dta) {
      case 'Modified By':
        return 'modifiedBy';
      case 'Created By':
        return 'createdBy';
      case 'Assign to':
        return 'assignTo';
      case 'assignedTo':
        return;
      default:
        return '';
    }
  };
  const getPayloadForAutomationManualScripts = (data, date, xAxisValue) => {
    let payload = {
      module: 'custom-graph-module',
      collectionName: 'modules',
      searchText: '',
      facetQueries: [{ name: 'createdOn', values: [date.fromDate, date.toDate] }],
      customGraphFilter: {
        scriptTestCaseType: data?.option === 'Number of Automation Scripts' ? 'Automation' : 'Manual',
      },
    };

    if (data?.users?.length) {
      setTitle('Users');
      payload.customGraphFilter.users = data.users;
      payload.customGraphFilter.defaultFilters = [{ name: 'userType', values: [data.userType] }];
      payload.customGraphFilter.secondOption = 'users';
    } else if (data?.labels?.length) {
      setTitle('Labels');
      payload.customGraphFilter.labels = data.labels;
      payload.customGraphFilter.secondOption = xAxisValue;
    } else if (data?.scripts?.length) {
      setTitle('Scripts');
      payload.customGraphFilter.types = data?.scripts;
      payload.customGraphFilter.secondOption = xAxisValue;
    }

    return payload;
  };

  const getPayloadForExecutions = (data, date) => {
    let payload = {
      executionType: data?.option === 'Number of Automation Executions' ? 'Automation' : 'Manual',
      dateRange: [date.fromDate, date.toDate],
    };

    if (data?.users?.length) {
      setTitle('Users');
      payload.users = data.users;
    } else if (data?.statuses?.length) {
      setTitle('Status');
      payload.statuses = data.statuses;
    } else if (data?.machines?.length) {
      setTitle('Machines');
      payload.machines = data?.machines;
    } else if (data?.executionRunTypes?.length) {
      setTitle('Executions');
      payload.executionRunTypes = data?.executionRunTypes;
    } else if (data?.suiteNames?.length) {
      setTitle('Suites');
      payload.suiteNames = data?.suiteNames;
    }

    return payload;
  };

  const getPayloadForProgramElements = (data, date, xAxisValue) => {
    let payload = {
      module: 'custom-graph-package',
      collectionName: 'packages',
      searchText: '',
      facetQueries: [{ name: 'modifiedOn', values: [date.fromDate, date.toDate] }],
    };

    if (data?.users?.length) {
      setTitle('Users');
      payload.customGraphFilter = {
        users: data.users,
        defaultFilters: [{ name: 'userType', values: [data.userType] }],
        secondOption: getSecondOption(xAxisValue),
      };
    }

    return payload;
  };

  const getPayloadForElements = (data, date, xAxisValue) => {
    let payload = {
      module: 'custom-graph-pages',
      collectionName: 'pages',
      searchText: '',
      facetQueries: [{ name: 'createdOn', values: [date.fromDate, date.toDate] }],
    };

    if (data?.users?.length) {
      setTitle('Users');
      payload.customGraphFilter = {
        users: data.users,
        defaultFilters: [{ name: 'userType', values: [data.userType] }],
        secondOption: getSecondOption(xAxisValue),
      };
    } else if (data?.platforms?.length) {
      setTitle('Platforms');
      payload.customGraphFilter = {
        platforms: data?.platforms,
        secondOption: xAxisValue,
      };
    } else if (data?.types?.length) {
      setTitle('Elements');
      payload.customGraphFilter = {
        types: data?.types,
        secondOption: xAxisValue,
      };
    }

    return payload;
  };

  const getPayloadForStepGroups = (data, date, xAxisValue) => {
    let payload = {
      module: 'custom-graph-stepGroups',
      collectionName: 'libraries',
      searchText: '',
      facetQueries: [{ name: 'createdOn', values: [date.fromDate, date.toDate] }],
    };

    if (data?.users?.length) {
      setTitle('Users');
      payload.customGraphFilter = {
        users: data.users,
        defaultFilters: [{ name: 'userType', values: [data.userType] }],
        secondOption: getSecondOption(xAxisValue),
      };
    } else if (data?.types?.length) {
      setTitle('Step Groups');
      payload.customGraphFilter = {
        types: data?.types,
        secondOption: xAxisValue,
      };
    }

    return payload;
  };

  const getPayloadForDefects = (data, date, xAxisValue) => {
    let payload = {
      module: 'custom-graph-defect',
      collectionName: 'defects',
      searchText: '',
      facetQueries: [{ name: 'createdOn', values: [date.fromDate, date.toDate] }],
    };

    if (data?.users?.length) {
      setTitle('Users');
      payload.customGraphFilter = {
        users: data.users,
        secondOption: getSecondOption(xAxisValue),
      };
    } else if (data?.valuesToFilter?.length) {
      setTitle('Defects');
      payload.customGraphFilter = {
        valuesToFilter: data?.valuesToFilter,
        secondOption: xAxisValue,
      };
    }

    return payload;
  };

  const projectId = getCurrentProjectId();
  const fetchLabelNames = async () => {
    try {
      const response = await getAllLabelsByProjectId(projectId);
  
      if (response?.data?.responseCode === 200 && response?.data.message === 'SUCCESS') {
        return response?.data?.responseObject.map((item) => ({
          name: item.name,
          value: item.name,
        }));
      } else {
        return [];
      }
    } catch (error) {
      console.error('API error:', error);
      return [];
    }
  };

  const getSearchedDataforGraph = async (data) => {
    let labels = [];
    let count = [];
    let percentage = [];
    let mailToolTip = [];
    const date = getDates();
    const xAxisValue = data?.name?.split('v/s' || 'vs')[1]?.trim();
    let label = '';
    let payload = {};
    if (['Number of Automation Scripts', 'Number of Manual Test cases'].includes(data?.option)) {
      const scripts = getScriptOptions();
      label = 'Scripts';
      setFilterOptions(scripts);
      let scriptLabelData = data.name;
      let scriptValue = scriptLabelData.split('v/s')[1].trim();
      setLabel(scriptValue !== 'Script Type' ? 'Scripts' : '');    
    }
    if(['Number of Automation Scripts', 'Number of Manual Test cases','Number of defects'].includes(data?.option)){
      const labelsOptions = await fetchLabelNames(projectId);
      label = 'Scripts';
      setFilterOptionsLabels(labelsOptions); 
      let labelLabelData = data.name;
      let labelValue = labelLabelData.split('v/s')[1].trim();
      setLabelsOption(labelValue !== 'Labels' ? 'Labels' : ''); 
    }
    else if (['Number of Automation Executions', 'Number of Manual Executions'].includes(data?.option)) {
      label = 'Executions';
      setFilterOptions(resultStatus);
      let labelData = data.name;
      let value = labelData.split('v/s')[1].trim();
      setLabel(value !== 'Status' ? 'Status' : '');
    } else if (data?.option === 'Number of Elements') {
      let platform = [];
      if (projectType === 'Web') {
        platform = CUSTOM_GRAPH_WEB_PLATFORM;
      } else if (projectType === 'Mobile') {
        platform = CUSTOM_GRAPH_MOBILE_PLATFORM;
      } else {
        platform = CUSTOM_GRAPH_PLATFORM;
      }
      label = 'Scripts';
      setFilterOptions(platform);
      let labelData = data.name;
      let value = labelData.split('v/s')[1].trim();
      setLabel(value !== 'Platform Type' ? 'Platform Type' : '');
    } else if (data?.option === 'Number of Program Elements') {
      label = 'Scripts';
      setFilterOptions([]);
      setLabel('');
    } else if (data?.option === 'Number of Step Groups') {
      const stepgrouptype = getScriptOptions();
      label = 'Scripts';
      setFilterOptions(stepgrouptype);
      let labelData = data.name;
      let value = labelData.split('v/s')[1].trim();
      setLabel(value !== 'Step Group type' ? 'Step Group type' : '');
    } else if (data?.option === 'Number of defects') {
      label = 'Scripts';
      setFilterOptions([]);
      setLabel('');
    }
    switch (data?.option) {
      case 'Number of Automation Scripts':
      case 'Number of Manual Test cases':
        setSubTitle('Total Scripts');
        payload = getPayloadForAutomationManualScripts(data, date, xAxisValue);
        break;
      case 'Number of Automation Executions':
      case 'Number of Manual Executions':
        setSubTitle('Total Executions');
        payload = getPayloadForExecutions(data, date);
        break;
      case 'Number of Program Elements':
        setSubTitle('Total Program Elements');
        payload = getPayloadForProgramElements(data, date, xAxisValue);
        break;
      case 'Number of Elements':
        setSubTitle('Total Elements');
        payload = getPayloadForElements(data, date, xAxisValue);
        break;
      case 'Number of Step Groups':
        setSubTitle('Total Step Groups');
        payload = getPayloadForStepGroups(data, date, xAxisValue);
        break;
      case 'Number of defects':
        setSubTitle('Total Defects');
        payload = getPayloadForDefects(data, date, xAxisValue);
        break;
      default:
        return '';
    }

    let chartDatas = {
      labels: [],
      datasets: [
        {
          barThickness: 35,
          barPercentage: 9,
          data: [],
          count: [],
          backgroundColor: [
            colors.ff_primary,
            colors.ff_skyBlue,
            colors.ff_skyLight,
            colors.ff_lightGreen,
            colors.ff_orange,
            colors.ff_lightPink,
            colors.ff_lightBrown,
            colors.ff_purple,
          ],
          hoverBackgroundColor: [
            'rgba(67, 77, 184, 0.5)',
            'rgba(31, 120, 180, 0.5)',
            'rgba(166, 206, 227, 0.5)',
            'rgba(178, 223, 138, 0.5)',
            'rgba(227, 149, 18, 0.5)',
            'rgba(216, 89, 255, 0.5)',
            ' rgba(184, 114, 67, 0.5)',
            'rgba(129, 99, 224, 0.5)',
          ],
          toolTipMailArray: [],
        },
      ],
    };

    let response = await getSearchedGraphData(payload, label);

    if (response?.data?.responseCode === 200) {
      if (['modifiedBy', 'createdBy', 'assignTo', 'users'].includes(response?.data?.filterResponse?.name)) {
        setUser(true);
        let filterResponseData = response?.data?.filterResponse?.values;
        let userLabels = Object.keys(filterResponseData);
        userLabels.forEach((key) => {
          const { username, percent, emailId, count: keyCount } = filterResponseData[key];
          if (key !== 'Others') {
            labels.push(username);
            count.push(percent);
            percentage.push(keyCount);
            mailToolTip.push(emailId);
          } else {
            labels.push('Others');
            count.push(percent);
            percentage.push(keyCount);
          }
          chartDatas.labels = labels;
          chartDatas.datasets[0].count = count;
          chartDatas.datasets[0].data = percentage;
          chartDatas.datasets[0].toolTipMailArray = mailToolTip;
          chartDatas.datasets[0].userLegendMailArray = mailToolTip;
          getTotalCount(percentage);
        });
      } else {
        setUser(false);
        if (['Number of Automation Executions', 'Number of Manual Executions'].includes(data?.option)) {
          let filterResponseData = response?.data.responseObject;
          let chartArray = filterResponseData;
          let labels = Object.keys(chartArray);
          const userData = labels.map((key) => {
            if (filterResponseData[key].username !== undefined && filterResponseData[key].username !== null) {
              const userData = { username: filterResponseData[key].username, emailId: filterResponseData[key].emailId };
              return userData;
            } else {
              const [username, emailId] = key.split(':').map((str) => str.trim());
              return {
                username,
                emailId,
              };
            }
          });
          const usernames = userData.map((user) => user.username);
          const emailIds = userData.map((user) => user.emailId);
          let count = labels.map((key) => chartArray[key].percent);
          let percentage = labels.map((key) => chartArray[key].count);
          chartDatas.labels = usernames;
          chartDatas.datasets[0].count = count;
          chartDatas.datasets[0].data = percentage;
          chartDatas.datasets[0].toolTipMailArray = props.data.graphType === 'BarGraph' && emailIds;
          chartDatas.datasets[0].userLegendMailArray = emailIds;
          getTotalCount(percentage);
        } else {
          let filterResponseData = response?.data?.filterResponse?.values;
          let chartArray = Object.keys(filterResponseData);

          chartArray?.map((key) => {
            labels.push(key);
            count.push(filterResponseData[key].percent);
            percentage.push(filterResponseData[key].count);
          });
          chartDatas.labels = labels;
          chartDatas.datasets[0].count = count;
          chartDatas.datasets[0].data = percentage;
          getTotalCount(percentage);
        }
      }
      if (chartDatas) {
        setChartData(chartDatas);
      }
      sethasData(true);
    }
  };

  const handleDateFormat = (date) => {
    const inputDateString = date;
    const [day, month, year] = inputDateString.split('/');
    const dateObject = new Date(`${year}-${month}-${day}`);
    return dateObject;
  };

  const handleTimePeriod = (startdate, enddate, numberofdays) => {
    if (numberofdays !== '') {
      setSelectedDateRange({ start: null, end: null });
    } else {
      const startDate = handleDateFormat(startdate);
      const endDate = handleDateFormat(enddate);
      setSelectedDateRange({ start: startDate, end: endDate });
    }
    handleonSubmit({ start: startdate, end: enddate }, 'date');
    setCurretTimePeriod(numberofdays);
    setSelectedDate({
      start: startdate,
      end: enddate,
    });
  };

  
  const handleFilter = (filterOption, filterGroup) => {
    const tempMultiFilter = {...multiFilterRef.current};
    tempMultiFilter[filterGroup] = filterOption;
    multiFilterRef.current = tempMultiFilter;
    if(isEmptyValue(filterOption)){
      delete multiFilterRef.current[filterGroup]
    }
    if (filterGroup === "Labels") {
        setFilterLabelOne(filterOption);
    } else if (filterGroup === "Scripts") {
        setFilterLabels(filterOption);
    }
    setFilterGroup(filterGroup);
    handleonSubmit(filterOption, filterGroup);

  };

  const getFacetQueries = (filterOption, filterGroup) => {
    if (['Scripts', 'Labels'].includes(filterGroup)) {
      return Object.entries(multiFilterRef.current).map(([key, values]) => ({
        name: key,
        values,
      }));
    }
  
    const facetMap = {
      'Status': 'Status',
      'Step Group type': 'type',
      'Platform Type': 'platform',
    };
  
    return facetMap[filterGroup] ? { name: facetMap[filterGroup], values: filterOption } : null;
  };

  const getScriptPayload = (dataProps, facetQueries, xAxisValue) => {
    let payload = {
      module: 'custom-graph-module',
      collectionName: 'modules',
      searchText: '',
      facetQueries: facetQueries,
    };
    if (dataProps?.users?.length) {
      payload.customGraphFilter = {
        users: dataProps.users,
        defaultFilters: [{ name: 'userType', values: [dataProps.userType] }],
        secondOption: 'users',
      };
    } else if (dataProps?.labels?.length) {
      payload.customGraphFilter = {
        labels: dataProps.labels,
        secondOption: xAxisValue,
      };
    } else if (dataProps?.scripts?.length) {
      payload.customGraphFilter = {
        types: dataProps?.scripts,
        secondOption: xAxisValue,
      };
    }
    payload.customGraphFilter.scriptTestCaseType =
      dataProps?.option === 'Number of Automation Scripts' ? 'Automation' : 'Manual';

    return payload;
  };

  const getExecutionPayload = (dataProps, facetQueries) => {
    let payload = {};
    payload.executionType = dataProps?.option === 'Number of Automation Executions' ? 'Automation' : 'Manual';

    facetQueries.forEach((item) => {
      if (item.name === 'createdOn') {
        payload.dateRange = item.values;
      } else if (item.name === 'Status') {
        payload.statuses = item.values;
      }
    });

    if (dataProps?.users?.length) {
      payload.users = dataProps.users;
    } else if (dataProps?.statuses?.length) {
      payload.statuses = dataProps.statuses;
    } else if (dataProps?.machines?.length) {
      payload.machines = dataProps?.machines;
    } else if (dataProps?.executionRunType?.length) {
      payload.executionRunType = dataProps?.executionRunType;
    } else if (dataProps?.suiteNames?.length) {
      payload.suiteNames = dataProps?.suiteNames;
    }

    return payload;
  };

  const getProgramElementPayload = (dataProps, facetQueries, xAxisValue) => {
    let payload = {
      module: 'custom-graph-package',
      collectionName: 'packages',
      searchText: '',
      facetQueries: facetQueries,
    };

    if (dataProps?.users?.length) {
      payload.customGraphFilter = {
        users: dataProps.users,
        defaultFilters: [{ name: 'userType', values: [dataProps.userType] }],
        secondOption: getSecondOption(xAxisValue),
      };
    }

    return payload;
  };

  const getElementPayload = (dataProps, facetQueries, xAxisValue) => {
    let payload = {
      module: 'custom-graph-pages',
      collectionName: 'pages',
      searchText: '',
      facetQueries: facetQueries,
    };

    if (dataProps?.users?.length) {
      payload.customGraphFilter = {
        users: dataProps.users,
        defaultFilters: [{ name: 'userType', values: [dataProps.userType] }],
        secondOption: getSecondOption(xAxisValue),
      };
    } else if (dataProps?.platforms?.length) {
      setTitle('Platforms');
      payload.customGraphFilter = {
        platforms: dataProps?.platforms,
        secondOption: xAxisValue,
      };
    } else if (dataProps?.types?.length) {
      payload.customGraphFilter = {
        types: dataProps?.types,
        secondOption: xAxisValue,
      };
    }

    return payload;
  };

  const getStepGroupPayload = (dataProps, facetQueries, xAxisValue) => {
    let payload = {
      module: 'custom-graph-stepGroups',
      collectionName: 'libraries',
      searchText: '',
      facetQueries: facetQueries,
    };

    if (dataProps?.users?.length) {
      payload.customGraphFilter = {
        users: dataProps.users,
        defaultFilters: [{ name: 'userType', values: [dataProps.userType] }],
        secondOption: getSecondOption(xAxisValue),
      };
    } else if (dataProps?.types?.length) {
      payload.customGraphFilter = {
        types: dataProps?.types,
        secondOption: xAxisValue,
      };
    }

    return payload;
  };

  const getDefectPayload = (dataProps, facetQueries, xAxisValue) => {
    let payload = {
      module: 'custom-graph-defect',
      collectionName: 'defects',
      searchText: '',
      facetQueries: facetQueries,
    };

    if (dataProps?.users?.length) {
      payload.customGraphFilter = {
        users: dataProps.users,
        secondOption: getSecondOption(xAxisValue),
      };
    } else if (dataProps?.valuesToFilter?.length) {
      payload.customGraphFilter = {
        valuesToFilter: dataProps?.valuesToFilter,
        secondOption: xAxisValue,
      };
    }

    return payload;
  };

  const getPayload = async (facetQueries) => {
    let dataProps = props?.data;
    let payload = {};
    let label = '';
    const xAxisValue = props?.data?.name?.split('v/s' || 'vs')[1]?.trim();
    switch (dataProps?.option) {
      case 'Number of Automation Scripts':
      case 'Number of Manual Test cases':
        label = 'Scripts';
        payload = getScriptPayload(dataProps, facetQueries, xAxisValue);
        break;
      case 'Number of Automation Executions':
      case 'Number of Manual Executions':
        label = 'Executions';
        payload = getExecutionPayload(dataProps, facetQueries);
        break;
      case 'Number of Program Elements':
        label = 'Scripts';
        payload = getProgramElementPayload(dataProps, facetQueries, xAxisValue);
        break;
      case 'Number of Elements':
        label = 'Scripts';
        payload = getElementPayload(dataProps, facetQueries, xAxisValue);
        break;
      case 'Number of Step Groups':
        label = 'Scripts';
        payload = getStepGroupPayload(dataProps, facetQueries, xAxisValue);
        break;
      case 'Number of defects':
        label = 'Scripts';
        payload = getDefectPayload(dataProps, facetQueries, xAxisValue);
        break;
      default:
        break;
    }

    return [payload, label];
  };

  const handleonSubmit = async (data, label) => {

    const facetQueries = [];
    if (label !== 'date') {
      if (Object.keys(multiFilterRef.current)?.length) {
        facetQueries.push(getFacetQueries(data, label));
        if (selectedDate?.start !== null && selectedDate?.end !== null) {
          facetQueries.push({
            name: 'createdOn',
            values: [selectedDate.start, selectedDate.end],
          });
        } else {
          const date = getDates();
          facetQueries.push({
            name: 'createdOn',
            values: [date.fromDate, date.toDate],
          });
        }
      } else {
        if (selectedDate?.start !== null && selectedDate?.end !== null) {
          facetQueries.push({
            name: 'createdOn',
            values: [selectedDate.start, selectedDate.end],
          });
        } else {
          const date = getDates();
          facetQueries.push({
            name: 'createdOn',
            values: [date.fromDate, date.toDate],
          });
        }
      }
    } else {
      if (data?.start !== null && data?.end !== null) {
        facetQueries.push({
          name: 'createdOn',
          values: [data.start, data.end],
        });
      } else {
        const date = getDates();
        facetQueries.push({
          name: 'createdOn',
          values: [date.fromDate, date.toDate],
        });
      }
    }
    let chartDatas = {
      labels: [],
      datasets: [
        {
          barThickness: 35,
          barPercentage: 9,
          data: [],
          count: [],
          backgroundColor: [
            colors.ff_primary,
            colors.ff_skyBlue,
            colors.ff_skyLight,
            colors.ff_lightGreen,
            colors.ff_orange,
            colors.ff_lightPink,
            colors.ff_lightBrown,
            colors.ff_purple,
          ],
          hoverBackgroundColor: [
            'rgba(67, 77, 184, 0.5)',
            'rgba(31, 120, 180, 0.5)',
            'rgba(166, 206, 227, 0.5)',
            'rgba(178, 223, 138, 0.5)',
            'rgba(227, 149, 18, 0.5)',
            'rgba(216, 89, 255, 0.5)',
            ' rgba(184, 114, 67, 0.5)',
            'rgba(129, 99, 224, 0.5)',
          ],
          toolTipMailArray: [],
        },
      ],
    };
    let payloadData = await getPayload(facetQueries);
    let labels = [];
    let count = [];
    let percentage = [];
    let mailToolTip = [];
    
    payloadData[0]['facetQueries']=payloadData?.[0]['facetQueries']?.flat();
    let response = await getSearchedGraphData(payloadData[0], payloadData[1]);
    if (response?.data?.responseCode === 200) {
      if (['modifiedBy', 'createdBy', 'assignTo', 'users'].includes(response?.data?.filterResponse?.name)) {
        let filterResponseData = response?.data?.filterResponse?.values;

        let userLabels = Object.keys(filterResponseData);
        userLabels.forEach((key) => {
          const { username, percent, emailId, count: keyCount } = filterResponseData[key];
          if (key !== 'Others') {
            labels.push(username);
            count.push(percent);
            percentage.push(keyCount);
            mailToolTip.push(emailId);
          } else {
            labels.push('Others');
            count.push(percent);
            percentage.push(keyCount);
          }
          chartDatas.labels = labels;
          chartDatas.datasets[0].count = count;
          chartDatas.datasets[0].data = percentage;
          chartDatas.datasets[0].toolTipMailArray = mailToolTip;
          chartDatas.datasets[0].userLegendMailArray = mailToolTip;
          getTotalCount(percentage);
        });
      } else {
        if (['Number of Automation Executions', 'Number of Manual Executions'].includes(props?.data?.option)) {
          let filterResponseData = response?.data.responseObject;
          let chartArray = filterResponseData;
          let labels = Object.keys(chartArray);
          const userData = labels.map((key) => {
            if (filterResponseData[key].username !== undefined && filterResponseData[key].username !== null) {
              const userData = { username: filterResponseData[key].username, emailId: filterResponseData[key].emailId };
              return userData;
            } else {
              if (data.option === 'Number of Automation Executions') {
                const [username, emailId] = key.split(':').map((str) => str.trim());
                return {
                  username,
                  emailId,
                };
              } else {
                return {
                  username: key,
                  emailId: null,
                };
              }
            }
          });
          const usernames = userData.map((user) => user.username);
          const emailIds = userData.map((user) => user.emailId);
          let count = labels.map((key) => chartArray[key].percent);
          let percentage = labels.map((key) => chartArray[key].count);
          chartDatas.labels = usernames;
          chartDatas.datasets[0].count = count;
          chartDatas.datasets[0].data = percentage;
          chartDatas.datasets[0].toolTipMailArray = props.data.graphType === 'BarGraph' && emailIds;
          chartDatas.datasets[0].userLegendMailArray = emailIds;
          getTotalCount(percentage);
        } else {
          let filterResponseData = response?.data?.filterResponse?.values;
          let chartArray = Object.keys(filterResponseData);
          chartArray?.map((key) => {
            labels.push(key);
            count.push(filterResponseData[key].percent);
            percentage.push(filterResponseData[key].count);
          });
          chartDatas.labels = labels;
          chartDatas.datasets[0].count = count;
          chartDatas.datasets[0].data = percentage;
          getTotalCount(percentage);
        }
      }
    }
    if (chartDatas) {
      setChartData(chartDatas);
    }
    sethasData(true);
  };

  const pdfRef = useRef();
  const downloadPiePDF = (event) => {
    const chartContainer = event.currentTarget.closest('.chart-containers');
    const legendElement = chartContainer.querySelector('#ff-chart-lagend');
    const input = chartContainer.getElementsByClassName('legend-allignement');

    if (!legendElement || input.length === 0) {
      console.error('Legend element or legend alignment element not found.');
      return;
    }

    const originalLegendHeight = legendElement.style.height;
    legendElement.style.height = 'auto';
    const contentHeight = legendElement.scrollHeight + 300;
    legendElement.style.height = `${contentHeight}px`;

    const chartName = props?.data?.name;

    html2canvas(input[0], {
      height: contentHeight,
      windowHeight: contentHeight,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: [imgWidth, imgHeight],
      });

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

      setTimeout(() => {
        pdf.save(`${chartName}.pdf`);
        legendElement.style.height = originalLegendHeight;
        props.MyAlert.success(`${chartName} PDF file downloaded successfully.`);
      }, 200);
    });
  };

  const downloadBarChartPdf = (event) => {
    const temptXAxisValue = xAxisValue;
    const temptYAxisValue = yAxisValue;
    const chartContainer = event.currentTarget.closest('.chart-containers');
    const chart1Canvas = chartContainer.querySelector('#chart1');
    const chart2Canvas = chartContainer.querySelector('#chart2');

    if (!chart1Canvas || !chart2Canvas) {
      console.error('One or both of the canvas elements are not found.');
      return;
    }
    const chartName = props?.data?.name;
    setXAxisLabel(xAxisValue);
    setYAxisLabel(yAxisValue);
    setXAxisValue('');
    setYAxisValue('');

    setTimeout(async () => {
      const canvas1 = await html2canvas(chart1Canvas);
      const canvas2 = await html2canvas(chart2Canvas);
      const combinedCanvas = document.createElement('canvas');
      const context = combinedCanvas.getContext('2d');

      if (!context) {
        return;
      }

      combinedCanvas.width = canvas1.width + canvas2.width;
      combinedCanvas.height = Math.max(canvas1.height, canvas2.height);
      context.drawImage(canvas2, 0, 0);
      context.drawImage(canvas1, canvas2.width, 0);
      const combinedBase64Image = combinedCanvas.toDataURL('image/png');
      const pdfWidth = combinedCanvas.width;
      const pdfHeight = combinedCanvas.height;
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: [pdfWidth, pdfHeight],
      });
      const scaleFactor = pdf.internal.pageSize.width / combinedCanvas.width;
      pdf.addImage(
        combinedBase64Image,
        'PNG',
        0,
        0,
        combinedCanvas.width * scaleFactor,
        combinedCanvas.height * scaleFactor
      );
      pdf.save(`${chartName}.pdf`);
      props.MyAlert.success(`${chartName} pdf file downloaded successfully.`);
      setXAxisLabel('');
      setYAxisLabel('');
      setXAxisValue(temptXAxisValue);
      setYAxisValue(temptYAxisValue);
    }, 500);
  };

  return (
    <>
      <div className="chart-containers mx-3  mt-3 relative" ref={pdfRef}>
        <div className="flex justify-between">
          <div className="mt-6 ml-5 chartHeader fontPoppinsSemiboldSm" id={'chartName'}>
            {props?.data?.name}
          </div>
          <div className="text-right cursor-pointer mt-6 flex flex-row table-non-link-color-common mr-5">
            {!downloadingPDF && (
              <>
                {(props.accessData === 'Edit' || props.accessData === 'FullAccess') && projectStatus === 'Open' && (
                  <span className="action-button">
                    {' '}
                    <Tooltip title={DASHBOARD_CONSTANTS.EDIT} placement="bottom">
                      <EditIcon
                        onClick={() => {
                          setOpenGraph(true);
                        }}
                      />
                    </Tooltip>
                  </span>
                )}
                {props.accessData === 'FullAccess' && projectStatus === 'Open' && (
                  <span className="cursor-pointer action-button ml-6 ">
                    <Tooltip title={DASHBOARD_CONSTANTS.DELETE} placement="bottom">
                      <DeleteOutlineIcon onClick={() => openModalDelete(props?.data)} />
                    </Tooltip>{' '}
                  </span>
                )}
                {projectStatus === 'Open' && (
                  <span className=" cursor-pointer action-button ml-6 ">
                    <Tooltip title={DASHBOARD_CONSTANTS.DOWNLOAD} placement="bottom">
                      <FileDownloadOutlinedIcon
                        onClick={(event) =>
                          props?.data?.graphType === 'BarGraph' ? downloadBarChartPdf(event) : downloadPiePDF(event)
                        }
                      />
                    </Tooltip>{' '}
                  </span>
                )}
                <span className="ml-6 relative action-button absolute" onClick={toggleFiltersList}>
                  {' '}
                  <Tooltip title={'Filter'} placement="bottom">
                    <FilterOutline className=" mt-1 text-lg cursor-pointer filter-icon h-4 w-4" />
                  </Tooltip>
                </span>
              </>
            )}
          </div>
        </div>
        <div className={`${props?.data?.graphType === 'BarGraph' ? 'ml-8 mt-8' : 'ml-20 mt-5'} `}>
          {hasData &&
            (props?.data?.graphType === 'BarGraph' ? (
              <>
                <div className="flex flex-row items-center justify-start ">
                  <span className="fontPoppinsMediumSm whitespace-nowrap  label-box chart-label">{yAxisValue}</span>
                  <div style={{ maxWidth: '100%' }}>
                    <Chart
                      type="BarChart"
                      chartData={chartData}
                      chartHeight="300px"
                      chartWidth="1000px"
                      toolTipText={subTitle}
                      xAxisLabel={xAxisLabel}
                      yAxisLabel={yAxisLabel}
                    />
                  </div>
                </div>
                <div className="fontPoppinsMediumSm text-center chart-label">{xAxisValue}</div>
              </>
            ) : (
              <>
                <div className="pieChart-legend-container">
                  <Chart
                    type="PieChart"
                    chartData={chartData}
                    chartSize="400px"
                    legend={true}
                    centerText={true}
                    legendHeight="245px"
                    legendTitle={title}
                    ledendTitlesize="large"
                    legendSubtitle={subTitle}
                    legendSubTitleValue={totalCount}
                    legendSubTitleSize="small"
                    legendValueSize="small"
                    legendSubtitleColor="#3C3838"
                    legendTitleColor="#434DB8"
                    legeContainerWidth="362.5px"
                    isLegendUser={isUser === true && title === 'Users' ? true : false}
                  />
                </div>
              </>
            ))}
        </div>
        {showFiltersList && (
          <div className=" filters_header_open mt-2 ">
            <div className="filter-container">
              <div className=" fontPoppinsSemiboldSm rs-blue my-2 filter">{DASHBOARD_CONSTANTS.FILTERS}</div>
            </div>

            <div>
              {
                <CustomFilterTimePeriod
                  options={filterOptions}
                  filterOptionsLabels={filterOptionsLabels}
                  label={label}
                  labelsOption={labelsOption}
                  handleTimePeriod={handleTimePeriod}
                  handleFilter={handleFilter}
                  currentTimePeriod={currentTimePeriod}
                  selectedDateRange={selectedDateRange}
                  selectedFilterLabel={filterLabels}
                  selectedLabels={filterLableOne}
                />
              }
            </div>
          </div>
        )}
      </div>
      {openGraph && (
        <CreateEditGraph
          isOpen={openGraph}
          onClose={closeGraphDrawer}
          MyAlert={props.MyAlert}
          actionType={'Edit'}
          data={props?.data}
          handleChange={props?.handleChange}
          reloadTree={props?.reloadTree}
        />
      )}{' '}
      {openDeleteModal && (
        <GraphDelete
          openModal={openModalDelete}
          closeModal={closeDeleteModal}
          MyAlert={props.MyAlert}
          selectedGraph={selectedGraph}
          reloadTree={props?.reloadTree}
          handleChange={props?.handleChange}
        />
      )}
    </>
  );
};
export default CustomGraph;
