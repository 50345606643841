import React, { useState, useRef, useEffect, useContext } from 'react';
import './ExcelStyle.scss';
import Spreadsheet from 'react-spreadsheet';
import ImportExcelStyles from './ExcelComp/ImportExcelStyles';
import ChangeExcelStyles from './ExcelComp/ChangeExcelStyles';
import ColorBarSelector from './CommonComp/ColorBarSelector';
import { Button, Icon, MenuOption, Popup, Tooltip } from 'fireflink-ui';
import { isEmptyValue } from '@src/util/common_utils';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { colors } from '@src/css_config/colorConstants';
import { downloadFileFromCloud, getExcelFile, updateExcelFile } from '@src/api/api_services';
import { BUTTON_CONSTANTS, WARNING_POPUP_CONSTANTS } from '@src/common/ui-constants';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { CommonLoader } from '@src/pages/common/common-loader';
import { saveFileFromBlob } from '@src/pages/test-development/shared/common-methods';
import isEqual from 'lodash/isEqual';

function ExcelFilePage(props) {
  const [fileDetails, setFileDetails] = useState({ fileName: '', sheetNames: [] });
  const [selectedSheet, setSelectedSheet] = useState({ name: '', index: 0 });
  const [worksheetsData, setWorksheetsData] = useState({});
  const [selectedSheetData, setSelectedSheetData] = useState([[]]);
  const [borderType, setBorderType] = useState('All Border');
  const [textAlign, setTextAlign] = useState('Text Left');
  const [isSaving, setIsSaving] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [editingSheet, setEditingSheet] = useState(null);
  const history = useHistory();
  const [selectedValue, setSelectedValue] = useState({
    value: '',
    row: '',
    column: '',
    alphaCol: '',
    style: {},
    sheet: selectedSheet.name,
  });
  const [selectedCell, setSelectedCell] = useState([]);
  const [reloadKey, setReloadKey] = useState(false);
  const [formatePainter, setFormatePainter] = useState({ active: false, style: null });
  const [cancelPopup, setCancelPopup] = useState(false);
  const sheetRef = useRef(null);
  const outerSheetRef = useRef(null);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    type: 'cell',
  });
  const greyColor = colors.light_gray_60.replace('#', '');
  const [colorContainer, setColorPicker] = useState({
    color: greyColor,
    backgroundColor: greyColor,
    borderColor: greyColor,
  });

  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');
  const parentId = new URLSearchParams(search).get('parentId');
  const [isLoading, setIsLoading] = useState(true);
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);

  const borderTypeIcon = () =>
    [
      { name: 'border-all-sides', title: 'All Border', value: 'All Border' },
      { name: 'border-bottom', title: 'Border Bottom', value: 'Border Bottom' },
      { name: 'border-left', title: 'Border Left', value: 'Border Left' },
      { name: 'border-right', title: 'Border Right', value: 'Border Right' },
      { name: 'border-top', title: 'Border Top', value: 'Border Top' },
      { name: 'border-none', title: 'No Border', value: 'No Border' },
    ].map((e) => ({
      title: e.title,
      value: e.name,
      element: <Icon color="none" onClick={() => applyStyle('border', e.title)} name={e.name} />,
      label: (
        <div className="menu-list">
          <Icon color="none" onClick={() => applyStyle('border', e.title)} name={e.name} />
          <div className="menu-title">{e.title}</div>
        </div>
      ),
    }));

  const TextAlignIcon = () =>
    [
      { name: 'text-align-left', title: 'Text Left' },
      { name: 'text-align-center', title: 'Text Center' },
      { name: 'text-align-right', title: 'Text Right' },
    ].map((e) => ({
      title: e.title,
      value: e.name,
      element: <Icon color="none" onClick={() => applyStyle('alignment', e.title)} name={e.name} />,
      label: (
        <div className="menu-list" onClick={() => applyStyle('alignment', e.title)}>
          <Icon color="none" name={e.name} />
          <div className="menu-title">{e.title}</div>
        </div>
      ),
    }));
  const contextGroup = [
    {
      name: 'row',
      value: [
        { name: 'Add Row', title: 'Add Row' },
        { name: 'Delete Row', title: 'Delete Row' },
      ],
    },
    {
      name: 'column',
      value: [
        { name: 'Add Column', title: 'Add Column' },
        { name: 'Delete Column', title: 'Delete Column' },
      ],
    },
    {
      name: 'sheet_options',
      value: [
        {
          name: 'Add Sheet',
          title: (
            <span
              className="flex items-center justify-evenly"
              onClick={() => {
                isSaving && handleAddSheet();
              }}
            >
              <Icon color={colors.rs_primary} name="add_icon" /> <span className="pl-1">Add Sheet</span>
            </span>
          ),
        },
        {
          name: 'Delete Sheet',
          title: (
            <span className="flex items-center justify-evenly">
              <Icon color={colors.button_red_60} name="delete" /> <span className="pl-1">Delete Sheet</span>
            </span>
          ),
        },
      ],
    },
  ];

  const basicStyle = {
    name: 'Arial',
    size: 12,
    bold: false,
    italic: false,
    color: colors.text_black.replace('#', ''),
    backgroundColor: colors.text_white.replace('#', ''),
    border: {
      top: 'THIN',
      bottom: 'THIN',
      left: 'THIN',
      right: 'THIN',
    },
    alignment: {
      horizontal: 'GENERAL',
      vertical: 'BOTTOM',
      wrapText: false,
    },
  };

  const EmptyRow = {
    value: null,
    style: basicStyle,
    type: false,
  };

  async function delayTime(time) {
    await new Promise((resolve) => setTimeout(resolve, time));
  }

  let checkVal = (val) => {
    if (val === undefined || val === null) {
      return null;
    }
    return val;
  };

  useEffect(() => {
    const loadExcelFile = async () => {
      resetProgress();
      startOrResumeProgress({ stopAt: 90 });
      setIsLoading(true);
      try {
        const response = await getExcelFile(id);
        stopProgress();
        startOrResumeProgress({ stopAt: 100, intervalDuration: 10 });

        if (response.data.responseCode === 413) {
          history.push(`${props.path}/LargeFile?id=${id}&parentId=${parentId}`);
          return;
        }

        const payload = response.data;
        const sheetNames = payload.sheets.map((e) => e.sheetName);
        setFileDetails({ fileName: payload.fileName, sheetNames });
        if (sheetNames?.length > 0) {
          setSelectedSheet({ name: sheetNames[0], index: 0 });
        }
        const newWorksheetsData = {};
        payload.sheets.forEach((sheet) => {
          const { sheetName, data: json } = sheet;
          const maxRows = Math.max(26, json.length + 2);
          const maxCols = Math.max(26, Math.max(...json.map((row) => row.length)) + 2);

          let spreadsheetData = Array.from({ length: maxRows }, () => Array.from({ length: maxCols }, () => EmptyRow));
          json.forEach((row, rowIndex) => {
            row.forEach((cell, colIndex) => {
              if (rowIndex < maxRows && colIndex < maxCols) {
                spreadsheetData[rowIndex][colIndex] = {
                  value: checkVal(cell.value),
                  style: cell.styles,
                  type: true,
                };
              }
            });
          });
          newWorksheetsData[sheetName] = spreadsheetData;
        });
        setWorksheetsData(newWorksheetsData);
        const firstSheetName = Object.keys(newWorksheetsData)[0];
        if (firstSheetName) {
          setSelectedSheetData(newWorksheetsData[firstSheetName]);
        }
      } catch (error) {
        console.error(error);
        props.MyAlert.danger('An error occurred while loading the Excel file.');
      } finally {
        setIsLoading(false);
      }
    };
    loadExcelFile();
  }, [reloadKey]);

  const handleCancelData = () => {
    props.MyAlert.info('Data Successfully reset');
    setIsSaving(false);
    setIsCanceling(false);
    setReloadKey((prevKey) => !prevKey);
  };

  const prevSheetDataRef = useRef(selectedSheetData);
  const canUpdateRef = useRef(true);

  useEffect(() => {
    if (!isEqual(prevSheetDataRef.current, selectedSheetData) && canUpdateRef.current) {
      prevSheetDataRef.current = selectedSheetData;
      canUpdateRef.current = false;
      setWorksheetsData((prevData) => {
        let updatedData = { ...prevData };
        updatedData[selectedSheet.name] = selectedSheetData;
        return updatedData;
      });
    } else {
      canUpdateRef.current = true;
    }
  }, [selectedSheetData]);

  const createStyler = (setWorksheetsData, selectedCell) => (styleType, value) => {
    ChangeExcelStyles(setWorksheetsData, {
      sheetName: selectedSheet.name,
      styleType,
      value,
      selectedCell,
    });
  };

  const applyStyle = createStyler(setWorksheetsData, selectedCell);

  useEffect(() => {
    let TwoSec = setTimeout(() => {
      applyStyle('color', colorContainer.color);
    }, 800);
    return () => {
      clearTimeout(TwoSec);
    };
  }, [colorContainer.color]);

  useEffect(() => {
    let TwoSec = setTimeout(() => {
      applyStyle('backgroundColor', colorContainer.backgroundColor);
    }, 800);
    return () => {
      clearTimeout(TwoSec);
    };
  }, [colorContainer.backgroundColor]);

  useEffect(() => {
    ImportExcelStyles(worksheetsData[selectedSheet.name]);
    setSelectedSheetData(worksheetsData[selectedSheet.name]);
  }, [worksheetsData, selectedSheet.name]);

  const selectedCellData = ({ row, column }) => {
    let myVal = worksheetsData[selectedSheet.name]?.[row]?.[column];
    let alphaCol = '';
    let col = column;
    while (col >= 0) {
      alphaCol = String.fromCharCode((col % 26) + 65) + alphaCol;
      col = Math.floor(col / 26) - 1;
    }

    setSelectedValue({
      value: myVal ? myVal.value : '',
      row: row + 1,
      column: column,
      alphaCol: alphaCol,
      sheet: selectedSheet.name,
      style: myVal ? myVal.style : {},
    });
  };

  const handleSaveData = async () => {
    resetProgress();
    startOrResumeProgress({ stopAt: 90 });

    await delayTime(2000);

    const dataToSave = {
      fileName: fileDetails.fileName,
      sheets: fileDetails.sheetNames.map((sheetName) => {
        const sheetData =
          worksheetsData[sheetName]?.map((row) => {
            const lastIndex = row.reduce((lastIdx, cell, i) => {
              const hasValueOrTypeTrue = (cell.value !== null && cell.value.trim() !== '') || cell.type === true;
              return hasValueOrTypeTrue ? i : lastIdx;
            }, -1);

            const filteredRow = row
              .map((cell, index) => {
                if (cell.value !== null) {
                  return {
                    value: cell.value,
                    styles: cell.style,
                  };
                } else if (cell.type || index <= lastIndex) {
                  return { value: '', styles: cell.style };
                }
                return null;
              })
              .filter((cell) => cell !== null);

            return filteredRow.length > 0 ? filteredRow : [];
          }) || [];

        const finalData = sheetData.filter((row, index) => {
          const isNextRowNotEmpty = sheetData.slice(index + 1).some((nextRow) => nextRow.length > 0);
          return row.length > 0 || isNextRowNotEmpty;
        });

        return {
          sheetName: sheetName,
          data: finalData.length === 0 ? [[]] : finalData,
        };
      }),
    };

    updateExcelFile(id, dataToSave)
      .then((response) => {
        stopProgress();
        startOrResumeProgress({ stopAt: 100, intervalDuration: 10 });
        props.MyAlert.success(`${response.data.responseObject.name} saved Successfully`);
      })
      .catch((error) => {
        props.MyAlert.danger(error.data.status);
      })
      .finally(() => {
        setIsSaving(false);
        setIsLoading(false);
        setIsCanceling(false);
      });
  };

  const cancelData = () => {
    if (isCanceling) {
      setCancelPopup(true);
    } else {
      setIsSaving(false);
    }
  };

  const handleExportData = async () => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    try {
      downloadFileFromCloud(id).then((d) => {
        saveFileFromBlob(d.data, fileDetails.fileName);
      });
      stopProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      props.MyAlert.success('File downloaded successfully!');
    } catch (error) {
      props.MyAlert.danger(`Download failed: ${error.message}`);
    }
    setIsLoading(false);
  };

  const preventDefault = (e) => {
    e.preventDefault();
  };

  const handleSheetChange = ({ index, name }) => {
    setSelectedSheet({ index: index, name: name });
  };

  const handleAddSheet = () => {
    const generateUniqueSheetName = (baseName, existingNames) => {
      let newName = baseName;
      let counter = 1;
      while (existingNames.includes(newName)) {
        newName = `${baseName}${counter}`;
        counter++;
      }
      return newName;
    };
    const baseName = 'NewSheet';
    const existingSheetNames = fileDetails.sheetNames;
    const newSheetName = generateUniqueSheetName(baseName, existingSheetNames);
    const newSheetData = Array.from({ length: 26 }, () => Array.from({ length: 26 }, () => EmptyRow));
    setFileDetails((prev) => ({ ...prev, sheetNames: [...prev.sheetNames, newSheetName] }));
    setWorksheetsData({ ...worksheetsData, [newSheetName]: newSheetData });
    setSelectedSheet({ index: fileDetails?.sheetNames?.length, name: newSheetName });
  };

  const handleContextMenuOptionClick = (option) => {
    setWorksheetsData((prevWorksheetsData) => {
      const updateSheet = [...prevWorksheetsData[selectedSheet.name]];
      let insertRow = [];

      switch (option) {
        case 'Add Row':
          insertRow = Array.from({ length: updateSheet[0]?.length }, () => EmptyRow);
          updateSheet.splice(selectedValue.row, 0, insertRow);
          return {
            ...prevWorksheetsData,
            [selectedSheet.name]: updateSheet,
          };

        case 'Delete Row':
          if (selectedValue.row >= 0 && selectedValue.row <= updateSheet?.length) {
            updateSheet.splice(selectedValue.row, 1);
            return {
              ...prevWorksheetsData,
              [selectedSheet.name]: updateSheet,
            };
          }
          break;

        case 'Add Column':
          if (updateSheet.length > 0) {
            const newSheet = updateSheet.map((row) => {
              const newRow = [...row];
              newRow.splice(selectedValue.column, 0, EmptyRow);
              return newRow;
            });
            return {
              ...prevWorksheetsData,
              [selectedSheet.name]: newSheet,
            };
          }
          break;
        case 'Delete Column':
          if (updateSheet.length > 0 && selectedValue.column >= 0 && selectedValue.column < updateSheet[0].length) {
            const newSheet = updateSheet.map((row) => {
              const newRow = [...row];
              newRow.splice(selectedValue.column, 1);
              return newRow;
            });
            return {
              ...prevWorksheetsData,
              [selectedSheet.name]: newSheet,
            };
          }
          break;

        case 'Delete Sheet':
          if (fileDetails?.sheetNames?.length > 1) {
            const updatedSheetNames = [...fileDetails.sheetNames];
            const sheetIndex = selectedSheet.index;
            updatedSheetNames.splice(sheetIndex, 1);
            const updatedWorksheetsData = { ...worksheetsData };
            delete updatedWorksheetsData[selectedSheet.name];
            setFileDetails((prev) => ({ ...prev, sheetNames: updatedSheetNames }));
            setWorksheetsData(updatedWorksheetsData);
            const newIndex = Math.min(sheetIndex, updatedSheetNames?.length - 1);
            setSelectedSheet({
              index: newIndex,
              name: updatedSheetNames[newIndex],
            });
          } else {
            props.MyAlert.danger('Cannot delete the last sheet.');
          }
          break;

        default:
          break;
      }

      return prevWorksheetsData;
    });
    setContextMenu({ visible: false, x: 0, y: 0, type: null });
  };

  function getTextColor(e) {
    setColorPicker((prev) => ({ ...prev, color: e }));
  }
  function getBackgroundColor(e) {
    setColorPicker((prev) => ({ ...prev, backgroundColor: e }));
  }

  const formatePaint = () => {
    if (!formatePainter.active && selectedValue.style) {
      setFormatePainter({
        active: true,
        style: selectedValue.style,
      });
    } else if (formatePainter.active) {
      const updatedSheet = worksheetsData[selectedSheet.name].map((row, i) =>
        row.map((col, j) => {
          const isSelected = selectedCell.some((e) => e.row === i && e.column === j);
          if (isSelected) {
            return { ...col, style: formatePainter.style || col.style };
          }
          return col;
        })
      );
      setWorksheetsData((prev) => ({
        ...prev,
        [selectedSheet.name]: updatedSheet,
      }));

      setFormatePainter({ active: false, style: null });
    }
  };
  const renderContextMenu = (contextOption, key) => {
    return (
      <div key={key} className="context-menu" style={{ top: contextMenu.y, left: contextMenu.x }}>
        <div className="menu-options">
          {contextOption.map((item) => {
            return (
              <div
                className="options"
                key={item.name}
                onClick={() => {
                  handleContextMenuOptionClick(item.name);
                }}
              >
                <label className="sheet-context-menu">{item.title}</label>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  let handleSelect = (e) => {
    if (e.hasOwnProperty('range')) {
      let rowStart = e.range.start.row;
      let rowEnd = e.range.end.row;
      let columnStart = e.range.start.column;
      let columnEnd = e.range.end.column;
      let cells = [];
      for (let i = rowStart; i <= rowEnd; i++) {
        for (let j = columnStart; j <= columnEnd; j++) {
          cells.push({ row: i, column: j });
        }
      }
      setSelectedCell(cells);
    }
  };

  const selectRowAndColumn = (event) => {
    event.preventDefault();
    const isNumber = (value) => !isNaN(value) && !isNaN(parseFloat(value));

    if (event.target.tagName === 'TH' || event.target.closest('th')) {
      const headerText = event.target.innerText.trim();
      let selectedCells = [];

      if (isEmptyValue(headerText)) {
        const rowCount = worksheetsData[selectedSheet.name].length;
        const colCount = worksheetsData[selectedSheet.name][0]?.length || 0;

        for (let i = 0; i < rowCount; i++) {
          for (let j = 0; j < colCount; j++) {
            selectedCells.push({ row: i, column: j });
          }
        }
      } else if (isNumber(headerText)) {
        const rowIndex = event.target.parentNode.rowIndex;
        const colCount = worksheetsData[selectedSheet.name][0]?.length || 0;

        for (let j = 0; j < colCount; j++) {
          selectedCells.push({ row: rowIndex - 1, column: j });
        }
      } else {
        const columnIndex = event.target.cellIndex;
        const rowCount = worksheetsData[selectedSheet.name].length;

        for (let i = 0; i < rowCount; i++) {
          selectedCells.push({ row: i, column: columnIndex - 1 });
        }
      }
      setSelectedCell(selectedCells);
    }
  };

  let evaluatedData = (newData) => {
    if (newData) {
      const currentSheet = worksheetsData[selectedSheet.name] || [];
      const currentRowCount = currentSheet.length;
      const currentColCount = currentSheet[0]?.length || 0;

      const newRowCount = newData.length;
      const newColCount = newData[0]?.length || 0;

      if (newRowCount > currentRowCount) {
        const additionalRows = newRowCount - currentRowCount;
        for (let i = 0; i < additionalRows; i++) {
          currentSheet.push(Array.from({ length: currentColCount }, () => EmptyRow));
        }
      }

      if (newColCount > currentColCount) {
        currentSheet.forEach((row) => {
          for (let i = 0; i < newColCount - currentColCount; i++) {
            row.push(EmptyRow);
          }
        });
      }

      const validData = currentSheet.map((row, rowIndex) =>
        row.map((cell, colIndex) => {
          if (rowIndex < newRowCount && colIndex < newColCount) {
            return {
              value: checkVal(newData[rowIndex][colIndex]?.value),
              style: newData[rowIndex][colIndex]?.style ?? cell.style,
              type: cell.type,
            };
          }
          return cell;
        })
      );

      setSelectedSheetData(validData);
    }
  };

  let contextSelect = (event) => {
    event.preventDefault();
    const isNumber = (value) => !isNaN(value) && !isNaN(parseFloat(value));
    if (event.target.tagName === 'TH' || event.target.closest('th')) {
      const headerText = event.target.innerText.trim();
      let cell = [];
      if (isNumber(headerText)) {
        const rowIndex = parseInt(headerText, 10) - 1;
        const columnCount = worksheetsData[selectedSheet.name][0]?.length || 0;
        for (let j = 0; j < columnCount; j++) {
          cell.push({ row: rowIndex, column: j });
        }
        setSelectedCell(cell);
        setSelectedValue((prev) => ({ ...prev, row: rowIndex, sheet: selectedSheet.name }));
        setContextMenu({ type: 'row', visible: true, x: event.clientX, y: event.clientY - 45 });
      } else {
        const columnIndex = alphabetToIndex(headerText);
        const rowCount = worksheetsData[selectedSheet.name].length;
        for (let i = 0; i < rowCount; i++) {
          cell.push({ row: i, column: columnIndex });
        }
        setSelectedCell(cell);
        setSelectedValue((prev) => ({ ...prev, column: columnIndex, sheet: selectedSheet.name }));
        setContextMenu({ type: 'column', visible: true, x: event.clientX, y: event.clientY - 45 });
      }
    }
  };

  const alphabetToIndex = (letters) => {
    let index = 0;
    const length = letters.length;
    for (let i = 0; i < length; i++) {
      const letter = letters[i].toUpperCase();
      index = index * 26 + (letter.charCodeAt(0) - 65 + 1);
    }
    return index - 1;
  };

  let handleKeyDown = (e) => {
    if (outerSheetRef.current && outerSheetRef.current.contains(e.target)) {
      if (isSaving) {
        if (e.ctrlKey && (e.key === 's' || e.key === 'S')) {
          e.preventDefault();
          handleSaveData();
        }
        if (e.key === 'Delete') {
          handleDeleteSelectedCells();
        }
        setIsCanceling(true);
      } else {
        preventDefault(e);
      }
    }
  };

  const handleDeleteSelectedCells = () => {
    setWorksheetsData((prev) => {
      const newData = { ...prev };
      const newSheetData = newData[selectedSheet.name].map((row) => row.map((cell) => ({ ...cell })));
      selectedCell.forEach(({ row, column }) => {
        if (newSheetData[row] && newSheetData[row][column]) {
          newSheetData[row][column] = EmptyRow;
        }
      });

      newData[selectedSheet.name] = newSheetData;
      return newData;
    });
  };

  const handleNameChange = (event, index, name) => {
    if (!event.target) {
      setEditingSheet(null);
      return;
    }

    const updatedSheetValue = event.target.textContent.trim();

    if (!updatedSheetValue) {
      props.MyAlert.info('Sheet name cannot be empty.');
      event.target.textContent = selectedSheet.name;
      setEditingSheet(null);
      return;
    }
    if (updatedSheetValue.length > 30) {
      props.MyAlert.info('The sheet name cannot be greater than 30 characters');
      event.target.textContent = selectedSheet.name;
      setEditingSheet(null);
      return;
    }
    if (fileDetails.sheetNames.includes(updatedSheetValue) && fileDetails.sheetNames[index] !== updatedSheetValue) {
      props.MyAlert.info('Sheet name already Exist');
      event.target.textContent = selectedSheet.name;
      setEditingSheet(null);
      return;
    }
    let updatedSheetNames = [...fileDetails.sheetNames];
    updatedSheetNames.splice(index, 1, updatedSheetValue);
    let remainingSheets = worksheetsData;
    let updatedData = replaceKeyValueByKeyName(remainingSheets, name, updatedSheetValue, selectedSheetData);
    setSelectedSheet((prev) => ({ ...prev, name: updatedSheetValue }));
    setWorksheetsData(updatedData);
    setFileDetails((prev) => ({ ...prev, sheetNames: updatedSheetNames }));
    setEditingSheet(null);
  };

  function replaceKeyValueByKeyName(obj, oldKey, newKey, newValue) {
    if (!(oldKey in obj)) {
      throw new Error(`Key "${oldKey}" not found in object`);
    }
    delete obj[oldKey];
    obj[newKey] = newValue;
    return obj;
  }

  const setCursorToEnd = (element) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  if (isLoading) {
    return <CommonLoader />;
  }

  return (
    <div className="flex-col justify-start overflow-hidden" ref={outerSheetRef}>
      <div className="navBar flex items-center">
        <div className="flex justify-between items-center w-full font-semibold text-sm mb-3 ">
          <div className="flex items-center">
            <Icon className="more-button relative inline-block text-left " name="excel" height={20} width={20} />
            {fileDetails.fileName}
          </div>
          <div className="flex justify-evenly items-center">
            <Button
              label="Edit"
              variant="primary"
              className="mx-1"
              onClick={() => {
                isSaving ? handleSaveData() : setIsSaving(true);
              }}
            >
              {isSaving ? 'Save' : 'Edit'}
            </Button>
            <Button
              label="Edit"
              variant={isSaving ? 'secondary' : 'primary'}
              className="mx-1"
              onClick={() => {
                isSaving ? cancelData() : handleExportData();
              }}
            >
              {isSaving ? 'Cancel' : 'Export File'}
            </Button>
          </div>
        </div>
      </div>
      <div
        className={classNames('excel-main-sheet', {
          'non-editable': !isSaving,
        })}
      >
        {!isEmptyValue(fileDetails.sheetNames) && (
          <div className="bg-white flex flex-col justify-between ">
            <div className="flex bg-sky-100 items-center justify-evenly  py-1 h-9 w-2/3">
              <Tooltip placement="top" arrow title={'Bold'}>
                <Icon
                  color="none"
                  onClick={() => {
                    applyStyle('bold', true);
                  }}
                  name="bold-icon"
                />
              </Tooltip>
              <Tooltip placement="top" arrow title={'Italic'}>
                <Icon
                  color="none"
                  onClick={() => {
                    applyStyle('italic', true);
                  }}
                  name="italic-icon"
                />
              </Tooltip>
              <MenuOption
                labelName={borderTypeIcon().find((e) => e.title === borderType)?.element}
                iconName="arrow_down"
                options={borderTypeIcon()}
                tooltipTitle="Border Type"
                placement="top"
                onOptionClick={(e) => {
                  setBorderType(e.title);
                  applyStyle('border', e.title);
                }}
              />
              <MenuOption
                labelName={TextAlignIcon().find((e) => e.title === textAlign)?.element}
                iconName="arrow_down"
                options={TextAlignIcon()}
                tooltipTitle="Text Align"
                placement="top"
                onOptionClick={(e) => {
                  setTextAlign(e.title);
                  applyStyle('alignment', e.title);
                }}
              />
              <Tooltip placement="top" arrow title={'Format Painter'}>
                <Icon
                  color={formatePainter.active ? colors.blue_dark : colors.black}
                  onClick={formatePaint}
                  name="format-painter-icon"
                />
              </Tooltip>
              <div className="flex flex-col items-center justify-evenly cursor-pointer">
                <Tooltip placement="top" arrow title={'Text Color'}>
                  <Icon
                    color="none"
                    onClick={() => {
                      applyStyle('color', colorContainer.color);
                    }}
                    name="font-color-icon"
                  />
                  <ColorBarSelector getColorValue={getTextColor} />
                </Tooltip>
              </div>
              <div className="flex flex-col items-center justify-center cursor-pointer">
                <Tooltip placement="top" arrow title={'Background Color'}>
                  <Icon
                    color="none"
                    onClick={() => {
                      applyStyle('backgroundColor', colorContainer.backgroundColor);
                    }}
                    name="paint-icon"
                  />
                </Tooltip>
                <ColorBarSelector getColorValue={getBackgroundColor} />
              </div>
            </div>
            <div
              ref={sheetRef}
              onClick={selectRowAndColumn}
              onContextMenu={(event) => {
                if (isSaving) {
                  contextSelect(event);
                }
              }}
              className="w-full overflow-scroll excel-sheet"
            >
              <Spreadsheet
                onKeyDown={handleKeyDown}
                data={worksheetsData[selectedSheet.name]}
                onEvaluatedDataChange={evaluatedData}
                onSelect={handleSelect}
                onActivate={(e) => {
                  selectedCellData(e);
                  setContextMenu((prev) => ({ ...prev, visible: false }));
                }}
              />
            </div>
            <div className="excel-tab-container">
              <div className="excel-tab">
                {fileDetails.sheetNames.map((name, index) => (
                  <div
                    key={name}
                    className={`excel-tab-list ${name === selectedSheet.name ? 'active' : ''}`}
                    onClick={() => handleSheetChange({ index: index, name: name })}
                    onContextMenu={(event) => {
                      if (isSaving) {
                        event.preventDefault();
                        setSelectedSheet({ index: index, name: name });
                        setContextMenu({
                          visible: true,
                          x: event.clientX,
                          y: event.clientY - 150,
                          type: 'sheet_options',
                        });
                      }
                    }}
                    suppressContentEditableWarning={editingSheet === index}
                    onDoubleClick={(e) => {
                      if (isSaving) {
                        setEditingSheet(index);
                        editingSheet === null && setTimeout(() => setCursorToEnd(e.target), 0);
                      }
                    }}
                    contentEditable={editingSheet === index}
                    onBlur={(e) => {
                      handleNameChange(e, index, name);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleNameChange(e, index, name);
                      }
                    }}
                  >
                    {name}
                  </div>
                ))}
              </div>

              <Tooltip title="Add Sheet" placement="top">
                <Icon
                  className="cursor-pointer ml-1 add-sheet-icon"
                  hoverEffect={isSaving}
                  color={isSaving ? colors.rs_primary : colors.light_gray_60}
                  name="add_filled_icon"
                  onClick={() => {
                    isSaving && handleAddSheet();
                  }}
                />
              </Tooltip>
            </div>

            {contextMenu.visible &&
              contextGroup.map((contextType) => {
                if (contextType.name === contextMenu.type) {
                  return renderContextMenu(contextType.value, contextType.name);
                }
                return null;
              })}
            <Popup
              isOpen={cancelPopup}
              type="warning"
              headerTitle="Warning!"
              headerText={WARNING_POPUP_CONSTANTS.SUB_HEADER_TITLE}
              continueButtonLabel={BUTTON_CONSTANTS.CONTINUE}
              cancelButtonLabel={BUTTON_CONSTANTS.CANCEL}
              continueButtonDisable={false}
              cancelButtonDisable={false}
              onContinueClick={() => {
                setCancelPopup(false);
                handleCancelData();
              }}
              onCancelClick={() => {
                setCancelPopup(false);
              }}
            >
              <div>{WARNING_POPUP_CONSTANTS.BODY_TEXT}</div>
              <div>{WARNING_POPUP_CONSTANTS.SUB_BODY_TEXT}</div>
            </Popup>
          </div>
        )}
      </div>
    </div>
  );
}

export default ExcelFilePage;
