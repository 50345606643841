import React, { useEffect, useState, Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import Child from '../child';
import FileDetailsPage from '../files/files_details_page';
import HistoryTestData from '../shared/history';
import { useAlert } from '@src/pages/common/alert_service/useAlert';
import ExcelFilePage from '../files/ExcelFile/ExcelFilePage';
import FileDownloadPage from '../files/file_download_page';

function FolderRouter(props) {
  const [tabsList, setTabsList] = useState([]);
  let contextMenuData;
  const { AlertContatiner, MyAlert } = useAlert();
  const { history } = props;
  let historyLocation = history.location.pathname;
  let path;
  let index = historyLocation.lastIndexOf('/');
  path = historyLocation.slice(0, index);
  let activeTab;
  const search = window.location.search;
  const id = new URLSearchParams(search).get('id');
  const parentId = new URLSearchParams(search).get('parentId');
  let count = path.split('/').length;
  if (parentId) {
    activeTab = history.location.pathname.split('/')[count];
    contextMenuData = [
      {
        option: 'Help',
      },
    ];
  } else {
    contextMenuData = [
      {
        option: 'Optimizer',
      },
      {
        option: 'Help',
      },
    ];
    activeTab = history.location.pathname.split('/')[count];
  }
  useEffect(() => {
    if (parentId) {
      if (activeTab === 'File') {
        setTabsList([
          {
            name: 'File',
            logo: '/assets/images/project_rec_logo.svg',
            selectedLogo: '/assets/images/project_rec_logo_blue.svg',
            pathUrl: `${path}/File?id=${id}&parentId=${parentId}`,
            selectedName: 'File',
          },
        ]);
      } else if (activeTab === 'ExcelFile') {
        setTabsList([
          {
            name: 'ExcelFile',
            logo: '/assets/images/project_rec_logo.svg',
            selectedLogo: '/assets/images/project_rec_logo_blue.svg',
            pathUrl: `${path}/ExcelFile?id=${id}&parentId=${parentId}`,
            selectedName: 'ExcelFile',
          },
        ]);
      }
    } else {
      setTabsList([
        {
          name: 'Folder',
          logo: '/assets/images/project_rec_logo.svg',
          selectedLogo: '/assets/images/project_rec_logo_blue.svg',
          pathUrl: `${path}/Folder?id=${id}`,
          selectedName: 'Folder',
        },
        // {
        //   name: "History",
        //   logo: "/assets/images/user_logo.svg",
        //   selectedLogo: "/assets/images/history_logo_blue.svg",
        //   pathUrl: `${path}/history?id=${id}`,
        //   selectedName: "history",
        // },
      ]);
    }
  }, [parentId, activeTab]);

  const renderTabs = () => {
    return (
      <ul className="navBar text-blue-600 flex flex-row primary-tab">
        {tabsList.map((tab, index) => {
          return (
            <li>
              <a
                href=""
                key={`dashboard-tab-${index}`}
                onClick={(event) => {
                  event.preventDefault();
                }}
                className={`${activeTab === tab.selectedName ? 'selected_tab_style' : ''}`}
              >
                {tab.name}
              </a>
            </li>
          );
        })}
      </ul>
    );
  };
  return (
    <>
      <div className="alert-position-style">
        <AlertContatiner></AlertContatiner>
      </div>
      {parentId ? (
        <Switch>
          <Route path={`${path}/File`} exact={true} strict={true} key="module-page">
            <FileDetailsPage MyAlert={MyAlert} />
          </Route>
          <Route path={`${path}/LargeFile`} exact={true} strict={true} key="module-page">
            <FileDownloadPage MyAlert={MyAlert} />
          </Route>
          <Route path={`${path}/ExcelFile`} exact={true} strict={true} key="module-page">
            <ExcelFilePage history={history} MyAlert={MyAlert} path={path} />
          </Route>
          <Route
            path={`${path}/history`}
            exact={true}
            strict={true}
            key="results-page"
            component={HistoryTestData}
          ></Route>
        </Switch>
      ) : (
        <Switch>
          <Route path={`${path}/Folder`} exact={true} strict={true} key="module-page" component={Child}></Route>
          <Route
            path={`${path}/history`}
            exact={true}
            strict={true}
            key="results-page"
            component={HistoryTestData}
          ></Route>
        </Switch>
      )}
    </>
  );
}

export default withRouter(FolderRouter);
