import React, { useState, useEffect, useRef } from 'react';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import Modal from 'react-modal';
import MachineDetails from './run-settings-machines-common/machine-details';
import { AiOutlineDelete } from 'react-icons/ai';
import {
  getAllMachineListReq,
  getBrowserStackList,
  getSauceLabsList,
  getAllLambdaTestsList,
  getUserAllAvailableMachineReq,
} from '@api/api_services';
import { getBrowserLabel, getOsLabel, getDeviceLabel } from './run-settings-machines-common/common-methods';
import AutomaticDistribution from './multiple-machine-child-pages/automatic-distribution';
import ManualDistribution from './multiple-machine-child-pages/manual-distribution';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { enableDisableHeadlessSwitch } from '../machine-landing-page';
import MachineTable from '@src/pages/common/MachineTable/MachineTable';
import MachineEnvironmentLayout from '@src/common/MachineEnvironmentLayout';
import MachineEnvironmentTableLayout from '@src/common/MachineEnvironmentTableLayout';
import AlertPopUps from '@src/pages/common/alert_pop_ups/AlertPopUps';
import {
  ALERT_MODAL_CONSTANTS,
  AZURE_DEVOPS_CONSTANTS,
  DEFECT_CONSTANTS,
  EXECUTION_CONSTANTS,
  LABELS_CONSTANTS,
  SUITES_CONSTANTS,
  FIRECLOUD_DATA,
} from '@src/common/ui-constants';
import { fetchMachineData, getScriptTypes, isEmptyObject, isEmptyValue, maxNumberOfRunsAlert } from '@src/util/common_utils';
import InvalidMachineModal from '@src/pages/execution/common/invalid-machine-modal';
import { Button } from 'fireflink-ui';
import { UI_VALIDATIONS } from '@src/util/validations';
let tempUpdatedSelectedMachine;
const MultipleMachineLandingPage = (props) => {
  const { AlertContatiner, MyAlert } = useAlert();
  const { WARNING } = ALERT_MODAL_CONSTANTS;
  const project = JSON.parse(localStorage.getItem('selected-project'));
  let multipleDataType = [
    'Run Full suite in parallel on all machines (Compatibility)',
    'Distribute Test Scripts of one suite on different machines (For Quick Execution)',
  ];
  const [selectedCheckData, setSelectedCheckData] = useState(
    ['MANUAL', 'AUTOMATIC'].includes(props?.distributionMechanism) ? multipleDataType[1] : multipleDataType[0]
  );
  const [selectedTableData, setSelectedTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [operation, setOperation] = useState('');
  const [allClientSystems, setAllClientSystems] = useState([]);
  const [instanceList, setInstanceList] = useState({});
  const [defaultSystem, setDefaultSystem] = useState([]);
  const [selectedSystem, setSelectedSystem] = useState({});
  const [selectedJsonData, setSelectedJsonData] = useState([]);
  const [selectedObject, setSelectedObject] = useState();
  const [open, setOpen] = useState(false);
  const [defaultSystemObj, setDefaultSystemObj] = useState([]);
  let multipleMachineData = [SUITES_CONSTANTS.AUTOMATICALLY_DISTRIBUTE, SUITES_CONSTANTS.MANUALLY_DISTRIBUTE];
  const [selectedMultipleMachineData, setselectedMultipleMachineData] = useState(
    props?.distributionMechanism === 'MANUAL' ? multipleMachineData[1] : multipleMachineData[0]
  );
  const [disableSelectMachineButton, setDisableSelectMachineButton] = useState(true);
  const [noCallGetUpdated, setNoCallGetUpdated] = useState(false);
  const [disableHeadlessSwitch, setdisableHeadlessSwitch] = useState(true);
  const disableHeadlessSwitchRef = useRef();
  disableHeadlessSwitchRef.current = disableHeadlessSwitch;
  let selectedProject = JSON.parse(localStorage.getItem('selected-project'));
  const multipleMachineDetailsRef = useRef();
  const [machineInstanceErrors, setMachineInstanceErrors] = useState({});
  const LoggedInUserName = JSON.parse(localStorage.getItem('test-optimize-user')).name;
  let scriptTypes = getScriptTypes(props?.moduleArray);
  const user = JSON.parse(localStorage.getItem('test-optimize-user'));
  const [downloadClientModal, setDownloadClientModal] = useState(false);
  const [isData, setIsData] = useState('');
  useEffect(() => {
    setSelectedCheckData(
      ['MANUAL', 'AUTOMATIC'].includes(props?.distributionMechanism) ? multipleDataType[1] : multipleDataType[0]
    );
  }, [props?.distributionMechanism]);

  const closeModal = (val) => {
    if (downloadClientModal) {
      setDownloadClientModal(val);
    }
  };

  const columnHeader = [
    {
      Header: 'Execution Environment',
      accessor: 'executionEnvironment',
      width: '160',
      disableFilters: true,
      Cell: ({ value, row, ...props }) => {
        return (
          <div className="table-non-link-color-common float-left table-font-style-common w-180px truncate">
            {value}
            {`${row?.original?.headless === true ? '(headless)' : ''}`}
          </div>
        );
      },
    },
    {
      Header: 'Os & Os Version',
      accessor: 'selectedOs',
      width: '140',
      disableFilters: true,
      Cell: ({ value, row }) => {
        return (
          <div className=" table-non-link-color-common float-left table-font-style-common w-140px truncate version-text-color">
            {value ? value : ''}
            {`${row?.original?.machine?.osVersion}`}
          </div>
        );
      },
    },
    {
      Header: 'Browser',
      accessor: 'selectedBrowser',
      width: '80',
      disableFilters: true,
      Cell: ({ value }) => {
        return <span className="w-80px version-text-color float-left inline truncate">{value ? value : ''}</span>;
      },
    },
    {
      Header: 'Browser Version',
      accessor: 'systemConfigDetails[0].version',
      width: '140',
      disableFilters: true,
      Cell: ({ value, row }) => {
        return (
          <div className="float-left table-non-link-color-common inline version-text-color truncate">
            {row.original.systemConfigDetails?.[0].version ? value : row.original.browserVersion}
          </div>
        );
      },
    },
    {
      Header: 'Devices',
      accessor: 'deviceNames',
      width: '100',
      disableFilters: true,
      Cell: ({ value }) => {
        return (
          <div className="w-100px table-non-link-color-common float-left table-font-style-common truncate">{value}</div>
        );
      },
    },
    {
      Header: 'No. of Runs',
      accessor: 'numberOfRuns',
      disableFilters: true,
      width: '100',
      className: 'text-center',
      Cell: ({ value }) => {
        return (
          <div className="table-display-common table-non-link-color-common  w-100px table-font-style-common">
            {value}
          </div>
        );
      },
    },
    {
      Header: 'Action',
      accessor: 'Action',
      width: '80',
      className: 'text-center',
    },
  ];
  const isFireCloudEnvironment = (systemObj) =>
    systemObj?.executionEnvironment?.toLowerCase() === FIRECLOUD_DATA.FIRE_CLOUD;
  const createSystemConfigObject = (instance) => {
    return {
      name: instance?.browserName,
      platform: instance?.browserName,
      version: instance?.browserVersion,
      subType: 'browser',
    };
  };
  const columns = React.useMemo(() => {
    if (project?.type?.toLowerCase() === 'web') {
      let x = columnHeader.slice(0, 4).concat(columnHeader.slice(5, 8));
      return x;
    } else if (project?.type?.toLowerCase() === 'mobile') {
      let x = columnHeader.slice(0, 1).concat(columnHeader.slice(4, 5)).concat(columnHeader.slice(5, 8));
      return x;
    } else if (project?.type?.toLowerCase() === 'web & mobile' || project?.type?.toLowerCase() === 'web services') {
      return columnHeader;
    } else {
      return columnHeader;
    }
  });

  async function getUserAllAvailableMachine() {
    const runSetApiData = await fetchMachineData(user, getUserAllAvailableMachineReq, getAllMachineListReq);
    if (runSetApiData) {
      setAllClientSystems(runSetApiData);
      let instanceList = {};
      if (runSetApiData) {
        const browserStackResponse = await getBrowserStackList();
        if (browserStackResponse?.data?.responseCode === 200) {
          instanceList['browserInstanceArray'] = browserStackResponse?.data?.responseObject;
          setInstanceList({ ...instanceList });
        }
        const saucelabResponse = await getSauceLabsList();
        if (saucelabResponse?.data?.responseCode === 200) {
          instanceList['sauceLabInstanceArray'] = saucelabResponse?.data?.responseObject;
          setInstanceList({ ...instanceList });
        }
        const lambdaTestResponse = await getAllLambdaTestsList();
        if (lambdaTestResponse?.data?.responseCode === 200) {
          instanceList['lambdaTestsInstanceArray'] = lambdaTestResponse?.data?.responseObject;
          setInstanceList({ ...instanceList });
        }
        setInstanceList({ ...instanceList });
        getOldData(runSetApiData);
      }
    }
  }

  function getOldData(runSetApiData) {
    if (props?.multipleJsonData?.length) {
      let multipleJsonData = props?.multipleJsonData;
      let tempDefaultSystem = [];
      setSelectedJsonData([...multipleJsonData]);
      if (runSetApiData?.length) {
        multipleJsonData?.forEach((obj) => {
          let ind = runSetApiData?.findIndex((cliObj) => cliObj?.clientId === obj.clientId);
          if (ind > -1) {
            const instancesCopy = [];
            obj?.machineInstances?.forEach((instance, instanceIndex) => {
              let tempDefaultSystemObj = JSON.parse(JSON.stringify(runSetApiData[ind]));
              tempDefaultSystemObj.executionEnvironment = instance?.executionEnv;
              tempDefaultSystemObj.machine = instance?.machineInfo;
              tempDefaultSystemObj['selectedOs'] = getOsLabel(instance?.machineInfo.osName);
              tempDefaultSystemObj['rowId'] = instance?.clientId + instanceIndex;
              if (project.type.toLowerCase() === 'web') {
                tempDefaultSystemObj.systemConfigDetails = getSingleBrowserList(
                  tempDefaultSystemObj?.systemConfigDetails
                );
                if (tempDefaultSystemObj.systemConfigDetails?.length) {
                  tempDefaultSystemObj.systemConfigDetails[0].name = instance?.browserName;
                  tempDefaultSystemObj.systemConfigDetails[0].platform = instance?.browserName;
                  tempDefaultSystemObj.systemConfigDetails[0].version = instance?.browserVersion;
                } else {
                  let browserObj = {
                    name: instance?.browserName,
                    platform: instance?.browserName,
                    version: instance?.browserVersion,
                    subType: 'browser',
                  };
                  let browserArray = [];
                  browserArray.push(browserObj);
                  tempDefaultSystemObj['systemConfigDetails'] = [...browserArray];
                }
                tempDefaultSystemObj['selectedBrowser'] = getBrowserLabel(instance?.browserName);
              } else if (project.type.toLowerCase() === 'mobile') {
                let deviceArray = [];
                if (instance?.deviceInfo?.length) {
                  let systemConfig = instance?.deviceInfo;
                  systemConfig?.forEach((obj) => {
                    if (obj?.subType === 'device') {
                      deviceArray?.push(obj);
                    }
                  });
                }
                tempDefaultSystemObj['deviceNames'] = getDeviceLabel(deviceArray);
                tempDefaultSystemObj.systemConfigDetails = deviceArray;
              } else if (['web & mobile', 'salesforce', 'ms dynamics'].includes(project.type.toLowerCase())) {
                let deviceArray = [];
                let browserObjArray = getSingleBrowserList(tempDefaultSystemObj?.systemConfigDetails);
                if (browserObjArray?.length) {
                  browserObjArray[0].name = instance?.browserName;
                  browserObjArray[0].platform = instance?.browserName;
                  browserObjArray[0].version = instance?.browserVersion;
                }
                else if (isFireCloudEnvironment(tempDefaultSystemObj)) {
                  browserObjArray = [];
                  const systemConfigObject = createSystemConfigObject(instance);
                  browserObjArray.push(systemConfigObject);
                }
                tempDefaultSystemObj['selectedBrowser'] = getBrowserLabel(instance?.browserName);
                if (instance?.deviceInfo?.length) {
                  deviceArray = instance?.deviceInfo.filter((device) => device.subType === 'device');
                }
                tempDefaultSystemObj['deviceNames'] = getDeviceLabel(deviceArray);
                tempDefaultSystemObj.systemConfigDetails = browserObjArray?.concat(deviceArray);
              }
              tempDefaultSystemObj['Action'] = [];
              tempDefaultSystemObj['Action'].push(
                <span className="project-action flex flex-row">
                  <span className={props?.rerunMode ? 'disable_icon  flex flex-row' : 'flex flex-row'}>
                    <AiOutlineDelete
                      className=" cursor-hand icon-blue-style mt-2"
                      size={20}
                      onClick={() => {
                        setOpen(true);
                        setSelectedObject(tempDefaultSystemObj);
                      }}
                    />
                  </span>
                </span>
              );
              tempDefaultSystemObj['Actions'] = [];
              tempDefaultSystemObj['Actions'].push(
                <span className="project-action flex flex-row">
                  <span className={props?.rerunMode ? 'disable_icon  flex flex-row' : 'flex flex-row'}>
                    <AiOutlineDelete className=" cursor-hand icon-blue-style mt-2" size={20} />
                  </span>
                </span>
              );
              tempDefaultSystemObj['headless'] = instance?.headless;
              tempDefaultSystemObj['browserVersion'] = instance?.browserVersion;
              tempDefaultSystemObj['numberOfRuns'] = instance?.numberOfRuns;
              instancesCopy.push({ ...tempDefaultSystemObj });
            });
            tempDefaultSystem.push([...instancesCopy]);
          }
        });
        setDefaultSystem([...tempDefaultSystem]);
        setSelectedTableData([...tempDefaultSystem]);
      }
    } else {
      const tempSystem = runSetApiData.find((machineTemp) => !machineTemp.disabled);
      if (tempSystem) {
        setSelectedSystem({ ...tempSystem });
        setDefaultSystemObj([{ ...tempSystem, numberOfRuns: 1, systemConfigDetails: [] }]);
      }
      setDefaultSystem([]);
    }
    setDisableSelectMachineButton(false);
  }

  useEffect(() => {
    getUserAllAvailableMachine();
  }, []);

  useEffect(() => {
    let tempArray = [...defaultSystem];
    if (tempArray?.length) {
      let ind = tempArray?.findIndex((defObj) => defObj?.[0]?.clientId === tempUpdatedSelectedMachine?.[0]?.clientId);
      if (ind > -1) {
        setDefaultSystemObj([...tempArray[ind]]);
        tempUpdatedSelectedMachine = [...tempArray[ind]];
      }
      if (ind === -1) {
        setDefaultSystemObj([{ ...selectedSystem, numberOfRuns: 1, systemConfigDetails: [] }]);
        tempUpdatedSelectedMachine = [{ ...selectedSystem, numberOfRuns: 1, systemConfigDetails: [] }];
      }
    }
  }, [selectedSystem]);

  function openModalfun(btn) {
    let tempArray = [...defaultSystem];
    let tempClientArray = allClientSystems?.length ? [...allClientSystems] : [];
    const _flag = enableDisableHeadlessSwitch(props.moduleArray, selectedProject);
    setdisableHeadlessSwitch(_flag);
    if (btn === 'Create') {
      if (tempArray?.length) {
        let defaultSelected = false;
        tempArray?.forEach((defObj) => {
          let ind = tempClientArray?.findIndex((clientObj) => clientObj?.clientId === defObj?.[0]?.clientId);
          if (ind > -1 && !defaultSelected) {
            defaultSelected = true;
            setSelectedSystem({ ...tempClientArray[ind] });
            setDefaultSystemObj([...defObj]);
            tempUpdatedSelectedMachine = [...defObj];
          }
        });
      } else {
        const tempSystem = tempClientArray.find((machineTemp) => !machineTemp.disabled);
        if (tempSystem) {
          setSelectedSystem({ ...tempSystem });
          setDefaultSystemObj([{ ...tempSystem, numberOfRuns: 1, systemConfigDetails: [] }]);
        }
      }
      // history.push(`/execution/suite/create-suite?hideParentLevelParallelRun=${false}`)
      setShowModal(true);
      setOperation('Create');
    }
  }

  const updateMachineInstanceErrors = (clientId, action) => {
    const machineInstanceErrorsCopy = { ...machineInstanceErrors };
    action === 'add' ? (machineInstanceErrorsCopy[clientId] = clientId) : delete machineInstanceErrorsCopy?.[clientId];
    setMachineInstanceErrors(machineInstanceErrorsCopy);
    return machineInstanceErrorsCopy;
  };
  const handleSelectedSystem = async (system) => {
    if (system?.clientId === selectedSystem?.clientId) {
      return;
    }
    const selectedSystemCopy = { ...selectedSystem };
    let tempArray = [...defaultSystem];
    if (tempArray?.length) {
      let ind = tempArray?.findIndex((defObj) => defObj?.[0]?.clientId === system?.clientId);
      if (ind > -1) {
        setDefaultSystemObj([...tempArray[ind]]);
        tempUpdatedSelectedMachine = [...tempArray[ind]];
      } else {
        setDefaultSystemObj([{ ...system, numberOfRuns: 1, systemConfigDetails: [] }]);
        tempUpdatedSelectedMachine = [{ ...system, numberOfRuns: 1, systemConfigDetails: [] }];
      }
    } else {
      setDefaultSystemObj([{ ...system, numberOfRuns: 1, systemConfigDetails: [] }]);
      tempUpdatedSelectedMachine = [{ ...system, numberOfRuns: 1, systemConfigDetails: [] }];
    }
    setSelectedSystem(system);
    const isValid = await multipleMachineDetailsRef.current?.validateForm();
    if (defaultSystem.some((machine) => machine?.[0].clientId === selectedSystemCopy?.clientId)) {
      if (!isValid?.machineInstances?.length) {
        updateMachineInstanceErrors(selectedSystemCopy?.clientId, 'delete');
      } else {
        updateMachineInstanceErrors(selectedSystemCopy?.clientId, 'add');
      }
    } else {
      updateMachineInstanceErrors(selectedSystemCopy?.clientId, 'delete');
    }
  };

  const getUpdatedSelectedMachine = (updatedSystem) => {
    setIsData(updatedSystem);
    let tempArray = [...defaultSystem];
    if (tempArray?.length) {
      let ind = tempArray?.findIndex((defObj) => defObj?.[0]?.clientId === updatedSystem?.[0]?.clientId);
      if (ind > -1) {
        tempArray[ind] = [...updatedSystem];
      }
    }
    setDefaultSystem([...tempArray]);
    setDefaultSystemObj([...updatedSystem]);
    tempUpdatedSelectedMachine = [...updatedSystem];
  };

  const handleChangeDefaultMachine = (machine) => {
    let updatedSystem;
    if (tempUpdatedSelectedMachine?.[0]?.clientId === machine?.clientId) {
      updatedSystem = [...tempUpdatedSelectedMachine];
    } else {
      updatedSystem = [{ ...machine, numberOfRuns: 1, systemConfigDetails: [] }];
    }
    updatedSystem.forEach((instance) => {
      if (project.type.toLowerCase() === 'web') {
        instance.systemConfigDetails = getSingleBrowserList(instance?.systemConfigDetails);
      } else if (project.type.toLowerCase() === 'mobile') {
        instance.systemConfigDetails = getSingleBrowserListForMobile(instance?.systemConfigDetails);
        if (tempUpdatedSelectedMachine?.systemConfigDetails?.length) {
          let configData = tempUpdatedSelectedMachine?.systemConfigDetails;
          let deviceArray = [];
          configData?.forEach((obj) => {
            if (obj?.subType === 'device') {
              deviceArray?.push(obj);
            }
          });
          instance.systemConfigDetails = deviceArray;
        }
      } else if (['web & mobile', 'salesforce', 'ms dynamics'].includes(project.type.toLowerCase())) {
        instance.systemConfigDetails = getSingleBrowserList(instance?.systemConfigDetails);
        if (tempUpdatedSelectedMachine?.systemConfigDetails?.length) {
          let configData = tempUpdatedSelectedMachine?.systemConfigDetails;
          let deviceArray = [];
          configData?.forEach((obj) => {
            if (obj?.subType === 'device') {
              deviceArray?.push(obj);
            }
          });
          instance.systemConfigDetails = instance?.systemConfigDetails?.concat(deviceArray);
        }
      }
    });
    let tempArray = defaultSystem;
    tempArray?.push([...updatedSystem]);
    setDefaultSystem([...tempArray]);
  };

  const checkMachineInstanceErrors = () => {
    const updatedMachineInstanceErrors = updateMachineInstanceErrors(selectedSystem?.clientId, 'delete');
    if (!Object.keys(updatedMachineInstanceErrors)?.length) {
      getSelectedDeviceData();
    }
  };
  let checkDefaultSystemContainSelectedSystem = defaultSystem.some(
    (system) => system?.[0].clientId === selectedSystem.clientId
  );
  const validateMachineInstanceData = async () => {
    if (maxNumberOfRunsAlert(defaultSystem,'multipleMachine',UI_VALIDATIONS.MAX_NUMBER_OF_RUNS_SHOULD_SELECT)) {
      const isValid = await multipleMachineDetailsRef.current?.validateForm();
      if (checkDefaultSystemContainSelectedSystem) {
        if (!isEmptyValue(Object.keys(machineInstanceErrors)) || !isEmptyValue(isValid?.machineInstances)) {
          if (isEmptyValue(isValid?.machineInstances)) {
            checkMachineInstanceErrors();
          } else {
            updateMachineInstanceErrors(selectedSystem?.clientId, 'add');
          }
        } else {
          getSelectedDeviceData();
        }
      } else if (isEmptyValue(Object.keys(machineInstanceErrors))) {
        getSelectedDeviceData();
      } else if (!isEmptyValue(Object.keys(machineInstanceErrors))) {
        checkMachineInstanceErrors();
      }
    } else {
      MyAlert?.info(`${EXECUTION_CONSTANTS.MAXIMUM_RUNS} ${UI_VALIDATIONS.MAX_NUMBER_OF_RUNS}`);
    }
  };

  function getSelectedDeviceData(updatedDefaultSystems) {
    let tempDefaultSystemsCopy = updatedDefaultSystems || [...defaultSystem];
    if (tempDefaultSystemsCopy?.length) {
      let jsonArray = [];
      tempDefaultSystemsCopy?.forEach((defaultSystemIteCopy) => {
        const machineInstances = [];
        defaultSystemIteCopy?.forEach((instanceCopy, instanceIndex) => {
          let deviceArray = [];
          let browserObj = {};
          if (instanceCopy?.systemConfigDetails?.length) {
            let systemConfig = instanceCopy?.systemConfigDetails;
            systemConfig?.forEach((obj) => {
              if (obj.name && obj.subType === 'device') {
                deviceArray?.push(obj);
              } else if (obj.name) {
                browserObj = { ...obj };
              }
            });
          }
          instanceCopy['rowId'] = instanceCopy?.clientId + instanceIndex;
          instanceCopy['Action'] = [];
          instanceCopy['Action'].push(
            <span className="project-action flex flex-row">
              <span className={props?.rerunMode ? 'disable_icon  flex flex-row' : 'flex flex-row'}>
                <AiOutlineDelete
                  className=" cursor-hand icon-blue-style mt-2"
                  size={20}
                  onClick={() => {
                    setOpen(true);
                    setSelectedObject(instanceCopy);
                  }}
                />
              </span>
            </span>
          );
          instanceCopy['Actions'] = [];
          instanceCopy['Actions'].push(
            <span className="project-action flex flex-row">
              <span className={props?.rerunMode ? 'disable_icon  flex flex-row' : 'flex flex-row'}>
                <AiOutlineDelete className=" cursor-hand icon-blue-style mt-2" size={20} />
              </span>
            </span>
          );
          instanceCopy['selectedBrowser'] = getBrowserLabel(browserObj?.name);
          instanceCopy['selectedOs'] = getOsLabel(instanceCopy?.machine?.osName);

          let selecteSystemObj = {
            clientId: instanceCopy?.clientId,
            numberOfRuns: instanceCopy?.numberOfRuns,
            executionEnv: instanceCopy?.executionEnvironment,
            browserName: browserObj?.name || null,
            browserVersion: browserObj?.version || null,
            systemUrl: '',
            machineInfo: {
              osName: instanceCopy?.machine?.osName,
              osVersion: instanceCopy?.machine?.osVersion,
              hostName: instanceCopy?.machine?.hostName,
            },
            deviceInfo: deviceArray || null,
            headless: instanceCopy?.headless || false,
          };
          instanceCopy['browserVersion'] = selecteSystemObj.browserVersion;
          if (project.type.toLowerCase() === 'web') {
            selecteSystemObj.deviceInfo = [];
          } else if (project.type.toLowerCase() === 'mobile') {
            selecteSystemObj.browserName = '';
            selecteSystemObj.browserVersion = '';
            instanceCopy['deviceNames'] = getDeviceLabel(deviceArray);
          } else if (['web & mobile', 'salesforce', 'ms dynamics'].includes(project.type.toLowerCase())) {
            instanceCopy['deviceNames'] = getDeviceLabel(deviceArray);
          }
          machineInstances.push({ ...selecteSystemObj });
        });
        jsonArray?.push({ clientId: machineInstances?.[0]?.clientId, machineInstances: [...machineInstances] });
      });
      setSelectedJsonData([...jsonArray]);
      setSelectedTableData([...tempDefaultSystemsCopy]);
      props?.storeMultipleMachineData([...jsonArray]);
      setDefaultSystem([...tempDefaultSystemsCopy]);
      setDefaultSystemObj([]);
      tempUpdatedSelectedMachine = {};
      setShowModal(false);
      setNoCallGetUpdated(false);
    }
  }

  function getSingleBrowserList(systemConfig) {
    let tempArray = [];
    if (systemConfig?.length) {
      systemConfig?.forEach((obj) => {
        if (obj.subType === 'browser') {
          if (!tempArray?.length) {
            tempArray?.push(obj);
          }
        }
      });
      return tempArray;
    } else {
      return systemConfig;
    }
  }

  function getSingleBrowserListForMobile(systemConfig) {
    if (systemConfig?.length) {
      let tempArray = [];
      return tempArray;
    }
  }

  let handelDlt = (deletObj) => {
    let defaultSystemsCopy = JSON.parse(JSON.stringify(defaultSystem));
    const selectedMachineIndexForDelete = selectedJsonData.findIndex((obj) => obj?.clientId === deletObj.clientId);
    if (selectedMachineIndexForDelete > -1) {
      const selectedInstanceIndexForDelete = selectedTableData[selectedMachineIndexForDelete]?.findIndex(
        (obj) => obj?.rowId === deletObj.rowId
      );
      if (selectedInstanceIndexForDelete > -1) {
        defaultSystemsCopy?.[selectedMachineIndexForDelete]?.splice(selectedInstanceIndexForDelete, 1);
        if (defaultSystemsCopy?.[selectedMachineIndexForDelete].length === 0) {
          defaultSystemsCopy?.splice(selectedMachineIndexForDelete, 1);
        }
        if (defaultSystemsCopy?.length) {
          getSelectedDeviceData([...defaultSystemsCopy]);
        } else {
          setDefaultSystem([]);
          setSelectedTableData([]);
          setSelectedJsonData([]);
          props?.storeMultipleMachineData([], []);
        }
        setOpen(false);
      }
    }
  };

  function cancelFunction() {
    getOldData(allClientSystems);
    setMachineInstanceErrors({});
    setNoCallGetUpdated(false);
    setShowModal(false);
    setOperation('Create');
  }

  function changeDistributionMechanism(selectedValue) {
    if (selectedValue === multipleMachineData[0]) {
      props.setDistributionMechanism('AUTOMATIC');
      props?.changeRadioFunction('AUTOMATIC');
    } else {
      props.setDistributionMechanism('MANUAL');
      props?.changeRadioFunction('MANUAL');
    }
  }

  function showAlertMsg() {
    MyAlert.warning(
      `This license is limited to ${props?.numberOfParallelRuns} parallel runs, so you cannot select more than ${props?.numberOfParallelRuns}  machines.`
    );
  }

  const handleClose = () => {
    setDownloadClientModal(true);
  };
  function checkBoxOnChangeHandler(e, selSystem) {
    let tempArray = defaultSystem?.length ? [...defaultSystem] : [];
    if (e?.target?.checked) {
      if (props?.numberOfParallelRuns > tempArray.length) {
        let tempObj = {};
        Object.assign(tempObj, selSystem);
        tempArray?.push(tempObj);
        handleChangeDefaultMachine({ ...tempObj });
      } else {
        e.target.checked = false;
        showAlertMsg();
      }
    } else {
      if (tempArray?.length) {
        let selIndex = tempArray?.findIndex((obj) => obj?.[0]?.clientId === selSystem?.clientId);
        if (selIndex > -1) {
          tempArray?.splice(selIndex, 1);
        }
        setDefaultSystem([...tempArray]);
      }
    }
  }

  return (
    <>
      {open && (
        <AlertPopUps
          dialogClass={'info-child-modal-z-index'}
          execution={true}
          open={open}
          alertType={WARNING}
          saveBtnText={'Cancel'}
          cancelBtnText={'Delete'}
          content={[
            <div className="mb-2 capitalize">Hi {LoggedInUserName},</div>,
            <p className="">
              {' '}
              Your data will be lost. Are you sure you want to delete {selectedObject?.machine?.hostName}?{' '}
            </p>,
          ]}
          alertTitle={'Delete'}
          onSaveBtnClick={() => {
            setOpen(false);
          }}
          onCancelBtnClick={() => {
            handelDlt(selectedObject);
          }}
        />
      )}

      <Modal
        isOpen={showModal}
        className={
          operation === 'Create'
            ? 'react-modal-main-style modal-overlay popup-height-runsettings'
            : operation === 'maximize'
            ? 'maximize-react-modal-style modal-overlay popup-height-runsettings'
            : null
        }
        onRequestClose={() => {}}
      >
        <div className="testEnv-header-section">
          <span className="ml-3 fontPoppinsSemiboldSm py-0.5"> Test Environments</span>
          <button
            onClick={() => {
              setShowModal(false);
              cancelFunction();
            }}
            type="button"
            className="float-right mr-4 text-white hover:ring-2 ring-white focus:ring-2 rounded-full"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        {operation === 'Create' ? (
          <>
            <div className="alert-variable-modal-position-style">
              <AlertContatiner />
            </div>

            {!isEmptyValue(allClientSystems) ? (
              <div
                className="table_height  border-b  border-t mt-3 mb-4 border-blue-100 qrs_modal_body"
                id="journal-scroll"
              >
                <div className="m-2 qrs-system-grid-container grid grid-cols-10 gap-2.5 px-4 py-1">
                  <div className="qrs-machine-table col-span-4">
                    <MachineTable
                      inputType={'checkbox'}
                      systems={allClientSystems}
                      currentVisibleSystem={selectedSystem}
                      selectedSystems={[...defaultSystem.map((sys) => sys[0])]}
                      onRowClickHandler={handleSelectedSystem}
                      classes={''}
                      checkBoxOnChangeHandler={checkBoxOnChangeHandler}
                      errors={Object.keys(machineInstanceErrors)}
                      selectedExecutionType={props?.distributionMechanism}
                    />
                  </div>
                  <div className="qrs-details-div col-span-6 overflow-y-auto">
                    <MachineDetails
                      executionType="parallel"
                      ref={multipleMachineDetailsRef}
                      selectedSystem={selectedSystem}
                      instanceList={instanceList}
                      defaultSystem={defaultSystemObj}
                      project={project}
                      getUpdatedSelectedMachine={getUpdatedSelectedMachine}
                      noCallGetUpdated={noCallGetUpdated}
                      setNoCallGetUpdated={setNoCallGetUpdated}
                      disableHeadless={disableHeadlessSwitchRef.current}
                      scriptTypes={scriptTypes}
                      type={props?.type}
                      MyAlert={MyAlert}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <InvalidMachineModal
                handleClose={handleClose}
                downloadClientModal={downloadClientModal}
                closeModal={closeModal}
              />
            )}

            {!isEmptyValue(allClientSystems) && (
              <div className="float-right mr-4 pb-3 flex flex-row">
                <Button
                  className="mr-3"
                  label={LABELS_CONSTANTS.CANCEL}
                  variant="secondary"
                  onClick={() => {
                    cancelFunction();
                  }}
                />
                <Button
                  type="submit"
                  label={DEFECT_CONSTANTS.SELECT}
                  variant="primary"
                  id="selectBtn"
                  disabled={defaultSystem.length === 0}
                  onClick={() => {
                    const hasUndefined = isData.some((item) => item.machine.osName === undefined);
                    if (!hasUndefined) {
                      validateMachineInstanceData();
                    } else {
                      updateMachineInstanceErrors(selectedSystem?.clientId, 'add');
                    }
                  }}
                />
              </div>
            )}
          </>
        ) : null}
      </Modal>
      <div>
        {selectedCheckData === multipleDataType[0] && (
          <MachineEnvironmentLayout
            onHeaderBtnClick={() => {
              openModalfun('Create');
            }}
            headerBtnProps={{ disabled: disableSelectMachineButton }}
          >
            {selectedTableData?.length &&
              selectedTableData.map((selTabDat) => (
                <MachineEnvironmentTableLayout
                  headerLabel={selTabDat?.[0]?.machine?.hostName}
                  accessDetails={selTabDat?.[0]?.access}
                  columns={columns}
                  data={[...selTabDat]}
                />
              ))}
          </MachineEnvironmentLayout>
        )}
        {selectedCheckData === multipleDataType[1] && (
          <div className="mt-4">
            <label className="left-border-style radio-button-label-color fontPoppinsSemiboldSm">
              Distribution Mechanism
            </label>
            <div className="left-border-style flex">
              {multipleMachineData?.map((multiradiotxt, multiOptindex) => {
                return (
                  <>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue="top"
                      style={{ display: '-webkit-inline-flex' }}
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            disabled={props?.rerunMode ? props?.rerunMode : false}
                            {...{ checked: selectedMultipleMachineData === multiradiotxt }}
                            id={multiradiotxt + multiOptindex}
                            label={multiradiotxt}
                            className=" m-2 cursor-pointer"
                            value={multiradiotxt}
                            onChange={(e) => {
                              setselectedMultipleMachineData(e.target.value);
                              changeDistributionMechanism(e.target.value);
                            }}
                          />
                        }
                        label={<Typography className="radio-btn-style">{multiradiotxt}</Typography>}
                      />
                    </RadioGroup>
                    <br></br>
                  </>
                );
              })}
            </div>
            <div className="mt-4  mb-5">
              {selectedMultipleMachineData === multipleMachineData[0] ? (
                <div className="module-area-style">
                  <AutomaticDistribution
                    userDetails={user}
                    moduleArray={props?.moduleArray}
                    rootData={props?.rootData}
                    automaticDistributionAllData={props?.automaticDistributionAllData}
                    automaticJsonData={props?.automaticJsonData}
                    numberOfParallelRuns={props?.numberOfParallelRuns}
                    createAlertFun={props.createAlertFun}
                    operationForRunSettings={props?.operationForRunSettings}
                    disableHeadless={props.hasOwnProperty('disableHeadless') ? props?.disableHeadless : false}
                    rerunMode={props?.rerunMode}
                    scriptTypes={scriptTypes}
                    selectedExecutionType={props?.distributionMechanism}
                  />
                </div>
              ) : (
                selectedMultipleMachineData === multipleMachineData[1] && (
                  <div className="module-area-style">
                    <ManualDistribution
                      moduleArray={props?.moduleArray}
                      rootData={props?.rootData}
                      manualDistributionAllData={props?.manualDistributionAllData}
                      manualJsonData={props?.manualJsonData}
                      manualDistribution={props?.manualDistribution}
                      numberOfParallelRuns={props?.numberOfParallelRuns}
                      createAlertFun={props.createAlertFun}
                      disableHeadless={props.hasOwnProperty('disableHeadless') ? props.disableHeadless : false}
                      rerunMode={props?.rerunMode}
                      scriptTypes={scriptTypes}
                      selectedExecutionType={props?.distributionMechanism}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MultipleMachineLandingPage;
