import { getArchiveFlag, isEmptyValue } from '@src/util/common_utils.js';
import httpConnection from './http_connection.js';
const managementBaseUrl = process.env.REACT_APP_MANAGEMENT_BASE_URL;
const projectBaseUrl = process.env.REACT_APP_PROJECT_BASE_URL;
const treeUrlBase = process.env.REACT_APP_ALL_TREES_BASE_URL;
const executionBaseUrl = process.env.REACT_APP_EXECUTION_BASE_URL;
const resultBaseUrl = process.env.REACT_APP_RESULT_BASE_URL;
const webserviceBaseUrl = process.env.REACT_APP_WEBSERVICE_BASE_URL;
const exportBaseUrl = process.env.REACT_APP_EXPORT_BASE_URL;
const analyticsBaseUrl = process.env.REACT_APP_ANALYTICS_BASE_URL;
const licensemanagementBaseUrl = process.env.REACT_APP_LICENSE_MANAGEMENT_BASE_URL;
const clientSystemConfigBaseUrl = process.env.REACT_APP_CLIENT_SYSTEM_CONFIG_BASE_URL;
const chatbotBaseUrl = process.env.REACT_APP_CHATBOT_BASE_URL;
const htmlResultBaseUrl = process.env.REACT_APP_HTML_REPORTS_BASE_URL;
const nlpDetailsBaseUrl = process.env.REACT_APP_NLP_DETAILS_BASE_URL;
const autoTaggingBaseUrl = process.env.REACT_APP_AUTOTAGGING_BASE_URL;
const trainAutoTagModelBaseUrl = process.env.REACT_APP_TRAIN_AUTOTAGMODEL_BASE_URL;
const autotaggingDeleteStepBaseUrl = process.env.REACT_APP_DELETE_AUTOTAGGING_STEP_BASE_URL;

//Login And Registration

export const getExecutionData = (exeId) =>
  httpConnection.get(`${resultBaseUrl}/optimize/v1/executionResponse/${exeId}?archiveFlag=${getArchiveFlag()}`);

export const deleteUserReq = (userId, changeSuiteAccess = false) =>
  httpConnection.delete(`${managementBaseUrl}/optimize/v1/user/${userId}?changeSuiteAccess=${changeSuiteAccess}`);

export const refreshUserToken = (payload) =>
  httpConnection.post(`${managementBaseUrl}/optimize/v1/public/user/refresh`, payload);
export const validateUserToken = (payload) =>
  httpConnection.post(`${managementBaseUrl}/optimize/v1/public/user/validate/user-token`, payload);
export const validateAzureToken = (payload) =>
  httpConnection.post(`${managementBaseUrl}/optimize/v1/public/user/token-validate`, payload, {
    headers: { type: 'formdata' },
  });
export const signInRequest = (payload) =>
  httpConnection.post(`${managementBaseUrl}/optimize/v1/public/user/signin`, payload);
export const signUpRequest = (payload) =>
  httpConnection.post(`${managementBaseUrl}/optimize/v1/public/user/signup`, payload);
export const forgotPasswordRequest = (payload) =>
  httpConnection.post(`${managementBaseUrl}/optimize/v1/public/user/send-change-pwd-link`, payload);
export const changePasswordRequest = (payload) =>
  httpConnection.post(`${managementBaseUrl}/optimize/v1/public/user/change-password`, payload);
export const isEmailExistRequest = (payload) =>
  httpConnection.post(`${managementBaseUrl}/optimize/v1/public/user/email/exists`, payload);
export const resetActivationLink = (payload) =>
  httpConnection.post(`${managementBaseUrl}/optimize/v1/public/user/activation/resend`, payload);
export const activateEmail = (payload) =>
  httpConnection.post(`${managementBaseUrl}/optimize/v1/public/user/activation/activate`, payload);
export const changeActivePhoneNumber = (payload) =>
  httpConnection.post(`${managementBaseUrl}/optimize/v1/public/user/change-phone`, payload);
export const getPermissionsReq = () => httpConnection.get(`${managementBaseUrl}/optimize/v1/user/role`);
export const signInSiteKey = (payload) =>
  httpConnection.post(`${managementBaseUrl}/optimize/v1/public/user/site-key`, payload);
export const getPrivilegeReq = () => httpConnection.get(`${managementBaseUrl}/optimize/v1/license/privileges`);
export const signOutReq = (payload) => httpConnection.post(`${managementBaseUrl}/optimize/v1/user/signout`, payload);
export const checkServerStatus = () => httpConnection.get(`${managementBaseUrl}/optimize/actuator/health/liveness`);
//End Login And Registration

export const getProjectFile = (fileID) => httpConnection.get(`${projectBaseUrl}/optimize/v1/file/${fileID}`);
export const getProjectListDropdownReq = (queryParams) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/projects/dropdown${queryParams ? `?${queryParams}` : ''}`);

export const fileReplaceReq = (fileID, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/file/replace/${fileID}`, payload, {
    headers: { type: 'formdata' },
  });

export const updateUploadedFile = (fileID, payload, projectId) => {
  const headers = projectId ? { headers: { projectId } } : {};
  return httpConnection.put(`${projectBaseUrl}/optimize/v1/file/cloud/${fileID}`, payload, {
    headers: { type: 'formdata', ...headers.headers },
  });
};

export const downloadProjectFile = (fileID) =>
  httpConnection({
    method: 'GET',
    url: `${projectBaseUrl}/optimize/v1/file/download/cloud/${fileID}`,
    responseType: 'blob',
  });

export const getProjectListReq = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/projects/all`);

export const getProjectListByUserIdReq = (userId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/projects/user/${userId}`);

export const getSingleProjectRequest = (projectId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/projects/${projectId}`);

export const deleteProjectRequest = (projectId) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/projects/${projectId}`);

export const createProjectRequest = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/projects/`, payload, {
    headers: { type: 'formdata' },
  });

export const updateProjectRequest = (payload, projectId) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/projects/${projectId}`, payload, { headers: { type: 'formdata' } });
export const updateProjectDefectRequest = (payload, projectId) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/projects/defect/${projectId}`, payload);

export const getAllUserRequest = () => httpConnection.get(`${managementBaseUrl}/optimize/v1/user/all`);
export const getAllUserByProjectIdRequest = (projectId) =>
  httpConnection.get(`${managementBaseUrl}/optimize/v1/user/all/${projectId}`);

export const createUserRequest = (payload) => httpConnection.post(`${managementBaseUrl}/optimize/v1/user/`, payload);
export const updateUserRequest = (payload, userId) =>
  httpConnection.put(`${managementBaseUrl}/optimize/v1/user/${userId}`, payload);

export const addUserRequest = (payload, userId) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/userDetail/${userId}`, payload, { headers: { type: 'formdata' } });

export const getUsersByProjectIdReq = (projectId) =>
  httpConnection.get(`${managementBaseUrl}/optimize/v1/user/projects/${projectId}`);
// export const getUsersByProjectIdReq = () => httpConnection.get(`${managementBaseUrl}/optimize/v1/user/projects/PJT1214`);

export const deletesingleUserRequest = (userId) =>
  httpConnection.delete(`${licensemanagementBaseUrl}/optimize/v1/licenseUsers/user/${userId}`);

export const addUsersListReq = (projectId, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/userDetail/${projectId}`, payload);
export const getAllRoleRequest = () => httpConnection.get(`${managementBaseUrl}/optimize/v1/role/all`);
export const createRoleRequest = (payload) => httpConnection.post(`${managementBaseUrl}/optimize/v1/role/`, payload);
export const deleteSingleRoleRequest = (delId) =>
  httpConnection.delete(`${managementBaseUrl}/optimize/v1/role/${delId}`);
export const updateRoleRequest = (payload, roleId) =>
  httpConnection.put(`${managementBaseUrl}/optimize/v1/role/${roleId}`, payload);

export const getRolesByprojectIdReq = (projectId) =>
  httpConnection.get(`${managementBaseUrl}/optimize/v1/role/projects/${projectId}`);
export const createRoleByProjectRequest = (payload) =>
  httpConnection.post(`${managementBaseUrl}/optimize/v1/role/`, payload);
export const getSingleRoleRequest = (roleId) => httpConnection.get(`${managementBaseUrl}/optimize/v1/role/${roleId}`);

//template
export const createTemplateRequest = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/scriptTemplate/`, payload);
export const getAllTestCaseTemplate = (action) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/scriptTemplate/${action ? '?action=' + action : ''}`);
export const updateTemplateRequest = (projectId, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/scriptTemplate/${projectId}`, payload);
export const deleteTemplateRequest = (templateId, passProjectId) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/scriptTemplate/${templateId}`, { passProjectId });
export const getTemplateByIdRequest = (projectId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/scriptTemplate/${projectId}`);
export const updateDefaultTemplateRequest = (projectId, payload, templateId) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/scriptTemplate/?templateId=${templateId}`, payload, {
    headers: { projectId },
  });

//defect template
export const createDefectTemplateRequest = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/defectDetailsTemplate/`, payload);
export const getAllDefectTemplate = (action) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/defectDetailsTemplate/${action ? '?action=' + action : ''}`);
export const updateDefectTemplateRequest = (projectId, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/defectDetailsTemplate/${projectId}`, payload);
export const deleteDefectTemplateRequest = (templateId, passProjectId) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/defectDetailsTemplate/${templateId}`, { passProjectId });
export const getDefectTemplateByIdRequest = (projectId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/defectDetailsTemplate/${projectId}`);
export const updateDefaultDefectTemplateRequest = (projectId, payload, templateId) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/defectDetailsTemplate/?templateId=${templateId}`, payload, {
    headers: { projectId },
  });
export const getAllDefectLifecycleTemplate = (action) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/defectLifecycleTemplate/${action ? '?action=' + action : ''}`);
export const getDefectLifecycleTemplateByIdRequest = (projectId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/defectLifecycleTemplate/${projectId}`);
export const updateDefaultDefectLifecycleTemplateRequest = (projectId, payload, templateId) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/defectLifecycleTemplate/?templateId=${templateId}`, payload, {
    headers: { projectId },
  });

//Repository  Start

export const getPageTreeReq = (projectType, platform, resourceRequired) => {
  if (projectType !== 'Web') {
    return httpConnection.get(
      `${treeUrlBase}/optimize/v1/trees/pages${resourceRequired === false ? '?resourceRequired=false' : ''}`,
      { headers: { projectType: `${projectType}`, platForm: `${platform}` } }
    );
  } else {
    return httpConnection.get(
      `${treeUrlBase}/optimize/v1/trees/pages${resourceRequired === false ? '?resourceRequired=false' : ''}`,
      { headers: { projectType: `${projectType}` } }
    );
  }
};
export const singlePageTreeReq = (pageId, projectType, platform, resourceRequired) => {
  if (projectType === 'Web') {
    return httpConnection.get(
      `${treeUrlBase}/optimize/v1/trees/pages/${pageId}${resourceRequired === false ? '?resourceRequired=false' : ''}`,
      { headers: { projectType: `${projectType}` } }
    );
  } else {
    return httpConnection.get(
      `${treeUrlBase}/optimize/v1/trees/pages/${pageId}${resourceRequired === false ? '?resourceRequired=false' : ''}`,
      { headers: { projectType: `${projectType}`, platForm: `${platform}` } }
    );
  }
};

//first level page tree
export const getFirstLevelPageTreeReq = (projectType, platform, resourceRequired) => {
  if (projectType !== 'Web') {
    return httpConnection.get(
      `${treeUrlBase}/optimize/v1/trees/parentPagesTree${resourceRequired === false ? '?resourceRequired=false' : ''}`,
      { headers: { projectType: `${projectType}`, platForm: `${platform}` } }
    );
  } else {
    return httpConnection.get(
      `${treeUrlBase}/optimize/v1/trees/parentPagesTree${resourceRequired === false ? '?resourceRequired=false' : ''}`,
      { headers: { projectType: `${projectType}` } }
    );
  }
};

export const getPageReq = (key) => httpConnection.get(`${projectBaseUrl}/optimize/v1/pages/${key}`);
export const postPageReq = (payload) => httpConnection.post(`${projectBaseUrl}/optimize/v1/pages/`, payload);
export const delPageReq = (key) => httpConnection.delete(`${projectBaseUrl}/optimize/v1/pages/${key}`);
export const putPageReq = (key, payload) => httpConnection.put(`${projectBaseUrl}/optimize/v1/pages/${key}`, payload);

//element
export const getElementReq = (parentId, key) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/elements/pages/${parentId}/element/${key}`);
export const postElementReq = (payload, parentId) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/elements/pages/${parentId}/element`, payload);
export const delElementReq = (parentId, key, headerData) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/elements/pages/${parentId}/element/${key}`, {
    headers: headerData,
  });
export const putElementReq = (key, elementId, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/elements/pages/${key}/element/${elementId}`, payload);
export const getAllElementsForSuggestion = (projectId, projectType, platform) =>
  httpConnection.get(`${treeUrlBase}/optimize/v1/trees/element-suggestion`, {
    headers: { projectId: projectId, projectType: projectType, platForm: platform ? platform : '' },
  });
//packages
export const getPackageTreeReq = (packageId) => {
  if (!packageId) {
    return httpConnection.get(`${treeUrlBase}/optimize/v1/trees/packages`);
  } else {
    return httpConnection.get(`${treeUrlBase}/optimize/v1/trees/package/${packageId}`);
  }
};

//first level package tree
export const getFirstLevelPackageTreeReq = () => httpConnection.get(`${treeUrlBase}/optimize/v1/trees/parentPackages`);

export const getPackageReq = (key) => httpConnection.get(`${projectBaseUrl}/optimize/v1/packages/${key}`);
export const postPackageReq = (payload) => httpConnection.post(`${projectBaseUrl}/optimize/v1/packages/`, payload);
export const delPackageReq = (key) => httpConnection.delete(`${projectBaseUrl}/optimize/v1/packages/${key}`);
export const putPackageReq = (key, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/packages/${key}`, payload);

export const getFilesReq = (fileId) => httpConnection.get(`${projectBaseUrl}/optimize/v1/file/${fileId}`);
//Library
export const getLibraryTreeReq = (libraryId, resourceRequired) => {
  if (!libraryId) {
    return httpConnection.get(
      `${treeUrlBase}/optimize/v1/trees/libraries${resourceRequired === false ? '?resourceRequired=false' : ''}`
    );
  } else {
    return httpConnection.get(
      `${treeUrlBase}/optimize/v1/trees/library/${libraryId}${
        resourceRequired === false ? '?resourceRequired=false' : ''
      }`
    );
  }
};

export const getLibraryStepGroupMoveReq = (resourceRequired, stepGroupOrLibraryId) => {
  return httpConnection.get(
    `${treeUrlBase}/optimize/v1/trees/libraries${resourceRequired === true ? '?resourceRequired=true' : ''}${
      stepGroupOrLibraryId ? `&stepGroupOrLibraryId=${stepGroupOrLibraryId}` : ''
    }`
  );
};

export const getPageElementMoveReq = (resourceRequired, elementOrPageId, headerData) => {
  return httpConnection.get(
    `${treeUrlBase}/optimize/v1/trees/pages${resourceRequired === true ? '?resourceRequired=true' : ''}${
      elementOrPageId ? `&elementOrPageId=${elementOrPageId}` : ''
    }`,
    { headers: headerData }
  );
};

//first level library tree
export const getFirstLevelLibraryTreeReq = () =>
  httpConnection.get(`${treeUrlBase}/optimize/v1/trees/parentLibrariesTree?resourceRequired=true`);

export const getLibraryReq = (key) => httpConnection.get(`${projectBaseUrl}/optimize/v1/libraries/${key}`);
export const postLibraryReq = (payload) => httpConnection.post(`${projectBaseUrl}/optimize/v1/libraries/`, payload);
export const delLibraryReq = (key) => httpConnection.delete(`${projectBaseUrl}/optimize/v1/libraries/${key}`);
export const putLibraryReq = (key, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/libraries/${key}`, payload);

// stepgroup call

export const createStepGroupReq = (libId, payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/stepGroups/libraries/${libId}/stepGroup`, payload);
export const getStepGroupReq = (libId, stepgrpId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/stepGroups/libraries/${libId}/stepGroup/${stepgrpId}`);
export const updateStepGroupReq = (libId, stepgrpId, action, payload) =>
  httpConnection.put(
    `${projectBaseUrl}/optimize/v1/stepGroups/libraries/${libId}/stepGroup/${stepgrpId}?action=${action}`,
    payload
  );
export const DeleteStepGroupReq = (libId, stepgrpId) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/stepGroups/libraries/${libId}/stepGroup/${stepgrpId}`);

///Repository Call End

//export const getAllRoleRequest= () => httpConnection.get(`${managementBaseUrl}/optimize/v1/role/ROLE1001`);

// TestDevelopment API's
// module calls
export const getSingleModuleTreeReq = (moduleId, resourceRequired) =>
  httpConnection.get(
    `${treeUrlBase}/optimize/v1/trees/modules/${moduleId}?sort=true${
      resourceRequired === false ? '&resourceRequired=false' : ''
    }`
  );
export const getAllModuleTreeReq = (resourceRequired, scriptOrModuleId) =>
  httpConnection.get(
    `${treeUrlBase}/optimize/v1/trees/modules/?sort=true${resourceRequired === false ? '&resourceRequired=false' : ''}${
      scriptOrModuleId ? `&scriptOrModuleId=${scriptOrModuleId}` : ''
    }`
  );
export const getFirstLevelAllModulesTreeReq = (scriptOrModuleId) =>
  httpConnection.get(
    `${treeUrlBase}/optimize/v1/trees/modulesTrees/?sort=true&resourceRequired=true${
      scriptOrModuleId ? `&scriptOrModuleId=${scriptOrModuleId}` : ''
    }`
  );
export const getExpandModuleTreeReq = (moduleId, scriptOrModuleId, resourceRequired, type) =>
  httpConnection.get(
    `${treeUrlBase}/optimize/v1/trees/moduleExpand/${moduleId}?sort=true${
      resourceRequired === false ? '&resourceRequired=false' : ''
    }${type ? `&type=${type}` : ''}${scriptOrModuleId ? `&scriptOrModuleId=${scriptOrModuleId}` : ''}`
  );
export const getTCMSingleModuleTreeReq = (moduleId, testCaseType) =>
  httpConnection.get(`${treeUrlBase}/optimize/v1/trees/modules/${moduleId}?sort=true&testCaseType=${testCaseType}`);
export const getTCMAllModuleTreeReq = (testCaseType) =>
  httpConnection.get(`${treeUrlBase}/optimize/v1/trees/modules/?sort=true&testCaseType=${testCaseType}`);
export const createModuleReq = (payload) => httpConnection.post(`${projectBaseUrl}/optimize/v1/modules/`, payload);
export const updateModuleReq = (id, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/modules/${id}`, payload);
export const DeleteModuleReq = (moduleId) => httpConnection.delete(`${projectBaseUrl}/optimize/v1/modules/${moduleId}`);
export const getSingleModuleReq = (moduleId) => httpConnection.get(`${projectBaseUrl}/optimize/v1/modules/${moduleId}`);

// Execution
export const createExecutionReq = (payload) =>
  httpConnection.post(`${executionBaseUrl}/optimize/v1/dashboard/execution`, payload);

// Script calls
//export script calls starts
export const createScriptZipFile = (moduleId, scriptId) =>
  httpConnection.post(`${exportBaseUrl}/optimize/v1/export/modules/${moduleId}/scripts/${scriptId}`);
export const downloadScriptZipFile = (fileId) =>
  httpConnection({
    method: 'GET',
    url: `${projectBaseUrl}/optimize/v1/file/download/${fileId}`,
    responseType: 'blob',
  });
//export script calls ends
export const getScriptReq = (moduleId, scriptId, manualScriptIdRequired) =>
  httpConnection.get(
    `${projectBaseUrl}/optimize/v1/scripts/modules/${moduleId}/script/${scriptId}${
      manualScriptIdRequired ? '?manualScriptIdRequired=true' : ''
    } `
  );
export const createScriptReq = (id, payload, scriptType) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/scripts/modules/${id}/script?newScript=${scriptType}`, payload);
export const updateScriptReq = (moduleId, scriptId, action, payload) =>
  httpConnection.put(
    `${projectBaseUrl}/optimize/v1/scripts/modules/${moduleId}/script/${scriptId}?action=${action}`,
    payload
  );
export const updateBothScriptReq = (moduleId, oldScriptName, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/scripts/modules/${moduleId}/?oldName=${oldScriptName}`, payload);
export const DeleteScriptReq = (moduleId, scriptId) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/scripts/modules/${moduleId}/script/${scriptId}`);

export const getNewNlpReq = (payload, headers, conditions) => {
  return httpConnection.post(`${analyticsBaseUrl}/optimize/v1/nlp/${conditions}`, payload, {
    headers: headers,
  });
};

export const getAllElementsReq = (projectId, projectType, platform) => {
  if (platform) {
    return httpConnection.get(`${treeUrlBase}/optimize/v1/trees/elements`, {
      headers: { projectType: `${projectType}`, platForm: `${platform}` },
    });
  } else {
    return httpConnection.get(`${treeUrlBase}/optimize/v1/trees/elements`, {
      headers: { projectType: `${projectType}` },
    });
  }
};
export const createStepReq = (moduleId, scriptId, payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/steps/modules/${moduleId}/script/${scriptId}/`, payload);
export const getStepsReq = (moduleId, scriptId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/steps/modules/${moduleId}/script/${scriptId}/steps`);
export const DeleteStepReq = (moduleId, scriptId, stepId) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/steps/modules/${moduleId}/script/${scriptId}/step/${stepId}`);

//* To create visual testing element from repository

export const createVisualTestingElementReq = (payload, parentId, sectionName) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/elements/pages/${parentId}/visual-testing`, payload, {
    headers: { type: 'formdata', Sectionname: sectionName },
  });

export const updateVisualTestingElementReq = (key, elementId, payload, sectionName) =>
  httpConnection.put(
    `${projectBaseUrl}/optimize/v1/elements/pages/${key}/element/visual-testing/${elementId}`,
    payload,
    { headers: { type: 'formdata', Sectionname: sectionName } }
  );

export const updateVisualTestingSharedElementReq = (id, payload, sectionName) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/sharedElement/visual-testing/${id}`, payload, {
    headers: { type: 'formdata', Sectionname: sectionName },
  });

export const downloadVisualTestingElementReq = (payload) =>
  httpConnection({
    method: 'POST',
    url: `${projectBaseUrl}/optimize/v1/file/download/file`,
    responseType: 'blob',
    data: payload,
  });

// Local Variables
export const getAllLocalVariablesReq = async (moduleId, scriptId, excludeWebserviceVariables = true) => {
  const response = await httpConnection.get(`${projectBaseUrl}/optimize/v1/variable/MODULE/${moduleId}/${scriptId}`);
  if (response?.data?.responseObject?.length && excludeWebserviceVariables) {
    response.data.responseObject = response.data.responseObject.filter(
      (variable) => !(variable.name.startsWith('WSWB_') && variable.name.endsWith('_VAR'))
    );
  }
  return response;
};
export const createLocalVariableReq = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/variable/`, payload);
export const updateLocalVariableReq = (id, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/variable/${id}`, payload);
export const deleteLocalVariableReq = (id, name) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/variable/${id}/${name}`);

// Quick run settings
export const getAllMachineReq = (userId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/clientSystemConfig/user/${userId}`);
export const getQuickRunSettingsReq = (userId, quickRunSettingsId) =>
  httpConnection.get(
    `${projectBaseUrl}/optimize/v1/quickRunSettings/userdetails/${userId}/quickRunSetting/${quickRunSettingsId}`
  );
export const createQuickRunSettingsReq = (userId, payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/quickRunSettings/userdetails/${userId}/quickRunSetting`, payload);
export const updateQuickRunSettingsReq = (userId, quickRunSettingsId, payload) =>
  httpConnection.put(
    `${projectBaseUrl}/optimize/v1/quickRunSettings/userdetails/${userId}/quickRunSetting/${quickRunSettingsId}`,
    payload
  );

// Dataprovider
export const getAllDataprovidersReq = (moduleId, scriptId) =>
  httpConnection.get(
    `${projectBaseUrl}/optimize/v1/dataProviders/modules/${moduleId}/scripts/${scriptId}/dataProviders`
  );
export const createDataproviderReq = (moduleId, scriptId, payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/dataProviders/modules/${moduleId}/scripts/${scriptId}/`, payload);
export const updateDataproviderReq = (moduleId, scriptId, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/dataProviders/modules/${moduleId}/scripts/${scriptId}/`, payload);
export const deleteDataproviderReq = (moduleId, scriptId, dataproviderId) =>
  httpConnection.delete(
    `${projectBaseUrl}/optimize/v1/dataProviders/modules/${moduleId}/scripts/${scriptId}/dataProvider/${dataproviderId}`
  );
export const getAllExcelFilesSearchReq = (projectId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/file/search/${projectId}/xlsx`);
export const getAllExcelFilesSheetsReq = (fileId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/file/read/${fileId}`);
export const getExcelSheetDataReq = (fileId, sheetName) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/file/view/${fileId}/${sheetName}`);
// module -level pre-post-Condition                                                              /optimize/v1/conditions/modules/MOD3870/conditions
export const getModuleConditionReq = (moduleId, conditionId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/conditions/modules/${moduleId}/condition/${conditionId}`);
export const getModulePrePostConditionsReq = (moduleId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/conditions/modules/${moduleId}/conditions`);
export const createModulePrePostConditionReq = (moduleId, payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/conditions/modules/${moduleId}/condition`, payload);
export const cascadeConditionToScriptReq = (moduleId, payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/conditions/modules/${moduleId}/cascadedCondition`, payload);
export const updateModulePrePostConditionReq = (moduleId, conditionId, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/conditions/modules/${moduleId}/condition/${conditionId}`, payload);
export const deleteModulePrePostConditionReq = (moduleId, conditionId) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/conditions/modules/${moduleId}/condition/${conditionId}`);
export const getPaginatedModulePrePostConditionsReq = (moduleId, conditionType, page, size) =>
  httpConnection.get(
    `${projectBaseUrl}/optimize/v1/conditions/modules/${moduleId}/conditions?type=${conditionType}&page=${page}&size=${size}`
  );

//Script-Level Pre-PostCondition
export const getScriptPrePostConditionsReq = (moduleId, scriptId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/conditions/modules/${moduleId}/scripts/${scriptId}/conditions`);
export const createPrePostConditionReq = (moduleId, scriptId, payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/conditions/modules/${moduleId}/scripts/${scriptId}`, payload);
export const updatePrePostConditionReq = (moduleId, scriptId, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/conditions/modules/${moduleId}/scripts/${scriptId}`, payload);
export const deletePrePostConditionReq = (moduleId, scriptId, conditionId, conditionType) =>
  httpConnection.delete(
    `${projectBaseUrl}/optimize/v1/conditions/modules/${moduleId}/scripts/${scriptId}/condition/${conditionId}/${conditionType}`
  );

// Depends on scripts
export const getDependsOnScriptsTreeReq = (moduleId, scriptId) =>
  httpConnection.get(`${treeUrlBase}/optimize/v1/trees/module/${moduleId}/script/${scriptId}`);
export const getAllDependsOnScriptsReq = (moduleId, scriptId) =>
  httpConnection.get(
    `${projectBaseUrl}/optimize/v1/dependentScripts/modules/${moduleId}/scripts/${scriptId}/dependentScripts`
  );
export const createDependsOnScriptsReq = (moduleId, scriptId, payload) =>
  httpConnection.post(
    `${projectBaseUrl}/optimize/v1/dependentScripts/modules/${moduleId}/scripts/${scriptId}/`,
    payload
  );
export const updateDependsOnScriptReq = (moduleId, scriptId, payload) =>
  httpConnection.put(
    `${projectBaseUrl}/optimize/v1/dependentScripts/modules/${moduleId}/scripts/${scriptId}/`,
    payload
  );
export const deleteDependsOnScriptReq = (moduleId, scriptId, dependsOnScriptId) =>
  httpConnection.delete(
    `${projectBaseUrl}/optimize/v1/dependentScripts/modules/${moduleId}/scripts/${scriptId}/dependentScript/${dependsOnScriptId}`
  );

// Execution Dashboard
export const getExecutionTableDataReq = (projectId, pageNo, pageSize) =>
  httpConnection.get(
    `${executionBaseUrl}/optimize/v1/dashboard/projects/${projectId}?executionType=INDIVIDUAL&pageNo=${pageNo}&pageSize=${pageSize}&archiveFlag=${getArchiveFlag()}`
  );
export const getSingleExecutionDataReq = (executionId) =>
  httpConnection.get(
    `${executionBaseUrl}/optimize/v1/dashboard/execution/${executionId}?archiveFlag=${getArchiveFlag()}`
  );
export const getSingleScheduleExecutionDataReq = (executionId) =>
  httpConnection.get(`${executionBaseUrl}/optimize/v1/scheduledExecutionDashboard/${executionId}`);
export const updateExecutionNameAndStatusReq = (executionId, payload) =>
  httpConnection.put(
    `${executionBaseUrl}/optimize/v1/dashboard/execution/${executionId}?archiveFlag=${getArchiveFlag()}`,
    payload
  );
export const terminateExecutionReq = (executionId, payload) =>
  httpConnection.put(
    `${executionBaseUrl}/optimize/v1/dashboard/execution/status/${executionId}?status=Terminated`,
    payload
  );
export const getExecutionMachineDetailsReq = (clientId) =>
  httpConnection.get(`${executionBaseUrl}/optimize/v1/dashboard/clients/${clientId}`);
export const reRunExecutionReq = (executionId, payload, deleteSpillOverMemory = false) =>
  httpConnection.post(
    `${executionBaseUrl}/optimize/v1/dashboard/execution/${executionId}?deleteSpillOverMemory=${deleteSpillOverMemory}&archiveFlag=${getArchiveFlag()}`,
    payload
  );
export const getExecutionTreeResultReq = (executionId) =>
  httpConnection.get(
    `${resultBaseUrl}/optimize/v1/executionResponse/result/${executionId}?archiveFlag=${getArchiveFlag()}`
  );
export const getExecutionTreeExpandAllResultReq = (executionId) =>
  httpConnection.get(
    `${resultBaseUrl}/optimize/v1/executionResponse/result/expandAll/${executionId}${
      getArchiveFlag() ? '?archiveFlag=' + getArchiveFlag() : ''
    }`
  );
export const getExecutionTreeResultSyncReq = (executionId) =>
  httpConnection.get(
    `${resultBaseUrl}/optimize/v1/executionResponse/result/sync/${executionId}?archiveFlag=${getArchiveFlag()}`
  );
export const getStepResult = (stepId) =>
  httpConnection.get(`${resultBaseUrl}/optimize/v1/executionResponse/step/${stepId}?archiveFlag=${getArchiveFlag()}`);
export const deleteExecutionReq = (executionId, payload) =>
  httpConnection.delete(
    `${executionBaseUrl}/optimize/v1/dashboard/execution/${executionId}?archiveFlag=${getArchiveFlag()}`,
    { data: payload }
  );
export const downloadScreenshotVideoReq = (payload) =>
  httpConnection({
    method: 'POST',
    url: `${executionBaseUrl}/optimize/v1/dashboard/download/storage`,
    responseType: 'blob',
    data: payload,
  });
export const getTemplateReq = (templateId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/scriptTemplate/${templateId}`);
// TestDevelopment API's end

export const getSingleUserRequest = (userId) => httpConnection.get(`${managementBaseUrl}/optimize/v1/user/${userId}`);
export const getAllFiles = (projectId, fileType) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/file/search/${projectId}/${fileType}`);
export const getFolderPathReq = () => httpConnection.get(`${treeUrlBase}/optimize/v1/trees/folders/path`);
export const getAllTestDataFiles = (fileType) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/file/files${fileType ? `?fileType=${fileType}` : ''}`);
//export const getSingleRoleRequest = () => httpConnection.get(`${managementBaseUrl}/optimize/v1/role/ROLE1001`);

//test data calls

export const getFolderTreeReq = (folderId, projectId) => {
  const headers = projectId ? { headers: { projectId } } : {};
  if (folderId) {
    return httpConnection.get(`${treeUrlBase}/optimize/v1/trees/folder/${folderId}`, headers);
  } else {
    return httpConnection.get(`${treeUrlBase}/optimize/v1/trees/folders`, headers);
  }
};
//first level folder tree
export const getFirstLevelFolderTreeReq = () =>
  httpConnection.get(`${treeUrlBase}/optimize/v1/trees/parentFolders?resourceRequired=true`);

export const getSingleFolderTreeReq = (folderId) =>
  httpConnection.get(`${treeUrlBase}/optimize/v1/trees/folder/${folderId}`);

export const getOnlyContainerFolderTreeReq = (folderId) => {
  if (folderId) {
    return httpConnection.get(`${treeUrlBase}/optimize/v1/trees/folder/${folderId}?resourceRequired=false`);
  } else {
    return httpConnection.get(`${treeUrlBase}/optimize/v1/trees/folders?resourceRequired=false`);
  }
};

export const postFolderReq = (payload, projectId) => {
  const headers = projectId ? { headers: { projectId } } : {};
  return httpConnection.post(`${projectBaseUrl}/optimize/v1/folders/`, payload, headers);
};

export const deleteFolderReq = (folderId) => httpConnection.delete(`${projectBaseUrl}/optimize/v1/folders/${folderId}`);

export const getSingleFolderReq = (folderId) => httpConnection.get(`${projectBaseUrl}/optimize/v1/folders/${folderId}`);

export const editSingleFolderReq = (folderId, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/folders/${folderId}`, payload);

export const renameFileReq = (fileId, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/file/${fileId}`, payload);

export const renameFileCloudReq = (fileId, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/file/cloud/${fileId}`, payload);

export const uploadFilesReq = (folderId, folderName, files, sectionName, projectId) => {
  const headers = projectId ? { headers: { projectId } } : {};
  return httpConnection.post(`${projectBaseUrl}/optimize/v1/file/folder/${folderId}/testdata/true`, files, {
    headers: { type: 'formdata', Sectionname: sectionName, ...headers.headers },
  });
};

export const updateFileReq = (fileId, payload, sectionName) => {
  return httpConnection.put(`${projectBaseUrl}/optimize/v1/file/replace/cloud/${fileId}`, payload, {
    headers: { type: 'formdata', Sectionname: sectionName },
  });
};

export const programElementJarFiles = (folderId, files) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/file/folder/${folderId}/programelement/false`, files, {
    headers: { type: 'formdata' },
  });

export const deleteFileReq = (fileId, folderId, projectId) => {
  const headers = projectId ? { headers: { projectId } } : {};

  return httpConnection.delete(`${projectBaseUrl}/optimize/v1/file/cloud/${fileId}`, {
    headers: { folderId: `${folderId}`, ...headers.headers },
  });
};

export const getFileRequest = (fileId, projectId) => {
  const headers = projectId ? { headers: { projectId } } : {};
  return httpConnection.get(`${projectBaseUrl}/optimize/v1/file/cloud/${fileId}`, headers);
};
//system config
export const getSingleClientMachineReq = (machineId) =>
  httpConnection.get(`http://localhost:8007/optimize/v1/client-system-config/${machineId}`);
export const deleteMachineReq = (machineId) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/clientSystemConfig/${machineId}`);
export const getUserAllAvailableMachineReq = (userId, projectId) => {
  if (projectId) {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/clientSystemConfig/user/${userId}`, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/clientSystemConfig/user/${userId}`);
  }
};
export const disableMachineReq = (machineId, payload, projectId) => {
  if (projectId) {
    return httpConnection.put(`${projectBaseUrl}/optimize/v1/clientSystemConfig/${machineId}`, payload, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.put(`${projectBaseUrl}/optimize/v1/clientSystemConfig/${machineId}`, payload);
  }
};
export const getAllClientMachineReq = (projectId) => {
  if (projectId) {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/clientSystemConfig/all`, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/clientSystemConfig/all`);
  }
};
export const getAllUserDetailsReq = (projectId, machineID) => {
  if (projectId) {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/userDetail/users/machines/${machineID}`, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/userDetail/users/machines/${machineID}`);
  }
};
export const getAllCommomUserDetailsReq = (projectId) => {
  if (projectId) {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/userDetail/users/common/machine`, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/userDetail/users/common/machine`);
  }
};
export const addMachineToMultiUsersReq = (machineId, payload, projectId) => {
  if (projectId) {
    return httpConnection.put(`${projectBaseUrl}/optimize/v1/userDetail/users/machine/${machineId}`, payload, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.put(`${projectBaseUrl}/optimize/v1/userDetail/users/machine/${machineId}`, payload);
  }
};
export const removeMachineToMultiUsersReq = (machineId, payload, projectId) => {
  if (projectId) {
    return httpConnection.delete(
      `${projectBaseUrl}/optimize/v1/userDetail/users/machine/${machineId}`,
      { data: payload },
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.delete(`${projectBaseUrl}/optimize/v1/userDetail/users/machine/${machineId}`, {
      data: payload,
    });
  }
};
export const addMachineToAllUsersReq = (payload, projectId) => {
  if (projectId) {
    return httpConnection.put(`${projectBaseUrl}/optimize/v1/userDetail/users/all/access`, payload, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.put(`${projectBaseUrl}/optimize/v1/userDetail/users/all/access`, payload);
  }
};
export const removeMachinefromAllUsersReq = (machineId, projectId) => {
  if (projectId) {
    return httpConnection.delete(
      `${projectBaseUrl}/optimize/v1/userDetail/users/access/machine/user/all`,
      { data: machineId },
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.delete(`${projectBaseUrl}/optimize/v1/userDetail/users/access/machine/user/all`, {
      data: machineId,
    });
  }
};
export const addOrChangeDefaultMachineReq = (userId, payload, projectId) => {
  if (projectId) {
    return httpConnection.put(`${projectBaseUrl}/optimize/v1/userDetail/${userId}/machine/default`, payload, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.put(`${projectBaseUrl}/optimize/v1/userDetail/${userId}/machine/default`, payload);
  }
};

//shared elements
export const getAllSharedElements = (projectId, projectType, platform) => {
  if (platform && platform !== 'undefined') {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/sharedElement/`, {
      headers: {
        projectId: `${projectId}`,
        projectType: `${projectType}`,
        platform: `${platform}`,
      },
    });
  } else if (projectType === 'Web') {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/sharedElement/`, {
      headers: { projectId: `${projectId}`, projectType: `${projectType}` },
    });
  }
};
export const addEleAsSharedEleReq = (pageId, payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/elements/pages/${pageId}/element`, payload);
export const changeEleAsSharedEleReq = (pageId, ElementId) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/sharedElement/page/${pageId}/element/${ElementId}`);
export const unShareSharedEleReq = (pageId, ElementId) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/sharedElement/unshare/${pageId}/element/${ElementId}`);
export const getSharedElementReq = (key) => httpConnection.get(`${projectBaseUrl}/optimize/v1/sharedElement/${key}`);
export const addAsNewElementReq = (payload, eleId, parentId) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/sharedElement/Y/null`, payload, {
    headers: { pageId: `${parentId}`, elementId: `${eleId}` },
  });
export const updateSharedElementReq = (id, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/sharedElement/${id}`, payload);
export const addmultisharedElementReq = (pageId, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/sharedElement/page/${pageId}`, payload);
export const mergeMultipleElementReq = (payload, eleId, parentId) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/sharedElement/clearMultipleConflicts`, payload, {
    headers: { pageId: `${parentId}`, elementId: `${eleId}` },
  });
export const mergeElementReq = (payload, sharingId, eleId, parentId) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/sharedElement/N/${sharingId}`, payload, {
    headers: { pageId: `${parentId}`, elementId: `${eleId}` },
  });

//Program Element

export const postProgramElementReq = (payload, packageid) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/programelements/packages/${packageid}/programelement`, payload);

export const deleteProgramElementReq = (parentId, key, projectId) =>
  httpConnection.delete(
    `${projectBaseUrl}/optimize/v1/programelements/packages/${parentId}/programelement/${key}/${projectId}`
  );

export const getProgramElementReq = (parentId, key) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/programelements/packages/${parentId}/programelement/${key}`);

export const updateProgramElementReq = (parentId, key, payload) =>
  httpConnection.put(
    `${projectBaseUrl}/optimize/v1/programelements/packages/${parentId}/programelement/${key}?skipCompile=true`,
    payload
  );

//Execution-suite
export const getAllModuleSuiteTreeReq = () => httpConnection.get(`${treeUrlBase}/optimize/v1/trees/modules/suite`);
export const getAllModuleTreeSuiteReq = (type) =>
  httpConnection.get(`${treeUrlBase}/optimize/v1/trees/modules/?sort=true&type=${type}`);
export const getAllTestDataForSuiteTreeReq = () =>
  httpConnection.get(`${treeUrlBase}/optimize/v1/trees/folders/suite?sort=false`);
export const getAllEmailGroupListReq = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/emailGroup/`);
export const getAllMachineListReq = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/clientSystemConfig/all`);
export const getAllSuiteReq = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/suite/getAll/all`);
export const getSingleSuiteReq = (suiteId) => httpConnection.get(`${projectBaseUrl}/optimize/v1/suite/${suiteId}`);

export const postSuiteReq = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/suite/`, payload, {
    headers: { type: 'formdata' },
  });
export const updateSuiteReq = (id, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/suite/${id}`, payload, {
    headers: { type: 'formdata' },
  });
export const deleteSuiteReq = (id) => httpConnection.delete(`${projectBaseUrl}/optimize/v1/suite/${id}`);
export const postSetReq = (id, payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/variableSets/suites/${id}/projectEnvironmentVariableSet`, payload);
export const deleteSetReq = (suiteId, setName) =>
  httpConnection.delete(
    `${projectBaseUrl}/optimize/v1/variableSets/suites/${suiteId}/projectEnvironmentVariableSet/${setName}`
  );
export const postGlobalSetReq = (id, payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/variableSets/suites/${id}/globalVariableSet`, payload);
export const deleteGlobalSetReq = (suiteId, setName) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/variableSets/suites/${suiteId}/globalVariableSet/${setName}`);
export const postTestDataSetReq = (id, payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/testDataSets/suites/${id}/testDataSet`, payload, {
    headers: { type: 'formdata' },
  });
export const deleteTestDataSetReq = (suiteId, setName) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/testDataSets/suites/${suiteId}/testDataSet/${setName}`);
export const updateProjectVarSetReq = (suiteId, setName, payload) =>
  httpConnection.put(
    `${projectBaseUrl}/optimize/v1/variableSets/suites/${suiteId}/projectEnvironmentVariableSet/${setName}`,
    payload
  );
export const updateGlobalVarSetReq = (suiteId, setName, payload) =>
  httpConnection.put(
    `${projectBaseUrl}/optimize/v1/variableSets/suites/${suiteId}/globalVariableSet/${setName}`,
    payload
  );
export const updateTestDataSetReq = (suiteId, setName, payload, sectionName) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/testDataSets/suites/${suiteId}/testDataSet/${setName}`, payload, {
    headers: { type: 'formdata', Sectionname: `${sectionName}` },
  });
//export const downloadFileReq = (fileId) => httpConnection.get(`${projectBaseUrl}/optimize/v1/file/download/${fileId}`, { headers: { 'type':'formdata' } });
export const downloadFileReq = (fileID) =>
  httpConnection({
    method: 'GET',
    url: `${projectBaseUrl}/optimize/v1/file/download/${fileID}`,
    responseType: 'blob',
  });

export const downloadFileFromCloud = (fileId) =>
  httpConnection({
    method: 'GET',
    url: `${projectBaseUrl}/optimize/v1/file/download/cloud/${fileId}`,
    responseType: 'blob',
  });

export const getExecutionSpecificTreeReq = (id) =>
  httpConnection.get(`${executionBaseUrl}/optimize/v1/dashboard/execution/partial/${id}`);

export const executeSuitePostReq = (id, payLoad) =>
  httpConnection.post(`${executionBaseUrl}/optimize/v1/dashboard/execution/suite/${id}`, payLoad);
export const getAllExeutionReq = (projid, type) =>
  httpConnection.get(
    `${executionBaseUrl}/optimize/v1/dashboard/projects/${projid}?executionType=${type}&archiveFlag=${getArchiveFlag()}`
  );
export const reExecuteSuiteReq = (exeId, deleteSpillOverMemory = false) =>
  httpConnection.post(
    `${executionBaseUrl}/optimize/v1/dashboard/execution/${exeId}?deleteSpillOverMemory=${deleteSpillOverMemory}&archiveFlag=${getArchiveFlag()}`
  );
export const terminateExecutionSuiteReq = (executionId) =>
  httpConnection.put(`${executionBaseUrl}/optimize/v1/dashboard/execution/status/${executionId}?status=Terminated`);
export const cancelExecutionReq = (exeId, payload) =>
  httpConnection.put(
    `${executionBaseUrl}/optimize/v1/dashboard/execution/${exeId}?archiveFlag=${getArchiveFlag()}`,
    payload
  );
export const getSingleClientSystemReq = (clientId) =>
  httpConnection.get(`${executionBaseUrl}/optimize/v1/dashboard/clients/${clientId}`);
export const getSuiteSpecificExeutionReq = (projid, suiteId, type, pageNo, pageSize) =>
  httpConnection.get(
    `${executionBaseUrl}/optimize/v1/dashboard/projects/${projid}/${suiteId}?executionType=${type}&pageNo=${pageNo}&pageSize=${pageSize}&archiveFlag=${getArchiveFlag()}`
  );
export const deleteExecutionDataReq = (exeId, payload) =>
  httpConnection.delete(
    `${executionBaseUrl}/optimize/v1/dashboard/execution/${exeId}?archiveFlag=${getArchiveFlag()}`,
    {
      data: payload,
    }
  );
export const schedulePostReq = (payload) =>
  httpConnection.post(`${executionBaseUrl}/optimize/v1/scheduledExecutionDashboard/`, payload);
export const getAllScheduleReq = (suiteId) =>
  httpConnection.get(`${executionBaseUrl}/optimize/v1/scheduledExecutionDashboard/all/SUITE/${suiteId}`);
export const deleteSheduledDataReq = (exeId) =>
  httpConnection.delete(`${executionBaseUrl}/optimize/v1/scheduledExecutionDashboard/${exeId}`);
export const updateScheduleExecutionReq = (exeId, payload) =>
  httpConnection.put(`${executionBaseUrl}/optimize/v1/scheduledExecutionDashboard/${exeId}`, payload);
export const getSingleExecutionDetailsReq = (exeId) =>
  httpConnection.get(`${resultBaseUrl}/optimize/v1/executionResponse/result/${exeId}?archiveFlag=${getArchiveFlag()}`);
export const syncVariableReq = (varNames) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/variable/sync?names=${varNames}`);
export const autoRerunExecutionReq = (exeId, payload, deleteSpillOverMemory = false) =>
  httpConnection.post(
    `${executionBaseUrl}/optimize/v1/dashboard/execution/${exeId}/manual?deleteSpillOverMemory=${deleteSpillOverMemory}&archiveFlag=${getArchiveFlag()}`,
    payload
  );
export const syncTestDataReq = (fileId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/file/sync?fileIds=${fileId}`);
export const updateSyncTestDataReq = (suiteId, payload, selectionType) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/file/sync?suiteId=${suiteId}&all=${selectionType}`, payload);
export const getSingleTestDataReq = (suiteId, setId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/testDataSets/suites/${suiteId}/testDataSet/${setId}`);
export const automaticDistributionPostReq = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/suite/distribution`, payload);
// export const  = (exeId) => httpConnection.get(`${resultBaseUrl}/optimize/v1/executionResponse/download/${exeId}`);
export const downloadFileFromExeDashboard = (exeId) =>
  httpConnection({
    method: 'GET',
    url: `${resultBaseUrl}/optimize/v1/executionResponse/download/${exeId}`,
    responseType: 'blob',
  });
export const getReportData = (exeId) =>
  httpConnection({
    method: 'GET',
    url: `${resultBaseUrl}/optimize/v1/executionResponse/download/${exeId}?archiveFlag=${getArchiveFlag()}`,
    responseType: 'blob',
  });

export const getHtmlReportDataReq = (exeId, currentLicenseId) =>
  httpConnection({
    method: 'GET',
    url: `${resultBaseUrl}/optimize/v1/executionResponse/download-html-report/${exeId}?archiveFlag=${getArchiveFlag()}`,
    responseType: 'blob',
  });
export const createRunSettingsPostReq = (suiteId, payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/suite/runSetting/${suiteId}`, payload);
export const getRunSettingsDataReq = (suiteId, action) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/suite/runSetting/${action}/${suiteId}`);

//Execute Manual Suite
export const executeManualSuite = (id, payLoad) =>
  httpConnection.post(`${executionBaseUrl}/optimize/v1/dashboard/execution/suite/manual/${id}`, payLoad);

export const sendExecutionMail = (id) =>
  httpConnection.get(`${resultBaseUrl}/optimize/v1/executionResponse/mail/${id}?archiveFlag=${getArchiveFlag()}`);

//Update Manual Suite Execution
export const updateManualSuiteExecution = (id, payload) =>
  httpConnection.put(
    `${executionBaseUrl}/optimize/v1/dashboard/execution/suite/manual/${id}?archiveFlag=${getArchiveFlag()}`,
    payload
  );

//Execution-suiteofsuie

export const postSuiteOfSuiteReq = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/suiteOfSuites/`, payload);
export const getAllSuiteOfSuiteReq = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/suiteOfSuites/getAll/all`);
export const updateSuiteOfSuiteReq = (id, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/suiteOfSuites/${id}`, payload);
export const deleteSuiteOfSuiteReq = (id) => httpConnection.delete(`${projectBaseUrl}/optimize/v1/suiteOfSuites/${id}`);
export const executeSuiteOfSuitePostReq = (id) =>
  httpConnection.post(`${executionBaseUrl}/optimize/v1/dashboard/execution/suiteOfSuite/${id}`);
export const getAllSuiteOfSuiteScheduleReq = (suiteId) =>
  httpConnection.get(`${executionBaseUrl}/optimize/v1/scheduledExecutionDashboard/all/SUITE_OF_SUITES/${suiteId}`);

//tags
export const postStepTag = (executionId, payload) =>
  httpConnection.post(
    `${resultBaseUrl}/optimize/v1/executions/${executionId}/tagDetails?archiveFlag=${getArchiveFlag()}`,
    payload
  );
export const updateStepTag = (tagDetailId, executionId, payload) =>
  httpConnection.put(
    `${resultBaseUrl}/optimize/v1/executions/${tagDetailId}/${executionId}/tagDetails?archiveFlag=${getArchiveFlag()}`,
    payload
  );
export const deleteStepTag = (executionId, payload) =>
  httpConnection.delete(
    `${resultBaseUrl}/optimize/v1/executions/${executionId}/tagDetails?archiveFlag=${getArchiveFlag()}`,
    {
      data: payload,
    }
  );
export const resolveStepTag = (executionId, payload, resolveStep) =>
  httpConnection.put(
    `${resultBaseUrl}/optimize/v1/executions/${executionId}/tagDetails/${resolveStep}?archiveFlag=${getArchiveFlag()}`,
    payload
  );

//parameters
export const addParameter = (libraryId, stepGroupId, payload) =>
  httpConnection.post(
    `${projectBaseUrl}/optimize/v1/parameter/librarys/${libraryId}/stepgroups/${stepGroupId}/`,
    payload
  );
export const updateParameter = (libraryId, stepGroupId, payload) =>
  httpConnection.put(
    `${projectBaseUrl}/optimize/v1/parameter/librarys/${libraryId}/stepgroups/${stepGroupId}/`,
    payload
  );
export const deleteParameter = (libraryId, stepGroupId, parameterId) =>
  httpConnection.delete(
    `${projectBaseUrl}/optimize/v1/parameter/librarys/${libraryId}/stepgroups/${stepGroupId}/parameter/${parameterId}`
  );
export const gellAllParameter = (libraryId, stepGroupId) =>
  httpConnection.get(
    `${projectBaseUrl}/optimize/v1/parameter/librarys/${libraryId}/stepgroups/${stepGroupId}/parameters`
  );
export const getParameter = (libraryId, stepGroupId, parameterId) =>
  httpConnection.get(
    `${projectBaseUrl}/optimize/v1/parameter/librarys/${libraryId}/stepgroups/${stepGroupId}/parameter/${parameterId}`
  );
export const getDataTypes = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/datatype/all`);

//step group variable
export const addStepGroupVariable = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/variable/`, payload);
export const getAllStepGroupVariable = async (libraryId, stepGroupId, excludeWebserviceVariables = true) => {
  const response = await httpConnection.get(
    `${projectBaseUrl}/optimize/v1/variable/STEPGROUP/${libraryId}/${stepGroupId}`
  );
  if (response?.data?.responseObject?.length && excludeWebserviceVariables) {
    response.data.responseObject = response.data.responseObject.filter(
      (variable) => !(variable.name.startsWith('WSWB_') && variable.name.endsWith('_VAR'))
    );
  }
  return response;
};
export const deleteStepGroupVariable = (id, name) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/variable/${id}/${name}`);
export const updateStepGroupVariable = (id, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/variable/${id}`, payload);
export const getStepGroupVariable = (id) => httpConnection.get(`${projectBaseUrl}/optimize/v1/variable/${id}`);
export const addStepGroupVariableArray = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/variable/multi/`, payload);

//global variable
export const getAllGlobalVariableListReq = async (excludeWebserviceVariables = true) => {
  const response = await httpConnection.get(`${projectBaseUrl}/optimize/v1/variable/type/GLOBAL`);
  if (response && response.data && response.data.responseObject) {
    if (excludeWebserviceVariables) {
      response.data.responseObject = response.data.responseObject.filter(
        (variable) => !variable.name.startsWith('WSWB_') && !variable.name.endsWith('_VAR')
      );
    }
    return response;
  } else {
    return response;
  }
};
export const createGlobalVariable = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/variable/`, payload);
export const deleteGloabalVariable = (id, name) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/variable/${id}/${name}`);
export const updateGloabalVariable = (id, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/variable/${id}`, payload);

//project environment variable
export const getAllProjectVariableListReq = () =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/variable/type/PROJECT_ENVIRONMENT`);
export const createProjectEnvironmentVariable = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/variable/`, payload);
export const updateProjectEnvironmentVairable = (id, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/variable/${id}`, payload);
export const deleteProjectEnvironmentVariable = (id, name) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/variable/${id}/${name}`);
export const getSingleVariableDeatils = (id) => httpConnection.get(`${projectBaseUrl}/optimize/v1/variable/${id}`);

//Webservice
export const postWebServiceAddApi = (payload, headers) =>
  httpConnection.post(`${webserviceBaseUrl}/optimize/apis/execute`, payload, {
    headers: headers,
  });
export const getAllSnippet = (projectId, scriptId, moduleId) =>
  httpConnection.get(`${webserviceBaseUrl}/optimize/snippet/type/all?condition=`, {
    headers: {
      projectId: `${projectId}`,
      scriptId: `${scriptId}`,
      moduleId: `${moduleId}`,
    },
  });
export const postWebServiceStepNLP = (payload, id, moduleId, scriptId, headers, type) =>
  type === 'Webservice'
    ? httpConnection.post(
        `${webserviceBaseUrl}/optimize/apis/step?projectId=${id}&moduleId=${moduleId}&scriptId=${scriptId}`,
        payload,
        { headers: headers }
      )
    : httpConnection.post(
        `${webserviceBaseUrl}/optimize/apis/step?projectId=${id}&libraryId=${moduleId}&stepGroupId=${scriptId}`,
        payload,
        { headers: headers }
      );
export const getPreSnippet = () =>
  httpConnection.get(`${webserviceBaseUrl}/optimize/snippet/type/custom?condition=pre`);
export const getPostSnippet = () =>
  httpConnection.get(`${webserviceBaseUrl}/optimize/snippet/type/custom?condition=post`);
export const postCompileAPI = (payload) =>
  httpConnection.post(`${webserviceBaseUrl}/optimize/snippets/compile`, payload);
export const postAuth2Value = (payload) =>
  httpConnection.post(`${webserviceBaseUrl}/optimize/oauth`, payload, {
    headers: { 'Content-Type': 'application/json', Redirect: 'manual' },
    maxRedirects: 0,
    validateStatus: function (status) {
      return status >= 200 && status <= 302; // default
    },
  });
export const getAllHeaderKey = (rowName) =>
  httpConnection.get(`${webserviceBaseUrl}/optimize/suggestion/param/${rowName}?path=header_keys`);
export const getAllHeaderValueApi = (rowValue) =>
  httpConnection.get(`${webserviceBaseUrl}/optimize/suggestion/param/${rowValue}?path=header_values`);
export const getAllDefaultSnippet = () => httpConnection.get(`${webserviceBaseUrl}/optimize/snippet/default`);
export const getAllCustomSnippet = (projectId, scriptId, moduleId) =>
  httpConnection.get(`${webserviceBaseUrl}/optimize/snippet/custom`, {
    headers: {
      projectId: `${projectId}`,
      scriptId: `${scriptId}`,
      moduleId: `${moduleId}`,
    },
  });
export const postCompileSnippetAPI = (payload) =>
  httpConnection.post(`${webserviceBaseUrl}/optimize/snippets/compile`, payload);
export const createSnippet = (payload, projectId, scriptId, moduleId) =>
  httpConnection.post(`${webserviceBaseUrl}/optimize/snippet/custom`, payload, {
    headers: {
      projectId: `${projectId}`,
      scriptId: `${scriptId}`,
      moduleId: `${moduleId}`,
    },
  });
// export const updateSnippet = (id,payLoad)=> httpConnection.put(`${webserviceBaseUrl}/optimize/snippet/custom/${id}`,payLoad);
export const updateSnippet = (payLoad, projectId, scriptId, moduleId) =>
  httpConnection.put(`${webserviceBaseUrl}/optimize/snippet/custom`, payLoad, {
    headers: {
      projectId: `${projectId}`,
      scriptId: `${scriptId}`,
      moduleId: `${moduleId}`,
    },
  });
export const deleteSnippetVariable = (id) =>
  httpConnection.delete(`${webserviceBaseUrl}/optimize/snippet/custom/${id}`);

//Results
export const getModuleResultTreeReq = (exec_id) =>
  httpConnection.get(
    `${resultBaseUrl}/optimize/v1/executionResponse/result/${exec_id}?archiveFlag=${getArchiveFlag()}`
  );
export const getScriptResultReq = (exeId, clientId, runId, scriptId) => {
  const url = `${resultBaseUrl}/optimize/v1/executionResponse/result/${exeId}/${clientId}/${
    runId ? (['undefined', 'null'].includes(runId) ? 'NA/' : `${runId}/`) : 'NA/'
  }${scriptId}${getArchiveFlag() ? '?archiveFlag=' + getArchiveFlag() : ''}`;
  return httpConnection.get(url);
};

export const getScriptResultTags = (exeId, runId, scriptId) => {
  const url = `${resultBaseUrl}/optimize/v1/executions/tag/${exeId}/${runId}/${scriptId}?archiveFlag=${getArchiveFlag()}`;
  return httpConnection.get(url);
};

export const getExceptionSuggestion = (exceptionName, platform) => {
  return httpConnection.get(
    `${resultBaseUrl}/optimize/v1/exception-suggestions/exception-name/${exceptionName}/platform/${platform}`
  );
};

//Recorded Element
export const getAllRecordedElementReq = (projectType, platform) => {
  if (platform && platform !== 'undefined') {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/recordedElement/all`, {
      headers: { projectType: `${projectType}`, platform: `${platform}` },
    });
  } else {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/recordedElement/all`, {
      headers: { projectType: `${projectType}` },
    });
  }
};
export const updateRecordedElementReq = (id, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/recordedElement/${id}`, payload);
export const deleteSingleRecordedElementReq = (payload) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/recordedElement/deleteMultiple`, { data: payload });
export const moveRecordedElementReq = (payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/recordedElement/page/`, payload);
export const addAsNewRecordedEleReq = (payload, parentId, eleId) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/recordedElement/Y`, payload, {
    headers: { recordElementIds: `${eleId}` },
  });
export const clearMergeConflictApiReq = (payload, eleIds) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/recordedElement/N`, payload, {
    headers: { recordElementIds: `${eleIds}` },
  });
export const assignPageToMultipleElementReq = (pageId, pageName, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/recordedElement/assignMultipleReToPage`, payload, {
    headers: { pageName: `${pageName}`, pageId: `${pageId}` },
  });

//BrowserStack
export const getBrowserStackList = () =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/browserstack/browserStackList`);
export const addBrowserStack = (payload) => httpConnection.post(`${projectBaseUrl}/optimize/v1/browserstack/`, payload);
export const updateBrowserStack = (payload, id) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/browserstack/${id}`, payload);
export const deleteBrowserStack = (id) => httpConnection.delete(`${projectBaseUrl}/optimize/v1/browserstack/${id}`);
export const getBrowserList = (accessKey, username) => {
  return httpConnection.get(`${projectBaseUrl}/optimize/v1/browserstack/browserList/${accessKey}/${username}`);
};

//Cloud Platform API Integration

export const getCloudPlatformDataReq = (payload) => {
  return httpConnection.post(`${projectBaseUrl}/optimize/v1/integration/data`, payload);
};

// instance details for BrowserStack
export const getBrowserStackInstance = (username) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/browserstack/files/${username}`);
export const uploadBrowserStackInstance = (payload, username, sectionName) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/browserstack/upload/${username}`, payload, {
    headers: { type: 'formdata', Sectionname: sectionName },
  });

//sauceLabs
export const getSauceLabsList = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/saucelabs/sauceLabList`);
// export const addSauceLab = (payload) => httpConnection.post(`${projectBaseUrl}/optimize/v1/saucelabs/false`, payload);
export const updateSauceLab = (payload, id) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/saucelabs/${id}`, payload);
export const deleteSauceLab = (id) => httpConnection.delete(`${projectBaseUrl}/optimize/v1/saucelabs/${id}`);
export const getSauceLabBrowserList = (accessKey, username) => {
  return httpConnection.get(`${projectBaseUrl}/optimize/v1/saucelabs/sauceLabList/${accessKey}/${username}`);
};
// azure DevOps
export const getAzureList = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/azuredevops`);
export const updateAzure = (payload, id) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/azuredevops/${id}`, payload);
export const createAzureInstance = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/azuredevops`, payload);
export const deleteAzure = (id) => httpConnection.delete(`${projectBaseUrl}/optimize/v1/azuredevops/${id}`);
export const getAzurefileDownload = (id, env) =>
  httpConnection({
    method: 'GET',
    url: `${projectBaseUrl}/optimize/v1/azuredevops/${id}/download?environment=${env}`,
    responseType: 'blob',
  });
// git hub
export const getGithubList = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/github`);
export const updateGithub = (payload, id) => httpConnection.put(`${projectBaseUrl}/optimize/v1/github/${id}`, payload);
export const deleteGithub = (id) => httpConnection.delete(`${projectBaseUrl}/optimize/v1/github/${id}`);
export const getGithubfileDownload = (id, env) =>
  httpConnection({
    method: 'GET',
    url: `${projectBaseUrl}/optimize/v1/github/${id}/download?environment=${env}`,
    responseType: 'blob',
  });
export const validateGitHub = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/github/validate`, payload);
export const isInstanceExistGitHub = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/github/instance/exist`, payload);
export const isRepositoryExistGitHub = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/github/repository/exist`, payload);
export const GitHubSyncPassword = (payload, id) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/github/sync/${id}`, payload);
export const createGitHubInstance = (payload) => httpConnection.post(`${projectBaseUrl}/optimize/v1/github`, payload);
export const updateGithubInstance = (payload, id) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/github/updateGitHubInstance/${id}`, payload);
export const validateGitHubRepository = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/github/getGitHubRepo`, payload);
export const getOrganizationList = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/github/organizations`, payload);
// bitBucket
export const validateBitbucket = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/bitbucket/validate`, payload);
export const isInstanceExist = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/bitbucket/instance/exist`, payload);
export const getWorkSpaces = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/bitbucket/workspaces`, payload);
export const getProjects = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/bitbucket/projects`, payload);
export const isRepositoryExist = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/bitbucket/repository/exist`, payload);
export const validateFireFlink = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/bitbucket/fireFlink/validate`, payload);
export const createBitbucketInstance = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/bitbucket`, payload);
export const getBitBucketsall = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/bitbucket`);
export const getBitBucket = (id) => httpConnection.get(`${projectBaseUrl}/optimize/v1/bitbucket/${id}`);
export const deleteBitBucket = (id) => httpConnection.delete(`${projectBaseUrl}/optimize/v1/bitbucket/${id}`);
export const updateBitBucket = (payload, id) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/bitbucket/${id}`, payload);
export const syncPassword = (payload, id) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/bitbucket/sync/${id}`, payload);
export const validateRepository = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/bitbucket/getBitBucketRepo`, payload);

// git Lab
export const _getGitlabMandatoryField = (projectId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/defectLifecycleTemplate/template/GitLab`, {
    headers: { projectId: `${projectId}` },
  });
export const getGitlabList = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/gitlab`);
export const updateGitlab = (payload, id) => httpConnection.put(`${projectBaseUrl}/optimize/v1/gitlab/${id}`, payload);
export const deleteGitlab = (id) => httpConnection.delete(`${projectBaseUrl}/optimize/v1/gitlab/${id}`);
export const getGitlabfileDownload = (id) =>
  httpConnection({
    method: 'GET',
    url: `${projectBaseUrl}/optimize/v1/gitlab/${id}/download`,
    responseType: 'blob',
  });

// instance details for Sauce Lab
export const getSaucelabInstance = (username) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/saucelabs/files/${username}`);
export const uploadSaucelabInstance = (payload, username, sectionName) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/saucelabs/upload/${username}`, payload, {
    headers: { type: 'formdata', Sectionname: sectionName },
  });

// validation saucelab instance details
export const validateInstance = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/saucelabs/true`, payload);
export const createInstance = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/saucelabs/false`, payload);

//Git Hub
export const createGithubInstance = (payload) => httpConnection.post(`${projectBaseUrl}/optimize/v1/github`, payload);
export const createGitlabInstance = (payload) => httpConnection.post(`${projectBaseUrl}/optimize/v1/gitlab`, payload);

// lambdaTests
export const getAllLambdaTestsList = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/lambda-test`);
export const addLambdaTestInstance = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/lambda-test`, payload);
export const updateLambdaTestInstance = (payload, id) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/lambda-test/${id}`, payload);
export const deleteLambdaTestInstance = (id) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/lambda-test/${id}`);
export const getLambdaTestsBrowserList = () => {
  return httpConnection.get(`${projectBaseUrl}/optimize/v1/lambda-test/browsers-list`);
};

export const getLambdaTestMobileDeviceInfo = (id) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/lambda-test/devices/${id}`);

// instance details for Lambda Test
export const getLambdaTestInstance = (username) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/lambda-test/files/${username}`);
export const uploadLambdaTestInstance = (file, username, sectionName) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/lambda-test/upload/${username}`, file, {
    headers: { type: 'formdata', Sectionname: sectionName },
  });

//configuration timezone
export const getAllTimeZone = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/timeZoneConfiguration/projects`);
export const getTimeZoneList = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/timeZoneConfiguration/dropdown`);
export const getLicenseLevelTimeZone = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/timeZoneConfiguration/`);
export const updateLicenseLevelTimeZone = (payload, projectId) => {
  if (projectId) {
    return httpConnection.put(`${projectBaseUrl}/optimize/v1/timeZoneConfiguration/`, payload, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.put(`${projectBaseUrl}/optimize/v1/timeZoneConfiguration/`, payload);
  }
};

// configuration
export const getWaitConfigReq = (projectId) => {
  if (projectId) {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/waitConfiguration/`, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/waitConfiguration/`);
  }
};

export const putWaitConfigReq = (userId, payload, projectId) => {
  if (projectId) {
    return httpConnection.put(`${projectBaseUrl}/optimize/v1/waitConfiguration/${userId}`, payload, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.put(`${projectBaseUrl}/optimize/v1/waitConfiguration/${userId}`, payload);
  }
};
export const getDateTimeConfigReq = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/dateAndTime/`);

export const putDateTimeConfigReq = (userId, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/dateAndTime/${userId}`, payload);

export const getResultConfigReq = (projectId) => {
  if (projectId) {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/resultConfig/all`, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/resultConfig/all`);
  }
};

export const putResultConfigReq = (userId, payload, projectId) => {
  if (projectId) {
    return httpConnection.put(`${projectBaseUrl}/optimize/v1/resultConfig/${userId}`, payload, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.put(`${projectBaseUrl}/optimize/v1/resultConfig/${userId}`, payload);
  }
};
export const getViewConfigReq = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/viewConfiguration/`);
export const putViewConfigReq = (userId, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/viewConfiguration/selectColumns/${userId}`, payload);
// export const postEmailConfigReq = (payload) => httpConnection.post(`${projectBaseUrl}/optimize/v1/emailGroup/`, payload);
export const postEmailConfigReq = (projectId, payload) => {
  if (projectId) {
    return httpConnection.post(`${projectBaseUrl}/optimize/v1/emailGroup/`, payload, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.post(`${projectBaseUrl}/optimize/v1/emailGroup/`, payload);
  }
};
export const getEmailConfigReq = (projectId) => {
  if (projectId) {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/emailGroup/`, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/emailGroup/`);
  }
};
export const deleteEmailConfigReq = (id) => httpConnection.delete(`${projectBaseUrl}/optimize/v1/emailGroup/${id}`);
export const putEmailConfigReq = (userId, payload, projectId) => {
  return httpConnection.put(`${projectBaseUrl}/optimize/v1/emailGroup/${userId}`, payload, {
    headers: { projectId: `${projectId}` },
  });
};

export const getHistoryConfigReq = (projectId) => {
  if (projectId) {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/historyConfig/`, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/historyConfig/`);
  }
};

export const putHistoryConfigReq = (userId, payload, projectId) => {
  if (projectId) {
    return httpConnection.put(`${projectBaseUrl}/optimize/v1/historyConfig/${userId}`, payload, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.put(`${projectBaseUrl}/optimize/v1/historyConfig/${userId}`, payload);
  }
};
export const getScreenVideoReq = (projectId) => {
  if (projectId) {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/screenShotVideoConfig/`, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/screenShotVideoConfig/`);
  }
};
export const deleteScreenShotReq = (projectId, LicenseId) => {
  if (projectId) {
    return httpConnection.delete(`${projectBaseUrl}/optimize/v1/screenShotVideoConfig/Screenshots/${projectId}`, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.delete(`${projectBaseUrl}/optimize/v1/screenShotVideoConfig/Screenshots/${LicenseId}`);
  }
};
export const deleteVideoReq = (projectId, LicenseId) => {
  if (projectId) {
    return httpConnection.delete(`${projectBaseUrl}/optimize/v1/screenShotVideoConfig/Videos/${projectId}`, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.delete(`${projectBaseUrl}/optimize/v1/screenShotVideoConfig/Videos/${LicenseId}`);
  }
};

export const putScreenVideoReq = (userId, payload, projectId) => {
  if (projectId) {
    return httpConnection.put(`${projectBaseUrl}/optimize/v1/screenShotVideoConfig/${userId}`, payload, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.put(`${projectBaseUrl}/optimize/v1/screenShotVideoConfig/${userId}`, payload);
  }
};
export const getElementConfig = (projectId) => {
  if (projectId) {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/elementConfig/elementTypes?projectType=Web`, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/elementConfig/elementTypes?projectType=Web`);
  }
};
export const getElementAndroidConfig = (projectId) => {
  if (projectId) {
    return httpConnection.get(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementTypes?platform=Android&appType=Native&projectType=Mobile`,
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.get(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementTypes?platform=Android&appType=Native&projectType=Mobile`
    );
  }
};
export const getElementIOSConfig = (projectId) => {
  if (projectId) {
    return httpConnection.get(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementTypes?platform=iOS&appType=Native&projectType=Mobile`,
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.get(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementTypes?platform=iOS&appType=Native&projectType=Mobile`
    );
  }
};
export const getSFDCConfig = (projectId) => {
  if (projectId) {
    return httpConnection.get(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementTypes?platform=Android %26 iOS&appType=N/A&projectType=Salesforce`,
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.get(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementTypes?platform=Android %26 iOS&appType=N/A&projectType=Salesforce`
    );
  }
};
export const postElementConfig = (projectId, payload) => {
  if (projectId) {
    return httpConnection.post(`${projectBaseUrl}/optimize/v1/elementConfig/elementType?projectType=Web`, payload, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.post(`${projectBaseUrl}/optimize/v1/elementConfig/elementType?projectType=Web`, payload);
  }
};
// export const postElementConfig = (payload) => httpConnection.post(`${projectBaseUrl}/optimize/v1/elementConfig/elementType?projectType=Web`, payload);
export const deleteElementConfig = (projectId, userId) => {
  if (projectId) {
    return httpConnection.delete(`${projectBaseUrl}/optimize/v1/elementConfig/elementType/${userId}?projectType=Web`, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.delete(`${projectBaseUrl}/optimize/v1/elementConfig/elementType/${userId}?projectType=Web`);
  }
};

export const putElementConfig = (userId, payload, projectId) => {
  if (projectId) {
    return httpConnection.put(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementType/${userId}?projectType=Web`,
      payload,
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.put(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementType/${userId}?projectType=Web`,
      payload
    );
  }
};
export const putReorderElementConfig = (payload, projectId) => {
  if (projectId) {
    return httpConnection.put(`${projectBaseUrl}/optimize/v1/elementConfig/elementType?projectType=Web`, payload, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.put(`${projectBaseUrl}/optimize/v1/elementConfig/elementType?projectType=Web`, payload);
  }
};
export const postSFDCConfig = (projectId, payload) => {
  if (projectId) {
    return httpConnection.post(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementType?projectType=Salesforce&platform=Android %26 iOS&appType=N/A`,
      payload,
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.post(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementType?projectType=Salesforce&platform=Android %26 iOS&appType=N/A`,
      payload
    );
  }
};
export const putSFDCConfig = (userId, payload, projectId) => {
  if (projectId) {
    return httpConnection.put(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementType/${userId}?projectType=Salesforce&platform=Android %26 iOS&appType=N/A`,
      payload,
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.put(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementType/${userId}?projectType=Salesforce&platform=Android %26 iOS&appType=N/A`,
      payload
    );
  }
};
export const deleteSFDCConfig = (projectId, userId) => {
  if (projectId) {
    return httpConnection.delete(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementType/${userId}?projectType=Salesforce&platform=Android %26 iOS&appType=N/A`,
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.delete(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementType/${userId}?projectType=Salesforce&platform=Android %26 iOS&appType=N/A`
    );
  }
};
export const postAndroidConfig = (projectId, payload) => {
  if (projectId) {
    return httpConnection.post(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementType?projectType=Mobile&platform=Android&appType=Native`,
      payload,
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.post(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementType?projectType=Mobile&platform=Android&appType=Native`,
      payload
    );
  }
};
export const putAndroidConfig = (userId, payload, projectId) => {
  if (projectId) {
    return httpConnection.put(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementType/${userId}?projectType=Mobile&platform=Android&appType=Native`,
      payload,
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.put(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementType/${userId}?projectType=Mobile&platform=Android&appType=Native`,
      payload
    );
  }
};
export const deleteAndroidConfig = (projectId, userId) => {
  if (projectId) {
    return httpConnection.delete(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementType/${userId}?projectType=Mobile&platform=Android&appType=Native`,
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.delete(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementType/${userId}?projectType=Mobile&platform=Android&appType=Native`
    );
  }
};
export const postIOSConfig = (projectId, payload) => {
  if (projectId) {
    return httpConnection.post(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementType?projectType=Mobile&platform=iOS&appType=Native`,
      payload,
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.post(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementType?projectType=Mobile&platform=iOS&appType=Native`,
      payload
    );
  }
};
export const putIOSConfig = (userId, payload, projectId) => {
  if (projectId) {
    return httpConnection.put(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementType/${userId}?projectType=Mobile&platform=iOS&appType=Native`,
      payload,
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.put(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementType/${userId}?projectType=Mobile&platform=iOS&appType=Native`,
      payload
    );
  }
};
export const deleteIOSConfig = (projectId, userId) => {
  if (projectId) {
    return httpConnection.delete(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementType/${userId}?projectType=Mobile&platform=iOS&appType=Native`,
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.delete(
      `${projectBaseUrl}/optimize/v1/elementConfig/elementType/${userId}?projectType=Mobile&platform=iOS&appType=Native`
    );
  }
};
export const getWebLocatorsConfig = (projectId) => {
  if (projectId) {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/locatorsConfig/locatorTypes?projectType=Web`, {
      headers: { projectId: `${projectId}` },
    });
  } else {
    return httpConnection.get(`${projectBaseUrl}/optimize/v1/locatorsConfig/locatorTypes?projectType=Web`);
  }
};
export const putWebLocConfig = (userId, payload, projectId) => {
  if (projectId) {
    return httpConnection.put(
      `${projectBaseUrl}/optimize/v1/locatorsConfig/locatorTypes/${userId}?projectType=Web`,
      payload,
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.put(
      `${projectBaseUrl}/optimize/v1/locatorsConfig/locatorTypes/${userId}?projectType=Web`,
      payload
    );
  }
};
export const getAndroidLocators = (projectId) => {
  if (projectId) {
    return httpConnection.get(
      `${projectBaseUrl}/optimize/v1/locatorsConfig/locatorTypes?projectType=Mobile&platform=Android&appType=Native`,
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.get(
      `${projectBaseUrl}/optimize/v1/locatorsConfig/locatorTypes?projectType=Mobile&platform=Android&appType=Native`
    );
  }
};
export const putAndroidLocators = (userId, payload, projectId) => {
  if (projectId) {
    return httpConnection.put(
      `${projectBaseUrl}/optimize/v1/locatorsConfig/locatorTypes/${userId}?projectType=Mobile&platform=Android&appType=Native`,
      payload,
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.put(
      `${projectBaseUrl}/optimize/v1/locatorsConfig/locatorTypes/${userId}?projectType=Mobile&platform=Android&appType=Native`,
      payload
    );
  }
};
export const getIOSLocators = (projectId) => {
  if (projectId) {
    return httpConnection.get(
      `${projectBaseUrl}/optimize/v1/locatorsConfig/locatorTypes?projectType=Mobile&platform=iOS&appType=Native`,
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.get(
      `${projectBaseUrl}/optimize/v1/locatorsConfig/locatorTypes?projectType=Mobile&platform=iOS&appType=Native`
    );
  }
};
export const putIOSLocators = (userId, payload, projectId) => {
  if (projectId) {
    return httpConnection.put(
      `${projectBaseUrl}/optimize/v1/locatorsConfig/locatorTypes/${userId}?projectType=Mobile&platform=iOS&appType=Native`,
      payload,
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.put(
      `${projectBaseUrl}/optimize/v1/locatorsConfig/locatorTypes/${userId}?projectType=Mobile&platform=iOS&appType=Native`,
      payload
    );
  }
};
export const getSFDCLocators = (projectId) => {
  if (projectId) {
    return httpConnection.get(
      `${projectBaseUrl}/optimize/v1/locatorsConfig/locatorTypes?projectType=Salesforce&platform=Android %26 iOS&appType=N/A`,
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.get(
      `${projectBaseUrl}/optimize/v1/locatorsConfig/locatorTypes?projectType=Salesforce&platform=Android %26 iOS&appType=N/A`
    );
  }
};
export const putSFDCLocators = (userId, payload, projectId) => {
  if (projectId) {
    return httpConnection.put(
      `${projectBaseUrl}/optimize/v1/locatorsConfig/locatorTypes/${userId}?projectType=Salesforce&platform=Android %26 iOS&appType=N/A`,
      payload,
      { headers: { projectId: `${projectId}` } }
    );
  } else {
    return httpConnection.put(
      `${projectBaseUrl}/optimize/v1/locatorsConfig/locatorTypes/${userId}?projectType=Salesforce&platform=Android %26 iOS&appType=N/A`,
      payload
    );
  }
};
export const getMemoryConfigReq = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/memoryConfiguration/`);

export const putMemoryConfigReq = (payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/memoryConfiguration/distributeMemory`, payload);

//Configuration Labels
export const getAllLabelsByProjectId = (projectId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/projectlabels/getAll?projectId=${projectId}`);
export const createLabel = (payLoad, projectId) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/projectlabels/`, payLoad, {
    headers: { projectId: `${projectId}` },
  });
export const updateLabel = (payload, scriptId, projectId) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/projectlabels/${scriptId}`, payload, {
    headers: { projectId: `${projectId}` },
  });
export const deleteLabel = (scriptId) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/projectlabels/${scriptId}`);

//Dashboard
export const allProjectDashboard = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/project/dashboard/all`);
export const allProjectDashboardMemory = () =>
  httpConnection.get(`${executionBaseUrl}/optimize/v1/project/dashboard/storage/all`);
export const allProjectDashboardConsumeStatistics = (param) =>
  httpConnection.get(`${executionBaseUrl}/optimize/v1/project/dashboard/storage/stats/All?data=${param}`);
export const getIndividualDashboardConsumeStatistics = (projectID, param) =>
  httpConnection.get(`${executionBaseUrl}/optimize/v1/project/dashboard/storage/stats/${projectID}?data=${param}`);
export const getUsersSectionChartData = () =>
  httpConnection.get(`${managementBaseUrl}/optimize/v1/user/dashboard/users`);
export const getProjectsSectionChartData = () =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/project/dashboard/all`);
export const getClientsSectionChartData = () =>
  httpConnection.get(`${executionBaseUrl}/optimize/v1/client/dashboard/allClient`);
export const getClientsForOS = (osName) =>
  httpConnection.get(`${clientSystemConfigBaseUrl}/optimize/v1/client/update?os=${osName}`);
export const getIndividualProjectExecutionData = (projectId) =>
  httpConnection.get(
    `${executionBaseUrl}/optimize/v1/dashboard/project/${projectId}/results?archiveFlag=${getArchiveFlag()}`
  );
export const getIndividualDashboardData = (projectId) =>
  httpConnection.get(`${executionBaseUrl}/optimize/v1/dashboard/project/${projectId}?archiveFlag=${getArchiveFlag()}`);
export const getIndividualMemoryDashboardData = (projectId) =>
  httpConnection.get(`${executionBaseUrl}/optimize/v1/project/dashboard/storage/${projectId}`);
export const getIndividualProjectSuiteNames = () =>
  httpConnection.get(`${executionBaseUrl}/optimize/v1/dashboard/project/suite-names`);
export const getIndividualProjectSuiteResultDetails = (suiteId) =>
  httpConnection.get(`${executionBaseUrl}/optimize/v1/dashboard/project/suite-dashboard/${suiteId}`);
export const getIndividualProjectSuiteInfoGraph = () =>
  httpConnection.get(`${executionBaseUrl}/optimize/v1/dashboard/project/suite-info`);
export const getIndividualProjectScheduledExecution = () =>
  httpConnection.get(`${executionBaseUrl}/optimize/v1/dashboard/project/scheduled-execution/results`);

//JIRA API Integration
export const getAllJiraList = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/config/jira/all`);
export const addJiraAccount = (payload) => httpConnection.post(`${projectBaseUrl}/optimize/v1/config/jira`, payload);
export const getJiraBasedOnID = (id) => httpConnection.get(`${projectBaseUrl}/optimize/v1/config/jira/${id}`);
export const updateJiraBasedOnID = (payload, id) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/config/jira/${id}`, payload);
export const deleteJiraBasedOnID = (id) => httpConnection.delete(`${projectBaseUrl}/optimize/v1/config/jira/${id}`);

export const getAllProjectsForDomain = (domainName, email, apiToken) =>
  httpConnection.get(
    `${projectBaseUrl}/optimize/v1/config/jira/projects?userName=${email}&apiToken=${apiToken}&domain=${domainName}`
  );

export const getAllAssignableUsers = (projectId, domainName, email, apiToken) =>
  httpConnection.get(
    `${projectBaseUrl}/optimize/v1/config/jira/users?userName=${email}&apiToken=${apiToken}&domain=${domainName}&project=${projectId}`
  );
export const getMandatoryFieldForJIRA = (projectId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/defectLifecycleTemplate/template/Jira`, {
    headers: { projectId: `${projectId}` },
  });

export const postJiraIssue = (projectId, payload, sectionName) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/config/jira/issue`, payload, {
    headers: { type: 'formdata', projectId: `${projectId}`, Sectionname: `${sectionName}` },
  });

export const getAllIssueTypeForDomain = (domainName, email, apiToken, projType) =>
  httpConnection.get(
    `${projectBaseUrl}/optimize/v1/config/jira/issueType?userName=${email}&apiToken=${apiToken}&domain=${domainName}&projectKey=${projType}`
  );

export const getAllMetaDataBasedOnIssueType = (domainName, email, apiToken, issueType) =>
  httpConnection.get(
    `${projectBaseUrl}/optimize/v1/config/jira/issueType/metaData?userName=${email}&apiToken=${apiToken}&domain=${domainName}&issueType=${issueType}`
  );

// JIRA MAPING
export const getAllJiraProjects = (userName, apiToken, domain) =>
  httpConnection.get(
    `${projectBaseUrl}/optimize/v1/config/jira/mapScript/projects?userName=${userName}&apiToken=${apiToken}&domain=${domain}`
  );
export const getAllJiraIds = ({ userName, apiToken, domain, pageSize, scriptPrefix, moduleId, searchedText }) =>
  httpConnection.get(
    `${projectBaseUrl}/optimize/v1/config/jira/ids/${pageSize}?userName=${userName}&apiToken=${apiToken}&domain=${domain}&scriptPrefix=${scriptPrefix}&moduleId=${moduleId}&searchText=${searchedText}`
  );
export const mapScriptToJira = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/config/jira/mapScript`, payload);
export const getMappedJira = (scriptPrefix, moduleId) =>
  httpConnection.get(
    `${projectBaseUrl}/optimize/v1/config/jira/getMappedJira?scriptPrefix=${scriptPrefix}&moduleId=${moduleId}`
  );

// LInking JIRA Defect APIs

export const getAllJiraLinks = ({
  userName,
  apiToken,
  domain,
  pageSize,
  scriptPrefix,
  moduleId,
  testCaseType,
  searchedText,
}) =>
  httpConnection.get(
    `${projectBaseUrl}/optimize/v1/config/jira/defects/${pageSize}?userName=${userName}&apiToken=${apiToken}&domain=${domain}&scriptPrefix=${scriptPrefix}&moduleId=${moduleId}&testCaseType=${testCaseType}&searchText=${searchedText}`
  );

//SLACK API Integration
export const _getTemplateForEmailConfigReq = (projectId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/defectLifecycleTemplate/template/Slack`, {
    headers: { projectId: `${projectId}` },
  });

export const getAllSlackInstance = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/slack/`);
export const deleteSlackInstance = (instancename, id) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/slack/deleteSlackInstance/${instancename}/${id}`);
export const createSlackInstance = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/slack/createSlackInstance`, payload);
export const updateSlackInstance = (payload, id) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/slack/updateSlackInstance/${id}`, payload);

export const postSlackIssue = (projectId, payload, sectionName) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/slack/post-message/post-media`, payload, {
    headers: { type: 'formdata', projectId: `${projectId}`, Sectionname: `${sectionName}` },
  });
export const validateAuthToken = (OauthToken) =>
  httpConnection.post(
    `${projectBaseUrl}/optimize/v1/slack/slackAppTokenVerification`,
    {},
    { headers: { OauthToken: `${OauthToken}` } }
  );
export const validateChannelId = (OauthToken, channelId) =>
  httpConnection.post(
    `${projectBaseUrl}/optimize/v1/slack/slackChannelIdVerification`,
    {},
    { headers: { OauthToken: `${OauthToken}`, channelId: `${channelId}` } }
  );
export const getSlackDetails = (id) => httpConnection.get(`${projectBaseUrl}/optimize/v1/slack/getSlackDetails/${id}`);
// external libraries
export const extLibJarFiles = (projectId, files) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/file/folder/programelement/false`, files, {
    headers: { projectId: `${projectId}` },
  });
export const getLibFilesReq = (projectId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/file/custom-jars`, {
    headers: { projectId: `${projectId}` },
  });
export const deleteextFileReq = (fileId, projectId) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/file/${fileId}`, {
    headers: { projectId: `${projectId}` },
  });

//plugins
export const downloadjenkinsFileReq = (payload) =>
  httpConnection({
    method: 'GET',
    url: `${executionBaseUrl}/optimize/v1/dashboard/download/jenkins`,
    responseType: 'blob',
    data: payload,
  });

export const downloadGithubFileReq = (userId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/gitlab/GITLAB1001/download`);

// httpConnection.post(`${executionBaseUrl}/optimize/v1/dashboard/download/storage`,payload,{headers: { "Content-Type": "application/json"}});

// History
export const getAllLocalHistory = (moduleId, scriptId) =>
  httpConnection.get(`${webserviceBaseUrl}/optimize/apis/history/all?scope=local`, {
    headers: { moduleId: `${moduleId}`, scriptId: `${scriptId}`, includeresponseonly: true },
  });
export const getAllGlobalHistory = (moduleId, scriptId) =>
  httpConnection.get(`${webserviceBaseUrl}/optimize/apis/history/all?scope=global`, {
    headers: { moduleId: `${moduleId}`, scriptId: `${scriptId}`, includeresponseonly: true },
  });
export const deleteHistoryReq = (executionId) =>
  httpConnection.delete(`${webserviceBaseUrl}/optimize/apis/history/${executionId}`);
export const getStepsDataByHistoryId = (historyId) =>
  httpConnection.get(`${webserviceBaseUrl}/optimize/apis/history/${historyId}`);

// Analytics Api
export const getAnalyticsReq = (payload) =>
  httpConnection.post(`${analyticsBaseUrl}/optimize/v1/analytics/search`, payload);
export const getAnalyticsTableData = (payload, tableType, from, to) =>
  httpConnection.post(`${analyticsBaseUrl}/optimize/v1/analytics/getScripts/${tableType}/${from}/${to}`, payload);
export const getComparisonSuiteReq = (para) =>
  httpConnection.get(
    `${analyticsBaseUrl}/optimize/v1/analytics/${para.licenseId}/${para.projectId}/${para.suiteName}/${para.executionId1}/${para.executionId2}/compare`
  );
export const getExecutionScriptsResult = (licenseId, projectId, scriptId, executionId) =>
  httpConnection.get(
    `${analyticsBaseUrl}/optimize/v1/analytics/${licenseId}/${projectId}/${scriptId}/${executionId}/scriptDetails`
  );
export const getDefectTableDetailsReq = (payLoad, from, to, tableType, tableContent) =>
  httpConnection.post(
    `${analyticsBaseUrl}/optimize/v1/analytics/getdefectTabledetails/${from}/${to}?tableType=${tableType}&tableContent=${tableContent}`,
    payLoad
  );
export const getAllDefectsList = (payload, from, to) =>
  httpConnection.post(`${analyticsBaseUrl}/optimize/v1/analytics/getdefectdetails/${from}/${to}`, payload);

export const getAutomationSuiteExecutionIds = (data) =>
  httpConnection.post(`${analyticsBaseUrl}/optimize/v1/analytics/executionIds`, data);

export const getAutomationSuiteMachineDetails = (payload) =>
  httpConnection.post(`${analyticsBaseUrl}/optimize/v1/analytics/machine-details`, payload);
// Licenses
//Payment Request
export const getPaymentURL = (payload, licenseId) =>
  httpConnection.put(`${managementBaseUrl}/optimize/v1/license/paymentUrl/${licenseId}`, payload);
export const getTransactionStatus = (orderId) =>
  httpConnection.get(`${managementBaseUrl}/optimize/v1/license/transactionStatus/${orderId}`);

export const updateTransactionDetails = (orderId, payload) =>
  httpConnection.put(`${managementBaseUrl}/optimize/v1/license/transaction-details/${orderId}`, payload);

export const getPendingTransactions = () =>
  httpConnection.get(`${managementBaseUrl}/optimize/v1/user/check-pending-transactions`);

export const getFailedTransactions = () =>
  httpConnection.get(`${managementBaseUrl}/optimize/v1/user/failed-transactions`);

export const getAllLicensesForUser = () => httpConnection.get(`${managementBaseUrl}/optimize/v1/license/licenses`);

export const getLicenseByLicenseId = (licenseId) =>
  httpConnection.get(`${managementBaseUrl}/optimize/v1/license/${licenseId}`);

export const addLicense = (payload) => httpConnection.post(`${managementBaseUrl}/optimize/v1/user/addLicense`, payload);

export const updateLicense = (licenseId, payload) =>
  httpConnection.put(`${managementBaseUrl}/optimize/v1/license/${licenseId}`, payload);

export const updateUserData = (userId, payload) =>
  httpConnection.put(`${managementBaseUrl}/optimize/v1/user/updateUserData/${userId}`, payload);

export const getLicenseTypes = () => httpConnection.get(`${managementBaseUrl}/optimize/v1/user/license-types`);

export const checkIfUserSuperAdminOfCBasic = () =>
  httpConnection.get(`${managementBaseUrl}/optimize/v1/license/isSuperAdminOfCBasic`);

export const getUserByEmailId = (emailId) =>
  httpConnection.get(`${managementBaseUrl}/optimize/v1/user/emailId?emailId=${emailId}`);

//payment History
export const getAllPaymentHistory = () => httpConnection.get(`${managementBaseUrl}/optimize/v1/user/transactions`);

export const getAlltransactionDetails = (payload) =>
  httpConnection.get(`${managementBaseUrl}/optimize/v1/license/transaction-details/licenses`, {
    headers: { licenseId: `${payload}` },
  });

// User data
export const getUserData = () => httpConnection.get(`${executionBaseUrl}/optimize/v1/dashboard/execution/user`);

//clone and Export
export const cloneReq = (id, parentid, type, projectId, payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/clone/${parentid}/${id}/${type}`, payload, {
    headers: { projectId: `${projectId}` },
  });
export const cloneWebServiceStepReq = (id) =>
  httpConnection.post(`${webserviceBaseUrl}/optimize/apis/history/${id}/clone`, {});

// move script or module
export const moveResourceReq = (sourceId, destinationId, action, headerData) => {
  return httpConnection.post(`${projectBaseUrl}/optimize/v1/move/${sourceId}/${destinationId}/${action}`, '', {
    headers: headerData,
  });
};

// Gitlab API's
export const getGitlabInstanceReq = (id) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/gitInstance/${id ? id : ''}`);
export const deleteGitlabInstanceReq = (id) => httpConnection.delete(`${projectBaseUrl}/optimize/v1/gitInstance/${id}`);
export const createGitlabInstanceReq = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/gitInstance/`, payload);
export const updateGitlabInstanceReq = (id, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/gitInstance/${id}`, payload);
export const getGitlabProjectsReq = (personalToken) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/gitIssue/?owned=true&per_page=100`, {
    headers: { personalToken: `${personalToken}` },
  });
export const getGitlabMetaDataReq = (personalToken, projectId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/gitIssue/metadata/${projectId}?per_page=100`, {
    headers: { personalToken: `${personalToken}` },
  });
export const createGitlabIssueReq = (personalToken, projectId, assigneeId, milestoneId, payload, sectionName) =>
  httpConnection.post(
    `${projectBaseUrl}/optimize/v1/gitIssue/${projectId}?assigneeId=${assigneeId}${
      milestoneId ? '&milestoneId=' + milestoneId : ''
    }`,
    payload,
    { headers: { type: 'formdata', personalToken: `${personalToken}`, Sectionname: sectionName } }
  );

// defect management
export const getTemplateForEmailConfigReq = (projectId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/defectLifecycleTemplate/config/all`, {
    headers: { projectId: `${projectId}` },
  });
export const getDefectsStatistic = () => httpConnection.get(`${projectBaseUrl}/optimize/v1/defects/platform/statistic`);
export const createDefectReq = (projectId, payload, sectionName) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/defects/`, payload, {
    headers: { type: 'formdata', projectId: `${projectId}`, Sectionname: sectionName },
  });
export const getAllDefectsWithPaginationReq = (projectId, page, size) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/defects/?pageNo=${page}&pageSize=${size}`, {
    headers: { projectId: `${projectId}` },
  });
export const getSingleDefectReq = (projectId, defectId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/defects/${defectId}`, {
    headers: { projectId: `${projectId}` },
  });
export const updateDefectReq = (projectId, payload, defectId, sectionNam) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/defects/${defectId}`, payload, {
    headers: { type: 'formdata', projectId: `${projectId}`, Sectionname: `${sectionNam}` },
  });
export const deleteMultipleFilesReq = (projectId, payload) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/defects/`, {
    data: payload,
    headers: { projectId: projectId },
  });
export const getScriptSpecificDefectsReq = (projectId, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/defects/`, payload, {
    headers: { projectId: `${projectId}` },
  });

export const updateDefaultLifeCycleReq = (id, projectId) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/defectLifecycleTemplate/?templateId=${id}`, {
    headers: { projectId: `${projectId}` },
  });
export const updateDefaultdetailsReq = (id, projectId) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/defectDetailsTemplate/?templateId=${id}`, {
    headers: { projectId: `${projectId}` },
  });
export const getStepsDataByVarId = (id, projectId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/variable/${id}`, {
    headers: { projectId: `${projectId}` },
  });

export const getIndividualScriptResult = (scriptId) =>
  httpConnection.get(
    `${resultBaseUrl}/optimize/v1/executionResponse/result/script/${scriptId}?archiveFlag=${getArchiveFlag()}`
  );

//defects comments
export const getAllComments = (defectId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/defects/${defectId}/comments`);
export const createComments = (defectId, payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/defects/${defectId}/comments`, payload);
export const deleteComments = (defectId, commentsid) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/defects/${defectId}/${commentsid}`);
export const updateComments = (defectId, id, payload, actions) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/defects/${defectId}/comments/${id}?action=${actions}`, payload);

//import and export

export const exportProject = (projectId, payload) =>
  httpConnection({
    method: 'POST',
    url: `${projectBaseUrl}/optimize/v1/projects/export/`,
    responseType: 'blob',
    data: payload,
    headers: {
      projectId,
    },
  });

export const importProject = (projectId, payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/projects/import/${projectId}`, payload, {
    headers: { type: 'formdata' },
  });

// Import and export V2
export const exportProjectV2 = (projectId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/resources/export/v2/${projectId}`, {
    responseType: 'blob',
  });

export const importProjectV2 = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/resources/import/v2`, payload, {
    headers: {
      type: 'formdata',
    },
  });

//import and export Manual Test Case and templates
export const importTestcase = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/scriptTemplate/importTestcase`, payload, {
    headers: { type: 'formdata' },
  });
export const validatedTestcaseImport = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/scriptTemplate/importValidatedTestcases`, payload, {
    headers: { type: 'formdata' },
  });

export const exportManualTestCaseTemplate = (payloadData) =>
  httpConnection({
    method: 'POST',
    url: `${projectBaseUrl}/optimize/v1/scriptTemplate/downloadSampleData?templateId=${payloadData?.templateId}`,
    responseType: 'blob',
    data: payloadData?.payload,
  });

export const getImportProgressPercent = (importId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/scriptTemplate/import-progress?importedId=${importId}`);

export const exportManualTestCaseFiles = (moduleId) => {
  let url = isEmptyValue(moduleId)
    ? '/optimize/v1/scriptTemplate/exportTestCase'
    : `/optimize/v1/scriptTemplate/exportTestCase?moduleIds=${moduleId}`;
  return httpConnection({
    method: 'GET',
    url: `${projectBaseUrl}${url}`,
    responseType: 'blob',
  });
};

//test data file
export const getAllTestDataFileReq = (projectId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/file/search/${projectId}/all`);

export const createCurlReq = (payload, projectId, moduleId, scriptId, WEBSERVICE) =>
  httpConnection.post(`${webserviceBaseUrl}/optimize/apis/curl`, payload, {
    headers: {
      type: 'formdata',
      projectId: `${projectId}`,
      scriptId: `${scriptId}`,
      moduleId: `${moduleId}`,
      Sectionname: WEBSERVICE,
    },
  });

// Export Defects API

export const exportDefects = (projectId) =>
  httpConnection({
    method: 'GET',
    url: `${projectBaseUrl}/optimize/v1/defects/exportDefect`,
    responseType: 'blob',
    headers: { projectId },
  });
export const getOnGoingExecutionReq = () =>
  httpConnection.get(`${executionBaseUrl}/optimize/v1/dashboard/execution/onGoingExecutionDetails`);

// Folder by ProjectID
export const getFolderByProjectId = (projectId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/folders/${projectId}/manualSuite`);

// Add Files to Cloud Folder
export const addFilesToCloudFolder = (folderId, payload, executionId, sectionName) =>
  httpConnection.post(
    `${projectBaseUrl}/optimize/v1/file/folder/${folderId}/manualsuite:${executionId}/true`,
    payload,
    { headers: { type: 'formdata', Sectionname: sectionName } }
  );

// Delete Images from Cloud API
export const deleteImagesFromCloudFolder = (fileId, folderId) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/file/cloud/${fileId}`, {
    headers: { folderId: `${folderId}` },
  });

// Download Image
export const downloadImagesFromCloudFolder = (fileID) =>
  httpConnection({
    method: 'GET',
    url: `${projectBaseUrl}/optimize/v1/file/download/cloud/${fileID}`,
    responseType: 'blob',
  });

// Parent Level Authorization
export const updateParentAuthorization = (payload, headers) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/parent-level-authorization`, payload, { headers: headers });
//Custom DashBoard
export const getAllDashBoard = (projectId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/dashboard/`, {
    headers: { projectId: `${projectId}` },
  });
export const getAllUesrsList = (projectId) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/dashboard/getUsersByProjectId`, {
    headers: { projectId: `${projectId}` },
  });
export const getAllUesrsDashBoard = (projectId, type) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/dashboard/getUsersByProjectId?type=${type}`, {
    headers: { projectId: `${projectId}` },
  });
export const createDashBoard = (payload) => httpConnection.post(`${projectBaseUrl}/optimize/v1/dashboard/`, payload);
export const updateDashboard = (id, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/dashboard/${id}`, payload);

export const deleteDashBoard = (id, name) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/dashboard/${id}?name=${name}`);
//custom Graph
export const getDropDownOptions = (params) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/dropdownoptions/customGraphDropDown?licenseFeature=${params}`);
export const getGraphsOnDashboard = (id) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/custom-graphs/dashboard/${id}`);
export const getAllMachines = (type) =>
  httpConnection.get(`${projectBaseUrl}/optimize/v1/custom-graphs/getAllSuitesAndMachines?executionType=${type}`);
export const getSearchedGraphData = (payload, label) => {
  if (label === 'Scripts') {
    return httpConnection.post(`${analyticsBaseUrl}/optimize/v1/analytics/search`, payload);
  } else if (label === 'Executions') {
    return httpConnection.post(`${projectBaseUrl}/optimize/v1/custom-graphs/searchexecutions`, payload);
  }
};

export const createCustomGraph = (payload) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/custom-graphs/`, payload);

export const updateCustomGraph = (payload, id) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/custom-graphs/${id}`, +payload);

export const deleteGraph = (id, name, type) =>
  httpConnection.delete(`${projectBaseUrl}/optimize/v1/custom-graphs/dashboard/${id}?name=${name}&type=${type}`);
export const editCustomGraph = (payload, graphId) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/custom-graphs/${graphId}`, payload);

// Chatbot API's
export const getChatbotMessage = (payload) => httpConnection.post(`${chatbotBaseUrl}/predict`, payload);

//Bulk labeling
export const updateBulkLabeling = (payload, action) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/scripts/modules/NA/updateLabels?action=${action}`, payload);

//cloud
export const getUpdatedExecutionData = (payload) => {
  return httpConnection.get(
    `${executionBaseUrl}/optimize/v1/dashboard/execution/${payload.executionId}/${payload.scriptId}/${payload.runId}`
  );
};

// NlP suggestion AI API's

export const sendNlpDetails = (payload) => {
  return httpConnection.post(`${nlpDetailsBaseUrl}/details`, payload);
};

//AutoTagging AI API's
export const getAutotaggingAI = (payload) => {
  return httpConnection.post(`${autoTaggingBaseUrl}/get_data`, payload);
};
//Api for training AI Autotagging model
export const trainAutoTagModel = (payload) => {
  return httpConnection.post(`${trainAutoTagModelBaseUrl}/auto_tag`, payload);
};

//Api for deleting the step from DB AI collection
export const deleteAutotaggingStep = (payload) => {
  return httpConnection.post(`${autotaggingDeleteStepBaseUrl}/delete`, payload);
};

//Api for Excel Implementation
export const getExcelFile = (fileID) => {
  return httpConnection.get(`${projectBaseUrl}/optimize/v1/file/testdata/readfile/${fileID}`);
};

export const updateExcelFile = (fileID, payload) =>
  httpConnection.put(`${projectBaseUrl}/optimize/v1/file/testdata/writefile/${fileID}`, payload);

export const createExcelFile = (payload, headers) =>
  httpConnection.post(`${projectBaseUrl}/optimize/v1/file/testdata/create-file`, payload, {
    headers: headers,
  });

export const importCurlToJson = (payload) => {
  return httpConnection
    .post(`${htmlResultBaseUrl}/curl-import`, payload, {
      headers: { type: 'text' },
    })
    .then((response) => {
      return response;
    });
};
