import { Icon, Modal } from 'fireflink-ui';
import React from 'react';
import style from './ExceptionSuggestion.module.scss';
import ExceptionSuggestion from './ExceptionSuggestion';
import { colors } from '@src/css_config/colorConstants';
import { EXCEPTION_SUGGESTION } from '@src/common/ui-constants';

const ExceptionSuggestionModal = ({ exceptionSuggestion, isOpen, onClose, openFrom }) => {
  const { SUGGESTIONS_TO_FIX, RESULT_PAGE } = EXCEPTION_SUGGESTION;

  return (
    <Modal
      isOpen={isOpen}
      style={{
        content: { width: '1166px', maxWidth: '1166px', height: '533px' },
        overlay: { background: colors.ff_white_shade, zIndex: 9999 },
      }}
    >
      <div className={style['suggestion-header']}>
        <div className={style['suggestion-title']}>{SUGGESTIONS_TO_FIX}</div>
        {openFrom === RESULT_PAGE ? (
          <Icon className={style['close-icon']} name={'close_blue'} hoverEffect={true} onClick={() => onClose()} />
        ) : (
          <Icon className={style['minimize-icon']} height={14} width={14} name={'collapse'} onClick={() => onClose()} />
        )}
      </div>
      <ExceptionSuggestion exceptionSuggestion={exceptionSuggestion} />
    </Modal>
  );
};

export default ExceptionSuggestionModal;
