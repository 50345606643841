import { Accordion, Icon } from 'fireflink-ui';
import React, { useState } from 'react';
import style from './ExceptionSuggestion.module.scss';
import ExceptionSuggestionModal from './ExceptionSuggestionModal';
import { EXCEPTION_SUGGESTION } from '@src/common/ui-constants';
import { isEmptyObject } from '@src/util/common_utils';

export const ExceptionSuggestion = ({ exceptionSuggestion, openFrom }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { POSSIBLE_REASON, HOW_TO_FIX, NO_SUGGESTION_AVAILABLE, EXCEPTION_COLON, STEP_RESULT } = EXCEPTION_SUGGESTION;

  const possibleFix = (value) => {
    return (
      <div>
        <div className={style['possiblefix-label']}>{POSSIBLE_REASON}</div>
        {value?.reasons.map((reason, index) => (
          <div className={style['possiblefix-value']} key={index}>
            {'- ' + reason}
          </div>
        ))}

        <div className={style['possiblefix-label']}>{HOW_TO_FIX}</div>
        {value?.possibleFixes.map((fix, index) => (
          <div className={style['possiblefix-value']} key={index}>
            {'- ' + fix?.name + ': ' + fix?.value}
          </div>
        ))}
      </div>
    );
  };

  const accordianContent = () => {
    return (
      <div className={style['accordian-content']}>
        {exceptionSuggestion?.possibleIssues.map((value, index) => (
          <Accordion
            key={index}
            variant="minimal"
            headerTitle={index + 1 + '. ' + value?.name}
            accordionContent={possibleFix(value)}
            defaultState={openFrom !== STEP_RESULT}
          />
        ))}
      </div>
    );
  };

  const exceptionSuggestionContent = () => {
    return (
      <div className={style['parent-container']}>
        <div className={style['exception-container']}>
          <div className={style['exception-header']}>
            <span className={style['exception-label']}>{EXCEPTION_COLON} </span>
            <span className={style['exception-value']}>{exceptionSuggestion?.exceptionName}</span>
          </div>
          {openFrom === STEP_RESULT && (
            <div className={style['expand-icon']}>
              <Icon height={14} width={14} name="expand" onClick={() => setIsModalOpen(true)} />
            </div>
          )}
        </div>
        {openFrom !== STEP_RESULT ? (
          <div id="journal-scroll" className={style['suggestion-content']}>
            {accordianContent()}
          </div>
        ) : (
          accordianContent()
        )}

        {isModalOpen && (
          <ExceptionSuggestionModal
            exceptionSuggestion={exceptionSuggestion}
            openFrom={openFrom}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </div>
    );
  };
  return !exceptionSuggestion || isEmptyObject(exceptionSuggestion) ? (
    <div className={style['no-suggestions']}>{NO_SUGGESTION_AVAILABLE}</div>
  ) : (
    exceptionSuggestionContent()
  );
};
export default ExceptionSuggestion;
