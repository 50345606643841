import React, { useState, useEffect, Fragment } from 'react';
import './custom-filter.scss';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { ChevronUpIcon } from '@heroicons/react/solid';
import DateTimePicker from '@src/pages/common/date_time_picker';
import moment from 'moment';
import { isValidDateFormatDDMMYYYY, convertDate } from '../../analytics/common/util';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { ReactComponent as CalenderIcon } from '@assets/analytics/Calender.svg';
import { DASHBOARD_CONSTANTS } from '@src/common/ui-constants';
import { FormGroup } from '@material-ui/core';
import { Checkbox } from 'fireflink-ui';
import Tooltip from '@material-ui/core/Tooltip';
import { colors } from '@src/css_config/colorConstants';
import { getTruncatedText } from '@util/common_utils';
let timePeriods = [
  {
    name: 'Date',
    label: 'Last 7 Days',
    value: '7',
  },
  {
    name: 'Date',
    label: 'Last Month',
    value: '30',
  },
  {
    name: 'Date',
    label: 'Last 3 Months',
    value: '90',
  },
  {
    name: 'Date',
    label: 'Last 12 Months',
    value: '365',
  },
];

let startDate = '',
  endDate = '';
export const CustomFilterTimePeriod = (props) => {
  const [expandTimePeriod, setExpandTimePeriod] = useState(true);
  const [currentTimePeriod, setCurretTimePeriod] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: null,
    end: null,
  });

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedLabelsOptions, setSelectedLabelsOptions] = useState([]);
  const [expandScriptType, setExpandScriptType] = useState(false);
  const [expandLabelsType, setExpandLabelsType] = useState(false);

  const toggleDropdown = (dropdownType) => {
    setExpandScriptType(dropdownType === 'scriptType' ? !expandScriptType : false);
    setExpandLabelsType(dropdownType === 'labelsType' ? !expandLabelsType : false);
    setExpandTimePeriod(dropdownType === 'timePeriod' ? !expandTimePeriod : false);
  };

  const handleScriptTypeToggle = () => toggleDropdown('scriptType');
  const handleLabelsToggle = () => toggleDropdown('labelsType');
  const handleTimePeriodToggle = () => toggleDropdown('timePeriod');

  const handleOptionToggle = (e, option, selectedOptions, setSelectedOptions, filterLabel) => {
    const { value } = e.target;
    let updatedFilterData;
  
    if (selectedOptions.includes(value)) {
      updatedFilterData = selectedOptions.filter((item) => item !== value);
    } else {
      updatedFilterData = [...selectedOptions, value];
    }
  
    setSelectedOptions(updatedFilterData);
    props?.handleFilter(updatedFilterData, filterLabel);
  };

  const handleScriptOptionClick  = (e, option) =>
    handleOptionToggle(e, option, selectedOptions, setSelectedOptions, props?.label);
  
  const handleLabelsOptionClick = (e, option) =>
    handleOptionToggle(e, option, selectedLabelsOptions, setSelectedLabelsOptions, props?.labelsOption);
  

  const setTimePeriod = (e) => {
    let tempTimePeriod = e.target.value.toString();
    document.getElementById('rangeFromDate').value = '';
    document.getElementById('rangeToDate').value = '';
    startDate = '';
    endDate = '';
    setCurretTimePeriod(tempTimePeriod);
    switch (tempTimePeriod) {
      case '7':
        return props.handleTimePeriod(
          moment().subtract(7, 'days').add(1, 'day').format('DD/MM/YYYY'),
          moment().format('DD/MM/YYYY'),
          tempTimePeriod
        );

      case '30':
        return props.handleTimePeriod(
          moment().subtract(1, 'month').add(1, 'day').format('DD/MM/YYYY'),
          moment().format('DD/MM/YYYY'),
          tempTimePeriod
        );
      case '90':
        return props.handleTimePeriod(
          moment().subtract(3, 'months').add(1, 'day').format('DD/MM/YYYY'),
          moment().format('DD/MM/YYYY'),
          tempTimePeriod
        );
      case '365':
        return props.handleTimePeriod(
          moment().subtract(1, 'year').add(1, 'day').format('DD/MM/YYYY'),
          moment().format('DD/MM/YYYY'),
          tempTimePeriod
        );
      default:
        return props.handleTimePeriod(
          moment().subtract(7, 'days').add(1, 'day').format('DD/MM/YYYY'),
          moment().format('DD/MM/YYYY'),
          tempTimePeriod
        );
    }
  };

  const setDateRange = (rId, rDate) => {
    if (rId === 'rangeFromDate') {
      startDate = convertDate(rDate);
      setSelectedDateRange({ ...selectedDateRange, start: rDate });
    } else {
      endDate = convertDate(rDate);
      setSelectedDateRange({ ...selectedDateRange, end: rDate });
    }
    if (startDate !== '' && endDate !== '') {
      let validFromDate = isValidDateFormatDDMMYYYY(startDate);
      let validToDate = isValidDateFormatDDMMYYYY(endDate);
      if (validFromDate && validToDate) {
        setCurretTimePeriod('');
        props?.handleTimePeriod(startDate, endDate, '');
      }
    }
  };
  useEffect(() => {
    if (currentTimePeriod !== '') {
      startDate = '';
      endDate = '';
      setSelectedDateRange({
        start: null,
        end: null,
      });
    }
  }, [currentTimePeriod]);
  useEffect(() => {
    setCurretTimePeriod(props?.currentTimePeriod);
    setSelectedDateRange(props?.selectedDateRange);
    setSelectedOptions(props?.selectedFilterLabel);
    setSelectedLabelsOptions(props?.selectedLabels);
  }, [props]);
  return (
    <section className="main-filter-container">
      <div className="group main-group" onClick={handleTimePeriodToggle}>
        <div className="timeperiodheader ">
          <div className="timeperiodheader-box ">
            <div className="time-period-text ">{DASHBOARD_CONSTANTS.TIME_PERIOD}</div>
            <div className="col-span-1 inline-block">
              {expandTimePeriod ? (
                <ChevronUpIcon className="ChevronIcon cursor-hand" />
              ) : (
                <ChevronDownIcon className="ChevronIcon cursor-hand " />
              )}
            </div>
          </div>
        </div>
      </div>

      {expandTimePeriod && (
        <div className="main-time-period-container">
          <div className="time-period-container">
            <div className="fromdate">
              <DateTimePicker
                className="date-picker"
                dateId="rangeFromDate"
                setDateRange={setDateRange}
                selectedDate={selectedDateRange.start}
                placeholder="From Date"
                maxDate={selectedDateRange.end}
                popperPlacement="left-start"
              />
            </div>
            <div className="fromdate">
              <DateTimePicker
                className="date-picker"
                dateId="rangeToDate"
                setDateRange={setDateRange}
                selectedDate={selectedDateRange.end}
                minDate={selectedDateRange.start}
                placeholder="To Date"
                popperPlacement="left-start"
              />
            </div>
          </div>
          <div className="radio-btn-container">
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                className="radioGroupButtons"
                value={currentTimePeriod}
              >
                {timePeriods.map((timePeriod) => (
                  <FormControlLabel
                    value={timePeriod.value}
                    onChange={setTimePeriod}
                    control={<Radio size="small" className="radioButton-custom-graph-filter" />}
                    label={<span className="rs-text-black fontPoppinsRegularSm">{timePeriod.label}</span>}
                    key={timePeriod.value}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      )}
      {props?.label && (
        <section className="script-type-header" onClick={handleScriptTypeToggle}>
          <div>{props?.label}</div>
          <div className="arrow-icon">
            {expandScriptType ? (
              <ChevronUpIcon className="ChevronIcon cursor-hand" />
            ) : (
              <ChevronDownIcon className="ChevronIcon cursor-hand " />
            )}
          </div>
        </section>
      )}

      {expandScriptType && (
        <section className="expandTypeScriptContainer">
          <div className="check-box">
            {props?.options.map((option, index) => {
              return (
                <Fragment key={index}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={selectedOptions.includes(option) ? { color: colors.rs_primary  } : null}
                          value={option.name}
                          checked={selectedOptions.includes(option.name)}
                          onChange={(e) => handleScriptOptionClick(e, option)}
                        />
                      }
                      label={
                        <span title={(option.name.length >= 20 && option.name) || ''}>
                          {getTruncatedText(option.name,20)}
                        </span>
                      }
                    />
                  </FormGroup>
                </Fragment>
              );
            })}
          </div>
        </section>
      )}

      {props?.labelsOption && (
              <section className="script-type-header" onClick={handleLabelsToggle}>
                <div>{props?.labelsOption}</div>
                <div className="arrow-icon">
                  {expandLabelsType ? (
                    <ChevronUpIcon className="ChevronIcon cursor-hand" />
                  ) : (
                    <ChevronDownIcon className="ChevronIcon cursor-hand " />
                  )}
                </div>
              </section>
      )}

      {expandLabelsType && (
              <section className="expandTypeScriptContainer">
                <div className="check-box">
                  {props?.filterOptionsLabels.map((option, index) => {
                    return (
                      <Fragment key={index}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                style={selectedLabelsOptions.includes(option) ? { color: colors.rs_primary } : null}
                                value={option.name}
                                checked={selectedLabelsOptions.includes(option.name)}
                                onChange={(e) => handleLabelsOptionClick(e, option)}
                              />
                            }
                            label={
                              <span title={(option.name.length >= 20 && option.name) || ''}>
                                {getTruncatedText(option.name,20)}
                              </span>
                            }
                          />
                        </FormGroup>
                      </Fragment>
                    );
                  })}
           </div>
        </section>
      )}
    </section>
  );
};
