import React, { useRef, useState } from 'react';
import './ColorBarSelector.scss';
import { colors } from '@src/css_config/colorConstants';

const ColorBarSelector = ({ getColorValue }) => {
  const colorInputRef = useRef(null);
  const [color, setColor] = useState(colors.grey_dark);

  const handleColorClick = () => {
    if (colorInputRef.current) {
      colorInputRef.current.click();
    }
  };

  return (
    <div
      className="color-bar"
      style={{
        backgroundColor: color,
      }}
      onClick={handleColorClick}
    >
      <input
        type="color"
        className="colorPicker"
        ref={colorInputRef}
        onChange={(e) => {
          setColor(e.target.value);
          getColorValue(e.target.value.replace('#', ''));
        }}
      />
    </div>
  );
};

export default ColorBarSelector;
