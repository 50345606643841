import React, { Fragment } from 'react';
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table';
import matchSorter from 'match-sorter';
import { AiOutlineDelete, AiOutlinePlayCircle } from 'react-icons/ai';
import { MdEdit } from 'react-icons/md';
import { FaStopCircle } from 'react-icons/fa';
import { FormControl, InputLabel, MenuItem, Select, Tooltip } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import Pagination from '../../common/pagination';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import UserDetailsPage from '../common/user-details-page';
import { useHistory } from 'react-router';
import ExecutionIdDetailsPage from '../suites/details-child-pages/execution-id-details-page';

const ExecutionDashboard = () => {
  let tab = window.location.pathname.split('/')[3];
  let history = useHistory();
  const [dashboardData, setDashboardData] = React.useState([]);
  const [pageIndexValue, setPageIndex] = React.useState(0);
  console.log(`pageIndex`, pageIndexValue);
  let deleteOption = ['Delete all.', 'Delete only screenshots.', 'Delete only videos.'];
  const data = [
    {
      name: 'suite1',
      NoOfScripts: '13',
      state: 'gjg',
      estimated_duration: '02 : 05 : 08',
      exeType: 'Parallel',
      execution_id: 'EXEC0012',
      result: 'result',
      'running-instance': '5',
      status: 'Running',
      machine: ['Machine1', 'Machine2'],
      run_initiated_by: 'Manjunath',
      run_initiated_on: '04/09/2020, 24:00',
      execution_Start_Time: '04/09/2020, 24:00',
      type: 'suite',
    },
    {
      name: 'suite2',
      NoOfScripts: '15',
      state: 'gjg',
      estimated_duration: '02 : 05 : 08',
      exeType: 'Sequential',
      execution_id: 'EXEC0013',
      result: 'result',
      'running-instance': '2',
      status: 'Cancelled',
      machine: ['Machine1'],
      run_initiated_by: 'Manjunath',
      run_initiated_on: '04/09/2020, 24:00',
      execution_Start_Time: '04/09/2020, 24:00',
      type: 'suite-of-suite',
    },
    {
      name: 'suite3',
      NoOfScripts: '10',
      state: 'gjg',
      estimated_duration: '02 : 05 : 08',
      exeType: 'Distributed',
      execution_id: 'EXEC0014',
      result: 'result',
      'running-instance': '3',
      status: 'Failed',
      machine: ['Machine1', 'Machine4'],
      run_initiated_by: 'Manjunath',
      run_initiated_on: '04/09/2020, 24:00',
      execution_Start_Time: '04/09/2020, 24:00',
      type: 'suite',
    },
    {
      name: 'suite4',
      NoOfScripts: '10',
      state: 'gjg',
      estimated_duration: '02 : 05 : 08',
      exeType: 'Sequential',
      execution_id: 'EXEC0015',
      result: 'result',
      'running-instance': '1',
      status: 'Pending',
      machine: ['Machine3', 'Machine2'],
      run_initiated_by: 'Manjunath',
      run_initiated_on: '04/09/2020, 24:00',
      execution_Start_Time: '04/09/2020, 24:00',
      type: 'suite-of-suite',
    },
    {
      name: 'suite5',
      NoOfScripts: '10',
      state: 'gjg',
      estimated_duration: '02 : 05 : 08',
      exeType: '',
      execution_id: 'EXEC0016',
      result: 'result',
      'running-instance': '5',
      status: 'Passed',
      machine: ['Machine1'],
      run_initiated_by: 'Manjunath',
      run_initiated_on: '04/09/2020, 24:00',
      execution_Start_Time: '04/09/2020, 24:00',
      type: 'suite-of-suite',
    },
    {
      name: 'suite6',
      NoOfScripts: '10',
      state: 'gjg',
      estimated_duration: '02 : 05 : 08',
      exeType: 'Sequential',
      execution_id: 'EXEC0017',
      result: 'result',
      'running-instance': '2',
      status: 'Failed',
      machine: ['Machine3', 'Machine4'],
      run_initiated_by: 'Manjunath',
      run_initiated_on: '04/09/2020, 24:00',
      execution_Start_Time: '04/09/2020, 24:00',
      type: 'suite-of-suite',
    },
    {
      name: 'suite7',
      NoOfScripts: '10',
      state: 'gjg',
      estimated_duration: '02 : 05 : 08',
      exeType: 'Sequential',
      execution_id: 'EXEC0018',
      result: 'result',
      'running-instance': '2',
      status: 'Terminated',
      machine: ['Machine1'],
      run_initiated_by: 'Manjunath',
      run_initiated_on: '04/09/2020, 24:00',
      execution_Start_Time: '04/09/2020, 24:00',
      type: 'suite-of-suite',
    },
  ];

  let userObj = {
    activationStatus: 'ACTIVE',
    createdBy: 'USR1005',
    createdByUname: 'John',
    createdOn: '06/07/2021 14:29',
    emailId: 'john@testyantra.com',
    grantedAuthoritiesList: [],
    id: 'USR1005',
    licenses: [],
    modifiedBy: 'USR1386',
    modifiedByUname: 'Sample User',
    modifiedOn: '04/08/2021 07:09',
    name: 'John',
    phoneNumbers: { primaryPhone: '+145345', secondaryPhone: '+1345345345' },
    privilege: 'SUPER_ADMIN',
  };

  function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);

    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);

    return (
      <span>
        Search:{' '}
        <input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`Type here...`}
          style={{
            fontSize: '1.1rem',
            border: '0',
          }}
        />
      </span>
    );
  }

  // Define a default UI for filtering
  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search...`}
      />
    );
  }

  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  // This is a custom filter UI that uses a
  // slider to set the filter value between a column's
  // min and max values
  function SliderColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      preFilteredRows.forEach((row) => {
        min = Math.min(row.values[id], min);
        max = Math.max(row.values[id], max);
      });
      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <>
        <input
          type="range"
          min={min}
          max={max}
          value={filterValue || min}
          onChange={(e) => {
            setFilter(parseInt(e.target.value, 10));
          }}
        />
        <button onClick={() => setFilter(undefined)}>Off</button>
      </>
    );
  }

  // This is a custom UI for our 'between' or number range
  // filter. It uses two number boxes and filters rows to
  // ones that have values between the two
  function NumberRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id } }) {
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      preFilteredRows.forEach((row) => {
        min = Math.min(row.values[id], min);
        max = Math.max(row.values[id], max);
      });
      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <input
          value={filterValue[0] || ''}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
          }}
          placeholder={`Min (${min})`}
          style={{
            width: '70px',
            marginRight: '0.5rem',
          }}
        />
        to
        <input
          value={filterValue[1] || ''}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
          }}
          placeholder={`Max (${max})`}
          style={{
            width: '70px',
            marginLeft: '0.5rem',
          }}
        />
      </div>
    );
  }

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  React.useEffect(() => {
    getAllDashboard();
  }, []);

  function getAllDashboard() {
    // getProjectListReq().then((results) => {
    //     console.log("", results.data.responseObject);
    const response = data;
    if (response) {
      // setIsLoaded(true);
      response.map((res, i) => {
        // stateIndex = i;
        res.Actions = [];
        if (res.status === 'Running') {
          res.Actions.push(
            <span className="flex flex-row project-action delete-icon-style">
              {global.permission.isOnlyViewAccess('execution') && (
                <Tooltip>
                  <FaStopCircle title="Terminate" className=" cursor-hand FaStop-style float-left  ml-2" />
                </Tooltip>
              )}
              {global.permission.isFullAccess('execution') && (
                <Tooltip title="Delete">
                  <DeleteOutlinedIcon className=" disabled-delete-style  float-right  ml-2 " />
                </Tooltip>
              )}
            </span>
          );
        } else if (res.status === 'Terminated' || res.status === 'Cancelled' || res.status === 'Passed') {
          res.Actions.push(
            <span className="flex flex-row project-action delete-icon-style">
              <Tooltip>
                <AiOutlinePlayCircle title="Re-run" className="cursor-hand icon-blue-style float-left  ml-2" />
              </Tooltip>
              {global.permission.isFullAccess('execution') && (
                <Tooltip title="Delete">
                  <DeleteOutlinedIcon
                    className=" icon-blue-style float-left ml-2 "
                    onClick={() => {
                      setOpen(true);
                      setSelectedDataObj(res);
                    }}
                  />
                </Tooltip>
              )}
            </span>
          );
        } else if (res.status === 'Failed' && res.type === 'suite') {
          res.Actions.push(
            <span className="flex flex-row project-action delete-icon-style">
              <Tooltip>
                <AiOutlinePlayCircle title="Re-run" className="cursor-hand icon-blue-style float-left ml-2 " />
              </Tooltip>
              {global.permission.isFullAccess('execution') && (
                <Tooltip title="Delete">
                  <DeleteOutlinedIcon
                    className=" icon-blue-style float-left  ml-2"
                    onClick={() => {
                      setOpen(true);
                      setSelectedDataObj(res);
                    }}
                  />
                </Tooltip>
              )}
            </span>
          );
        } else if (res.status === 'Failed' && res.type === 'suite-of-suite') {
          res.Actions.push(
            <span className="flex flex-row project-action delete-icon-style">
              <Tooltip>
                <AiOutlinePlayCircle title="Re-run" className="cursor-hand icon-blue-style float-left ml-2" />
              </Tooltip>
              {global.permission.isFullAccess('execution') && (
                <Tooltip title="Delete">
                  <DeleteOutlinedIcon
                    className=" icon-blue-style float-left  ml-2"
                    onClick={() => {
                      setOpen(true);
                      setSelectedDataObj(res);
                    }}
                  />
                </Tooltip>
              )}
            </span>
          );
        } else {
          res.Actions.push(
            <span className="flex flex-row project-action delete-icon-style">
              <Tooltip title="Cancel">
                <CancelOutlinedIcon className=" icon-blue-style   float-left  ml-2" />
              </Tooltip>
              {global.permission.isFullAccess('execution') && (
                <Tooltip title="Delete">
                  <DeleteOutlinedIcon className="float-right  ml-2  disabled-delete-style" />
                </Tooltip>
              )}
            </span>
          );
        }
      });
      setDashboardData((d) => response);
    }
    // })
    //     .catch((error) => {
    //         console.log(error)
    //         setIsLoaded(true);
    //         setError(error);
    //     });
    // console.log("Single Project")
    return [];
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  // Our table component
  function Table({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );

    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      nextPage,
      previousPage,
      state,
      visibleColumns,
      preGlobalFilteredRows,
      setGlobalFilter,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
        initialState: { pageIndex: pageIndexValue, pageSize: 5 },
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useSortBy,
      usePagination
    );
    return (
      <>
        <table class="relative w-full border" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr class="mt-4" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th class="sticky top-0 px-3 py-3 text-current text-sm bg-gray-100" {...column.getHeaderProps()}>
                    <span className="flex flex-row">
                      {column.render('Header')}
                      {/* Render the columns filter UI */}
                      <div>
                        <span>
                          {/* {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <AiOutlineArrowDown className="mt-1 ml-1" />
                                                            : <AiOutlineArrowUp className="mt-1 ml-1" />
                                                        : ''} */}
                        </span>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr className="project-row" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td class="px-3 py-3 text-center" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagecountborder" id="footer-details" style={{ position: 'fixed' }}>
          <div className="float-right pr-4">
            <span className="pagecount">
              {page[0]?.index + 1} - {page[page.length - 1]?.index + 1} of {dashboardData.length}
            </span>
            <span className="pageborderleft ml-2 pl-2">
              <button
                onClick={() => {
                  previousPage();
                  setPageIndex(pageIndexValue - 1);
                }}
                disabled={!canPreviousPage}
                className="pageArrow"
              >
                {'<'}
              </button>{' '}
              <span className="currentpagecount ml-2 mr-2">
                {' '}
                <span className="pl-2 pr-2">{pageIndex + 1}</span>
              </span>
              <button
                onClick={() => {
                  nextPage();
                  setPageIndex(pageIndexValue + 1);
                }}
                disabled={!canNextPage}
                className="pageArrow"
              >
                {'>'}
              </button>{' '}
            </span>
          </div>
        </div>
        <br />
      </>
    );
  }

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue >= filterValue;
    });
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        columns: [],
      },
      // {
      //     Header: 'Sl.No',
      //     accessor: 'slNo',
      //     disableFilters: true,
      //     Cell: ({ value }) => {
      //         return (
      //             <div className="table-non-link-color-common float-left ml-3">{value}</div>
      //         )
      //     }
      // },
      {
        Header: 'Execution ID',
        accessor: 'execution_id',
        disableFilters: true,
        Cell: (e) => (
          <a
            href=""
            onClick={(event) => {
              event.preventDefault();
              getData(e.cell.row.original, 'details');
            }}
            className="hyper_link_color float-left ml-2 table-font-style-common"
          >
            {' '}
            {e.value}{' '}
          </a>
        ),
      },
      {
        Header: 'Execution Name',
        accessor: 'name',
        disableFilters: true,
        Cell: (e) => (
          <a
            href=""
            onClick={(event) => {
              event.preventDefault();
            }}
            className="table-non-link-color-common float-left ml-2 table-font-style-common flex flex-row "
          >
            {' '}
            {e.value}
            {console.log(`e.value`, e.cell.row.original)}
            {e.cell.row.original.type === 'suite-of-suite' ? (
              <img src="\assets\execution_logos\cases_logo.svg" height="12px" width="12px" className="ml-2" />
            ) : null}
          </a>
        ),
      },
      {
        Header: 'Machine',
        accessor: 'machine',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="hyper_link_color float-left ml-3 table-font-style-common">{value}</div>;
        },
      },
      {
        Header: 'Execution Type',
        accessor: 'exeType',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-3 table-font-style-common">{value}</div>;
        },
      },
      {
        Header: 'Run Initiated By',
        accessor: 'run_initiated_by',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div
              className="hyper_link_color float-left ml-3 cursor-hand table-font-style-common"
              onClick={() => openModalfun('createdBy')}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Run Initiated On',
        accessor: 'run_initiated_on',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-3 table-font-style-common">{value}</div>;
        },
      },
      {
        Header: 'Execution Start Time',
        accessor: 'execution_Start_Time',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-3 table-font-style-common">{value}</div>;
        },
      },

      // {
      //     Header: 'Name',
      //     accessor: 'name',
      //     disableFilters: true,
      //     Cell: e => <a href="" onClick={(e) => { e.preventDefault(); setShowModal(true); setpopupHeaderAndBtn("Role Details"); setdetailsHeader(e.target.text) }} className="hyper_link_color float-left ml-2"> {e.value} </a>

      // },

      {
        Header: 'Estimated Duration',
        accessor: 'estimated_duration',
        disableFilters: true,

        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-3 table-font-style-common">{value}</div>;
        },
      },
      // {
      //     Header: 'Status',
      //     accessor: 'status',
      //     disableFilters: true,

      //     Cell: ({ value }) => {
      //         return (
      //             <div className="table-non-link-color-common float-left ml-3 table-font-style-common">{value}</div>
      //         )
      //     }
      // },
      {
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,

        Cell: ({ value }) => {
          return value == 'Passed' ? (
            <div style={{ color: '#79B62F' }} className=" float-left ml-3 cursor-hand">
              {value}
            </div>
          ) : value == 'Running' ? (
            <div className=" hyper_link_color float-left ml-3 cursor-hand">{value}</div>
          ) : value == 'Terminated' || value == 'Failed' ? (
            <div style={{ color: '#C50303' }} className=" float-left ml-3 cursor-hand">
              {value}
            </div>
          ) : value == 'Scheduled' ? (
            <div style={{ color: 'yellow' }} className=" float-left ml-3 cursor-hand">
              {value}
            </div>
          ) : value == 'Pending' ? (
            <div class="flex flex-row">
              <div
                style={{ color: '#EFBB07' }}
                className=" float-left ml-3 flex flex-row  delete-icon-style cursor-hand"
              >
                {value}
              </div>
              {executionInfo()}
            </div>
          ) : (
            <div className="table-non-link-color-common  float-left ml-3">{value}</div>
          );
        },
      },
      {
        Header: 'Actions',
        accessor: 'Actions',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common table-font-style-common">{value}</div>;
        },
      },
    ],
    []
  );

  const detailsColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        columns: [],
      },

      {
        Header: 'Name',
        accessor: 'name',
        disableFilters: true,
        Cell: (e) => (
          <a
            href=""
            onClick={(event) => {
              event.preventDefault();
            }}
            className="hyper_link_color float-left ml-2 table-font-style-common"
          >
            {' '}
            {e.value}{' '}
          </a>
        ),
      },
      {
        Header: 'No. of Scripts',
        accessor: 'NoOfScripts',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-3 table-font-style-common">{value}</div>;
        },
      },
      {
        Header: 'Machine',
        accessor: 'machine',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="hyper_link_color float-left ml-3 table-font-style-common">{value}</div>;
        },
      },

      {
        Header: 'Execution Type',
        accessor: 'exeType',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left ml-3 table-font-style-common">{value}</div>;
        },
      },
    ],
    []
  );

  const [suiteArray, setSuiteArrayData] = React.useState([
    {
      name: 'suite1',
      NoOfScripts: '13',
      machine: ['Machine1', 'Machine2'],
      runin: 'single machine',
      exeType: 'Distributed',
      NoOfRuns: '2',
      result: 'result',
      'running-instance': '5',
      status: 'Running',
    },
    {
      name: 'suite2',
      NoOfScripts: '13',
      machine: ['Machine3', 'Machine2'],
      runin: 'single machine',
      exeType: 'Sequential',
      NoOfRuns: '2',
      result: 'result',
      'running-instance': '5',
      status: 'Running',
    },
    {
      name: 'suite3',
      NoOfScripts: '14',
      machine: ['Machine1'],
      runin: 'single machine',
      exeType: 'Parallel',
      NoOfRuns: '5',
      result: 'result',
      'running-instance': '5',
      status: 'Running',
    },
  ]);
  const [open, setOpen] = React.useState(false);
  const [selectedDataObj, setSelectedDataObj] = React.useState({});
  const [selectedDeleteOptions, setSelectedDeleteOptions] = React.useState([]);
  const [operation, setOperation] = React.useState();
  const [showModal, setShowModal] = React.useState(false);

  function selectDeleteOption(e, value) {
    console.log(`e`, e, value);
    let tempArray = [];
    if (e.target.checked) {
      tempArray = selectedDeleteOptions;
      tempArray.push(value);
      console.log(`tempArray`, tempArray);
      setSelectedDeleteOptions(tempArray);
    } else {
      const ind = selectedDeleteOptions.findIndex((tempObj) => tempObj === value);
      console.log(`ind`, ind);
      selectedDeleteOptions.splice(ind, 1);
      setSelectedDeleteOptions(selectedDeleteOptions);
    }
  }

  const executionInfo = (execData) => {
    let clientStatusMessage = '';
    if (execData?.clientStatus) {
      Object.entries(execData?.clientStatus).map(([key, value]) => {
        if (value?.message) {
          clientStatusMessage = clientStatusMessage + key + ':' + value?.message;
        }
      });
    } else {
      clientStatusMessage = 'Checking for client status';
    }
    return (
      <span>
        <Tooltip title={clientStatusMessage} placement="bottom">
          <InfoOutlinedIcon fontSize="small" className="text-blue-600 ml-3 text-xs" />
        </Tooltip>
      </span>
    );
  };

  let handelDlt = () => {
    // console.log(seletedProject)
    // deleteProjectRequest(seletedProject.id).then((res) => {
    //     if (res) {
    //         setOpen(false)
    // getAllProjects();
    // window.location.reload();
    //     }
    // })
    setOpen(false);
  };

  function openModalfun(btn) {
    if (btn === 'createdBy') {
      setShowModal(true);
      setOperation('createdBy');
    }
  }

  function getData(data, type) {
    console.log(`data`, data);
    let pathUrl = window.location.pathname;
    history.push(`${pathUrl}/${data.execution_id}`);
    setSelectedDataObj(data);
    // setOperation(type)
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4">
                <div className="inline-block">
                  <div className="flex flex-start ml-2">
                    <span className="pt-5 pl-3">
                      <ExclamationIcon className="h-6 w-6 text-yellow-600 " />
                    </span>
                    <Dialog.Title as="h3" className="text-sm leading-6 ml-4 pt-3 font-small text-gray-900">
                      <span className="fontPoppinsSemiboldLg block">Warning </span>
                      <span className="fontPoppinsMediumMd">Delete </span>
                    </Dialog.Title>
                  </div>
                </div>

                <hr className="line mt-px" />
                <div className="text-sm font-sans mt-3 text-gray-500 ml-16 mr-8">
                  <p className="">
                    {' '}
                    Your data will be lost. Are you sure you want to delete {selectedDataObj.execution_id}?{' '}
                  </p>
                  <p className="mt-2"> How do you want to proceed?</p>
                  {deleteOption.map((option, i) => (
                    <div className="mt-1">
                      <label>
                        <input
                          type="checkbox"
                          id={option}
                          className="rounded mr-2"
                          onChange={(e) => selectDeleteOption(e, option)}
                        ></input>
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
                <hr className="line mt-3" />
                <div className="mt-2 pb-2 flex flex-row float-right">
                  <button type="button" className="mt-3 gray-btn ml-1" onClick={() => handelDlt(selectedDataObj)}>
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 ml-4 primary-btn "
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Modal open={showModal} className="modal-dialog">
        {operation === 'createdBy' ? (
          <div className="modal-container page-modal-size">
            <div className="modal-header ">
              <label className="Popup-header-common Popup-header-layout-style-user">
                {' '}
                <label> User Details -{userObj.name}</label>
              </label>
              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className="modal-body" id="journal-scroll">
              <UserDetailsPage />
            </div>
          </div>
        ) : null}
      </Modal>
      {!tab ? (
        <div className="content_area_style overflow-auto" id="journal-scroll">
          <div>
            <div className="mt-3 suite-of-suite-header-style">
              <label className="project_label page-header-common float-left">Execution Dashboard</label>
            </div>
          </div>
          <div className="mt-3">
            {/* <div className="suite-of-suite-deails-area-style"> */}

            <div className="suite-of-suite-deails-area-style ">
              <Table columns={columns} data={dashboardData} />
            </div>
          </div>
        </div>
      ) : (
        <ExecutionIdDetailsPage selectedSuite={selectedDataObj} />
      )}
    </>
  );
};

export default ExecutionDashboard;
