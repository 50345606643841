import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { FiMaximize2 } from 'react-icons/fi';
import MoreInfoButton from '@pages/common/more_info_button';
import TableTree from '@pages/common/table_tree/table_tree';
import InvalidMachineModal from '@src/pages/execution/common/invalid-machine-modal';
import {
  getAllMachineListReq,
  getBrowserStackList,
  getSauceLabsList,
  getAllLambdaTestsList,
  automaticDistributionPostReq,
  getUserAllAvailableMachineReq,
} from '@api/api_services';
import UserDetailsPage from '@pages/execution/common/user-details-page';
import MachineTable from '@src/pages/common/MachineTable/MachineTable';
import MachineDetails from '../run-settings-machines-common/machine-details';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { renderBrowserCell } from '@src/pages/execution/execution';
import { Tooltip } from '@material-ui/core';
import { getBrowserLabel, getDeviceLabel, getOsLabel } from '../run-settings-machines-common/common-methods';
import { enableDisableHeadlessSwitch } from '@pages/execution/suites/create-edit-child-pages/run-settings-child-pages/machine-landing-page';
import { useHistory } from 'react-router-dom';
import { fetchMachineData, isEmptyValue, maxNumberOfRunsAlert } from '@src/util/common_utils';
import {
  ALERT_MODAL_CONSTANTS,
  AZURE_DEVOPS_CONSTANTS,
  DEFECT_CONSTANTS,
  EXECUTION_CONSTANTS,
  LABELS_CONSTANTS,
  SUITES_CONSTANTS,
  FIRECLOUD_DATA,
} from '@src/common/ui-constants';
import { Button } from 'fireflink-ui';
import { UI_VALIDATIONS } from '@src/util/validations';
let tempUpdatedSelectedMachine;
const AutomaticDistribution = (props) => {
  const { AlertContatiner, MyAlert } = useAlert();
  const project = JSON.parse(localStorage.getItem('selected-project'));
  let [treeData, setTreeData] = useState([]);
  let [rootData, setRootData] = useState(props?.rootData);
  let [isExpandAll, setIsExpandAll] = useState(false);
  let [isLoading, setIsLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  let [selectedOperation, setSelectedOperation] = useState();
  let [selectedNodeForUser, setSelectedNodeForUser] = useState();
  let [treeValue, setTreeValue] = useState('');
  const [allClientSystems, setAllClientSystems] = useState({});
  const [instanceList, setInstanceList] = useState({});
  const [defaultSystem, setDefaultSystem] = useState([]);
  const [selectedSystem, setSelectedSystem] = useState({});
  const [defaultSystemObj, setDefaultSystemObj] = useState({});
  const [selectedJsonData, setSelectedJsonData] = useState([]);
  const [selectedTableData, setSelectedTableData] = useState([]);
  const [selectMachineDisable, setSelectMachineDisable] = useState(true);
  const [noCallGetUpdated, setNoCallGetUpdated] = useState(false);
  const [disableHeadlessSwitch, setdisableHeadlessSwitch] = useState(true);
  const disableHeadlessSwitchRef = useRef();
  disableHeadlessSwitchRef.current = disableHeadlessSwitch;
  let selectedProject = JSON.parse(localStorage.getItem('selected-project'));
  let history = useHistory();
  const multipleMachineDetailsRef = useRef();
  const [machineInstanceErrors, setMachineInstanceErrors] = useState({});
  const [downloadClientModal, setDownloadClientModal] = useState(false);
  let renderTree;
  let renderMaxTree;
  const [isData, setIsData] = useState('');
  const colDefs = [
    {
      field: 'title',
      title: 'Module',
      class: 'title',
      isTitle: true,
      width: `calc(100% - 796px)`,
      folderTitleWidth: `calc(100% - 770px)`,
      paddingRight: '16px',
      render: (nodeObj) => {
        createFilterData(treeData);
        return (
          <>
            {nodeObj?.isRootNode && (
              <>
                {treeData?.[0]?.totalModuleCount ? (
                  <span class="count-badge folder-count ml-2">M {treeData[0]?.totalModuleCount} </span>
                ) : null}
                {treeData?.[0]?.totalScriptCount ? (
                  <span class="count-badge file-count"> S {treeData[0]?.totalScriptCount} </span>
                ) : null}
              </>
            )}
            {!nodeObj?.isRootNode && nodeObj?.data?.scriptCount > 0 && (
              <span class="count-badge file-count"> S {nodeObj?.data?.calculatedScriptCount} </span>
            )}
            {nodeObj?.folder && (
              <button class="add-btn">
                <MoreInfoButton type="add" nodeObj={nodeObj} plusIconArray={plusIconArray} />
              </button>
            )}
          </>
        );
      },
    },
    {
      field: 'scriptType',
      title: 'Type',
      class: 'Type truncate',
      width: '68px',
      render: (nodeObj) => {
        return (
          <Tooltip title={nodeObj?.data?.scriptType}>
            <span className="text-xs table-non-link-color-common fontPoppinsRegularMd">
              {nodeObj?.data?.scriptType}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'Assigned to Machine',
      title: 'Client Machine',
      class: 'AssignedMachine truncate',
      width: '120px',
      render: (nodeObj) => {
        return (
          <Tooltip title={nodeObj?.data?.assignedToMachine}>
            <span className="table-non-link-color-common cursor-pointer">{nodeObj?.data?.assignedToMachine}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'Execution Environment',
      title: 'Execution Environment',
      class: 'executionEnvironment truncate',
      width: '170px',
      render: (nodeObj) => {
        return (
          <Tooltip title={nodeObj?.data?.executionEnv}>
            <span className="table-non-link-color-common cursor-pointer">{nodeObj?.data?.executionEnv}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'Os',
      title: 'OS & OS Ver.',
      class: 'os',
      width: '90px',
      render: (nodeObj) => {
        return <span className="version-text-color">{nodeObj?.data?.Os ? getOsLabel(nodeObj.data.Os) : ''}</span>;
      },
    },

    {
      field: project?.type?.toLowerCase() === 'web' ? 'Browser' : 'Device',
      title: project?.type?.toLowerCase() === 'web' ? 'Browser' : 'Device',
      class: project?.type?.toLowerCase() === 'web' ? 'browser truncate' : 'device truncate',
      width: '80px',
      render: (nodeObj) => {
        if (project?.type.toLowerCase() !== 'web') {
          return <span className="">{nodeObj?.data?.device ? nodeObj.data.device : ''}</span>;
        } else {
          return nodeObj?.data?.browser ? (
            <Tooltip title={nodeObj.data?.browser} placement="top">
              {renderBrowserCell(nodeObj.data.browser)}
            </Tooltip>
          ) : (
            ''
          );
        }
      },
    },
    {
      field: 'numberOfRuns',
      title: 'No. of Runs',
      width: '85px',
      render: (nodeObj) => {
        return <span className="text-xs table-non-link-color-common">{nodeObj?.data?.numberOfRuns}</span>;
      },
    },
  ];
  const closeModal = (val) => {
    if (downloadClientModal) {
      setDownloadClientModal(val);
    }
  };
  const colDefsForWebAndMobile = [
    {
      field: 'title',
      title: 'Module',
      class: 'title',
      isTitle: true,
      width: `calc(100% - 954px)`,
      folderTitleWidth: `calc(100% - 850px)`,
      render: (nodeObj) => {
        createFilterData(treeData);
        return (
          <>
            {nodeObj?.isRootNode && (
              <>
                {treeData && treeData?.[0]?.totalModuleCount ? (
                  <span class="count-badge folder-count ml-2 modifiedBy">M {treeData?.[0]?.totalModuleCount} </span>
                ) : null}
                {treeData?.[0]?.totalScriptCount ? (
                  <span class="count-badge file-count"> S {treeData?.[0]?.totalScriptCount} </span>
                ) : null}
              </>
            )}
            {!nodeObj?.isRootNode && nodeObj?.data?.calculatedScriptCount > 0 && (
              <span class="count-badge file-count modifiedBy"> S {nodeObj?.data?.calculatedScriptCount} </span>
            )}
            {nodeObj?.folder && (
              <button class="add-btn">
                <MoreInfoButton type="add" nodeObj={nodeObj} plusIconArray={plusIconArray} />
              </button>
            )}
          </>
        );
      },
    },
    {
      field: 'scriptType',
      title: 'Type',
      class: 'Type truncate',
      width: '70px',
      render: (nodeObj) => {
        return (
          <Tooltip title={nodeObj?.data?.scriptType}>
            <span className="text-xs table-non-link-color-common fontPoppinsRegularMd">
              {nodeObj?.data?.scriptType}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'Assigned to Machine',
      title: 'Client Machine',
      class: 'AssignedMachine truncate',
      width: '152px',
      render: (nodeObj) => {
        return (
          <Tooltip title={nodeObj?.data?.assignedToMachine}>
            <span className="table-non-link-color-common cursor-pointer">{nodeObj?.data?.assignedToMachine}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'Execution Environment',
      title: 'Execution Environment',
      class: 'executionEnvironment truncate',
      width: '168px',
      render: (nodeObj) => {
        return (
          <Tooltip title={nodeObj?.data?.executionEnv}>
            <span className="table-non-link-color-common cursor-pointer">{nodeObj?.data?.executionEnv}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'Os',
      title: 'OS & OS Ver.',
      class: 'os',
      width: '90px',
      render: (nodeObj) => {
        return <span className="version-text-color">{nodeObj?.data?.Os ? getOsLabel(nodeObj.data.Os) : ''}</span>;
      },
    },

    {
      field: 'Browser',
      title: 'Browser',
      class: 'browser',
      width: '70px',
      render: (nodeObj) => {
        return nodeObj?.data?.browser ? (
          <Tooltip title={nodeObj.data?.browser} placement="top">
            {renderBrowserCell(nodeObj.data.browser)}
          </Tooltip>
        ) : (
          ''
        );
      },
    },
    {
      field: 'Device',
      title: 'Devices',
      class: 'device truncate',
      width: '70px',
      render: (nodeObj) => {
        return <span className="version-text-color">{nodeObj?.data?.device ? nodeObj.data.device : ''}</span>;
      },
    },
    {
      field: 'numberOfRuns',
      title: 'No. of Runs',
      width: '85px',
      render: (nodeObj) => {
        return <span className="text-xs table-non-link-color-common">{nodeObj?.data?.numberOfRuns}</span>;
      },
    },
  ];

  const isFireCloudEnvironment = (systemObj) =>
    systemObj?.executionEnvironment?.toLowerCase() === FIRECLOUD_DATA.FIRE_CLOUD;
  const createSystemConfigObject = (instance) => {
    return {
      name: instance?.browserName,
      platform: instance?.browserName,
      version: instance?.browserVersion,
      subType: 'browser',
    };
  };

  function onNodeSelected() {}

  const onCheckedNodes = (nodes) => {};

  const plusIconArray = [
    {
      name: 'Add Module',
      value: 'folder',
    },
    {
      name: 'Add Script',
      value: 'file',
    },
  ];
  const LABELS = {
    EXPAND_ALL: 'Expand All',
    COLLAPSE_ALL: 'Collapse All',
    SHOW_ALL_ELEMENTS: 'Show all scripts',
    HIDE_ALL_ELEMENTS: 'Hide all scripts',
    SHOW_PRE_POST_CONDITION: 'Show Pre/Post Conditions',
    HIDE_PRE_POST_CONDITION: 'Hide Pre/Post Conditions',
  };

  function createFilterData(treeData) {
    if (treeData) {
      let tempArray = [];
      let scriptArray = [];
      let tempNamesArray = [];
      let tempTypeArray = [];
      treeData.forEach((treeVal) => {
        if (treeVal) {
          if (treeVal.title) {
            tempArray.push(treeVal.title);
          }
          if (treeVal.createdByUname) {
            tempNamesArray.push(treeVal.createdByUname);
          }
          if (treeVal && treeVal.children) {
            scriptArray = treeVal.children;
            scriptArray.forEach((scriptVal) => {
              if (scriptVal.title) {
                tempArray.push(scriptVal.title);
              }
              if (scriptVal.createdByUname) {
                tempNamesArray.push(treeVal.createdByUname);
              }
              if (scriptVal.scriptType) {
                tempTypeArray.push(scriptVal.scriptType);
              }
            });
          }
        }
      });
    }
  }

  if (isLoading) {
    renderTree = (
      <div className="base-h flex items-center justify-center">
        <span className="text-center font-bold align-middle empty_page_info -mt-20">
          <h2>data loading...</h2>
        </span>
      </div>
    );
  } else if (treeData && treeData.length > 0) {
    renderTree = (
      <TableTree
        data={treeData}
        rootData={rootData}
        colDefs={
          project?.type?.toLowerCase() === 'web'
            ? colDefs
            : project?.type?.toLowerCase() === 'mobile'
            ? colDefs
            : colDefsForWebAndMobile
        }
        filter={treeValue}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={true}
        showCheckbox={false}
        conditions={[
          { key: 'title', value: 'Preconditions' },
          { key: 'title', value: 'Postconditions' },
        ]}
        hideConditionBtn={true}
        onCheckedNodes={onCheckedNodes}
        labels={LABELS}
        onNodeSelected={onNodeSelected}
        id="distributeTree"
      />
    );
  } else {
    renderTree = (
      <div>
        <div>
          <TableTree
            data={[]}
            rootData={rootData}
            colDefs={
              project?.type?.toLowerCase() === 'web'
                ? colDefs
                : project?.type?.toLowerCase() === 'mobile'
                ? colDefs
                : colDefsForWebAndMobile
            }
            filter={treeValue}
            showCheckbox={false}
            onCheckedNodes={onCheckedNodes}
            expandAll={isExpandAll}
            hideElements={false}
            hideConditions={true}
            conditions={[
              { key: 'title', value: 'Preconditions' },
              { key: 'title', value: 'Postconditions' },
            ]}
            hideConditionBtn={true}
            labels={LABELS}
            onNodeSelected={onNodeSelected}
            id="distributeTree"
          />
        </div>
        <div className="overflow-y-auto h-82">
          <div className="fontPoppinsRegularMd mt-28 no-steps">
            <div classname="create-set-layout">
              <div className="qucBtn">
                <label className="quick-start-style px-1  ">Select some modules </label>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    renderMaxTree = (
      <div className="base-h flex items-center justify-center">
        <span className="text-center font-bold align-middle empty_page_info -mt-20">
          <h2>data loading...</h2>
        </span>
      </div>
    );
  } else if (treeData && treeData.length > 0) {
    renderMaxTree = (
      <TableTree
        data={treeData}
        rootData={rootData}
        colDefs={
          project?.type?.toLowerCase() === 'web'
            ? colDefs
            : project?.type?.toLowerCase() === 'mobile'
            ? colDefs
            : colDefsForWebAndMobile
        }
        filter={treeValue}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={true}
        showCheckbox={false}
        conditions={[
          { key: 'title', value: 'Preconditions' },
          { key: 'title', value: 'Postconditions' },
        ]}
        hideConditionBtn={true}
        onCheckedNodes={onCheckedNodes}
        labels={LABELS}
        onNodeSelected={onNodeSelected}
        id="distributeMaxTree"
      />
    );
  }

  function reloadModuleTree(distributionData) {
    setIsLoading(true);
    let getdata;
    let root;
    let rootChildren;
    let rootDetailsTemp = {
      children: [...props?.moduleArray],
    };
    let rootDetails = JSON.parse(JSON.stringify(rootDetailsTemp));
    if (distributionData?.length) {
      distributionData?.forEach((machineData) => {
        machineData?.machineInstances?.forEach((machineInstance) => {
          let tempModuleArray = machineInstance?.distribution?.selectedModules;
          if (tempModuleArray && tempModuleArray?.length) {
            tempModuleArray.forEach((tempDisObj, index) => {
              if (rootDetails?.children && rootDetails?.children?.length) {
                rootDetails.children.forEach((nodeObj) => {
                  if (nodeObj?.children) {
                    updateAssignedMachine(tempDisObj, machineInstance, nodeObj, distributionData);
                  }
                  if (tempDisObj?.includes(nodeObj?.key)) {
                    if (distributionData?.length) {
                      updateClientDetails(nodeObj, machineInstance, distributionData);
                    }
                  }
                });
              }
            });
          }
        });
      });
    }
    root = {
      subModuleCount: rootDetails.children[0]?.totalModuleCount,
      title: rootDetails.children[0]?.parentName,
      key: rootDetails.children[0]?.parentId,
      ver: rootDetails.children[0]?.ver,
      modifiedByUname: rootDetails.children[0]?.modifiedByUname,
      scriptCount: rootDetails.children[0]?.totalScriptCount,
    };
    rootChildren = rootDetails.children;
    setRootData(root);
    setIsExpandAll(rootDetails.expanded);
    setTreeData(rootChildren);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }

  function updateAssignedMachine(tempDisObj, disObj, childrenObj, selectedJsonData) {
    childrenObj.children.forEach((nodeObj) => {
      if (nodeObj?.children) {
        updateAssignedMachine(tempDisObj, disObj, nodeObj, selectedJsonData);
      }
      if (tempDisObj?.includes(nodeObj?.key)) {
        if (selectedJsonData && selectedJsonData.length) {
          updateClientDetails(nodeObj, disObj, selectedJsonData);
        }
      }
    });
  }

  function updateClientDetails(nodeObj, disObj, selectedJsonData) {
    if (!nodeObj?.folder) {
      const found = selectedJsonData.find((resObj) => resObj.clientId === disObj.clientId);
      if (found) {
        nodeObj.browser = disObj.browserName;
        nodeObj.Os = `${disObj.machineInfo.osName} - ${disObj.machineInfo.osVersion}`;
        nodeObj.assignedToMachine = disObj.machineInfo.hostName;
        nodeObj.executionEnv = disObj.executionEnv;
        nodeObj.numberOfRuns = disObj.numberOfRuns;
        if (disObj?.deviceInfo?.length) {
          nodeObj.device = getDeviceLabel(disObj?.deviceInfo);
        } else {
          nodeObj.device = '';
        }
      }
    }
  }

  function getModuleTree() {
    setIsLoading(true);
    let root;
    let rootChildren;
    let rootDetails = {};
    rootDetails.children = [...props?.moduleArray];
    root = {
      subModuleCount: rootDetails.children[0]?.totalModuleCount,
      title: rootDetails.children[0]?.parentName,
      key: rootDetails.children[0]?.parentId,
      ver: rootDetails.children[0]?.ver,
      modifiedByUname: rootDetails.children[0]?.modifiedByUname,
      scriptCount: rootDetails.children[0]?.totalScriptCount,
    };
    rootChildren = rootDetails.children;
    setRootData(root);
    setIsExpandAll(rootDetails.expanded);
    setTreeData(rootChildren);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }

  function selectModule(val, obj) {
    setdisableHeadlessSwitch(enableDisableHeadlessSwitch(props.moduleArray, selectedProject));
    if (val === 'create') {
      let tempArray = [...defaultSystem];
      let tempClientArray = allClientSystems?.length ? [...allClientSystems] : [];
      if (tempArray?.length) {
        let defaultSelected = false;
        tempArray?.forEach((defObj) => {
          let ind = tempClientArray?.findIndex((clientObj) => clientObj?.clientId === defObj?.[0]?.clientId);
          if (ind > -1 && !defaultSelected) {
            defaultSelected = true;
            setSelectedSystem({ ...tempClientArray[ind] });
            setDefaultSystemObj([...defObj]);
            tempUpdatedSelectedMachine = [...defObj];
          }
        });
      } else {
        const tempSystem = tempClientArray.find((machineTemp) => !machineTemp.disabled);
        if (tempSystem) {
          setSelectedSystem({ ...tempSystem });
          setDefaultSystemObj([{ ...tempSystem, numberOfRuns: 1, systemConfigDetails: [] }]);
        }
      }
      // history.push(`?hideParentLevelParallelRun=${false}`)
      setShowModal(true);
      setSelectedOperation(val);
    } else {
      setShowModal(true);
      setSelectedOperation(val);
      if (val === 'createdBy') {
        if (obj) {
          setSelectedNodeForUser(obj);
        }
      }
    }
  }

  async function getUserAllAvailableMachine() {
    const runSetApiData = await fetchMachineData(
      props?.userDetails,
      getUserAllAvailableMachineReq,
      getAllMachineListReq
    );
    if (runSetApiData) {
      setAllClientSystems(runSetApiData);
      setSelectMachineDisable(false);
      let instanceList = {};
      if (runSetApiData) {
        getBrowserStackList().then((response) => {
          if (response?.data?.responseCode === 200) {
            instanceList['browserInstanceArray'] = response?.data?.responseObject;
            setInstanceList({ ...instanceList });
          }
          getSauceLabsList().then((res) => {
            if (res?.data?.responseCode === 200) {
              instanceList['sauceLabInstanceArray'] = res?.data?.responseObject;
              setInstanceList({ ...instanceList });
            }
            getAllLambdaTestsList().then((res) => {
              if (res?.data?.responseCode === 200) {
                instanceList['lambdaTestsInstanceArray'] = res?.data?.responseObject;
                setInstanceList({ ...instanceList });
              }
            });
          });
        });
        getOldData(runSetApiData);
      }
    }
  }

  function getOldData(runSetApiData) {
    if (props?.automaticJsonData?.length) {
      let tempDefaultSystem = [];
      let automaticJsonData = props?.automaticJsonData;
      setSelectedJsonData([...automaticJsonData]);
      if (runSetApiData?.length) {
        automaticJsonData?.forEach((obj) => {
          let ind = runSetApiData?.findIndex((cliObj) => cliObj?.clientId === obj.clientId);
          if (ind > -1) {
            const instancesCopy = [];
            obj?.machineInstances?.forEach((instance, instanceIndex) => {
              let tempDefaultSystemObj = JSON.parse(JSON.stringify(runSetApiData[ind]));
              tempDefaultSystemObj.executionEnvironment = instance?.executionEnv;
              tempDefaultSystemObj.machine = instance?.machineInfo;
              tempDefaultSystemObj['selectedOs'] = getOsLabel(instance?.machineInfo.osName);
              tempDefaultSystemObj['rowId'] = instance?.clientId + instanceIndex;
              if (project.type.toLowerCase() === 'web') {
                tempDefaultSystemObj.systemConfigDetails = getSingleBrowserList(
                  tempDefaultSystemObj?.systemConfigDetails
                );
                if (tempDefaultSystemObj.systemConfigDetails?.length) {
                  tempDefaultSystemObj.systemConfigDetails[0].name = instance?.browserName;
                  tempDefaultSystemObj.systemConfigDetails[0].platform = instance?.browserName;
                  tempDefaultSystemObj.systemConfigDetails[0].version = instance?.browserVersion;
                } else {
                  let browserObj = {
                    name: instance?.browserName,
                    platform: instance?.browserName,
                    version: instance?.browserVersion,
                    subType: 'browser',
                  };
                  let browserArray = [];
                  browserArray.push(browserObj);
                  tempDefaultSystemObj['systemConfigDetails'] = [...browserArray];
                }
                tempDefaultSystemObj['selectedBrowser'] = getBrowserLabel(instance?.browserName);
              } else if (project.type.toLowerCase() === 'mobile') {
                let deviceArray = [];
                if (instance?.deviceInfo?.length) {
                  let systemConfig = instance?.deviceInfo;
                  systemConfig?.forEach((obj) => {
                    if (obj?.subType === 'device') {
                      deviceArray?.push(obj);
                    }
                  });
                }
                tempDefaultSystemObj['deviceNames'] = getDeviceLabel(deviceArray);
                tempDefaultSystemObj.systemConfigDetails = deviceArray;
              } else if (['web & mobile', 'salesforce'].includes(project.type.toLowerCase())) {
                let deviceArray = [];
                let browserObjArray = getSingleBrowserList(tempDefaultSystemObj?.systemConfigDetails);
                if (browserObjArray?.length) {
                  browserObjArray[0].name = instance?.browserName;
                  browserObjArray[0].platform = instance?.browserName;
                  browserObjArray[0].version = instance?.browserVersion;
                }
                else if (isFireCloudEnvironment(tempDefaultSystemObj)) {
                  browserObjArray = [];
                  const systemConfigObject = createSystemConfigObject(instance);
                  browserObjArray.push(systemConfigObject);
                }
                tempDefaultSystemObj['selectedBrowser'] = getBrowserLabel(instance?.browserName);
                if (instance?.deviceInfo?.length) {
                  deviceArray = instance?.deviceInfo.filter((device) => device.subType === 'device');
                }
                tempDefaultSystemObj['deviceNames'] = getDeviceLabel(deviceArray);
                tempDefaultSystemObj.systemConfigDetails = browserObjArray?.concat(deviceArray);
              }
              tempDefaultSystemObj['headless'] = instance?.headless || false;
              tempDefaultSystemObj['numberOfRuns'] = instance?.numberOfRuns;
              instancesCopy.push({ ...tempDefaultSystemObj });
            });
            tempDefaultSystem.push([...instancesCopy]);
          }
        });
        setDefaultSystem([...tempDefaultSystem]);
        setSelectedTableData([...tempDefaultSystem]);
      }
    } else {
      const tempSystem = runSetApiData.find((machineTemp) => !machineTemp.disabled);
      if (tempSystem) {
        setSelectedSystem({ ...tempSystem });
        setDefaultSystemObj([{ ...tempSystem, numberOfRuns: 1, systemConfigDetails: [] }]);
      }
      setDefaultSystem([]);
    }
  }

  useEffect(() => {
    let tempArray = [...defaultSystem];
    if (tempArray?.length) {
      let ind = tempArray?.findIndex((defObj) => defObj?.[0]?.clientId === tempUpdatedSelectedMachine?.[0]?.clientId);
      if (ind > -1) {
        setDefaultSystemObj([...tempArray[ind]]);
        tempUpdatedSelectedMachine = [...tempArray[ind]];
      }
      if (ind === -1) {
        setDefaultSystemObj([{ ...selectedSystem, numberOfRuns: 1, systemConfigDetails: [] }]);
        tempUpdatedSelectedMachine = [{ ...selectedSystem, numberOfRuns: 1, systemConfigDetails: [] }];
      }
    }
  }, [selectedSystem]);

  useEffect(() => {
    getUserAllAvailableMachine();
  }, [props?.automaticJsonData]);

  useEffect(() => {
    if (props?.moduleArray?.length) {
      props?.moduleArray?.forEach((obj) => {
        if (obj) {
          obj.checkbox = false;
          ['browser', 'device', 'executionEnv', 'Os', 'assignedToMachine'].forEach((e) => delete obj[e]);
        }
      });
    }
    if (props?.automaticJsonData?.length) {
      setTreeData([]);
      reloadModuleTree(props?.automaticJsonData);
    } else {
      getModuleTree();
    }
  }, [props?.moduleArray, props?.automaticJsonData]);

  const updateMachineInstanceErrors = (clientId, action) => {
    const machineInstanceErrorsCopy = { ...machineInstanceErrors };
    action === 'add' ? (machineInstanceErrorsCopy[clientId] = clientId) : delete machineInstanceErrorsCopy?.[clientId];
    setMachineInstanceErrors(machineInstanceErrorsCopy);
    return machineInstanceErrorsCopy;
  };

  const handleSelectedSystem = async (system) => {
    if (system?.clientId === selectedSystem?.clientId) {
      return;
    }
    const selectedSystemCopy = { ...selectedSystem };
    let tempArray = [...defaultSystem];
    if (tempArray?.length) {
      let ind = tempArray?.findIndex((defObj) => defObj?.[0]?.clientId === system?.clientId);
      if (ind > -1) {
        setDefaultSystemObj([...tempArray[ind]]);
        tempUpdatedSelectedMachine = [...tempArray[ind]];
      } else {
        setDefaultSystemObj([{ ...system, numberOfRuns: 1, systemConfigDetails: [] }]);
        tempUpdatedSelectedMachine = [{ ...system, numberOfRuns: 1, systemConfigDetails: [] }];
      }
    } else {
      setDefaultSystemObj([{ ...system, numberOfRuns: 1, systemConfigDetails: [] }]);
      tempUpdatedSelectedMachine = [{ ...system, numberOfRuns: 1, systemConfigDetails: [] }];
    }
    setSelectedSystem(system);
    const isValid = await multipleMachineDetailsRef.current?.validateForm();
    if (defaultSystem.some((machine) => machine?.[0].clientId === selectedSystemCopy?.clientId)) {
      if (!isValid?.machineInstances?.length) {
        updateMachineInstanceErrors(selectedSystemCopy?.clientId, 'delete');
      } else {
        updateMachineInstanceErrors(selectedSystemCopy?.clientId, 'add');
      }
    } else {
      updateMachineInstanceErrors(selectedSystemCopy?.clientId, 'delete');
    }
  };

  const getUpdatedSelectedMachine = (updatedSystem) => {
    setIsData(updatedSystem);
    let tempArray = [...defaultSystem];
    if (tempArray?.length) {
      let ind = tempArray?.findIndex((defObj) => defObj?.[0]?.clientId === updatedSystem?.[0]?.clientId);
      if (ind > -1) {
        tempArray[ind] = [...updatedSystem];
      }
    }
    setDefaultSystem([...tempArray]);
    setDefaultSystemObj([...updatedSystem]);
    tempUpdatedSelectedMachine = [...updatedSystem];
  };

  const handleChangeDefaultMachine = (machine) => {
    let updatedSystem;
    if (tempUpdatedSelectedMachine?.[0]?.clientId === machine?.clientId) {
      updatedSystem = [...tempUpdatedSelectedMachine];
    } else {
      updatedSystem = [{ ...machine, numberOfRuns: 1, systemConfigDetails: [] }];
    }
    updatedSystem.forEach((instance) => {
      if (project.type.toLowerCase() === 'web') {
        instance.systemConfigDetails = getSingleBrowserList(instance?.systemConfigDetails);
      } else if (project.type.toLowerCase() === 'mobile') {
        instance.systemConfigDetails = getSingleBrowserListForMobile(instance?.systemConfigDetails);
        if (tempUpdatedSelectedMachine?.systemConfigDetails?.length) {
          let configData = tempUpdatedSelectedMachine.systemConfigDetails;
          let deviceArray = [];
          configData?.forEach((obj) => {
            if (obj?.subType === 'device') {
              deviceArray?.push(obj);
            }
          });
          instance.systemConfigDetails = deviceArray;
        }
      } else if (['web & mobile', 'salesforce'].includes(project.type.toLowerCase())) {
        instance.systemConfigDetails = getSingleBrowserList(instance?.systemConfigDetails);
        if (tempUpdatedSelectedMachine?.systemConfigDetails?.length) {
          let configData = tempUpdatedSelectedMachine.systemConfigDetails;
          let deviceArray = [];
          configData?.forEach((obj) => {
            if (obj?.subType === 'device') {
              deviceArray?.push(obj);
            }
          });
          instance.systemConfigDetails = instance?.systemConfigDetails?.concat(deviceArray);
        }
      }
    });
    let tempArray = defaultSystem;
    tempArray?.push([...updatedSystem]);
    setDefaultSystem([...tempArray]);
  };
  const checkMachineInstanceErrors = () => {
    const updatedMachineInstanceErrors = updateMachineInstanceErrors(selectedSystem?.clientId, 'delete');
    if (!Object.keys(updatedMachineInstanceErrors)?.length) {
      getSelectedDeviceData();
    }
  };
  let checkDefaultSystemContainSelectedSystem = defaultSystem.some(
    (system) => system?.[0].clientId === selectedSystem.clientId
  );
  const validateMachineInstanceData = async () => {
    if (maxNumberOfRunsAlert(defaultSystem, 'multipleMachine', UI_VALIDATIONS.MAX_NUMBER_OF_RUNS_SHOULD_SELECT)) {
      const isValid = await multipleMachineDetailsRef.current?.validateForm();
      if (checkDefaultSystemContainSelectedSystem) {
        if (!isEmptyValue(Object.keys(machineInstanceErrors)) || !isEmptyValue(isValid?.machineInstances)) {
          if (isEmptyValue(isValid?.machineInstances)) {
            checkMachineInstanceErrors();
          } else {
            updateMachineInstanceErrors(selectedSystem?.clientId, 'add');
          }
        } else {
          getSelectedDeviceData();
        }
      } else if (isEmptyValue(Object.keys(machineInstanceErrors))) {
        getSelectedDeviceData();
      } else if (!isEmptyValue(Object.keys(machineInstanceErrors))) {
        checkMachineInstanceErrors();
      }
    } else {
      MyAlert?.info(`${EXECUTION_CONSTANTS.MAXIMUM_RUNS} ${UI_VALIDATIONS.MAX_NUMBER_OF_RUNS}`);
    }
  };

  function getSelectedDeviceData() {
    let tempDefaultSystemsCopy = [...defaultSystem];
    if (tempDefaultSystemsCopy?.length) {
      let jsonArray = [];
      tempDefaultSystemsCopy?.forEach((defaultSystemItemCopy) => {
        const machineInstances = [];
        defaultSystemItemCopy?.forEach((instanceCopy, instanceIndex) => {
          let deviceArray = [];
          let deviceName = '';
          let browserObj = {};
          if (instanceCopy?.systemConfigDetails?.length) {
            let systemConfig = instanceCopy?.systemConfigDetails;
            systemConfig?.forEach((obj) => {
              if (obj.name && obj.subType === 'device') {
                deviceArray.push(obj);
              } else if (obj.name) {
                browserObj = { ...obj };
              }
            });
            if (deviceArray?.length) {
              deviceArray?.forEach((devObj) => {
                deviceName = deviceName + (devObj?.name ? devObj?.name + ',' : '');
              });
            }
          }
          instanceCopy['rowId'] = instanceCopy?.clientId + instanceIndex;
          let selecteSystemObj = {
            clientId: instanceCopy?.clientId,
            numberOfRuns: instanceCopy?.numberOfRuns,
            executionEnv: instanceCopy?.executionEnvironment,
            browserName: browserObj?.name || null,
            browserVersion: browserObj?.version || null,
            systemUrl: '',
            machineInfo: {
              osName: instanceCopy?.machine?.osName,
              osVersion: instanceCopy?.machine?.osVersion,
              hostName: instanceCopy?.machine?.hostName,
            },
            deviceInfo: deviceArray || null,
            headless: instanceCopy?.headless || false,
            distribution: instanceCopy?.distribution || null,
          };
          if (project.type.toLowerCase() === 'web') {
            selecteSystemObj.deviceInfo = [];
          } else if (project.type.toLowerCase() === 'mobile') {
            selecteSystemObj.browserName = '';
            selecteSystemObj.browserVersion = '';
            instanceCopy['deviceNames'] = getDeviceLabel(deviceArray);
          } else if (['web & mobile', 'salesforce'].includes(project.type.toLowerCase())) {
            instanceCopy['deviceNames'] = getDeviceLabel(deviceArray);
          }
          machineInstances.push({ ...selecteSystemObj });
        });
        jsonArray?.push({ clientId: machineInstances?.[0]?.clientId, machineInstances: [...machineInstances] });
      });

      let rootModule = {
        key: props?.rootData?.key,
        title: props?.rootData?.title,
        type: 'Module',
        children: treeData,
      };
      let tempArray = [];
      tempArray.push(rootModule);
      let automaticObj = {
        selectedModulesAndScripts: tempArray,
        selectedMachines: jsonArray,
        distributionBy: 'NO_OF_SCRIPTS',
      };
      setSelectedJsonData([...jsonArray]);
      automaticDistributionPostReq(automaticObj)
        .then((res) => {
          if (res.data && res.data.responseObject) {
            const automaticDistributionData = [...res?.data?.responseObject];
            props?.automaticDistributionAllData(automaticDistributionData);
            setSelectedTableData([...tempDefaultSystemsCopy]);
            setSelectedJsonData(automaticDistributionData);
            setShowModal(false);
            setTimeout(() => reloadModuleTree(automaticDistributionData), 100);
            setDefaultSystem([...tempDefaultSystemsCopy]);
            setDefaultSystemObj([]);
            tempUpdatedSelectedMachine = {};
            setNoCallGetUpdated(false);
          } else {
            MyAlert.danger(res.data.message);
          }
        })
        .catch((error) => {
          console.error('Automatic Distribution of scripts:', error);
        });
    }
  }
  function getSingleBrowserList(systemConfig) {
    let tempArray = [];
    if (systemConfig?.length) {
      systemConfig?.forEach((obj) => {
        if (obj.subType === 'browser') {
          if (!tempArray?.length) {
            tempArray?.push(obj);
          }
        }
      });
      return tempArray;
    } else {
      return systemConfig;
    }
  }

  function getSingleBrowserListForMobile(systemConfig) {
    if (systemConfig?.length) {
      let tempArray = [];
      return tempArray;
    }
  }

  function cancelFunction() {
    getOldData(allClientSystems);
    setNoCallGetUpdated(false);
    setShowModal(false);
  }

  function showAlertMsg() {
    MyAlert.warning(
      `This license is limited to ${props?.numberOfParallelRuns} parallel runs, so you cannot select more than ${props?.numberOfParallelRuns}  machines.`
    );
  }

  const handleClose = () => {
    setDownloadClientModal(true);
  };
  function checkBoxOnChangeHandler(e, selSystem) {
    let tempArray = defaultSystem?.length ? [...defaultSystem] : [];
    if (e?.target?.checked) {
      if (props?.numberOfParallelRuns > tempArray.length) {
        let tempObj = {};
        Object.assign(tempObj, selSystem);
        tempArray?.push(tempObj);
        handleChangeDefaultMachine({ ...tempObj });
      } else {
        e.target.checked = false;
        showAlertMsg();
      }
    } else {
      if (tempArray?.length) {
        let selIndex = tempArray?.findIndex((obj) => obj?.[0]?.clientId === selSystem?.clientId);
        if (selIndex > -1) {
          tempArray?.splice(selIndex, 1);
        }
        setDefaultSystem([...tempArray]);
      }
    }
  }

  return (
    <>
      <Modal
        isOpen={showModal}
        className={
          selectedOperation === 'create'
            ? 'react-modal-main-style modal-overlay popup-height-runsettings'
            : selectedOperation === 'maximize'
            ? 'maximize-react-modal-style modal-overlay popup-height-runsettings'
            : selectedOperation === 'createdBy'
            ? 'user-react-modal-style modal-overlay popup-height-runsettings'
            : null
        }
        onRequestClose={() => {
          setShowModal(false);
        }}
      >
        <div className="testEnv-header-section">
          <span className="ml-3 fontPoppinsSemiboldSm py-0.5">
            {selectedOperation === 'maximize'
              ? ' Modules / Scripts'
              : selectedOperation === 'createdBy'
              ? `User Details -  ${selectedNodeForUser?.data?.modifiedByUname}`
              : 'Test Environments'}
          </span>
          <button
            onClick={() => {
              cancelFunction();
            }}
            type="button"
            className="float-right mr-4 text-white hover:ring-2 ring-white focus:ring-2 rounded-full"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        {selectedOperation === 'create' ? (
          <>
            <div className="alert-variable-modal-position-style">
              <AlertContatiner />
            </div>
            {!isEmptyValue(allClientSystems) ? (
              <div
                className="table_height  border-b  border-t mt-3 mb-4 border-blue-100 qrs_modal_body"
                id="journal-scroll"
              >
                <div className="m-2 qrs-system-grid-container grid grid-cols-10 gap-2.5 px-4 py-1">
                  <div className="qrs-machine-table col-span-4">
                    <MachineTable
                      inputType={'checkbox'}
                      systems={allClientSystems}
                      currentVisibleSystem={selectedSystem}
                      selectedSystems={[...defaultSystem.map((sys) => sys[0])]}
                      onRowClickHandler={handleSelectedSystem}
                      classes={''}
                      checkBoxOnChangeHandler={checkBoxOnChangeHandler}
                      errors={Object.keys(machineInstanceErrors)}
                      selectedExecutionType={props?.selectedExecutionType}
                    />
                  </div>
                  <div className="qrs-details-div col-span-6 overflow-y-auto">
                    <MachineDetails
                      executionType="parallel"
                      ref={multipleMachineDetailsRef}
                      selectedSystem={selectedSystem}
                      instanceList={instanceList}
                      defaultSystem={defaultSystemObj}
                      project={project}
                      getUpdatedSelectedMachine={getUpdatedSelectedMachine}
                      noCallGetUpdated={noCallGetUpdated}
                      setNoCallGetUpdated={setNoCallGetUpdated}
                      disableHeadless={disableHeadlessSwitchRef.current}
                      scriptTypes={props?.scriptTypes}
                      MyAlert={MyAlert}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <InvalidMachineModal
                handleClose={handleClose}
                downloadClientModal={downloadClientModal}
                closeModal={closeModal}
              />
            )}
            {!isEmptyValue(allClientSystems) && (
              <div className="float-right mr-4 pb-3 flex flex-row">
                <Button
                  className="mr-3"
                  label={LABELS_CONSTANTS.CANCEL}
                  variant="secondary"
                  onClick={() => {
                    cancelFunction();
                  }}
                />
                <Button
                  type="submit"
                  id="selectBtn"
                  label={DEFECT_CONSTANTS.SELECT}
                  variant="primary"
                  disabled={defaultSystem.length === 0}
                  onClick={() => {
                    const hasUndefined = isData.some((item) => item.machine.osName === undefined);
                    if (!hasUndefined) {
                      validateMachineInstanceData();
                    } else {
                      updateMachineInstanceErrors(selectedSystem?.clientId, 'add');
                    }
                  }}
                />
              </div>
            )}
          </>
        ) : selectedOperation === 'maximize' ? (
          <div className="table_height mt-3 mb-4 border-blue-100 qrs_modal_body" id="journal-scroll">
            <div className="react-modal-style">{renderMaxTree}</div>
          </div>
        ) : selectedOperation === 'createdBy' ? (
          <div className="table_height  border-t p-4 mt-3 mb-4 border-blue-100 qrs_modal_body" id="journal-scroll">
            <div>
              <UserDetailsPage modifiedBy={selectedNodeForUser?.data?.modifiedBy} />
            </div>
          </div>
        ) : null}
      </Modal>

      <div className="content_area_header_style">
        <div>
          <label className="main-header-label float-left ">Modules / Scripts</label>
        </div>
        <div className="flex flex-row float-right">
          {/* <FaSearch className="mt-2 mr-3 searchBtn" /> */}
          <button
            className={`${
              props?.rerunMode
                ? 'secondary-btn-disable opacity-50 cursor-auto'
                : treeData?.length
                ? 'secondary-btn'
                : 'secondary-btn-disable opacity-50 cursor-auto'
            } mr-3`}
            disabled={props?.rerunMode || !treeData?.length || selectMachineDisable}
            onClick={(e) => {
              selectModule('create');
              e.preventDefault();
            }}
          >
            Select Machines
          </button>
          <FiMaximize2
            title="Maximize"
            className={`${renderTree ? 'cursor-hand maximize-btn-style' : 'disabled-action '} mt-2 mr-3`}
            onClick={() => {
              selectModule('maximize');
            }}
          />
        </div>
      </div>
      <div className="table_tree_height">{renderTree}</div>
    </>
  );
};

export default AutomaticDistribution;
