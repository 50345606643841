import { Tooltip } from '@material-ui/core';
import { PlayCircleOutlineOutlined, SearchOutlined, Sync } from '@material-ui/icons';
import React, { useEffect, useState, useContext, useRef, useMemo } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import {
  getSingleExecutionDataReq,
  getExecutionTreeResultReq,
  updateExecutionNameAndStatusReq,
  terminateExecutionReq,
  getModuleResultTreeReq,
  getExecutionTreeResultSyncReq,
  createExecutionReq,
  reRunExecutionReq,
} from '@api/api_services';
import TableTree from '../../common/table_tree/table_tree';
import UserDetailsModal from '../shared/user-details-modal';
import { setPreselectedValue } from '../shared/create-execution-tree';
import '../test-development.scss';
import $ from 'jquery';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { getIDB, removeIDB, setIDB } from '@src/util/localForage_idb_controller';
import { getExecutionName } from '../shared/common-methods';
import {
  executionDashboardInfo,
  partialResultPageStatuses,
} from '@src/pages/test-development/execution-dashboard/execution-dashboard-util';
import TerminateWarningComponent from './modals/terminate-warning-component';
import { archive, getTruncatedText } from '@src/util/common_utils';
import { CircularProgressWithLabel, useCircularProgress } from '@src/common/CircularProgressContainer';

const RunTree = (props) => {
  localStorage.removeItem('moduleId');
  const search = window.location.search;
  const executionNameUrl = props.match.params.executionName;
  const [executionIdUrl, setExecutionIdUrl] = useState(new URLSearchParams(search).get('executionId'));
  if (executionIdUrl !== new URLSearchParams(search).get('executionId')) {
    setExecutionIdUrl(new URLSearchParams(search).get('executionId'));
  }
  const LABELS = {
    EXPAND_ALL: 'Expand All',
    COLLAPSE_ALL: 'Collapse All',
    SHOW_ALL_ELEMENTS: 'Show all scripts',
    HIDE_ALL_ELEMENTS: 'Hide all scripts',
    SHOW_PRE_POST_CONDITION: 'Show Pre/Post Conditions',
    HIDE_PRE_POST_CONDITION: 'Hide Pre/Post Conditions',
  };
  const history = useHistory();
  const [completeTree, setCompleteTree] = useState([]);
  const [execStatus, setExecStatus] = useState('');
  const [pendingState, setPendingState] = useState('');
  const [resultStatus, setResultStatus] = useState('');
  const [rootData, setRootData] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isExpandAll, setIsExpandAll] = useState(false);
  let renderTree;
  const [reloadTree, setReloadTree] = useState(false);
  const [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const syncExecStatus = useRef({});
  const [syncStatusCalled, setSyncStatusCalled] = useState(false);
  const [runSettingsApiData, setRunSettingsApiData] = useState({});
  const [executionDashboardData, setExecutionDashboardData] = useState({});
  const [isSyncDisabled, setIsSyncDisabled] = useState(false);
  const project = JSON.parse(localStorage.getItem('selected-project'));

  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);

  const { progress, startCircularProgress } = useCircularProgress(`testdev-${executionIdUrl}`);

  const isProjectOpen = React.useMemo(() => window.permission?.isProjectOpen(), []);
  let [showWarningModal, setShowWarningModal] = useState(false);
  let [selectedExeObject, setSelectedExeObject] = useState({});

  const stateColumnHtml = (node) => {
    return (
      <div className="state-col-wrp state-disabled opacity-70 pointer-events-none">
        <button className="state-button py-0 px-1 rounded-l">New</button>
        <button className="state-active py-0 px-1 rounded-l">Commit</button>
        <button className="state-button py-0 px-1 rounded-l">Publish</button>
      </div>
    );
  };

  const { hasUserViewAccessInTestDev } = useMemo(
    () => ({
      hasUserViewAccessInTestDev: window.permission?.isOnlyViewAccess('testDevelopment'),
    }),
    []
  );

  /* to find the sub tree data from the all tree data*/
  const findTreeObj = (arr, keyToFind) => {
    for (const item of arr) {
      if (item.key === keyToFind) {
        return item;
      }
      if (item.children) {
        const recursiveResult = findTreeObj(item.children, keyToFind);
        if (recursiveResult) {
          return recursiveResult;
        }
      }
    }
  };

  const onStatusClick = async (nodeObj) => {
    const search = window.location.search;
    const executionId = new URLSearchParams(search).get('executionId');
    let testDevRunId = localStorage.getItem('testDevRunId');
    if (nodeObj.data.folder || nodeObj.isRootNode) {
      if (nodeObj.data.folder) {
        JSON.stringify(localStorage.setItem('moduleName', nodeObj.data.name));
        history.push({
          pathname: `/testdevelopment/Execution Dashboard/${executionNameUrl}/Root Module/${
            nodeObj.node.parent.title === 'root' ? nodeObj.node.title : nodeObj.node.parent.title
          }/result`,
          search: `?executionId=${executionId}`,
        });
      } else {
        history.push({
          pathname: `/testdevelopment/Execution Dashboard/${executionNameUrl}/Root Module/result`,
          search: `?executionId=${executionId}`,
        });
      }
    } else {
      /** If Status of a Script clicked, updating the tree-data local storage with the current execution result tree*/
      if (nodeObj.node.type === 'Script') {
        const clientId = runSettingsApiData?.selectedSystem[0]?.clientId || nodeObj?.data?.selectedSystem?.clientId;
        let _treeData = await getIDB('tree-data');
        if (!_treeData) {
          await getModuleResultTree(executionId);
          _treeData = await getIDB('tree-data');
        }
        /** If current localStorage tree data executionId doesn't match with the execution tree id,
         *   will update the localStorage*/
        if (_treeData && _treeData.executionId !== executionId) {
          await getModuleResultTree(executionId);
          _treeData = await getIDB('tree-data');
        }
        if (_treeData) {
          let parentModule;
          if (_treeData.key === nodeObj.node.parent.key) {
            parentModule = _treeData;
          } else {
            parentModule = findTreeObj(_treeData.children, nodeObj.node.parent.key);
          }
          if (parentModule) {
            const _scriptData = [findTreeObj(parentModule.children, nodeObj.node.key)];
            if (_scriptData && _scriptData.length) {
              setIDB('script-data', _scriptData);
              history.push({
                pathname: `/testdevelopment/Execution Dashboard/${executionNameUrl}/Root Module/${nodeObj.node.parent.title}/${nodeObj.node.title}/script/result`,
                search: `?id=${nodeObj.node.key}&moduleId=${nodeObj?.data?.parentId}&executionId=${executionIdUrl}&clientId=${clientId}&runId=${testDevRunId}`,
              });
            }
          }
        }
      }
    }
  };
  function getModuleResultTree(executionId) {
    removeIDB('dependent-data');
    return getModuleResultTreeReq(executionId)
      .then((response) => {
        if (response.data.responseObject) {
          getTreeData(response.data);
        }
      })
      .catch((error) => {
        console.error('GET_MODULE_TREE_RESULT : ', error);
      });
  }

  const getTreeData = (response) => {
    let response_data = response?.responseObject?.executionResponses[0];
    let returned_data = response_data?.children;
    let dependentData = response_data?.dependantExecutionEntityResponses;
    if (dependentData && dependentData.length) {
      setIDB('dependent-data', dependentData);
    }
    if (response_data.executionId) {
      returned_data[0]['executionId'] = response_data?.executionId;
    }
    if (response_data.storageDetails) {
      returned_data[0]['storageDetails'] = response_data?.storageDetails;
    }
    /* Indexed Db */
    setIDB('tree-data', returned_data[0]);
    setIDB('breadcrumb-data', returned_data[0]);
  };
  const statusRenderer = (nodeObj) => {
    let status;
    if (nodeObj.data.status) {
      status = nodeObj.data.status;
    } else {
      status = nodeObj.isRootNode
        ? syncExecStatus.current[nodeObj.data.key]
          ? syncExecStatus.current[nodeObj.data.key]
          : ''
        : syncExecStatus.current[nodeObj.data._key]
        ? syncExecStatus.current[nodeObj.data._key]
        : '--';
    }
    if (status === 'PASS') {
      return (
        <span className="text-green-600 cursor-pointer" onClick={() => onStatusClick(nodeObj)}>
          Passed
        </span>
      );
    } else if (status === 'FAIL') {
      return (
        <span className="text-red-600 cursor-pointer" onClick={() => onStatusClick(nodeObj)}>
          Failed
        </span>
      );
    } else if (status === 'SKIP') {
      return <span className="text-gray-500 cursor-pointer">Skipped</span>;
    } else if (status === 'WARNING') {
      return (
        <span className="warning-status cursor-pointer" onClick={() => onStatusClick(nodeObj)}>
          Warning
        </span>
      );
    } else if (status === 'Terminated') {
      return (
        <span className="text-red-600 cursor-pointer" onClick={() => onStatusClick(nodeObj)}>
          Terminated
        </span>
      );
    } else if (status === 'N/A') {
      return <span className="text-gray-600">N/A</span>;
    } else if (status === 'Aborted') {
      return (
        <span
          className={`text-red-600 ${
            nodeObj?.data.type === 'Script' && nodeObj?.data?.status === 'Aborted' ? '' : ' cursor-pointer'
          }`}
          onClick={() =>
            nodeObj?.data.type === 'Script' && nodeObj?.data?.status === 'Aborted' ? '' : onStatusClick(nodeObj)
          }
        >
          Aborted
        </span>
      );
    } else {
      return <span className=""> {status} </span>;
    }
  };

  const colDefs = [
    {
      field: 'title',
      title: 'Module',
      class: 'title',
      isTitle: true,
      width: `calc(100% - 550px)`,
      folderTitleWidth: `calc(100% - 670px)`,
      render: (nodeObj) => {
        return (
          <>
            {(nodeObj.data.cascaded === 'cascaded' || nodeObj.data.cascaded === 'true') && (
              <span className="mt-1 text-blue-700 cursor-pointer hover:text-underline"> - Cascaded </span>
            )}
          </>
        );
      },
    },
    {
      field: 'scriptType',
      title: 'Type',
      class: 'Type truncate',
      width: '150px',
      render: (nodeObj) => {
        return (
          <span className="capitalize text-xs table-non-link-color-common">
            {nodeObj.data.scriptType?.toLowerCase()}
          </span>
        );
      },
    },
    {
      field: 'status',
      title: 'Status',
      class: 'status',
      width: '130px',
      render: statusRenderer,
    },
    {
      field: 'modifiedByUname',
      title: 'Modified By',
      class: 'modified-by',
      width: '200px',
      render: (nodeObj) => {
        return (
          <span className="table-non-link-color-common modifiedBy">
            {nodeObj.data.modifiedByUname === '--' ? nodeObj.data.createdByUname : nodeObj.data.modifiedByUname}
          </span>
        );
      },
    },
  ];
  const onCheckedNodes = (nodes) => {
    setSelectedNodes(nodes);
  };

  const onStatus = (nodeObj) => {
    const search = window.location.search;
    const executionId = new URLSearchParams(search).get('executionId');
    if (nodeObj.folder) {
      localStorage.removeItem('moduleName');
      history.push({
        pathname: `/testdevelopment/Execution Dashboard/${executionNameUrl}/Root Module/result`,
        search: `?executionId=${executionId}`,
      });
    }
  };

  function closeUserDetailsModal(value) {
    setOpenUserDetailsModal(value);
    setUserDetails({});
  }

  const getPendingState = (execData) => {
    const { clientStatus } = execData;
    if (!clientStatus?.clientMessage?.message) {
      return 'Checking...';
    } else if (clientStatus.clientMessage.message.startsWith('Execution is already triggered on this machine')) {
      return 'Queued';
    } else if (clientStatus.clientMessage.message.startsWith('This licens is limited to')) {
      return 'Exceeded';
    } else {
      return execData?.executionStatus;
    }
  };

  const getRunTree = async () => {
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    setIsLoading(true);
    let root;
    let rootChildren;
    try {
      const res = await getSingleExecutionDataReq(executionIdUrl);
      stopProgress();
      if (res.data && res.data.responseObject) {
        setExecutionDashboardData(res.data.responseObject);
        setRunSettingsApiData({ ...res.data.responseObject.preExecution });
        setExecStatus(res.data.responseObject.executionStatus);
        setPendingState(getPendingState(res.data.responseObject));
        setResultStatus(res.data.responseObject?.resultStatus);
        const executionResultSync = await getExecutionTreeResultSyncReq(executionIdUrl);
        setExecutionDashboardData({
          ...res.data.responseObject,
          runId: executionResultSync?.data?.responseObject?.executionResponses[0]?.runId,
        });
        let fancyTree;
        let rootDetails;
        if (
          res.data.responseObject.executionStatus === 'Completed' ||
          res.data.responseObject.executionStatus === 'Terminated'
        ) {
          if (res.data.responseObject.fancyTreeData) {
            rootDetails = res.data.responseObject.fancyTreeData.responseObject.executionResponses[0].children[0];
          } else {
            const fancyTreeResponse = await getModuleResultTreeReq(executionIdUrl);
            let executionResponses = fancyTreeResponse?.data?.responseObject?.executionResponses;
            if (executionResponses) {
              fancyTree = executionResponses[0]?.children;
              let exeResponse = executionResponses[0];
              localStorage.setItem('testDevRunId', exeResponse?.runId);
              rootDetails = fancyTree[0];
            }
          }
        } else {
          rootDetails = res.data.responseObject.preExecution.executionTree[0];
        }
        setCompleteTree(rootDetails);
        root = {
          subModuleCount: rootDetails.subModuleCount,
          title: rootDetails.title,
          scriptCount: rootDetails.scriptCount,
          key: rootDetails.key,
        };
        if (rootDetails?.status) {
          root.status = rootDetails?.status;
        }
        if (rootDetails.title === 'Root Module') {
          if (rootDetails.children) {
            rootChildren = rootDetails.children;
          } else {
            rootChildren = [];
          }
        } else {
          if (
            res.data.responseObject.executionStatus === 'Completed' ||
            res.data.responseObject.executionStatus === 'Terminated'
          ) {
            if (fancyTree) {
              rootChildren = fancyTree;
            } else {
              rootChildren = res.data.responseObject.fancyTreeData.responseObject.executionResponses[0].children;
            }
          } else {
            rootChildren = res.data.responseObject.preExecution.executionTree;
          }
        }
        setRootData(root);
        setIsExpandAll(rootDetails.expanded);
        setTreeData(rootChildren);
      } else {
        setRootData({});
        setIsExpandAll(false);
        setTreeData([]);
      }
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      setIsLoading(false);
    } catch (err) {
      resetProgress();
      setIsLoading(false);
      console.error('GET_SINGLE_EXECUTION_DATA : ', err);
    }
  }

  if (isLoading) {
    renderTree = <CommonLoader />;
  } else if (treeData && treeData.length > 0) {
    renderTree = (
      <TableTree
        executionData={executionDashboardData}
        isExecutionTree={true}
        disableRootCheckbox={execStatus === 'Running' || execStatus === 'Pending'}
        data={treeData}
        rootData={rootData}
        runId={executionDashboardData?.runId}
        colDefs={colDefs}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={false}
        showCheckbox={false}
        conditions={[
          { key: 'title', value: 'Preconditions' },
          { key: 'title', value: 'Postconditions' },
        ]}
        defaultDisabled={
          execStatus === 'Running' || execStatus === 'Pending'
            ? [
                { key: 'folder', value: true },
                { key: 'folder', value: undefined },
              ]
            : []
        }
        hideConditionBtn={false}
        onCheckedNodes={onCheckedNodes}
        labels={LABELS}
        onNodeSelected={() => {}}
        id="run-tree"
      />
    );
  }
  async function terminateExecution(id) {
    try {
      resetProgress();
      startOrResumeProgress({
        stopAt: 90,
      });
      setIsLoading(true);
      const res = await terminateExecutionReq(id, {});
      stopProgress();
      if (res?.data?.status === 'SUCCESS') {
        setExecStatus('Terminated');
        executionDashboardData['executionStatus'] = 'Terminated';
        const { name } = executionDashboardData || {};
        const execName = getTruncatedText(name, 30);
        props.MyAlert.success(`${execName} execution terminated successfully`);
      } else {
        props.MyAlert.warning(`${res.data.message}`);
      }
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      setIsLoading(false);
    } catch (err) {
      resetProgress();
      setIsLoading(false);
      console.error('TERMINATE_EXECUTION : ', err);
    }
  }

  async function updateExecutionStatus(id) {
    try {
      const response = await getExecutionTreeResultReq(id);
      let exeResponse = response.data.responseObject.executionResponses[0];
      localStorage.setItem('testDevRunId', exeResponse?.runId);
      if (
        response.data.responseObject &&
        response.data.responseObject.executionResponses &&
        response.data.responseObject.executionResponses.length
      ) {
        if (exeResponse.executionStatus !== 'Running' && exeResponse.executionStatus !== 'Pending') {
          getRunTree();
        } else {
          try {
            resetProgress();
            startOrResumeProgress({
              stopAt: 90,
            });
            const syncResponse = await getExecutionTreeResultSyncReq(id);
            stopProgress();

            if (syncResponse?.data?.responseObject) {
              const { executionResponses } = syncResponse.data.responseObject;
              let tree_data = executionResponses[0].children ? executionResponses[0].children : [];
              if (executionResponses[0].executionId) {
                tree_data[0]['executionId'] = executionResponses[0].executionId;
              }
              if (executionResponses[0].storageDetails) {
                tree_data[0]['storageDetails'] = executionResponses[0].storageDetails;
              }
              setIDB('tree-data', tree_data[0]);
              if (executionResponses[0]?.executionStatusObject) {
                Object.entries(executionResponses[0]?.executionStatusObject).forEach(([key, value]) => {
                  syncExecStatus.current[key] = value;
                });
                $.ui.fancytree.getTree(`#${'run-tree'}`).reload();
                $.ui.fancytree.getTree(`#${'run-tree'}`).expandAll(true);
              }
            }

            startOrResumeProgress({
              stopAt: 100,
              intervalDuration: 10,
            });
            setIsLoading(false);
          } catch (err) {
            console.error('PARTIAL_RESULT_SYNC : ', err);
            resetProgress();
            setIsLoading(false);
          }
        }
      }
    } catch (err) {
      console.log(err);
      resetProgress();
      setIsLoading(false);
    }
  }
  async function handleRun() {
    try {
      const res = await reRunExecutionReq(executionIdUrl, {});
      if (res.data && res.data.responseObject) {
        props.history.push(`/testdevelopment/Execution Dashboard`);
        archive.testDevFlags[project.id] = false;
      } else if (res?.data?.message && res?.data?.message.toLowerCase().includes('please connect a device')) {
        props.MyAlert.info(res?.data?.message);
      } else if (res?.data?.message && res?.data?.message.toLowerCase().includes('please install a browser')) {
        props.MyAlert.info(res?.data?.message);
      } else if (
        res?.data?.message &&
        res?.data?.message.startsWith('Client') &&
        res?.data?.message.toLowerCase().includes('is not available')
      ) {
        props.MyAlert.info(res?.data?.message);
      } else {
        props.MyAlert.warning(`${res.data.message}`);
      }
    } catch (err) {
      console.error('RE_RUN_ERROR :', err);
    }
  }

  async function handleRunOnlyFailed() {
    let exeJson = setPreselectedValue(completeTree.children);
    let rootModule = {
      key: rootData?.key,
      title: rootData?.title,
      type: 'Module',
      children: exeJson,
    };

    let executionDataJson = {};
    executionDataJson = {
      name: getExecutionName([rootModule]),
      executionTree: [rootModule],
      installedDir: 'D:\\resources\\macine_config',
      screenshotRequired: runSettingsApiData?.screenshotRequired,
      failedStepsScreenshot: runSettingsApiData?.failedStepsScreenshot,
      videoRequired: 'false',
      waitTime: { ...runSettingsApiData?.waitTime },
      selectedSystem: runSettingsApiData?.selectedSystem,
      projectName: project.name,
      projectId: project.id,
      type: 'INDIVIDUAL',
    };
    const executionResponse = await createExecutionReq(executionDataJson);
    if (executionResponse && executionResponse.data && executionResponse.data.responseObject) {
      props.history.push(`/testdevelopment/Execution Dashboard`);
      archive.testDevFlags[project.id] = false;
    } else if (
      executionResponse?.data?.message &&
      executionResponse?.data?.message.toLowerCase().includes('please connect a device')
    ) {
      props.MyAlert.info(executionResponse?.data?.message);
    } else if (
      executionResponse?.data?.message &&
      executionResponse?.data?.message.toLowerCase().includes('please install a browser')
    ) {
      props.MyAlert.info(executionResponse?.data?.message);
    } else if (
      executionResponse?.data?.message &&
      executionResponse?.data?.message.startsWith('Client') &&
      executionResponse?.data?.message.toLowerCase().includes('is not available')
    ) {
      props.MyAlert.info(executionResponse?.data?.message);
    } else if (executionResponse.data && executionResponse.data.responseCode === 400) {
      props.MyAlert.warning(`${executionResponse.data.message}`);
    }
  }

  useEffect(() => {
    if (syncStatusCalled) {
      updateExecutionStatus(executionIdUrl);
    }
    setSyncStatusCalled(false);
  }, [syncStatusCalled]);

  useEffect(() => {
    if (reloadTree) {
      setTreeData([]);
      getRunTree();
    }
    setReloadTree(false);
  }, [reloadTree]);

  useEffect(() => {
    if (executionIdUrl) {
      setReloadTree(true);
    }
  }, [executionIdUrl]);

  useEffect(() => {
    let timerId = null;
    if (isSyncDisabled) {
      timerId = setTimeout(() => {
        setIsSyncDisabled(false);
      }, process.env.REACT_APP_SUITE_EXECUTION_INTERVAL || 10000);
    }
    return () => clearTimeout(timerId);
  }, [isSyncDisabled]);

  const getExecutionData = () => {
    setSelectedExeObject({ ...executionDashboardData });
  };

  return (
    <div className="content_area_style" id="journal-scroll">
      <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
        <label className="project_label text-sm flex-auto pb-2" title={`${executionNameUrl}`}>
          {executionNameUrl.length > 20 ? executionNameUrl.substring(0, 20) + '...' : executionNameUrl}
          {['Running', 'Fetching Results', 'Processing Result'].includes(execStatus) && (
            <span className="rounded-xl text-xs bg-blue-100 text-blue-700 px-2 py-0.5 ml-3">{execStatus}</span>
          )}
          {execStatus === 'Pending' && (
            <span className={`bg-yellow-400 text-xs rounded-xl text-white px-2 py-0.5 ml-3`}>{pendingState}</span>
          )}
          {execStatus === 'Cancelled' && (
            <span className="text-xs rounded-xl bg-gray-100 text-gray-800 px-2 py-0.5 ml-3">{execStatus}</span>
          )}
          {execStatus === 'Terminated' && (
            <>
              {executionDashboardData?.resultStatus ? (
                <span
                  className="text-xs rounded-xl bg-red-600 text-white px-2 py-0.5 ml-3 cursor-pointer"
                  onClick={() => onStatus(completeTree)}
                >
                  {execStatus}
                </span>
              ) : (
                <>
                  <span className="text-xs rounded-xl bg-red-600 text-white px-2 py-0.5 ml-3 opacity-50">
                    {execStatus}
                  </span>{' '}
                  {executionDashboardInfo(executionDashboardData)}
                </>
              )}
            </>
          )}
          {execStatus === 'Completed' && resultStatus === 'PASS' && (
            <span
              className="text-xs rounded-xl bg-green-600 text-white px-2 py-0.5 ml-3 cursor-pointer bggreencolor"
              onClick={() => onStatus(completeTree)}
            >
              Passed
            </span>
          )}
          {execStatus === 'Completed' && resultStatus === 'FAIL' && (
            <span
              className="text-xs rounded-xl bg-red-600 text-white px-2 py-0.5 ml-3 cursor-pointer"
              onClick={() => onStatus(completeTree)}
            >
              Failed
            </span>
          )}
          {execStatus === 'Completed' && resultStatus === 'WARNING' && (
            <span
              className="text-xs rounded-xl result-badge-warning text-white px-2 py-0.5 ml-3 cursor-pointer"
              onClick={() => onStatus(completeTree)}
            >
              Warning
            </span>
          )}
          {execStatus === 'Completed' && resultStatus === 'Aborted' && (
            <span
              className="text-xs rounded-xl bg-red-600 text-white px-2 py-0.5 ml-3 cursor-pointer"
              onClick={() => onStatus(completeTree)}
            >
              Aborted
            </span>
          )}
          {resultStatus === 'N/A' && execStatus !== 'Terminated' && (
            <span className="text-xs rounded-xl bg-red-600 text-white px-2 py-0.5 ml-3">N/A</span>
          )}
          {partialResultPageStatuses.includes(execStatus) && (
            <span className="rounded-xl text-xs bg-yellow-400 text-white px-2 py-0.5 ml-3">{execStatus}</span>
          )}
        </label>
        <div className="pr-4">
          <div className="flex flex-row ml-32">
            {/* <Tooltip title="Search" placement="top">
                            <SearchOutlined fontSize='small' className="text-gray-500 mr-3 ml-3 mt-1 cursor-pointer hover:text-blue-700" />
                        </Tooltip> */}
            {execStatus === 'Pending' && isProjectOpen && !hasUserViewAccessInTestDev && (
              <button
                onClick={() => {
                  setShowWarningModal(true);
                  getExecutionData(executionIdUrl);
                }}
                className="cursor-pointer mr-3"
              >
                <Tooltip title="Terminate" placement="top">
                  <img className="mt-1 text-sm" src="/assets/images/terminateExecution.svg" alt="Terminate Execution" />
                </Tooltip>
              </button>
            )}
            {isProjectOpen &&
              (progress === 0 ? (
                <button
                  className="float-left"
                  onClick={() => {
                    if (!isSyncDisabled) {
                      startCircularProgress();
                      setIsSyncDisabled(true);
                      setSyncStatusCalled(true);
                    }
                  }}
                >
                  <Tooltip title="Sync Status" placement="top">
                    <Sync fontSize="small" className="mr-3" />
                  </Tooltip>
                </button>
              ) : (
                <CircularProgressWithLabel value={progress} />
              ))}
            {['Running', ...partialResultPageStatuses].includes(execStatus) && isProjectOpen && !hasUserViewAccessInTestDev && (
              <button
                onClick={() => {
                  setShowWarningModal(true);
                  getExecutionData(executionIdUrl);
                }}
                className="cursor-pointer mr-3"
              >
                <Tooltip title="Terminate" placement="top">
                  <img className="mt-1 text-sm" src="/assets/images/terminateExecution.svg" alt="Terminate Execution" />
                </Tooltip>
              </button>
            )}
            {execStatus === 'Completed' && resultStatus === 'FAIL' && isProjectOpen && !hasUserViewAccessInTestDev && (
              <button
                onClick={() => {
                  handleRunOnlyFailed();
                }}
                className="mr-3 mt-1 cursor-pointer"
              >
                <Tooltip title="Run only failed" placement="top">
                  <img src="/assets/images/RunOnlyFailedBlue.svg" alt="Run only failed" />
                </Tooltip>
              </button>
            )}
            {['Running', 'Completed', 'Terminated', ...partialResultPageStatuses].includes(execStatus) &&
              isProjectOpen &&
              !hasUserViewAccessInTestDev && (
                <button
                  disabled={['Running', ...partialResultPageStatuses].includes(execStatus)}
                  onClick={() => {
                    handleRun();
                  }}
                  className={
                    ['Running', ...partialResultPageStatuses].includes(execStatus)
                      ? 'float-left opacity-50'
                      : 'float-left'
                  }
                >
                  <Tooltip title="Run" placement="top">
                    <PlayCircleOutlineOutlined fontSize="small" className="text-blue-600 mt-1 cursor-pointer" />
                  </Tooltip>
                </button>
              )}
          </div>
        </div>
      </div>
      <div className="table-height overflow-auto">{renderTree}</div>

      {showWarningModal && (
        <TerminateWarningComponent
          executionData={selectedExeObject}
          setShowWarningModal={setShowWarningModal}
          MyAlert={props?.MyAlert}
          actionKey="Terminate"
          terminateExecution={terminateExecution}
        />
      )}

      {openUserDetailsModal ? <UserDetailsModal data={userDetails} closeModal={closeUserDetailsModal} /> : null}
    </div>
  );
};

export default withRouter(RunTree);
