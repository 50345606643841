import React from 'react';
import { ClipLoader } from 'react-spinners';
import { getHtmlReportDataReq, getReportData } from '@api/api_services';
import { saveFileFromBlob } from '@pages/test-development/shared/common-methods';
import { MenuOption } from 'fireflink-ui';
import { getCurrentLicenseId } from '@src/util/common_utils';

const REPORT_OPTIONS = [
  {
    label: 'HTML',
    value: 'HTML_REPORT',
  },
  {
    label: 'PDF',
    value: 'PDF_REPORT',
  },
];
export const LazyDownloadPDFButton = ({ e, size, MyAlert}) => {
  const executionId = e?.data?.length ? e?.data[e?.row?.id]?._id : e;
  const [isLoading, setIsLoading] = React.useState(false);

  const getPdfReportData = async () => {
    if (executionId) {
      try {
        setIsLoading(true);
        const response = await getReportData(executionId);
        setIsLoading(false);
        if (response.status === 200) {
          saveFileFromBlob(response.data, `ExecutionReport_${executionId}.pdf`);
        } else {
          alert('File not found');
        }
      } catch (err) {
        setIsLoading(false);
        console.error('ERROR_WHILE_GENERATING_PDF_IN EXECUTION_DASHBOARD', err);
      }
    }
  };
  const getHtmlReportData = async () => {
    if (executionId) {
      const currentLicenseId = getCurrentLicenseId();
      try {
        setIsLoading(true);
        const response = await getHtmlReportDataReq(executionId, currentLicenseId);
        setIsLoading(false);
        if (response.status === 200) {
          saveFileFromBlob(response.data, `ExecutionReport_${executionId}.html`);
        } else  {
          const alertMessage = response.status === 202 ? "Html Report generation is in-progress, please try again later" : 'File not found';
          MyAlert.info(alertMessage);
        }
      } catch (err) {
        setIsLoading(false);
        console.error('ERROR_WHILE_GENERATING_HTML_IN EXECUTION_DASHBOARD', err);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <ClipLoader color="blue" loading={isLoading} size={size ? size : 22} />
      ) : (
        <MenuOption
          tooltipTitle="Download"
          iconName="download"
          options={REPORT_OPTIONS}
          onOptionClick={({ value }) => {
            if (value === 'PDF_REPORT') {
              getPdfReportData();
            } else {
              getHtmlReportData();
            }
          }}
        />
      )}
    </>
  );
};
