import React, { useEffect, useState, useContext, useRef } from 'react';
import ReactTable from '@pages/common/Table/ReactTable';
import DefectCreateModal from './defect-create-modal';
import ContextMenu from '@pages/common/context_menu';
import UserDetailsModal from '@pages/test-development/shared/user-details-modal';
import {
  getAllDefectsWithPaginationReq,
  getSingleUserRequest,
  getTemplateForEmailConfigReq,
  getAnalyticsReq,
} from '@api/api_services';
import { Link, useHistory } from 'react-router-dom';
import SelectDefectTemplate from './select-defect-template';
import { EditOutlined } from '@material-ui/icons';
import { CommonLoader } from '@pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import SearchBar from '@src/pages/analytics/common/search-bar';
import {
  HighlightText,
  isEmptyValue,
  trimModuleName,
  convertDateHyphenReverse,
  getVerifiedDateAuto,
  isSearchFilterEnabled,
} from '@util/common_utils';
import NoResultsFound from '@src/pages/common/Table/NoResultFound';
import Tooltip from '@mui/material/Tooltip';
import { MESSAGES } from './defectMgmtConstants';
import FilterModal from '@pagescommon/filters/filter-modal';
import MultipleSelectCheckmarks from '@pagescommon/multi_select_checkbox_dropdown/multiple-select-checkmarks.js';
import FormControl from '@mui/material/FormControl';
import Chip from '@pagescommon/chip/chip.js';
import { defectAnalyticsLabel, filterDisplayDateFormat, getSelectedFilterLableName } from '@common/ui-constants';
import DateSelector from '@pagescommon/date/date-selector';

function DefectTablePage(props) {
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);
  const [isLoading, setIsLoading] = useState(true);
  const [reloadTable, setReloadTable] = useState(false);
  const [showDefectModal, setShowDefectModal] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [defectsData, setDefectsData] = useState([]);
  const [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [modalType, setModalType] = useState('');
  let project = JSON.parse(localStorage.getItem('selected-project'));
  const [lifeCycleTemplates, setLifeCycleTemplates] = useState([]);
  const [detailsTemplates, setDetailsTemplates] = useState([]);
  const [defectMgmtData, setDefectMgmtData] = useState({});
  const [selectedDefectData, setSelectedDefectData] = useState({});
  const allowedDefect = JSON.parse(localStorage.getItem('allowedProjects'));
  const [defectLimitExceeded, setDefectLimitExceeded] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(false);
  const [paginatedDefectsData, setPaginatedDefectsData] = useState([]);
  const [totalDefectsCount, setTotalDefectsCount] = useState(0);
  const [createdDefectData, setCreatedDefectData] = useState({});
  const [updatedTotalDefectsCount, setUpdatedTotalDefectsCount] = useState({});
  const [pageNo, setPageNo] = useState(0);
  const [defectId, setDefectId] = useState();
  const defectTableRef = useRef(null);
  const [isDataFound, setIsDataFound] = useState(true);
  const pageSize = 20;
  const displaySearchFilter = isSearchFilterEnabled('defects');

  const history = useHistory();
  const reloadHandler = (totalCountDefect, defectCreatedData, defectDataId) => {
    setUpdatedTotalDefectsCount(totalCountDefect);
    setCreatedDefectData(defectCreatedData);
    setDefectId(defectDataId);
    setReloadTable(true);
  };
  const contextMenuData = [
    {
      option: 'Details',
      icon: '/assets/images/eye_logo.svg',
    },
    // {
    //   option: "History",
    //   icon: "/assets/images/clone_logo.svg",
    // },
  ];

  useEffect(() => {
    if (!searchTextHighlight && !isFilterApplied) {
      // Note::  stop scroll api if searched || Filter applied
      if (scrollPosition && paginatedDefectsData?.length < totalDefectsCount && isDataFound) {
        const updatedPageNo = pageNo + 1;
        setPageNo(updatedPageNo);
        getpaginatedDefectsData(updatedPageNo, pageSize);
      }
      setScrollPosition(false);
    }
  }, [scrollPosition]);

  useEffect(() => {
    props.totalNoOfDefects(totalDefectsCount);
  }, [totalDefectsCount]);

  const getpaginatedDefectsData = async (pageNumber, pageSize, reset) => {
    setIsDataFound(false);
    let paginatedResponse = await getAllDefectsWithPaginationReq(project.id, pageNumber, pageSize);
    let newDefectsData = [];
    newDefectsData = paginatedResponse?.data?.responseObject;
    let previousDefectData = reset ? [] : paginatedDefectsData;
    if (newDefectsData?.length) {
      previousDefectData.push(...newDefectsData);
      setTotalDefectsCount(paginatedResponse?.data?.responseMap?.totalDefectsCount);
      setPaginatedDefectsData(previousDefectData);
      getAllDefects(previousDefectData);
    }
    setIsDataFound(true);
  };

  const getTemplateForEmailConfig = async (pageReset) => {
    let pageNoToCall = pageReset === 0 ? pageReset : pageNo;

    try {
      resetProgress();
      startOrResumeProgress({
        stopAt: 90,
      });
      setIsLoading(true);
      let response = await getTemplateForEmailConfigReq(project.id);
      let paginatedResponseObject;
      if (response?.data?.responseObject?.templates_selected) {
        let paginatedResponse = await getAllDefectsWithPaginationReq(project.id, pageNoToCall, pageSize);
        setTotalDefectsCount(paginatedResponse?.data?.responseMap?.totalDefectsCount);
        paginatedResponseObject = paginatedResponse?.data?.responseObject;
        setPaginatedDefectsData(paginatedResponseObject);
      }

      if (response?.data?.responseMap?.defaultDefectCount > allowedDefect?.privilege?.defects?.numberOfDefects) {
        setDefectLimitExceeded(true);
      }
      stopProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 10,
      });
      if (response?.data?.status === 'SUCCESS' && response?.data?.responseObject) {
        let template = response?.data?.responseObject;
        if (template.default_defectLifeCycle_templates) {
          template?.defect_life_cycle_templates.unshift(template.default_defectLifeCycle_templates);
        }
        if (template.default_defectDetails_templates) {
          template?.defect_details_templates.unshift(template.default_defectDetails_templates);
        }
        setDefectMgmtData({ ...template });
        props.handelDisableEmailConfig(template.templates_selected);
        setLifeCycleTemplates([...template?.defect_life_cycle_templates]);
        setDetailsTemplates([...template?.defect_details_templates]);
        if (paginatedResponseObject?.length) {
          getAllDefects(paginatedResponseObject);
          props.handelDisableExportDefect(paginatedResponseObject);
        }
      }
      setIsLoading(false);
    } catch (err) {
      resetProgress();
      setIsLoading(false);
      console.error('get Template For Email Config api error', err);
    }
  };

  const closeUserDetailsModal = (value) => {
    setOpenUserDetailsModal(value);
    setUserDetails({});
  };
  const getUserDetails = async (key) => {
    try {
      const res = await getSingleUserRequest(key);
      if (res?.data?.responseCode === 200 && res?.data?.responseObject) {
        setUserDetails(res.data.responseObject);
        setOpenUserDetailsModal(true);
      }
    } catch (err) {
      console.error('GET_USER_DETAILS : ', err);
    }
  };

  const contextMenuFunction = (event, resObj) => {
    if (event.target.textContent === 'Details') {
      if (resObj?.defectDetails?.ID) {
        history.push(`/defect/${resObj.defectDetails.ID}/details`);
      }
    } else if (event.target.textContent === 'History') {
    }
  };

  const getAllDefects = async (defects) => {
    let defectsDataArray = [];
    defects?.forEach((res, i) => {
      const Actions = [
        <>
          {(isEditable || hasFullAccess) && (
            <button
              disabled={false}
              className={'float-left project-action mr-3 text-blue-700 text-base cursor-pointer'}
              onClick={() => {
                setSelectedDefectData(res);
                setModalType('edit');
                setShowDefectModal(true);
              }}
            >
              <span data-title="Edit" className="rounded-l float-left text-center ml-1">
                <EditOutlined />
              </span>
            </button>
          )}
          <button disabled={false} className={'float-left project-action pt-1 text-blue-700 text-base cursor-pointer'}>
            <ContextMenu
              contextMenuData={contextMenuData}
              func={(e) => {
                contextMenuFunction(e, res);
              }}
              onMouseOutz
              configSection={true}
            />
          </button>
        </>,
      ];
      defectsDataArray.push({
        ...res.defectDetails,
        Actions: Actions,
      });
    });

    setDefectsData([...defectsDataArray]);
    setIsLoading(false);
  };

  // TODO :: handle Search filter :: START

  const [userSearched, setuserSearched] = useState('');
  const [data, setData] = useState([]);
  let [openSearch, setOpenSearch] = useState(false);
  let [isHighlight, setIsHighlight] = useState(false);
  let [searchTextHighlight, setSearchTextHighlight] = useState('');

  let [isFilterApplied, setIsFilterApplied] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  let openFilterModal = Boolean(anchorEl);
  const [severity, setSeverity] = React.useState([]);
  const [priority, setPriority] = React.useState([]);
  const [assignTo, setAssignTo] = React.useState([]);
  const [createdBy, setCreatedBy] = React.useState([]);
  const [module, setModule] = React.useState([]);
  const [labels, setLabels] = React.useState([]);
  const [modifiedBy, setModifiedBy] = useState([]);

  const [createdOn, setCreatedOn] = React.useState({
    from: null,
    to: null,
  });
  const [modifiedOn, setModifiedOn] = React.useState({
    from: null,
    to: null,
  });

  const [disableApplyClearBtn, setDisableApplyClearBtn] = useState(true);

  const [optionsListItem, setOptionsListItem] = useState({
    severity: [],
    priority: [],
    assignTo: [],
    createdBy: [],
    module: [],
    labels: [],
    modifiedBy: [],
  });

  const handleSelectDropdown = (event, action, perfromOn) => {
    if (action === 'add') {
      const selectedValue = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
      switch (perfromOn) {
        case 'severity':
          return setSeverity(selectedValue);
        case 'priority':
          return setPriority(selectedValue);
        case 'assignTo':
          return setAssignTo(selectedValue);
        case 'createdBy':
          return setCreatedBy(selectedValue);
        case 'module':
          return setModule(selectedValue);
        case 'labels':
          return setLabels(selectedValue);
        case 'modifiedBy':
          return setModifiedBy(selectedValue);
      }
    } else if (action === 'delete') {
      let valuetoDelete = typeof event === 'string' ? event : '';
      switch (perfromOn) {
        case 'severity':
          return setSeverity(severity.filter((val) => val !== valuetoDelete));
        case 'priority':
          return setPriority(priority.filter((val) => val !== valuetoDelete));
        case 'assignTo':
          return setAssignTo(assignTo.filter((val) => val !== valuetoDelete));
        case 'createdBy':
          return setCreatedBy(createdBy.filter((val) => val !== valuetoDelete));
        case 'module':
          return setModule(module.filter((val) => val !== valuetoDelete));
        case 'labels':
          return setLabels(labels.filter((val) => val !== valuetoDelete));
        case 'modifiedBy':
          return setModifiedBy(modifiedBy.filter((val) => val !== valuetoDelete));
      }
    }
  };

  let renderChips = (listName) => {
    switch (listName) {
      case 'severity':
        return severity;
      case 'priority':
        return priority;
      case 'assignTo':
        return assignTo;
      case 'createdBy':
        return createdBy;
      case 'module':
        return module;
      case 'labels':
        return labels;
      case 'modifiedBy':
        return modifiedBy;
    }
  };

  let showSelectedList = [
    { priority },
    { severity },
    { assignTo },
    { createdBy },
    { module },
    { labels },
    { modifiedBy },
  ];

  let handleCreatedOnDate = (e, action) => {
    if (action == 'from') {
      setCreatedOn((perVal) => ({ ...perVal, from: e }));
    } else if (action == 'to') {
      setCreatedOn((perVal) => ({ ...perVal, to: e }));
    }
  };

  let handleModifiedOnDate = (e, action) => {
    if (action == 'from') {
      setModifiedOn((perVal) => ({ ...perVal, from: e }));
    } else if (action == 'to') {
      setModifiedOn((perVal) => ({ ...perVal, to: e }));
    }
  };

  const handleFilterOpen = async (event) => {
    setAnchorEl(event.currentTarget);

    let payload = {
      module: 'defect',
      collectionName: 'defects',
      responseFilterRequired: 'true',
      searchText: '',
      facetQueries: [],
    };
    try {
      let response = await getAnalyticsReq(payload);
      let filterResponse = response?.data?.filterResponse || [];

      filterResponse?.filter((val) => {
        if (Object?.keys(val?.values)?.length) {
          return (val.values = Object?.keys(val?.values));
        } else {
          return (val.values = []);
        }
      });

      let getValues = (data, type) => {
        let values = [];
        data?.forEach((val) => {
          if (val?.name === type) {
            values = val?.values;
          }
        });
        return values;
      };

      setOptionsListItem({
        severity: getValues(filterResponse, 'severity'),
        priority: getValues(filterResponse, 'priority'),
        assignTo: getValues(filterResponse, 'assignTo'),
        createdBy: getValues(filterResponse, 'createdBy'),
        module: getValues(filterResponse, 'module'),
        labels: getValues(filterResponse, 'labels'),
        modifiedBy: getValues(filterResponse, 'modifiedBy'),
      });

      if (isFilterApplied) {
        let retriveData = JSON.parse(sessionStorage.getItem('defectsFilters') || null);
        setSeverity(retriveData?.severity);
        setPriority(retriveData?.priority);
        setAssignTo(retriveData?.assignTo);
        setCreatedBy(retriveData?.createdBy);
        setModule(retriveData?.module);
        setLabels(retriveData?.labels);
        setModifiedBy(retriveData?.modifiedBy);
        setCreatedOn({
          from: convertDateHyphenReverse(retriveData?.createdOn?.from),
          to: convertDateHyphenReverse(retriveData?.createdOn?.to),
        });
        setModifiedOn({
          from: convertDateHyphenReverse(retriveData?.modifiedOn?.from),
          to: convertDateHyphenReverse(retriveData?.modifiedOn?.to),
        });
      }
    } catch (err) {
      console.error('ERROR IN GET OPTION LIST', err);
    }
  };

  const handleFilterClose = () => {
    if (!isFilterApplied) {
      setSeverity([]);
      setPriority([]);
      setAssignTo([]);
      setCreatedBy([]);
      setModule([]);
      setLabels([]);
      setModifiedBy([]);
      setCreatedOn({ from: null, to: null });
      setModifiedOn({ from: null, to: null });
    }
    setAnchorEl(null);
  };

  let handleApplyFilter = async (action) => {
    if (action === defectAnalyticsLabel.btnLabel.apply) {
      let newData = await getSearchResult(searchTextHighlight, action);
      setData(newData);
    } else if (action === defectAnalyticsLabel.btnLabel.clearAll) {
      setSeverity([]);
      setPriority([]);
      setAssignTo([]);
      setCreatedBy([]);
      setModule([]);
      setLabels([]);
      setModifiedBy([]);
      setCreatedOn({ from: null, to: null });
      setModifiedOn({ from: null, to: null });
      setPageNo(0);
      if (isFilterApplied) {
        setIsFilterApplied(false);
        getTemplateForEmailConfig(0);
        sessionStorage.removeItem('defectsFilters');
      }
    }
  };

  useEffect(() => {
    setData(defectsData);
    if (openSearch && userSearched) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [defectsData]);

  useEffect(() => {
    const handleAsync = async () => {
      if (openSearch && !userSearched && searchTextHighlight && !isFilterApplied) {
        setPageNo(0);
        getpaginatedDefectsData(0, pageSize, true);
        setSearchTextHighlight('');
        setIsHighlight(!isHighlight);
      } else if (!userSearched && searchTextHighlight && isFilterApplied) {
        setSearchTextHighlight('');
        setIsHighlight(!isHighlight);
        let newData = await getSearchResult(userSearched, defectAnalyticsLabel.btnLabel.apply);
        setData(newData);
      }
    };
    handleAsync();
  }, [userSearched]);

  const handleSearch = (e) => {
    setuserSearched(e.target.value);
  };

  let handleSearchClose = async () => {
    if (openSearch && searchTextHighlight && !isFilterApplied) {
      setPageNo(0);
      getpaginatedDefectsData(0, pageSize, true);
    }

    setOpenSearch(false);
    setuserSearched('');
    setSearchTextHighlight('');
    setIsHighlight(!isHighlight);

    if (isFilterApplied) {
      let newData = await getSearchResult('', defectAnalyticsLabel.btnLabel.apply);
      setData(newData);
    }
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  let handleSearchSubmit = async (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        resetProgress();
        startOrResumeProgress({
          stopAt: 90,
        });
        setIsLoading(true);

        let newData = await getSearchResult(e?.target?.value?.trim() || e?.trim());
        stopProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
        setIsLoading(false);
        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
        setIsHighlight(!isHighlight);
        setData(newData);
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  let getSearchResult = async (searchKey, action) => {
    let payload = {
      module: 'defect',
      collectionName: 'defects',
      responseFilterRequired: 'true',
      searchText: isFilterApplied ? searchKey : searchKey || searchTextHighlight || '',
      facetQueries: [
        { name: 'severity', values: severity },
        { name: 'priority', values: priority },
        { name: 'assignTo', values: assignTo },
        { name: 'createdBy', values: createdBy },
        { name: 'module', values: module },
        { name: 'labels', values: labels },
        { name: 'modifiedBy', values: modifiedBy },
        { name: 'createdOn', values: getVerifiedDateAuto(createdOn) },
        { name: 'modifiedOn', values: getVerifiedDateAuto(modifiedOn) },
      ],
    };

    payload['facetQueries'] = payload['facetQueries']?.filter((obj) => {
      if (obj?.values?.length) return obj;
    });

    try {
      let response = await getAnalyticsReq(payload);

      if (action === defectAnalyticsLabel.btnLabel.apply) {
        setIsFilterApplied(true);
        let selectedFilterList = {
          severity,
          priority,
          assignTo,
          createdBy,
          module,
          labels,
          modifiedBy,
          createdOn: { from: getVerifiedDateAuto(createdOn)?.at(0), to: getVerifiedDateAuto(createdOn)?.at(1) },
          modifiedOn: { from: getVerifiedDateAuto(modifiedOn)?.at(0), to: getVerifiedDateAuto(modifiedOn)?.at(1) },
        };

        sessionStorage.setItem('defectsFilters', JSON.stringify(selectedFilterList));
        setAnchorEl(null);
        openFilterModal = false;
      }

      let defectsDataArray = [];

      if (response.data?.errorCode === 404) {
        return defectsDataArray;
      } else {
        response.data?.responseObject?.forEach((val) => {
          let extrakey = {
            'Created By': {
              id: val.createdBy,
              name: val.createdByUname,
            },
            'Modified By': {
              id: val.modifiedBy,
              name: val.modifiedByUname,
            },
            'Created On': val?.createdOn,
            'Modified On': val?.modifiedOn,
          };

          const Actions = [
            <>
              {(isEditable || hasFullAccess) && (
                <button
                  disabled={false}
                  className={'float-left project-action mr-3 text-blue-700 text-base cursor-pointer'}
                  onClick={() => {
                    setSelectedDefectData({ ...val, defectDetails: { ...val.defectDetails, ...extrakey } });
                    setModalType('edit');
                    setShowDefectModal(true);
                  }}
                >
                  <span data-title="Edit" className="rounded-l float-left text-center ml-1">
                    <EditOutlined />
                  </span>
                </button>
              )}
              <button
                disabled={false}
                className={'float-left project-action pt-1 text-blue-700 text-base cursor-pointer'}
              >
                <ContextMenu
                  contextMenuData={contextMenuData}
                  func={(e) => {
                    contextMenuFunction(e, { ...val, defectDetails: { ...val.defectDetails, ...extrakey } });
                  }}
                  onMouseOutz
                  configSection={true}
                />
              </button>
            </>,
          ];
          defectsDataArray.push({
            ...val.defectDetails,
            ...extrakey,
            Actions: Actions,
          });
        });
        return defectsDataArray;
      }
    } catch (err) {
      console.error('ERROR IN SEARCH API', err);
    }
  };

  // TODO :: disable apply and clear all button
  useEffect(() => {
    if (!isEmptyValue([...severity, ...priority, ...createdBy, ...assignTo, ...module, ...labels, ...modifiedBy])) {
      setDisableApplyClearBtn(false);
    } else if ((createdOn.from && createdOn.to) || (modifiedOn.from && modifiedOn.to)) {
      setDisableApplyClearBtn(false);
    } else {
      setDisableApplyClearBtn(true);
    }
  }, [severity, priority, createdBy, assignTo, module, labels, modifiedBy, createdOn, modifiedOn]);

  // TODO :: handle Search filter :: END

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'ID',
        disableFilters: true,
        Cell: ({ value }) => (
          <div className="table-non-link-color-common font-style-common float-left" title={value}>
            <HighlightText text={value} highlight={searchTextHighlight} />
          </div>
        ),
      },
      {
        Header: 'MODULE',
        accessor: 'Module',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div
              className="table-non-link-color-common font-style-common float-left text-overflow-style w-16"
              title={value.split(':')[0]}
            >
              <HighlightText text={value?.split(':')[0]?.split('/')?.pop() || '--'} highlight={searchTextHighlight} />
            </div>
          );
        },
      },
      {
        Header: 'SUMMARY',
        accessor: 'Summary',
        disableFilters: true,
        Cell: ({ value, row }) => {
          return (
            <div
              title={value}
              className="hyper_link_color-common font-style-common float-left overflow-hidden overflow-ellipsis whitespace-nowrap w-64"
            >
              <Link to={`/defect/${row?.original?.ID}/details`}>
                <HighlightText text={value} highlight={searchTextHighlight} />{' '}
              </Link>
            </div>
          );
        },
      },
      {
        Header: 'SEVERITY',
        accessor: 'Severity',
        disableFilters: true,
        Cell: ({ value }) => <div className="table-non-link-color-common font-style-common float-left">{value}</div>,
      },
      {
        Header: 'PRIORITY',
        accessor: 'Priority',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common font-style-common float-left">{value}</div>;
        },
      },
      {
        Header: 'ASSIGN TO',
        accessor: 'Assign to',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <Tooltip title={value?.name}>
              <p
                className="hyper_link_color-common text font-style-common float-left cursor-pointer"
                onClick={(event) => {
                  event.preventDefault();
                  if (value?.id) {
                    getUserDetails(value.id);
                  }
                }}
              >
                <HighlightText text={value?.name || '--'} highlight={searchTextHighlight} />
              </p>
            </Tooltip>
          );
        },
      },
      {
        Header: 'CREATED BY',
        accessor: 'Created By',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <Tooltip title={value?.name}>
              <p
                className="hyper_link_color-common text font-style-common float-left cursor-pointer"
                onClick={(event) => {
                  event.preventDefault();
                  if (value?.id) {
                    getUserDetails(value.id);
                  }
                }}
              >
                <HighlightText text={value?.name || '--'} highlight={searchTextHighlight} />
              </p>
            </Tooltip>
          );
        },
      },
      {
        Header: 'STATE',
        accessor: 'State',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common font-style-common float-left">{value}</div>;
        },
      },
      {
        Header: 'ACTIONS',
        accessor: 'Actions',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="float-left">{value}</div>;
        },
      },
    ],
    [isHighlight]
  );

  useEffect(() => {
    if (reloadTable) {
      (async () => {
        try {
          setIsLoading(true);

          if (openSearch && searchTextHighlight && !isFilterApplied) {
            setTimeout(() => handleSearchSubmit(searchTextHighlight), 800);
          } else if (isFilterApplied) {
            setTimeout(() => handleApplyFilter(defectAnalyticsLabel.btnLabel.apply), 800);
          } else {
            let isDefectDataEmpty = isEmptyValue(paginatedDefectsData);
            if (
              (isDefectDataEmpty || paginatedDefectsData?.length === totalDefectsCount) &&
              totalDefectsCount < updatedTotalDefectsCount &&
              createdDefectData
            ) {
              let newDefectsData = [];
              if (isDefectDataEmpty) {
                newDefectsData.push(createdDefectData);
                props.handelDisableExportDefect(newDefectsData);
              } else {
                newDefectsData = [...paginatedDefectsData, createdDefectData];
              }
              setTotalDefectsCount(updatedTotalDefectsCount);
              setPaginatedDefectsData(newDefectsData);
              getAllDefects(newDefectsData);
            }

            if (modalType === MESSAGES.EDIT) {
              const indexVal = paginatedDefectsData?.findIndex((a) => a._id === defectId || a.id === defectId);
              let tempOrigin = [...paginatedDefectsData];
              tempOrigin[indexVal] = createdDefectData;
              setPaginatedDefectsData(tempOrigin);
              getAllDefects(tempOrigin);
            }
          }

          setIsLoading(false);
        } catch (err) {
          resetProgress();
          setIsLoading(false);
          console.error('GET_ALL_DEFECTS : ', err);
        }
      })();
      setReloadTable(false);
    }
  }, [reloadTable]);

  useEffect(() => {
    getTemplateForEmailConfig();
  }, []);

  const { isEditable, hasFullAccess } = React.useMemo(
    () => ({
      isEditable: window.permission?.isEditAccess('defects'),
      hasFullAccess: window.permission?.isFullAccess('defects'),
    }),
    []
  );

  return (
    <div className="page-table-body w-full" id="journal-scroll">
      <div className="content_area_style" id="journal-scroll">
        <div className="pt-3 pb-3 border_style flex flex-wrap items-center">
          <label className="project_label text-sm flex-auto">Defects</label>
          <div className="pr-4">
            <div className="flex flex-row ml-32 items-center">
              {displaySearchFilter && (
                <SearchBar
                  open={openSearch}
                  searchValue={userSearched}
                  handleSearch={handleSearch}
                  onSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  enterKeyHandler={handleSearchSubmit}
                  disable={isEmptyValue(defectsData)}
                />
              )}

              {/* TODO :: FILTER BLOCK :: START */}

              {displaySearchFilter && (
                <FilterModal
                  open={openFilterModal}
                  anchorEl={anchorEl}
                  onClose={handleFilterClose}
                  handleFilterOpen={handleFilterOpen}
                  onApply={handleApplyFilter}
                  isFilterApplied={isFilterApplied}
                  disableFilters={isEmptyValue(defectsData)}
                  disableClearAllBtn={disableApplyClearBtn}
                  disableApplyBtn={disableApplyClearBtn}
                >
                  <div
                    className={
                      showSelectedList.some((val) => val[Object.keys(val)].length > 0)
                        ? 'border-2 rounded mb-2 max-h-40 overflow-auto'
                        : 'hidden'
                    }
                  >
                    {showSelectedList?.map((obj, index) => {
                      let itemDisplay = obj[Object.keys(obj)];
                      if (!isEmptyValue(itemDisplay)) {
                        return (
                          <div className="p-2" key={index + 1}>
                            <div className="rs-blue capitalize fontPoppinsSemiboldSm border-b-2">
                              {getSelectedFilterLableName(Object.keys(obj)?.[0])}
                            </div>
                            <div className="chips-section flex flex-wrap p-1">
                              {renderChips(Object.keys(obj)?.[0])?.map((val, chipIndex) => {
                                return (
                                  <Chip
                                    key={chipIndex + 2}
                                    text={trimModuleName(val)}
                                    title={trimModuleName(val, 'path')}
                                    onClick={(e) => handleSelectDropdown(val, 'delete', Object.keys(obj)?.[0])}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>

                  <div className="grid grid-rows-4 grid-flow-col gap-2">
                    <div className=" flex gap-2 mt-1 ">
                      <FormControl sx={{ width: 173 }}>
                        <label className="fontPoppinsRegularSm mb-1">Severity</label>
                        <MultipleSelectCheckmarks
                          options={optionsListItem['severity']}
                          onChange={(e) => handleSelectDropdown(e, 'add', 'severity')}
                          value={severity}
                        />
                      </FormControl>
                      <FormControl sx={{ width: 173 }}>
                        <label className="fontPoppinsRegularSm mb-1">Priority</label>
                        <MultipleSelectCheckmarks
                          options={optionsListItem['priority']}
                          onChange={(e) => handleSelectDropdown(e, 'add', 'priority')}
                          value={priority}
                        />
                      </FormControl>

                      <FormControl sx={{ width: 173 }}>
                        <label className="fontPoppinsRegularSm mb-1">Assign To</label>
                        <MultipleSelectCheckmarks
                          options={optionsListItem['assignTo']}
                          onChange={(e) => handleSelectDropdown(e, 'add', 'assignTo')}
                          value={assignTo}
                        />
                      </FormControl>
                    </div>

                    <div className=" flex gap-2 mt-1 ">
                      <FormControl sx={{ width: 173 }}>
                        <label className="fontPoppinsRegularSm mb-1">Created By</label>
                        <MultipleSelectCheckmarks
                          options={optionsListItem['createdBy']}
                          onChange={(e) => handleSelectDropdown(e, 'add', 'createdBy')}
                          value={createdBy}
                        />
                      </FormControl>

                      <FormControl sx={{ width: 173 }}>
                        <label className="fontPoppinsRegularSm mb-1">Module</label>
                        <MultipleSelectCheckmarks
                          options={optionsListItem['module']}
                          onChange={(e) => handleSelectDropdown(e, 'add', 'module')}
                          value={module}
                          trimName={trimModuleName}
                        />
                      </FormControl>

                      <FormControl sx={{ width: 173 }}>
                        <label className="fontPoppinsRegularSm mb-1">Labels</label>
                        <MultipleSelectCheckmarks
                          options={optionsListItem['labels']}
                          onChange={(e) => handleSelectDropdown(e, 'add', 'labels')}
                          value={labels}
                        />
                      </FormControl>
                    </div>

                    <div className=" flex gap-2 mt-1 ">
                      <FormControl sx={{ width: 173 }}>
                        <label className="fontPoppinsRegularSm mb-1">Modified By</label>
                        <MultipleSelectCheckmarks
                          options={optionsListItem['modifiedBy']}
                          onChange={(e) => handleSelectDropdown(e, 'add', 'modifiedBy')}
                          value={modifiedBy}
                        />
                      </FormControl>

                      <FormControl sx={{ width: 173 }}>
                        <label className="fontPoppinsRegularSm mb-1">Created On</label>
                        <DateSelector
                          inputFormat={filterDisplayDateFormat}
                          value={createdOn.from}
                          onChange={(e) => handleCreatedOnDate(e, 'from')}
                          maxDate={createdOn?.to || new Date()}
                          label="From Date"
                        />
                      </FormControl>

                      <FormControl sx={{ width: 173 }}>
                        <label className="fontPoppinsRegularSm mb-1">&nbsp;</label>
                        <DateSelector
                          inputFormat={filterDisplayDateFormat}
                          value={createdOn.to}
                          onChange={(e) => handleCreatedOnDate(e, 'to')}
                          minDate={createdOn?.from}
                          maxDate={new Date()}
                          label="To Date"
                          disabled={!createdOn.from}
                        />
                      </FormControl>
                    </div>

                    <div className=" flex gap-2 mt-1 ">
                      <FormControl sx={{ width: 173 }}>
                        <label className="fontPoppinsRegularSm mb-1">Modified On</label>
                        <DateSelector
                          inputFormat={filterDisplayDateFormat}
                          value={modifiedOn.from}
                          onChange={(e) => handleModifiedOnDate(e, 'from')}
                          maxDate={modifiedOn?.to || new Date()}
                          label="From Date"
                        />
                      </FormControl>

                      <FormControl sx={{ width: 173 }}>
                        <label className="fontPoppinsRegularSm mb-1">&nbsp;</label>
                        <DateSelector
                          inputFormat={filterDisplayDateFormat}
                          value={modifiedOn.to}
                          onChange={(e) => handleModifiedOnDate(e, 'to')}
                          minDate={modifiedOn?.from}
                          maxDate={new Date()}
                          label="To Date"
                          disabled={!modifiedOn.from}
                        />
                      </FormControl>
                    </div>
                  </div>
                </FilterModal>
              )}

              {/* TODO :: FILTER BLOCK:: END*/}

              <div className="ml-auto pl-4">
                {defectMgmtData.templates_selected && (isEditable || hasFullAccess) ? (
                  <button
                    className="ml-3 primary-btn"
                    onClick={() => {
                      if (!defectLimitExceeded) {
                        setModalType('add');
                        setShowDefectModal(true);
                      } else {
                        props.MyAlert.info('Defect creation limit exceeded');
                      }
                    }}
                  >
                    + Defect
                  </button>
                ) : (
                  (isEditable || hasFullAccess) && (
                    <button
                      className="ml-3 primary-btn"
                      onClick={() => {
                        setModalType('add');
                        setShowTemplateModal(true);
                      }}
                    >
                      + Select Template
                    </button>
                  )
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="tab-height">
          {defectMgmtData?.templates_selected && (
            <ReactTable
              data={data}
              columns={columns}
              tableHeightClass={'main-table-height'}
              ref={defectTableRef}
              setScrollPosition={setScrollPosition}
            />
          )}
          {isLoading ? (
            <CommonLoader />
          ) : isEmptyValue(defectsData) ? (
            <div className="fontPoppinsRegularMd no-steps">
              {(isEditable || hasFullAccess) && (
                <div>
                  <p className="font-bold mb-4">Quick Start</p>
                  <p className="mb-4">
                    <span className="font-bold">Step 1 :</span> Click
                    {defectMgmtData.templates_selected ? (
                      <button
                        className="mx-2 primary-btn"
                        onClick={() => {
                          if (!defectLimitExceeded) {
                            setModalType('add');
                            setShowDefectModal(true);
                          } else {
                            props.MyAlert.info('Defect creation limit exceeded');
                          }
                        }}
                      >
                        + Defect
                      </button>
                    ) : (
                      <button
                        className="mx-2 primary-btn"
                        onClick={() => {
                          setModalType('add');
                          setShowTemplateModal(true);
                        }}
                      >
                        + Select Template
                      </button>
                    )}
                    to start adding defects
                  </p>
                </div>
              )}
            </div>
          ) : (
            <>
              {((openSearch && isEmptyValue(data)) || (isFilterApplied && isEmptyValue(data))) && (
                <NoResultsFound className="mt-16" />
              )}
            </>
          )}
        </div>
      </div>
      {showDefectModal && (
        <DefectCreateModal
          open={showDefectModal}
          closeModal={setShowDefectModal}
          modalType={modalType}
          defectMgmtData={defectMgmtData}
          MyAlert={props.MyAlert}
          selectedDefectData={modalType === 'add' ? {} : selectedDefectData}
          setSelectedDefectData={setSelectedDefectData}
          reloadHandler={reloadHandler}
        />
      )}
      {openUserDetailsModal && <UserDetailsModal data={userDetails} closeModal={closeUserDetailsModal} />}
      {showTemplateModal && (
        <SelectDefectTemplate
          open={showTemplateModal}
          showPopup={setShowTemplateModal}
          lifeCycleTemplates={lifeCycleTemplates}
          detailsTemplates={detailsTemplates}
          getTemplateForEmailConfig={getTemplateForEmailConfig}
          emailConfigApiResponse={defectMgmtData}
        />
      )}
    </div>
  );
}

export default DefectTablePage;
