import React, { useState, useEffect, useRef, useContext } from 'react';
import StepModal from '@pages/results/components/modals/step_result_modal';
import { RiArrowDownSFill, RiArrowRightSFill } from 'react-icons/ri';
import { GrFormFilter } from 'react-icons/gr';
import { TiFilter } from 'react-icons/ti';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { getFormattedTextStatus, textColor } from '../../result';
import { Tooltip } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import DataSetModal from '@pages/results/components/modals/data_set_modal';
import TagStepModal from '../modals/tag_step_modal';
import {
  deleteStepTag,
  resolveStepTag,
  getScriptResultTags,
  getStepResult,
  getScriptReq,
  getExecutionData,
  deleteAutotaggingStep,
  getAutotaggingAI,
} from '@api/api_services';
import Tag from './tag';
import BugIcon from '@pages/configuration/DefectConfig/BugIcon';
import { getIDB, setIDB } from '@src/util/localForage_idb_controller';
import {
  getStepId,
  encodeSpecialCharater,
  getExceptionSuggestionForFailedStep,
} from '@src/pages/test-development/shared/common-methods';
import { RESULT_ACCORDION_CONSTANTS, resultAccordionMsg } from '@common/ui-constants';
import { Icon, Popup, RadioButton, Label } from 'fireflink-ui';
import { colors } from '@src/css_config/colorConstants';
import { getCurrentLicenseId, toPascalCase, isEmptyValue } from '@src/util/common_utils';
import { ToasterContainerContext } from '@src/common/atoms/Toast/Toast';
import { TOAST_CONSTANTS, EXCEPTION_SUGGESTION } from '@src/common/ui-constants';
import ExceptionSuggestionModal from '../ExceptionSuggestion/ExceptionSuggestionModal';

const ResultAccordion = (props) => {
  let pathArray = window.location.pathname.split('/');
  const ifSuiteExecution = pathArray[1];
  const history = useHistory();
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  const scriptId = new URLSearchParams(search).get('id');
  const executionId = new URLSearchParams(search).get('executionId');
  const clientId = new URLSearchParams(search).get('clientId');
  const runId = new URLSearchParams(search).get('runId');
  const moduleName = new URLSearchParams(search).get('moduleName');
  const { executionName, scriptName, machineNames } = useParams();
  const { toastPropsHandler } = useContext(ToasterContainerContext);
  let [scriptData, setscriptData] = useState(props['content']);
  let [scriptTagData, setScriptTagData] = useState([]);
  let [openStepModal, setOpenStepModal] = useState(false);
  let [openDataSetModal, setOpenDataSetModal] = useState(false);
  let [stepResultData, setStepResultData] = useState({});
  let [dataSetData, setDataSetData] = useState({});
  let [filteredStatus, setFilteredStatus] = useState('ALL');
  let [displayFilterOption, setDisplayFilterOption] = useState(false);
  let [filterIcon, setFilterIcon] = useState(false);
  let [hasIteration, setHasIteration] = useState(false);
  let [elementHover, setElementHover] = useState(false);
  const [message, setMessage] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isResolve, setIsResolve] = useState(false);
  const [tagData, setTagData] = useState({
    index: null,
    data: null,
    tagDTO: null,
  });
  let [screenshotDeleted, setScreenshotDeleted] = useState(false);
  let [videoDeleted, setVideoDeleted] = useState(false);
  const [parentStep, setParentStep] = useState(null);
  const [stepTypeCondition, setStepTypeCondition] = useState(null);
  const [openPopup, setopenPopup] = useState(null);
  const [resolveStep, setResolveStep] = useState('');
  const [resolveAndDeleteText, setResolveAndDeleteText] = useState('');
  const [emptyOption, setEmptyOption] = useState(false);
  const [individualStepData, setIndividualStepData] = useState([]);
  const [render, setRender] = useState(false);
  const [openExceptionSuggestion, setOpenExceptionSuggestion] = useState(false);
  const [exceptionSuggestion, setExceptionSuggestion] = useState({});
  const { RESULT_PAGE } = EXCEPTION_SUGGESTION;
  const resolveAndDeleteSingleStep = tagData?.tagDTO?.updateAllSteps;
  let { RESOLVE_QUESTION, DELETE_QUESTION, RADIOBUTTON_OPTIONS, SELECT_OPTION, PROCEED_QUESTION, RESOLVE } =
    RESULT_ACCORDION_CONSTANTS;
  const disableContinueButton = !resolveStep && resolveAndDeleteSingleStep && resolveAndDeleteText === RESOLVE;
  const filterObject = {
    all: 'ALL',
    pass: 'PASS',
    fail: 'FAIL',
    warning: 'WARNING',
    skip: 'SKIP',
  };
  let { black_border, rs_primary } = colors;
  //? INFO:
  //! Autotagging using AI

  const currentLicenseId = getCurrentLicenseId(scriptId);

  const getAutotaggingManualAI = (scriptId) => {
    const payload = {
      database_name: currentLicenseId,
      scriptKey: scriptId,
      executionId: executionId,
    };
    getAutotaggingAI(payload)
      .then((response) => {
        setIndividualStepData(response?.data?.stepResultList);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleDeleteandResolveChange = (e) => {
    const valueMap = {
      'Only this step': { resolveStep: 'resolve-step' },
      'All the steps': { resolveStep: 'resolve-all' },
    };
    const selectedValue = e.target.value;
    if (selectedValue !== '') {
      setEmptyOption(false);
      if (valueMap[selectedValue]) {
        setResolveStep(valueMap[selectedValue].resolveStep);
      }
    }
  };
  const [tagModal, setOpenTagModal] = useState({
    openModal: false,
    data: null,
    index: null,
    edit: false,
    tagDTO: null,
    _tagindex: null,
    tagDetailsDTOs: null,
    taggedData: null,
    stepId: null,
  });

  const ifElseConditionsList = [
    'If Condition',
    'Else If Condition',
    'End If Condition',
    'End Else If Condition',
    'Else Condition',
    'End Else Condition',
  ];

  const [stepPathQueue, setStepPathQueue] = useState([]);
  let stepPathQueueRef = useRef({});
  stepPathQueueRef.current = stepPathQueue;
  const [stepResultType, setStepResultType] = useState();
  const { isEditable, hasFullAccess } = React.useMemo(
    () => ({
      isEditable: window.permission?.isEditAccess('execution'),
      hasFullAccess: window.permission?.isFullAccess('execution'),
    }),
    []
  );

  useEffect(() => {
    let stepPath = localStorage.getItem('stepPath');
    if (stepPath && stepPath.length) setStepPathQueue(JSON.parse(stepPath));
    updateDeleteAndResolve();
  }, []);
  /**
   * @param {*} _step | step getting tagged
   * @param {*} allSteps | stepResult obj with pre and post condition step results
   * @param {*} index | index of the step
   */
  function handleStepTag(_step, allSteps, index, taggedData) {
    getPathController(_step);

    if (isEditable) {
      setOpenTagModal({
        openModal: true,
        data: allSteps,
        index: index,
        edit: false,
        tagDTO: null,
        _tagindex: null,
        tagDetailsDTOs: null,
        stepId: _step.stepId,
        taggedData: taggedData,
      });
    }
  }
  const getPathController = (_step) => {
    let ifPathFound = false;
    if (
      scriptData.preConditions &&
      scriptData.preConditions.stepResults &&
      scriptData.preConditions.stepResults.length
    ) {
      ifPathFound = getPath(scriptData.preConditions.stepResults, _step, 'PRE');
    }
    if (!ifPathFound && scriptData.stepResults && scriptData.stepResults.length) {
      ifPathFound = getPath(scriptData.stepResults, _step);
    }
    if (
      !ifPathFound &&
      scriptData.postConditions &&
      scriptData.postConditions.stepResults &&
      scriptData.postConditions.stepResults.length
    ) {
      ifPathFound = getPath(scriptData.postConditions.stepResults, _step, 'POST');
    }
  };

  useEffect(() => {
    getAutotaggingManualAI(scriptId);
    setscriptData(props.content);
    getScriptTags(props?.content?.key);
    setRender(false);
  }, [props.content, props.reloadAccordion, render]);

  const getScriptTags = (id) => {
    getScriptResultTags(executionId, runId, id).then((response) => {
      if (response?.data?.responseCode === 200 && response?.data?.responseObject) {
        setScriptTagData(response.data.responseObject);
      } else {
        setScriptTagData([]);
      }
    });
  };
  const discardAutotaggingStep = async (action) => {
    props.DiscardAIStepDB.stepId = action === 'Discard' ? tagModal?.stepId : tagData?.data?.stepId;
    const payload = props.DiscardAIStepDB;
    const response = await deleteAutotaggingStep(payload);
    if (response?.data?.responseCode === 200) {
      setRender(true);
      toastPropsHandler({
        variant: TOAST_CONSTANTS.VARIANTS.SUCCESS,
        message:
          action === 'Discard' ? (
            response?.data?.message
          ) : (
            <>
              <Label className="fontPoppinsSemiboldSm" color={black_border} label={tagData?.tagDTO?.name} /> tag deleted
              successfully
            </>
          ),
      });
    }
  };
  const closeDeleteResolveModal = (val) => {
    setOpenDeleteModal(val);
    setIsResolve(val);
  };

  const closeStepModal = (val) => {
    setStepTypeCondition(null);
    setParentStep(null);
    setOpenStepModal(val);
  };
  const closeDataSetModal = (val) => {
    setOpenDataSetModal(val);
  };

  let accordionIcon = (elementId, stepGroupid) => {
    let data = elementId;
    if (elementId === '00:00:00:00') {
      data = stepGroupid;
    }
    return (
      <span>
        <RiArrowRightSFill className="expand-icon" id={`${data}-expand`} />
        <RiArrowDownSFill className="collapse-icon" id={`${data}-collapse`} />
      </span>
    );
  };

  let toggleAccordionIcon = (event, elementId, min, stepGroupResult) => {
    if (!event?.target?.id.includes('viewdataset_')) {
      let data = elementId;
      if (stepGroupResult) {
        if (
          stepGroupResult.executionDurationInHourMinSecFormat &&
          stepGroupResult.executionDurationInHourMinSecFormat !== '00:00:00:00'
        ) {
          data = stepGroupResult.executionDurationInHourMinSecFormat;
        }
      }
      let expandElement = document.getElementById(data + '-expand');
      let collapseElement = document.getElementById(data + '-collapse');
      if (expandElement?.style) {
        let accordionHtmlElement = expandElement.closest('.accordion');
        const panelHtmlElement = accordionHtmlElement.nextSibling;
        accordionHtmlElement.classList.toggle('active');
        if (expandElement.style.display === 'none') {
          expandElement.style.display = 'inline-block';
          if (panelHtmlElement) panelHtmlElement.style.display = 'none';
        } else if (expandElement.style.display !== 'none') {
          expandElement.style.display = 'none';
          if (panelHtmlElement) panelHtmlElement.style.display = 'block';
        }
      }
      if (collapseElement && collapseElement.style) {
        const accordionHtmlElement = collapseElement.parentElement.parentElement.parentElement.parentElement;
        const panelHtmlElement = accordionHtmlElement.nextSibling;
        accordionHtmlElement.classList.toggle('active');
        if (collapseElement.style.display === 'none' || collapseElement.style.display === '') {
          collapseElement.style.display = 'inline-block';
          if (panelHtmlElement) panelHtmlElement.style.display = 'block';
        } else if (collapseElement.style.display !== 'none') {
          collapseElement.style.display = 'none';
          if (panelHtmlElement) panelHtmlElement.style.display = 'none';
        }
      }
    }
  };

  let millisecondsToTime = (itrSteps = []) => {
    let ms = 0;
    itrSteps.map((step) => {
      if (step?.executionDuration) {
        ms = ms + step.executionDuration;
      }
    });
    if (typeof ms !== 'number' || ms < 0) {
      return '00:00:00:00';
    }
    const hours = Math.floor(ms / 3600000);
    ms %= 3600000;
    const minutes = Math.floor(ms / 60000);
    ms %= 60000;
    const seconds = Math.floor(ms / 1000);
    const milliseconds = ms % 1000;
    const timeString = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
      seconds
    ).padStart(2, '0')}:${String(milliseconds).padStart(2, '0')}`;
    return timeString;
  };

  const getData = async (exeId) => {
    try {
      let { data } = await getExecutionData(exeId);
      setScreenshotDeleted(data?.responseObject?.fancyTreeData?.responseObject?.isScreenshotDeleted);
      setVideoDeleted(data?.responseObject?.fancyTreeData?.responseObject?.isVideoDeleted);
    } catch (err) {
      console.error(err);
    }
  };

  const handleExceptionSuggestion = async (exceptionName, platform) => {
    setExceptionSuggestion(await getExceptionSuggestionForFailedStep(exceptionName, platform));
    setOpenExceptionSuggestion(true);
  };

  const handleExceptionSuggesitonOnClose = () => {
    setOpenExceptionSuggestion(!openExceptionSuggestion);
  };

  let viewStepResultModal = (stepResult, parentStep, condition) => {
    setParentStep(parentStep);
    setStepTypeCondition(condition);
    if (stepResult?.uniqueId) {
      updateStepResult(stepResult.uniqueId);
    } else if (stepResult.status && stepResult.status !== 'SKIP') {
      setOpenStepModal(true);
      setStepResultData(stepResult);
    }
  };

  const updateStepResult = async (uniqueId) => {
    const response = await getStepResult(uniqueId);
    const fileParamMap = response?.data?.responseObject?.webServiceRequest?.fileParamMap;
    if (fileParamMap) {
      for (const key in fileParamMap) {
        for (const item of fileParamMap[key]) {
          if (item.type === 'file' && item._id) {
            item.id = item._id;
            delete item._id;
          }
        }
      }
    }
    if (response?.data?.responseObject) {
      setStepResultData(response?.data?.responseObject);
      setOpenStepModal(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const stepHTMLElement = document.getElementById('steps');
      if (stepHTMLElement) {
        toggleAccordionIcon(null, 'steps');
      }
    }, 100);
  }, []);

  let stepsResult = (stepResult, stepStatistics, marginLeftSize, iterationMarginLeft) => {
    return (
      <div>
        <div
          className="accordion"
          onClick={(e) => {
            toggleAccordionIcon(e, 'steps');
          }}
        >
          <div className="grid grid-cols-5">
            <div className="result-subHeader col-span-3" id="steps" style={{ marginLeft: marginLeftSize + 'rem' }}>
              Steps
              {accordionIcon('steps')}
              {executionStatistics(stepStatistics)}
              {hasIteration ? (
                <Tooltip title={'Aggregated count of steps from all iteration is '} placement="right">
                  <InfoOutlinedIcon
                    className="ml-2 cursor-pointer"
                    color="#525050"
                    style={{ fontSize: '14px' }}
                  ></InfoOutlinedIcon>
                </Tooltip>
              ) : (
                ''
              )}
            </div>
            <div className={`result-status flex items-center cursor-pointer ${textColor(getStatus(stepStatistics))}`}>
              {filteredStatus === 'ALL' ? getFormattedTextStatus(getStatus(stepStatistics)) : ''}
            </div>
            <div className="result-duration flex items-center">
              {stepStatistics?.executionDurationInHourMinSecFormat}
            </div>
          </div>
        </div>
        <div className="panel ">
          {stepResult !== undefined
            ? marginLeftSize
              ? steps(
                  stepResult,
                  'steps',
                  Number(marginLeftSize) + Number(1.3),
                  Number(iterationMarginLeft) + Number(1.3)
                )
              : steps(stepResult, 'steps', '1.30', '')
            : ''}
        </div>
      </div>
    );
  };

  function getStatus(stepResultStats) {
    if (stepResultStats?.totalTerminated > 0) {
      return 'Terminated';
    } else if (stepResultStats?.totalAborted > 0) {
      return 'Aborted';
    } else if (stepResultStats?.totalFailed > 0) {
      return 'FAIL';
    } else if (stepResultStats?.totalWarning > 0) {
      return 'WARNING';
    } else if (stepResultStats?.totalSkipped > 0) {
      return 'SKIP';
    } else if (stepResultStats?.totalPassed > 0) {
      return 'PASS';
    } else if (stepResultStats?.totalNA > 0) {
      return 'NA';
    }
  }

  let conditions = (conditionResult, conditionType, conditionStatistics, marginLeftSize) => {
    return (
      <div>
        <div
          className="accordion"
          onClick={(e) => {
            toggleAccordionIcon(e, conditionType);
          }}
        >
          <div className="grid grid-cols-5">
            <div
              className="result-subHeader col-span-3"
              id={conditionType}
              style={{ marginLeft: marginLeftSize + 'rem' }}
            >
              {conditionType}
              {accordionIcon(conditionType)}
              {executionStatistics(conditionStatistics)}
            </div>
            <div className={`result-status flex items-center cursor-pointer ${textColor(conditionResult?.status)}`}>
              {filteredStatus === 'ALL' ? getFormattedTextStatus(conditionResult?.status) : ''}
            </div>
            <div className="result-duration flex items-center">
              {conditionStatistics?.executionDurationInHourMinSecFormat}
            </div>
          </div>
        </div>
        <div className="panel ">
          {
            conditionResult?.stepResults.map((stepGroup, index) => (
              <div className={`${accordionFilterClass(stepGroup?.stepResultStats)}`}>
                {
                  <div
                    className="accordion"
                    key={`precondition${index}`}
                    onClick={(e) => {
                      toggleAccordionIcon(e, `${conditionType}${index}`);
                    }}
                  >
                    <div className="grid grid-cols-5">
                      <div
                        className={`step-result flex items-center cursor-pointer col-span-3 pt-1`}
                        style={{
                          marginLeft: marginLeftSize ? Number(marginLeftSize) + Number(1.3) + 'rem' : '1.30rem',
                        }}
                      >
                        <span title={stepGroup?.name} className="max-w-xs truncate">
                          {index + 1}.{stepGroup?.name}
                        </span>
                        {accordionIcon(`${conditionType}${index}`)}
                        {executionStatistics(stepGroup?.stepResultStats)}
                      </div>
                      <div className="flex-wrapper">
                        <div
                          className={`result-status flex items-center cursor-pointer ${textColor(stepGroup?.status)}`}
                        >
                          {filteredStatus === 'ALL' ? getFormattedTextStatus(stepGroup?.status) : ''}
                        </div>
                      </div>
                      <div className="result-duration flex items-center">
                        {stepGroup?.executionDurationInHourMinSecFormat}
                      </div>
                    </div>
                  </div>
                }
                <div className="panel ">
                  {stepGroup?.stepResults !== undefined &&
                    checkFilterStatus(stepGroup?.stepResultStats) &&
                    steps(
                      stepGroup?.stepResults,
                      conditionType,
                      Number(marginLeftSize ? marginLeftSize : '') + Number(1.3) + Number(1.3),
                      null,
                      null,
                      stepGroup,
                      conditionType
                    )}
                </div>
              </div>
            ))
            // ))
          }
        </div>
      </div>
    );
  };

  const navigateBackToSteps = async (stepId) => {
    try {
      if (scriptName && moduleId) {
        let response = await getScriptReq(moduleId, scriptId);

        let verifyScriptModuleExistance = response?.data?.responseCode;

        if (verifyScriptModuleExistance === 200) {
          let scriptName = `${response?.data?.responseObject?.prefix} - ${response?.data?.responseObject?.name}`;
          let moduleName = response?.data?.responseObject?.parentName;
          window.open(
            `/testdevelopment/Script/${encodeSpecialCharater(moduleName)}_${moduleId}/${encodeSpecialCharater(
              scriptName
            )}_${scriptId}/Steps?moduleId=${moduleId}&scriptId=${scriptId}&manualScriptIdRequired=true`,
            '_blank'
          );
        } else if (verifyScriptModuleExistance === 404) {
          toastPropsHandler({
            variant: TOAST_CONSTANTS.VARIANTS.INFO,
            message: response?.data?.message,
          });
        }
      }
    } catch (error) {
      console.error('ERROR IN VERIFIYING SCRIPT EXISTANCE', error);
    }
  };

  function statusFilter() {
    return (
      <div
        className="status-filter absolute flex flex-col mt-4 step-result cursor-pointer mx-7 z-10"
        onClick={() => {
          setDisplayFilterOption(!displayFilterOption);
        }}
      >
        <div
          className={`result-primary filter mt-1 ${filteredStatus === 'ALL' ? 'selected-filter' : ''}`}
          onClick={() => {
            setFilteredStatus('ALL');
          }}
        >
          <div className="my-1 mx-4">All</div>
        </div>
        <div
          className={`result-passed filter ${filteredStatus === 'PASS' ? 'selected-filter' : ''}`}
          onClick={() => {
            setFilteredStatus('PASS');
          }}
        >
          <div className="my-1 mx-4">Passed</div>
        </div>
        <div
          className={`result-failed filter ${filteredStatus === 'FAIL' ? 'selected-filter' : ''}`}
          onClick={() => {
            setFilteredStatus('FAIL');
          }}
        >
          <div className="my-1 mx-4">Failed</div>
        </div>
        <div
          className={`result-warning filter ${filteredStatus === 'WARNING' ? 'selected-filter' : ''}`}
          onClick={() => {
            setFilteredStatus('WARNING');
          }}
        >
          <div className="my-1 mx-4">Warning</div>
        </div>
        <div
          className={`result-skipped filter mb-1 ${filteredStatus === 'SKIP' ? 'selected-filter' : ''}`}
          onClick={() => {
            setFilteredStatus('SKIP');
          }}
        >
          <div className="my-1 mx-4">Skipped</div>
        </div>
      </div>
    );
  }

  function scriptResult() {
    if (
      !checkFilterStatusPrepost(scriptData, 'pre') &&
      !checkFilterStatus(scriptData?.stepResultStats) &&
      !checkFilterStatus(scriptData?.dependantScriptStats) &&
      !checkFilterStatusPrepost(scriptData, 'post')
    ) {
      return <div className="pt-24 overflow-auto result-body text-center fontPoppinsRegularMd">No results found</div>;
    } else {
      return (
        <div
          className="p-4 overflow-auto result-body"
          id="journal-scroll"
          onClick={() => setDisplayFilterOption(false)}
        >
          {scriptData?.dependantExecutionEntityResponses &&
            checkFilterStatus(scriptData?.dependantScriptStats) &&
            dependsOnScripts(scriptData?.dependantExecutionEntityResponses, scriptData?.dependantScriptStats)}
          {/* Iteration starts */}
          {stepCondition()}
          {/* Iteration ends */}
          {openStepModal && (
            <StepModal
              screenshotDeleted={screenshotDeleted}
              videoDeleted={videoDeleted}
              stepResult={stepResultData}
              closeStepModal={closeStepModal}
              MyAlert={props.MyAlert}
              scriptId={scriptData?.id || scriptId}
              moduleId={moduleId}
              parentStep={parentStep}
              stepTypeCondition={stepTypeCondition}
            />
          )}
          {openDataSetModal && <DataSetModal dataSetData={dataSetData} closeDataSetModal={closeDataSetModal} />}
        </div>
      );
    }
  }

  const stepCondition = () => {
    let returnStepCondition;
    if (scriptData?.executionEntityResponses) {
      returnStepCondition = iterationIncludingPrePostCondition(scriptData);
    } else {
      returnStepCondition = (
        <>
          {scriptData?.preConditions !== undefined &&
            checkFilterStatusPrepost(scriptData, 'pre') &&
            conditions(scriptData?.preConditions, 'Preconditions', scriptData?.preConditionStats)}
          {scriptData?.stepResults !== undefined &&
            checkFilterStatus(scriptData?.stepResultStats) &&
            stepsResult(scriptData?.stepResults, scriptData?.stepResultStats)}
          {scriptData?.postConditions !== undefined &&
            checkFilterStatusPrepost(scriptData, 'post') &&
            conditions(scriptData?.postConditions, 'Postconditions', scriptData?.postConditionStats)}
        </>
      );
    }
    return returnStepCondition;
  };

  function navigateToDepScript(dependant) {
    let pathName = `/testdevelopment/Execution Dashboard/${executionName}/Root Module/${executionName}/${dependant.title}/script/result`;
    let searchParams = `?id=${
      dependant.key
    }&executionId=${executionId}&type=dependant&clientId=${clientId}&runId=${runId}&moduleId=${moduleId}&moduleName=${encodeSpecialCharater(
      moduleName
    )}`;
    if (ifSuiteExecution === 'execution') {
      const multiple = new URLSearchParams(search).get('multiple');
      const _isMultiple = /true/i.test(multiple);
      if (_isMultiple) {
        pathName = `/execution/suite/${executionName}/execution dashboard/overview/machines/${machineNames}/Root Module/${dependant.title}/Result`;
        searchParams = `?id=${dependant.key}&executionId=${executionId}&type=dependant&clientId=${clientId}${
          runId ? `&runId=${runId}` : ''
        }&multiple=true&moduleId=${moduleId}&moduleName=${encodeSpecialCharater(moduleName)}`;
      } else {
        pathName = `/execution/suite/${executionName}/execution dashboard/Root Module/${dependant.title}/script/results`;
        searchParams = `?id=${dependant.key}&executionId=${executionId}&type=dependant&clientId=${clientId}${
          runId ? `&runId=${runId}` : ''
        }&multiple=false&moduleId=${moduleId}&moduleName=${encodeSpecialCharater(moduleName)}`;
      }
    }
    history.push({
      pathname: pathName,
      search: searchParams,
    });
  }

  function dependsOnScripts(dependantScriptResult, dependantScriptStatistics) {
    return (
      <div>
        <div
          className="accordion"
          onClick={(e) => {
            toggleAccordionIcon(e, 'dependsOnScripts');
          }}
        >
          <div className="grid grid-cols-5">
            <div className="result-subHeader col-span-3" id="dependsOnScripts">
              Depends On Scripts
              {accordionIcon('dependsOnScripts')}
              {executionStatistics(dependantScriptStatistics)}
            </div>
            <div
              className={`result-status flex items-center cursor-pointer ${textColor(
                getStatus(dependantScriptStatistics)
              )}`}
            >
              {filteredStatus === 'ALL' ? getFormattedTextStatus(getStatus(dependantScriptStatistics)) : ''}
            </div>
            <div className="result-duration flex items-center">
              {dependantScriptStatistics?.executionDurationInHourMinSecFormat}
            </div>
          </div>
        </div>
        <div className="panel ">
          {dependantScriptResult?.map(
            (dependant, index) =>
              (dependant?.status === filteredStatus || filteredStatus === 'ALL') && (
                <div className="grid grid-cols-5 step-header" key={`dependantScript${index}`}>
                  <div
                    className={`col-span-3 ml-5 step-result flex items-center cursor-pointer`}
                    onClick={() => navigateToDepScript(dependant)}
                  >
                    <span title={dependant?.name} className="truncate pr-2">
                      {' '}
                      {index + 1}.{dependant?.name}{' '}
                    </span>
                  </div>
                  <div className="flex-wrapper">
                    <div
                      className={`result-status flex items-center cursor-pointer ${textColor(dependant?.status)}`}
                      onClick={() => navigateToDepScript(dependant)}
                    >
                      {' '}
                      {getFormattedTextStatus(dependant?.status)}
                    </div>
                    {getFormattedTextStatus(dependant?.status) === 'Failed' ? (
                      <>
                        {isEditable && (
                          <div className={`m-2.5 cursor-pointer ${textColor(dependant?.status)}`}>
                            <BugIcon
                              MyAlert={props?.MyAlert}
                              issueData={{
                                section: 'resultSteps',
                                runId: runId,
                                nodeObj: { data: scriptData },
                                stepsDetails: {
                                  data: { section: 'dependsOnScripts', id: dependant?.stepId },
                                  scriptResultData: scriptData,
                                },
                                systemData: props.content.selectedSystem,
                                executionId: executionId,
                              }}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="result-duration flex items-center">
                    {dependantScriptStatistics?.executionDurationInHourMinSecFormat}
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    );
  }
  /**
   * grouping the iteration steps based on iteration numbers
   * @param {*} objectArray the array of objects from which we have to group all
   * @param {*} property property/key by which has to be grouped
   * @param {*} type if its including or excluding pre/post conditions
   * @returns
   */
  function groupBy(objectArray, property, type) {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (key !== undefined) {
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
      }
      return acc;
    }, {});
  }

  function viewDataSet(iteArr, tempDataSetArr) {
    let dataSetArr = tempDataSetArr || [];
    let serialNumber = 0;
    let tempIteArr = iteArr.itr || iteArr;
    tempIteArr?.forEach((_ite) => {
      if (_ite?.elementDetails?.length) {
        _ite.elementDetails.forEach((element) => {
          if (element?.locators?.length) {
            element.locators.forEach((locator) => {
              if (locator?.dynamicLocatorArray?.length) {
                locator.dynamicLocatorArray.forEach((dynamicLocator) => {
                  if (dynamicLocator?.reference === 'DATAPROVIDER') {
                    let dataSet = Object.assign(dynamicLocator);
                    dataSet['step'] = ++serialNumber;
                    dataSet['actualValue'] = locator.actualValue;
                    dataSetArr.push(dataSet);
                  }
                });
              }
            });
          }
        });
      }
      _ite.stepInputs &&
        _ite.stepInputs.forEach((_stp) => {
          if ([_stp.reference, _stp.inputReference].includes('DATAPROVIDER')) {
            let _eachDataSet = Object.assign(_stp);
            _eachDataSet['step'] = ++serialNumber;
            dataSetArr.push(_eachDataSet);
          }
        });
      _ite.dataProviderStepInputs?.forEach((_stp) => {
        let _eachDataSet = Object.assign(_stp);
        _eachDataSet['step'] = ++serialNumber;
        dataSetArr.push(_eachDataSet);
      });
      if (_ite?.stepResults && ['_startforloop', '_startiteration'].includes(_ite?.type)) {
        const _varList = getListOfVars(_ite.stepResults);
        _varList &&
          _varList.forEach((_var) => {
            const hasDuplicates = dataSetArr.some((_item) => {
              return _var.actualValue === _item.actualValue && _var.name === _item.name;
            });
            if (!hasDuplicates) {
              _var['step'] = ++serialNumber;
              dataSetArr.push(_var);
            }
          });
      }
      if (_ite?.type.toLowerCase() === 'group') {
        viewDataSet(_ite?.stepResults, dataSetArr);
      }
    });
    setDataSetData(dataSetArr);
    setOpenDataSetModal(true);
  }

  const getListOfVars = (stepResults) => {
    let _varList = [];
    let _ite_obj = groupBy(stepResults, 'iteration');
    let iterationKeys = Object.keys(_ite_obj);
    if (iterationKeys?.length) {
      iterationKeys.forEach((_itr) => {
        const _itrStepArr = _ite_obj[_itr];
        _itrStepArr &&
          _itrStepArr.forEach((_itrStep) => {
            _itrStep?.stepInputs &&
              _itrStep.stepInputs.forEach((_stp) => {
                if (_stp.reference === 'DATAPROVIDER') {
                  let _eachDataSet = Object.assign(_stp);
                  if (_eachDataSet) {
                    _varList.push(_eachDataSet);
                  }
                }
              });
            _itrStep.dataProviderStepInputs?.forEach((_stp) => {
              let _eachDataSet = Object.assign(_stp);
              if (_eachDataSet) {
                _varList.push(_eachDataSet);
              }
            });
          });
      });
    }
    return _varList;
  };

  function iterationDataIncludingPrePostCondition(iterationSteps, iterationType, marginLeftSize, iterationMarginLeft) {
    let _ite_arr = groupBy(iterationSteps, 'iteration', 'including_iteration');
    let iterations = Object.keys(_ite_arr);
    return (
      <div>
        {iterations.map((key, ite_index) => (
          <div>
            <div
              className="accordion"
              onClick={(e) => {
                toggleAccordionIcon(e, `Iteration${ite_index + 1}`);
              }}
            >
              <div className="grid grid-cols-5">
                <div
                  className={`iteration-step col-span-3 ${textColor(iterationSteps?.status)}`}
                  id={`Iteration${ite_index + 1}`}
                  style={{ marginLeft: marginLeftSize + 'rem' }}
                >
                  Iteration {ite_index + 1}
                  {accordionIcon(`Iteration${ite_index + 1}`)}
                  {iterationType === 'DATAPROVIDER' ? (
                    <span
                      className="ml-1 iteration-data-set cursor-pointer"
                      onClick={() => viewDataSet(iterationSteps)}
                      id={`viewdataset_${ite_index + 1}`}
                    >
                      View Dataset
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className="flex-wrapper">
                  <div
                    className={`result-status flex items-center cursor-pointer ${textColor(iterationSteps?.status)}`}
                  >
                    {getFormattedTextStatus(iterationSteps?.status)}
                  </div>
                  {getFormattedTextStatus(iterationSteps?.status) === 'Failed' && isEditable ? (
                    <div className={`m-2.5 cursor-pointer ${textColor(iterationSteps?.status)}`}>
                      <BugIcon
                        MyAlert={props?.MyAlert}
                        issueData={{
                          section: 'resultSteps',
                          runId: runId,
                          nodeObj: { data: scriptData },
                          stepsDetails: { data: { section: 'iterationresultStep' }, scriptResultData: scriptData },
                          systemData: props.content.selectedSystem,
                          executionId: executionId,
                        }}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="result-duration flex items-center">
                  {iterationSteps?.executionDurationInHourMinSecFormat}
                </div>
              </div>
            </div>
            <div className="panel ">
              {_ite_arr[key][0]?.preConditions &&
                conditions(
                  _ite_arr[key][0]?.preConditions,
                  'Preconditions',
                  _ite_arr[key][0]?.preConditionStats,
                  Number(marginLeftSize) + Number(1.3)
                )}
              {_ite_arr[key][0]?.stepResults !== undefined &&
                stepsResult(
                  _ite_arr[key][0]?.stepResults,
                  _ite_arr[key][0]?.stepResultStats,
                  Number(marginLeftSize) + Number(1.3),
                  Number(iterationMarginLeft) + Number(1.3)
                )}
              {_ite_arr[key][0]?.postConditions &&
                conditions(
                  _ite_arr[key][0]?.postConditions,
                  'Postconditions',
                  _ite_arr[key][0]?.postConditionStats,
                  Number(marginLeftSize) + Number(1.3)
                )}
            </div>
          </div>
        ))}
      </div>
    );
  }

  function iterationIncludingPrePostCondition(step) {
    return (
      <div>
        <div
          className="accordion"
          onClick={(e) => {
            toggleAccordionIcon(e, 'start_Iteration');
          }}
        >
          <div className="grid grid-cols-5">
            <div className="iteration-header col-span-3">
              Start Iteration using Dataprovider
              {accordionIcon(`start_Iteration`)}
              {executionStatistics(step.stepResultStats)}
            </div>
            <div></div>
            <div className="result-duration flex items-center"></div>
          </div>
        </div>
        <div className="panel ">
          {iterationDataIncludingPrePostCondition(step.executionEntityResponses, 'DATAPROVIDER', '1.30', '1.30')}
        </div>
        <div
          className="iteration-header"
          onMouseOver={(e) => {
            onIterationHeaderFocus(e);
          }}
          id={`end_Iteration`}
        >
          End Iteration using dataprovider
        </div>
      </div>
    );
  }
  const getOverallStatus = (eachItrArray) => {
    let failedStatus = eachItrArray.find((_itr) => _itr.status === 'FAIL');
    let warningStatus = eachItrArray.find((_itr) => _itr.status === 'WARNING');
    let skippedStatus = eachItrArray.find((_itr) => _itr.status === 'SKIP');
    let passedStatus = eachItrArray.find((_itr) => _itr.status === 'PASS');
    let terminatedStatus = eachItrArray.find((_itr) => _itr.status === 'Terminated');
    let abortedStatus = eachItrArray.find((_itr) => _itr.status === 'Aborted');

    if (abortedStatus) {
      return 'Aborted';
    }
    if (terminatedStatus) {
      return 'Terminated';
    }
    if (failedStatus) {
      return 'FAIL';
    }
    if (warningStatus) {
      return 'WARNING';
    }
    if (passedStatus) {
      return 'PASS';
    }
    if (skippedStatus) {
      return 'SKIP';
    }
    return 'NA';
  };
  const getIterationList = (_iteArr, step, stepindex) => {
    const arr = Object.values(_iteArr);
    const iterationList = [];
    let _stepId = 'Iteration_';
    if (step.hasOwnProperty('iteration') && step.iteration !== null) {
      _stepId = _stepId + step.iteration + stepindex + '_' + step.stepId;
    } else {
      _stepId = _stepId + stepindex + '_' + step.stepId;
    }
    arr.forEach((_eachArr, index) => {
      let _status = getOverallStatus(_eachArr);
      iterationList.push({ itr: _eachArr, status: _status, ite_id: _stepId + '_' + index });
    });
    return iterationList;
  };

  function iterationData(step, iterationType, stepGroupIndex, marginLeftSize, iterationMarginLeft) {
    let _ite_arr = groupBy(step.stepResults, 'iteration');
    if (!hasIteration) {
      setHasIteration(true);
    }
    let iterationList = getIterationList(_ite_arr, step, stepGroupIndex);
    return iterationList?.length ? (
      <div>
        {iterationList &&
          iterationList.map((_itr, ite_index) => {
            const _id = getUniqueId();
            return (
              <div>
                <div
                  className="accordion"
                  onClick={(e) => {
                    toggleAccordionIcon(e, _itr.ite_id + _id);
                  }}
                >
                  <div className="grid grid-cols-5">
                    <div
                      className={`iteration-step col-span-3 `}
                      id={_itr.ite_id + _id}
                      style={{ marginLeft: marginLeftSize ? marginLeftSize + 'rem' : iterationMarginLeft + 'rem' }}
                    >
                      Iteration {ite_index + 1}
                      {accordionIcon(_itr.ite_id + _id)}
                      {iterationType === 'DATAPROVIDER' ? (
                        <span
                          className="ml-1 iteration-data-set cursor-pointer"
                          onClick={() => viewDataSet(_itr)}
                          id={`viewdataset_${ite_index + 1}`}
                        >
                          View Dataset
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="flex-wrapper">
                      <div className={`result-status flex items-center cursor-pointer ${textColor(_itr?.status)}`}>
                        {getFormattedTextStatus(_itr?.status)}
                      </div>
                    </div>
                    <div className="result-duration flex items-center">{millisecondsToTime(_itr?.itr)}</div>
                  </div>
                </div>
                <div className="panel ">
                  {_itr.itr &&
                    steps(
                      _itr.itr,
                      iterationType,
                      Number(marginLeftSize) + Number(1.3),
                      Number(iterationMarginLeft) + Number(1.3),
                      `${stepGroupIndex}${ite_index + 1}`
                    )}
                </div>
              </div>
            );
          })}
      </div>
    ) : (
      <div>
        {['FORLOOP']?.includes(iterationType) &&
          steps(
            step?.stepResults,
            'step',
            Number(marginLeftSize) + Number(1.3),
            Number(iterationMarginLeft) + Number(1.3)
          )}
      </div>
    );
  }

  function stepGroup(stepGroupResult, stepGroupIndex, marginLeftSize, iterationId, parentStep) {
    const elementId = stepGroupResult?.stepId + stepGroupIndex + (iterationId || '') + getStepId();
    return (
      <div>
        <div
          className="accordion"
          onClick={(e) => {
            toggleAccordionIcon(e, elementId, stepGroupResult.executionDurationInHourMinSecFormat);
          }}
        >
          <div className="grid grid-cols-5 flex items-center accordion-row-min-h">
            <div
              className={`step-result cursor-pointer col-span-3`}
              style={{ marginLeft: marginLeftSize ? marginLeftSize + 'rem' : '1.30rem' }}
            >
              {stepGroupIndex}.{stepGroupResult?.name}
              {accordionIcon(elementId, stepGroupResult?.stepId)}
              {executionStatistics(stepGroupResult?.stepResultStats)}
            </div>
            <div className={`result-status flex items-center cursor-pointer ${textColor(stepGroupResult?.status)}`}>
              {filteredStatus === 'ALL' ? getFormattedTextStatus(stepGroupResult?.status) : ''}
            </div>
            <div className="result-duration flex items-center">
              {stepGroupResult?.executionDurationInHourMinSecFormat}
            </div>
          </div>
        </div>
        <div className="panel ">
          {stepGroupResult?.stepResults !== undefined &&
            steps(
              stepGroupResult?.stepResults,
              'stepgroup',
              Number(marginLeftSize) + Number(1.3),
              '',
              iterationId,
              parentStep || stepGroupResult
            )}
        </div>
      </div>
    );
  }
  /*will need this in future */
  function forLoopIteration(data) {
    /*if(data?.stepResults){
      let _ite_arr = groupBy(data?.stepResults, 'iteration');
      let iterations = Object.keys(_ite_arr);
      return '(int i=0, i<' + iterations.length + ', i++)';
    }*/
    return '';
  }

  const handleRemoveAndResolveTag = async () => {
    if (tagData?.data?.uniqueId) {
      const response = await getStepResult(tagData.data.uniqueId);
      if (response?.data?.responseObject) {
        removeAndResolveTag(response.data.responseObject, 'Optimized');
      }
    } else {
      removeAndResolveTag(tagData.data);
    }
  };

  const getUniqueId = (iteId) => {
    let _id;
    _id = Date.now().toString(36) + Math.random().toString(36);
    if (iteId) {
      _id = _id + iteId;
    }
    return _id;
  };
  /**
   * remove and resolve the tag
   */
  const removeAndResolveTag = async (step, state) => {
    const tagDTO = tagData.tagDTO;
    const execResult = (await getIDB('execResult')).responseObject;
    const element = step.stepInputs.filter((input) => input?.reference === 'ELEMENT');
    if (execResult && execResult.executionResponses.length) {
      const executionId = execResult.executionResponses[0].executionId;
      const projectId = execResult.executionResponses[0].projectId;
      const payload = {
        id: tagDTO.id || tagDTO._id,
        suiteId: execResult.executionResponses[0]?.suiteId,
        updateEveryWhere: tagDTO.updateEveryWhere,
        projectId: projectId,
        scriptId: scriptId,
        stepId: step.stepId,
        state: state,
        runId: runId,
        clientId: clientId,
      };
      if (tagDTO.hasOwnProperty('updateAllSteps')) payload['updateAllSteps'] = tagDTO.updateAllSteps;
      else payload['updateAllSteps'] = false;
      const utilObj = {
        stepIndex: tagData.index,
      };
      if (step.errorLog) {
        payload['exceptionName'] = step.errorLog.name;
      }
      if (element.length) {
        utilObj['elementId'] = element[0].value;
      }
      try {
        if (isResolve) {
          payload['status'] = 'Resolved';
          const res = await resolveStepTag(executionId, payload, resolveStep);
          if (res?.data?.responseCode === 200) {
            updateScriptWithTag(step, tagDTO, 'RESOLVE', utilObj);
            updateDeleteAndResolve();
          } else {
            toastPropsHandler({
              variant: TOAST_CONSTANTS.VARIANTS.DANGER,
              message: res?.data?.message,
            });

            setIsResolve(false);
          }
        } else {
          const res = await deleteStepTag(executionId, payload);
          if (res?.data?.responseCode === 200) {
            updateScriptWithTag(step, tagDTO, 'DELETE', utilObj);
            updateDeleteAndResolve();
            discardAutotaggingStep('Delete');
          } else {
            if (res?.data?.responseCode === 404) {
              toastPropsHandler({
                variant: TOAST_CONSTANTS.VARIANTS.DANGER,
                message: 'Resource not found. Please refresh the page!',
              });

              updateDeleteAndResolve();
            } else {
              toastPropsHandler({
                variant: TOAST_CONSTANTS.VARIANTS.DANGER,
                message: res?.data?.message,
              });
            }
            setOpenDeleteModal(false);
            setTagData({
              index: null,
              data: null,
              tagDTO: null,
            });
          }
        }
      } catch (err) {
        toastPropsHandler({
          variant: TOAST_CONSTANTS.VARIANTS.DANGER,
          message: err?.message,
        });

        console.error(err.message);
        setOpenDeleteModal(false);
        setIsResolve(false);
        setTagData({
          index: null,
          data: null,
          tagDTO: null,
        });
      }
    }
  };

  const handleStepTags = async (scriptId, stepId) => {
    let returned_data = await getIDB('script-data');
    let res = returned_data.filter((dat) => dat.id === scriptId || dat.key === scriptId);
    setIDB('script-data', res);
    if (res[0]) {
      getScriptTags(res[0]?.key);
      setscriptData(res[0]);
    }
  };

  const updateDeleteAndResolve = () => {
    setResolveStep('');
    setEmptyOption(false);
  };

  /*If the step is a step group */
  const handleStepGroupStepUpdate = (step, _eachStep, tagDto, operation, utilObj, stepResults) => {
    _eachStep.stepResults &&
      _eachStep.stepResults.forEach((_eachSTGStep, _stgIndex) => {
        if (_eachSTGStep.type === 'Group') {
          handleStepGroupStepUpdate(step, _eachSTGStep, tagDto, operation, utilObj, stepResults);
        } else {
          updateEachStep(step, _eachSTGStep, tagDto, operation, utilObj, stepResults);
        }
      });
  };
  /**
   * Update the current script data in local storage with the created/updated/deleted tag
   * @param {*} step : step on which tag was created
   * @param {*} tagDto : tag data object
   * @param {*} operation : CREATE, EDIT, DELETE
   * @param {*} utilObj : utility object contains element id and exception name on which the tag is created
   */
  const updateScriptWithTag = async (step, tagDto, operation, utilObj) => {
    try {
      let returned_data = await getIDB('script-data');
      let res = returned_data.filter((dat) => dat.id === scriptId || dat.key === scriptId);
      let stepResults = res[0]?.stepResults || '';
      if (operation === 'RESOLVE') {
        let _eachStep;
        while (stepPathQueueRef.current.length) {
          if (stepPathQueueRef.current[0] < stepResults.length) {
            let index = stepPathQueueRef.current.shift();
            if (stepResults.length) {
              _eachStep = stepResults[index];
              if (_eachStep.stepResults) stepResults = _eachStep.stepResults;
            }
          } else return;
        }
        if (_eachStep) {
          if (_eachStep.tagDetailsDTOs) {
            _eachStep.tagDetailsDTOs.forEach((_eachTagDtObj, _tIndex) => {
              if (_eachTagDtObj.id === tagDto.id || _eachTagDtObj._id === tagDto._id) {
                _eachStep.tagDetailsDTOs.splice(_tIndex, 1);
              }
            });
          } else {
            _eachStep.tagDetailsDTOs = [];
          }
          updateDeleteAndResolve();
          toastPropsHandler({
            variant: TOAST_CONSTANTS.VARIANTS.SUCCESS,
            message: (
              <>
                <Label className="fontPoppinsSemiboldSm" color={black_border} label={tagDto?.name} /> tag resolved
                successfully
              </>
            ),
          });
        }
      } else {
        if (['PRE', 'POST'].includes(stepResultType)) {
          if (stepResultType === 'PRE' && res[0]?.preConditions && res[0].preConditions?.stepResults) {
            updateStepData(res[0].preConditions.stepResults, step, tagDto, operation, utilObj);
          }
          if (stepResultType === 'POST' && res[0]?.postConditions && res[0].postConditions?.stepResults) {
            updateStepData(res[0].postConditions.stepResults, step, tagDto, operation, utilObj);
          }
        } else {
          if (res[0]?.stepResults && res[0]?.stepResults.length) {
            updateStepData(res[0].stepResults, step, tagDto, operation, utilObj);
          }
        }
      }

      setIDB('script-data', res);
      if (res[0]) {
        getScriptTags(res[0]?.key);
        setscriptData(res[0]);
      }
      if (['DELETE', 'RESOLVE'].includes(operation)) {
        setOpenDeleteModal(false);
        setTagData({
          index: null,
          data: null,
          tagDTO: null,
          taggedData: null,
        });
        if (operation === 'RESOLVE') {
          setIsResolve(false);
          setResolveStep('');
          toastPropsHandler({
            variant: TOAST_CONSTANTS.VARIANTS.SUCCESS,
            message: (
              <>
                <Label className="fontPoppinsSemiboldSm" color={black_border} label={tagDto?.name} /> tag resolved
                successfully
              </>
            ),
          });
        } else {
          updateDeleteAndResolve();
          toastPropsHandler({
            variant: TOAST_CONSTANTS.VARIANTS.SUCCESS,
            message: (
              <>
                <Label className="fontPoppinsSemiboldSm" color={black_border} label={tagDto?.name} /> tag discarded
                successfully
              </>
            ),
          });
        }
      } else {
        setOpenTagModal({
          openModal: false,
          data: null,
          index: null,
          edit: false,
          tagDTO: null,
          _tagindex: null,
          tagDetailsDTOs: null,
          taggedData: null,
          stepId: null,
        });
        if (operation === 'EDIT') {
          toastPropsHandler({
            variant: TOAST_CONSTANTS.VARIANTS.SUCCESS,
            message: (
              <>
                <Label className="fontPoppinsSemiboldSm" color={black_border} label={tagDto?.name} /> tag updated
                successfully
              </>
            ),
          });
        } else {
          toastPropsHandler({
            variant: TOAST_CONSTANTS.VARIANTS.SUCCESS,
            message: (
              <>
                <Label className="fontPoppinsSemiboldSm" color={black_border} label={tagDto?.name} /> tag created
                successfully
              </>
            ),
          });
        }
      }
    } catch (err) {
      toastPropsHandler({
        variant: TOAST_CONSTANTS.VARIANTS.DANGER,
        message: 'Unexpected error. Please reload the page.',
      });
      console.error(err.message);
      setOpenDeleteModal(false);
      setTagData({
        index: null,
        data: null,
        tagDTO: null,
        taggedData: null,
      });
      setOpenTagModal({
        openModal: false,
        data: null,
        index: null,
        edit: false,
        tagDTO: null,
        _tagindex: null,
        tagDetailsDTOs: null,
        taggedData: null,
        stepId: null,
      });
    }
  };

  /**
   * get index path
   */

  function hasPath(_allStepResults, arr, x, childOfSTG, type) {
    let returnedValue = false;
    if (_allStepResults == null) return false;
    for (let i = 0; i < _allStepResults.length; i++) {
      let _st = _allStepResults[i];
      if (_st.status === 'FAIL') {
        if (['Group', 'Iteration', '_startforloop'].includes(_st.type) && _st.stepResults) {
          arr.push({ obj: _st, ind: i });
          returnedValue = hasPath(_st.stepResults, arr, x, true);
          if (returnedValue) return returnedValue;
        }
        if (arr.length) {
          //for multi level
          if (childOfSTG && _st.type === 'step') {
            if (_st.stepId === x.stepId && _st.executedOn === x.executedOn) {
              if (!_st.hasOwnProperty('iteration')) {
                arr.push({ obj: _st, ind: i });
                return true;
              } else {
                //for iteration steps
                if (_st.iteration === x.iteration) {
                  arr.push({ obj: _st, ind: i });
                  return true;
                }
              }
            } else {
              if (i === _allStepResults.length - 1) {
                if (arr.length) arr.pop();
                if (arr.length === 0) childOfSTG = false;
              }
            }
          }
        } else if (_st.type !== 'Group' && _st.type === 'step') {
          if (_st.stepId === x.stepId && _st.executedOn === x.executedOn) {
            if (!_st.hasOwnProperty('iteration')) {
              arr.push({ obj: _st, ind: i });
              return true;
            }
          }
        }
      } else {
        //when _allStepResults doesn't have tagging step
        if (i === _allStepResults.length - 1) {
          if (arr.length) arr.pop();
          if (arr.length === 0) childOfSTG = false;
        }
      }
    }
    return returnedValue;
  }

  function getPath(stepResults, step, type) {
    let arr = [];
    let temp = [];
    const _found = hasPath(stepResults, arr, step, false, type);
    setStepResultType('');
    if (_found) {
      for (let _arr of arr) {
        temp.push(_arr.ind);
      }
      localStorage.setItem('stepPath', JSON.stringify(temp));
      setStepPathQueue(temp);
      if (type) setStepResultType(type);
      else setStepResultType('STEP');
    } else console.info('No Path');
    return _found;
  }

  /**
   * Update all steps with tag
   */
  const updateStepData = (stepResults, step, tagDto, operation, utilObj) => {
    if (tagDto.updateAllSteps) {
      stepResults.length &&
        stepResults.forEach((_eachStep, _index) => {
          if (_eachStep.type === 'Group') {
            handleStepGroupStepUpdate(step, _eachStep, tagDto, operation, utilObj, stepResults);
          } else {
            if (['Iteration', '_startforloop'].includes(_eachStep.type)) {
              updateIterationSteps(step, _eachStep, _index, tagDto, operation, utilObj, stepResults);
            } else {
              updateEachStep(step, _eachStep, tagDto, operation, utilObj, stepResults);
            }
          }
        });
    } else {
      if (step.type === 'Group') {
        let _eachStep;
        while (stepPathQueueRef.current.length) {
          if (stepPathQueueRef.current[0] < stepResults.length) {
            let index = stepPathQueueRef.current.shift();
            if (stepResults.length) {
              _eachStep = stepResults[index];
              if (_eachStep.stepResults) {
                stepResults = _eachStep.stepResults;
              }
            }
          } else return;
        }
        if (_eachStep) {
          handleStepGroupStepUpdate(step, _eachStep, tagDto, operation, utilObj, stepResults);
        }
      } else {
        if (step.type === 'Iteration' || step.hasOwnProperty('iteration')) {
          if (stepPathQueueRef.current.length) {
            let indexx = stepPathQueueRef.current.shift();
            if (indexx > -1) {
              let itrationStepObj = stepResults[indexx];
              updateIterationSteps(step, itrationStepObj, utilObj.stepIndex, tagDto, operation, utilObj, stepResults);
            }
          }
        } else {
          let _eachStep;
          while (stepPathQueueRef.current.length) {
            if (stepPathQueueRef.current[0] < stepResults.length) {
              if (stepResults[stepPathQueueRef.current[0]]) {
                let index = stepPathQueueRef.current.shift();
                if (stepResults.length) {
                  _eachStep = stepResults[index];
                  if (_eachStep.stepResults) {
                    stepResults = _eachStep.stepResults;
                  }
                }
              }
            } else return;
          }
          if (_eachStep) updateEachStep(step, _eachStep, tagDto, operation, utilObj, stepResults);
        }
      }
    }
  };
  /**
   * Update each step of a script with the tags
   */
  const updateEachStep = (step, _eachStep, tagDto, operation, utilObj, stepResults) => {
    if (_eachStep && _eachStep.status === 'FAIL' && _eachStep.stepInputs) {
      const _stepElement = _eachStep?.stepInputs.filter((input) => input?.reference === 'ELEMENT');
      const _stepException = _eachStep?.errorLogName;
      if (Object.keys(utilObj).length) {
        const exceptionName = utilObj?.exceptionName;
        const elementId = utilObj?.elementId;
        if (
          _eachStep.nlpName &&
          exceptionName === _stepException &&
          (elementId
            ? _stepElement && _stepElement.length && _stepElement.some((ele) => ele?.value === elementId)
            : _eachStep.nlpName === step.nlpName)
        ) {
          if (_eachStep.tagDetailsDTOs) {
            if (operation !== 'CREATE') {
              _eachStep.tagDetailsDTOs.forEach((_eachTagDtObj, _tIndex) => {
                if (_eachTagDtObj.id === tagDto.id || _eachTagDtObj._id === tagDto._id) {
                  if (operation === 'DELETE') {
                    _eachStep.tagDetailsDTOs.splice(_tIndex, 1);
                  } else if (operation === 'EDIT') {
                    _eachStep.tagDetailsDTOs[_tIndex] = tagDto;
                  }
                }
              });
              if (operation === 'DELETE' && !_eachStep.tagDetailsDTOs.length) {
                delete _eachStep['tagDetailsDTOs'];
              }
            } else {
              //for creating a new tag for the step which already have tag
              _eachStep.tagDetailsDTOs.push(tagDto);
            }
          } else {
            //creating a new tag for a fresh step which doesn't have any tag
            if (operation === 'CREATE') {
              _eachStep['tagDetailsDTOs'] = [tagDto];
            }
          }
        }
      }
    }
  };
  const updateIterationSteps = (step, _eachStep, _index, tagDto, operation, utilObj) => {
    let _ite_arr = groupBy(_eachStep.stepResults, 'iteration');
    let iterationList = getIterationList(_ite_arr, _eachStep, _index);
    iterationList.forEach((_itr) => {
      _itr.itr.forEach((itrStep) => {
        if (
          tagDto.updateAllSteps ||
          (itrStep.id === step.id && itrStep.stepId === step.stepId && itrStep.executedOn === step.executedOn)
        ) {
          if (itrStep.type === 'Group') handleStepGroupStepUpdate(step, itrStep, tagDto, operation, utilObj);
          else updateEachStep(step, itrStep, tagDto, operation, utilObj);
        }
      });
    });
  };

  const getformatedResultMessage = (message, row, marginLeftSize) => {
    if (
      [
        'IfCondition',
        'EndIfCondition',
        'EndElseIfCondition',
        'EndElseCondition',
        'ElseIfCondition',
        'ElseCondition',
      ].includes(row?.nlpName)
    ) {
      if (row?.nlpName === 'IfCondition')
        return (message = (
          <span
            className={elementHover ? 'accordion section-hover' : 'accordion section'}
            onMouseOver={(e) => {
              onMouseOverIfHeader(e);
            }}
            onMouseLeave={(e) => {
              onMouseLeaveIfHeader(e);
            }}
          >
            {row?.message
              ? row?.message
              : `if (${
                  row?.stepInputs[0]?.hasOwnProperty('actualValue')
                    ? row?.stepInputs[0]?.actualValue
                    : row?.stepInputs[0]?.value
                }${
                  row?.stepInputs[1]?.hasOwnProperty('actualValue')
                    ? row?.stepInputs[1]?.actualValue
                    : row?.stepInputs[1]?.value
                }${
                  row?.stepInputs[2]?.hasOwnProperty('actualValue')
                    ? row?.stepInputs[2]?.actualValue
                    : row?.stepInputs[2]?.value
                }) - Condition is ${row.status === 'PASS' ? 'True' : 'false'}`}
          </span>
        ));
      if (row?.nlpName === 'EndIfCondition') return (message = '');
      if (row?.nlpName === 'ElseIfCondition')
        return (message = (
          <span
            className={elementHover ? 'accordion section-hover' : 'accordion section'}
            onMouseOver={(e) => {
              onMouseOverIfHeader(e);
            }}
            onMouseLeave={(e) => {
              onMouseLeaveIfHeader(e);
            }}
          >
            {row?.message
              ? row?.message
              : `Else if (${
                  row?.stepInputs[0]?.hasOwnProperty('actualValue')
                    ? row?.stepInputs[0]?.actualValue
                    : row?.stepInputs[0]?.value
                }${
                  row?.stepInputs[1]?.hasOwnProperty('actualValue')
                    ? row?.stepInputs[1]?.actualValue
                    : row?.stepInputs[1]?.value
                }${
                  row?.stepInputs[2]?.hasOwnProperty('actualValue')
                    ? row?.stepInputs[2]?.actualValue
                    : row?.stepInputs[2]?.value
                }) - Condition is ${row.status === 'PASS' ? 'True' : 'false'}`}
          </span>
        ));
      if (row?.nlpName === 'EndElseIfCondition') return (message = '');
      if (row?.nlpName === 'ElseCondition')
        return (message = (
          <span
            className={elementHover ? 'accordion section-hover' : 'accordion section'}
            onMouseOver={(e) => {
              onMouseOverIfHeader(e);
            }}
            onMouseLeave={(e) => {
              onMouseLeaveIfHeader(e);
            }}
          >
            {row?.message
              ? row?.message
              : `Else ${row.status === 'PASS' ? '- Executed' : `is ${getFormattedTextStatus(row.status)}`}`}
          </span>
        ));
      if (row?.nlpName === 'EndElseCondition') return (message = '');
    } else {
      return message;
    }
  };

  const getFormattedStatusIfElse = (status, row) => {
    if (
      row?.nlpName === 'IfCondition' ||
      row?.nlpName === 'EndIfCondition' ||
      row?.nlpName === 'EndElseIfCondition' ||
      row?.nlpName === 'EndElseCondition' ||
      row?.nlpName === 'ElseIfCondition' ||
      row?.nlpName === 'ElseCondition'
    ) {
      return (status = '');
    } else {
      return status;
    }
  };

  const getFormattedDurationIfElse = (status, row) => {
    if (
      row?.nlpName === 'IfCondition' ||
      row?.nlpName === 'EndIfCondition' ||
      row?.nlpName === 'EndElseIfCondition' ||
      row?.nlpName === 'EndElseCondition' ||
      row?.nlpName === 'ElseIfCondition' ||
      row?.nlpName === 'ElseCondition'
    ) {
      return (status = '');
    } else {
      return status;
    }
  };

  /**
   *
   * @param {*} steps
   * @param {*} type | type is exclusive to distinguish between pre/post condtition
   * @param {*} marginLeftSize
   * @param {*} iterationMarginLeft
   * @param {*} iteId
   * @returns
   */

  const checkFilterStatus = (step) => {
    switch (filteredStatus) {
      case filterObject?.all:
        return true;
      case filterObject?.fail:
        return step?.totalFailed > 0;
      case filterObject?.pass:
        return step?.totalPassed > 0;
      case filterObject?.warning:
        return step?.totalWarning > 0;
      case filterObject?.skip:
        return step?.totalSkipped > 0;
      default:
        return false;
    }
  };

  const getFilterCount = (step, tempFilterVal) => {
    const updateFilterValue = (filterKey, stepKey) => {
      tempFilterVal[filterKey] =
        tempFilterVal?.[filterKey] === 0 && step?.stepResultStats?.[stepKey] > 0 ? 1 : tempFilterVal?.[filterKey];
    };
    updateFilterValue('totalFailed', 'totalFailed');
    updateFilterValue('totalPassed', 'totalPassed');
    updateFilterValue('totalSkipped', 'totalSkipped');
    updateFilterValue('totalWarning', 'totalWarning');
    if (step?.stepResults?.length) {
      step.stepResults.forEach((obj) => getFilterCount(obj, tempFilterVal));
    }
    return tempFilterVal;
  };

  const checkFilterStatusPrepost = (step, type) => {
    const filterResCount = {
      totalFailed: 0,
      totalPassed: 0,
      totalWarning: 0,
      totalSkipped: 0,
    };
    const filteredCount = getFilterCount(type === 'pre' ? step?.preConditions : step?.postConditions, filterResCount);
    switch (filteredStatus) {
      case filterObject?.all:
        return true;
      case filterObject?.fail:
        return filteredCount.totalFailed > 0;
      case filterObject?.pass:
        return filteredCount.totalPassed > 0;
      case filterObject?.warning:
        return filteredCount.totalWarning > 0;
      case filterObject?.skip:
        return filteredCount.totalSkipped > 0;
      default:
        return false;
    }
  };

  function steps(steps, type, marginLeftSize, iterationMarginLeft, iteId, parentStep, condition) {
    let isTaggedByAI = false;
    let aiUntaggedName = '';
    return (
      <div>
        {steps &&
          steps.map((_step, index) => {
            const _id = getUniqueId(iteId);
            const _idStep = getUniqueId(_step.stepId);

            if (_step?.uniqueId && scriptTagData?.length) {
              const tagData = scriptTagData.find((scriptTag) => scriptTag['_id'] === _step.uniqueId);
              if (tagData) {
                _step.tagDetailsDTOs = tagData.tagDetailsDTOs;
              } else {
                _step.tagDetailsDTOs = [];
              }
            } else {
              _step.tagDetailsDTOs = [];
            }
            let taggedData = individualStepData?.find((stepData) => stepData.stepId === _step.stepId);
            const isTagged = taggedData?.tagDetailsDTOs?.[0]?.Tagged === 'YES';
            const nameOfTag = taggedData?.tagDetailsDTOs?.[0]?.tagName;
            if (taggedData && isTagged) {
              isTaggedByAI = true;
              aiUntaggedName = nameOfTag;
            } else {
              isTaggedByAI = false;
            }
            let taggedByAI = [];
            let taggedManually = [];
            if (_step.tagDetailsDTOs?.length) {
              taggedByAI = _step.tagDetailsDTOs.filter((step) => step.taggedBy === 'AI');
              taggedManually = _step.tagDetailsDTOs.filter((step) => !step?.taggedBy || step?.taggedBy !== 'AI');
            }
            const ifStepTagged = _step.tagDetailsDTOs && _step.tagDetailsDTOs.length ? true : false;
            return (
              <div>
                {_step?.type === 'step' && !ifElseConditionsList.includes(_step?.name) ? (
                  stepRenderer(
                    steps,
                    _step,
                    _idStep,
                    type,
                    index,
                    marginLeftSize,
                    ifStepTagged,
                    parentStep,
                    condition,
                    isTaggedByAI,
                    taggedData,
                    taggedByAI,
                    taggedManually,
                    aiUntaggedName
                  )
                ) : ['Iteration', 'EndIteration'].includes(_step?.type) ? (
                  dpIterationRenderer(_step, _id, index, marginLeftSize, iterationMarginLeft)
                ) : ['ForCondition', 'EndForCondition', '_startforloop', '_endforloop'].includes(_step?.type) ? (
                  forConditionRenderer(_step, _id, index, marginLeftSize, iterationMarginLeft)
                ) : ifElseConditionsList.includes(_step?.type) ? (
                  <div>{ifElseCondition(_step, index, marginLeftSize ? Number(marginLeftSize) : '')}</div>
                ) : (
                  <div>
                    {checkFilterStatus(_step?.stepResultStats) &&
                      stepGroup(_step, index + 1, marginLeftSize ? marginLeftSize : '', iteId, parentStep, condition)}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  }
  /*
   * for loop ui renderer
   */
  const forConditionRenderer = (_step, _id, index, marginLeftSize, iterationMarginLeft) => {
    return _step.name === 'Start for loop' ? (
      <div>
        <div
          className="accordion"
          onClick={(e) => {
            toggleAccordionIcon(e, `start_forloop${_id}`);
          }}
        >
          <div className="grid grid-cols-5 step-header" key={`step${index}`}>
            <div className={`col-span-3 step-result items-center cursor-pointer mt-3`}>
              <div
                className="iteration-header"
                id={`start_forloop${_id}`}
                style={{ marginLeft: marginLeftSize !== undefined ? marginLeftSize + 'rem' : '' }}
              >
                Start for loop {forLoopIteration(_step)}
                {accordionIcon(`start_forloop${_id}`)}
                {executionStatistics(_step.stepResultStats)}
              </div>
            </div>
            <div className="flex-wrapper">
              <div className={`result-status flex items-center cursor-pointer ${textColor(_step?.status)}`}></div>
            </div>
            <div className="result-duration flex items-center"></div>
          </div>
        </div>
        <div className="panel ">
          {_step?.stepResults &&
            iterationData(
              _step,
              'FORLOOP',
              index,
              Number(marginLeftSize) + Number(1.3),
              Number(iterationMarginLeft) + Number(1.3)
            )}
        </div>
      </div>
    ) : _step.name === 'End for loop' ? (
      <div className="grid grid-cols-5">
        <div
          className="col-span-3 iteration-header"
          onMouseOver={(e) => {
            onIterationHeaderFocus(e);
          }}
          id={`end_forloop${_id}`}
          style={{ marginLeft: marginLeftSize !== undefined ? marginLeftSize + 'rem' : '1.30rem' }}
        >
          End for loop
        </div>
        <div className="flex-wrapper">
          <div className={`result-status flex items-center cursor-pointer ${textColor(_step?.status)}`}></div>
        </div>
        <div className="result-duration flex items-center"></div>
      </div>
    ) : (
      ''
    );
  };
  const dpIterationRenderer = (_step, _id, index, marginLeftSize, iterationMarginLeft) => {
    return _step.type === 'Iteration' ? (
      <div>
        <div className="grid grid-cols-5 step-header" key={`step${index}`}>
          <div className={`col-span-5 step-result items-center cursor-pointer mt-3`}>
            <div
              className="accordion iteration-header"
              id={'start_Iteration' + _id}
              onClick={(e) => {
                toggleAccordionIcon(e, 'start_Iteration' + _id);
              }}
              style={{ marginLeft: marginLeftSize !== undefined ? marginLeftSize + 'rem' : '' }}
            >
              {_step.name}
              {accordionIcon('start_Iteration' + _id)}
              {executionStatistics(_step.stepResultStats)}
            </div>
            <div className="panel ">
              {_step?.stepResults &&
                iterationData(
                  _step,
                  'DATAPROVIDER',
                  index,
                  Number(marginLeftSize) + Number(1.3),
                  Number(iterationMarginLeft) + Number(1.3)
                )}
            </div>
          </div>
          <div id={`msg_Iteration${index}`} className="ite-msg text-primary" style={{ display: 'none' }}>
            End focused
          </div>
        </div>
      </div>
    ) : _step?.type === 'EndIteration' ? (
      <div
        className="iteration-header"
        onMouseOver={(e) => {
          onIterationHeaderFocus(e);
        }}
        id={`end_Iteration${_id}`}
        style={{ marginLeft: marginLeftSize !== undefined ? marginLeftSize + 'rem' : '' }}
      >
        {_step.name.replace('Start', 'End')}
      </div>
    ) : (
      ''
    );
  };

  const stepRenderer = (
    allSteps,
    _step,
    _idStep,
    type,
    index,
    marginLeftSize,
    ifStepTagged,
    parentStep,
    condition,
    isTaggedByAI,
    taggedData,
    taggedByAI,
    taggedManually,
    aiUntaggedName
  ) => {
    return (
      (_step?.status === filteredStatus || filteredStatus === 'ALL') && (
        <div className="grid grid-cols-5 step-header" key={`step${index}`}>
          <Tooltip title={_step?.message ? _step?.message : _step?.name} placement="top-start">
            <div
              className={`col-span-3 step-result cursor-pointer truncate pr-3 mt-2`}
              style={{ marginLeft: marginLeftSize ? marginLeftSize + 'rem' : '2.30rem' }}
              id={`${type}_step_${_idStep}`}
              onClick={() => viewStepResultModal(_step, parentStep, condition)}
            >
              {index + 1}.{' '}
              {getformatedResultMessage(_step?.message ? _step?.message : _step?.name, _step, marginLeftSize)}
            </div>
          </Tooltip>
          <div className="flex items-center h-9">
            {_step?.status === 'N/A' ? (
              <Tooltip
                title="This step is not executed since one of the condition statements is executed"
                placement="top"
              >
                <div
                  className={`result-status flex items-center cursor-pointer ${textColor(_step?.status)}`}
                  onClick={() => viewStepResultModal(_step, parentStep, condition)}
                >
                  {getFormattedTextStatus(getFormattedStatusIfElse(_step.status, _step))}
                </div>
              </Tooltip>
            ) : (
              <div
                className={`result-status flex items-center cursor-pointer ${textColor(_step?.status)}`}
                onClick={() => {
                  getData(executionId);
                  viewStepResultModal(_step, parentStep, condition);
                }}
              >
                {getFormattedTextStatus(getFormattedStatusIfElse(_step.status, _step))}
              </div>
            )}
            {getFormattedTextStatus(_step?.status) === 'Failed' && (
              <>
                <div className="flex-col">
                  {isEditable && (
                    <div className={`cursor-pointer ml-1 ${textColor(_step?.status)}`} id={`${type}_step_${_idStep}`}>
                      <BugIcon
                        MyAlert={props?.MyAlert}
                        issueData={{
                          section: 'resultSteps',
                          runId: runId,
                          nodeObj: { data: scriptData },
                          stepsDetails: { data: { section: 'steps', id: _step?.stepId }, scriptResultData: scriptData },
                          systemData: props.content.selectedSystem,
                          executionId: executionId,
                        }}
                      />
                    </div>
                  )}
                </div>
                {ifSuiteExecution === 'execution' && !_step?.isWebServiceRequest && (
                  <>
                    <div className="flex content-center items-center ml-3">
                      {isTaggedByAI && isEmptyValue(taggedByAI) && isEmptyValue(taggedManually) && (
                        <Tooltip title={`Pending AI-${aiUntaggedName}`}>
                          <div>
                            <span
                              className="flex justify-center center cursor-pointer"
                              onClick={() => {
                                handleStepTag(_step, allSteps, index, taggedData);
                              }}
                            >
                              <Icon color={rs_primary} name="ai_untagged" />
                            </span>
                          </div>
                        </Tooltip>
                      )}
                      {isTaggedByAI && !isEmptyValue(taggedByAI) && isEmptyValue(taggedManually) && (
                        <Tooltip title="Create manual tag">
                          <div>
                            <span className="flex justify-center center cursor-pointer">
                              <Icon
                                color={rs_primary}
                                name={'manual_untagged'}
                                onClick={() => handleStepTag(_step, allSteps, index)}
                              />
                            </span>
                          </div>
                        </Tooltip>
                      )}
                      {!isTaggedByAI && isEmptyValue(taggedByAI) && isEmptyValue(taggedManually) && (
                        <Tooltip title="Create manual tag">
                          <div>
                            <span className="flex justify-center center cursor-pointer">
                              <Icon
                                color={rs_primary}
                                name={'manual_untagged'}
                                onClick={() => handleStepTag(_step, allSteps, index)}
                              />
                            </span>
                          </div>
                        </Tooltip>
                      )}
                      {ifStepTagged && (taggedByAI?.length || taggedManually?.length) && (
                        <Tag
                          setResolveAndDeleteText={setResolveAndDeleteText}
                          openPopup={openPopup}
                          setopenPopup={setopenPopup}
                          _step={_step}
                          taggedManually={taggedManually}
                          taggedByAI={taggedByAI}
                          steps={allSteps}
                          setOpenTagModal={setOpenTagModal}
                          setTagData={setTagData}
                          setMessage={setMessage}
                          setOpenDeleteModal={setOpenDeleteModal}
                          index={index}
                          resolveTag={handleRemoveAndResolveTag}
                          setIsResolve={setIsResolve}
                          isEditable={isEditable}
                          hasFullAccess={hasFullAccess}
                          scriptData={scriptData}
                          getPathController={getPathController}
                          taggedData={tagModal.taggedData}
                          setResolveStep={setResolveStep}
                          discardAutotaggingStep={discardAutotaggingStep}
                        />
                      )}
                    </div>
                  </>
                )}
                {_step?.errorLogName && _step?.platform && (
                  <Tooltip title="Suggestions to fix">
                    <div className="view-in-expand">
                      <Icon
                        name="nlp_suggestion_icon"
                        color={colors.ff_black_shade}
                        hoverFill={true}
                        onClick={() => handleExceptionSuggestion(_step.errorLogName, _step.platform)}
                      />
                    </div>
                  </Tooltip>
                )}
                {openExceptionSuggestion && (
                  <ExceptionSuggestionModal
                    isOpen={openExceptionSuggestion}
                    step={_step}
                    onClose={handleExceptionSuggesitonOnClose}
                    openFrom={RESULT_PAGE}
                    exceptionSuggestion={exceptionSuggestion}
                  />
                )}
              </>
            )}
          </div>
          <div className="result-duration flex items-center">
            {getFormattedDurationIfElse(_step?.executionDurationInHourMinSecFormat, _step)}
          </div>
        </div>
      )
    );
  };

  function ifElseCondition(step, index, marginLeftSize) {
    return (
      <div>
        {['If Condition', 'End If Condition'].includes(step?.name) ? (
          step.name === 'If Condition' ? (
            <div>
              {ifElseJsx(
                step,
                index,
                step.name,
                'start_If',
                marginLeftSize ? Number(marginLeftSize) + Number(1.3) : ''
              )}
            </div>
          ) : step.name === 'End If Condition' ? (
            <div
              className={elementHover ? 'accordion section-hover' : 'accordion section'}
              onMouseOver={(e) => {
                onMouseOverIfHeader(e);
              }}
              onMouseLeave={(e) => {
                onMouseLeaveIfHeader(e);
              }}
              id={`end_If${index}`}
              style={{ marginLeft: marginLeftSize !== undefined ? Number(marginLeftSize) - Number(1.3) + 'rem' : '' }}
            >
              End If
            </div>
          ) : (
            ''
          )
        ) : ['Else If Condition', 'End Else If Condition'].includes(step?.name) ? (
          step.name === 'Else If Condition' ? (
            <div>
              {ifElseJsx(
                step,
                index,
                step.name,
                'start_elseIf',
                marginLeftSize ? Number(marginLeftSize) + Number(1.3) : ''
              )}
            </div>
          ) : step.name === 'End Else If Condition' ? (
            <div
              className={elementHover ? 'accordion section-hover' : 'accordion section'}
              onMouseOver={(e) => {
                onMouseOverIfHeader(e);
              }}
              onMouseLeave={(e) => {
                onMouseLeaveIfHeader(e);
              }}
              id={`end_elseIf${index}`}
              style={{ marginLeft: marginLeftSize !== undefined ? Number(marginLeftSize) - Number(1.3) + 'rem' : '' }}
            >
              End Else If
            </div>
          ) : (
            ''
          )
        ) : ['Else Condition', 'End Else Condition'].includes(step?.name) ? (
          step.name === 'Else Condition' ? (
            <div>
              {ifElseJsx(
                step,
                index,
                step.name,
                'start_else',
                marginLeftSize ? Number(marginLeftSize) + Number(1.3) : ''
              )}
            </div>
          ) : step.name === 'End Else Condition' ? (
            <div
              className={elementHover ? 'accordion section-hover' : 'accordion section'}
              onMouseOver={(e) => {
                onMouseOverIfHeader(e);
              }}
              onMouseLeave={(e) => {
                onMouseLeaveIfHeader(e);
              }}
              id={`end_else${index}`}
              style={{ marginLeft: marginLeftSize !== undefined ? Number(marginLeftSize) - Number(1.3) + 'rem' : '' }}
            >
              End Else
            </div>
          ) : (
            ''
          )
        ) : (
          ''
        )}
      </div>
    );
  }

  function ifElseJsx(step, index, conditionHeader, idPrefix, marginLeftSize) {
    return (
      <div className="grid grid-cols-5 step-header" key={`step${index}`}>
        <div className={`col-span-5 step-result flex items-center cursor-pointer`}>
          <div
            className={elementHover ? 'accordion section-hover' : 'accordion section'}
            id={`${idPrefix}start_If${index}`}
            onMouseOver={(e) => {
              onMouseOverIfHeader(e);
            }}
            onMouseLeave={(e) => {
              onMouseLeaveIfHeader(e);
            }}
            style={{ marginLeft: marginLeftSize !== undefined ? marginLeftSize + 'rem' : '' }}
          >
            {conditionHeader.replace('Condition', '')}
            {conditionStatus(step.stepInputs, idPrefix)}
            {executionStatistics(step.stepResultStats)}
          </div>
        </div>
      </div>
    );
  }

  function onMouseOverIfHeader() {
    setElementHover(true);
  }
  function onMouseLeaveIfHeader() {
    setElementHover(false);
  }

  function conditionStatus(scriptInputs, cond_type) {
    let condition = '';
    let status;
    let conditionValue = scriptInputs[0].value;
    status = scriptInputs[1].value;
    if (cond_type !== 'start_else' && cond_type !== 'end_else') {
      condition = conditionValue.replace('if', '');
    }
    return condition + ' - condition is ' + status;
  }

  function executionStatistics(statistics) {
    return (
      <section className="inline-block text-xs font-semibold">
        {statistics?.totalFailed > 0 && (
          <span className="result-failed">
            {statistics?.totalFailed} / {statistics?.total} Failed
          </span>
        )}
        {statistics?.totalWarning > 0 && (
          <span className="result-warning ml-2">
            {statistics?.totalWarning} / {statistics?.total} Warning
          </span>
        )}
        {statistics?.totalSkipped > 0 && (
          <span className="result-skipped ml-2">
            {statistics?.totalSkipped} / {statistics?.total} Skipped
          </span>
        )}
        {statistics?.totalPassed > 0 && (
          <span className="result-passed ml-2">
            {statistics?.totalPassed} / {statistics?.total} Passed
          </span>
        )}
      </section>
    );
  }

  function accordionFilterClass(statistic) {
    if (
      filteredStatus === 'ALL' ||
      (filteredStatus === 'FAIL' && statistic?.totalFailed > 0) ||
      (filteredStatus === 'PASS' && statistic?.totalPassed > 0) ||
      (filteredStatus === 'SKIP' && statistic?.totalSkipped > 0) ||
      (filteredStatus === 'WARNING' && statistic?.totalWarning > 0)
    ) {
      return '';
    }
    return 'accordion-inactive';
  }
  function onIterationHeaderFocus(event) {
    let _id = event.target.id;
    let _searchID = '';
    let msgID = '';
    if (_id) {
      let sourceHtmlElement = document.getElementById(_id);
      if (sourceHtmlElement) {
        let splittedIdArr = _id.split('_');
        if (splittedIdArr[0].toLowerCase() === 'start') {
          _searchID = 'end_'.concat(splittedIdArr[1]);
        } else {
          _searchID = 'start_'.concat(splittedIdArr[1]);
          msgID = 'msg_'.concat(splittedIdArr[1]);
        }
        if (_searchID || msgID) {
          let targetHtmlElement = document.getElementById(_searchID);
          let targetMSGHtmlElement = document.getElementById(msgID);
          if (targetHtmlElement) {
            let style = targetHtmlElement.style;
          }
          if (targetMSGHtmlElement) {
            let _style = targetMSGHtmlElement.style;
            _style.display = 'block';
          }
        }
      }
    }
  }

  const popupOnContinue = () => {
    if (!resolveStep) {
      setEmptyOption(true);
    }
    handleRemoveAndResolveTag();
  };
  const popupOnCancel = () => {
    setopenPopup(false);
    updateDeleteAndResolve();
    setEmptyOption(false);
  };
  return (
    <div>
      <div className="grid grid-cols-4 p-2 sm:p-2 md:p-3 lg:p-3 xl:p-4 items-center">
        <div className="col-span-3 fontPoppinsSemiboldLg truncate">{props?.content?.title}</div>
        <div className="cursor-pointer">
          {moduleId && !global.permission.isNoAccess('testDevelopment') && (
            <span
              className="float-right hyper_link_color-common fontPoppinsRegularMd"
              onClick={() => navigateBackToSteps()}
            >
              {resultAccordionMsg.GO_TO_SCRIPT}
            </span>
          )}
        </div>
      </div>
      <div className="grid grid-cols-5 result-header uppercase p-2 sm:p-2 md:p-3 lg:p-3 xl:p-4">
        <div className="col-span-3">Result</div>
        <div className="flex">
          Status
          {displayFilterOption || filterIcon ? (
            <Tooltip title="Filter" placement="top">
              <div>
                <TiFilter
                  className="ml-3 mt-1 text-lg cursor-pointer filter-icon"
                  onClick={() => setDisplayFilterOption(!displayFilterOption)}
                  onMouseLeave={() => setFilterIcon(false)}
                />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title="Filter" placement="top">
              <div>
                <GrFormFilter
                  className="ml-3 mt-1 text-lg cursor-pointer filter-icon"
                  onClick={() => setDisplayFilterOption(!displayFilterOption)}
                  onMouseOver={() => setFilterIcon(true)}
                  onMouseLeave={() => setFilterIcon(false)}
                />
              </div>
            </Tooltip>
          )}
          {displayFilterOption && statusFilter()}
        </div>
        <div>Duration</div>
      </div>
      {props.content && scriptResult()}

      {tagModal.openModal && (
        <TagStepModal
          openPopup={openPopup}
          setopenPopup={setopenPopup}
          setOpenTagModal={setOpenTagModal}
          stepsData={tagModal.data}
          index={tagModal.index}
          edit={tagModal.edit}
          tagDTO={tagModal.tagDTO}
          updateStep={updateScriptWithTag}
          clientId={clientId}
          taggedData={tagModal.taggedData}
          DiscardAIStepDB={props.DiscardAIStepDB}
          handleStepTags={handleStepTags}
          discardAutotaggingStep={discardAutotaggingStep}
        />
      )}
      {openDeleteModal && (
        <Popup
          isOpen={openPopup}
          type="warning"
          headerTitle="Warning!"
          headerText={toPascalCase(resolveAndDeleteText)}
          continueButtonLabel={toPascalCase(resolveAndDeleteText)}
          cancelButtonLabel="Cancel"
          onContinueClick={popupOnContinue}
          onCancelClick={popupOnCancel}
          continueButtonDisable={disableContinueButton}
        >
          <>
            <div className="mt-6">
              <span>{resolveAndDeleteText === RESOLVE ? RESOLVE_QUESTION : DELETE_QUESTION}</span>
            </div>
            {resolveAndDeleteSingleStep && resolveAndDeleteText === RESOLVE && (
              <div>
                <div className="flex flex-1 items-center mt-5">
                  {RADIOBUTTON_OPTIONS.map((options) => {
                    return (
                      <div className="flex flex-1 items-center fontPoppinsRegularSm">
                        <RadioButton
                          label={options.label}
                          variant={options.variant}
                          name={options.name}
                          id={options.id}
                          value={options.value}
                          onChange={handleDeleteandResolveChange}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="mt-5">
                  <span>{PROCEED_QUESTION}</span>
                </div>
              </div>
            )}
          </>
        </Popup>
      )}
    </div>
  );
};

export default ResultAccordion;
