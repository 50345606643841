import React, { useEffect, useState, useContext } from 'react';
import { getFilesReq, downloadFileFromCloud } from '@api/api_services';
import { CommonLoader } from '@src/pages/common/common-loader';
import { TEST_DATA } from '@src/common/ui-constants';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { Button, Icon } from 'fireflink-ui';

function FileDetailsPage(props) {
  const [fileData, setFileData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const search = window.location.search;
  const { resetProgress, startOrResumeProgress, stopProgress } = useContext(ProgressContainerContext);

  const id = new URLSearchParams(search).get('id');
  const parentId = new URLSearchParams(search).get('parentId');
  useEffect(() => {
    getElementDetails(parentId, id);
  }, [id]);

  const { name = '' } = fileData || {};

  const getElementDetails = (parentId, id) => {
    setIsLoading(true);
    resetProgress();
    startOrResumeProgress({
      stopAt: 90,
    });
    getFilesReq(id)
      .then((result) => {
        stopProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 10,
        });
        if (result.data.responseObject) {
          setFileData(result.data.responseObject);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        resetProgress();
        console.error('GET_FILE_REQ : ', err);
      });
  };

  const downloadFile = () => {
    downloadFileFromCloud(fileData.id).then((results) => {
      saveFileFromBlob(results.data, name);
    });
  };

  function saveFileFromBlob(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }
  if (isLoading) {
    return <CommonLoader />;
  }

  return (
    <div className="page-table-body">
      <div className="content-wrapper">
        <div className="content_area_style" id="journal-scroll">
          {fileData ? (
            <>
              <div className="flex justify-between">
                <div className="flex flex-nowrap font-semibold gap-1">
                  <Icon name="test_data_icon" height={24} width={24} />
                  <span>{name}</span>
                </div>
                <div>
                  <Button label="Edit" variant="primary" className="w-28" onClick={downloadFile}>
                    Download
                  </Button>
                </div>
              </div>
              <div className="downloadFileContent">
                <div className="flex justify-center mt-6">
                  <Icon name="test_data_icon" height="100" width="100" />
                </div>
                <div className="m-4">{TEST_DATA.UNSUPPORTED_FILE_TO_VIEW}, Please download the file to View it.</div>
                <div>
                  <Button label="Edit" variant="primary" className="w-28" onClick={downloadFile}>
                    Download
                  </Button>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default FileDetailsPage;
