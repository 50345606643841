import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Tooltip } from '@material-ui/core';
import { AddOutlined, DragIndicatorOutlined } from '@material-ui/icons';
import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import DeleteModal from '@src/pages/test-development/shared/delete-modal';
import {
  cascadeConditionToScriptReq,
  deleteModulePrePostConditionReq,
  getNLPReq,
  getPaginatedModulePrePostConditionsReq,
  updateModulePrePostConditionReq,
  getNewNlpReq,
} from '@api/api_services';
import CreateEditModuleConditions from './create-edit-module-conditions';
import TableWithDragAndDrop from '@src/pages/test-development/shared/table/conditions-table';
import { CommonLoader } from '@src/pages/common/common-loader';
import Conditions from '@pages/test-development/script/modules/settings/pre-post-condition.js/prepostcondition.module.scss';
import cx from 'classnames';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import { isEmptyValue } from '@src/util/common_utils';
import { UI_VALIDATIONS } from '@src/util/validations';
import { NLP_SUGGESTION_CONDITIONS } from '@src/common/ui-constants';

const StepgroupSuggestion = (props) => {
  let [stepGroupDropDownData, setStepGroupDropDownData] = useState([]);
  let [filterData, setFilterData] = useState(stepGroupDropDownData);
  let [stepGroupName, setStepGroupName] = useState(props.editData ? props.editData : '');
  let [nlpSelected, setNlpSelected] = useState(false);
  let [selectedNLPData, setSelectedNLPData] = useState({});
  const project = JSON.parse(localStorage.getItem('selected-project'));
  const [pageState, setPageState] = useState({
    pagePositionY: 0,
    pageValue: 0,
  });
  const handleChange = (e) => {
    setStepGroupName(e.target.value);
    // search(e.target.value); //!old code
  };
  const handleClick = (data) => {
    setStepGroupName(data.name);
    props.openStepgroupInputDiv(data);
    //!old code
    // props.showHideNlpInputDiv(0);
    // props.dataChild(data);
    // document.getElementById("nlpSuggestion-" + props.stepsCount).style.display = "none";
  };
  // let search = (data) => {
  //   let x = [];
  //   x = stepGroupDropDownData.filter((element) => element.nlpName.toLowerCase().includes(data.toLowerCase()));
  //   setFilterData(x);
  // };
  const getStepGroups = async () => {
    try {
      let x = [];
      const projectDetails = JSON.parse(localStorage.getItem('selected-project'));
      let platform = ['Salesforce', 'MS Dynamics']?.includes(projectDetails.type)
        ? 'Web & Mobile'
        : projectDetails.type;
      platform = platform === 'Web Service' ? 'Webservice' : platform;
      const payload = {
        module: 'nlpSearch',
        collectionName: 'nlps',
        searchText: stepGroupName,
      };
      const headers = {
        projectType: project.type,
        projectId: project.id,
        platform: platform,
      };
      // const response = await getNLPReq(platform, platform); //!old code
      const response = await getNewNlpReq(payload, headers, NLP_SUGGESTION_CONDITIONS.CONDITIONS);
      if (response.data && response.data.responseObject) {
        setFilterData(response.data.responseObject);
      }
    } catch (err) {
      console.error('error while getting NLP req:', err);
    }
  };

  //!old code
  // useEffect(() => {
  //   getStepGroups();
  // }, []);

  useEffect(() => {
    const data = setTimeout(() => {
      getStepGroups();
    }, 500);
    return () => clearTimeout(data);
  }, [stepGroupName]);

  const onHoverSelectedNLP = (ele) => {
    setSelectedNLPData(ele);
  };
  useEffect(() => {
    if (pageState.pagePositionY > UI_VALIDATIONS.MAX_CLIENT_Y_COUNT) {
      setPageState((prevState) => ({
        ...prevState,
        pageValue: UI_VALIDATIONS.MAX_BOTTOM_PERCENTAGE,
      }));
    } else {
      setPageState((prevState) => ({
        ...prevState,
        pageValue: -UI_VALIDATIONS.MAX_CLIENT_Y_COUNTS,
      }));
    }
  }, [pageState.pagePositionY]);

  return (
    <div className={`relative  ml-5 text-sm text-blue-700 `}>
      <div className="flex">
        <input
          autoFocus={true}
          type="search"
          id="textareaTd"
          className={`w-11/12 search-nlp text-sm text-blue-700 ${props.drpPos ? '-ml-7' : 'ml-7'}`}
          value={stepGroupName}
          onChange={handleChange}
          autoComplete="off"
          onBlur={() => {
            if (props.closeBox && !nlpSelected) {
              props.closeBox(false);
              props.closeModal(true);
            }
          }}
          onMouseEnter={(e) => {
            let mouse = e.clientY;
            setPageState((prevState) => ({
              ...prevState,
              pagePositionY: mouse,
            }));
          }}
          placeholder="Search for stepgroup"
        />
      </div>
      <div
        className={`flex nlpdataloading absolute w-full `}
        style={{
          bottom: pageState.pagePositionY > UI_VALIDATIONS.MAX_CLIENT_Y_COUNT && `${pageState.pageValue}%`,
        }}
      >
        <div
          style={props.drpPos ? { zIndex: '1', width: '93%' } : null}
          className={`${
            props.drpPos ? '' : 'w-11/12'
          } border mt-px p-px max-h-48 w-full overflow-y-auto overflow-hidden shadow rounded text-sm bg-white ${
            props.drpPos ? '-ml-8' : 'ml-7'
          }`}
          id="journal-scroll"
          onMouseEnter={() => {
            setNlpSelected(true);
          }}
          onMouseLeave={() => {
            onHoverSelectedNLP(null);
          }}
        >
          {filterData.map((ele, index) => {
            return (
              <div
                onMouseOver={() => onHoverSelectedNLP(ele)}
                onClick={() => {
                  handleClick(ele);
                }}
                id={'sg' + index.toString()}
                key={index}
                className="fontPoppinsRegularMd cursor-pointer flex item-start ml-3 mb-1 text-gray-500 items-center"
              >
                <span className="ml-5 mr-5 mt-3 p-3 sg fontPoppinsRegularMd nlp-type-step-group">SG</span>
                <p className="mt-3 break-all fontPoppinsRegularMd">
                  {ele?.nlpName?.substring(0, UI_VALIDATIONS.MAX_NLP_NAME_CHAR_COUNT)}
                  {ele?.nlpName?.length > UI_VALIDATIONS.MAX_NLP_NAME_CHAR_COUNT ? '...' : ''}{' '}
                </p>
              </div>
            );
          })}
          <div
            className="flex w-full"
            onMouseEnter={() => {
              setNlpSelected(true);
            }}
            onMouseLeave={() => {
              onHoverSelectedNLP(null);
            }}
          >
            {!isEmptyValue(filterData) && selectedNLPData?._id && (
              <div
                className={`absolute left-full w-full ${
                  props.drpPos ? '-ml-14' : 'ml-2'
                } mr-10 h-56 border p-2 max-h-48 overflow-y-auto  shadow rounded bg-white z-20 ${
                  pageState.pagePositionY > UI_VALIDATIONS.MAX_CLIENT_Y_COUNT ? 'bottom-0' : 'top-0 bottom-8'
                }`}
                onMouseEnter={() => setNlpSelected(true)}
                onMouseLeave={() => setNlpSelected(false)}
              >
                <div className="nlp-details-header mt-4">Name:</div>
                <div className="nlp-details nlpdiv-check">
                  {selectedNLPData?.displayName ? selectedNLPData?.displayName : '--'}
                </div>
                <div className="nlp-details-header mt-4">Type:</div>
                <div className="nlp-details nlpdiv-check">
                  {selectedNLPData?.displayName ? selectedNLPData?.platform : '--'}
                </div>
                <div className="nlp-details-header mt-4">Description:</div>
                <div className="nlp-details nlpdiv-check">
                  {selectedNLPData?.description ? selectedNLPData?.description : '--'}
                </div>
                {selectedNLPData?.nlpType === 'STEP_GROUP' && (
                  <>
                    <div className="nlp-details-header mt-4">Path:</div>
                    <div className="nlp-details nlpdiv-check">
                      {selectedNLPData?.path ? selectedNLPData?.path?.slice(6) : '--'}
                    </div>
                  </>
                )}
                <div className="nlp-details-header mt-4">Inputs:</div>
                {!isEmptyValue(selectedNLPData?.stepInputs) ? (
                  selectedNLPData?.stepInputs.map((stepInput, index) => {
                    const stepInputType = stepInput?.type ? stepInput?.type?.split('.').pop() : '--';
                    return (
                      <div key={index}>
                        <div className="nlp-details-header">Input{index + 1}:</div>
                        <div className="nlp-details">type: {stepInputType}</div>
                        <div className="nlp-details mb-4">name: {stepInput?.name}</div>
                      </div>
                    );
                  })
                ) : (
                  <div className="nlp-details mb-4">--</div>
                )}
                <div className="nlp-details-header">Output:</div>
                <div className="nlp-details mb-4">
                  {selectedNLPData?.returnType ? selectedNLPData?.returnType : '--'}
                </div>
                <div className="w-1/6"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const PreCondition = (props) => {
  const search = window.location.search;
  const moduleId = new URLSearchParams(search).get('moduleId');
  let [openCreatePreCondition, setOpenCreatePreCondition] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  let [reload, setReload] = useState(true);
  let [isLoading, setIsLoading] = useState(false);

  let [preconditionData, setPreconditionData] = useState([]);
  const preconditionDataRef = useRef({});
  preconditionDataRef.current = preconditionData;

  let [noPreConditions, setNoPreConditions] = useState(preconditionData.length > 0 ? false : true);
  let [openStepGroupInputParameterDiv, setOpenStepGroupInputParameterDiv] = useState(false);
  let [stepGroupInputParameterDivData, setStepGroupInputParameterDivData] = useState('');
  let [editPreCondition, setEditPreCondition] = useState(false);
  let [openDeleteModal, setOpenDeleteModal] = useState(false);
  let [stepIndex, setStepIndex] = useState(1);
  let [editData, setEditData] = useState(null);
  const [showAddStepButton, setshowAddStepButton] = useState(true);
  const [addStepAtIndex, setAddStepAtIndex] = useState();
  const [midExecutionOrder, setMidExecutionOrder] = useState();
  const defaultStepGroup = new URLSearchParams(search).get('defaultStepGroup');
  const [editDeleteConditionIndex, setEditDeleteConditionIndex] = useState(-1);
  const [pageNo, setPageNo] = useState(0);
  const pageNoRef = useRef({});
  pageNoRef.current = pageNo;
  const _listThresholdSize = 12;
  const conditionType = 'PRE';
  const [lastPaginatedResponse, setLastPaginatedResponse] = useState();
  const lastPaginatedResponseRef = useRef({});
  lastPaginatedResponseRef.current = lastPaginatedResponse;

  const [enableCallPaginatedApi, setEnableCallPaginatedApi] = useState(true);
  const enableCallPaginatedApiRef = useRef({});
  enableCallPaginatedApiRef.current = enableCallPaginatedApi;

  const { resetProgress, startOrResumeProgress } = useContext(ProgressContainerContext);

  const { isEditable, hasFullAccess } = React.useMemo(
    () => ({
      isEditable: window.permission?.isEditAccess('testDevelopment'),
      hasFullAccess: window.permission?.isFullAccess('testDevelopment'),
    }),
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'dragIcon',
        width: '1%',
      },
      {
        Header: 'StepGroup',
        accessor: 'name',
        width: '40%',
      },
      {
        Header: 'If Failed',
        accessor: 'ifFailed',
        width: '29%',
      },
      {
        Header: 'Cascade',
        accessor: 'cascade',
        width: '10%',
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '10%',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        width: '10%',
      },
    ],
    []
  );

  const OpenStepGroupDiv = (data) => {
    props.DisableCreateButton(true);
    setOpenStepGroupInputParameterDiv(true);
    setStepGroupInputParameterDivData(data);
  };

  const closeBox = (val) => {
    setOpenCreatePreCondition(val);
    myCssMethod(-1);
    setEditData(null);
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(preconditionDataRef.current);
    let execOrder;
    if (items[result.destination.index + 1] === undefined) {
      execOrder = items[result.destination.index].executionOrder + 1;
    } else if (items[result.destination.index - 1] === undefined) {
      execOrder = items[result.destination.index].executionOrder / 2;
    } else {
      if (result.destination.index > result.source.index) {
        execOrder =
          (items[result.destination.index].executionOrder + items[result.destination.index + 1].executionOrder) / 2;
      } else {
        execOrder =
          (items[result.destination.index].executionOrder + items[result.destination.index - 1].executionOrder) / 2;
      }
    }
    const reorderedItem = items[result.source.index];
    const requestBody = {
      ...reorderedItem,
      executionOrder: execOrder,
    };
    if (result.source.index !== result.destination.index) {
      const [x] = items.splice(result.source.index, 1);
      x.executionOrder = execOrder;
      items.splice(result.destination.index, 0, x);
      setPreconditionData(items);
      updateModulePrePostConditionReq(moduleId, requestBody.id, requestBody).then((response) => {
        if (!(response.data && response.data.responseObject)) {
          props.MyAlert.info(`Last reordering failed`);
        }
      });
    }
  }
  const deletePreCondition = async (data) => {
    try {
      if (data.cascaded === 'cascaded') {
        const removeCascadeData = {
          ...data,
          cascaded: 'not_cascaded',
          cascadedFrom: '',
        };
        await cascadeConditionToScriptReq(moduleId, removeCascadeData);
        if (isEmptyValue(preconditionData)) {
          props?.setPreExeOrder(1);
        } else {
          props?.setPreExeOrder(preconditionData?.length);
        }
      }
      const response = await deleteModulePrePostConditionReq(moduleId, data.id);
      if (response.data.responseCode === 200 && response.data.status === 'SUCCESS') {
        updatePreconditionTableData('delete', {}, editDeleteConditionIndex);
        let title = `${data.stepGroupName?.substring(0, 50)}${data?.stepGroupName?.length > 50 ? '...' : ''}${' '}`;
        props.MyAlert.success(`${title} deleted successfully`);
        if (isEmptyValue(preconditionData)) {
          props?.setPreExeOrder(1);
        } else {
          props?.setPostExeOrder(preconditionData.length);
        }
        setNoPreConditions(true);
      } else {
        props.MyAlert.warning(`${response.data.message}`);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleEditStep = (index, data) => {
    setStepIndex(index + 1);
    setEditPreCondition(true);
    setEditData(data);
    props.DisableCreateButton(true);
  };
  const myCssMethod = (index) => {
    const myClass = document.getElementsByClassName('myRow');
    for (let i = 0; i < myClass.length; i++) {
      if (index >= 0) {
        myClass[i].classList.remove('configHover');
      } else {
        myClass[i].classList.add('configHover');
      }
      if (i === index) {
        myClass[i].classList.add('shadow-md', 'bg-white');
        myClass[i].style.boxShadow = '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)';
      } else {
        myClass[i].classList.remove('shadow-md', 'bg-white');
        myClass[i].style.boxShadow = 'none';
      }
    }
  };

  const reloadPreConditions = (val) => {
    setPageNo(0);
    setReload(val);
  };

  const closeModal = (value) => {
    myCssMethod(-1);
    if (openDeleteModal) {
      setOpenDeleteModal(value);
      setStepGroupInputParameterDivData('');
    }
    if (openStepGroupInputParameterDiv) {
      setOpenStepGroupInputParameterDiv(false);
      setStepGroupInputParameterDivData('');
      setOpenCreatePreCondition(false);
    }
    if (editPreCondition) {
      setEditPreCondition(false);
    }
    if (preconditionDataRef.current && preconditionDataRef.current.length === 0) {
      setNoPreConditions(true);
    }
    setStepIndex(preconditionDataRef.current.length + 1);
    setEditData(null);
    myCssMethod(-1);
    props.DisableCreateButton(false);
  };

  const handleNameClick = (row, index) => {
    if (isEditable) {
      OpenStepGroupDiv(row);
      setStepIndex(index + 1);
      setEditPreCondition(true);
      myCssMethod(index);
    }
  };

  const getNameRenderer = (row, index) => {
    return stepIndex - 1 === index && editPreCondition && editData !== null ? (
      openStepGroupInputParameterDiv ? (
        <span className=" pt-2 w-full text-xs text-blue-700">
          {index + 1}. {stepGroupInputParameterDivData.nlpName}
        </span>
      ) : (
        <div className="inline-block w-full">
          {myCssMethod(index)}
          <StepgroupSuggestion
            editData={row.stepGroupName}
            drpPos={true}
            // handleBlur={handleBlur}
            openStepgroupInputDiv={OpenStepGroupDiv}
            closeBox={closeBox}
            closeModal={closeModal}
          />
        </div>
      )
    ) : (
      <div className="flex items-center">
        <Tooltip title={row.stepGroupName} placement="top">
          <span
            className={cx(
              'text-xs text-blue-700 mr-1 cursor-pointer fontPoppinsRegularMd',
              Conditions['length-script']
            )}
            onClick={() => handleNameClick(row, index)}
          >
            {index + 1}. {`${row.stepGroupName?.substring(0, 30)}${row.stepGroupName?.length > 30 ? '...' : ''}`}
          </span>
        </Tooltip>

        {isEditable && showAddStepButton && (
          <button
            disabled={openStepGroupInputParameterDiv}
            className={openStepGroupInputParameterDiv ? 'HideAddStepBelow' : ' ShowAddStepBelow actionIcons'}
            onClick={(ev) => {
              if (defaultStepGroup) {
                if (defaultStepGroup === 'false') {
                  addStepBelow(ev, row, index);
                }
              } else {
                addStepBelow(ev, row, index);
              }
            }}
          >
            {!openCreatePreCondition && (
              <Tooltip title="Add" placement="top">
                <AddOutlined fontSize="small" className="cursor-pointer text-xs ml-1 text-blue-700 actionIcons" />
              </Tooltip>
            )}
          </button>
        )}
      </div>
    );
  };

  const getActionIcons = (row, index) => {
    return (
      <span className="actionIcons">
        {isEditable && (
          <Tooltip title="Edit">
            <EditOutlinedIcon
              className="float-left cursor-pointer text-base mr-3 mt-1.5 text-blue-700"
              onClick={() => {
                handleEditStep(index, row);
                setEditDeleteConditionIndex(index);
              }}
            />
          </Tooltip>
        )}

        {hasFullAccess && (
          <Tooltip title="Delete">
            <DeleteOutlinedIcon
              className="float-left cursor-pointer mr-3 mt-1.5 text-blue-700 text-base"
              onClick={() => {
                setOpenDeleteModal(true);
                setEditDeleteConditionIndex(index);
                setStepGroupInputParameterDivData(row);
              }}
            />
          </Tooltip>
        )}
      </span>
    );
  };
  const addInBetweenConditionRenderer = () => {
    return (
      <div className="mt-2 ">
        {openStepGroupInputParameterDiv ? (
          <p
            style={{
              boxShadow: 'none',
            }}
            className="pt-2 text-left w-full text-xs text-blue-700 shadow-md bg-white h-8 pl-8 -ml-5"
            title={stepGroupInputParameterDivData?.name}
          >
            {stepGroupInputParameterDivData.nlpName}
          </p>
        ) : (
          <StepgroupSuggestion drpPos={true} openStepgroupInputDiv={OpenStepGroupDiv} closeModal={closeModal} />
        )}
      </div>
    );
  };

  const tableData = React.useMemo(() => {
    if (preconditionDataRef.current && preconditionDataRef.current.length) {
      let tabData = [];
      preconditionDataRef.current.forEach((row, index) => {
        if (row.type !== 'stepGroup') {
          tabData.push({
            ...(isEditable &&
              preconditionData.length > 1 && {
                dragIcon: (
                  <span className="actionIcons">
                    <DragIndicatorOutlined fontSize="small" className="text-blue-600" />{' '}
                  </span>
                ),
              }),
            name: getNameRenderer(row, index),
            ifFailed: getIfFailed(row.ifCheckPointIsFailed),
            cascade: (
              <span className="text-gray-700 fontPoppinsRegularMd">
                {' '}
                {row.cascaded === 'cascaded' ? 'Cascaded' : 'Not Cascaded'}
              </span>
            ),
            status: (
              <span style={{ textTransform: 'capitalize' }} className="text-gray-700 fontPoppinsRegularMd">
                {row.status}
              </span>
            ),
            actions: getActionIcons(row, index),
            rowValue: { value: row, index: index },
          });
        } else {
          tabData.push({
            name: addInBetweenConditionRenderer(),
            rowValue: { value: row, index: index },
          });
        }
      });
      return tabData;
    } else {
      return [];
    }
  }, [preconditionDataRef.current, addInBetweenConditionRenderer, getNameRenderer, getActionIcons]);

  const prevScrollRef = useRef(0);
  const isLoadingRef = useRef(false);
  const onScrollHandler = useCallback((event) => {
    const currentScrollTop = event.target.scrollTop;
    if (prevScrollRef.current < currentScrollTop) {
      if (event.target.scrollTop + event.target.clientHeight > event.target.scrollHeight - 100) {
        if (!isLoadingRef.current) callPaginatedApi();
      }
    }
    prevScrollRef.current = currentScrollTop;
  }, []);

  const callPaginatedApi = useCallback(() => {
    if (
      enableCallPaginatedApiRef.current &&
      lastPaginatedResponseRef.current &&
      lastPaginatedResponseRef.current.nextPage &&
      !lastPaginatedResponseRef.current.lastPage
    ) {
      setEnableCallPaginatedApi(false);
      isLoadingRef.current = true;
      getPaginatedPreConditions(pageNoRef.current, _listThresholdSize)
        .then((res) => {
          const b = pageNoRef.current + 1;
          setPageNo(b);
          if (res) {
            const dataObj = {
              firstPage: res.data.firstPage,
              lastPage: res.data.lastPage,
              nextPage: res.data.nextPage,
            };
            setLastPaginatedResponse(dataObj);
          }
          setEnableCallPaginatedApi(true);
          isLoadingRef.current = false;
        })
        .catch((error) => {
          console.error('error while calling paginated api:', error);
          setEnableCallPaginatedApi(true);
          isLoadingRef.current = false;
        });
    }
  }, [enableCallPaginatedApiRef, lastPaginatedResponseRef]);

  const getPaginatedPreConditions = async (page, size) => {
    try {
      setIsLoading(true);
      const res = await getPaginatedModulePrePostConditionsReq(moduleId, conditionType, page, size);
      if (res.data && res.data.responseObject && res.data.responseObject.length) {
        let x = [];
        x = res.data.responseObject.filter((val) => val.type === 'PRE');
        props?.setPreExeOrder(x.length ? x[x.length - 1].executionOrder + 1 : 1);
        props.setCountPill({ type: 'PreCondition', value: preconditionDataRef.current.length });
        setStepIndex(x.length + 1);
        setIsLoading(false);
        const _data = preconditionDataRef.current;
        if (x.length) {
          _data.push(...x);
          setPreconditionData(_data);
        }

        return res;
      } else {
        setPreconditionData([]);
        props?.setPreExeOrder(1);
        setStepIndex(1);
        setNoPreConditions(true);
        setIsLoading(false);
        return {};
      }
    } catch (err) {
      console.error('error while calling paginated api:', err);
    } finally {
      setAddStepAtIndex();
      setMidExecutionOrder();
      setshowAddStepButton(true);
    }
  };
  // const preConditionHandleUpdate = () => {
  //   getPaginatedPreConditions(pageNoRef.current, _listThresholdSize);
  // };
  const getPreConditions = async (page, size) => {
    try {
      setIsLoading(true);
      const res = await getPaginatedModulePrePostConditionsReq(moduleId, conditionType, page, size);
      if (res.data && res.data.responseObject && res.data.responseObject.length) {
        let x = [];
        const a = pageNoRef.current + 1;
        setPageNo(a);
        const dataObj = {
          firstPage: res.data.firstPage,
          lastPage: res.data.lastPage,
          nextPage: res.data.nextPage,
        };
        x = res.data.responseObject.filter((val) => val.type === 'PRE');
        props?.setPreExeOrder(x.length ? x[x.length - 1].executionOrder + 1 : 1);
        props.setCountPill({ type: 'PreCondition', value: x.length });
        setStepIndex(x.length + 1);
        setPreconditionData(x);
        setLastPaginatedResponse(dataObj);
      } else {
        setPreconditionData([]);
        props?.setPreExeOrder(1);
        setStepIndex(1);
        setNoPreConditions(true);
      }
      setIsLoading(false);
    } catch (err) {
      console.error('error while calling paginated api:', err);
    } finally {
      setAddStepAtIndex();
      setMidExecutionOrder();
      setshowAddStepButton(true);
    }
  };
  const updatePreconditionTableData = (operation, _data, index) => {
    const _currentData = preconditionDataRef.current;
    if (operation === 'create') {
      if (addStepAtIndex) {
        _currentData[addStepAtIndex] = _data;
        setAddStepAtIndex(null);
      } else {
        _currentData.push(_data);
      }
      setshowAddStepButton(true);
    } else if (operation === 'edit') {
      _currentData[index] = _data;
    } else if (operation === 'delete') {
      _currentData.splice(index, 1);
    }

    setPreconditionData(_currentData);
  };
  function ifFailedText(status) {
    switch (status) {
      case 'MARK_THIS_STEP_AS_FAILED_AND_CONTINUE_MODULE_EXECUTION':
        return 'Mark this step as Failed and continue module execution';
      case 'MARK_THIS_STEP_AS_WARNING_AND_CONTINUE_MODULE_EXECUTION':
        return 'Mark this step as Warning and continue module execution';
      case 'MARK_THIS_STEP_AS_FAILED_AND_STOP_CURRENT_MODULE_EXECUTION':
        return 'Mark this step as Failed and stop current module execution';
      case 'MARK_THIS_STEP_AS_FAILED_AND_STOP_COMPLETE_EXECUTION':
        return 'Mark this step as Failed and stop complete execution';
      default:
        return '--';
    }
  }

  function getIfFailed(status) {
    let ifFailedMessage = ifFailedText(status);
    return (
      <span
        className={cx('text-gray-700 fontPoppinsRegularMd', Conditions['if-failed-length-pre-post'])}
        title={`${ifFailedMessage}`}
      >
        {`${ifFailedMessage?.substring(0, 40)}${'...'}`}
      </span>
    );
  }

  let _DOMNode = useClickOutSide((event) => {
    if (addStepAtIndex && !openStepGroupInputParameterDiv) {
      removeEmptyStep(event, addStepAtIndex);
    }
  });

  const findExecutionOrder = (index, data) => {
    if (index === data.length - 1) {
      return data[index].executionOrder + 1;
    } else {
      return (data[index].executionOrder + data[index + 1].executionOrder) / 2;
    }
  };
  const addStepBelow = (event, row, index) => {
    let _currentData = [];
    Object.assign(_currentData, preconditionDataRef.current);
    if (_currentData && _currentData.length) {
      setMidExecutionOrder(findExecutionOrder(index, preconditionDataRef.current));
      _currentData.splice(index + 1, 0, { type: 'stepGroup' });
      setPreconditionData(_currentData);
      setAddStepAtIndex(index + 1);
      setshowAddStepButton(false);
    }
  };
  const removeEmptyStep = (ev, index) => {
    let _currentData = [];
    Object.assign(_currentData, preconditionDataRef.current);
    if (_currentData && _currentData.length) {
      _currentData.splice(index, 1);
      setPreconditionData(_currentData);
      setAddStepAtIndex();
      setMidExecutionOrder();
      setshowAddStepButton(true);
    }
  };
  const removeInputField = (val) => {
    setOpenCreatePreCondition(val);
    myCssMethod(-1);
    setEditData(null);
  };

  useEffect(() => {
    if (reload) {
      if (initialLoad) {
        resetProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 20,
        });
      }
      getPreConditions(pageNoRef.current, _listThresholdSize);
      setInitialLoad(false);
    }
    setReload(false);
  }, [reload]);

  useEffect(() => {
    if (props.createCalled) {
      setOpenCreatePreCondition(true);
      if (noPreConditions) {
        setNoPreConditions(false);
      }
    }
  }, [props.createCalled, noPreConditions]);

  useEffect(() => {
    if (preconditionData) {
      props?.setPreConditionData(preconditionData);
    }
  }, [preconditionData]);
  return (
    <div className="h-90 overflow-auto min-h-full" id="journal-scroll">
      {/* Pre Conditions table */}
      <TableWithDragAndDrop
        isDragDisabled={!isEditable || preconditionData?.length === 1}
        columns={columns}
        data={tableData}
        handleOnDragEnd={handleOnDragEnd}
        rowRef={_DOMNode}
        scrollHandler={onScrollHandler}
        from="Precondition"
      />

      {openCreatePreCondition ? (
        <div className="mt-2 ">
          {openStepGroupInputParameterDiv ? (
            <span
              style={{ boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)' }}
              className="inline-block pt-2 w-full text-xs text-blue-700 shadow-md bg-white h-8 pl-16 "
            >
              {stepGroupInputParameterDivData.nlpName}
            </span>
          ) : (
            <div className="w-2/5 inline-block">
              <StepgroupSuggestion
                openStepgroupInputDiv={OpenStepGroupDiv}
                closeBox={closeBox}
                closeModal={closeModal}
              />
            </div>
          )}
        </div>
      ) : null}
      {/* step group input parameter div */}
      {openStepGroupInputParameterDiv ? (
        <CreateEditModuleConditions
          orderOfInsertion={addStepAtIndex ? midExecutionOrder : props?.preExeOrder}
          edit={editPreCondition}
          editData={editData}
          reload={reloadPreConditions}
          closeModal={closeModal}
          MyAlert={props.MyAlert}
          data={stepGroupInputParameterDivData}
          type={'PRE'}
          editConditionIndex={editDeleteConditionIndex}
          updateTableData={updatePreconditionTableData}
          // preConditionHandleUpdate={preConditionHandleUpdate}
        />
      ) : null}
      {isLoading ? (
        <CommonLoader />
      ) : noPreConditions && preconditionDataRef.current && preconditionDataRef.current.length === 0 ? (
        <div className="fontPoppinsRegularMd text-sm no-steps">
          {isEditable && (
            <div className="">
              <p className="font-bold mb-2">Quick Start</p>
              <p className="mb-2">
                <span className="font-bold">Step 1 :</span> Click
                <button
                  onClick={() => {
                    setNoPreConditions(false);
                    setOpenCreatePreCondition(true);
                  }}
                  className="ml-3 mr-3 primary-btn"
                >
                  + Pre Condition
                </button>
                to start adding the Pre Conditions
              </p>
            </div>
          )}
        </div>
      ) : null}

      {/* modal for delete pre-condition */}
      {openDeleteModal ? (
        <DeleteModal
          closeModal={closeModal}
          handleDelete={deletePreCondition}
          reload={reloadPreConditions}
          dataObj={{ type: 'PreCondition', data: stepGroupInputParameterDivData }}
        />
      ) : null}
    </div>
  );
};
const useClickOutSide = (handler) => {
  const _genericDOMNode = useRef();
  useEffect(() => {
    const _handler = (event) => {
      if (_genericDOMNode.current && !_genericDOMNode.current.contains(event.target)) {
        handler();
      }
    };
    document.addEventListener('mousedown', _handler);
    return () => {
      document.removeEventListener('mousedown', _handler);
    };
  });
  return _genericDOMNode;
};
export default PreCondition;
