export default function ChangeExcelStyles(setWorksheetsData, options) {
  const { sheetName, styleType, value, selectedCell } = options;

  setWorksheetsData((prev) => {
    if (!prev[sheetName]) {
      return prev;
    }
    const sheetData = prev[sheetName];
    const selectedCells = new Set(selectedCell.map(({ row, column }) => `${row}-${column}`));

    const updatedSheetData = sheetData.map((row, rowIndex) =>
      row.map((cell, columnIndex) => {
        const isSelected = selectedCells.has(`${rowIndex}-${columnIndex}`);
        if (isSelected) {
          let newStyle = { ...cell.style };

          switch (styleType) {
            case 'border':
              newStyle = handleBorderChange(newStyle, value);
              break;
            case 'alignment':
              newStyle = handleAlignmentChange(newStyle, value);
              break;
            case 'bold':
              newStyle = handleBoldChange(newStyle);
              break;
            case 'italic':
              newStyle = handleItalicChange(newStyle);
              break;
            case 'color':
              newStyle = handleColorChange(newStyle, value);
              break;
            case 'backgroundColor':
              newStyle = handleBackgroundColorChange(newStyle, value);
              break;
            default:
              break;
          }

          return { ...cell, style: newStyle, type: true };
        }
        return cell;
      })
    );

    return { ...prev, [sheetName]: updatedSheetData };
  });
}

function handleBorderChange(style, value) {
  const newBorder = { ...style.border };

  switch (value) {
    case 'Border Top':
      newBorder.top = 'MEDIUM';
      break;
    case 'Border Bottom':
      newBorder.bottom = 'MEDIUM';
      break;
    case 'Border Left':
      newBorder.left = 'MEDIUM';
      break;
    case 'Border Right':
      newBorder.right = 'MEDIUM';
      break;
    case 'No Border':
      newBorder.top = newBorder.bottom = newBorder.left = newBorder.right = 'THIN';
      break;
    case 'All Border':
      newBorder.top = newBorder.bottom = newBorder.left = newBorder.right = 'MEDIUM';
      break;
    default:
      break;
  }

  return { ...style, border: newBorder };
}

function handleAlignmentChange(style, value) {
  const newAlignment = { ...style.alignment };

  switch (value) {
    case 'Text Left':
      newAlignment.horizontal = 'LEFT';
      break;
    case 'Text Center':
      newAlignment.horizontal = 'CENTER';
      break;
    case 'Text Right':
      newAlignment.horizontal = 'RIGHT';
      break;
    default:
      break;
  }

  return { ...style, alignment: newAlignment };
}

function handleBoldChange(style) {
  return { ...style, bold: !style.bold };
}

function handleItalicChange(style) {
  return { ...style, italic: !style.italic };
}

function handleColorChange(style, value) {
  return { ...style, color: value };
}

function handleBackgroundColorChange(style, value) {
  return { ...style, backgroundColor: value };
}
