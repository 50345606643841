import React, { Fragment, useState, useContext } from 'react';
import RadioButton from '../../../common/radio_button';
import { FiMaximize2 } from 'react-icons/fi';
import TableTree from '../../../common/table_tree/table_tree';
import {
  deleteTestDataSetReq,
  downloadFileFromCloud,
  getAllTestDataForSuiteTreeReq,
  getSingleTestDataReq,
  postTestDataSetReq,
  syncTestDataReq,
  updateSyncTestDataReq,
  updateTestDataSetReq,
} from '../../../../api/api_services';
import { useEffect } from 'react';
import { useTable, usePagination, useFilters, useGlobalFilter, useSortBy } from 'react-table';
import matchSorter from 'match-sorter';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Input from '@material-ui/core/Input';
import Tooltip from '@material-ui/core/Tooltip';
import UserDetailsPage from '../../common/user-details-page';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useAlert } from '@pagescommon/alert_service/useAlert';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import {
  TimeZoneDates,
  getCurrentProjectTimeZoneName,
  getDateFormateForCreateSuite,
  getTimeZoneFormateDate,
  validateFileExtension,
} from '@src/util/common_utils';
import { TIMEZONE_CONSTANTS } from '@src/common/ui-constants';
import { TEST_DATA_FILE_ALLOWED } from '@src/util/validations';

// let time = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();

let testDataSyncValue = [];
let replacedBackupArray = []; /* storing data which is removed from replaced array when click on sync*/
let replacedFileArray = [];
let replacedTempArray = []; /* storing data until performing any action like update or cancel*/

const TestData = (props) => {
  const [testDataMainObj, setTestDataMainObj] = React.useState({
    useTestDataFrom: props?.suiteObjMain?.useTestDataFrom
      ? props?.suiteObjMain?.useTestDataFrom
      : props?.suiteObjForPreview?.useTestDataFrom
      ? props?.suiteObjForPreview?.useTestDataFrom
      : '',
    sourceTestDataSet: props?.suiteObjForPreview?.sourceTestDataSet ? props?.suiteObjForPreview?.sourceTestDataSet : [],
    selectedTDSetName: props?.suiteObjMain?.selectedTDSetName
      ? props?.suiteObjMain?.selectedTDSetName
      : props?.suiteObjForPreview?.selectedTDSetName
      ? props?.suiteObjForPreview?.selectedTDSetName
      : '',
    clonedTestDataSets: props?.suiteObjMain?.clonedTestDataSets
      ? props?.suiteObjMain?.clonedTestDataSets
      : props?.suiteObjForPreview?.clonedTestDataSets
      ? props?.suiteObjForPreview?.clonedTestDataSets
      : [],
  });

  const { resetProgress, startOrResumeProgress } = useContext(ProgressContainerContext);
  const { AlertContatiner, MyAlert } = useAlert();
  const [pageSizeCount, setPageSizeCount] = React.useState(5);
  const [pageIndexVal, setPageIndexVal] = React.useState(0);

  const [TestDataSetArray, setTestDataSetArray] = React.useState([]);
  let selectTestDataType = ['Use Test Data from Test Development', 'Use Test Data from Test Data set'];
  const [testDataType, setTestDataType] = React.useState(
    testDataMainObj && testDataMainObj?.useTestDataFrom === 'SET'
      ? selectTestDataType[1]
      : testDataMainObj?.useTestDataFrom === 'TEST_DEVELOPMENT'
      ? selectTestDataType[0]
      : selectTestDataType[0]
  );
  const [showModal, setShowModal] = React.useState(false);
  const [operation, setOperation] = React.useState('Create');
  const [treeData, setTreeData] = useState({});
  const [rootData, setRootData] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  let renderTree;
  let renderTreeEdit;
  let renderTreeForCreation;
  let renderMaximizeTree;
  let renderTreeForDetails;
  const [reloadTree, setReloadTree] = useState(false);
  const [setObj, setObjectSet] = React.useState({});

  const [open, setOpen] = React.useState(false);
  const [selectedNodeSetArray, setSelectedNodeSetArray] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage] = React.useState(2);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const [createUpdateCalled, setCreateUpdateCalled] = React.useState(false);

  const [maximizePopUpOpened, setMaximizePopUpOpened] = useState(false);
  const [userObjMain, setUserObj] = React.useState({});
  const [selectedNode, setSelectedNode] = React.useState({});
  let [userId, setUserId] = React.useState();
  let [isExpandAll, setIsExpandAll] = useState(false);
  let [testDataSetName, setTestDataSetName] = useState();
  let timeZoneName = getCurrentProjectTimeZoneName();
  let mindate = TimeZoneDates(TIMEZONE_CONSTANTS.US_LOCALE_TIME_ZONE, timeZoneName);
  let todayDate = getDateFormateForCreateSuite(mindate.$d);

  let setObjForTd = {};
  if (!setObj) {
    if (props?.suiteObjMain) {
      setObjForTd = props?.suiteObjMain;
    }
  } else {
    if (props?.suiteObjMain) {
      setObjForTd = props?.suiteObjMain;
    }
  }
  useEffect(() => {
    if (props && props.suiteObjMain && props?.suiteObjMain?.clonedTestDataSets) {
      props?.suiteObjMain?.clonedTestDataSets.map((obj) => {
        obj.Actions = [];
        if (props?.rerunMode) {
          obj.Actions.push(
            <>
              <img
                src="\assets\execution_logos\edit.svg"
                alt="Edit icon"
                className="project-action"
                height="17px"
                width="17px"
              ></img>
              <img
                src="\assets\execution_logos\delete.svg"
                alt="Delete icon"
                className="flex flex-row icon-blue-style project-action "
                height="17px"
                width="17px"
                title="Delete"
              ></img>
            </>
          );
        } else {
          obj.Actions.push(
            <>
              <img
                src="\assets\execution_logos\edit.svg"
                alt="Edit icon"
                className="project-action mr-2 cursor-hand"
                height="17px"
                width="17px"
                onClick={() => {
                  openModalfun('updateSet');
                  createSetObj(obj);
                }}
              ></img>
              <img
                src="\assets\execution_logos\delete.svg"
                alt="Delete icon"
                className="flex flex-row icon-blue-style project-action cursor-hand"
                height="17px"
                width="17px"
                onClick={() => {
                  setOpen(true);
                  createSetObj(obj);
                }}
                title="Delete"
              ></img>
            </>
          );
        }

        setTestDataSetArray(props?.suiteObjMain?.clonedTestDataSets);
      });
    } else if (props && props.suiteObjForPreview && props.suiteObjForPreview.clonedTestDataSets) {
      if (props?.suiteObjForPreview?.clonedTestDataSets?.length) {
        props?.suiteObjForPreview?.clonedTestDataSets?.map((obj) => {
          obj.Actions = [];
          obj.Actions.push(
            <>
              <img
                src="\assets\execution_logos\edit.svg"
                alt="Edit icon"
                className="project-action mr-2 cursor-hand"
                height="17px"
                width="17px"
                onClick={() => {
                  openModalfun('updateSet');
                  createSetObj(obj);
                }}
              ></img>
              <img
                src="\assets\execution_logos\delete.svg"
                alt="Delete icon"
                className="flex flex-row icon-blue-style project-action cursor-hand"
                height="17px"
                width="17px"
                onClick={() => {
                  setOpen(true);
                  createSetObj(obj);
                }}
                title="Delete"
              ></img>
            </>
          );
        });
      }
      setTestDataSetArray(props.suiteObjForPreview.clonedTestDataSets);
    }
    replacedFileArray = [];
    replacedBackupArray = [];
    replacedTempArray = [];
    getFolderTreeFunction();
  }, [reloadTree]);

  // Define a default UI for filtering
  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search...`}
      />
    );
  }

  function fuzzyTextFilterFn(page, id, filterValue) {
    return matchSorter(page, filterValue, { keys: [(page) => page.values[id]] });
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  // Our table component
  function Table({ columns, data }) {
    const filterTypes = React.useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (page, id, filterValue) => {
          return page.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );

    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      // page,
      // canPreviousPage,
      // canNextPage,
      // nextPage,
      // previousPage,
      // state,
      // visibleColumns,
      // preGlobalFilteredRows,
      // setGlobalFilter,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination
    );
    return (
      <>
        <table class="relative" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr class="mt-4" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    class="sticky top-0 px-4 py-3 text-current table-header_font-style bg-gray-100"
                    {...column.getHeaderProps()}
                    width={column.width}
                  >
                    <span className="flex flex-row">
                      {column.render('Header')}
                      <div>
                        <span></span>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr className="project-row" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td class="px-4 text-center" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
      </>
    );
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'SELECT SET',
        accessor: 'checkValue',
        disableFilters: true,
        Cell: (e) => {
          return (
            <RadioGroup
              row
              aria-label="position"
              id={e.cell.row.original.name}
              name="position"
              defaultValue="top"
              style={{ marginLeft: '6px' }}
            >
              <FormControlLabel
                control={
                  <Radio
                    color="primary"
                    disabled={props?.rerunMode}
                    name="testData"
                    {...{ checked: testDataMainObj.selectedTDSetName == e.cell.row.original.name }}
                    label={e.cell.row.original.name}
                    className=" m-2 cursor-pointer"
                    value={e.cell.row.original.name}
                    onClick={() => getSelectedSet(e.cell.row.original)}
                  />
                }
                label={
                  <Typography
                    style={{ fontSize: '14px', color: '#727171', fontFamily: 'OpenSans-Regular' }}
                  ></Typography>
                }
              />
            </RadioGroup>
          );
        },
      },

      {
        Header: 'TEST DATA SET',
        accessor: 'name',
        disableFilters: true,
        Cell: (e) => {
          return (
            <div
              className="hyper_link_color-common float-left cursor-hand font-style-common "
              onClick={() => {
                openModalfun('Details');
                createSetObj(e.cell.row.original);
              }}
            >
              {e.cell.row.original.name}
            </div>
          );
        },
      },

      {
        Header: 'LAST SYNC',
        accessor: 'lastSync',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left font-style-common">{value ? value : '--'}</div>;
        },
      },

      {
        Header: 'MODIFIED BY',
        accessor: 'modifiedByUname',
        disableFilters: true,
        Cell: (e) => (
          <a href="#" className="table-non-link-color-common float-left  table-font-style-common">
            {e.cell.row.original.modifiedByUname && e.cell.row.original.modifiedByUname !== '--' ? (
              <span
                className="hyper_link_color-common cursor-hand table-font-style-common"
                onClick={(event) => {
                  event.preventDefault();
                  openModalfun('createdBy', e.cell.row.original);
                }}
              >
                {e.cell.row.original.modifiedByUname}
              </span>
            ) : (
              <span className="table-non-link-color-common">--</span>
            )}{' '}
          </a>
        ),
      },
      {
        Header: 'ACTIONS',
        accessor: 'Actions',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="flex table-non-link-color-common rs-icon-alignment-font-style">{value}</div>;
        },
      },
    ],
    []
  );

  const maximizeColumns = React.useMemo(
    () => [
      {
        Header: 'TEST DATA SET',
        accessor: 'name',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left font-style-common">{value}</div>;
        },
      },

      {
        Header: 'LAST SYNC',
        accessor: 'lastSync',
        disableFilters: true,
        Cell: ({ value }) => {
          return <div className="table-non-link-color-common float-left font-style-common">{value ? value : '--'}</div>;
        },
      },

      {
        Header: 'MODIFIED BY',
        accessor: 'modifiedByUname',
        disableFilters: true,
        Cell: ({ value }) => {
          return (
            <div className="table-non-link-color-common float-left  tabel-font-style-common">
              {value ? value : '--'}
            </div>
          );
        },
      },
      // {
      //     Header: 'ACTIONS',
      //     accessor: 'Actions',
      //     disableFilters: true,
      //     Cell: ({ value }) => {
      //         return (
      //             <div className="actionBtn">
      //                 <img src="\assets\execution_logos\edit.svg" className="project-action -ml-3" height="17px" width="17px"
      //                 ></img>
      //                 <img src="\assets\execution_logos\delete.svg" className="flex flex-row icon-blue-style project-action ml-2" height="17px" width="17px"
      //                     title="Delete"></img>
      //             </div>
      //         )
      //     }

      // },
    ],
    []
  );

  async function getFolderTreeFunction() {
    props.createTestDataFunction(testDataMainObj);

    let tocken = JSON.parse(localStorage.getItem('test-optimize-user'));
    setUserObj(tocken);
    resetProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 80,
    });
    setIsLoading(true);
    let getdata;
    try {
      getdata = await getAllTestDataForSuiteTreeReq();
      let res = createTreeData(getdata.data.responseObject.folderTree, 'root');
      setTreeData(res.treeData);
      localStorage.setItem('testdataFiles', JSON.stringify(res.treeData));
      setRootData(res.rootData);
      resetProgress();
      setIsLoading(false);
      testDataMainObj.sourceTestDataSet = res.treeData;
      props.createTestDataFunction(testDataMainObj);
    } catch (err) {
      console.log(err);
    }
  }

  const createTreeData = (response, type) => {
    let res_obj = {
      treeData: [],
      rootData: {},
    };

    if (!response[0].children) {
      response[0]['children'] = [];
    }

    const { children, ...root_data } = response[0];
    if (type === 'root') {
      res_obj.treeData = response[0].children;
    } else {
      res_obj.treeData = response;
    }
    let title = root_data.title;
    if (!root_data.title.toLowerCase().includes('folder')) {
      root_data.title = title.concat(' folder');
    }
    res_obj.rootData = root_data;
    return res_obj;
  };

  const onCheckedNodes = (nodes) => {
    if (replacedFileArray?.length) {
      setCreateUpdateCalled(false);
    } else {
      if (nodes?.length && testDataSyncValue?.length) {
        setCreateUpdateCalled(false);
      } else {
        setCreateUpdateCalled(true);
      }
    }
    setSelectedNodeSetArray(nodes);
  };

  const actionColumnHtml = (nodeObj) => {
    let type;
    if (nodeObj.isRootNode) {
      type = 'root';
    } else if (nodeObj.node.folder) {
      type = 'folder';
    } else {
      type = 'nonFolder';
    }
    return (
      <div className="action-col-wrp">
        <div className="flex flex-row cursor-hand">
          {nodeObj?.data?.folder ? (
            ''
          ) : (
            <div style={{ display: 'flex' }}>
              {' '}
              <img
                src="\assets\images\download.png"
                alt=""
                height="25px"
                className={`${!nodeObj?.data?.searchKey ? 'disabled-action' : null}`}
                width="25px"
                onClick={(e) => downloadFile(e, nodeObj)}
              />
              <img
                src="\assets\execution_logos\Replace_File.svg"
                alt=""
                height="25px"
                width="25px"
                onClick={() => (!nodeObj.data.folder ? handleClickBtn(nodeObj) : null)}
              />
            </div>
          )}
        </div>

        <button onChange={(event) => onLoad(event, nodeObj)} style={{ display: 'none' }}>
          <input type="file" id="replace-id" accept={Object.keys(TEST_DATA_FILE_ALLOWED)} />
        </button>
      </div>
    );
  };
  function handleClickBtn(node) {
    setSelectedNode(node);
    let htmlEle = document.getElementById('replace-id');
    if (htmlEle) {
      htmlEle.click();
    }
  }

  function onLoad(event, node) {
    let newKey;

    var file = event?.target?.files[0];

    if (selectedNode?.data?.searchKey.includes(`/`)) {
      let searchKeyArray = selectedNode?.data?.searchKey.split('/');
      if (searchKeyArray.length) {
        searchKeyArray.forEach((keyObj) => {
          if (keyObj !== '') {
            if (newKey) {
              newKey = newKey + '-' + keyObj;
            } else {
              newKey = '-' + keyObj;
            }
          }
        });
      }
    }

    if (validateFileExtension(file, TEST_DATA_FILE_ALLOWED)) {
      if (file?.name === selectedNode?.data?.name) {
        var reader = new FileReader();
        let fileNewName = newKey + '_&_' + selectedNode?.data?.name;
        fileNewName = fileNewName.replace('/', '-');

        reader.onload = function (e) {
          var content = reader.result;
          MyAlert.success(`${file.name} file replaced successfully `);
          setCreateUpdateCalled(false);
          let obj = {
            name: file?.name,
            content: content,
            file: file,
            fileNewName: fileNewName,
            setName: setObj?.name ? setObj?.name : formik?.values?.Name,
            file_id: selectedNode?.data?.key ? selectedNode?.data?.key : selectedNode?.data?._key,
          };
          obj.fileNewName = `${obj.setName}_@_${obj.fileNewName}`;
          if (replacedFileArray?.length) {
            if (obj?.setName) {
              let childIndex = replacedFileArray.findIndex(
                (objVal) => objVal?.setName === setObj?.name && objVal?.file_id === obj?.file_id
              );
              if (childIndex > -1) {
                replacedFileArray.splice(childIndex, 1);
                replacedFileArray.push(obj);
              } else {
                replacedFileArray.push(obj);
              }
            } else {
              let childIndex = replacedFileArray.findIndex(
                (objVal) => !objVal?.setName && objVal?.file_id === obj?.file_id
              );

              if (childIndex > -1) {
                replacedFileArray.splice(childIndex, 1);
                replacedFileArray.push(obj);
              } else {
                replacedFileArray.push(obj);
              }
            }
          } else {
            replacedFileArray.push(obj);
          }

          if (replacedTempArray?.length) {
            if (obj?.setName) {
              let childIndex = replacedTempArray.findIndex(
                (objVal) => objVal?.setName === setObj?.name && objVal?.file_id === obj?.file_id
              );

              if (childIndex > -1) {
                replacedTempArray.splice(childIndex, 1);
                replacedTempArray.push(obj);
              } else {
                replacedTempArray.push(obj);
              }
            } else {
              let childIndex = replacedTempArray.findIndex(
                (objVal) => !objVal?.setName && objVal?.file_id === obj?.file_id
              );
              if (childIndex > -1) {
                replacedTempArray.splice(childIndex, 1);
                replacedTempArray.push(obj);
              } else {
                replacedTempArray.push(obj);
              }
            }
          } else {
            replacedTempArray.push(obj);
          }

          props?.getChangedFile(replacedFileArray);
          if (testDataSyncValue?.length) {
            let index = testDataSyncValue.findIndex((obj) => obj?.id === obj?.file_id);
            if (index > -1) {
              testDataSyncValue.splice(index, 1);
            }
          }
        };
        if (file) {
          reader.readAsArrayBuffer(file);
        }
      } else {
        if (
          file?.name?.split('.')[file?.name?.split('.')?.length - 1] !==
          selectedNode?.data?.name?.split('.')[selectedNode?.data?.name?.split('.')?.length - 1]
        ) {
          MyAlert.danger('Select same file type');
        } else {
          MyAlert.danger('Select same file name');
        }
      }
    } else {
      MyAlert.warning(`${file?.name} is unsupported`);
    }

    event.target.value = '';
  }

  function downloadFile(e, node) {
    // let id = node?.node.title.indexOf('.');
    // let type = node?.node.title.slice(id + 1, node?.node.title.length);
    if (replacedFileArray.length) {
      let downloadObj = replacedFileArray.find(
        (obj) => obj.file_id === node?.data?._key && obj?.setName === setObj?.name
      );
      if (downloadObj) {
        if (testDataSyncValue?.length) {
          let downloadObjSync = testDataSyncValue.find((obj) => obj?.id === downloadObj?.file_id);
          if (downloadObjSync) {
            downloadFileFromCloud(downloadObjSync?.clonedFromId).then((d) => {
              saveFileFromBlob(d.data, downloadObjSync?.name);
            });
          } else {
            // var ctx = {
            //   worksheet: 'Worksheet',
            //   table: 'htmls',
            // };
            const blobObj = new Blob([downloadObj?.content], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
            });
            saveFileFromBlob(blobObj, node.node.title);
            MyAlert.success(`${node.node.title} file is downloaded`);
          }
        } else {
          // var ctx = {
          //   worksheet: 'Worksheet',
          //   table: 'htmls',
          // };
          const blobObj = new Blob([downloadObj?.content], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
          });
          saveFileFromBlob(blobObj, node.node.title);
          MyAlert.success(`${node.node.title} file is downloaded`);
        }
      } else {
        if (testDataSyncValue?.length) {
          let downloadObjSync = testDataSyncValue.find((obj) => obj.id === node.data._key);
          if (downloadObjSync) {
            downloadFileFromCloud(downloadObjSync?.clonedFromId).then((d) => {
              saveFileFromBlob(d.data, downloadObjSync?.name);
            });
          } else {
            if (node?.data?._key) {
              downloadFileFromCloud(node.node.key ? node.node.key : node.node._key).then((d) => {
                saveFileFromBlob(d.data, node.node.title);
              });
            }
          }
        } else {
          if (node?.data?._key) {
            downloadFileFromCloud(node.node.key ? node.node.key : node.node._key).then((d) => {
              saveFileFromBlob(d.data, node.node.title);
            });
          }
        }
      }
    } else {
      if (testDataSyncValue?.length) {
        let downloadObjSync = testDataSyncValue.find((obj) => obj.id === node.data?._key);
        if (downloadObjSync) {
          downloadFileFromCloud(downloadObjSync?.clonedFromId).then((d) => {
            saveFileFromBlob(d.data, downloadObjSync?.name);
          });
        } else {
          if (node?.data?._key) {
            downloadFileFromCloud(node.node.key ? node.node.key : node.node._key).then((d) => {
              saveFileFromBlob(d.data, node.node.title);
            });
          }
        }
      } else {
        if (node?.data?._key) {
          downloadFileFromCloud(node.node.key ? node.node.key : node.node._key).then((d) => {
            saveFileFromBlob(d.data, node.node.title);
          });
        }
      }
    }
  }

  function saveFileFromBlob(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window?.URL?.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
    MyAlert.success(`${filename} file is downloaded`);
  }

  const colDefs = [
    {
      field: 'title',
      title: 'FOLDER',
      class: 'title',
      width: `calc(100% - 550px)`,
      folderTitleWidth: `calc(100% - 670px)`,
      isTitle: true,
      render: (nodeObj) => {
        return (
          <>
            {nodeObj.isRootNode ? (
              <span
                className="count-badge folder-count ml-2 modifiedBy "
                title={`Folders ${nodeObj.data.subFolderCount}`}
              >
                F {nodeObj.data.subFolderCount}
              </span>
            ) : null}
            {nodeObj.node ? (
              nodeObj.node && nodeObj.node.data && nodeObj.node.folder ? (
                <span className="count-badge file-count modifiedBy">
                  {' '}
                  F {nodeObj.node.data.fileCount ? nodeObj.node.data.fileCount : nodeObj.node.data.fileCount}{' '}
                </span>
              ) : nodeObj.isRootNode ? (
                <span className="count-badge file-count modifiedBy" title={`Files ${nodeObj.data.fileCount}`}>
                  {' '}
                  F {nodeObj.data.fileCount}
                </span>
              ) : null
            ) : null}
            {nodeObj.node.folder && <button className="add-btn modifiedBy" type="button"></button>}
          </>
        );
      },
    },
    {
      field: 'fileType',
      title: 'TYPE',
      class: 'Type',
      width: '150px',
      render: (node) => {
        let type;
        if (node?.data?.folder === false) {
          let id = node?.node?.title?.indexOf('.');
          type = node?.node?.title?.slice(id + 1, node?.node.title.length);
        }
        return <>{type}</>;
      },
    },

    {
      field: 'lastSync',
      title: 'LAST SYNC',
      class: 'last-sync',
      width: '150px',
      render: (node) => {
        return <span>{!node?.data?.folder ? (node?.data?.lastSync ? node?.data?.lastSync : 'N/A') : null}</span>;
      },
    },
    {
      field: 'action',
      title: 'ACTIONS',
      class: 'action',
      width: '150px',
      render: actionColumnHtml,
    },
  ];

  const colDefsForCreate = [
    {
      field: 'title',
      title: 'FOLDER',
      class: 'title',
      width: `calc(100% - 500px)`,
      folderTitleWidth: `calc(100% - 670px)`,
      paddingRight: '16px',
      isTitle: true,
      render: (nodeObj) => {
        return (
          <>
            {nodeObj.isRootNode ? (
              <span className="count-badge folder-count ml-2" title={`Folders ${nodeObj.data.subFolderCount}`}>
                F {nodeObj.data.subFolderCount}
              </span>
            ) : null}
            {nodeObj.node ? (
              nodeObj.node && nodeObj.node.data && nodeObj.node.folder ? (
                <span className="count-badge file-count">
                  {' '}
                  F {nodeObj.node.data.fileCount ? nodeObj.node.data.fileCount : nodeObj.node.data.fileCount}{' '}
                </span>
              ) : nodeObj.isRootNode ? (
                <span className="count-badge file-count" title={`Files ${nodeObj.data.fileCount}`}>
                  {' '}
                  F {nodeObj.data.fileCount}
                </span>
              ) : null
            ) : null}
          </>
        );
      },
    },
    {
      field: 'fileType',
      title: 'TYPE',
      class: 'Type',
      width: '80px',
      render: (node) => {
        let type;
        if (node.data.folder === false) {
          let id = node?.node.title.indexOf('.');
          type = node?.node.title.slice(id + 1, node?.node.title.length);
        }
        return <>{type}</>;
      },
    },

    {
      field: 'modifiedByUname',
      title: 'MODIFIED BY',
      class: 'modified-by',
      width: '220px',
      render: (nodeObj) => {
        if (maximizePopUpOpened) {
          return (
            <div>
              {!nodeObj.isRootNode && (
                <span className="grey modifiedBy " name="modifiedBy">
                  {nodeObj.data.modifiedByUname}
                </span>
              )}
            </div>
          );
        } else {
          return (
            <div>
              {!nodeObj.isRootNode && (
                <span
                  className="hyper_link_color-common table-font-style-common  cursor-pointer"
                  onClick={() => openModalfun('createdBy', nodeObj.data)}
                  name="modifiedBy"
                >
                  {nodeObj.data.modifiedByUname}
                </span>
              )}
            </div>
          );
        }
      },
    },
  ];

  const colDefsForDetails = [
    {
      field: 'title',
      title: 'FOLDER',
      class: 'title',
      width: `calc(100% - 500px)`,
      folderTitleWidth: `calc(100% - 670px)`,
      paddingRight: '16px',
      isTitle: true,
      render: (nodeObj) => {
        return (
          <>
            {nodeObj.isRootNode ? (
              <span
                className="count-badge folder-count ml-2 modifiedBy"
                title={`Folders ${nodeObj.data.subFolderCount}`}
              >
                F {nodeObj.data.subFolderCount}
              </span>
            ) : null}
            {nodeObj.node ? (
              nodeObj.node && nodeObj.node.data && nodeObj.node.folder ? (
                <span className="count-badge file-count modifiedBy">
                  {' '}
                  F {nodeObj.node.data.fileCount ? nodeObj.node.data.fileCount : nodeObj.node.data.fileCount}{' '}
                </span>
              ) : nodeObj.isRootNode ? (
                <span className="count-badge file-count modifiedBy" title={`Files ${nodeObj.data.fileCount}`}>
                  {' '}
                  F {nodeObj.data.fileCount}
                </span>
              ) : null
            ) : null}
          </>
        );
      },
    },
    {
      field: 'fileType',
      title: 'TYPE',
      class: 'Type',
      width: '80px',
      render: (node) => {
        let type;
        if (node.data.folder === false) {
          let id = node?.node.title.indexOf('.');
          type = node?.node.title.slice(id + 1, node?.node.title.length);
        }
        return <>{type}</>;
      },
    },

    {
      field: 'lastSync',
      title: 'LAST SYNC',
      class: 'last-sync',
      width: '150px',
      render: (node) => {
        return <span>N/A</span>;
      },
    },
  ];

  const LABELS = {
    EXPAND_ALL: 'Expand folders',
    COLLAPSE_ALL: 'Collapse folders',
    SHOW_ALL_ELEMENTS: 'Show all files',
    HIDE_ALL_ELEMENTS: 'Hide all files',
  };

  if (isLoading) {
    renderTree = <CommonLoader />;
  } else if (treeData && treeData.length > 0) {
    renderTree = (
      <TableTree
        data={treeData}
        rootData={rootData}
        colDefs={colDefsForCreate}
        showCheckbox={false}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={true}
        conditions={[]}
        hideConditionBtn={true}
        labels={LABELS}
        onNodeSelected={() => {}}
        id={'testDataParent'}
      />
    );
  } else {
    renderTree = (
      <div>
        <div>
          <TableTree
            data={[]}
            rootData={rootData}
            colDefs={colDefsForCreate}
            showCheckbox={false}
            expandAll={isExpandAll}
            hideConditions={true}
            conditions={[]}
            hideConditionBtn={true}
            labels={LABELS}
            onNodeSelected={() => {}}
            id={'testDataParent'}
          />
        </div>
        <div className="overflow-y-auto h-82">
          <div className="mt-28 no-steps">
            <div classname="create-set-layout">
              <div className="qucBtn">
                <label className="quick-start-style px-1  ">No Data Available </label>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    renderMaximizeTree = <CommonLoader />;
  } else if (treeData && treeData.length > 0) {
    renderMaximizeTree = (
      <TableTree
        data={treeData}
        rootData={rootData}
        colDefs={colDefsForCreate}
        showCheckbox={false}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={true}
        conditions={[{ key: 'isPreCondition', value: true }]}
        hideConditionBtn={true}
        labels={LABELS}
        onNodeSelected={() => {}}
        id={'testDataParentMax'}
      />
    );
  }

  if (isLoading) {
    renderTreeForCreation = <CommonLoader />;
  } else if (treeData && treeData.length > 0) {
    renderTreeForCreation = (
      <TableTree
        data={treeData}
        rootData={rootData}
        colDefs={colDefs}
        showCheckbox={false}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={true}
        conditions={[{ key: 'isPreCondition', value: true }]}
        hideConditionBtn={true}
        labels={LABELS}
        onNodeSelected={() => {}}
        id={'testDataCreation'}
      />
    );
  }
  if (isLoading) {
    renderTreeEdit = <CommonLoader />;
  } else if (treeData && treeData.length > 0) {
    renderTreeEdit = (
      <TableTree
        data={setObj.testDataSets}
        rootData={rootData}
        colDefs={colDefs}
        showCheckbox={true}
        onCheckedNodes={onCheckedNodes}
        expandAll={true}
        hideElements={false}
        hideConditions={true}
        conditions={[{ key: 'isPreCondition', value: true }]}
        hideConditionBtn={true}
        labels={LABELS}
        onNodeSelected={() => {}}
        id={'testDataChild'}
      />
    );
  }

  if (isLoading) {
    renderTreeForDetails = <CommonLoader />;
  } else if (treeData && treeData.length > 0) {
    renderTreeForDetails = (
      <TableTree
        data={setObj?.testDataSets}
        rootData={rootData}
        colDefs={colDefsForDetails}
        showCheckbox={false}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={true}
        conditions={[{ key: 'isPreCondition', value: true }]}
        hideConditionBtn={true}
        labels={LABELS}
        onNodeSelected={() => {}}
        id={'testDataDetails'}
      />
    );
  }

  const formik = useFormik({
    initialValues: {
      Name: '',
    },

    validationSchema: Yup.object({
      Name: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(25, 'Maximum 25 characters')
        .required('Required!')
        .matches(/^[-_A-Za-z0-9 ]*$|^[-_A-Za-z0-9 ][A-Za-z0-9 ]*[-_A-Za-z-_]$/, 'Name should be alphanumeric')
        .matches(/^[-a-zA-Z0-9-()_]+(\s+[-a-zA-Z0-9-()_]+)*$/, 'Space is not allowed in the beginning and at the end'),
    }),
    onSubmit: (values) => {
      submitFormData();
    },
  });

  function createSetFunction(setObj) {
    if (props && props?.suiteObjMain && props?.suiteObjMain?.id) {
      let tempFileArray = [];
      let setObjForJsonForSet = {
        name: formik.values.Name,
        testDataSets: treeData,
      };

      if (replacedFileArray && replacedFileArray.length) {
        replacedFileArray.map((fileObj) => {
          if (fileObj) {
            var file = new File([fileObj.file], fileObj.fileNewName, {
              type: fileObj?.file?.type,
            });
            tempFileArray.push(file);
          }
        });
      }
      const unique = [...new Map(tempFileArray.map((item) => [item['name'], item])).values()];
      const formData = new FormData();
      unique.forEach((tag) => formData.append('files', tag));
      formData.append('data', JSON.stringify(setObjForJsonForSet));
      setCreateUpdateCalled(true);
      postTestDataSetReq(props?.suiteObjMain?.id, formData)
        .then((res) => {
          if (res.data && res.data.responseObject) {
            let testDataSetArray = TestDataSetArray;
            setObj.testDataSets = res?.data?.responseObject?.testDataSets;
            setObj.Actions = [];
            setCreateUpdateCalled(false);
            if (props?.rerunMode) {
              setObj.Actions.push(
                <>
                  <img
                    src="\assets\execution_logos\edit.svg"
                    alt="Edit icon"
                    className="project-action"
                    height="17px"
                    width="17px"
                  ></img>
                  <img
                    src="\assets\execution_logos\delete.svg"
                    alt="Delete icon"
                    className="flex flex-row icon-blue-style project-action "
                    height="17px"
                    width="17px"
                    title="Delete"
                  ></img>
                </>
              );
            } else {
              setObj.Actions.push(
                <>
                  <img
                    src="\assets\execution_logos\edit.svg"
                    alt="Edit icon"
                    className="project-action mr-2 cursor-hand"
                    height="17px"
                    width="17px"
                    onClick={() => {
                      openModalfun('updateSet');
                      createSetObj(setObj);
                    }}
                  ></img>
                  <img
                    src="\assets\execution_logos\delete.svg"
                    alt="Delete icon"
                    className="flex flex-row icon-blue-style project-action cursor-hand"
                    height="17px"
                    width="17px"
                    onClick={() => {
                      setOpen(true);
                      createSetObj(setObj);
                    }}
                    title="Delete"
                  ></img>
                </>
              );
            }
            if (replacedFileArray?.length) {
              replacedFileArray.map((obj) => {
                if (!obj?.setName) {
                  obj.setName = setObj?.name;
                }
              });
            }
            testDataSetArray.push(setObj);
            setTestDataSetArray(testDataSetArray);
            props.createAlertFun('success', `${setObjForJsonForSet.name} created successfully`);
            setShowModal(false);
            createJsonObject();
          } else {
            if (res.data.message.includes('already exists')) {
              MyAlert.danger(`Set with name ${setObj.name} already exist`);
            }
            setCreateUpdateCalled(false);
          }
        })
        .catch((error) => {
          console.log('error');
          setCreateUpdateCalled(false);
        });
    } else {
      let noDuplicate = false;
      if (TestDataSetArray && TestDataSetArray.length) {
        if (setObj && setObj?.name) {
          TestDataSetArray.map((setObjChild) => {
            if (setObjChild.name.toLowerCase() === setObj.name.toLowerCase()) {
              MyAlert.danger(`Set with name ${setObj.name} already exist`);
              noDuplicate = true;
            }
          });
          if (!noDuplicate) {
            let testDataSetArray = TestDataSetArray;
            setObj.Actions = [];
            if (props?.rerunMode) {
              setObj.Actions.push(
                <>
                  <img
                    src="\assets\execution_logos\edit.svg"
                    alt="Edit icon"
                    className="project-action"
                    height="17px"
                    width="17px"
                  ></img>
                  <img
                    src="\assets\execution_logos\delete.svg"
                    alt="Delete icon"
                    className="flex flex-row icon-blue-style project-action "
                    height="17px"
                    width="17px"
                    title="Delete"
                  ></img>
                </>
              );
            } else {
              setObj.Actions.push(
                <>
                  <img
                    src="\assets\execution_logos\edit.svg"
                    alt="Edit icon"
                    className="project-action mr-2 cursor-hand"
                    height="17px"
                    width="17px"
                    onClick={() => {
                      openModalfun('updateSet');
                      createSetObj(setObj);
                    }}
                  ></img>
                  <img
                    src="\assets\execution_logos\delete.svg"
                    alt="Delete icon"
                    className="flex flex-row icon-blue-style project-action cursor-hand"
                    height="17px"
                    width="17px"
                    onClick={() => {
                      setOpen(true);
                      createSetObj(setObj);
                    }}
                    title="Delete"
                  ></img>
                </>
              );
            }
            if (replacedFileArray?.length) {
              replacedFileArray.map((obj) => {
                if (!obj?.setName) {
                  obj.setName = setObj?.name;
                }
              });
            }
            testDataSetArray.push(setObj);
            setTestDataSetArray(testDataSetArray);
            props.createAlertFun('success', `${setObj.name} created successfully`);
            setShowModal(false);
            createJsonObject();
          }
        }
      } else {
        let testDataSetArray = TestDataSetArray;
        setObj.Actions = [];
        if (props?.rerunMode) {
          setObj.Actions.push(
            <>
              <img
                src="\assets\execution_logos\edit.svg"
                alt="Edit icon"
                className="project-action"
                height="17px"
                width="17px"
              ></img>
              <img
                src="\assets\execution_logos\delete.svg"
                alt="Delete icon"
                className="flex flex-row icon-blue-style project-action "
                height="17px"
                width="17px"
                title="Delete"
              ></img>
            </>
          );
        } else {
          setObj.Actions.push(
            <>
              <img
                src="\assets\execution_logos\edit.svg"
                alt="Edit icon"
                className="project-action mr-2 cursor-hand"
                height="17px"
                width="17px"
                onClick={() => {
                  openModalfun('updateSet');
                  createSetObj(setObj);
                }}
              ></img>
              <img
                src="\assets\execution_logos\delete.svg"
                className="flex flex-row icon-blue-style project-action cursor-hand"
                height="17px"
                width="17px"
                onClick={() => {
                  setOpen(true);
                  createSetObj(setObj);
                }}
                title="Delete"
                alt="Delete icon"
              ></img>
            </>
          );
        }

        if (replacedFileArray?.length) {
          replacedFileArray.map((obj) => {
            if (!obj?.setName) {
              obj.setName = setObj?.name;
            }
          });
        }
        testDataSetArray.push(setObj);
        setTestDataSetArray(testDataSetArray);
        props.createAlertFun('success', `${setObj.name} created successfully`);
        setShowModal(false);
        createJsonObject();
      }
    }
  }

  function createJsonObject() {
    let setObjForJson = {};
    testDataMainObj.clonedTestDataSets = [];
    TestDataSetArray.map((proObj) => {
      setObjForJson = {
        name: proObj.name,
        testDataSets: proObj.testDataSets,
        createdBy: proObj.createdBy,
        createdOn: proObj.createdOn,
        modifiedBy: proObj.modifiedBy,
        modifiedOn: proObj.modifiedOn,
        modifiedByUname: proObj.modifiedByUname,
      };
      testDataMainObj.clonedTestDataSets.push(setObjForJson);

      setShowModal(false);
      props.createTestDataFunction(testDataMainObj);
    });
  }

  function openModalfun(btn, testVal) {
    if (btn === 'create') {
      let testData = JSON.parse(localStorage.getItem('testdataFiles'));
      setCreateUpdateCalled(false);
      setObjectSet({});
      setTreeData(testData);
      setShowModal(true);
      setOperation('Create');
      formik.resetForm();
    } else if (btn === 'updateSet') {
      setShowModal(true);
      setCreateUpdateCalled(true);
      setOperation('updateSet');
    } else if (btn === 'maximize') {
      if (testDataType === selectTestDataType[1]) {
        if (TestDataSetArray.length) {
          setPageSizeCount(8);
          setShowModal(true);
          setOperation('maximize');
          setMaximizePopUpOpened(true);
          setPageIndexVal(0);
        }
      } else {
        setShowModal(true);
        setOperation('maximize');
        setMaximizePopUpOpened(true);
        setPageSizeCount(8);
        setPageIndexVal(0);
      }
    } else if (btn === 'createdBy') {
      if (testVal && testVal.modifiedBy) {
        setUserId(testVal);
      }

      setShowModal(true);
      setOperation('createdBy');
    } else if (btn === 'Details') {
      setShowModal(true);
      setOperation('Details');
    }
  }

  function setTestDataTypeFun(radioVal) {
    if (radioVal === selectTestDataType[0]) {
      testDataMainObj.useTestDataFrom = 'TEST_DEVELOPMENT';
    } else {
      testDataMainObj.useTestDataFrom = 'SET';
    }
    props.createTestDataFunction(testDataMainObj);
    setTestDataType(radioVal);
  }

  function getSelectedSet(val) {
    testDataMainObj.selectedTDSetName = val.name;
    setTestDataSetName(val?.name);
    props.createTestDataFunction(testDataMainObj);
  }

  function createSetObj(obj) {
    setObjectSet(obj);
  }

  let handelDlt = (deletObj) => {
    if (props?.suiteObjMain?.id) {
      let delObjIndex = TestDataSetArray.findIndex((obj) => obj.name === deletObj.name);
      if (delObjIndex > -1) {
        deleteTestDataSetReq(props.suiteObjMain.id, deletObj.name).then((res) => {
          if (res) {
            TestDataSetArray.splice(delObjIndex, 1);
            props.createAlertFun('success', `${deletObj.name} deleted successfully`);
            createJsonObject();
            setOpen(false);
            if (testDataMainObj.selectedTDSetName === deletObj.name) {
              testDataMainObj.selectedTDSetName = '';
            }
          }
        });
      }
    } else {
      let delObjIndex = TestDataSetArray.findIndex((obj) => obj.name === deletObj.name);
      if (delObjIndex > -1) {
        TestDataSetArray.splice(delObjIndex, 1);
        props.createAlertFun('success', `${deletObj.name} deleted successfully`);
        setOpen(false);
        createJsonObject();
      }
      if (testDataMainObj.selectedTDSetName === deletObj.name) {
        testDataMainObj.selectedTDSetName = '';
      }
    }
  };

  function syncVariable() {
    let fileId;
    if (props?.operation === 'create') {
      resetProgress();
      startOrResumeProgress({
        stopAt: 100,
        intervalDuration: 80,
      });
      setIsLoading(true);
      setCreateUpdateCalled(false);
      syncLocalData(setObj?.testDataSets);
    } else {
      setCreateUpdateCalled(false);
      if (selectedNodeSetArray.length) {
        selectedNodeSetArray.map((obj) => {
          if (obj?.data?.searchKey) {
            if (fileId) {
              fileId = fileId + obj?.data?._key + ',';
            } else {
              fileId = obj?.data?._key + ',';
            }
          }
        });
        resetProgress();
        startOrResumeProgress({
          stopAt: 100,
          intervalDuration: 80,
        });
        setIsLoading(true);
        syncTestDataReq(fileId)
          .then((results) => {
            testDataSyncValue = results?.data?.responseObject;
            filterTestData(testDataSyncValue, setObj?.testDataSets);
          })
          .catch((error) => {
            console.error('SYNC_TEST_DATA : ', error);
            resetProgress();
            setIsLoading(false);
          });
      }
    }
  }

  function syncLocalData(testDataSets) {
    if (replacedFileArray?.length && testDataSets?.length) {
      testDataSets.map((upObj) => {
        if (upObj?.folder) {
          syncLocalData(upObj?.children);
        } else {
          if (selectedNodeSetArray?.length) {
            let replacedIndex = replacedFileArray.findIndex(
              (chilObj) => chilObj.file_id === upObj?.key && chilObj?.setName === setObj?.name
            );

            if (replacedIndex > -1) {
              let selectedObj = selectedNodeSetArray.find((chilObj) => chilObj?.data?._key === upObj?.key);
              if (selectedObj) {
                replacedBackupArray.push(replacedFileArray[replacedIndex]);
                replacedFileArray.splice(replacedIndex, 1);
              }
            }
            let selectedObj = selectedNodeSetArray.find((chilObj) => chilObj?.data?._key === upObj?.key);
            if (selectedObj) {
              upObj.selected = true;
              upObj['previousLastSync'] = upObj?.lastSync ? upObj?.lastSync : 'N/A';
              upObj['lastSync'] = getTimeZoneFormateDate(todayDate, props?.operation);
            } else {
              upObj.selected = false;
            }
          } else {
            upObj.selected = false;
          }
        }
      });
      setTimeout(() => {
        resetProgress();
        setIsLoading(false);
      }, 1000);
    } else {
      if (testDataSets?.length) {
        testDataSets.map((upObj) => {
          if (upObj?.folder) {
            syncLocalData(upObj?.children);
          } else {
            if (selectedNodeSetArray?.length) {
              let selectedObj = selectedNodeSetArray.find((chilObj) => chilObj?.data?._key === upObj?.key);
              if (selectedObj) {
                upObj.selected = true;
                upObj['previousLastSync'] = upObj?.lastSync ? upObj?.lastSync : 'N/A';
                upObj['lastSync'] = getTimeZoneFormateDate(todayDate, props?.operation);
              } else {
                upObj.selected = false;
              }
            } else {
              upObj.selected = false;
            }
          }
        });
      }
      setTimeout(() => {
        resetProgress();
        setIsLoading(false);
      }, 1000);
    }
  }

  function filterTestData(testDataSyncValue, testDataSets) {
    testDataSets?.map((upObj) => {
      if (upObj?.folder) {
        filterTestData(testDataSyncValue, upObj?.children);
      } else {
        let tempObj = testDataSyncValue?.find((obj) => obj?.id === upObj?.key);
        if (tempObj) {
          upObj['lastSync'] = tempObj?.lastSync;
        }
      }

      let selectedObj = selectedNodeSetArray?.find((chilObj) => chilObj?.data?._key === upObj?.key);
      if (selectedObj) {
        upObj.selected = true;
      } else {
        upObj.selected = false;
      }
    });
    resetProgress();
    setIsLoading(false);
  }

  function updateSetFun() {
    let tempArray = [];
    let tempSyncArray = [];
    let d = new Date();
    let time = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();

    if (props?.suiteObjMain?.id) {
      let tempFileArray = [];
      const clone = (({ Actions, ...o }) => o)(setObj);
      let setObjForJsonForSet = {
        name: setObj.name,
        testDataSets: setObj.testDataSets,
      };

      if (replacedFileArray && replacedFileArray.length) {
        replacedFileArray.map((fileObj) => {
          if (fileObj) {
            var file = new File([fileObj.file], fileObj.fileNewName, {
              type: fileObj?.file?.type,
            });
            tempFileArray.push(file);
          }
        });
      }
      const unique = [...new Map(tempFileArray.map((item) => [item['name'], item])).values()];
      const formData = new FormData();
      unique.forEach((tag) => formData.append('files', tag));
      formData.append('data', JSON.stringify(setObjForJsonForSet));
      if (selectedNodeSetArray?.length && testDataSyncValue?.length) {
        selectedNodeSetArray.map((selObj) => {
          let tempObj = treeData.find((obj) => obj?.name === selObj?.node?.title);
          if (tempObj) {
            tempArray.push(tempObj);
          }
          let tempSyncObj = testDataSyncValue.find((obj) => obj?.name === selObj?.data?.title);
          if (tempSyncObj) {
            tempSyncArray.push(tempSyncObj);
          }
        });
      }

      if (tempArray?.length === treeData?.length) {
        setObjForJsonForSet['lastSync'] = testDataSyncValue[0]?.lastSync;
      }
      setCreateUpdateCalled(true);
      updateTestDataSetReq(props?.suiteObjMain?.id, setObjForJsonForSet.name, formData, 'Dataset')
        .then((res) => {
          if (res.data && res.data.responseObject) {
            setShowModal(false);
            replacedTempArray = [];

            updateSyncTestDataReq(
              props?.suiteObjMain?.id,
              tempSyncArray,
              tempArray?.length === treeData?.length ? true : false
            )
              .then((res) => {
                console.log('res', res);
              })
              .catch((error) => {
                console.log('error');
              });

            props.createAlertFun('success', `${clone.name} updated successfully`);
            createJsonObject();
            setSelectedNodeSetArray([]);
            popupCloseFun();
            setCreateUpdateCalled(true);
          } else {
            props.createAlertFun('danger', res.data.message);
            setCreateUpdateCalled(true);
          }
        })
        .catch((error) => {
          console.log('error');
          setCreateUpdateCalled(true);
        });
    } else {
      if (replacedBackupArray?.length && selectedNodeSetArray?.length) {
        selectedNodeSetArray.map((obj) => {
          let index = replacedBackupArray.findIndex((repObj) => repObj?.file_id === obj?.data?._key);

          if (index > -1) {
            replacedBackupArray.splice(index, 1);
          }
        });
      }

      // for removing last sync from unchecked data

      if (selectedNodeSetArray?.length) {
        selectedNodeSetArray.map((selObj) => {
          let tempObj = treeData.find((obj) => obj?.name === selObj?.node?.title);
          if (tempObj) {
            tempArray.push(tempObj);
          }
          let tempSyncObj = testDataSyncValue?.find((obj) => obj?.name === selObj?.data?.title);
          if (tempSyncObj) {
            tempSyncArray.push(tempSyncObj);
          }
        });
      }

      if (tempArray?.length === treeData?.length) {
        setObj['previousLastSync'] = setObj?.lastSync ? setObj?.lastSync : 'N/A';
        setObj['lastSync'] = getTimeZoneFormateDate(todayDate, props?.operation);
      }
      replacedFileArray = replacedFileArray.concat([...replacedBackupArray]);
      removeLastSyncDateFromFile(setObj?.testDataSets);
      replacedTempArray = [];
      replacedBackupArray = [];
      setObj.modifiedBy = userObjMain.id;
      setObj.modifiedByUname = userObjMain.name;
      setObj.modifiedOn = todayDate;
      setShowModal(false);
      removeSelected(setObj?.testDataSets);

      props.createAlertFun('success', `${setObj.name} updated successfully`);

      setSelectedNodeSetArray([]);
      createJsonObject();
    }
  }

  function removeLastSyncDateFromFile(testDataSets) {
    testDataSets?.map((upObj) => {
      if (upObj?.folder) {
        removeLastSyncDateFromFile(upObj?.children);
      } else {
        let replacedObj = replacedFileArray?.find(
          (chilObj) => chilObj.file_id === upObj?.key && chilObj?.setName === setObj?.name
        );
        if (replacedObj) {
          upObj.lastSync = upObj?.previousLastSync ? upObj?.previousLastSync : 'N/A';
        }
      }
    });
  }

  function popupCloseFun() {
    if (props?.operation !== 'create') {
      testDataSyncValue = [];
      replacedTempArray = [];
      replacedFileArray = [];
      getSingleTestDataReq(props?.suiteObjMain?.id, setObj?.name)
        .then((results) => {
          const response = results.data.responseObject;
          if (response) {
            setObj.testDataSets = response?.testDataSets;
            let temparray = TestDataSetArray;
            TestDataSetArray.forEach((data, index) => {
              if (data?.name === response?.name) {
                temparray[index] = response;
                temparray[index].Actions = data.Actions;
              }
            });
            setTestDataSetArray(temparray);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      if (replacedBackupArray?.length) {
        replacedFileArray = replacedFileArray.concat(replacedBackupArray);
        replacedBackupArray = [];
      }
      if (replacedTempArray?.length) {
        replacedTempArray.map((obj) => {
          let replacedIndex = replacedFileArray.findIndex(
            (chilObj) => chilObj.name === obj?.name && chilObj?.setName === setObj?.name
          );
          if (replacedIndex > -1) {
            replacedFileArray.splice(replacedIndex, 1);
          }
        });
      }
      setObj.lastSync = setObj?.previousLastSync ? setObj?.previousLastSync : 'N/A';

      removeSelected(setObj?.testDataSets, 'create');
      removeLastSyncDateFromFile(setObj?.testDataSets);
      replacedTempArray = [];
    }
  }

  function removeSelected(testDataSets, type) {
    if (type === 'create') {
      if (testDataSets?.length) {
        testDataSets.map((upObj) => {
          if (upObj?.folder) {
            removeSelected(upObj?.children, 'create');
          } else {
            upObj.selected = false;
            let replacedObj = replacedTempArray?.find(
              (chilObj) => chilObj.name === upObj?.name && chilObj?.setName === setObj?.name
            );

            if (replacedObj) {
              upObj.lastSync = upObj?.previousLastSync ? upObj?.previousLastSync : 'N/A';
            }
          }
        });
      }
    } else {
      if (testDataSets?.length) {
        testDataSets.map((upObj) => {
          if (upObj?.folder) {
            removeSelected(upObj?.children);
          } else {
            upObj.selected = false;
          }
        });
      }
    }
  }

  function submitFormData() {
    let data = {
      name: formik.values.Name,
      createdBy: userObjMain.name,
      createdOn: todayDate,
      testDataSets: treeData,
    };
    createSetFunction(data);
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block marginupper pr-6 align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-2/4">
                <div className="inline-block">
                  <div className="flex flex-start ml-2">
                    <span className="pt-5 pl-3">
                      <ExclamationIcon className="h-6 w-6 text-yellow-600 " />
                    </span>
                    <Dialog.Title as="h3" className="text-sm leading-6 ml-4 pt-3 font-small text-gray-900">
                      <span className="fontPoppinsSemiboldLg block">Warning </span>
                      <span className="fontPoppinsMediumMd">Delete </span>
                    </Dialog.Title>
                  </div>
                </div>
                <hr className="line mt-px" />
                <div className="text-sm font-sans mt-3 text-gray-500 ml-16 mr-8">
                  <p className=""> Your test data will be lost. Are you sure you want to delete {setObj.name}? </p>
                  <p className="mt-2"> How do you want to proceed?</p>
                </div>
                <hr className="line mt-3" />
                <div className="mt-2 pb-2 flex flex-row float-right">
                  <button type="button" className="mt-3 gray-btn ml-1" onClick={() => handelDlt(setObj)}>
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 ml-4 primary-btn "
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Modal open={showModal} className="modal-dialog" id="journal-scroll">
        {operation === 'Create' ? (
          <div className="modal-container testData-modal-style">
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-header">
                <label className="Popup-header-common">Create a copy of Test Data</label>
                <IconButton
                  color="primary"
                  component="span"
                  className="close"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>

              <div className="modal-body" id="journal-scroll" style={{ overflowY: 'hidden' }}>
                <div className="alert-testData-modal-position-style">
                  <AlertContatiner />
                </div>

                <div className="input-field-display">
                  <label htmlFor="Name" className="input-filed-label-style-common">
                    <span class="text-red-400">*</span> Name
                  </label>
                  <Input
                    className="w-1/2 fontPoppinsRegularLg"
                    type="text"
                    name="Name"
                    id="Name"
                    placeholder={`Enter name of the copy`}
                    autoComplete="off"
                    onChange={formik.handleChange}
                    value={formik.values.Name}
                  />
                  {formik.errors.Name && formik.touched.Name ? (
                    <div className="errorMessage">{formik.errors.Name}</div>
                  ) : null}
                </div>
                <div className="test-data-top-line-style"></div>

                <div className="test-data-set-content-area-style">
                  <div className="create-set-header">
                    <label className="create-set-header-label">Test Data Set</label>
                    {/* <FaSearch className="float-right searchBtn" /> */}
                  </div>
                  <div className="test-data-tree-height">{renderTreeForCreation}</div>
                </div>
              </div>
              <div className="modal-footer ">
                <button
                  className="gray-btn mr-3"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  {' '}
                  Close{' '}
                </button>
                <button type="submit" disabled={createUpdateCalled} className="primary-btn">
                  {' '}
                  Create
                </button>
              </div>
            </form>
          </div>
        ) : operation === 'updateSet' ? (
          <div className="modal-container testData-modal-style">
            <div className="modal-header">
              <label className="Popup-header-common">Edit Test Data Set</label>
              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  setShowModal(false);
                  setSelectedNodeSetArray([]);
                  setCreateUpdateCalled(true);
                  popupCloseFun();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className="modal-body" id="journal-scroll">
              <div className="alert-testData-modal-position-style">
                <AlertContatiner />
              </div>
              <div className="edit-test-data-content-area-style">
                <div className="create-set-header">
                  <div>
                    <label className="create-set-header-label float-left ">{setObj.name}</label>
                  </div>
                  <div className="flex flex-row float-right">
                    {/* <FaSearch className="mt-2 mr-3 searchBtn" /> */}
                    <button
                      className={`fontPoppinsMediumMd ${
                        selectedNodeSetArray.length ? 'secondary-btn' : 'disabled-btn '
                      } mr-3`}
                      disabled={!selectedNodeSetArray.length}
                      onClick={syncVariable}
                    >
                      Sync Test Data
                    </button>
                    <Tooltip placement="top" title="Selected test data files will be updated to latest published state">
                      <InfoOutlinedIcon className="float-right  flex flex-row mr-2 mt-1 hyper_link_color-common" />
                    </Tooltip>
                  </div>
                </div>
                <div className="test-data-tree-height">{renderTreeEdit}</div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                className="gray-btn"
                onClick={() => {
                  setShowModal(false);
                  setSelectedNodeSetArray([]);
                  setCreateUpdateCalled(true);
                  popupCloseFun();
                }}
              >
                Cancel
              </button>
              <button type="submit" onClick={updateSetFun} className="primary-btn" disabled={createUpdateCalled}>
                Update
              </button>
            </div>
          </div>
        ) : operation === 'maximize' ? (
          <div className="modal-container max-popup-style ">
            <div className="modal-header ">
              {testDataType === selectTestDataType[0] ? (
                <label className="Popup-header-common">Test Data from Test Development</label>
              ) : (
                <label className="Popup-header-common">Test Data Sets</label>
              )}
              <div className="float-right flex flex-row">
                {/* <FaSearch className="search-icon-maximize-style searchBtn" /> */}
                <CloseIcon
                  className="cursor-hand"
                  onClick={() => {
                    setShowModal(false);
                    setPageSizeCount(5);
                    setOperation('');
                    setPageIndexVal(0);
                  }}
                />
              </div>
            </div>

            <div className="modal-body-1" id="journal-scroll">
              {testDataType === selectTestDataType[0] ? (
                <div className="maximize-tree-size">{renderMaximizeTree}</div>
              ) : (
                <>
                  <div className="table_height overflow-auto" id="journal-scroll">
                    <Table columns={maximizeColumns} data={TestDataSetArray} />
                  </div>
                </>
              )}
            </div>
          </div>
        ) : operation === 'Details' ? (
          <div className="modal-container testData-modal-style">
            <div className="modal-header ">
              <label className="Popup-header-common">Test Data Set Details - {setObj.name}</label>
              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className="modal-body" id="journal-scroll">
              <div>
                <div className="grid grid-cols-3">
                  <div>
                    <label className="input-filed-label-style-common">Name</label>
                    <br />
                    <label className="set-details-value-style">{setObj.name}</label>
                  </div>
                  <div>
                    <label className="input-filed-label-style-common">No Of Files</label>
                    <br />
                    <label className="set-details-value-style">{setObj?.testDataSets?.length}</label>
                  </div>
                  <div>
                    <label className="input-filed-label-style-common">Created By</label>
                    <br />
                    <label className="set-details-value-style">{setObj.createdBy}</label>
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-5">
                  <div>
                    <label className="input-filed-label-style-common">Created On</label>
                    <br />
                    <label className="set-details-value-style">
                      {setObj.createdOn ? getTimeZoneFormateDate(setObj.createdOn, props?.operation) : '--'}
                    </label>
                  </div>
                  <div>
                    <label className="input-filed-label-style-common">Modified By</label>
                    <br />
                    <label className="set-details-value-style">{setObj.modifiedBy ? setObj.modifiedBy : '--'}</label>
                  </div>
                  <div>
                    <label className="input-filed-label-style-common">Modified On</label>
                    <br />
                    <label className="set-details-value-style">
                      {setObj.modifiedOn ? getTimeZoneFormateDate(setObj.modifiedOn, props?.operation) : '--'}
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <div className="dotted-border-style mt-2"></div>
                <div className="test-data-details-content-area-style mt-4">
                  <div className="create-set-header">
                    <label className="create-set-header-label">{setObj.name}</label>
                    {/* <FaSearch className="float-right searchBtn" /> */}
                  </div>
                  <div className="test-data-tree-height">{renderTreeForDetails}</div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="gray-btn"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        ) : operation === 'createdBy' ? (
          <div className="modal-container page-modal-size">
            <div className="modal-header ">
              <label className="Popup-header-common">User Details - {userId?.modifiedByUname}</label>
              <IconButton
                color="primary"
                component="span"
                className="close"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <div className="modal-body" id="journal-scroll">
              <UserDetailsPage modifiedBy={userId?.modifiedBy} />
            </div>
          </div>
        ) : null}
      </Modal>
      <div>
        <div className="left-border-style mt-3">
          <div className="mb-3 ">
            <span className="text-data-head-style fontPoppinsSemiboldMd">Test Data</span>
          </div>
          <RadioButton
            rerunMode={props?.rerunMode}
            radioList={selectTestDataType}
            editData={testDataType}
            radioName="selectTestDataType"
            func={setTestDataTypeFun}
          />
        </div>
        {testDataType === selectTestDataType[0] ||
        (testDataType === selectTestDataType[1] && TestDataSetArray.length) ? (
          <div className="module-area-style">
            <div className="content_area_header_style">
              <div>
                <label className="main-header-label float-left ">
                  {testDataType === selectTestDataType[0] ? 'Test Data from Test Development' : 'Test Data Sets'}
                </label>
              </div>

              <div className="flex flex-row float-right">
                {/* <FaSearch className="mt-2 mr-3 searchBtn" /> */}
                {testDataType === selectTestDataType[1] ? (
                  <button
                    className="secondary-btn mr-3"
                    onClick={() => openModalfun('create')}
                    disabled={!treeData.length || props?.rerunMode || !treeData.length}
                  >
                    + Test Data Set
                  </button>
                ) : null}
                {testDataType === selectTestDataType[0] ? (
                  <FiMaximize2
                    title="Maximize"
                    className={`${renderTree ? 'maximize-btn-style  cursor-hand' : 'disabled-action'} mt-2 mr-3`}
                    onClick={() => {
                      openModalfun('maximize');
                      setTestDataType(selectTestDataType[0]);
                      setCurrentPage(1);
                    }}
                  />
                ) : (
                  <FiMaximize2
                    title="Maximize"
                    className={`${
                      TestDataSetArray.length ? 'maximize-btn-style  cursor-hand' : 'disabled-action'
                    } mt-2 mr-3`}
                    onClick={() => {
                      openModalfun('maximize');
                      setTestDataType(selectTestDataType[1]);
                      setCurrentPage(1);
                    }}
                  />
                )}
              </div>
            </div>
            <div>
              {testDataType === selectTestDataType[0] ? <div className="table_tree_height">{renderTree}</div> : null}
              {testDataType === selectTestDataType[1] ? (
                <div className="table_height overflow-auto" id="journal-scroll">
                  <Table columns={columns} data={TestDataSetArray} />
                </div>
              ) : null}
            </div>
          </div>
        ) : testDataType === selectTestDataType[1] ? (
          !TestDataSetArray.length ? (
            <button
              className="secondary-btn mr-3 left-border-style top-border-style"
              onClick={() => openModalfun('create')}
              disabled={!treeData.length || props?.rerunMode || !treeData.length}
            >
              + Test Data Set
            </button>
          ) : null
        ) : null}
      </div>
    </>
  );
};

export default TestData;
