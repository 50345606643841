import React, { createContext, useEffect, useState, useCallback } from 'react';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import CommonDrawer from '@src/common/atoms/CommonDrawer';
import CommonButton from '@src/common/button/Button';
import { FIREFLINK_ISSUE_DRAWER } from '@pages/configuration/DefectConfig/defect-constants';
import { DEFECT_CONSTANTS } from '@src/common/ui-constants';
import { useAlert } from '@pages/common/alert_service/useAlert';
import { FORM_FIELDS, FORM_FIELDS_ARRAY, MESSAGES } from '@pages/defects/defectMgmtConstants';
import Comment from '@pages/defects/defect-comments/components/Comment/Comment';
import '@pages/defects/defect-comments/sass/style.scss';
import CommentForm from '@pages/defects/defect-comments/components/CommentForm/CommentForm';
import {
  createDefectReq,
  deleteMultipleFilesReq,
  getAllModuleTreeReq,
  getUsersByProjectIdReq,
  updateDefectReq,
  getAllComments,
  createComments,
  deleteComments,
  updateComments,
} from '@api/api_services';
import { checkValidationForAlphanumericSpecialCharacters } from '@pages/analytics/common/util';
import { getDefectDescription } from '@pages/defects/defect-utils';
import MultiAutocomplete from '@pages/test-development/script/modules/module/modals/labels-multiselect-dropdown';
import { getUserName, isEmptyValue, regexValidator, validateFileExtension } from '@src/util/common_utils';
import { getSelectedProject } from '@src/service/common_service';
import { Label } from '@src/common/atoms/LabelComponent';
import { DefectIssueFields } from '@pages/configuration/DefectConfig/defect-drawers/FireFlinkIssueDrawerFields';
import { REGEX, DEFECT_ATTACTMENT_FILE_ALLOWED, UI_VALIDATIONS } from '@src/util/validations';
import styles from '@pages/configuration/DefectConfig/defect-config.module.scss';
const commentContext = createContext();

const FireFlinkIssueDrawer = (props) => {
  const {
    closeDrawer,
    openDrawer,
    reloadTable,
    failedStepsData,
    drawerType,
    defectMgmtData,
    selectedDefectData,
    setSelectedDefectData,
    reloadHandler = () => {},
    initDefectData = () => {},
    selectedModule,
    history,
    cleanUpFunction,
  } = props;
  const { steps: failedSteps, envDetails, isFromSteps } = failedStepsData || {};
  const [isCreateAnotherDefect, setIsCreateAnotherDefect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [anotherDefectCheck, setAnotherDefectCheck] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [validateSchema, setValidateSchema] = useState({});
  const [defectTemplateFields, setDefectTemplateFields] = useState([]);
  const [defaultSelectedModule, setDefaultSelectedModule] = useState([]);
  const { AlertContatiner, MyAlert } = useAlert();
  const [projectUsers, setProjectUsers] = useState([]);
  const [tempUploadFiles, setTempUploadFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [newFilesToBeUploaded, setNewFilesToBeUploaded] = useState([]);
  const [comments, setComments] = useState([]);
  const [currentComment, SetCurrentComment] = useState('');
  const [reset, setReset] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [labelSelectedOptions, setLabelSelectedOptions] = useState([]);
  const [labelResponseObject, setLabelResponseObject] = useState([]);
  const [resetLabels, setResetLabels] = useState(false);
  const projectLabelsObject = [];
  const checkForSpecialCharacter = regexValidator(REGEX.SPECIAL_CHARACTER);

  const { TEXTFIELD, TEXTBOX, CHECKBOX, LINK, ATTACHMENT, DATE } = FORM_FIELDS;
  const {
    CREATE_SUCCESS,
    UPDATE_SUCCESS,
    ALPHA_ERR_MSG,
    CREATE_DEFECT_ERR,
    REQUIRED_ERR_MSG,
    NO_SPACE_AT_START_AND_END_MSG,
    YES,
    VALID_URL,
    MAX_NO_OF_FILES_ALLOWED,
    DUPLICATE_FILE,
    MAX_FILE_SIZE_ALLOWED,
    CREATE_ANOTHER_DEFECT,
    ADD,
    EDIT,
    NO,
  } = MESSAGES;
  const { MAX_FILE_UPLOAD_COUNT } = UI_VALIDATIONS;

  const [moduleTree, setModuleTree] = useState([]);
  const [moduleLevelScriptCount, setModuleLevelScriptCount] = useState(0);
  let tempInitialValues = {};
  let tempValidateSchema = '';
  let loginUserName = getUserName();
  let project = getSelectedProject();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validateSchema,
    onSubmit: (values) => {
      handleOnSubmit(values);
    },
  });

  useEffect(() => {
    (async function () {
      const allUsers = await getUsersByProjectId();
      setProjectUsers(allUsers);
      createValidationSchema(allUsers);
    })();
  }, []);

  useEffect(() => {
    (async function () {
      try {
        const response = await getAllModuleTreeReq(false);
        if (response?.data?.responseObject?.moduleTree?.length) {
          setModuleTree(response.data.responseObject.moduleTree);
          setModuleLevelScriptCount(response?.data?.responseObject?.moduleLevelScriptCount);
        } else {
          setModuleTree([]);
        }
      } catch (err) {
        console.error('GET_MODULE_TREE :', err);
      }
    })();
  }, []);

  const handleClick = () => {
    let userEmailArray = [];
    let emailArray = [];

    let getProjectUser = [...projectUsers];
    let userName = currentComment.split(/(?=[" ",@])/gi);
    let fetchOnlyUserName = userName.filter((atuserName) => atuserName.includes('@'));
    let fetchUser = fetchOnlyUserName.map((user) => user.slice(1));
    let unique = [...new Set(fetchUser.filter((word) => word.trim().length > 0))];

    getProjectUser.forEach((usernames) => {
      unique.forEach((user) => {
        if (usernames.name.includes(user.slice(0, 5))) {
          emailArray.push(usernames);
        }
      });
    });

    emailArray.forEach((userEmail) => {
      userEmailArray.push(userEmail.emailId);
    });

    let emailId = [];
    if (checkForSpecialCharacter(currentComment) || currentComment === '@' || isEmptyValue(userEmailArray)) {
      emailId = [];
    } else {
      emailId = userEmailArray;
    }

    let data = {
      emailId,
      description: currentComment,
      commentParentId: '',
      comments: [],
    };

    createCommentsAPI(selectedDefectData?.defectDetails?.ID, data);
    setReset(true);
  };

  let createCommentsAPI = (defectId, data) => {
    createComments(defectId, data)
      .then((response) => {
        getAllCommentsAPI();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const dataPassToParent = useCallback(() => {
    getAllCommentsAPI();
  }, []);

  const labelDataPassToParent = (labelData) => {
    setLabelSelectedOptions(labelData);
  };

  labelSelectedOptions?.forEach((labelValue) => {
    labelResponseObject?.forEach((objectValue) => {
      if (objectValue.name === labelValue) {
        projectLabelsObject.push(objectValue);
      }
    });
  });

  const deleteComment = (id) => {
    let duplicateCommentArray = [...comments];
    let matchedComment = duplicateCommentArray.filter((comment) => {
      return comment.id === id ? comment : '';
    });
    let index = duplicateCommentArray.indexOf(matchedComment[0]);
    duplicateCommentArray.splice(index, 1);

    deleteComments(selectedDefectData?.defectDetails?.ID, id, duplicateCommentArray)
      .then(() => {
        setComments(duplicateCommentArray);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const updateComment = (id, updatedComment) => {
    let duplicateCommentArray = [...comments];
    duplicateCommentArray.map((comment) => {
      if (comment.id === id) {
        let userEmailArray = [];
        let emailArray = [];
        let getProjectUser = [...projectUsers];
        let userName = updatedComment.split(/(?=[" ",@])/gi);
        let fetchOnlyUserName = userName.filter((atuserName) => atuserName.includes('@'));
        let fetchUser = fetchOnlyUserName.map((user) => user.slice(1));
        let unique = [...new Set(fetchUser.filter((word) => word.trim().length > 0))];

        getProjectUser.forEach((usernames) => {
          unique.forEach((user) => {
            if (usernames.name.includes(user.slice(0, 5))) {
              emailArray.push(usernames);
            }
          });
        });

        const userCheckwithSpecial = checkForSpecialCharacter(updatedComment);
        emailArray.forEach((userEmail) => {
          if (userCheckwithSpecial) {
            userEmailArray = [];
          } else {
            userEmailArray.push(userEmail.emailId);
          }
        });

        comment.description = updatedComment;
        comment.emailId = [...new Set(userEmailArray)];
      }
      return comment;
    });
    let updatedObject = [...duplicateCommentArray];
    const filteredResult = updatedObject.find((update) => update.id === id);
    updateComments(selectedDefectData?.defectDetails?.ID, id, filteredResult, 'Update')
      .then(() => {
        getAllCommentsAPI();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const updateReplyComment = (id, childCommentId, updatedComment) => {
    let duplicateCommentArray = [...comments];
    let sendObjToBackend = [];
    duplicateCommentArray.forEach((comment) => {
      if (comment.id === id) {
        comment.comments.forEach((replyComment) => {
          if (replyComment.id === childCommentId) {
            let userEmailArray = [];
            let emailArray = [];
            let getProjectUser = [...projectUsers];
            let userName = updatedComment.split(/(?=[" ",@])/gi);
            let fetchOnlyUserName = userName.filter((atuserName) => atuserName.includes('@'));
            let fetchUser = fetchOnlyUserName.map((user) => user.slice(1));
            let unique = [...new Set(fetchUser)];

            getProjectUser.forEach((usernames) => {
              unique.forEach((user) => {
                if (usernames.name.includes(user.slice(0, 5))) {
                  emailArray.push(usernames);
                }
              });
            });

            const userCheckwithSpecial = checkForSpecialCharacter(updatedComment);
            emailArray.forEach((userEmail) => {
              if (userCheckwithSpecial) {
                userEmailArray = [];
              } else {
                userEmailArray.push(userEmail.emailId);
              }
            });

            sendObjToBackend.push(replyComment);
            replyComment.description = updatedComment;
            replyComment.emailId = [...new Set(userEmailArray)];
          }
        });
        return comment;
      }
    });

    sendObjToBackend.forEach((filteredResult) => {
      updateComments(selectedDefectData?.defectDetails?.ID, filteredResult.id, filteredResult, 'Update')
        .then(() => {
          getAllCommentsAPI();
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const deleteReplyComment = (parentCommentId, childCommentId) => {
    let duplicateCommentArray = [...comments];
    let updateObject = [];
    duplicateCommentArray.forEach((comment) => {
      if (comment.id === parentCommentId) {
        updateObject.push(comment);
        let replyCommentsArray = [...comment.comments];
        comment.comments.forEach((replyComment, index) => {
          if (replyComment.id === childCommentId) {
            replyCommentsArray.splice(index, 1);
            comment.comments = [...replyCommentsArray];
          }
        });
      }
    });
    deleteComments(selectedDefectData?.defectDetails?.ID, childCommentId, duplicateCommentArray)
      .then(() => {
        setComments(duplicateCommentArray);
      })
      .catch((err) => {
        console.error(err);
      });

    updateObject.forEach((filteredResult) => {
      updateComments(selectedDefectData?.defectDetails?.ID, filteredResult.id, filteredResult)
        .then(() => {
          setComments(comments);
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const deleteThreadComment = (parentCommentId, childCommentId) => {
    let duplicateCommentArray = [...comments];
    let findObj = [];
    duplicateCommentArray.forEach((comment) => {
      comment.comments?.forEach((replyComment) => {
        if (replyComment.id === parentCommentId) {
          findObj.push(comment);
          let threadCommentsArray = [...replyComment.comments];
          replyComment.comments.forEach((replyCommentThread, index) => {
            if (replyCommentThread.id === childCommentId) {
              threadCommentsArray.splice(index, 1);
              replyComment.comments = [...threadCommentsArray];
            }
          });
        }
      });
    });
    deleteComments(selectedDefectData?.defectDetails?.ID, childCommentId)
      .then(() => {
        setComments(duplicateCommentArray);
      })
      .catch((err) => {
        console.error(err);
      });

    findObj.forEach((filteredResult) => {
      updateComments(selectedDefectData?.defectDetails?.ID, filteredResult.id, filteredResult)
        .then(() => {
          setComments(comments);
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const updateThreadComment = (parentCommentId, childCommentId, updatedComment) => {
    let duplicateCommentArray = [...comments];
    let findObj = [];
    let sendObjToBack = [];
    duplicateCommentArray.forEach((comment) => {
      comment.comments?.forEach((replyComment) => {
        if (replyComment.id === parentCommentId) {
          findObj.push(comment);
          replyComment.comments.forEach((replyCommentThread) => {
            if (replyCommentThread.id === childCommentId) {
              let userEmailArray = [];
              let emailArray = [];
              let getProjectUser = [...projectUsers];
              let userName = updatedComment.split(/(?=[" ",@])/gi);
              let fetchOnlyUserName = userName.filter((atuserName) => atuserName.includes('@'));
              let fetchUser = fetchOnlyUserName.map((user) => user.slice(1));
              let unique = [...new Set(fetchUser.filter((word) => word.trim().length > 0))];

              getProjectUser.forEach((usernames) => {
                unique.forEach((user) => {
                  if (usernames.name.includes(user.slice(0, 5))) {
                    emailArray.push(usernames);
                  }
                });
              });

              const userCheckwithSpecial = checkForSpecialCharacter(updatedComment);

              emailArray.forEach((userEmail) => {
                if (userCheckwithSpecial) {
                  userEmailArray = [];
                } else {
                  userEmailArray.push(userEmail.emailId);
                }
              });
              sendObjToBack.push(replyCommentThread);
              replyCommentThread.description = updatedComment;
              replyCommentThread.emailId = [...new Set(userEmailArray)];
            }
          });
        }
      });
    });
    sendObjToBack.forEach((filteredResult) => {
      updateComments(selectedDefectData?.defectDetails?.ID, filteredResult.id, filteredResult, 'Update')
        .then(() => {
          getAllCommentsAPI();
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const getUsersByProjectId = async () => {
    try {
      const response = await getUsersByProjectIdReq(project?.id);
      if (response?.data?.responseCode === 200) {
        const data = response?.data?.responseObject;
        const allUsers = data.map((val) => {
          return {
            id: val?.user?.id,
            name: val?.user?.name,
            emailId: val?.user?.emailId,
          };
        });
        return allUsers;
      }
    } catch (err) {
      console.error('Get Users By ProjectId error', err);
    }
  };

  const sortBasedOnOrder = (defectTemplateData) => {
    return defectTemplateData?.sort((a, b) => {
      return a?.order - b?.order;
    });
  };
  function createYupSchema(schema, config) {
    const { id, validationType, validations = [] } = config;
    if (!yup[validationType]) {
      return schema;
    }
    let validator = yup[validationType]();
    validations.forEach((validation) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });
    schema[id] = validator;
    return schema;
  }

  const createValidationSchema = (allUsers) => {
    let defectTemplateData = JSON.parse(
      JSON.stringify(sortBasedOnOrder(defectMgmtData?.defect_details_templates[0]?.defectDetails))
    );
    for (let i = 0; i < defectTemplateData?.length; i++) {
      let element = defectTemplateData[i];
      let validations = [];
      if (element?.label === 'State' && drawerType === ADD) {
        element.enable = NO;
      }

      if (FORM_FIELDS_ARRAY.includes(element?.type)) {
        if ([CHECKBOX, ATTACHMENT].includes(element.type)) {
          element.validationType = 'array';
        } else if (element?.type === DATE) {
          if (['Created On', 'Modified On'].includes(element?.label)) {
            element.validationType = 'string';
          } else {
            element.validationType = DATE;
          }
        } else if (['Modified By', 'Created By'].includes(element?.label)) {
          element.validationType = 'object';
        } else {
          element.validationType = 'string';
        }
      }
      if ([TEXTFIELD, TEXTBOX].includes(element.type)) {
        validations.push(
          {
            type: 'trim',
            params: [NO_SPACE_AT_START_AND_END_MSG],
          },
          {
            type: 'strict',
            params: [true],
          }
        );

        if (element.type === TEXTFIELD) {
          validations.push({
            type: 'matches',
            params: [checkValidationForAlphanumericSpecialCharacters, ALPHA_ERR_MSG],
          });
        }

        if (element?.label === 'Summary') {
          validations.push(
            {
              type: 'max',
              params: [250, `${element?.label} ${DEFECT_CONSTANTS.MAX_CHARACTER_VALIDATION}`],
            },
            {
              type: 'min',
              params: [3, `${element?.label} ${DEFECT_CONSTANTS.MIN_CHARACTER_VALIDATION}`],
            }
          );
        }
      }
      if (element?.minLength) {
        let validationObj = {
          type: 'min',
          params: [element.minLength, `Input cannot be less than ${element.minLength} characters`],
        };
        validations.push(validationObj);
      }
      if (element?.maxLength) {
        let validationObj = {
          type: 'max',
          params: [element.maxLength, `Input cannot be more than ${element.maxLength} characters`],
        };
        validations.push(validationObj);
      }
      if (element?.mandatory === YES) {
        let validationObj = {
          type: 'required',
          params: [MESSAGES.REQUIRED_ERR_MSG(element.label)],
        };
        validations.push(validationObj);

        if ([CHECKBOX, ATTACHMENT].includes(element.type)) {
          let validationObj = {
            type: 'min',
            params: [1, MESSAGES.REQUIRED_ERR_MSG(element.label)],
          };
          validations.push(validationObj);
        }
      }
      if (element.type === LINK) {
        let validationObj = {
          type: 'url',
          params: [VALID_URL],
        };
        validations.push(validationObj);
      }
      element.validations = validations;
      element.id = element.label;
    }
    createFormikSchema(defectTemplateData, allUsers);
  };

  function handleDynamicStateValues(currentState, data) {
    const nextState = data.filter((a) => {
      if (a.current_state === currentState) return a.next_states;
    });
    return nextState[0].next_states;
  }

  function createFormikSchema(defectTemplateData, allUsers) {
    const { defectDetails } = selectedDefectData;
    defectTemplateData.forEach((item) => {
      if (item?.label === 'State') {
        if (drawerType === ADD) {
          item.options = defectMgmtData?.defect_life_cycle_templates[0]?.states || [];
        } else if (drawerType === EDIT) {
          let nextState;
          if (defectMgmtData?.defect_life_cycle_templates[0]?._id !== selectedDefectData?.defectLifeCycleTemplateId) {
            nextState = handleDynamicStateValues(
              defectMgmtData?.defect_life_cycle_templates[0]?.states[0],
              defectMgmtData?.defect_life_cycle_templates[0]?.state_transitions
            );
            if (!nextState.includes(defectMgmtData?.defect_life_cycle_templates[0]?.states[0])) {
              nextState.unshift(defectMgmtData?.defect_life_cycle_templates[0]?.states[0]);
            }
            item.options = nextState || [];
          } else {
            nextState = handleDynamicStateValues(
              selectedDefectData.state,
              defectMgmtData?.defect_life_cycle_templates[0]?.state_transitions
            );
            if (!nextState.includes(selectedDefectData.state)) {
              nextState.unshift(selectedDefectData.state);
            }
            item.options = nextState || [];
          }
        }
        if (drawerType === ADD) {
          tempInitialValues[item.label] = item?.options?.[0] || '';
        } else if (
          drawerType === EDIT &&
          defectMgmtData?.defect_life_cycle_templates[0]?._id !== selectedDefectData?.defectLifeCycleTemplateId
        ) {
          tempInitialValues[item.label] = defectMgmtData?.defect_life_cycle_templates[0]?.states[0];
        } else if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        } else {
          tempInitialValues[item.label] = '';
        }
      } else if (item?.label === 'Assign to') {
        item.options = allUsers;
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label]?.id;
        } else {
          tempInitialValues[item.label] = '';
        }
      } else if (item?.label?.toLowerCase() === 'description') {
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        } else if (failedSteps || envDetails) {
          tempInitialValues[item.label] = `{"blocks":${getDefectDescription(
            failedSteps,
            envDetails,
            isFromSteps
          )},"entityMap":{}}`;
        } else {
          tempInitialValues[item.label] = '';
        }
      } else if (item?.label?.toLowerCase() === 'module') {
        if (selectedModule) {
          const [defaultCheckedNodeName, defaultCheckedNodeId] = selectedModule.split(':');
          setDefaultSelectedModule([{ key: 'key', value: defaultCheckedNodeId || '' }]);
          tempInitialValues[item.label] = selectedModule || '';
        } else {
          const [defaultCheckedNodeName, defaultCheckedNodeId] =
            defectDetails && defectDetails[item.label] ? defectDetails[item.label].split(':') : ['', ''];
          setDefaultSelectedModule([{ key: 'key', value: defaultCheckedNodeId || '' }]);
          tempInitialValues[item.label] = defectDetails?.[item?.label] || '';
        }
      } else if ([CHECKBOX, ATTACHMENT].includes(item?.type)) {
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        } else {
          tempInitialValues[item.label] = [];
        }
      } else if ([TEXTFIELD, TEXTBOX].includes(item?.type)) {
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        } else {
          tempInitialValues[item.label] = item?.defaultValue || '';
        }
      } else if (item?.type === DATE) {
        if (defectDetails && defectDetails[item.label]?.length > 0) {
          if (['Created On', 'Modified On'].includes(item.label)) {
            tempInitialValues[item.label] = defectDetails[item.label];
          } else {
            const myDate = moment(
              defectDetails[item.label],
              item?.dateFormat && typeof item.dateFormat === 'string' ? item?.dateFormat?.toUpperCase() : 'DD-MM-YYYY'
            ).toDate();
            if (myDate instanceof Date && !isNaN(myDate)) {
              tempInitialValues[item.label] = myDate;
            } else {
              tempInitialValues[item.label] = '';
            }
          }
        } else {
          tempInitialValues[item.label] = '';
        }
      } else {
        tempInitialValues[item.label] = (defectDetails && defectDetails[item.label]) || '';
      }
    });
    setInitialValues(tempInitialValues);
    setDefectTemplateFields(defectTemplateData);
    const yepSchema = defectTemplateData.reduce(createYupSchema, {});
    tempValidateSchema = yup.object().shape(yepSchema);
    setValidateSchema(tempValidateSchema);
  }

  const handleOnSubmit = async (values) => {
    const formData = new FormData();
    const data = {
      defectTemplateId: defectMgmtData?.defect_details_templates[0]?._id,
      defectLifeCycleTemplateId: defectMgmtData?.defect_life_cycle_templates[0]?._id,
      projectLabels: projectLabelsObject,
      scriptRunDetails: failedStepsData?.scriptRunDetails,
    };
    const formValues = { ...values };
    if (drawerType === ADD) {
      formValues['Created By'] = {};
      formValues['Modified By'] = {};
    }
    let a = 0;
    projectUsers.forEach((user) => {
      if (user?.id === formValues['Assign to']) {
        formValues['Assign to'] = user;
        a++;
      }
    });
    if (a === 0) {
      formValues['Assign to'] = {};
    }
    if (!formValues['Assign to']) {
      formValues['Assign to'] = {};
    }
    data.state_transitions = defectMgmtData?.defect_life_cycle_templates[0]?.state_transitions;
    defectMgmtData?.defect_details_templates[0]?.defectDetails?.forEach((data) => {
      if (data) {
        if (
          formValues.hasOwnProperty(data.label) &&
          formValues[data.label] &&
          data.type === 'date' &&
          !['Created On', 'Modified On'].includes(data.label)
        ) {
          formValues[data.label] = moment(formValues[data.label]).format(
            data.dateFormat && typeof data.dateFormat === 'string' ? data.dateFormat.toUpperCase() : 'DD-MM-YYYY'
          );
        }
      }
    });
    data.defectDetails = formValues;
    data.state = formValues['State'];
    formData.append('data', JSON.stringify(data));
    newFilesToBeUploaded.forEach((data) => {
      formData.append('file', data?.file);
    });

    try {
      let response;
      setIsLoading(true);
      if (drawerType === ADD) {
        try {
          response = await createDefectReq(project?.id, formData, 'Defects');
          if (response?.data?.responseCode === 507) {
            MyAlert.info(response?.data?.message);
            setIsLoading(false);
            return;
          }
        } catch (error) {
          MyAlert.info(`Failed to upload File(s)`);
        }
      } else {
        formValues['Comments'] = comments;
        const fileIds = [];
        deletedFiles.forEach((fileData) => {
          if (fileData?.id) {
            fileIds.push(fileData.id);
          }
        });

        if (fileIds?.length > 0) {
          await deleteMultipleFilesReq(project?.id, fileIds);
        }
        try {
          response = await updateDefectReq(project?.id, formData, selectedDefectData?.defectDetails?.ID);
          if (response?.data?.responseCode === 507) {
            MyAlert.info(response?.data?.message);
            setIsLoading(false);
            return;
          }
        } catch (error) {
          MyAlert.info(`Failed to upload File(s)`);
        }
      }
      if (response?.data?.responseCode === 200) {
        if (drawerType === EDIT) {
          closeDrawer();
          props.MyAlert.success(selectedDefectData?.defectDetails?.ID + ' ' + UPDATE_SUCCESS);
        }
        if (isCreateAnotherDefect) {
          setResetLabels(true);
          MyAlert.success(`${response?.data?.responseObject?.id} ${CREATE_SUCCESS}`);
          setAnotherDefectCheck(true);
          formik.resetForm();
          reloadTable(true);
        } else if (drawerType === ADD) {
          props.MyAlert.success(`${response?.data?.responseObject?.id} ${CREATE_SUCCESS}`);
          closeDrawer(true);
          reloadTable(true);
        }
        tempInitialValues = {};
        tempValidateSchema = '';
        setSelectedDefectData({});
        initDefectData();
        reloadHandler(
          response?.data?.responseMap?.totalDefectsCount,
          response?.data?.responseObject,
          selectedDefectData?.defectDetails?.ID
        );
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(CREATE_DEFECT_ERR, err);
    }
  };
  const deleteFile = (file) => {
    let files = tempUploadFiles;
    files = files.filter((fileVal) => {
      return file?.name !== fileVal?.name;
    });
    setTempUploadFiles(files);
    let newFilesToBeUploadedCopy = [...newFilesToBeUploaded];
    newFilesToBeUploadedCopy = newFilesToBeUploadedCopy.filter((fileVal) => {
      return file?.name !== fileVal?.name;
    });
    setNewFilesToBeUploaded(newFilesToBeUploadedCopy);
    const tempDeletedFiles = [...deletedFiles];
    tempDeletedFiles.push(file);
    setDeletedFiles(tempDeletedFiles);
  };

  const validateFilesSize = (existingFiles, newUploadedFiles) => {
    let fileSizeInBytes = 0;
    for (let i = 0; i < existingFiles?.length; i++) {
      fileSizeInBytes += existingFiles[i]?.sizeInBytes;
    }
    for (let i = 0; i < newUploadedFiles?.length; i++) {
      fileSizeInBytes += newUploadedFiles[i]?.size;
    }
    return fileSizeInBytes <= 31457280;
  };
  const validateFileAlreadyExists = (newUploadedFiles, files) => {
    for (let i = 0; i < newUploadedFiles.length; i++) {
      for (let j = 0; j < files.length; j++) {
        if (files[j].name === newUploadedFiles[i].name) {
          return true;
        }
      }
    }
    return false;
  };
  const onLoad = (event, data, setFieldValue, value) => {
    let allAtachmentValue = [];
    defectTemplateFields.forEach((el) => {
      if (el.type === 'attachment') {
        allAtachmentValue.push(...formik?.values[el.id]);
      }
    });
    const allFiles = tempUploadFiles;
    const existingFiles = [...value];
    const newUploadedFiles = event?.target?.files;
    const newFilesToBeUploadedCopy = [...newFilesToBeUploaded];
    if (existingFiles?.length + newUploadedFiles?.length > MAX_FILE_UPLOAD_COUNT) {
      MyAlert.info(MAX_NO_OF_FILES_ALLOWED);
      event.target.value = '';
      return;
    }

    const existPlusNew = [...allAtachmentValue, ...existingFiles];
    if (validateFileAlreadyExists(newUploadedFiles, existPlusNew) && !anotherDefectCheck) {
      MyAlert.info(DUPLICATE_FILE);
      event.target.value = '';
      return;
    }

    if (!validateFilesSize(existingFiles, newUploadedFiles)) {
      MyAlert.info(MAX_FILE_SIZE_ALLOWED);
      event.target.value = '';
      return;
    }
    let file;
    let unsupportedFile = [];
    for (let i = 0; i < newUploadedFiles.length; i++) {
      file = newUploadedFiles[i];
      if (file && validateFileExtension(newUploadedFiles[i], DEFECT_ATTACTMENT_FILE_ALLOWED)) {
        const obj = {
          name: file?.name,
          sizeInBytes: file?.size,
        };
        for (let j = 0; j < existPlusNew.length; j++) {
          if (existPlusNew[j].name === file?.name) {
            MyAlert.info(DUPLICATE_FILE);
            return;
          }
        }
        allFiles.push(obj);
        existingFiles.push(obj);
        newFilesToBeUploadedCopy.push({
          name: file?.name,
          sizeInBytes: file?.size,
          file: file,
        });
      } else {
        unsupportedFile.push(newUploadedFiles[i]);
      }
    }
    if (!isEmptyValue(unsupportedFile)) {
      MyAlert.warning(`${unsupportedFile?.length} unsupported file. Please check the file format.`);
    }
    setFieldValue(data?.label, existingFiles);
    setTempUploadFiles(allFiles);
    setNewFilesToBeUploaded(newFilesToBeUploadedCopy);

    event.target.value = '';
  };

  useEffect(() => {
    if (drawerType === EDIT) {
      getAllCommentsAPI();
    }
  }, []);

  let getAllCommentsAPI = () => {
    getAllComments(selectedDefectData?.defectDetails?.ID).then((data) => {
      setComments(data.data.responseObject);
    });
  };

  useEffect(() => {
    if (currentComment.trim().length === 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [currentComment]);

  function projectLabelsArray() {
    let projectOptionValues = [];
    selectedDefectData?.projectLabels?.forEach((labelName) => {
      projectOptionValues.push(labelName?.name);
    });
    return projectOptionValues;
  }

  const defectMgmtDetailElements = (data, props, error, index) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="flex">
            {data?.label && <Label label={data?.label} required={data?.mandatory === YES} className="truncate" />}
          </div>
          <div className="contentDataStyle mt-1">
            {drawerType === EDIT && data?.label === 'Comments' ? (
              <>
                <div className="comment-area">
                  <div className="initial-comment">
                    <CommentForm
                      id={'currentUser'}
                      getData={SetCurrentComment}
                      isReset={reset}
                      setReset={setReset}
                      placeholder={'Add a comment....'}
                      projectUsers={projectUsers}
                    />
                    <div className="commentbox-footer">
                      <button
                        type="button"
                        className="primary-btn"
                        onClick={() => handleClick()}
                        {...(isDisabled ? { disabled: 'disabled' } : '')}
                      >
                        {' '}
                        Comment{' '}
                      </button>
                    </div>
                  </div>
                  <div className="comment-section">
                    <commentContext.Provider>
                      {comments?.map((comment, index) => {
                        return (
                          <Comment
                            key={index}
                            onDelete={deleteComment}
                            id={index}
                            data={comment}
                            commentsArray={comments}
                            onAddComment={setComments}
                            onUpdateComment={updateComment}
                            onUpdateReplyComment={updateReplyComment}
                            onDelteReplyComment={deleteReplyComment}
                            onDeleteThreadComment={deleteThreadComment}
                            onUpdateThreadComment={updateThreadComment}
                            defectId={selectedDefectData?.defectDetails?.ID}
                            projectUsers={projectUsers}
                            dataPassToParent={dataPassToParent}
                            loginUserName={loginUserName}
                          />
                        );
                      })}
                    </commentContext.Provider>
                  </div>
                </div>
              </>
            ) : data?.label === 'Labels' ? (
              <MultiAutocomplete
                selectedOptionsValue={drawerType === EDIT ? projectLabelsArray() : []}
                labelSelectedOptions={labelDataPassToParent}
                labelResponse={setLabelResponseObject}
                defects={true}
                MyAlert={MyAlert}
                resetLabels={resetLabels}
                history={history}
                cleanUpFunction={cleanUpFunction}
                setResetLabels={setResetLabels}
              />
            ) : (
              <DefectIssueFields
                data={data}
                formikProps={props}
                error={error}
                formik={formik}
                deleteFile={deleteFile}
                onLoad={onLoad}
                drawerType={drawerType}
                moduleTree={moduleTree}
                selectedModule={selectedModule}
                defaultSelectedModule={defaultSelectedModule}
                moduleLevelScriptCount={moduleLevelScriptCount}
              />
            )}
          </div>
        </div>
      </>
    );
  };

  const closeDrawerHandler = () => {
    tempInitialValues = {};
    tempValidateSchema = '';
    if (setSelectedDefectData) {
      setSelectedDefectData({});
    }
    initDefectData();
    closeDrawer(false);
  };
  const renderFooterContent = () => {
    return (
      <div className="flex flex-row justify-between -mt-3">
        <div className="">
          {drawerType === ADD && (
            <span>
              <input
                type="checkbox"
                name="createDefect"
                id="createDefect"
                checked={isCreateAnotherDefect}
                className="rounded"
                onChange={() => setIsCreateAnotherDefect(!isCreateAnotherDefect)}
              />
              <Label
                label={CREATE_ANOTHER_DEFECT}
                required={false}
                fontClass={'fontPoppinsRegularSm'}
                className="ml-2"
                htmlFor="createDefect"
              />
            </span>
          )}
        </div>
        <div className="float-right flex justify-between">
          <div>
            <CommonButton btnType="secondary" label={DEFECT_CONSTANTS.CANCEL} onClick={closeDrawerHandler} />
          </div>
          <div className="ml-4">
            <CommonButton
              disabled={isLoading}
              label={drawerType === ADD ? DEFECT_CONSTANTS.CREATE_ISSUE : DEFECT_CONSTANTS.UPDATE_ISSUE}
              type="submit"
              form={FIREFLINK_ISSUE_DRAWER.FORM_ID}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <CommonDrawer
      isDrawerOpen={openDrawer}
      titleText={FIREFLINK_ISSUE_DRAWER.FORM_LABEL}
      drawerWidth={DEFECT_CONSTANTS.DRAWER_WIDTH}
      footerContent={renderFooterContent()}
      onDrawerClose={closeDrawerHandler}
      backButton={props?.backButtonRequired}
      onBackButtonClick={closeDrawerHandler}
    >
      <form id={FIREFLINK_ISSUE_DRAWER.FORM_ID} onSubmit={formik.handleSubmit}>
        <div className="modal-body" id="journal-scroll">
          <div className={styles['alert-custom-style']}>
            <AlertContatiner></AlertContatiner>
          </div>
          <div className="shadow-none p-4 justify-center items-center">
            {
              <Grid container columnSpacing={{ xs: 2, md: 3, sm: 2 }} rowSpacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
                {defectTemplateFields?.length > 0 &&
                  defectTemplateFields?.map((data, index) => {
                    let error = formik.errors.hasOwnProperty(data.id) && formik.errors[data.id];
                    return ['ID', 'Modified By', 'Created By', 'Created On', 'Modified On'].includes(data?.label) ||
                      (drawerType === ADD && data?.label === 'Comments') ? (
                      <></>
                    ) : (
                      <Grid item xs={4} sm={8} md={12} key={data?.id}>
                        {defectMgmtDetailElements(data, formik, error, index)}
                      </Grid>
                    );
                  })}
              </Grid>
            }
          </div>
        </div>
      </form>
    </CommonDrawer>
  );
};

export default FireFlinkIssueDrawer;
