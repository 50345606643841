import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styles from '@pages/configuration/DefectConfig/defect-config.module.scss';
import CommonDrawer from '@src/common/atoms/CommonDrawer';
import { DEFECT_CONSTANTS } from '@src/common/ui-constants';
import {
  createGitlabIssueReq,
  getGitlabMetaDataReq,
  getGitlabProjectsReq,
  getAllModuleTreeReq,
} from '@api/api_services';
import SelectBox from '@src/common/atoms/SelectComponent';
import { Label } from '@src/common/atoms/LabelComponent';
import CommonButton from '@src/common/button/Button';
import { GITLAB_ISSUE_DRAWER, JIRA_ISSUE_DRAWER } from '@pages/configuration/DefectConfig/defect-constants';
import { FORM_FIELDS, FORM_FIELDS_ARRAY, MESSAGES } from '@pages/defects/defectMgmtConstants';
import { DefectIssueFields } from './FireFlinkIssueDrawerFields';
import { checkValidationForAlphanumericSpecialCharacters, TooltipPoppin } from '@pages/analytics/common/util';
import moment from 'moment';
import { getDefectDescription } from '@src/pages/defects/defect-utils';
import { useAlert } from '@src/pages/common/alert_service/useAlert';
import MultiAutocomplete from '@src/pages/test-development/script/modules/module/modals/labels-multiselect-dropdown';
import { ReactComponent as InfoIcon } from '@assets/InfoIcon.svg';
import { DEFECT_ATTACTMENT_FILE_ALLOWED, UI_VALIDATIONS } from '@src/util/validations';
import { isEmptyValue, validateFileExtension } from '@src/util/common_utils';

const { TEXTFIELD, TEXTBOX, CHECKBOX, LINK, ATTACHMENT, DATE } = FORM_FIELDS;

const GitlabIssueDrawer = (props) => {
  const {
    closeDrawer,
    openDrawer,
    allInstances,
    reloadTable,
    failedStepsData,
    drawerType,
    defectMgmtData,
    selectedDefectData,
    selectedModule,
    setSelectedDefectData,
    cleanUpFunction,
    history,
    reloadHandler = () => {},
    initDefectData = () => {},
  } = props;
  const {
    CREATE_SUCCESS,
    UPDATE_SUCCESS,
    ALPHA_ERR_MSG,
    CREATE_DEFECT_ERR,
    REQUIRED_ERR_MSG,
    NO_SPACE_AT_START_AND_END_MSG,
    YES,
    VALID_URL,
    MAX_NO_OF_FILES_ALLOWED,
    DUPLICATE_FILE,
    MAX_FILE_SIZE_ALLOWED,
    CREATE_ANOTHER_DEFECT,
    ADD,
    EDIT,
    NO,
  } = MESSAGES;

  const { MAX_FILE_UPLOAD_COUNT } = UI_VALIDATIONS;

  const { steps, envDetails, isFromSteps } = failedStepsData || {};
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBtn, setisLoadingBtn] = useState(false);
  const [resetLabels, setResetLabels] = useState(false);
  const [labelResponseObject, setLabelResponseObject] = useState([]);

  const [labelSelectedOptions, setLabelSelectedOptions] = useState([]);
  let tempInitialValues = {};
  let tempValidateSchema = '';
  const projectLabelsObject = [];
  const instances = useMemo(
    () =>
      allInstances.map((instance) => ({
        ...instance,
        label: instance.name,
        value: instance.name,
      })),
    [allInstances]
  );
  const [selectedInstance, setSelectedInstance] = useState([]);
  const { AlertContatiner, MyAlert } = useAlert();
  const [defectDetails, setDefectDetails] = useState([]);
  const [moduleTree, setModuleTree] = useState([]);
  const [moduleLevelScriptCount, setModuleLevelScriptCount] = useState(0);
  const [initialValues, setInitialValues] = useState({});
  const [defaultSelectedModule, setDefaultSelectedModule] = useState([]);
  const [validateSchema, setValidateSchema] = useState({});
  const [newFilesToBeUploaded, setNewFilesToBeUploaded] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [tempUploadFiles, setTempUploadFiles] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);

  const [assignees, setAssignees] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState([]);

  const [milestones, setMilestones] = useState([]);
  const [selectedMilestone, setSelectedMilestone] = useState([]);

  const [labels, setLabels] = useState([]);

  useEffect(() => {
    (async function () {
      try {
        const response = await getAllModuleTreeReq(false);
        if (response?.data?.responseObject?.moduleTree?.length) {
          setModuleTree(response.data.responseObject.moduleTree);
          setModuleLevelScriptCount(response?.data?.responseObject?.moduleLevelScriptCount);
        } else {
          setModuleTree([]);
        }
      } catch (err) {
        console.error('GET_MODULE_TREE :', err);
      }
    })();
  }, []);
  const [personalToken, setPersonalToken] = useState('');
  const handleSearchableSelectOnChange = (value, label) => {
    setSelectedProject([]);
    setSelectedMilestone([]);
    formik.setFieldValue('Project', '');
    formik.setFieldTouched(label, true);
    if (value?.length) {
      const fieldValueToUpdate = value[0]?.label;
      formik.setFieldValue(label, fieldValueToUpdate);
      setSelectedInstance(value[0]?.personalToken);
    } else {
      formik.setFieldValue(label, '');
    }
    const _personalToken = value.map((val) => val?.personalToken);
    setPersonalToken(_personalToken);

    if (_personalToken) {
      getAllProjects(_personalToken);
    }
  };
  const getAllProjects = async (personalToken) => {
    try {
      setIsLoading(true);
      const response = await getGitlabProjectsReq(personalToken);
      if (response?.data?.responseObject?.length) {
        setProjects([
          ...response?.data?.responseObject.map((project) => ({
            ...project,
            label: project.name_with_namespace,
            value: project.name_with_namespace,
          })),
        ]);
      } else {
        setProjects([]);
      }
      setIsLoading(false);
    } catch (err) {
      console.error('GET_ALL_PROJECTS : ', err);
    }
  };

  const getAllGitLabData = async () => {
    try {
      setIsLoading(true);
      const response = await getGitlabMetaDataReq(personalToken, selectedProject[0].id);
      if (response?.data?.responseObject) {
        const mileStonesTemp = response.data.responseObject?.milestones?.filter(
          (mileStone) => mileStone.state !== 'closed'
        );
        const assigneeTemp = response.data.responseObject?.users;
        const labelsTemp = response.data.responseObject?.labels;
        if (mileStonesTemp?.length) {
          setMilestones([
            ...mileStonesTemp.map((milestone) => ({
              ...milestone,
              label: milestone.title,
              value: milestone.title,
            })),
          ]);
        } else {
          setMilestones([]);
        }
        if (labelsTemp?.length) {
          setLabels([
            ...labelsTemp.map((label) => ({
              ...label,
              label: label.name,
              value: label.name,
            })),
          ]);
        } else {
          setLabels([]);
        }
        if (assigneeTemp?.length) {
          const updatedAssigneeList = [
            ...assigneeTemp.map((label) => ({
              ...label,
              label: label.name,
              value: label.name,
            })),
          ];
          setAssignees([...updatedAssigneeList]);
          handleOnChangeForAssignee([updatedAssigneeList[0]], 'Assign to');
        } else {
          setAssignees([]);
        }
      } else {
        setProjects([]);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error('GET_ALL_GITLAB_DATA : ', err);
    }
  };

  useEffect(() => {
    if (selectedProject.length) {
      getAllGitLabData();
    }
  }, [selectedProject]);

  const renderFooterContent = () => {
    return (
      <div className="flex flex-row justify-end -mt-3">
        <div>
          <CommonButton btnType="secondary" label={DEFECT_CONSTANTS.CANCEL} onClick={closeDrawer} />
        </div>
        <div className="ml-4">
          <CommonButton
            disabled={isLoading || isLoadingBtn}
            label={DEFECT_CONSTANTS.CREATE_ISSUE}
            type="submit"
            form={GITLAB_ISSUE_DRAWER.FORM_ID}
          />
        </div>
      </div>
    );
  };
  const sortBasedOnOrder = (defectTemplateData) => {
    return defectTemplateData?.sort((a, b) => {
      return a?.order - b?.order;
    });
  };

  useEffect(() => {
    createValidationSchema();
  }, []);
  function createYupSchema(schema, config) {
    const { id, validationType, validations = [] } = config;
    if (!yup[validationType]) {
      return schema;
    }
    let validator = yup[validationType]();
    validations.forEach((validation) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });
    schema[id] = validator;
    return schema;
  }

  const createValidationSchema = (allUsers) => {
    let defectTemplateData = JSON.parse(
      JSON.stringify(sortBasedOnOrder(defectMgmtData?.defect_details_templates[0]?.defectDetails))
    );
    for (let i = 0; i < defectTemplateData?.length; i++) {
      let element = defectTemplateData[i];
      let validations = [];
      if (element?.label === 'State') {
        element.enable = NO;
      }

      if (FORM_FIELDS_ARRAY.includes(element?.type)) {
        if ([CHECKBOX, ATTACHMENT].includes(element.type)) {
          element.validationType = 'array';
        } else if (element?.type === DATE) {
          if (['Created On', 'Modified On'].includes(element?.label)) {
            element.validationType = 'string';
          } else {
            element.validationType = DATE;
          }
        } else if (['Modified By', 'Created By'].includes(element?.label)) {
          element.validationType = 'object';
        } else {
          element.validationType = 'string';
        }
      }
      if ([TEXTFIELD, TEXTBOX].includes(element.type)) {
        validations.push(
          {
            type: 'trim',
            params: [NO_SPACE_AT_START_AND_END_MSG],
          },
          {
            type: 'strict',
            params: [true],
          }
        );

        if (element.type === TEXTFIELD) {
          validations.push({
            type: 'matches',
            params: [checkValidationForAlphanumericSpecialCharacters, ALPHA_ERR_MSG],
          });
        }

        if (element?.label === 'Summary') {
          validations.push(
            {
              type: 'max',
              params: [250, `${element?.label} ${DEFECT_CONSTANTS.MAX_CHARACTER_VALIDATION}`],
            },
            {
              type: 'min',
              params: [3, `${element?.label} ${DEFECT_CONSTANTS.MIN_CHARACTER_VALIDATION}`],
            }
          );
        }
      }
      if (element?.minLength) {
        let validationObj = {
          type: 'min',
          params: [element.minLength, `Input cannot be less than ${element.minLength} characters`],
        };
        validations.push(validationObj);
      }
      if (element?.maxLength) {
        let validationObj = {
          type: 'max',
          params: [element.maxLength, `Input cannot be more than ${element.maxLength} characters`],
        };
        validations.push(validationObj);
      }
      if (element?.mandatory === YES) {
        let validationObj = {
          type: 'required',
          params: [REQUIRED_ERR_MSG(element.label)],
        };
        validations.push(validationObj);

        if ([CHECKBOX, ATTACHMENT].includes(element.type)) {
          let validationObj = {
            type: 'min',
            params: [1, REQUIRED_ERR_MSG(element.label)],
          };
          validations.push(validationObj);
        }
      }
      if (element.type === LINK) {
        let validationObj = {
          type: 'url',
          params: [VALID_URL],
        };
        validations.push(validationObj);
      }
      element.validations = validations;
      element.id = element.label;
    }
    createFormikSchema(defectTemplateData, allUsers);
  };

  function createFormikSchema(defectTemplateData, allUsers) {
    const { defectDetails } = selectedDefectData;
    defectTemplateData.forEach((item) => {
      if (item?.label === 'State') {
        if (drawerType === ADD) {
          item.options = defectMgmtData?.defect_life_cycle_templates[0]?.states || [];
        }
        if (drawerType === ADD) {
          tempInitialValues[item.label] = item?.options?.[0] || '';
        }
      } else if (item?.label === 'Instances') {
        item.options = [...instances];
        tempInitialValues[item.label] = '';
      } else if (item?.label?.toLowerCase() === 'description') {
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        } else if (steps || envDetails) {
          tempInitialValues[item.label] = `{"blocks":${getDefectDescription(
            steps,
            envDetails,
            isFromSteps
          )},"entityMap":{}}`;
        } else {
          tempInitialValues[item.label] = '';
        }
      } else if (item?.label?.toLowerCase() === 'module') {
        if (selectedModule) {
          const [defaultCheckedNodeName, defaultCheckedNodeId] = selectedModule.split(':');
          setDefaultSelectedModule([{ key: 'key', value: defaultCheckedNodeId || '' }]);
          tempInitialValues[item.label] = selectedModule || '';
        } else {
          const [defaultCheckedNodeName, defaultCheckedNodeId] =
            defectDetails && defectDetails[item.label] ? defectDetails[item.label].split(':') : ['', ''];
          setDefaultSelectedModule([{ key: 'key', value: defaultCheckedNodeId || '' }]);
          tempInitialValues[item.label] = defectDetails?.[item?.label] || '';
        }
      } else if ([CHECKBOX, ATTACHMENT].includes(item?.type)) {
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        } else {
          tempInitialValues[item.label] = [];
        }
      } else if ([TEXTFIELD, TEXTBOX].includes(item?.type)) {
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        } else {
          tempInitialValues[item.label] = item?.defaultValue || '';
        }
      } else if (item?.type === DATE) {
        if (defectDetails && defectDetails[item.label]?.length > 0) {
          if (['Created On', 'Modified On'].includes(item.label)) {
            tempInitialValues[item.label] = defectDetails[item.label];
          } else {
            const myDate = moment(
              defectDetails[item.label],
              item?.dateFormat && typeof item.dateFormat === 'string' ? item?.dateFormat?.toUpperCase() : 'DD-MM-YYYY'
            ).toDate();
            if (myDate instanceof Date && !isNaN(myDate)) {
              tempInitialValues[item.label] = myDate;
            } else {
              tempInitialValues[item.label] = '';
            }
          }
        } else {
          tempInitialValues[item.label] = '';
        }
      } else {
        tempInitialValues[item.label] = (defectDetails && defectDetails[item.label]) || '';
      }
    });

    setInitialValues(tempInitialValues);
    setDefectDetails(defectTemplateData);
    const yepSchema = defectTemplateData.reduce(createYupSchema, {});
    tempValidateSchema = yup.object().shape(yepSchema);
    setValidateSchema(tempValidateSchema);
  }

  const validateFileAlreadyExists = (newUploadedFiles, files) => {
    for (let i = 0; i < newUploadedFiles.length; i++) {
      for (let j = 0; j < files.length; j++) {
        if (files[j].name === newUploadedFiles[i].name) {
          return true;
        }
      }
    }
    return false;
  };
  const validateFilesSize = (existingFiles, newUploadedFiles) => {
    let fileSizeInBytes = 0;
    for (let i = 0; i < existingFiles?.length; i++) {
      fileSizeInBytes += existingFiles[i]?.sizeInBytes;
    }
    for (let i = 0; i < newUploadedFiles?.length; i++) {
      fileSizeInBytes += newUploadedFiles[i]?.size;
    }
    return fileSizeInBytes <= 31457280;
  };
  const onLoad = (event, data, setFieldValue, value) => {
    let allAtachmentValue = [];
    defectDetails.forEach((el) => {
      if (el.type === 'attachment') {
        allAtachmentValue.push(...formik?.values[el.id]);
      }
    });
    const allFiles = tempUploadFiles;
    const existingFiles = [...value];
    const newUploadedFiles = event?.target?.files;
    const newFilesToBeUploadedCopy = [...newFilesToBeUploaded];
    if (existingFiles?.length + newUploadedFiles?.length > MAX_FILE_UPLOAD_COUNT) {
      MyAlert.info(MAX_NO_OF_FILES_ALLOWED);
      event.target.value = '';
      return;
    }

    const existPlusNew = [...allAtachmentValue, ...existingFiles];
    if (validateFileAlreadyExists(newUploadedFiles, existPlusNew)) {
      MyAlert.info(DUPLICATE_FILE);
      event.target.value = '';
      return;
    }

    if (!validateFilesSize(existingFiles, newUploadedFiles)) {
      MyAlert.info(MAX_FILE_SIZE_ALLOWED);
      event.target.value = '';
      return;
    }
    let file;
    let unsupportedFile = [];
    for (let i = 0; i < newUploadedFiles.length; i++) {
      file = newUploadedFiles[i];
      if (file && validateFileExtension(newUploadedFiles[i], DEFECT_ATTACTMENT_FILE_ALLOWED)) {
        const obj = {
          name: file?.name,
          sizeInBytes: file?.size,
        };
        for (let j = 0; j < existPlusNew.length; j++) {
          if (existPlusNew[j].name === file?.name) {
            MyAlert.info(DUPLICATE_FILE);
            return;
          }
        }
        allFiles.push(obj);
        existingFiles.push(obj);
        newFilesToBeUploadedCopy.push({
          name: file?.name,
          sizeInBytes: file?.size,
          file: file,
        });
      } else {
        unsupportedFile.push(newUploadedFiles[i]);
      }
    }

    if (!isEmptyValue(unsupportedFile)) {
      MyAlert.warning(`${unsupportedFile?.length} unsupported file. Please check the file format.`);
    }
    setFieldValue(data?.label, existingFiles);
    setTempUploadFiles(allFiles);
    setNewFilesToBeUploaded(newFilesToBeUploadedCopy);

    event.target.value = '';
  };

  const deleteFile = (file) => {
    let files = tempUploadFiles;
    files = files.filter((fileVal) => {
      return file?.name !== fileVal?.name;
    });
    setTempUploadFiles(files);
    let newFilesToBeUploadedCopy = [...newFilesToBeUploaded];
    newFilesToBeUploadedCopy = newFilesToBeUploadedCopy.filter((fileVal) => {
      return file?.name !== fileVal?.name;
    });
    setNewFilesToBeUploaded(newFilesToBeUploadedCopy);
    const tempDeletedFiles = [...deletedFiles];
    tempDeletedFiles.push(file);
    setDeletedFiles(tempDeletedFiles);
  };

  function formatInput(input) {
    const parsedInput = JSON.parse(input);

    let output = '';
    let stepsStarted = false;
    let stepCounter = 1;

    parsedInput.blocks.forEach((block, index) => {
      let text = block.text.replace(/:/g, '');

      if (text.startsWith('Steps to reproduce')) {
        stepsStarted = true;
        output += `${text}\n`;
      } else if (stepsStarted && !text.startsWith('Environment Details')) {
        output += `${stepCounter}. ${text}\n`;
        stepCounter++;
      } else {
        if (text.startsWith('Environment Details')) {
          output += `\n${text}\n`;
        } else {
          output += `${text}\n`;
        }
        stepsStarted = false;
      }
    });

    return output;
  }

  const handleOnSubmit = async (values) => {
    const formData = new FormData();
    const formValues = { ...values };
    const data = {
      defectTemplateId: defectMgmtData?.defect_details_templates[0]?._id,
      defectLifeCycleTemplateId: defectMgmtData?.defect_life_cycle_templates[0]?._id,

      scriptRunDetails: failedStepsData?.scriptRunDetails,
      project: selectedProject[0]?.key,
      instanceName: values.Instances,
      type: 'ISSUE',

      summary: values.Summary,
      Severity: values.Severity,
      Priority: values.Priority,

      description: formatInput(formValues.Description),
    };

    data.labels = projectLabelsObject.map((label) => label.name);

    delete formValues.Project;
    delete formValues.setLabelResponseObject;

    if (drawerType === ADD) {
      formValues['Created By'] = {};
      formValues['Modified By'] = {};
    }

    data['Assign to'] = assignees[0].name;
    data.state = formValues['State'];
    formData.append('data', JSON.stringify(data));
    newFilesToBeUploaded.forEach((data) => {
      formData.append('file', data?.file);
    });

    try {
      let response;
      setIsLoading(true);
      if (drawerType === ADD) {
        try {
          response = await createGitlabIssueReq(
            personalToken,
            selectedProject[0]?.id,
            selectedAssignee[0]?.id,
            milestones[0]?.id,
            formData,
            'Defects'
          );
        } catch (error) {
          MyAlert.info(`Failed to upload File(s)`);
        }
      } else {
        const fileIds = [];
        deletedFiles.forEach((fileData) => {
          if (fileData?.id) {
            fileIds.push(fileData.id);
          }
        });
      }

      if (response?.data?.responseCode === 201) {
        if (drawerType === ADD) {
          props.MyAlert.success(`${response?.data?.responseObject?.defectId} ${CREATE_SUCCESS}`);
          closeDrawer(true);
          reloadTable(true);
        }
        tempInitialValues = {};
        tempValidateSchema = '';
        setSelectedDefectData({});
        initDefectData();
        reloadHandler(
          response?.data?.responseMap?.totalDefectsCount,
          response?.data?.responseObject,
          selectedDefectData?.defectDetails?.ID
        );
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(CREATE_DEFECT_ERR, err);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validateSchema,
    onSubmit: async (values) => {
      await handleOnSubmit(values);
    },
  });
  const handleOnChangeForProject = (value, label) => {
    setSelectedAssignee([]);
    setSelectedProject(value ? value : []);
    setSelectedMilestone([]);
    formik.setFieldValue(label.toLowerCase() === 'mileStone', '');

    formik.setFieldTouched(label, true);
    if (value?.length) {
      const fieldValueToUpdate = value[0]?.label;
      formik.setFieldValue(label, fieldValueToUpdate);
    } else {
      formik.setFieldValue(label, '');
    }
  };
  const handleOnChangeForAssignee = (value, label) => {
    setSelectedAssignee(value ? value : []);
    formik.setFieldTouched(label, true);
    if (value?.length) {
      const fieldValueToUpdate = value[0]?.accountId;
      formik.setFieldValue(label, fieldValueToUpdate);
    } else {
      formik.setFieldValue(label, '');
    }
  };

  const handleOnChangeForMileStone = (value, label) => {
    setSelectedMilestone(value ? value : []);

    formik.setFieldTouched(label, true);
    if (value?.length) {
      const fieldValueToUpdate = value[0]?.label;
      formik.setFieldValue(label, fieldValueToUpdate);
    } else {
      formik.setFieldValue(label, '');
    }
  };

  const labelDataPassToParent = (labelData) => {
    setLabelSelectedOptions(labelData);
  };
  labelSelectedOptions?.forEach((labelValue) => {
    labelResponseObject?.forEach((objectValue) => {
      if (objectValue.name === labelValue) {
        projectLabelsObject.push(objectValue);
      }
    });
  });
  const shouldRenderData = (data) => {
    const forbiddenLabels = ['instances', 'project', 'assign to', 'labels', 'milestone'];
    return !forbiddenLabels.includes(data?.label.toLowerCase()) && data;
  };
  const defectMgmtDetailElements = (data, props, error, index) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="flex">
            {data?.label && (
              <>
                <Label
                  label={data?.label}
                  required={data?.mandatory === 'yes'}
                  className={
                    data?.label.toLowerCase() === 'labels'
                      ? 'ml-1 mt-4'
                      : data?.label.toLowerCase() !== 'instances'
                      ? 'truncate mt-4'
                      : ''
                  }
                />
                {data?.label === 'Summary' && (
                  <TooltipPoppin
                    className={styles['tooltip-style']}
                    title={JIRA_ISSUE_DRAWER.TEMPLATE_MISMATCH.GITLAB_TOOLTIP_INFO}
                  >
                    <span className="ml-1 mt-5">
                      <InfoIcon className={styles['info-icon-style']} />
                    </span>
                  </TooltipPoppin>
                )}
              </>
            )}
          </div>
          <div className="contentDataStyle mt-1 relative">
            {data?.label.toLowerCase() === 'instances' ? (
              <>
                <div className="">
                  <div variant="standard" className="w-full">
                    <div className="">
                      <SelectBox
                        showLabel={false}
                        name={data.id}
                        options={instances}
                        disabled={data?.enable === NO}
                        error={formik.errors[data.id] && formik.touched[data.id] ? true : false}
                        errMsg={error}
                        placeholder={data.placeholder ? data.placeholder : ''}
                        handleOptionChange={(value) => {
                          handleSearchableSelectOnChange(value, data?.label);
                        }}
                        selectedOption={[]}
                      />
                    </div>
                    {formik.errors[data.id] && formik.touched[data.id] && (
                      <p className={styles['errorText']}>{error}</p>
                    )}
                  </div>
                </div>
              </>
            ) : data?.label.toLowerCase() === 'project' ? (
              <>
                <div className="">
                  <div variant="standard" className="w-full">
                    <div className="">
                      <SelectBox
                        showLabel={false}
                        name={data.id}
                        options={projects}
                        disabled={data.enable === NO}
                        error={formik.errors[data.id] && formik.touched[data.id] ? true : false}
                        errMsg={error}
                        placeholder={data.placeholder ? data.placeholder : ''}
                        handleOptionChange={(value) => {
                          handleOnChangeForProject(value, data?.label);
                        }}
                        selectedOption={selectedProject}
                      />
                    </div>
                    {formik.errors[data.id] && formik.touched[data.id] && (
                      <p className={styles['errorText']}>{error}</p>
                    )}
                  </div>
                </div>
              </>
            ) : data?.label.toLowerCase() === 'labels' ? (
              <div className="-ml-1">
                <MultiAutocomplete
                  labelSelectedOptions={labelDataPassToParent}
                  labelResponse={setLabelResponseObject}
                  defects={true}
                  MyAlert={MyAlert}
                  resetLabels={resetLabels}
                  history={history}
                  cleanUpFunction={cleanUpFunction}
                />
              </div>
            ) : data?.label.toLowerCase() === 'assign to' ? (
              <>
                <div className="">
                  <div variant="standard" className="w-full">
                    <div className="">
                      <SelectBox
                        showLabel={false}
                        name={data.id}
                        options={assignees}
                        disabled={data.enable === NO}
                        error={formik.errors[data.id] && formik.touched[data.id] ? true : false}
                        errMsg={error}
                        placeholder={data.placeholder ? data.placeholder : ''}
                        handleOptionChange={(value) => {
                          handleOnChangeForAssignee(value, data?.label);
                        }}
                        selectedOption={selectedAssignee}
                      />
                    </div>
                    {formik.errors[data.id] && formik.touched[data.id] && (
                      <p className={styles['errorText']}>{error}</p>
                    )}
                  </div>
                </div>
              </>
            ) : (
              data?.label.toLowerCase() === 'milestone' && (
                <>
                  <div className="">
                    <div variant="standard" className="w-full">
                      <div className="">
                        <SelectBox
                          showLabel={false}
                          name={data.id}
                          options={milestones}
                          disabled={data.enable === NO}
                          error={formik.errors[data.id] && formik.touched[data.id] ? true : false}
                          errMsg={error}
                          placeholder={data.placeholder ? data.placeholder : ''}
                          handleOptionChange={(value) => {
                            handleOnChangeForMileStone(value, data?.label);
                          }}
                          selectedOption={selectedMilestone}
                        />
                      </div>
                      {formik.errors[data.id] && formik.touched[data.id] && (
                        <p className={styles['errorText']}>{error}</p>
                      )}
                    </div>
                  </div>
                </>
              )
            )}

            <DefectIssueFields
              data={shouldRenderData(data)}
              formikProps={props}
              error={error}
              formik={formik}
              deleteFile={deleteFile}
              onLoad={onLoad}
              drawerType={drawerType}
              moduleTree={moduleTree}
              selectedModule={selectedModule}
              defaultSelectedModule={defaultSelectedModule}
              moduleLevelScriptCount={moduleLevelScriptCount}
            />
          </div>
        </div>
      </>
    );
  };
  return (
    <CommonDrawer
      isDrawerOpen={openDrawer}
      titleText={GITLAB_ISSUE_DRAWER.FORM_LABEL}
      drawerWidth={DEFECT_CONSTANTS.DRAWER_WIDTH}
      footerContent={renderFooterContent()}
      onDrawerClose={closeDrawer}
      backButton={props?.backButtonRequired}
      onBackButtonClick={closeDrawer}
    >
      <form
        id={GITLAB_ISSUE_DRAWER.FORM_ID}
        onSubmit={(e) => {
          formik.handleSubmit(e);
        }}
      >
        <div className={styles['drawer-body-height']}>
          <div className={styles['alert-custom-style']}>
            <AlertContatiner></AlertContatiner>
          </div>

          <div className="p-4 justify-center items-center">
            {defectDetails?.length > 0 &&
              defectDetails?.map((data, index) => {
                let error = formik.errors.hasOwnProperty(data.id) && formik.errors[data.id];
                return ['ID', 'Modified By', 'Created By', 'Created On', 'Modified On', 'Comments'].includes(
                  data?.label
                ) ? (
                  <></>
                ) : (
                  <Grid item xs={4} sm={8} md={12} key={data?.id}>
                    {defectMgmtDetailElements(data, formik, error, index)}
                  </Grid>
                );
              })}
          </div>
        </div>
      </form>
    </CommonDrawer>
  );
};

export default GitlabIssueDrawer;
