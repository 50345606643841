import React, { useState, useContext, useRef, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Tooltip, Stack } from '@mui/material';
import { Modal, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FiMaximize2 } from 'react-icons/fi';
import CloseIcon from '@material-ui/icons/Close';
import cx from 'classnames';
import SuiteTree from '@pages/common/table_tree/Suite_Tree/SuiteTree';
import { getAllModuleTreeReq, getAllModuleTreeSuiteReq, getAnalyticsReq } from '@src/api/api_services';
import MoreInfoButton from '@pages/common/more_info_button';
import UserDetailsPage from '@pages/execution/common/user-details-page';
import RadioButton from '@pages/common/radio_button';
import { CommonLoader } from '@src/pages/common/common-loader';
import { ProgressContainerContext } from '@src/common/ProgressContainer';
import ProjectGlobalVariable from './project-global-variable';
import TestData from './test-data';
import EmailPage from './email-page';
import SuitePreview from '../suite-preview';
import SuiteRunSettings from '../suite-run-settings';
import { getLicenseFeatures, calculatedScriptNumber } from '@src/util/common_utils';
import TreeSearchBar from '@src/pages/common/table_tree/tree_searchbar';
import {
  getTruncatedText,
  isEmptyValue,
  trimModuleName,
  convertDateHyphenReverse,
  getVerifiedDateAuto,
  isSearchFilterEnabled,
} from '@src/util/common_utils';
import FilterModal from '@pagescommon/filters/filter-modal';
import MultipleSelectCheckmarks from '@pagescommon/multi_select_checkbox_dropdown/multiple-select-checkmarks.js';
import Chip from '@pagescommon/chip/chip.js';
import FormControl from '@mui/material/FormControl';
import { defectAnalyticsLabel, getSelectedFilterLableName } from '@src/common/ui-constants';
import { ReactComponent as InfoIcon } from '@assets/svg-imports/LicenseMangementTooltipIcon.svg';
import SlackPage from './slack-page';
import MemoryWarningPopup from '@src/pages/configuration/Memory_Config/MemoryWarningPopup';
import { getIDB, removeIDB, setIDB } from '@src/util/localForage_idb_controller';

let moduleScriptNames = [];
let moduleCreatedByNames = [];
let ScriptTypeName = [];
let selectedTreeDataArray = [];
let allSelectedNodes = [];
let prevAllSelectedNodes = [];
let prevSelectedModuleAndScript = {};
let nodeList = [];
let selectedModuleAndScript = {};
let totalScriptCount = 0;
const ModuleScript = ({
  suiteObjMain,
  getModule,
  getSelectedModulesStatus,
  suiteObjForPreview,
  getRootData,
  selectedModulesStatus,
  rerunMode,
  suiteType: propSuiteType,
  handleSuiteTypeChange,
  typeSwitch: propTypeSwitch,
  handleTypeSwitch: propHandleTypeSwitch,
  operation,
  formik,
  previewScreen,
  goToSuiteDashboard,
  onLoading,
  autoRerunExeFunction,
  memoryWarningPopup,
  setMemoryWarningPopup,
  isEmailDropdownOpen,
  goToSuiteTable,
  clearFunc,
  showAlertMessagee,
  createUpdateCalled,
  wrongPattern,
  createSuite,
  setSuiteNameClicked,
  expanded,
  handleChange,
  stepperArry,
  suiteNameClicked,
  setdescCount,
  createSuiteMainFunctionForProjectVariable,
  createAlertFun,
  createTestDataFunction,
  getChangedFile,
  setIsEmailDropdownOpen,
  createSuiteMainFunction,
  createSuiteSlackFunction,
  updateEmailData,
  setUpdateEmailData,
  suiteName,
  syncScripts,
  executionType,
  description,
  projectGlobalVarData,
  testData,
  moduleArray,
  descCount,
  navigateToCreateScreen,
  emailData,
  slackData,
  resultSettingObj,
  exeTerminationObj,
  waitTimeObj,
  runSettingValidation,
  getMachineObj,
  runSettingsObject,
  runSettingsManualObject,
  numberOfParallelRuns,
  selectedSuiteObjForExecution,
  setmachineDetails,
  setResultSetting,
  setWaitTime,
  operationForRunSettings,
  rootData: propRootData,
  setExeTermintion,
  suiteTypeList,
  privilegeObject,
}) => {
  const [allTreeRootData, setAllTreeRootData] = useState({});
  const { resetProgress, startOrResumeProgress } = useContext(ProgressContainerContext);
  let [isLoading, setIsLoading] = useState(false);
  let [treeData, setTreeData] = useState([]);
  let [moduleTree, setModuleTree] = useState('');
  let [rootData, setRootData] = useState([]);
  let [callTableTree, setCallTableTree] = useState(false);
  let rootDataRef = useRef();
  rootDataRef.current = rootData;
  let [isExpandAll, setIsExpandAll] = useState(false);
  let [treeValue, setTreeValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentFancytree, setCurrentFancytree] = useState();
  const currentFancytreeRef = useRef();
  currentFancytreeRef.current = currentFancytree;
  const [isComponentMounted, setIsComponentMounted] = useState(false);
  let [treeCopyData, setTreeCopyData] = useState([]);
  let [selectedTree, setSelectedTree] = useState();
  let [treeDataFlag, setTreeDataFlag] = useState(false);
  let [selectedNodeForUser, setSelectedNodeForUser] = useState();
  let [disableBtn, setDisableBtn] = useState(true);
  let [suiteType, setSuiteType] = useState(propSuiteType ? propSuiteType : 'Manual');
  let [typeSwitch, setTypeSwitch] = useState(propTypeSwitch ? propTypeSwitch : false);
  let [preSelectedType, setPreSelectedType] = useState(propSuiteType ? propSuiteType : 'Manual');
  let licenseFeatures = getLicenseFeatures();
  let hasAutomation = window.privilege.hasAutomation(licenseFeatures);
  let hasManualTestCase = window.privilege.hasManualTestCase(licenseFeatures);
  let hasCBasic = window.privilege.hasCBasic(licenseFeatures);
  let hasAutomationAndManual = hasCBasic ? hasCBasic : hasAutomation && hasManualTestCase;
  let [moduleScriptData, setModuleScriptData] = useState(null);
  let [moduleScriptButton, setModuleScriptButton] = useState(true);
  //  search filter states

  let [resetFilter, setResetFilter] = useState(false);
  const [searchedText, setSearchedText] = useState('');
  const [searchedTextQuery, setSearchedTextQuery] = useState('');
  let [isFilterApplied, setIsFilterApplied] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  let openFilterModal = Boolean(anchorEl);
  const [scriptType, setScriptType] = React.useState([]);
  const [createdBy, setCreatedBy] = React.useState([]);
  const [labels, setLabels] = React.useState([]);
  const [modifiedBy, setModifiedBy] = useState([]);
  const [IsHideAutoManualCombination, setIsHideAutoManualCombination] = useState(false);
  let [hideFiterOnViewMaximizeTreeModel, setHideFiterOnViewMaximizeTreeModel] = useState(false);
  const [createdOn, setCreatedOn] = React.useState({
    from: null,
    to: null,
  });
  const [modifiedOn, setModifiedOn] = React.useState({
    from: null,
    to: null,
  });

  const [disableApplyClearBtn, setDisableApplyClearBtn] = useState(true);
  const [optionsListItem, setOptionsListItem] = useState({ scriptType: [], createdBy: [], labels: [], modifiedBy: [] });
  let showSelectedList = [{ scriptType }, { createdBy }, { labels }, { modifiedBy }];
  let [disableSearchFilter, setDisableSearchFilter] = useState(true);
  let [currentSelectedScriptCount, setCurrentSelectedScriptCount] = useState();
  let [isTreeLoading, setIsTreeLoading] = useState(false);
  const isManualSuiteType = suiteType === 'Manual';

  let enableDisableSearchFilter = (resposeObj) => {
    if (propSuiteType?.toLowerCase() === 'manual' && resposeObj?.manualTestCaseExists) {
      setDisableSearchFilter(false);
    } else if (propSuiteType?.toLowerCase() !== 'manual' && resposeObj?.overAllAutomationScriptCount !== 0) {
      setDisableSearchFilter(false);
    } else {
      setDisableSearchFilter(true);
    }

    if (searchedTextQuery || isFilterApplied) {
      setDisableSearchFilter(false);
    }
  };

  let renderTree;
  let renderTreeModel;
  let renderMaximizeTreeModel;

  const totalModCount = useRef(0);
  const initializeSelectedNode = async () => {
    const _selectedModulesAndScripts = suiteObjForPreview?.selectedModulesAndScripts;
    if (rerunMode && suiteObjMain?.selectedModulesAndScripts.length) {
      return suiteObjMain?.selectedModulesAndScripts[0]?.children;
    } else if (suiteObjMain?.selectedModulesAndScripts?.length) {
      const _selectedModScr = await getIDB('allSelectedNodesForAutomaticDistributions');
      if (_selectedModScr) {
        return _selectedModScr;
      }
    } else if (_selectedModulesAndScripts?.length) {
      return suiteObjForPreview.selectedModulesAndScripts;
    } else {
      return [];
    }
  };

  let [selectedNod, setSelectedNod] = useState(initializeSelectedNode());
  const displaySearchFilter = isSearchFilterEnabled('execution');

  /**
 * 
 * @param {
 * } typeSwitchVal 
 * NOTE ::
 * typeSwitchVal used to filter script by case
 * eg :
 * if(typeSwitchVal){
 *  it will return case without combination of automation and manual
 * }else{
 *  it will return case includes 
 * } 
 * @param {*} _searchText 
 * @param {*} action 
 
 */
  const moduleTreeDataBasedType = async (suiteType) => {
    const responseData = await getAllModuleTreeSuiteReq(suiteType);
    if (responseData.data?.message === 'SUCCESS') {
      setModuleScriptData(responseData);
      setModuleScriptButton(false);
      setTreeData([]);
      selectedTreeDataArray = [];
      getModuleTree(typeSwitch, '', '', responseData);
    }
  };
  async function getModuleTree(typeSwitchVal, _searchText, action, responseData) {
    resetProgress();
    startOrResumeProgress({
      stopAt: 100,
      intervalDuration: 80,
    });
    setIsLoading(true);
    let getdata;
    let responseModuleTree;
    try {
      const requestBody = {
        module: 'module',
        collectionName: 'modules',
        searchText: _searchText,
        facetQueries: [
          { name: 'type', values: scriptType },
          { name: 'labels', values: labels },
          { name: 'createdBy', values: createdBy },
          { name: 'modifiedBy', values: modifiedBy },
          { name: 'createdOn', values: getVerifiedDateAuto(createdOn) },
          { name: 'modifiedOn', values: getVerifiedDateAuto(modifiedOn) },
        ],
      };

      if (
        action === defectAnalyticsLabel.btnLabel.apply ||
        (isFilterApplied && action !== defectAnalyticsLabel.btnLabel.clearAll)
      ) {
        if (action === defectAnalyticsLabel.btnLabel.clearAll) {
          requestBody['facetQueries']?.map((obj) => (obj.values = []));
          setIsFilterApplied(false);
        }

        requestBody['facetQueries'] = requestBody['facetQueries']?.filter((obj) => {
          if (obj?.values?.length) return obj;
        });

        if (isEmptyValue(requestBody['facetQueries']) && !requestBody['searchText']?.trim()) {
          getdata = await getIDB('getdata');
        } else {
          getdata = await getAnalyticsReq(requestBody);
        }
        if (action === defectAnalyticsLabel.btnLabel.apply) {
          setIsFilterApplied(true);
          let selectedFilterList = {
            scriptType,
            labels,
            createdBy,
            modifiedBy,
            IsHideAutoManualCombination,
            createdOn: { from: getVerifiedDateAuto(createdOn)?.at(0), to: getVerifiedDateAuto(createdOn)?.at(1) },
            modifiedOn: { from: getVerifiedDateAuto(modifiedOn)?.at(0), to: getVerifiedDateAuto(modifiedOn)?.at(1) },
          };

          sessionStorage.setItem('allLevelModuleTreeFilters', JSON.stringify(selectedFilterList));
          setAnchorEl(null);
          openFilterModal = false;
        }
        setSearchedTextQuery(_searchText);
        setTypeSwitch(IsHideAutoManualCombination);
        if (getdata?.data?.errorCode === 404) {
          setRootData([]);
          setTreeData([]);
        } else {
          responseModuleTree = getdata?.data?.responseObject?.moduleTree;
          if (responseModuleTree) {
            responseModuleTree = filterRoot(responseModuleTree, IsHideAutoManualCombination);
            handleSetTreeDataAfterFilter(responseModuleTree);
          }
        }

        resetProgress();
        setIsLoading(false);
      } else if (
        (action === 'search' && _searchText) ||
        (_searchText && action === defectAnalyticsLabel.btnLabel.clearAll)
      ) {
        if (action === defectAnalyticsLabel.btnLabel.clearAll) {
          requestBody['facetQueries']?.map((obj) => (obj.values = []));
          setIsFilterApplied(false);
        }

        requestBody['facetQueries'] = requestBody['facetQueries']?.filter((obj) => {
          if (obj?.values?.length) return obj;
        });

        getdata = await getAnalyticsReq(requestBody);
        setSearchedTextQuery(searchedText);

        if (getdata.data.errorCode === 404) {
          setRootData([]);
          setTreeData([]);
        } else {
          responseModuleTree = getdata?.data?.responseObject?.moduleTree;
          if (responseModuleTree) {
            handleSetTreeDataAfterFilter(responseModuleTree);
          }
        }
        resetProgress();
        setIsLoading(false);
      } else {
        getdata = responseData && Object.keys(responseData)?.length ? responseData : moduleScriptData;
        setIDB('getdata', getdata?.data);
        responseModuleTree = getdata?.data?.responseObject?.moduleTree;
        if (responseModuleTree) {
          handleSetTreeDataAfterFilter(responseModuleTree);
        }
        enableDisableSearchFilter(getdata?.data?.responseObject);
        resetProgress();
        setIsLoading(false);
      }
    } catch (err) {
      console.error('GET_ALL_MODULE_TREE : ', err);
    }
  }

  const filterRoot = (rootData, typeSwitchVal) => {
    let filteredModuleData = [];
    if (rootData) {
      rootData.forEach((module) => {
        if (module?.folder) {
          let filterData = filterModuleData(module, typeSwitchVal);
          if (filterData[0] && filterData[0]['children'].length) {
            filteredModuleData.push(filterData[0]);
          } else if (module?.title === 'Root Module') {
            filteredModuleData.push(module);
          }
        }
      });
    }
    return filteredModuleData;
  };
  const handleSetTreeDataAfterFilter = (responseModuleTree) => {
    let root;
    let rootChildren;
    totalScriptCount = null;
    let rootDetails = responseModuleTree[0];
    setModuleTree(rootDetails?.children);
    root = {
      subModuleCount: rootDetails.subModuleCount,
      title: rootDetails.title,
      scriptCount: rootDetails.scriptCount,
      key: rootDetails.key,
      ver: rootDetails.ver,
      modifiedByUname: rootDetails.modifiedByUname,
      selected: selectedModuleAndScript[rootDetails?.key] === 'selected',
      partsel: selectedModuleAndScript[rootDetails?.key] === 'partsel',
    };
    setAllTreeRootData(root);
    rootDataRef.current = root;
    rootChildren = rootDetails.children;
    setRootData(root);
    getRootData(root);
    setCallTableTree(true);
    setIsExpandAll(rootDetails.expanded);
    setTreeData(rootChildren);
    setTreeCopyData(rootChildren);
    setPreselectedValue(rootChildren);

    if (isEmptyValue(rootChildren)) {
      // TODO ::set No result found page
      setTreeData([]);
      setRootData([]);
    }
  };
  const filterModuleData = (moduleData, typeSwitchVal) => {
    let filteredModule = [];
    if (moduleData?.children) {
      moduleData['children'] = filterScriptData(moduleData?.children, typeSwitchVal);
      if (moduleData['children'] && moduleData['children'].length) {
        filteredModule.push(moduleData);
      }
    }
    return filteredModule;
  };

  const filterScriptData = (scriptsData, typeSwitchVal) => {
    let filteredData = [];
    if (scriptsData) {
      scriptsData.forEach((script) => {
        if (script?.folder) {
          let children = filterModuleData(script, typeSwitchVal);
          if (children[0] && children[0]['children'].length) {
            filteredData.push(children[0]);
          }
        } else {
          if (script?.testCaseType) {
            let obj = script.testCaseType.find((o) => o.type.toLowerCase() === suiteType.toLowerCase());
            if (obj) {
              if (typeSwitchVal && script.testCaseType.length === 1) {
                script['key'] = obj['id'];
                script['searchKey'] = getSearchKey(script['searchKey'], obj['id']);
                filteredData.push(script);
              } else if (!typeSwitchVal) {
                script['key'] = obj['id'];
                script['searchKey'] = getSearchKey(script['searchKey'], obj['id']);
                filteredData.push(script);
              }
            }
          } else {
            if (suiteType.toLowerCase() === 'manual' && script['type']) {
              if (script['type'] !== 'POST' && script['type'] !== 'PRE') {
                filteredData.push(script);
              }
            } else {
              filteredData.push(script);
            }
          }
        }
      });
    }
    return filteredData;
  };

  function getSearchKey(searchKey, scriptKey) {
    if (!searchKey.includes(scriptKey)) {
      let searchKeyArray = searchKey.split('/');
      if (searchKeyArray && searchKeyArray.length) {
        let manualScriptKey = searchKeyArray[searchKeyArray.length - 1];
        searchKey = searchKey.replace(manualScriptKey, scriptKey);
      }
    }
    return searchKey;
  }

  function setPreselectedValue(treeData) {
    if (selectedModuleAndScript) {
      if (treeData && treeData.length) {
        treeData.forEach((treeObj) => {
          if (treeObj.folder) {
            if (selectedModuleAndScript[treeObj.key] === 'selected') {
              treeObj.selected = true;
            } else if (selectedModuleAndScript[treeObj.key] === 'partsel') {
              treeObj.partsel = true;
            } else {
              treeObj.partsel = false;
              treeObj.selected = false;
            }
            setPreselectedValue(treeObj?.children ? treeObj?.children : null);
          } else {
            if (selectedModuleAndScript[treeObj.key] === 'selected') {
              treeObj.selected = true;
            } else if (selectedModuleAndScript[treeObj.key] === 'partsel') {
              treeObj.partsel = true;
            } else {
              treeObj.partsel = false;
              treeObj.selected = false;
            }
          }
        });
      }
    }
  }

  const suiteTypeSwitch = () => {
    let tooltipTitle;
    if (typeSwitch) {
      tooltipTitle = `Disable this to show test case with automation and manual steps`;
    } else {
      tooltipTitle = `Enable this to show test case with only ${propSuiteType ? propSuiteType : 'automation'} steps`;
    }
    return (
      <Tooltip title={tooltipTitle} placement="bottom">
        <Stack direction="row" spacing={1} alignItems="center" className="hide-scripts-switch">
          <span className="mt-1.5">
            <label className="switch mx-2.5">
              <input
                type="checkbox"
                className="switchdisable"
                onChange={(e) => {
                  handleTypeSwitch(e);
                }}
              />
              <span className="slider round" />
            </label>
          </span>
        </Stack>
      </Tooltip>
    );
  };

  const colDefs = [
    {
      field: 'title',
      title: 'MODULE',
      class: 'title',
      isTitle: true,
      width: `calc(100% - 550px)`,
      folderTilleWidth: `calc(100% - 670px)`,
      render: (nodeObj, index) => {
        createFilterData(treeData);
        return (
          <>
            {nodeObj.isRootNode && (
              <>
                <Tooltip
                  title={`Modules - ${
                    selectedNod && selectedNod[0]?.totalModuleCount ? selectedNod[0]?.totalModuleCount : 0
                  }`}
                  placement="bottom"
                >
                  <span className="count-badge folder-count ml-2 modifiedBy">
                    M {selectedNod && selectedNod[0]?.totalModuleCount ? selectedNod[0]?.totalModuleCount : 0}{' '}
                  </span>
                </Tooltip>
                <Tooltip
                  title={`Scripts - ${
                    totalScriptCount
                      ? totalScriptCount
                      : selectedNod && selectedNod[0]?.totalScriptCount
                      ? selectedNod[0]?.totalScriptCount
                      : 0
                  }`}
                  placement="bottom"
                >
                  <span className="count-badge file-count modifiedBy">
                    {' '}
                    S{' '}
                    {totalScriptCount
                      ? totalScriptCount
                      : selectedNod && selectedNod[0]?.totalScriptCount
                      ? selectedNod[0]?.totalScriptCount
                      : 0}{' '}
                  </span>
                </Tooltip>
              </>
            )}
            {nodeObj.data.folder && nodeObj.data.calculatedScriptCount > 0 && (
              <>
                <Tooltip title={`Scripts - ${nodeObj.data.calculatedScriptCount}`} placement="bottom">
                  <span className="count-badge file-count modifiedBy"> S {nodeObj.data.calculatedScriptCount} </span>
                </Tooltip>
              </>
            )}
            {nodeObj.folder && (
              <button class="add-btn">
                <MoreInfoButton type="add" nodeObj={nodeObj} plusIconArray={plusIconArray} />
              </button>
            )}
          </>
        );
      },
    },
    {
      field: 'scriptType',
      title: 'TYPE',
      class: 'Type',
      width: '160px',
      render: (nodeObj) => {
        return (
          <span className="text-xs table-non-link-color-common fontPoppinsRegularMd">
            {getTruncatedText(nodeObj.data.scriptType, 20)}
          </span>
        );
      },
    },
    {
      field: 'modifiedByUname',
      title: 'MODIFIED BY',
      class: 'modified-by',
      width: '200px',
      render: (nodeObj) => {
        return (
          <div>
            {!nodeObj.isRootNode && (
              <span
                className={
                  nodeObj.data.modifiedByUname !== '--'
                    ? 'hyper_link_color-common table-font-style-common  cursor-pointer'
                    : null
                }
                onClick={() => (nodeObj.data.modifiedByUname !== '--' ? openModalfun('createdBy', nodeObj) : null)}
                name="modifiedBy"
              >
                {nodeObj.data.modifiedByUname}
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const colDefsModel = [
    {
      field: 'title',
      title: 'MODULE',
      class: 'title',
      isTitle: true,
      width: `calc(100% - 550px)`,
      folderTilleWidth: `calc(100% - 550px)`,
      paddingRight: '12px',
      render: (nodeObj) => {
        createFilterData(treeData);
        return (
          <>
            {nodeObj.isRootNode && (
              <>
                <Tooltip
                  title={`Modules - ${nodeObj.data.subModuleCount ? nodeObj.data.subModuleCount : 0}`}
                  placement="bottom"
                >
                  <span className="count-badge folder-count ml-2 modifiedBy">
                    M {nodeObj.data.subModuleCount ? nodeObj.data.subModuleCount : 0}{' '}
                  </span>
                </Tooltip>
                <Tooltip
                  title={`Scripts - ${nodeObj.data.scriptCount ? nodeObj.data.scriptCount : 0}`}
                  placement="bottom"
                >
                  <span className="count-badge file-count modifiedBy">
                    {' '}
                    S {nodeObj.data.scriptCount ? nodeObj.data.scriptCount : 0}{' '}
                  </span>
                </Tooltip>
              </>
            )}
            {nodeObj.data.folder && nodeObj.data.scriptCount > 0 && (
              <>
                <Tooltip title={`Scripts - ${nodeObj.data.scriptCount}`} placement="bottom">
                  <span className="count-badge file-count modifiedBy"> S {nodeObj.data.scriptCount} </span>
                </Tooltip>
              </>
            )}

            {nodeObj.folder && (
              <button class="add-btn">
                <MoreInfoButton type="add" nodeObj={nodeObj} plusIconArray={plusIconArray} />
              </button>
            )}
          </>
        );
      },
    },
    {
      field: 'scriptType',
      title: 'TYPE',
      class: 'Type',
      width: '160px',
      render: (nodeObj) => {
        return (
          <span className="text-xs table-non-link-color-common fontPoppinsRegularMd">
            {getTruncatedText(nodeObj.data.scriptType, 20)}
          </span>
        );
      },
    },
    {
      field: 'modifiedByUname',
      title: 'MODIFIED BY',
      class: 'modified-by',
      width: '200px',
      render: (nodeObj) => {
        return (
          <div>
            {!nodeObj.isRootNode && <span className="table-non-link-color-common">{nodeObj.data.modifiedByUname}</span>}
          </div>
        );
      },
    },
  ];
  const plusIconArray = [
    {
      name: 'Add Module',
      value: 'folder',
    },
    {
      name: 'Add Script',
      value: 'file',
    },
  ];
  const LABELS = {
    EXPAND_ALL: 'Expand All',
    COLLAPSE_ALL: 'Collapse All',
    SHOW_ALL_ELEMENTS: 'Show all scripts',
    HIDE_ALL_ELEMENTS: 'Hide all scripts',
    SHOW_PRE_POST_CONDITION: 'Show Pre/Post Conditions',
    HIDE_PRE_POST_CONDITION: 'Hide Pre/Post Conditions',
  };

  const SuiteTypeSwitch = () => {
    let tooltipTitle;
    if (IsHideAutoManualCombination) {
      tooltipTitle = `Unselect this checkbox to filter test case with ${propSuiteType} and ${
        propSuiteType?.toLowerCase() === 'manual' ? 'automation' : propSuiteType?.toLowerCase()
      } scripts`;
    } else {
      tooltipTitle = `Select this checkbox to filter test case only ${propSuiteType?.toLowerCase()} Scripts`;
    }

    return (
      <div className="flex justify-center items-center gap-1 mr-24">
        <div className=" ff-checkbox-style mr-1 ">
          {selectedTree === 'parentTree' && displaySwitch(propSuiteType) && (
            <input
              type="checkbox"
              className="switchdisable "
              checked={IsHideAutoManualCombination}
              onChange={(e) => {
                setIsHideAutoManualCombination(e.target.checked);
              }}
            />
          )}
        </div>

        {selectedTree === 'parentTree' && displaySwitch(propSuiteType) ? (
          propSuiteType?.toLowerCase() === 'manual' ? (
            <>
              {hasAutomationAndManual && (
                <div className="fontPoppinsRegularXs">Hide Manual Test Cases with Automation Scripts</div>
              )}
              <Tooltip title={tooltipTitle} placement="bottom">
                <InfoIcon fontSize="small" color="disabled" />
              </Tooltip>
            </>
          ) : (
            <>
              {hasAutomationAndManual && (
                <div className="fontPoppinsRegularXs">Hide Automation Scripts with Manual Test Cases </div>
              )}
              <Tooltip title={tooltipTitle} placement="bottom">
                <InfoIcon fontSize="small" color="disabled" />
              </Tooltip>
            </>
          )
        ) : null}
      </div>
    );
  };

  const handleTypeSwitch = async (e) => {
    let tempTypeSwitch = e.target.checked;
    setTypeSwitch(tempTypeSwitch);
    setTreeData([]);
    setTreeCopyData([]);
    setRootData([]);
    getRootData([]);
    setIsExpandAll(false);
    setIsLoading(true);
    updateSelectedTreeDataArray();
    const treeResponse = await getIDB('getdata');
    let resModuleTree = treeResponse.data.responseObject.moduleTree;
    if (resModuleTree) {
      setTimeout(() => {
        handleSetTreeDataAfterFilter(resModuleTree);
        setIsLoading(false);
      }, 100);
    }
  };

  const updateSelectedTreeDataArray = () => {
    nodeList = [];
    if (currentFancytreeRef.current) {
      currentFancytreeRef.current.visit(function (node) {
        if (!node.isSelected()) {
          nodeList.push(node);
        }
      });
    }
    if (nodeList?.length) {
      selectedModuleAndScript = {};
      nodeList.forEach((_node) => {
        if (selectedTreeDataArray?.length) {
          removeUnselectedNodes(_node, selectedTreeDataArray);
        }
      });
    }
  };
  const removeUnselectedNodes = (_node, _selectedTreeDataArray) => {
    const uncheckedNodeIndex = _selectedTreeDataArray.findIndex((_treeNode) => {
      if (_treeNode?.node?.key === _node.key) {
        return true;
      } else if (_treeNode?.node?.folder && _treeNode?.node?.children & _treeNode?.node?.children?.length) {
        return removeUnselectedNodes(_node, _treeNode?.node?.children);
      }
      return false;
    });
    if (uncheckedNodeIndex > -1) {
      _selectedTreeDataArray.splice(uncheckedNodeIndex, 1);
    }
  };
  const setSelectedTreeDataHandler = (nodes) => {
    if (selectedTreeDataArray.length) {
      nodes.forEach((_node) => {
        const _foundNodeIndex = selectedTreeDataArray.findIndex((_treeNode) => _treeNode.node.key === _node.node.key);
        if (_foundNodeIndex > -1) {
          selectedTreeDataArray[_foundNodeIndex] = _node;
        } else {
          selectedTreeDataArray.push(_node);
        }
      });
    } else {
      selectedTreeDataArray = nodes;
    }
  };

  const onCheckedNodes = (nodes) => {
    updateSelectedTreeDataArray();
    setSelectedTreeDataHandler(nodes);
    selectedModuleAndScript = {};
    totalScriptCount = null;
    if (nodes.length) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  };

  const buildTreeData = (action) => {
    if (selectedTreeDataArray) {
      selectedTreeDataArray.forEach((selectedNode) => {
        let node = selectedNode?.node;
        let data = selectedNode?.data;
        if (node?.folder && node?.selected && node?.parent?.title === 'root') {
          if (action === 'SELECT') {
            let ifDataExist = allSelectedNodes.find((treeData) => treeData['key'] === node['key']);
            if (!ifDataExist || allSelectedNodes.length < 1) {
              allSelectedNodes.push(buildModuleObject(node, data));
            }
          } else {
            const returnedObj = buildModuleObject(node, data);
          }
        } else {
          var keyNode = node;
          if (keyNode) {
            var topNode = keyNode.getParentList()[0];
            if (
              topNode &&
              topNode?.folder &&
              (topNode?.selected || topNode?.partsel) &&
              topNode?.parent?.title === 'root'
            ) {
              if (action === 'SELECT') {
                let ifDataExist = allSelectedNodes.find((treeData) => treeData['key'] === topNode['key']);
                if (!ifDataExist || allSelectedNodes.length < 1) {
                  allSelectedNodes.push(buildModuleObject(topNode, topNode?.data));
                }
              } else {
                const returnedObj = buildModuleObject(topNode, topNode?.data);
              }
            }
          }
        }
      });
      if (moduleTree?.length) {
        let tempArray = [];
        moduleTree.forEach((obj) => {
          if (selectedModuleAndScript[obj.key] === 'selected') {
            tempArray.push(obj.key);
          }
        });
        if (tempArray?.length === moduleTree?.length) {
          selectedModuleAndScript[rootDataRef.current.key] = 'selected';
        } else if (tempArray.length) {
          selectedModuleAndScript[rootDataRef.current.key] = 'partsel';
        }
      }
      let tempRootData = { ...rootDataRef.current };
      tempRootData['selected'] = selectedModuleAndScript[tempRootData?.key] === 'selected';
      tempRootData['partsel'] = selectedModuleAndScript[tempRootData?.key] === 'partsel';
      setRootData({ ...tempRootData });
      setCallTableTree(true);
      setAllTreeRootData({ ...tempRootData });
    }
  };
  const getModuleCount = (allSelectedNodes) => {
    allSelectedNodes.forEach((obj) => {
      if (obj.type === 'Module') {
        totalModCount.current++;
        if (obj?.children) {
          getModuleCount(obj?.children);
          calculatedScriptNumber(obj);
        }
      }
    });
    return totalModCount.current;
  };

  function SelectedModules() {
    updateSelectedTreeDataArray();
    setTreeDataFlag(true);
    allSelectedNodes = [];
    selectedModuleAndScript = {};
    totalScriptCount = null;
    buildTreeData('SELECT');
    totalModCount.current = 0;
    allSelectedNodes.forEach((obj) => {
      if (obj.type === 'Module') {
        totalModCount.current++;
        if (obj?.children) {
          totalModCount.current = getModuleCount(obj?.children);
          calculatedScriptNumber(obj);
        }
      }
    });
    allSelectedNodes[0]['totalModuleCount'] = totalModCount?.current;
    allSelectedNodes[0]['totalScriptCount'] = totalScriptCount;
    getModule(allSelectedNodes);
    setIDB('moduleChangedStatus', true);
    getSelectedModulesStatus(selectedModuleAndScript);

    setIDB('allSelectedNodesForAutomaticDistributions', allSelectedNodes);
    allSelectedNodes.forEach((obj) => {
      obj.checkbox = true;
    });
    setIDB('selectedNodes', allSelectedNodes);
    setSelectedNod(allSelectedNodes);
    setPreSelectedType(suiteType);
    setShowModal(false);
    setTypeSwitch(false);
    setTimeout(() => {
      setTreeDataFlag(false);
    }, 50);
    resetFilterOnCloseModal();
  }

  const buildModuleObject = (moduleData, nodeData) => {
    if (!nodeData) {
      nodeData = {};
    }
    let treeObject = {
      key: moduleData?.key,
      title: moduleData?.title,
      children: filterSelectedScripts(moduleData?.children),
      folder: moduleData?.folder,
      type: moduleData?.type ? moduleData?.type : 'Module',
    };
    if (treeObject) {
      let count = 0;
      if (treeObject?.children?.length) {
        treeObject?.children.forEach((obj) => {
          if (obj?.type === 'Script') {
            count++;
          }
        });
      }
      treeObject['selectedScriptCount'] = count;
      if (totalScriptCount) {
        totalScriptCount = totalScriptCount + treeObject?.selectedScriptCount;
      } else {
        totalScriptCount = treeObject?.selectedScriptCount;
      }
    }
    selectedModuleAndScript[moduleData.key] = moduleData.selected ? 'selected' : 'partsel';
    const mergedObject = { ...treeObject, ...nodeData };
    return mergedObject;
  };

  const filterSelectedScripts = (moduleChildren) => {
    let moduleData = [];
    if (moduleChildren) {
      moduleChildren.forEach((child) => {
        if (child && child?.selected && !child?.folder) {
          let data = {};
          if (child?.data) {
            data = child?.data;
          }
          let treeObject = {
            key: child?.key,
            title: child?.title,
            children: child?.children,
            folder: child?.folder,
            type: child?.type ? child?.type : 'Script',
          };
          selectedModuleAndScript[child.key] = child.selected ? 'selected' : 'partsel';
          const mergedObject = { ...treeObject, ...data };
          moduleData.push(mergedObject);
        } else if (child?.folder && (child?.selected || (!child?.selected && child?.partsel))) {
          moduleData.push(buildModuleObject(child, child?.data));
        }
      });
    }
    return moduleData;
  };
  const ifTreeLoaded = () => {
    setIsTreeLoading(false);
  };
  if (treeDataFlag) {
    renderTree = (
      <div className="base-h flex items-center justify-center">
        <div className="text-center font-bold align-middle empty_page_info -mt-20">
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  } else if (callTableTree && selectedNod && selectedNod.length > 0) {
    renderTree = (
      <SuiteTree
        data={selectedNod}
        rootData={rootDataRef.current}
        colDefs={colDefs}
        filter={treeValue}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={true}
        hideConditionBtn={isManualSuiteType}
        conditions={[
          { key: 'title', value: 'Preconditions' },
          { key: 'title', value: 'Postconditions' },
        ]}
        labels={LABELS}
        onNodeSelected={onNodeSelected}
        id="selectedTree"
        resetFilter={resetFilter}
        userSearched={searchedTextQuery}
        isFilterApplied={isFilterApplied}
        ifTreeLoaded={ifTreeLoaded}
      />
    );
  } else if (selectedNod.length === 0) {
    renderTree = (
      <div>
        <div>
          <SuiteTree
            data={[]}
            rootData={rootDataRef.current}
            colDefs={colDefs}
            filter={treeValue}
            showCheckbox={false}
            onCheckedNodes={onCheckedNodes}
            expandAll={isExpandAll}
            hideElements={false}
            hideConditions={true}
            conditions={[
              { key: 'title', value: 'Preconditions' },
              { key: 'title', value: 'Postconditions' },
            ]}
            hideConditionBtn={isManualSuiteType}
            labels={LABELS}
            onNodeSelected={onNodeSelected}
            id="nodataBaseTree"
            resetFilter={resetFilter}
            userSearched={searchedTextQuery}
            isFilterApplied={isFilterApplied}
          />
        </div>
      </div>
    );
  }

  if (isLoading) {
    renderTreeModel = <CommonLoader />;
  } else if (treeData && treeData.length > 0) {
    renderTreeModel = (
      <SuiteTree
        data={treeData}
        rootData={allTreeRootData}
        colDefs={colDefsModel}
        filter={treeValue}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={true}
        showCheckbox={true}
        conditions={[
          { key: 'title', value: 'Preconditions' },
          { key: 'title', value: 'Postconditions' },
        ]}
        hideConditionBtn={isManualSuiteType}
        onCheckedNodes={onCheckedNodes}
        labels={LABELS}
        onNodeSelected={onNodeSelected}
        id="childTree"
        treeType="execution"
        getFancyTree={setCurrentFancytree}
        resetFilter={resetFilter}
        userSearched={searchedTextQuery}
        isFilterApplied={isFilterApplied}
        ifTreeLoaded={ifTreeLoaded}
        initFancyTree={(initFancyTree) => {
          if (!isComponentMounted) {
            const nodes = initFancyTree?.getSelectedNodes();
            nodes?.forEach((node) => {
              if (node.checkbox || (node.type === 'Script' && node.selected && !node.parent.expanded)) {
                selectedTreeDataArray.push({
                  data: {
                    ...node.data,
                    key: node.key,
                    _key: node.key,
                    title: node.title,
                    folder: node.folder,
                  },
                  node: node,
                });
              }
            });
            setIsComponentMounted(true);
          }
        }}
      />
    );
  } else {
    renderTreeModel = (
      <div>
        <div>
          <SuiteTree
            data={[]}
            rootData={rootData}
            colDefs={colDefs}
            filter={treeValue}
            showCheckbox={false}
            onCheckedNodes={onCheckedNodes}
            expandAll={isExpandAll}
            hideConditions={true}
            conditions={[
              { key: 'title', value: 'Preconditions' },
              { key: 'title', value: 'Postconditions' },
            ]}
            hideConditionBtn={isManualSuiteType}
            labels={LABELS}
            onNodeSelected={onNodeSelected}
            id="noData"
            resetFilter={resetFilter}
            userSearched={searchedTextQuery}
            isFilterApplied={isFilterApplied}
          />
        </div>
        {disableSearchFilter && (
          <div className="overflow-y-auto h-82">
            <div className="mt-28 no-steps">
              <div classname="create-set-layout">
                <div className="qucBtn">
                  <label className="quick-start-style px-1 fontPoppinsRegularMd">No Data Available </label>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  if (isLoading) {
    renderMaximizeTreeModel = <CommonLoader />;
  } else if (selectedNod && selectedNod.length > 0) {
    renderMaximizeTreeModel = (
      <SuiteTree
        data={selectedNod}
        rootData={rootData}
        colDefs={colDefs}
        filter={treeValue}
        expandAll={isExpandAll}
        hideElements={false}
        hideConditions={true}
        showCheckbox={false}
        conditions={[
          { key: 'title', value: 'Preconditions' },
          { key: 'title', value: 'Postconditions' },
        ]}
        hideConditionBtn={isManualSuiteType}
        labels={LABELS}
        onNodeSelected={onNodeSelected}
        id="maximizeTree"
        resetFilter={resetFilter}
        userSearched={searchedTextQuery}
        isFilterApplied={isFilterApplied}
        ifTreeLoaded={ifTreeLoaded}
      />
    );
  }

  function createFilterData(treeData) {
    if (treeData) {
      let tempArray = [];
      let scriptArray = [];
      let tempNamesArray = [];
      let tempTypeArray = [];
      treeData?.forEach((treeVal) => {
        if (treeVal) {
          if (treeVal?.title) {
            tempArray.push(treeVal.title);
          }
          if (treeVal?.createdByUname) {
            tempNamesArray.push(treeVal.createdByUname);
          }
          if (treeVal?.children) {
            scriptArray = treeVal.children;
            scriptArray.forEach((scriptVal) => {
              if (scriptVal?.title) {
                tempArray.push(scriptVal?.title);
              }
              if (scriptVal?.createdByUname) {
                tempNamesArray.push(scriptVal.createdByUname);
              }
              if (scriptVal?.scriptType) {
                tempTypeArray.push(scriptVal.scriptType);
              }
            });
          }
          moduleCreatedByNames = Array.from(new Set(tempNamesArray));
          moduleScriptNames = tempArray;
          ScriptTypeName = Array.from(new Set(tempTypeArray));
        }
      });
    }
  }

  function onNodeSelected() {
    console.log(`clicked`);
  }

  useEffect(() => {
    if (moduleScriptData === null) {
      moduleTreeDataBasedType(suiteType);
    }
    if (suiteObjMain?.selectedModulesAndScripts?.length) {
      let tempArray = suiteObjMain.selectedModulesAndScripts[0]?.children;
      let tempNodArray = [];
      tempArray?.forEach((obj) => {
        const clone = (({ checkbox, ...o }) => o)(obj);
        tempNodArray.push(clone);
      });
      setIDB('selectedNodes', suiteObjMain?.selectedModulesAndScripts?.[0]?.children);

      setIDB('allSelectedNodesForAutomaticDistributions', tempNodArray);
      setSelectedNod(tempNodArray);
      if (tempNodArray.length) {
        setDisableBtn(true);
      } else {
        setDisableBtn(false);
      }
      totalScriptCount = tempArray?.[0]?.totalScriptCount;
    } else {
      if (!selectedNod?.length) {
        removeIDB('allSelectedNodesForAutomaticDistributions');
        removeIDB('selectedNodes');
        totalScriptCount = 0;
      }
    }
    if (suiteObjMain?.selectedModulesStatus) {
      selectedModuleAndScript = suiteObjMain.selectedModulesStatus;
    } else if (selectedModulesStatus) {
      selectedModuleAndScript = selectedModulesStatus;
    } else {
      selectedModuleAndScript = {};
    }
  }, []);

  async function selectModule(val) {
    setIsTreeLoading(true);
    if (selectedNod && selectedNod.length) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
    if (propSuiteType) {
      setTreeData([]);
      getModuleTree(typeSwitch);
    }
    prevAllSelectedNodes = [...selectedTreeDataArray];
    prevSelectedModuleAndScript = JSON.parse(JSON.stringify(selectedModuleAndScript));
    let tempRootData = { ...rootData };
    tempRootData['selected'] = selectedModuleAndScript[tempRootData?.key] === 'selected';
    tempRootData['partsel'] = selectedModuleAndScript[tempRootData?.key] === 'partsel';
    setRootData({ ...tempRootData });
    setCallTableTree(true);
    setAllTreeRootData({ ...tempRootData });
    setShowModal(true);
    setSelectedTree(val);
    setCurrentSelectedScriptCount(selectedNod[0]?.totalScriptCount);
  }

  const defaultProps = {
    options: moduleScriptNames,
    getOptionLabel: (option) => option,
  };
  function openModalfun(btn, nodeObj) {
    if (btn === 'createdBy') {
      setOpenModal(true);
      setSelectedNodeForUser(nodeObj);
    }
  }

  const handleSuiteType = (radioVal) => {
    if (radioVal !== suiteType) {
      suiteObjMain['selectedModulesAndScripts'] = [];
      getModule([]);
      setRootData([]);
      selectedTreeDataArray = [];
      setAllTreeRootData([]);
      getRootData([]);
      setCallTableTree(true);
      setModuleScriptButton(true);
    }
    moduleTreeDataBasedType(radioVal);
    setSuiteType(radioVal);
    handleSuiteTypeChange(radioVal);
    setSelectedNod([]);
    selectedModuleAndScript = {};
  };

  const displaySwitch = (suiteType) => {
    suiteType = suiteType?.toLowerCase();
    if (
      suiteType === 'manual' &&
      !privilegeObject.hasCBasic &&
      !(privilegeObject.hasAutomation && privilegeObject.hasAutomationBasedOnProjType)
    ) {
      return false;
    } else if (suiteType === 'automation' && privilegeObject.hasCBasic) {
      return true;
    } else if (suiteType === 'automation' && !privilegeObject.hasManualTestCase) {
      return false;
    } else {
      return true;
    }
  };

  // TODO :: HANDLE FILTERS ::START

  const searchTree = async (_searchText) => {
    setSearchedText(_searchText);
    getModuleTree(IsHideAutoManualCombination, _searchText, 'search');
  };

  const resetTreeSearch = () => {
    setTreeValue('');
    setSearchedText('');
    setSearchedTextQuery('');
    getModuleTree(IsHideAutoManualCombination, '', 'RESET');
  };

  let renderChips = (listName) => {
    switch (listName) {
      case 'scriptType':
        return scriptType;
      case 'createdBy':
        return createdBy;
      case 'labels':
        return labels;
      case 'modifiedBy':
        return modifiedBy;
    }
  };

  const handleFilterOpen = async (event) => {
    setAnchorEl(event.currentTarget);
    let payload = {
      module: 'module',
      collectionName: 'modules',
      searchText: '',
      facetQueries: [],
    };
    try {
      let response = await getAnalyticsReq(payload);
      let filterResponse = response?.data?.filterResponse || [];

      filterResponse?.filter((val) => {
        if (val?.name === 'modifiedBy') {
          let nestModifiedObj = filterResponse.filter((nestVal) => nestVal.name === 'nestedModifiedBy');
          let modifiedByValues = { ...val.values, ...nestModifiedObj[0].values };
          if (Object?.keys(modifiedByValues)?.length) {
            return (val.values = Object?.keys(modifiedByValues));
          } else {
            return (val.values = []);
          }
        } else if (val?.name == 'createdBy') {
          let nestCreatedObj = filterResponse.filter((nestVal) => nestVal.name === 'nestedCreatedBy');
          let createdByValues = { ...val.values, ...nestCreatedObj[0].values };
          if (Object?.keys(createdByValues)?.length) {
            return (val.values = Object?.keys(createdByValues));
          } else {
            return (val.values = []);
          }
        } else if (
          val?.name !== 'nestedModifiedBy' &&
          val.name !== 'nestedCreatedBy' &&
          Object?.keys(val?.values)?.length
        ) {
          return (val.values = Object?.keys(val?.values));
        } else if (
          val?.name !== 'nestedModifiedBy' &&
          val.name !== 'nestedCreatedBy' &&
          Object?.keys(val?.values)?.length == 0
        ) {
          return (val.values = []);
        }
      });

      let getValues = (data, type) => {
        let values = [];
        data?.forEach((val) => {
          if (val?.name === type) {
            values = val?.values;
          }
        });
        return values;
      };

      setOptionsListItem({
        scriptType: getValues(filterResponse, 'type'),
        createdBy: getValues(filterResponse, 'createdBy'),
        labels: getValues(filterResponse, 'labels'),
        modifiedBy: getValues(filterResponse, 'modifiedBy'),
      });

      if (isFilterApplied) {
        let retriveData = JSON.parse(sessionStorage.getItem('allLevelModuleTreeFilters') || null);
        setScriptType(retriveData?.scriptType);
        setCreatedBy(retriveData?.createdBy);
        setLabels(retriveData?.labels);
        setModifiedBy(retriveData?.modifiedBy);
        setIsHideAutoManualCombination(retriveData?.IsHideAutoManualCombination);
        setIsHideAutoManualCombination(retriveData?.IsHideAutoManualCombination);

        setCreatedOn({
          from: convertDateHyphenReverse(retriveData?.createdOn?.from),
          to: convertDateHyphenReverse(retriveData?.createdOn?.to),
        });
        setModifiedOn({
          from: convertDateHyphenReverse(retriveData?.modifiedOn?.from),
          to: convertDateHyphenReverse(retriveData?.modifiedOn?.to),
        });
      }
    } catch (err) {
      console.error('ERROR IN GET OPTION LIST', err);
    }
  };

  const handleFilterClose = () => {
    if (!isFilterApplied) {
      setScriptType([]);
      setCreatedBy([]);
      setLabels([]);
      setModifiedBy([]);
      setCreatedOn({ from: null, to: null });
      setModifiedOn({ from: null, to: null });
    }
    setAnchorEl(null);
  };

  const handleSelectDropdown = (event, action, perfromOn) => {
    if (action === 'add') {
      const selectedValue = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
      switch (perfromOn) {
        case 'scriptType':
          return setScriptType(selectedValue);
        case 'createdBy':
          return setCreatedBy(selectedValue);
        case 'labels':
          return setLabels(selectedValue);
        case 'modifiedBy':
          return setModifiedBy(selectedValue);
      }
    } else if (action === 'delete') {
      let valuetoDelete = typeof event === 'string' ? event : '';
      switch (perfromOn) {
        case 'scriptType':
          return setScriptType(scriptType.filter((val) => val !== valuetoDelete));
        case 'createdBy':
          return setCreatedBy(createdBy.filter((val) => val !== valuetoDelete));
        case 'labels':
          return setLabels(labels.filter((val) => val !== valuetoDelete));
        case 'modifiedBy':
          return setModifiedBy(modifiedBy.filter((val) => val !== valuetoDelete));
      }
    }
  };

  let handleCreatedOnDate = (e, action) => {
    if (action == 'from') {
      setCreatedOn((perVal) => ({ ...perVal, from: e }));
    } else if (action == 'to') {
      setCreatedOn((perVal) => ({ ...perVal, to: e }));
    }
  };

  let handleModifiedOnDate = (e, action) => {
    if (action == 'from') {
      setModifiedOn((perVal) => ({ ...perVal, from: e }));
    } else if (action == 'to') {
      setModifiedOn((perVal) => ({ ...perVal, to: e }));
    }
  };

  let handleApplyFilter = async (action) => {
    if (action === defectAnalyticsLabel.btnLabel.apply) {
      getModuleTree(IsHideAutoManualCombination, searchedTextQuery, action);
    } else if (action === defectAnalyticsLabel.btnLabel.clearAll) {
      setScriptType([]);
      setCreatedBy([]);
      setLabels([]);
      setModifiedBy([]);
      setCreatedOn({ from: null, to: null });
      setModifiedOn({ from: null, to: null });
      setIsHideAutoManualCombination(false);
      setTypeSwitch(false);

      if (isFilterApplied) {
        setIsFilterApplied(false);
        getModuleTree(false, searchedTextQuery, action);
        sessionStorage.removeItem('allLevelModuleTreeFilters');
      }
    }
  };

  let resetFilterOnCloseModal = () => {
    setTreeValue('');
    setSearchedText('');
    setSearchedTextQuery('');
    setScriptType([]);
    setCreatedBy([]);
    setLabels([]);
    setModifiedBy([]);
    setCreatedOn({ from: null, to: null });
    setModifiedOn({ from: null, to: null });
    setIsFilterApplied(false);
    setIsHideAutoManualCombination(false);
    setTypeSwitch(false);
    sessionStorage.removeItem('allLevelModuleTreeFilters');
    setHideFiterOnViewMaximizeTreeModel(false);
  };

  // TODO :: disable apply and clear all button

  useEffect(() => {
    if (!isEmptyValue([...scriptType, ...createdBy, ...labels, ...modifiedBy])) {
      setDisableApplyClearBtn(false);
    } else if ((createdOn.from && createdOn.to) || (modifiedOn.from && modifiedOn.to)) {
      setDisableApplyClearBtn(false);
    } else if (IsHideAutoManualCombination && displaySwitch(propSuiteType)) {
      setDisableApplyClearBtn(false);
    } else {
      setDisableApplyClearBtn(true);
    }
  }, [IsHideAutoManualCombination, scriptType, createdBy, labels, modifiedBy, createdOn, modifiedOn]);

  // TODO :: HANDLE FILTERS :: END
  const getSuiteElement = (suiteTypeList) => {
    if (!suiteTypeList.includes('Manual') && suiteTypeList.includes('Automation'))
      return <p className="p-2 ml-0.5 text-sm text-gray-600">Automation</p>;
    else if (!suiteTypeList.includes('Automation') && suiteTypeList.includes('Manual'))
      return <p className="p-2 ml-0.5 text-sm text-gray-600">Manual</p>;
    else
      return (
        <RadioButton
          radioList={suiteTypeList}
          rerunMode={rerunMode || operation === 'update'}
          editData={suiteType}
          radioName="suiteType"
          func={handleSuiteType}
          hasAutomationAndManual={hasAutomationAndManual}
        />
      );
  };

  return (
    <div>
      {memoryWarningPopup && (
        <MemoryWarningPopup
          handleCancel={() => setMemoryWarningPopup(false)}
          handleContinue={async () => {
            await autoRerunExeFunction(true);
            setMemoryWarningPopup(false);
          }}
          isOpen={memoryWarningPopup}
        />
      )}
      {!previewScreen ? (
        <form onSubmit={formik.handleSubmit}>
          {rerunMode ? (
            <div className="content_area_header_style create-suite-header-style">
              <div>
                <Tooltip title={suiteName} placement="bottom">
                  <label className="main-header-label  float-left ">
                    Rerun failed Scripts -{suiteName.length > 32 ? suiteName.substring(0, 32) + '...' : suiteName}{' '}
                  </label>
                </Tooltip>
              </div>

              <div className="flex flex-row float-right">
                <button className="gray-btn mr-3" onClick={goToSuiteDashboard}>
                  Cancel
                </button>
                <button className="primary-btn mr-3" disabled={onLoading} onClick={() => autoRerunExeFunction(false)}>
                  Run
                </button>
              </div>
            </div>
          ) : (
            <div className={cx('content_area_header_style create-suite-header-style', { 'mt-5': isEmailDropdownOpen })}>
              <div>
                <label className="main-header-label float-left">
                  {operation === 'update' ? 'Edit Suite' : 'Create Suite'}
                </label>
              </div>

              <div className="flex flex-row float-right">
                <button className="gray-btn mr-3" type="button" onClick={goToSuiteTable}>
                  Cancel
                </button>
                {operation === 'update' ? (
                  ''
                ) : (
                  <button className="secondary-btn mr-3">
                    <span onClick={clearFunc}>Reset</span>
                  </button>
                )}
                {
                  <button
                    type="button"
                    className="secondary-btn mr-3"
                    onClick={() => {
                      showAlertMessagee();
                      runSettingValidation();
                    }}
                  >
                    <span>Preview</span>
                  </button>
                }
                <button
                  type="submit"
                  disabled={createUpdateCalled}
                  className="primary-btn mr-3"
                  onClick={() => {
                    if (!wrongPattern && formik.values.suiteName?.length > 2) {
                      const _validity = runSettingValidation();
                      if (_validity) createSuite(operation === 'update' ? 'Update' : 'Create');
                    }
                    setSuiteNameClicked(true);
                  }}
                >
                  <span>{operation === 'update' ? 'Update' : 'Create'}</span>
                </button>
              </div>
            </div>
          )}

          <div className="accordian-style mt-3 pb-4" id="journal-scroll">
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>
                  <img
                    src="\assets\execution_logos\suitcase_icon.svg"
                    className="flex flex-row mt-0.5 mr-2 float-left"
                    height="17px"
                    width="17px"
                  ></img>
                  {stepperArry[0]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="flex flex-row">
                    <div className="w-2/5 left-border-style mt-3">
                      <label htmlFor="suiteName" className="input-filed-label-style-common">
                        <span class="text-red-400">*</span> Name
                      </label>
                      <br></br>

                      <input
                        type="text"
                        value={suiteName}
                        disabled={rerunMode}
                        placeholder="Enter suite name"
                        onChange={formik.handleChange}
                        name="suiteName"
                        id="suiteName"
                        autocomplete="off"
                        className={`max-w-lg block w-11/12 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs
                                    sm:text-sm border-gray-300 input-field-style suite-name-input-background ${
                                      rerunMode ? 'opacity-50' : ''
                                    }`}
                      />
                      {formik.errors.suiteName ? (
                        <div className="text-red-500 text-xs md:mt-1">{formik.errors.suiteName}</div>
                      ) : suiteNameClicked && !suiteName ? (
                        <div className="text-red-500 text-xs md:mt-1">Name is Required!</div>
                      ) : suiteNameClicked && wrongPattern ? (
                        <div className="text-red-500 text-xs md:mt-1">
                          <div className="text-red-500 text-xs md:mt-1">Name should be alphanumeric</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="w-3/5 mt-3">
                      <label htmlFor="Description" className="input-filed-label-style-common">
                        Description
                      </label>
                      {rerunMode ? (
                        <Tooltip title={suiteObjMain?.description ? suiteObjMain?.description : ''}>
                          <textarea
                            id="description"
                            name="description"
                            disabled
                            value={description}
                            placeholder="Your description goes here..."
                            onChange={formik.handleChange}
                            onKeyUp={(e) => setdescCount(e.target.value.length)}
                            rows="1"
                            maxLength="200"
                            className={`input-style-textarea block border-0 border-b input-field-style-textarea opacity-50`}
                          ></textarea>
                        </Tooltip>
                      ) : (
                        <textarea
                          id="description"
                          name="description"
                          value={description}
                          placeholder="Your description goes here..."
                          onChange={formik.handleChange}
                          onKeyUp={(e) => setdescCount(e.target.value.length)}
                          rows="1"
                          maxLength="200"
                          className={`input-style-textarea block border-0 border-b input-field-style-textarea`}
                        ></textarea>
                      )}
                      <div className="mt-1 mr-5 text-sm text-gray-500 text-right">{descCount}/200</div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="left-border-style">
                        <label htmlFor="suiteName" className="input-filed-label-style-common">
                          <span class="text-red-400">*</span> Suite Type
                        </label>
                        <br></br>
                        <>{getSuiteElement(suiteTypeList)}</>
                      </div>
                      <Modal open={showModal} className="modal-dialog" disableEnforceFocus>
                        <div
                          className={`${
                            selectedTree === 'parentTree' ? 'module-modal-size' : 'max-popup-style'
                          } modal-container`}
                        >
                          <div className="modal-header flex justify-between">
                            {selectedTree === 'parentTree' ? (
                              <label className="Popup-header-common">Select Modules / Scripts</label>
                            ) : (
                              <label className="Popup-header-common">Modules / Scripts</label>
                            )}
                            {!displaySearchFilter && selectedTree === 'parentTree' ? suiteTypeSwitch() : null}
                            {!displaySearchFilter && selectedTree === 'parentTree' ? (
                              propSuiteType?.toLowerCase() === 'manual' ? (
                                <label className="hidetest">Hide Manual Test Cases with Automation Scripts</label>
                              ) : (
                                <label className="hidetest">Hide Automation Scripts with Manual Test Cases </label>
                              )
                            ) : null}
                            <div className="flex flex-1 justify-end mr-8">
                              {/* this code is commented for future requirement */}
                              {/* <TreeSearchBar
                                                                        disabled={disableSearchFilter}
                                                                        searchTree={searchTree}
                                                                        resetTree={resetTreeSearch}
                                                                        setSearchedText={setSearchedText}
                                                                    /> */}

                              {/* TODO :: FILTER BLOCK :: START */}

                              {!hideFiterOnViewMaximizeTreeModel && displaySearchFilter && (
                                <FilterModal
                                  open={openFilterModal}
                                  anchorEl={anchorEl}
                                  onClose={handleFilterClose}
                                  handleFilterOpen={handleFilterOpen}
                                  onApply={handleApplyFilter}
                                  isFilterApplied={isFilterApplied}
                                  disableFilters={disableSearchFilter}
                                  disableClearAllBtn={disableApplyClearBtn}
                                  disableApplyBtn={disableApplyClearBtn}
                                  renderCheckbox={<SuiteTypeSwitch />}
                                >
                                  <div
                                    className={
                                      showSelectedList?.some((val) => val[Object?.keys(val)]?.length > 0)
                                        ? 'border-2 rounded mb-2 max-h-40 overflow-auto'
                                        : 'hidden'
                                    }
                                  >
                                    {showSelectedList?.map((obj, index) => {
                                      let itemDisplay = obj[Object?.keys(obj)];
                                      if (!isEmptyValue(itemDisplay)) {
                                        return (
                                          <div className="p-2" key={index + 1}>
                                            <div className="rs-blue capitalize fontPoppinsSemiboldSm border-b-2">
                                              {getSelectedFilterLableName(Object?.keys(obj)?.[0])}
                                            </div>
                                            <div className="chips-section flex flex-wrap p-1">
                                              {renderChips(Object?.keys(obj)?.[0])?.map((val, chipIndex) => {
                                                return (
                                                  <Chip
                                                    key={chipIndex + 2}
                                                    text={trimModuleName(val)}
                                                    title={val}
                                                    onClick={(e) =>
                                                      handleSelectDropdown(e, 'delete', Object.keys(obj)?.[0])
                                                    }
                                                  />
                                                );
                                              })}
                                            </div>
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>

                                  <div className="grid grid-rows-1 grid-flow-col gap-2">
                                    <div className=" flex gap-2 mt-1 ">
                                      <FormControl sx={{ width: 264 }}>
                                        <label className="fontPoppinsRegularSm mb-1"> Script Type</label>
                                        <MultipleSelectCheckmarks
                                          options={optionsListItem['scriptType'] || []}
                                          onChange={(e) => handleSelectDropdown(e, 'add', 'scriptType')}
                                          value={scriptType}
                                        />
                                      </FormControl>

                                      <FormControl sx={{ width: 264 }}>
                                        <label className="fontPoppinsRegularSm mb-1"> Labels </label>
                                        <MultipleSelectCheckmarks
                                          options={optionsListItem['labels'] || []}
                                          onChange={(e) => handleSelectDropdown(e, 'add', 'labels')}
                                          value={labels}
                                        />
                                      </FormControl>

                                      {/* <FormControl sx={{ width: 173 }}>
                                                                            <label className="fontPoppinsRegularSm mb-1">Created By</label>
                                                                            <MultipleSelectCheckmarks
                                                                                options={optionsListItem["createdBy"] || []}
                                                                                onChange={(e) => handleSelectDropdown(e, "add", "createdBy")}
                                                                                value={createdBy}
                                                                            />
                                                                            </FormControl> */}
                                    </div>

                                    {/* <div className=" flex gap-2 mt-1 " >
                                                                            
                                                                            <FormControl sx={{ width: 173 }}>
                                                                            <label className="fontPoppinsRegularSm mb-1">Modified By</label>
                                                                            <MultipleSelectCheckmarks
                                                                                options={optionsListItem["modifiedBy"] || []}
                                                                                onChange={(e) => handleSelectDropdown(e, "add", "modifiedBy")}
                                                                                value={modifiedBy}
                                                                            />
                                                                            </FormControl>

                                                                            <FormControl sx={{ width: 173 }}>
                                                                            <label className="fontPoppinsRegularSm mb-1">Created On</label>
                                                                            <DateSelector
                                                                                inputFormat={filterDisplayDateFormat}
                                                                                value={createdOn.from}
                                                                                onChange={(e) => handleCreatedOnDate(e, "from")}
                                                                                maxDate={createdOn?.to || new Date()} 
                                                                                label="From Date"
                                                                                
                                                                            />
                                                                            </FormControl>

                                                                            <FormControl sx={{ width: 173 }}>
                                                                            <label className="fontPoppinsRegularSm mb-1">&nbsp;</label>
                                                                            <DateSelector
                                                                                inputFormat={filterDisplayDateFormat}
                                                                                value={createdOn.to}
                                                                                onChange={(e) => handleCreatedOnDate(e, "to")}
                                                                                minDate={createdOn?.from}
                                                                                maxDate={new Date()}
                                                                                label="To Date"
                                                                                disabled={!createdOn.from}
                                                                            />
                                                                            </FormControl>

                                                        
                                                                        </div> */}

                                    {/* <div className=" flex gap-2 mt-1 " >
                                                                            

                                                                            <FormControl sx={{ width: 173 }}>
                                                                            <label className="fontPoppinsRegularSm mb-1">Modified On</label>
                                                                            <DateSelector
                                                                                inputFormat={filterDisplayDateFormat}
                                                                                value={modifiedOn.from}
                                                                                onChange={(e) => handleModifiedOnDate(e, "from")}
                                                                                maxDate={modifiedOn?.to || new Date()} 
                                                                                label="From Date"
                                                                                
                                                                            />
                                                                            </FormControl>

                                                                            <FormControl sx={{ width: 173 }}>
                                                                            <label className="fontPoppinsRegularSm mb-1">&nbsp;</label>
                                                                            <DateSelector
                                                                                inputFormat={filterDisplayDateFormat}
                                                                                value={modifiedOn.to}
                                                                                onChange={(e) => handleModifiedOnDate(e, "to")}
                                                                                minDate={modifiedOn?.from}
                                                                                maxDate={new Date()}
                                                                                label="To Date"
                                                                                disabled={!modifiedOn.from}

                                                                            />
                                                                            </FormControl>

                                                                            
                                                                        </div> */}
                                  </div>
                                </FilterModal>
                              )}

                              {/* TODO :: FILTER BLOCK:: END*/}
                            </div>

                            <IconButton
                              color="primary"
                              component="span"
                              className="close"
                              onClick={() => {
                                if (selectedTree === 'parentTree') {
                                  setTreeDataFlag(false);
                                }
                                selectedTreeDataArray = prevAllSelectedNodes;
                                selectedModuleAndScript = prevSelectedModuleAndScript;
                                setPreSelectedType(suiteType);
                                setShowModal(false);
                                setTypeSwitch(false);
                                propHandleTypeSwitch(typeSwitch);
                                handleApplyFilter();
                                resetFilterOnCloseModal();
                                totalScriptCount = currentSelectedScriptCount;
                                setCurrentSelectedScriptCount();
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                          {selectedTree === 'parentTree' ? (
                            <div className="modal-body" id="journal-scroll">
                              <div className="flex flex-row">
                                <div className="module-popup-layout">
                                  {renderTreeModel}
                                  {isTreeLoading && isLoading && !disableSearchFilter && (
                                    <div className="grid h-80">
                                      <div className="flex flex-1 justify-center items-center">Loading...</div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="maximize-tree-size">{renderMaximizeTreeModel}</div>
                            </div>
                          )}
                          {selectedTree === 'parentTree' ? (
                            <div className="modal-footer">
                              <button
                                className="gray-btn mr-3"
                                onClick={() => {
                                  selectedTreeDataArray = prevAllSelectedNodes;
                                  selectedModuleAndScript = prevSelectedModuleAndScript;
                                  setShowModal(false);
                                  setTreeDataFlag(false);
                                  setTypeSwitch(false);
                                  setPreSelectedType(suiteType);
                                  propHandleTypeSwitch(typeSwitch);
                                  resetFilterOnCloseModal();
                                  totalScriptCount = currentSelectedScriptCount;
                                  setCurrentSelectedScriptCount();
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="primary-btn"
                                disabled={disableBtn}
                                onClick={() => {
                                  SelectedModules();
                                }}
                              >
                                Select
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </Modal>

                      <Modal open={openModal} className="modal-dialog">
                        <div className="modal-container page-modal-size">
                          <div className="modal-header ">
                            <label className="Popup-header-common Popup-header-layout-style-user">
                              User Details - {selectedNodeForUser?.data?.modifiedByUname}
                            </label>
                            <IconButton
                              color="primary"
                              component="span"
                              className="close"
                              onClick={() => {
                                setOpenModal(false);
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                          <div className="modal-body" id="journal-scroll">
                            <UserDetailsPage modifiedBy={selectedNodeForUser?.data?.modifiedBy} />
                          </div>
                        </div>
                      </Modal>
                      {!selectedNod?.length ? (
                        <button
                          className="secondary-btn mr-3 left-border-style top-border-style"
                          type="button"
                          onClick={() => {
                            selectModule('parentTree');
                            setPreselectedValue(moduleTree);
                          }}
                          disabled={moduleScriptButton}
                        >
                          <span className="select-module-label-style">Select Modules/Scripts</span>
                        </button>
                      ) : (
                        <div className="module-area-style">
                          <div className="content_area_header_style">
                            <div>
                              <label className="main-header-label float-left ">Modules / Scripts</label>
                            </div>
                            <div className="flex flex-row float-right">
                              {rerunMode === false ? (
                                <button
                                  className="secondary-btn mr-3"
                                  type="button"
                                  onClick={() => {
                                    selectModule('parentTree');
                                    setPreselectedValue(moduleTree);
                                  }}
                                  disabled={moduleScriptButton}
                                >
                                  <span className="select-module-label-style">Select Modules/Scripts</span>
                                </button>
                              ) : (
                                <button
                                  className="secondary-btn-disable opacity-50 mr-3 cursor-auto"
                                  type="button"
                                  disabled
                                  onClick={() => {
                                    selectModule('parentTree');
                                    setPreselectedValue(moduleTree);
                                  }}
                                >
                                  <span className="">Select Modules/Scripts</span>
                                </button>
                              )}
                              <FiMaximize2
                                title="Maximize"
                                className={`${
                                  selectedTreeDataArray.length ? 'maximize-btn-style' : ''
                                } mt-2 mr-3 cursor-pointer`}
                                onClick={() => {
                                  selectModule('childTree');
                                  setHideFiterOnViewMaximizeTreeModel(true);
                                }}
                              />
                            </div>
                          </div>
                          <div className="table_tree_height overflow-y">{renderTree}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            {suiteType !== 'Manual' ? (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                  <Typography>
                    <span className="variable-icon-style">(X)</span>
                    {stepperArry[1]}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <ProjectGlobalVariable
                      {...{
                        createSuiteMainFunctionForProjectVariable,
                        operation,
                        suiteObjMain,
                        createAlertFun,
                        suiteObjForPreview,
                        rerunMode,
                      }}
                    />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ) : null}

            {suiteType !== 'Manual' ? (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                  <Typography>
                    <img
                      src="\assets\execution_logos\list_icon.svg"
                      className="flex flex-row icon-style float-left"
                      height="17px"
                      width="17px"
                    ></img>
                    {stepperArry[2]}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <TestData
                      {...{
                        createTestDataFunction,
                        operation,
                        suiteObjMain,
                        createAlertFun,
                        suiteObjForPreview,
                        getChangedFile,
                        rerunMode,
                      }}
                    />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ) : null}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                <Typography>
                  {' '}
                  <img
                    src="\assets\execution_logos\execution_setting_icon.svg"
                    className="flex flex-row icon-style float-left"
                    height="17px"
                    width="17px"
                  ></img>
                  {isManualSuiteType ? stepperArry[5] : stepperArry[3]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <SuiteRunSettings
                    suiteType={suiteType || 'Automation'}
                    suiteObjMain={suiteObjMain || suiteObjForPreview}
                    rootData={propRootData}
                    {...{
                      createAlertFun,
                      setExeTermintion,
                      numberOfParallelRuns,
                      selectedSuiteObjForExecution,
                      setmachineDetails,
                      moduleArray,
                      runSettingsObject,
                      setResultSetting,
                      setWaitTime,
                      runSettingsManualObject,
                      operationForRunSettings,
                      rerunMode,
                    }}
                  />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                <Typography>
                  {' '}
                  <img
                    src="\assets\execution_logos\email_icon.svg"
                    className="flex flex-row icon-style float-left"
                    height="17px"
                    width="17px"
                  ></img>
                  {stepperArry[4]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <EmailPage
                    {...{
                      setIsEmailDropdownOpen,
                      createSuiteMainFunction,
                      rerunMode,
                      operation,
                      suiteObjMain,
                      suiteObjForPreview,
                      updateEmailData,
                      setUpdateEmailData,
                      suiteType,
                      createAlertFun,
                    }}
                  />
                  <SlackPage
                    {...{
                      setIsEmailDropdownOpen,
                      createSuiteSlackFunction,
                      rerunMode,
                      operation,
                      suiteObjMain,
                      suiteObjForPreview,
                      updateEmailData,
                      setUpdateEmailData,
                      suiteType,
                      createAlertFun,
                    }}
                  />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </form>
      ) : (
        <div className="overflow-auto" id="journal-scroll">
          <SuitePreview
            func={navigateToCreateScreen}
            name={suiteName}
            suiteObj={suiteObjForPreview}
            waitTime={waitTimeObj}
            resultSetting={resultSettingObj}
            exeTermination={exeTerminationObj}
            emailObj={emailData}
            slacObj={slackData}
            runSettingsData={runSettingsObject || runSettingsManualObject || {}}
            machineObj={getMachineObj()}
            {...{
              syncScripts,
              executionType,
              description,
              projectGlobalVarData,
              testData,
              moduleArray,
              suiteType,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ModuleScript;
