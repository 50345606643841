import React, { useEffect, useState, useContext, useRef } from 'react';
import Modal from 'react-modal';
import '@pages/test-development/test-development.scss';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl, Select, TextField } from '@material-ui/core';
import { MAP_TO_JIRA } from '@src/common/ui-constants';
import { MultiSelectSearchableDropdown } from '@src/pages/configuration/plugin/gitlab/select-dropdown';
import { scrollIntoView } from '../utils/common-functions';
import './styles/dropdown.scss';
import * as yup from 'yup';
import { Field, Formik, useFormik } from 'formik';
import { getAllJiraProjects, getAllJiraIds, mapScriptToJira, getMappedJira } from '@src/api/api_services';
import { getCurrentProjectId, isEmptyObject } from '@util/common_utils';
import { Checkbox } from 'fireflink-ui';

function MapToJiraModal(props) {
  const [isBothTestCaseType, setisBothTestCaseType] = useState(false);
  const testCaseData = props?.scriptData?.data?.testCaseType;
  const [openModal, setOpenModal] = useState(true);
  const [selectedLabel, setSelectedLabel] = useState([]);
  const [jiraUserName, setJiraUserName] = useState();
  const [jiraApiToken, setJiraApiToken] = useState();
  const [projectKey, setProjectKey] = useState();
  const [domainData, setDomainData] = useState();
  const [jiraProjects, setJiraProjects] = useState();
  const [jiarIds, setJiraIds] = useState([]);
  const [disable, setDisable] = useState(true);
  const [mappedData, setMappedData] = useState();
  const [mappedTypes, setMappedTypes] = useState();
  const [defaultSelectedValues, setDefaultSelectedValues] = useState([]);
  const [inputValueSheet, setInputValueSheet] = useState('');
  const [inputValueProject, setInputValueProject] = useState('');
  const [labels, setLabels] = useState([]);
  const [mappedAutomation, setMappedAutomation] = useState(false);
  const [mappedManual, setMappedManual] = useState(false);
  const [createCalled, setCreateCalled] = useState(false);
  const [initialDropDown, setInitalDropDown] = useState(0);
  const [jiraScrollPos, setJiraScrollPos] = useState(false);
  const [stopScrollApiCall, setStopScrollApiCall] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isSearch, setIsSearch] = useState(false);
  const [searchReset, setSearchReset] = useState(false);
  const [searchedText, setSearchedText] = useState('');
  const [editMode, setEditMode] = useState(false);
  let [isDomainDown, setIsdomainDown] = useState({
    status: false,
    message: '',
  });

  let initialValues = {
    domain: mappedTypes?.jiraDomain || '',
    projectName: mappedTypes?.projectName || '',
    jiraId: mappedTypes?.linkIds || '',
    automation: mappedTypes?.automationMapped,
    manual: mappedTypes?.manualMapped,
  };
  const jiraDropdownRef = useRef();

  const validationSchema = yup.object({
    domain: yup.string().ensure().required('Domain name is required'),
    projectName: yup.string().ensure().required('Project name is required'),
    jiraId: yup.object().nullable().required('Jira Id is required'),
  });
  const onSubmit = (values) => {
    if (
      formikDetails.values.domain !== '' &&
      formikDetails.values.projectName !== '' &&
      formikDetails.values.jiraId !== ''
    ) {
      if (!isSubmitting) {
        setIsSubmitting(true);
        createMapScript();
      }
    }
  };
  const formikDetails = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    formikDetails.setValues({
      ...formikDetails.values,
      projectName: mappedTypes?.projectName || '',
      domain: mappedTypes?.jiraDomain || '',
      jiraId: mappedTypes?.linkIds || '',
    });
  }, [formikDetails.setValues, mappedTypes?.projectName]);

  const customStylesFolder = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: '510px',
      width: '600px',
      boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
      background: '#fbfcfd 0% 0% no-repeat padding-box',
      borderRadius: '4px',
      opacity: '1',
    },
  };

  const hasManual = testCaseData?.some((data) => data.type === 'manual');
  const hasAutomation = testCaseData?.some((data) => data.type === 'Automation');
  useEffect(() => {
    if (hasManual && hasAutomation) {
      setisBothTestCaseType(true);
    } else {
      setisBothTestCaseType(false);
    }
  }, [mappedTypes]);

  const getScriptIdByType = (testCaseData, scriptType) => {
    const script = testCaseData.find((data) => data.type === scriptType);
    return script ? script.id : '';
  };

  const automationScripId = getScriptIdByType(testCaseData, 'Automation');
  const manualScripId = getScriptIdByType(testCaseData, 'manual');
  const moduleId = props?.scriptData?.data?.parentId;
  const scriptName = props?.scriptData?.data?.name;
  const scriptPrefix = props?.scriptData?.data?.prefix;
  const scriptId = props?.scriptData?.data?._key;

  useEffect(() => {
    if (jiarIds?.length) {
      setLabels([
        ...jiarIds.map((data) => ({
          ...data,
          label: data?.key + ' ' + data?.summery,
          value: data?.key + ' ' + data?.summery,
        })),
      ]);
    } else {
      setLabels([]);
    }
  }, [jiarIds]);

  const mapJiraIds = (values, selectedjiarIds) => {
    const mappedIds = {};

    values.forEach((selectedValue) => {
      const matchingEntry = selectedjiarIds.find((entry) => entry.id === selectedValue.id);
      if (matchingEntry) {
        mappedIds[matchingEntry.key] = selectedValue.id;
      }
    });

    return mappedIds;
  };

  const handleMultiSelectChange = (values) => {
    const mappedIds = mapJiraIds(values, values);
    const formikFormattedResult = Object.entries(mappedIds).reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
    setSelectedLabel([...values]);
    const newValues = values.length ? formikFormattedResult : null;
    formikDetails.setFieldValue('jiraId', newValues);
  };

  function handleProjectChange(value) {
    formikDetails.setFieldValue('projectName', value);
    const matchingProjectName = jiraProjects && jiraProjects.find((obj) => obj.name === value);
    if (matchingProjectName) {
      setProjectKey(matchingProjectName.key);
    }
  }

  function handleDomainChange(value) {
    formikDetails.setFieldValue('domain', value);
    formikDetails.setFieldValue('projectName', '');
    formikDetails.setFieldValue('jiraId', '');
    setSelectedLabel([]);
    setJiraProjects([]);
    setJiraIds([]);
    setEditMode(true);
    setStopScrollApiCall(false);
    const matchingObject = props.domainDetails.find((obj) => obj.domain === value);
    setDomainData(matchingObject);
    if (matchingObject) {
      setJiraUserName(matchingObject.userName);
      setJiraApiToken(matchingObject.apiToken);
    }
  }
  function handleManualCheck(value) {
    formikDetails.setFieldValue('manual', value.target.checked);
    setMappedManual(value.target.checked);
  }
  function handleAutomationCheck(value) {
    formikDetails.setFieldValue('automation', value.target.checked);
    setMappedAutomation(value.target.checked);
  }

  const domainUserName =
    props &&
    props.domainDetails &&
    props.domainDetails.find((val) => {
      return val;
    });

  const projectId = getCurrentProjectId();

  const getJiraProjects = () => {
    getAllJiraProjects(domainData.userName, domainData.apiToken, domainData.domain)
      .then((response) => {
        if (response?.data?.responseCode === 400) {
          setIsdomainDown({
            status: true,
            message: response?.data?.responseObject,
          });
        } else if (response?.data?.responseCode === 200 && response?.data?.responseObject) {
          setJiraProjects(response?.data?.responseObject);
          setIsdomainDown({ status: false, message: '' });
        }
      })
      .catch((error) => {
        console.error('Failed for getJiraProjects api', error);
      });
  };
  const getJiraIds = (paraData) => {
    const foundDomain = props?.domainDetails?.find((obj) => obj.domain === formikDetails.values.domain);
    let payload = {
      userName: foundDomain?.userName || domainData.userName,
      apiToken: foundDomain?.apiToken || domainData.apiToken,
      domain: foundDomain?.domain || domainData.domain,
      pageSize: paraData?.pageSize || initialDropDown,
      scriptPrefix: scriptPrefix,
      moduleId: moduleId,
      searchedText: paraData?.searchVal || searchedText || '',
    };

    if (paraData?.pageSize === 0 && paraData?.searchVal === '') {
      payload['pageSize'] = paraData?.pageSize;
      payload['searchedText'] = paraData?.searchVal;
    }

    if (stopScrollApiCall === false || paraData?.stopApiCall === false) {
      getAllJiraIds(payload)
        .then((response) => {
          if (response.data.status === 'NO_CONTENT' && response.data.responseCode === 204) {
            setStopScrollApiCall(true);
            if (payload.pageSize == 0) {
              setJiraIds([]);
            }
          }
          if (response?.data?.responseObject && response.data.responseCode === 200) {
            if (paraData?.pageSize === 0) {
              setJiraIds(response?.data?.responseObject);
            } else {
              setJiraIds([...jiarIds, ...response?.data?.responseObject]);
            }

            if (!isEmptyObject(foundDomain)) {
              const filteredJiraIds = response?.data?.responseObject?.filter((jiraId) => {
                return Object.keys(mappedTypes?.linkIds)?.includes(jiraId?.key);
              });
              filteredJiraIds?.map((Obj) => {
                Obj.label = Obj?.key;
                Obj.value = Obj?.key + ' ' + Obj?.summery;
                return Obj;
              });
              setSelectedLabel([...selectedLabel, ...filteredJiraIds]);
            }
          }
        })
        .catch((error) => {
          console.error('Failed for getJiraIds api', error);
        });
    }
  };

  useEffect(() => {
    if (initialDropDown && isSearch && !stopScrollApiCall && searchedText) {
      getJiraIds({ pageSize: 0, searchVal: searchedText });
    } else if (initialDropDown && !stopScrollApiCall && !isSearch) {
      getJiraIds();
    }
  }, [initialDropDown]);

  useEffect(() => {
    if (jiraScrollPos) {
      setInitalDropDown(initialDropDown + 1);
    }
  }, [jiraScrollPos]);

  const matchingDomain = props?.domainDetails?.find((obj) => obj.domain === formikDetails.values.domain);

  useEffect(() => {
    const foundDomain = props?.domainDetails?.find((obj) => obj.domain === formikDetails.values.domain);
    if (foundDomain && !createCalled && props.mappedDetails) {
      setCreateCalled(true);
      let payload = {
        userName: foundDomain?.userName,
        apiToken: foundDomain?.apiToken,
        domain: foundDomain?.domain,
        pageSize: initialDropDown,
        moduleId: moduleId,
        searchedText: '',
        scriptPrefix: scriptPrefix,
      };
      getAllJiraIds(payload)
        .then((response) => {
          setEditMode(true);
          if (response?.data?.responseObject) {
            const tempJiraIds = response?.data?.responseObject;
            setJiraUserName(matchingDomain.userName);
            setJiraApiToken(matchingDomain.apiToken);
            const filteredJiraIds =
              tempJiraIds &&
              tempJiraIds?.filter((jiraId) => {
                return Object.keys(mappedTypes?.linkIds).includes(jiraId?.key);
              });
            filteredJiraIds?.map((Obj) => {
              Obj.label = Obj.key;
              Obj.value = Obj.key + ' ' + Obj.summery;
              return Obj;
            });
            setJiraIds(response?.data?.responseObject);
            setSelectedLabel(filteredJiraIds);
          }
        })
        .catch((error) => {
          console.error('Failed for getAllJiraIds api', error);
        });
    }
  }, [props, formikDetails.values.domain, createCalled, props.mappedDetails]);

  useEffect(() => {
    if (domainData) {
      getJiraProjects();
      if (!props.mappedDetails) {
        getJiraIds();
      }
    }
  }, [domainData]);

  useEffect(() => {
    if (props?.mappedDetails) {
      setMappedData(props?.mappedDetails?.linkIds);
      setMappedTypes(props?.mappedDetails);
      setMappedAutomation(props?.mappedDetails?.automationMapped);
      setMappedManual(props?.mappedDetails?.manualMapped);
      setProjectKey(props?.mappedDetails?.projectKey);
    }
  }, [props?.mappedDetails]);

  const differences = {};

  for (const key in mappedTypes?.linkIds) {
    if (!formikDetails.values?.jiraId?.hasOwnProperty(key)) {
      differences[key] = mappedTypes?.linkIds[key];
    }
  }
  const formikTestcases = {
    automationMapped: mappedAutomation,
    manualMapped: mappedManual,
  };

  const mappedTestCases = {
    automationMapped: props?.mappedDetails?.automationMapped,
    manualMapped: props?.mappedDetails?.manualMapped,
  };

  useEffect(() => {
    const hasMappedDetails = props?.mappedDetails;
    const linkIdsMatch = JSON.stringify(props?.mappedDetails?.linkIds) === JSON.stringify(formikDetails.values.jiraId);

    if (hasMappedDetails) {
      if ((!isBothTestCaseType && linkIdsMatch) || !formikDetails?.values?.jiraId) {
        setDisable(true);
      } else if (JSON.stringify(formikTestcases) === JSON.stringify(mappedTestCases) && linkIdsMatch) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    } else {
      const { domain, projectName, jiraId } = formikDetails.values;

      if (domain !== '' && projectName !== '' && jiraId) {
        if (mappedAutomation || mappedManual || !isBothTestCaseType) {
          setDisable(false);
        } else {
          setDisable(true);
        }
      } else {
        setDisable(true);
      }
    }
  }, [props?.mappedDetails, formikDetails]);

  const createMapScript = () => {
    let data = {
      jiraDomain: formikDetails.values.domain,
      jiraUserName: jiraUserName,
      jiraApiToken: jiraApiToken,
      moduleId: moduleId,
      automationScriptId: automationScripId,
      manualScriptId: manualScripId,
      scriptName: scriptName,
      projectKey: projectKey,
      projectName: formikDetails.values.projectName,
      automation: hasAutomation && !hasManual ? true : mappedAutomation,
      manual: hasManual && !hasAutomation ? true : mappedManual,
      linkIds: formikDetails.values.jiraId,
      unLinkIds: differences,
      fireFlinkProjectId: projectId,
      scriptPrefix: scriptPrefix,
    };
    mapScriptToJira(data)
      .then((response) => {
        setIsSubmitting(false);
        if (response?.data?.responseCode === 200 && response.data.status === 'SUCCESS') {
          props.MyAlert.success(response.data.message);
          props.closeModal(false);
          props.reloadTree();
        }
      })
      .catch((error) => {
        console.error('Failed for MapScriptapi', error);
      });
  };

  if (domainData && mappedData && jiarIds) {
    const filterData = Object.keys(mappedData)
      .filter((mappedKey) => jiarIds.some((jiraObject) => jiraObject && jiraObject.key === mappedKey))
      .map((mappedKey) => {
        const matchingObject = jiarIds.find((jiraObject) => jiraObject && jiraObject.key === mappedKey);
        return matchingObject || {};
      });
    setDefaultSelectedValues(filterData);
  }

  const autoMationcheckboxStyles = {
    opacity: mappedTypes?.automationMapped === true ? 0.5 : '',
    color: mappedTypes?.automationMapped === true ? 'blue' : '',
  };
  const manualcheckboxStyles = {
    opacity: mappedTypes?.manualMapped === true ? 0.5 : '',
    color: mappedTypes?.manualMapped === true ? 'blue' : '',
  };
  const isProjectNameDisabled = initialValues.projectName;
  const isDomainDisabled = initialValues.domain;
  const renderOption = (props, option) => {
    const truncatedOption = option.length > 25 ? option.slice(0, 25) + '...' : option;
    return (
      <span title={option.length > 25 ? option : ''} key={option}>
        <li {...props}>{truncatedOption}</li>
      </span>
    );
  };

  //  TODO ::  ON CLICK SEARCH
  let handleJiraIdSearch = (e) => {
    if (e.trim() !== '') {
      setIsSearch(true);
      setSearchedText(e.trim());
      setInitalDropDown(0);
      setStopScrollApiCall(false);
      jiraDropdownRef?.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (!isSearch && searchReset) {
      setStopScrollApiCall(false);
      setInitalDropDown(0);
      setLabels([]);
      setJiraIds([]);
      setSearchedText('');
      setSearchReset(false);
      jiraDropdownRef?.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });

      getJiraIds({ pageSize: 0, searchVal: '', stopApiCall: false }); // NOTE :: RESET ON SEARCH TEXT CLEAR FROM DROPDOWN
    }
    if (isSearch && searchedText) {
      getJiraIds({ pageSize: 0, searchVal: searchedText });
    }
  }, [searchReset, isSearch, searchedText]);

  return (
    <Modal
      isOpen={openModal}
      style={customStylesFolder}
      className="p-px"
      onRequestClose={() => {
        props.closeModal(false);
        setOpenModal(false);
      }}
    >
      <div className="mt-2 p-1">
        <p
          title={props?.scriptData.name}
          className="Popup-header-common inline-block w-10/12 pl-3 text-overflow-style fontPoppinsMediumLg"
        >
          {MAP_TO_JIRA.CLONE_TO_JIRA}
        </p>
        <div className="float-right">
          <button
            onClick={() => {
              setOpenModal(false);
              props.closeModal(false);
            }}
            type="button"
            className="ml-5 mr-5 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="border-b border-t border-blue-100 mt-px mb-px pb-1">
        {isBothTestCaseType && (
          <div className="ml-4 mt-2 flex flex-col">
            <span className="fontPoppinsRegularMd">{MAP_TO_JIRA.WANT_TO_MAP} </span>
            <div className="ml-5 flex flex-col mt-2">
              <div>
                <Checkbox
                  disabled={mappedTypes?.manualMapped}
                  label={MAP_TO_JIRA.MANUAL_TEST_CASE}
                  value={formikDetails?.values?.manual}
                  onChange={handleManualCheck}
                  checked={mappedManual}
                />
              </div>
              <div className="mt-3">
                <Checkbox
                  checked={mappedAutomation}
                  onChange={handleAutomationCheck}
                  label={MAP_TO_JIRA.AUTOMATION_SCRIPT}
                  value={formikDetails?.values?.automation}
                  disabled={mappedTypes?.automationMapped}
                />
              </div>
            </div>
          </div>
        )}
        <div className="modal_body">
          <form onSubmit={formikDetails.handleSubmit}>
            <div className="flex flex-row">
              <div id="autocomplete-wrapper">
                <Autocomplete
                  className="fontPoppinsRegularMd autocomplete-wrapper-jira-mapping w-60 ml-5 mt-10"
                  id="domain"
                  disableClearable
                  options={props?.domainDetails?.map((option) => option?.domain) || []}
                  value={formikDetails.values.domain || ''}
                  onChange={(event, newValue) => {
                    handleDomainChange(newValue);
                  }}
                  onBlur={formikDetails.handleBlur}
                  inputValue={inputValueSheet}
                  onInputChange={(event, newInputValue) => {
                    if (newInputValue === '') {
                      handleDomainChange('');
                    }
                    setInputValueSheet(newInputValue);
                  }}
                  disabled={isDomainDisabled}
                  renderInput={(params) => (
                    <TextField
                      label={
                        <div className="fontPoppinsRegularXLg">
                          {' '}
                          <span className="text-red-400 mr-1">&#42;</span> {MAP_TO_JIRA.DOMAIN}
                        </div>
                      }
                      fullWidth
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={formikDetails.errors.domain && formikDetails.touched.domain}
                      placeholder="Search and select Domain name"
                      variant="standard"
                    />
                  )}
                />
                {formikDetails.errors.domain && formikDetails.touched.domain ? (
                  <div className="errorMessage  fontPoppinsRegularSm absolute ml-6">{formikDetails.errors.domain}</div>
                ) : null}
                {isDomainDown.status && !formikDetails.errors.domain && (
                  <span className="errorMessage  fontPoppinsRegularSm absolute mt-1 ml-5">{isDomainDown.message}</span>
                )}
              </div>
              <div>
                <Autocomplete
                  className="fontPoppinsRegularMd autocomplete-wrapper-jira-mapping w-60 ml-20 mt-10"
                  id="projectName"
                  disableClearable
                  options={(jiraProjects?.length > 0 && jiraProjects?.map((option) => option.name)) || []}
                  value={formikDetails.values.projectName || ''}
                  onChange={(event, newValue) => {
                    handleProjectChange(newValue);
                  }}
                  onBlur={formikDetails.handleBlur}
                  inputValue={inputValueProject}
                  disabled={isProjectNameDisabled}
                  onInputChange={(event, value) => {
                    setInputValueProject(value);
                  }}
                  renderOption={renderOption}
                  renderInput={(params) => (
                    <TextField
                      label={
                        <div className="fontPoppinsRegularXLg">
                          {' '}
                          <span className="text-red-400 mr-1">&#42;</span> {MAP_TO_JIRA.PROJECT}
                        </div>
                      }
                      fullWidth
                      {...params}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={formikDetails.errors.projectName && formikDetails.touched.projectName}
                      placeholder="Search and select Project name"
                      variant="standard"
                      title={formikDetails.values.projectName.length > 25 ? formikDetails.values.projectName : ''}
                    />
                  )}
                />
                {formikDetails.errors.projectName && formikDetails.touched.projectName ? (
                  <div className="errorMessage  fontPoppinsRegularSm absolute ml-20">
                    {formikDetails.errors.projectName}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="mt-10 mb-5 mx-5 ">
              <label
                className={`fontPoppinsRegularMd ${
                  (formikDetails.errors.jiraId && formikDetails.touched.jiraId) && 'text-red-500'
                }`}
              >
                <span className="text-red-400 mr-1 ">&#42;</span> {MAP_TO_JIRA.JIRA_ID}
              </label>
              <div className="fontPoppinsRegularMd ">
                <MultiSelectSearchableDropdown
                  data={labels}
                  placeholder={'Select Jira Id'}
                  handleSelectChange={handleMultiSelectChange}
                  selectedData={selectedLabel}
                  className=""
                  onDropdownOpen={() => {
                    scrollIntoView();
                  }}
                  id="jiraId"
                  name="mapToJira"
                  error={formikDetails.errors.jiraId && formikDetails.touched.jiraId}
                  ref={jiraDropdownRef}
                  setScrollPos={setJiraScrollPos}
                  onBlur={formikDetails.handleBlur}
                  onDropdownClose={() => {
                    formikDetails.setFieldTouched('jiraId', true, true);
                  }}
                  onSearch={handleJiraIdSearch}
                  setSearchReset={setSearchReset}
                  isSearch={isSearch}
                  setIsSearch={setIsSearch}
                  editMode={editMode}
                  setEditMode={setEditMode}
                />
                {formikDetails.errors.jiraId && formikDetails.touched.jiraId ? (
                  <div className="errorMessage  fontPoppinsRegularSm absolute ml-1">{formikDetails.errors.jiraId}</div>
                ) : null}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="">
        <button
          onClick={onSubmit}
          disabled={disable}
          type="submit"
          className="primary-btn float-right mt-3.5 mb-3 mr-5"
        >
          {MAP_TO_JIRA.MAP}
        </button>
        <button
          onClick={() => {
            props.closeModal(false);
            setOpenModal(false);
          }}
          type="button"
          className="gray-btn float-right mt-3.5 mb-3 mr-5"
        >
          {MAP_TO_JIRA.CANCEL}
        </button>
      </div>
    </Modal>
  );
}

export default MapToJiraModal;
