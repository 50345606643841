import React, { useState, useEffect, Fragment } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import RadioButton from '../../common/radio_button';
import { useHistory } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import '../user_page.css';
import { XIcon } from '@heroicons/react/outline';
import {
  createProjectRequest,
  deleteFileReq,
  getFileRequest,
  getFolderTreeReq,
  postFolderReq,
  updateUploadedFile,
  uploadFilesReq,
} from '@api/api_services';
import { updateProjectRequest } from '@api/api_services';
import FileUploadButton from '../../common/fileUpload_button';
import { useAlert } from '../../common/alert_service/useAlert';
import MyInput from '../Inputs/MyInput';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@mui/material/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import './project_create.scss';
import { getLicenseFeatures, isEmptyValue } from '@src/util/common_utils';
import { PROJECT_TYPES } from '@src/common/ui-constants';
const useStyles = makeStyles((theme) => ({
  select: {
    width: '255px',
  },
  root: {
    width: '255px',
  },
}));
const ProjectCreatePage = (props) => {
  const classes = useStyles();
  let [isOpen, setIsOpen] = useState(true);
  const [open, setOpen] = React.useState(false);
  const { AlertContatiner, MyAlert } = useAlert();

  let appTypeData = ['Native', 'Hybrid'];
  let appType = 'appType';
  const licenseFeatures = getLicenseFeatures();
  const [createUpdateCalled, setCreateUpdateCalled] = React.useState(true);
  const hasManualTestCase = window?.privilege?.hasManualTestCase(licenseFeatures);
  const hasDefects = window?.privilege?.hasDefects(licenseFeatures);

  const isProjectOpen = !props.project.status || props.project.status === 'Open';
  const isEditProject = props.popupType === 'Edit Project';

  const initialValues = {
    id: props.project.id,
    projectName: props.project.name,
    description: props.project.desc,
    status: props.project.status,
    type: props.project.type,
    AppType: props.project.appType,
    Url: props.project.url,
    baseUri: props.project.baseUri,
    port: props.project.port,
    Platform: props.project.platform,
    appPackage: props.project.appPackage,
    appActivity: props.project.appActivity,
    bundleId: props.project.bundleId,
    apk: props.project.apkFiles,
    ipa: props.project.ipaFiles,
  };
  const formik = useFormik({
    initialValues: initialValues,

    validationSchema: Yup.object({
      projectName: Yup.string()
        .trim('Space is not allowed at starting and at the end')
        .strict(true)
        .min(3, 'Minimum 3 characters')
        .max(25, 'Maximum 25 characters')
        .matches(/^[a-zA-Z0-9-_ ]+$/, 'Name should be alphanumeric')
        .required('Name is required'),
      type: Yup.string().required('Select project type'),
      AppType: Yup.string().when('type', {
        is: (v) => v?.includes('Mobile'),
        then: Yup.string().required('Select an app type'),
        otherwise: Yup.string().optional(),
      }),
      Platform: Yup.string().when('type', {
        is: (v) => v?.includes('Mobile'),
        then: Yup.string().required('Select a platform'),
        otherwise: Yup.string().optional(),
      }),

      port: Yup.string()
        .matches(/^[0-9\b]+$/, 'Port should be number')
        .min(2, 'Minimum 2 digit')
        .max(5, 'Maximum 5 digit'),
    }),
    onSubmit: (v) => {
      if (createUpdateCalled) {
        setCreateUpdateCalled(false);
        !isEditProject ? createProject() : updateProject();
      }
    },
  });

  function setRadiobuttonVal(dataVal) {
    formik.setFieldValue('AppType', dataVal);
    setCreateUpdateCalled(true);
  }
  function setPlatformData(dataVal) {
    formik.setFieldValue('Platform', dataVal);
  }

  useEffect(() => {
    if (formik?.values?.type?.includes('Mobile')) {
      setPlatformData('Android & iOS');
    }
  }, [formik.values.type]);

  function setDropDownValue(dropValue) {
    handleProjectType({ target: { name: 'type', value: dropValue } });
  }

  const [selectedApkFile, setSelectedApkFile] = useState();
  const [selectedIpaFile, setSelectedIpaFile] = useState();
  const [uploadedApkFile, setUploadedApkFile] = useState();
  const [uploadedIpaFile, setUploadedIpaFile] = useState();
  const [errorContent, setErrorContent] = React.useState();
  const [showAndroidBtn, setShowAndroidBtn] = useState(true);
  const [clickedd, setClickedd] = useState(false);
  const [showIPABtn, setShowIPAbtn] = useState(true);

  const changeHandler = (e) => {
    setSelectedApkFile(e.target.files[0]);
    setShowAndroidBtn(true);
  };
  const [Para, setPara] = useState(null);
  const [Para2, setPara2] = useState(null);
  const changeHandleripa = (e) => {
    if (e.target.files.length > 0) {
      let filename = e.target.files[0].name;
      let ext = filename.match(/\.([^\.]+)$/)[1];
      if (ext.indexOf('ipa') > -1) {
        setPara(null);
      } else if (typeof filename === 'undefined') {
        setPara('You should choose only ipa files');
        setSelectedIpaFile('');
        setUploadedIpaFile('');
      } else {
        setPara('You should choose only ipa files');
        setSelectedIpaFile('');
        setUploadedIpaFile('');
      }
    }
    if (e.target.files.length === 0) {
      setSelectedIpaFile(selectedIpaFile);
    }
  };
  const changeHandlerapk = (e) => {
    if (e.target.files.length > 0) {
      let filename = e.target.files[0].name;
      let ext = filename.match(/\.([^\.]+)$/)[1];
      if (ext.indexOf('apk') > -1) {
        setPara2(null);
      } else if (typeof filename === 'undefined') {
        setPara2('You should choose only apk files');
        setUploadedApkFile('');
        setSelectedApkFile('');
      } else {
        setUploadedApkFile('');
        setSelectedApkFile('');
        setPara2('You should choose only apk files');
      }
    }
    if (e.target.files.length === 0) {
      setSelectedApkFile(selectedApkFile);
    }
  };
  const onHandleChange = (e) => {
    setSelectedIpaFile(e.target.files[0]);
    setShowIPAbtn(true);
  };
  const handleProjectType = (e) => {
    const { name, value } = e.target;
    if (formik.values[name] == value) {
      formik.setValues({ ...formik.values, [name]: value });
    } else {
      formik.setErrors({});
      formik.setValues((v) => ({
        ...v,
        [name]: '',
        AppType: '',
        Url: '',
        baseUri: '',
        port: '',
        Platform: '',
        appPackage: '',
        appActivity: '',
        bundleId: '',
      }));
    }
  };

  const history = useHistory();
  const createProject = () => {
    const project = formik.values;
    const data = {};

    const formData = new FormData();
    if (selectedApkFile && showAndroidBtn) {
      formData.append('file', selectedApkFile);
    }
    if (selectedIpaFile && showIPABtn) {
      formData.append('file', selectedIpaFile);
    }
    if (project.type === 'Web') {
      data.name = project.projectName;
      data.desc = project.description;
      data.type = project.type;
      data.url = project.Url;
    }
    if (project.type === 'Mobile' && project.AppType === 'Native' && project.Platform === 'Android') {
      data.name = project.projectName;
      data.desc = project.description;
      data.type = project.type;
      data.appType = project.AppType;
      data.platform = project.Platform;
      data.appPackage = project.appPackage;
      data.appActivity = project.appActivity;
    }
    if (project.type === 'Mobile' && project.AppType === 'Native' && project.Platform === 'iOS') {
      data.name = project.projectName;
      data.desc = project.description;
      data.type = project.type;
      data.appType = project.AppType;
      data.platform = project.Platform;
      data.bundleId = project.bundleId;
    }
    if (project.type === 'Mobile' && project.AppType === 'Native' && project.Platform === 'Android & iOS') {
      data.name = project.projectName;
      data.desc = project.description;
      data.type = project.type;
      data.appType = project.AppType;
      data.platform = project.Platform;
      data.bundleId = project.bundleId;
      data.appPackage = project.appPackage;
      data.appActivity = project.appActivity;
    }
    if (project.type === 'Mobile' && project.AppType === 'Hybrid' && project.Platform === 'Android') {
      data.name = project.projectName;
      data.desc = project.description;
      data.type = project.type;
      data.appType = project.AppType;
      data.platform = project.Platform;
      data.appPackage = project.appPackage;
      data.appActivity = project.appActivity;
    }
    if (project.type === 'Mobile' && project.AppType === 'Hybrid' && project.Platform === 'iOS') {
      data.name = project.projectName;
      data.desc = project.description;
      data.type = project.type;
      data.appType = project.AppType;
      data.platform = project.Platform;
      data.bundleId = project.bundleId;
    }
    if (project.type === 'Mobile' && project.AppType === 'Hybrid' && project.Platform === 'Android & iOS') {
      data.name = project.projectName;
      data.desc = project.description;
      data.type = project.type;
      data.appType = project.AppType;
      data.platform = project.Platform;
      data.bundleId = project.bundleId;
      data.appPackage = project.appPackage;
      data.appActivity = project.appActivity;
    }
    if (project.type === 'Web & Mobile' && project.AppType === 'Native' && project.Platform === 'Android') {
      data.name = project.projectName;
      data.desc = project.description;
      data.type = project.type;
      data.url = project.Url;
      data.appType = project.AppType;
      data.platform = project.Platform;
      data.appPackage = project.appPackage;
      data.appActivity = project.appActivity;
    }
    if (project.type === 'Web & Mobile' && project.AppType === 'Native' && project.Platform === 'iOS') {
      data.name = project.projectName;
      data.desc = project.description;
      data.type = project.type;
      data.url = project.Url;
      data.appType = project.AppType;
      data.platform = project.Platform;
      data.bundleId = project.bundleId;
    }
    if (project.type === 'Web & Mobile' && project.AppType === 'Native' && project.Platform === 'Android & iOS') {
      data.name = project.projectName;
      data.desc = project.description;
      data.type = project.type;
      data.url = project.Url;
      data.appType = project.AppType;
      data.platform = project.Platform;
      data.bundleId = project.bundleId;
      data.appPackage = project.appPackage;
      data.appActivity = project.appActivity;
    }
    if (project.type === 'Web & Mobile' && project.AppType === 'Hybrid' && project.Platform === 'Android') {
      data.name = project.projectName;
      data.desc = project.description;
      data.type = project.type;
      data.url = project.Url;
      data.appType = project.AppType;
      data.platform = project.Platform;
      data.appPackage = project.appPackage;
      data.appActivity = project.appActivity;
    }
    if (project.type === 'Web & Mobile' && project.AppType === 'Hybrid' && project.Platform === 'iOS') {
      data.name = project.projectName;
      data.desc = project.description;
      data.type = project.type;
      data.url = project.Url;
      data.appType = project.AppType;
      data.platform = project.Platform;
      data.bundleId = project.bundleId;
    }
    if (project.type === 'Web & Mobile' && project.AppType === 'Hybrid' && project.Platform === 'Android & iOS') {
      data.name = project.projectName;
      data.desc = project.description;
      data.type = project.type;
      data.url = project.Url;
      data.appType = project.AppType;
      data.platform = project.Platform;
      data.bundleId = project.bundleId;
      data.appPackage = project.appPackage;
      data.appActivity = project.appActivity;
    }
    if (['Web Service']?.includes(project?.type)) {
      data.name = project.projectName;
      data.desc = project.description;
      data.type = project.type;
      data.baseUri = project.baseUri;
      data.port = project.port;
    }
    if (project.type === 'Salesforce') {
      data.name = project.projectName;
      data.desc = project.description;
      data.type = project.type;
      data.url = project.Url;
      data.bundleId = project.bundleId;
      data.appPackage = project.appPackage;
      data.appActivity = project.appActivity;
    }
    if (data.type === 'Salesforce') {
      data.platform = 'Android & iOS';
      data.appType = 'N/A';
    }
    if (project.type === 'MS Dynamics') {
      data.name = project.projectName;
      data.desc = project.description;
      data.type = project.type;
      data.url = project.Url;
      data.bundleId = project.bundleId;
      data.appPackage = project.appPackage;
      data.appActivity = project.appActivity;
    }
    if (data.type === 'MS Dynamics') {
      data.platform = 'Android & iOS';
      data.appType = 'N/A';
    }
    formData.append('data', JSON.stringify(data));

    createProjectRequest(formData)
      .then((results) => {
        if (results.data && results.data.responseObject) {
          MyAlert.success(`${project.projectName} Project created successfully`);
          const projectId = results.data.responseObject.id;
          results.data.responseObject['timeZoneName'] = localStorage.getItem('license-level-timezone');
          gotoHistroy(projectId);
          sessionStorage.setItem('projectId', projectId);
          localStorage.setItem('selected-project', JSON.stringify(results.data.responseObject));

          history.push('/testdevelopment');
        } else {
          setOpen(true);
          setErrorContent(results.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setOpen(false);
      });
  };
  async function updateProject() {
    const project = formik.values;
    if (['Mobile', 'Web & Mobile', 'Salesforce', 'MS Dynamics']?.includes(initialValues?.type)) {
      if (
        JSON.stringify(initialValues || initialValues.apk) == JSON.stringify(project) &&
        selectedApkFile === undefined &&
        selectedIpaFile === undefined &&
        showAndroidBtn === true &&
        showIPABtn === true
      ) {
        MyAlert.info(
          <div className="fontPoppinsRegularMd inline">No changes were done to the {project.projectName} project</div>
        );

        return null;
      }
    } else {
      if (JSON.stringify(initialValues || initialValues.apk) == JSON.stringify(project)) {
        MyAlert.info(
          <div className="fontPoppinsRegularMd inline">No changes were done to the {project.projectName} project</div>
        );

        return null;
      }
    }
    const data = {
      id: project.id,
      name: project.projectName,
      desc: project.description,
      status: project.status,
      type: project.type,
      url: project.Url,
      baseUri: project.baseUri,
      port: project.port,
      appType: project.AppType,
      platform: project.Platform,
      appPackage: project.appPackage,
      appActivity: project.appActivity,
      bundleId: project.bundleId,
    };

    if (project.Platform && project.Platform === 'iOS') {
      data.appPackage = '';
      data.appActivity = '';
    } else if (project.Platform && project.Platform === 'Android') {
      data.bundleId = '';
    }
    if (selectedApkFile) {
      if (selectedApkFile?.name === initialValues?.apk[0]?.name) {
        MyAlert.info(
          <div className="fontPoppinsRegularMd inline">No changes were done to the {project.projectName} project</div>
        );
        return null;
      }
      const fileFormData = new FormData();
      fileFormData.append('file', selectedApkFile);

      if (uploadedApkFile && uploadedApkFile.id) {
        fileFormData.append('data', JSON.stringify({ name: selectedApkFile.name }));

        await updateUploadedFile(uploadedApkFile.id, fileFormData, project.id);
      } else {
        let androidFolderId = '';
        const DEFAULT_ANDROID_FOLDER = 'Default-Android';

        await getFolderTreeReq(null, project.id).then(async (response) => {
          if (response?.data?.responseObject?.folderTree) {
            const rootFolderObject = response.data.responseObject.folderTree.find((folder) => folder.title === 'Root');

            if (rootFolderObject) {
              const androidFolderUnderRoot = rootFolderObject?.children?.find(
                (folder) => folder.name === DEFAULT_ANDROID_FOLDER
              );

              androidFolderId = androidFolderUnderRoot?.key;

              if (!androidFolderId) {
                const createDefaultAndroidFolderRequest = {
                  name: DEFAULT_ANDROID_FOLDER,
                  parentId: rootFolderObject.key,
                };
                await postFolderReq(createDefaultAndroidFolderRequest, project.id).then((response) => {
                  androidFolderId = response?.data?.responseObject?.id;
                });
              }
            }
          }
        });
        await uploadFilesReq(androidFolderId, null, fileFormData, project.id);
      }
    } else if (!showAndroidBtn && uploadedApkFile && uploadedApkFile.id) {
      const fileDataResponse = await getFileRequest(uploadedApkFile.id, project.id);
      const fileData = fileDataResponse?.data?.responseObject;
      if (fileData) {
        await deleteFileReq(fileData.id, fileData.parentId, project.id);
      }
    }

    if (selectedIpaFile) {
      if (selectedIpaFile?.name === initialValues?.ipa[0]?.name) {
        MyAlert.info(
          <div className="fontPoppinsRegularMd inline">No changes were done to the {project.projectName} project</div>
        );
        return null;
      }

      const fileFormData = new FormData();
      fileFormData.append('file', selectedIpaFile);
      if (uploadedIpaFile && uploadedIpaFile.id) {
        fileFormData.append('data', JSON.stringify({ name: selectedIpaFile.name }));
        await updateUploadedFile(uploadedIpaFile.id, fileFormData, project.id);
      } else {
        let iosFolderId = '';
        const DEFAULT_IOS_FOLDER = 'Default-iOS';
        await getFolderTreeReq(null, project.id).then(async (response) => {
          if (response?.data?.responseObject?.folderTree) {
            const rootFolderObject = response.data.responseObject.folderTree.find((folder) => folder.title === 'Root');
            if (rootFolderObject) {
              const iosFolderUnderRoot = rootFolderObject?.children?.find(
                (folder) => folder.name === DEFAULT_IOS_FOLDER
              );
              iosFolderId = iosFolderUnderRoot?.key;

              if (!iosFolderId) {
                const createDefaultAndroidFolderRequest = {
                  name: DEFAULT_IOS_FOLDER,
                  parentId: rootFolderObject.key,
                };
                await postFolderReq(createDefaultAndroidFolderRequest, project.id).then((response) => {
                  iosFolderId = response?.data?.responseObject?.id;
                });
              }
            }
          }
        });

        await uploadFilesReq(iosFolderId, null, fileFormData, project.id);
      }
    } else if (!showIPABtn && uploadedIpaFile && uploadedIpaFile.id) {
      const fileDataResponse = await getFileRequest(uploadedIpaFile.id);
      const fileData = fileDataResponse?.data?.responseObject;
      if (fileData) {
        await deleteFileReq(fileData.id, fileData.parentId, project.id);
      }
    }

    initUpdateProjectReq(data, project);
  }

  const initUpdateProjectReq = (data, project) => {
    const formData = new FormData();
    if (!uploadedApkFile && selectedApkFile) {
      formData.append('file', selectedApkFile);
    }
    if (!uploadedIpaFile && selectedIpaFile) {
      formData.append('file', selectedIpaFile);
    }

    formData.append('data', JSON.stringify(data));
    sessionStorage.setItem('projectId', project.id);

    updateProjectRequest(formData, project.id)
      .then((results) => {
        if (results.data.responseObject) {
          props.MyAlert.success(`${project.projectName} Project updated successfully`);

          if (project.id === '') {
            const myUpProject = JSON.parse(localStorage.getItem('selected-project'));
            myUpProject.name = project.projectName;
            localStorage.setItem('selected-project', JSON.stringify(myUpProject));
          }
          props.func('closePopup');
        } else {
          setOpen(true);
          setErrorContent(results.data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const gotoHistroy = (id) => {
    history.push(`/projectmenu/project-config?id=${id}`);
  };

  const [descCount, setdescCount] = useState(props.project.desc ? props.project.desc?.length : 0);
  let [noOfRows, setNoOfRows] = useState(1);
  const [checked, setChecked] = React.useState();
  const showUploadFile = false;

  useEffect(() => {
    if (formik?.values?.status === 'Open') {
      setChecked(true);
      setErrorContent('');
    } else {
      setChecked(false);
      setErrorContent('');
    }
  }, [checked, formik?.values?.status]);
  const toggle = () => {
    changeStatus();
    if (formik?.values?.status === 'Open') {
      setOpen(true);
      setChecked(!checked);
    } else {
      setOpen(true);
      setChecked(!checked);
    }
  };

  function changeStatus() {
    checked ? formik.setFieldValue('status', 'Closed') : formik.setFieldValue('status', 'Open');
  }

  let handelDlt = (e) => {
    setOpen(false);

    setTimeout(() => {
      setChecked(!checked);
      changeStatus();
    }, 1000);
  };
  let handelContinue = () => {
    setOpen(false);
  };

  const cancelFunction = () => {
    setOpen(false);
    props.closePopupOnCancel('cancel function');
  };

  useEffect(() => {
    if (props.project.apkFiles && props.project.apkFiles.length) {
      setUploadedApkFile(props.project.apkFiles[0]);
    }
    if (props.project.ipaFiles && props.project.ipaFiles.length) {
      setUploadedIpaFile(props.project.ipaFiles[0]);
    }
  }, [props.project.apkFiles, props.project.ipaFiles]);
  useEffect(() => {
    if (props.button === 'Create') {
      formik.values.AppType = '';
      formik.values.Url = '';
      formik.values.baseUri = '';
      formik.values.port = '';
      if (!['Web & Mobile', 'Mobile'].includes(formik?.values?.type)) {
        formik.values.Platform = '';
      }
      formik.values.appPackage = '';
      formik.values.bundleId = '';
      formik.values.appActivity = '';
      formik.values.ipa = '';
      formik.values.apk = '';
      setShowAndroidBtn(false);
      setShowIPAbtn(false);
      setPara(null);
      setPara2(null);
    }
  }, [formik?.values?.type]);

  useEffect(() => {
    if (props.button === 'Create') {
      formik.values.appPackage = '';
      formik.values.bundleId = '';
      formik.values.appActivity = '';
      formik.values.ipa = '';
      formik.values.apk = '';
      setShowAndroidBtn(false);
      setShowIPAbtn(false);
      setPara(null);
      setPara2(null);
    }
  }, [formik?.values?.Platform]);
  useEffect(() => {
    if (formik?.values?.AppType && props.popupType === 'Create Project') {
      if (document.getElementById('AppTypeId')) {
        document.getElementById('AppTypeId').style.color = '#1648C6';
      }
    }
  }, [formik?.values?.AppType, props.popupType]);

  useEffect(() => {
    if (formik?.values?.Platform && props.popupType === 'Create Project') {
      if (document.getElementById('PlatformId')) {
        document.getElementById('PlatformId').style.color = '#1648C6';
      }
    }
  }, [formik?.values?.Platform, props.popupType]);

  const inputChange = (e) => {
    if (e.target.value.length > 0 && e.target.value.length < 3) {
      document.getElementById('nameLvlText').style.color = '#EA0322';
    } else if (formik.values.projectName && e.target.value.length > 3) {
      document.getElementById('nameLvlText').style.color = '#727171';
    } else {
      document.getElementById('nameLvlText').style.color = '#727171';
    }
  };
  const inputOnChange = (e) => {
    if (e.target.value.length >= 3) {
      document.getElementById('nameLvlText').style.color = '#727171';
    }
  };
  const focusCheck = (e) => {
    if (e.target.value.length < 3 && formik.errors.projectName && formik.touched.projectName) {
      document.getElementById('nameLvlText').style.color = '#EA0322';
    } else {
      document.getElementById('nameLvlText').style.color = '#1648C6';
    }
  };
  const mySelectBlur = () => {
    document.getElementById('projecttype').style.color = '#727171';
  };

  const doubleError = () => {
    if (props.button) {
      document.getElementById('typeEmail').innerHTML = '';
    }
  };
  const closePopupFunction = () => {
    setOpen(false);
    props.closePopupOnCancel('cancel function');
    formik.setErrors({});
  };

  const allowedProjectList = JSON.parse(localStorage.getItem('allowedProjects'))?.features?.projectsMenu?.restrictions
    ?.allowedProjects;
  const getAllowedProjects = () => {
    if (hasDefects || hasManualTestCase) {
      return PROJECT_TYPES;
    } else {
      let projectTypes;
      allowedProjectList?.filter((feature) => {
        if (feature?.featureType === 'automation') {
          projectTypes = feature?.projects;
        }
      });
      return projectTypes;
    }
  };
  const projectTypeData = getAllowedProjects() || [];
  useEffect(() => {
    let data = getAllowedProjects();
    if (data?.length === 1) {
      formik?.setFieldValue('type', data[0]);
    }
  }, []);
  return (
    <div
      style={{ marginLeft: '18px' }}
      onBlur={(e) => {
        setCreateUpdateCalled(true);
      }}
    >
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed  inset-0 overflow-y-auto z-index-child-popup"
          open={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <div className="flex  items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity " />
            </Transition.Child>

            <span
              className={
                formik.values.status === 'Open' && !errorContent
                  ? 'hidden sm:inline-block sm:align-middle sm:h-screen'
                  : 'hidden sm:inline-block sm:align-middle msg_align'
              }
              aria-hidden="true"
            >
              &#8203;
            </span>

            {formik.values.status === 'Open' && !errorContent ? (
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div
                  className="statuschange inline-block marginupper align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle"
                  style={{ marginTop: '-17px' }}
                >
                  <div className="inline-block">
                    <div className="flex flex-start ml-2">
                      <span className="pt-5 pl-3">
                        <img src="/assets/images/remove-user-icon.svg" alt="" height="25px" width="25px" />
                      </span>
                      <Dialog.Title as="h3" className="text-sm leading-6 ml-4 pt-3 font-small text-gray-900">
                        <span className="fontPoppinsSemiboldLg block">Warning </span>
                        <span className="fontPoppinsMediumMd">Status Changes </span>
                      </Dialog.Title>
                    </div>
                  </div>
                  <hr className="line mt-px" />
                  <div className="fontPoppinsMediumMd mt-3 text-gray-500 ml-14 mr-8">
                    <p className=""> Status of the project has been changed from Closed to Open </p>
                    <p className="mt-2"> How do you want to proceed?</p>
                  </div>
                  <hr className="line mt-3" />
                  <div className="mt-2 pb-2 flex flex-row float-right">
                    <button type="button" className="mt-3 ml-4 mr-4 gray-btn" onClick={() => setOpen(false)}>
                      Change
                    </button>
                    <button
                      type="button"
                      className="mt-3 ml-1 mr-4 primary-btn"
                      style={{ marginLeft: '8px' }}
                      onClick={handelDlt}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Transition.Child>
            ) : formik.values.status === 'Closed' && !errorContent ? (
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div
                  className="statuschange inline-block marginupper align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle"
                  style={{ marginTop: '-45px' }}
                >
                  <div className="inline-block">
                    <div className="flex flex-start ml-2">
                      <span className="pt-5 pl-3">
                        <img src="/assets/images/remove-user-icon.svg" alt="" height="25px" width="25px" />
                      </span>
                      <Dialog.Title as="h3" className="text-sm leading-6 ml-4 pt-3 font-small text-gray-900">
                        <span className="fontPoppinsSemiboldLg block">Warning </span>
                        <span className="fontPoppinsMediumMd">Status Changes </span>
                      </Dialog.Title>
                    </div>
                  </div>
                  <hr className="line mt-px" />
                  <div className="fontPoppinsMediumMd mt-3 text-gray-500 ml-14 mr-8">
                    <p className=""> Status of the project has been changed from Open to Closed </p>
                    <p className="mt-2"> How do you want to proceed?</p>
                  </div>
                  <hr className="line mt-3" />
                  <div className="mt-2 pb-2 flex flex-row float-right">
                    <button type="button" className="mt-3 ml-4 mr-4 gray-btn" onClick={() => setOpen(false)}>
                      Change
                    </button>
                    <button
                      type="button"
                      className="mt-3 ml-1 mr-4 primary-btn"
                      style={{ marginLeft: '8px' }}
                      onClick={handelDlt}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Transition.Child>
            ) : (
              <Transition.Child
                as={Fragment}
                unmount={open}
                appear={false}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block marginupper_error align-bottom bg-white rounded-sm px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6 duplicate-popup">
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none cursor-pointer"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only cursor-pointer">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10">
                      <img
                        alt=""
                        src="/assets/images/remove_circle.jpg"
                        className="flex flex-row mr-2 ml-2"
                        height="20px"
                        width="20px"
                      ></img>
                    </div>
                    <div className="mt-1 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="leading-6 text-gray-900">
                        <div className="warning-popup-header">Error.</div>
                        {formik.data?.message === ' name bbbbbb already exists' ||
                        !formik.data?.message === 'SUCCESS' ? null : (
                          <div className="warning-popup-content">Duplication</div>
                        )}
                      </Dialog.Title>
                      <hr className="line mt-2" />
                      <div className="mt-2 warning-popup-content">
                        {errorContent === 'Resource with name web already exists' ? (
                          <p className="mt-2 pt-2">
                            A project with the name {formik.values?.projectName} already exist.
                          </p>
                        ) : (
                          errorContent
                        )}
                        <p className="mt-2"> How do you want to proceed?</p>
                      </div>
                    </div>
                  </div>
                  <hr className="line mt-6" />
                  <div className="mt-4 sm:mt-2 sm:flex sm:flex-row-reverse">
                    <button type="button" className="primary-btn" onClick={handelContinue}>
                      <span>Continue Editing</span>
                    </button>

                    <button type="button" className="gray-btn mr-6" onClick={cancelFunction}>
                      <span>Cancel</span>
                    </button>
                  </div>
                </div>
              </Transition.Child>
            )}
          </div>
        </Dialog>
      </Transition.Root>
      <form onSubmit={formik.handleSubmit} className="space-y-8">
        <div className={isEditProject ? 'project-platform-edit' : 'project-platform'}>
          <div>
            <div className="fixed ml-6 top-12">
              <AlertContatiner />
            </div>
            <div className="grid grid-cols-2  mt-2 h-24 ">
              <div className="mt-3">
                <div style={{ marginTop: '23px', marginBottom: '-11px' }}>
                  {formik.errors.projectName && formik.touched.projectName && !formik.values.projectName ? (
                    <>
                      {' '}
                      <span className="fontPoppinsRegularMd" style={{ color: '#EA0322' }}>
                        *
                      </span>
                      <span
                        className="fontPoppinsRegularMd"
                        id="nameLvlText"
                        style={{
                          color: '#Ea0322',
                        }}
                      >
                        {' '}
                        Name{' '}
                      </span>
                    </>
                  ) : (
                    <>
                      {' '}
                      <span className="fontPoppinsRegularMd" style={{ color: '#EA0322' }}>
                        *
                      </span>
                      <span className="fontPoppinsRegularMd" id="nameLvlText" style={{ color: '#727171' }}>
                        {' '}
                        Name{' '}
                      </span>
                    </>
                  )}
                </div>
                <MyInput
                  type="text"
                  error={formik.errors.projectName && formik.touched.projectName}
                  disabled={!isProjectOpen}
                  value={formik.values.projectName}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    setCreateUpdateCalled(true);
                    inputChange(e);
                  }}
                  onChange={(e) => {
                    formik.handleChange(e);
                    inputOnChange(e);
                  }}
                  onFocus={(e) => {
                    focusCheck(e);
                  }}
                  name="projectName"
                  autoComplete="off"
                  autoFocus={true}
                  id="projectName"
                  style={{ marginTop: '16px' }}
                  className=" block w-9/12  "
                  placeholder="Enter project name"
                  helperText={
                    <div className="fontPoppinsRegularSm" style={{ position: 'absolute' }}>
                      {formik.touched.projectName && formik.errors.projectName}
                    </div>
                  }
                />
              </div>

              <div className="mt-4" style={{ width: '140%', marginLeft: '-2px' }}>
                <div
                  style={{
                    marginTop: '23px',
                    marginBottom: '-30px',
                    paddingLeft: '26px',
                  }}
                >
                  {formik.touched.type && !formik.values.type && formik.errors.type ? (
                    <>
                      {' '}
                      <span className="fontPoppinsRegularMd" style={{ color: '#EA0322' }}>
                        *
                      </span>
                      <span
                        className="fontPoppinsRegularMd"
                        id="fontType"
                        style={{
                          color: '#Ea0322',
                        }}
                      >
                        {' '}
                        Type{' '}
                      </span>
                    </>
                  ) : (
                    <>
                      {' '}
                      <span className="fontPoppinsRegularMd" style={{ color: '#EA0322' }}>
                        *
                      </span>
                      <span className="fontPoppinsRegularMd" id="fontType" style={{ color: '#727171' }}>
                        {' '}
                        Type{' '}
                      </span>
                    </>
                  )}
                </div>
                <FormControl
                  style={{ width: '260px', left: '22px', marginTop: '-5px' }}
                  variant="standard"
                  error={
                    (formik.errors.type && formik.touched.type) ||
                    (!formik.values.type && formik.values.description) ||
                    (formik.touched.description && !formik.values.type)
                  }
                >
                  <Select
                    variant="standard"
                    displayEmpty
                    renderValue={(selected) => {
                      if (!isEmptyValue(formik.values.type) && !isEditProject) {
                        selected = formik.values.type;
                        initialValues.type = selected;
                      }
                      if (isEmptyValue(selected))
                        return <span className="fontPoppinsRegularSm">Select project type</span>;
                      else return <span className="fontPoppinsRegularSm">{selected}</span>;
                    }}
                    type="text"
                    style={{
                      marginTop: '35px',
                      color: '#dad5d5',
                      paddingRight: '0px',
                      paddingBottom: '0px',
                    }}
                    error={
                      ((formik.errors.type && formik.touched.type) ||
                        (!formik.values.type && formik.values.description) ||
                        (formik.touched.description && !formik.values.type)) && (
                        <div>{formik.errors.type && formik.touched.type}</div>
                      )
                    }
                    id="projecttype"
                    className={`${classes.select} block w-9/12`}
                    name="type"
                    selectedValue={props.project.type}
                    value={formik.values.type}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      mySelectBlur(e);
                      document.getElementById('fontType').style.color = '#727171';
                    }}
                    onFocus={() => {
                      document.getElementById('fontType').style.color = '#1648C6';
                    }}
                    onChange={(e) => {
                      formik.handleChange(e);
                      if (['Web & Mobile', 'Mobile'].includes(e.target.value)) {
                        formik.setFieldValue('Platform', 'Android & iOS', true);
                      }
                    }}
                    onClick={() => {
                      if (clickedd) {
                        if (!formik.values.type) {
                          setClickedd(true);
                          document.getElementById('typeEmail').innerHTML = '';
                        }
                      }
                    }}
                    disabled={isEditProject || projectTypeData?.length === 1}
                    disableUnderline={isEditProject || projectTypeData?.length === 1}
                    func={setDropDownValue}
                    helperText={<div className="fontPoppinsRegularSm">{formik.touched.type && formik.errors.type}</div>}
                  >
                    {Object.values(projectTypeData).map((option, optionIndex) => (
                      <MenuItem
                        id="sideLeft"
                        key={`labeled-select-${option}-${optionIndex}`}
                        value={option}
                        className="hoverItem fontPoppinsRegularSm"
                      >
                        <span className="text-gray-500"> {option}</span>
                      </MenuItem>
                    ))}
                  </Select>
                  {((formik.errors.type && formik.touched.type) ||
                    (!formik.values.type && formik.values.description) ||
                    (formik.touched.description && !formik.values.type)) && (
                    <FormHelperText
                      className="fontPoppinsRegularSm"
                      style={{
                        color: '#EA0322',
                        marginTop: '2px',
                        marginLeft: '0px',
                      }}
                    >
                      Select project type
                    </FormHelperText>
                  )}
                </FormControl>
                <p
                  id="typeEmail"
                  style={{
                    fontSize: '10px',
                    marginTop: '-4px',
                    letterSpacing: '0.8px',
                  }}
                ></p>
              </div>
              {isEditProject || projectTypeData?.length === 1 ? <span className="disabled-line"></span> : null}
            </div>
            <div className="grid grid-cols-1 mb-1 mt-2.5">
              <label
                className="fontPoppinsRegularMd"
                htmlFor="description"
                id="descriptionLabel"
                style={{ marginTop: '20px', color: '#727171' }}
              >
                Description
              </label>
              <TextareaAutosize
                onBlur={(e) => {
                  setNoOfRows(1);
                  e.target.classList.add('descriptionStyle');
                  document.getElementById('descriptionLabel').style.color = '#727171';
                  document.getElementById('description').style.height = 'initial';
                  setCreateUpdateCalled(true);
                }}
                onFocus={(e) => {
                  setNoOfRows(null);
                  e.target.classList.remove('descriptionStyle');
                  document.getElementById('descriptionLabel').style.color = '#1648C6';
                  if (!formik.values.type && !formik.touched.type) {
                    formik.handleSubmit();
                  }
                }}
                id="description"
                name="description"
                value={formik.values.description}
                placeholder="Your description goes here..."
                style={{
                  paddingLeft: '1px',
                  maxHeight: '70px',
                  lineHeight: '17px',
                }}
                onChange={formik.handleChange}
                onKeyUp={(e) => setdescCount(e.target.value.length)}
                rowsMax={noOfRows}
                maxLength="200"
                className="rs-input-style-textareaSm  shadow-sm block description-width-project overflow-ellipsis  mt-1   border-0 border-b input-field-color"
                disabled={!isProjectOpen}
              ></TextareaAutosize>
              <div className="mt-1 mr-5 text-sm text-gray-500 text-right">{descCount}/200</div>
            </div>
            {isEditProject ? (
              <div className="grid grid-cols-1 mb-1">
                <div>
                  <label htmlFor="status" className="input-filed-label-style-common block">
                    Status
                  </label>

                  <div
                    className="relative inline-block w-48 mr-2 align-middle select-none transition duration-200 ease-in"
                    onChange={(e) => {
                      changeStatus(e);
                      setCreateUpdateCalled(true);
                    }}
                  >
                    {formik.values.activationStatus === 'PENDING' ? (
                      <label className="text-red-500 w-28 fontPoppinsRegularMd" style={{ color: ' #C50303' }}>
                        Activation Pending
                      </label>
                    ) : (
                      <div>
                        <span className=" mt-1.5">
                          <label className="  ">
                            <span className="switch1 mx-5.5 w-20 h-10">
                              <input
                                type="checkbox"
                                checked={formik?.values?.status == 'Open'}
                                onChange={toggle}
                                value={String(formik?.values?.status).toLowerCase()}
                                name="toggle"
                              />
                              <span className="slider1 round1 mt-1.5"></span>
                            </span>
                          </label>
                        </span>
                        <label for="toggle" className="fontPoppinsRegularMd text-gray-700 ml-3 mt-2">
                          {formik.values.status}{' '}
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {['Web', 'Web & Mobile', 'Salesforce', 'MS Dynamics']?.includes(formik?.values?.type) ? (
              <div className="grid grid-cols-1 mb-1" style={{ width: '235px' }}>
                <div class="pt-2">
                  <div>
                    {
                      <>
                        <span className="fontPoppinsRegularMd" id="webText" style={{ color: '#727171' }}>
                          {' '}
                          Web Url
                        </span>
                      </>
                    }
                  </div>
                  <MyInput
                    type="text"
                    error={formik.errors.Url && formik.touched.Url}
                    value={formik.values.Url?.trim()}
                    disabled={!isProjectOpen}
                    onFocus={() => {
                      document.getElementById('webText').style.color = '#1648C6';
                    }}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      setCreateUpdateCalled(true);
                      document.getElementById('webText').style.color = '#727171';
                    }}
                    onChange={formik.handleChange}
                    name="Url"
                    id="Url"
                    autoFocus={false}
                    autoComplete="off"
                    className=" block pb-8 w-full"
                    placeholder="Enter web url"
                    helperText={<span className="fontPoppinsRegularSm">{formik.touched.Url && formik.errors.Url}</span>}
                  />
                </div>
              </div>
            ) : null}
          </div>

          {['Web Service']?.includes(formik?.values?.type) ? (
            <div className="grid grid-cols-2  mt-2 pb-3 ">
              <div>
                <div>
                  {
                    <>
                      <span className="fontPoppinsRegularMd" id="baseUriText" style={{ color: '#727171' }}>
                        {' '}
                        Base URI
                      </span>
                    </>
                  }
                </div>
                <MyInput
                  type="text"
                  error={formik.errors.baseUri && formik.touched.baseUri}
                  disabled={!isProjectOpen}
                  value={formik.values.baseUri}
                  onFocus={() => {
                    document.getElementById('baseUriText').style.color = '#1648C6';
                  }}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    setCreateUpdateCalled(true);
                    document.getElementById('baseUriText').style.color = '#727171';
                  }}
                  onChange={formik.handleChange}
                  name="baseUri"
                  id="baseUri"
                  autoFocus={false}
                  autoComplete="off"
                  className=" block w-9/12  "
                  placeholder="Enter Base URI"
                  helperText={
                    <span className="fontPoppinsRegularSm">{formik.touched.baseUri && formik.errors.baseUri}</span>
                  }
                />
              </div>
              <div>
                <div>
                  {
                    <>
                      <span
                        className="fontPoppinsRegularMd"
                        id="portText"
                        style={{ color: '#727171', marginLeft: '30px' }}
                      >
                        {' '}
                        Port
                      </span>
                    </>
                  }
                </div>
                <MyInput
                  type="text"
                  style={{ marginLeft: '30px', width: '83%' }}
                  error={formik.errors.port && formik.touched.port}
                  value={formik.values.port}
                  onFocus={() => {
                    document.getElementById('portText').style.color = '#1648C6';
                  }}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    setCreateUpdateCalled(true);
                    document.getElementById('portText').style.color = '#727171';
                  }}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                      formik.handleChange(e);
                    }
                  }}
                  maxLength={5}
                  name="port"
                  disabled={!isProjectOpen}
                  id="port"
                  autoFocus={false}
                  autoComplete="off"
                  className=" block w-9/12  "
                  placeholder="Enter Port"
                  helperText={<span className="fontPoppinsRegularSm">{formik.touched.port && formik.errors.port}</span>}
                />
              </div>
            </div>
          ) : null}
          <div>
            {['Mobile', 'Web & Mobile', 'Salesforce', 'MS Dynamics']?.includes(formik?.values?.type) ? (
              <div>
                {!['Salesforce', 'MS Dynamics']?.includes(formik?.values?.type) ? (
                  <div>
                    <div
                      onBlur={() => (document.getElementById('AppTypeId').style.color = '#727171')}
                      onChange={() => (document.getElementById('AppTypeId').style.color = '#1648C6')}
                    >
                      <label
                        htmlFor="AppType"
                        id="apptype"
                        className={`input-filed-label-style-common  ${
                          formik.errors.AppType && formik.touched.AppType && 'text-red-500 font-normal'
                        }`}
                      >
                        {!(formik.errors.AppType && formik.touched.AppType) ? (
                          <Typography
                            id="AppTypeId"
                            className="fontPoppinsRegularMd"
                            style={{ color: '#727171 !important' }}
                          >
                            {' '}
                            <span className="text-red-400 fontPoppinsRegularMd" style={{ color: '#EA0322' }}>
                              *
                            </span>{' '}
                            App Type
                          </Typography>
                        ) : (
                          <Typography id="AppTypeId" className="fontPoppinsRegularMd" style={{ color: '#EA0322' }}>
                            {' '}
                            <span className="text-red-400 fontPoppinsRegularMd" style={{ color: '#EA0322' }}>
                              *
                            </span>
                            App Type{' '}
                          </Typography>
                        )}
                      </label>
                      <RadioButton
                        value={formik.values.AppType}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          setCreateUpdateCalled(true);
                        }}
                        onChange={(e) => {
                          formik.handleChange(e);
                          setCreateUpdateCalled(true);
                        }}
                        popupType={!isProjectOpen ? props.popupType : ''}
                        name="AppType"
                        id="AppType"
                        onFocus={() => {
                          setCreateUpdateCalled(true);
                          document.getElementById('AppTypeId').style.color = 'blue';
                        }}
                        editData={formik.values.AppType}
                        radioList={appTypeData}
                        radioName={appType}
                        func={setRadiobuttonVal}
                        style={{ display: '-webkit-inline-box' }}
                      />
                      {formik.errors.AppType && formik.touched.AppType && (
                        <p
                          className="fontPoppinsRegularSm MuiFormHelperText-root Mui-error "
                          style={{ marginBottom: '15px', marginTop: '-6px' }}
                        >
                          {formik.errors.AppType}
                        </p>
                      )}
                    </div>
                  </div>
                ) : null}
                {formik?.values?.Platform === 'Android' ||
                formik?.values?.type === 'Salesforce' ||
                formik?.values?.type === 'MS Dynamics' ||
                formik?.values?.Platform === 'Android & iOS' ? (
                  <div className="grid grid-cols-2 ">
                    <div>
                      <div>
                        {
                          <>
                            <span className="fontPoppinsRegularMd" id="AppFocus" style={{ color: '#727171' }}>
                              App Package
                            </span>
                          </>
                        }
                      </div>
                      <MyInput
                        type="text"
                        style={{ width: '240px', marginTop: '6px' }}
                        error={formik.errors.appPackage && formik.touched.appPackage}
                        value={formik.values.appPackage?.trim()}
                        onFocus={() => {
                          document.getElementById('AppFocus').style.color = '#1648C6';
                        }}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          setCreateUpdateCalled(true);
                          document.getElementById('AppFocus').style.color = '#727171';
                        }}
                        onChange={formik.handleChange}
                        name="appPackage"
                        id="appPackage"
                        autoFocus={false}
                        disabled={!isProjectOpen}
                        autoComplete="off"
                        className=" block w-9/12  "
                        placeholder="Enter app package"
                        helperText={
                          <span className="fontPoppinsRegularSm">
                            {formik.touched.appPackage && formik.errors.appPackage}
                          </span>
                        }
                      />
                    </div>
                    <div>
                      <div>
                        {
                          <>
                            <span
                              className="fontPoppinsRegularMd"
                              id="AppActivity"
                              style={{ color: '#727171', marginLeft: '25px' }}
                            >
                              {' '}
                              App Activity
                            </span>
                          </>
                        }
                      </div>
                      <MyInput
                        type="text"
                        style={{
                          marginLeft: '26px',
                          width: '85%',
                          marginTop: '6px',
                        }}
                        error={formik.errors.appActivity && formik.touched.appActivity}
                        value={formik.values.appActivity?.trim()}
                        onFocus={() => {
                          document.getElementById('AppActivity').style.color = '#1648C6';
                        }}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          setCreateUpdateCalled(true);
                          document.getElementById('AppActivity').style.color = '#727171';
                        }}
                        onChange={formik.handleChange}
                        name="appActivity"
                        id="appActivity"
                        autoFocus={false}
                        autoComplete="off"
                        disabled={!isProjectOpen}
                        className=" block w-9/12  "
                        placeholder="Enter app activity"
                        helperText={
                          <span className="fontPoppinsRegularSm">
                            {formik.touched.appActivity && formik.errors.appActivity}
                          </span>
                        }
                      />
                    </div>
                  </div>
                ) : null}
                <div className="grid grid-cols-2  ">
                  {formik?.values?.Platform === 'iOS' ||
                  formik?.values?.Platform === 'Android & iOS' ||
                  formik?.values?.type === 'Salesforce' ||
                  formik?.values?.type === 'MS Dynamics' ? (
                    <div>
                      <div>
                        {
                          <>
                            <span className="fontPoppinsRegularMd" id="Bundle" style={{ color: '#727171' }}>
                              Bundle Id
                            </span>
                          </>
                        }
                      </div>
                      <MyInput
                        type="text"
                        style={{ width: '240px', marginTop: '6px' }}
                        error={formik.errors.bundleId && formik.touched.bundleId}
                        value={formik.values.bundleId?.trim()}
                        onFocus={() => {
                          document.getElementById('Bundle').style.color = '#1648C6';
                        }}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          setCreateUpdateCalled(true);
                          document.getElementById('Bundle').style.color = '#727171';
                        }}
                        onChange={formik.handleChange}
                        name="bundleId"
                        id="bundleId"
                        autoComplete="off"
                        autoFocus={false}
                        disabled={!isProjectOpen}
                        className=" block w-9/12  "
                        placeholder="Enter bundle id"
                        helperText={
                          <span className="fontPoppinsRegularSm">
                            {formik.touched.bundleId && formik.errors.bundleId}
                          </span>
                        }
                      />
                    </div>
                  ) : null}
                </div>
                {showUploadFile && (
                  <div className="grid grid-cols-2  mt-2 pb-3 ">
                    {formik?.values?.Platform === 'Android' ||
                    formik?.values?.Platform === 'Android & iOS' ||
                    formik?.values?.type === 'Salesforce' ||
                    formik?.values?.type === 'MS Dynamics' ? (
                      <div className="mt-5">
                        <div>
                          <div htmlFor="apk" className="block input-filed-label-style-common">
                            <span className="fontPoppinsRegularMd" style={{ position: 'relative', color: '#727171' }}>
                              Upload Apk
                              <span
                                id="info"
                                data-title="Apk"
                                style={{
                                  position: 'absolute',
                                  right: '-30px',
                                  top: '0',
                                }}
                              >
                                <img
                                  alt=""
                                  src="/assets/images/info_icon.svg"
                                  width="15px"
                                  height="15px"
                                  style={{ marginTop: '3px' }}
                                />
                              </span>
                            </span>
                          </div>
                        </div>
                        <FileUploadButton
                          value={selectedApkFile ? selectedApkFile.name : uploadedApkFile ? uploadedApkFile.name : ''}
                          name="apk"
                          id="apk"
                          title=""
                          selectedApkFile={selectedApkFile}
                          disabled={!isProjectOpen}
                          setSelectedApkFile={setSelectedApkFile}
                          onChange={(e) => {
                            changeHandler(e);
                            changeHandlerapk(e);
                            setCreateUpdateCalled(true);
                          }}
                          showBtn={showAndroidBtn}
                          setShowBtn={setShowAndroidBtn}
                          accept=".apk"
                          className="max-w-lg text-blue-600 block w-24 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 cursor-pointer"
                        />
                        {Para2 && (
                          <p id="para2" className="fontPoppinsRegularXs" style={{ color: '#EA0322' }}>
                            {Para2}
                          </p>
                        )}
                      </div>
                    ) : null}
                    {formik?.values?.Platform === 'iOS' ||
                    formik?.values?.Platform === 'Android & iOS' ||
                    formik?.values?.type === 'Salesforce' ||
                    formik?.values?.type === 'MS Dynamics' ? (
                      <div
                        style={{
                          width: '240px',
                          marginTop: '20px',
                          marginLeft:
                            formik?.values?.Platform === 'Android & iOS' ||
                            formik?.values?.type === 'Salesforce' ||
                            formik?.values?.type === 'MS Dynamics'
                              ? '27px'
                              : '0px',
                        }}
                      >
                        <div>
                          <div htmlFor="ipa" className="block input-filed-label-style-common">
                            <span className="fontPoppinsRegularMd" style={{ position: 'relative', color: '#727171' }}>
                              Upload Ipa
                              <span
                                id="info"
                                data-title="Ipa"
                                style={{
                                  position: 'absolute',
                                  right: '-30px',
                                  top: '0',
                                }}
                              >
                                <img
                                  alt=""
                                  src="/assets/images/info_icon.svg"
                                  width="15px"
                                  height="15px"
                                  style={{ marginTop: '3px' }}
                                />
                              </span>
                            </span>
                          </div>
                        </div>
                        <FileUploadButton
                          value={selectedIpaFile ? selectedIpaFile.name : uploadedIpaFile ? uploadedIpaFile.name : ''}
                          accept=".ipa"
                          disabled={!isProjectOpen}
                          title=""
                          selectedIpaFile={selectedIpaFile}
                          setSelectedIpaFile={setSelectedIpaFile}
                          name="ipa"
                          showBtn={showIPABtn}
                          setShowBtn={setShowIPAbtn}
                          id="ipa"
                          onChange={(e) => {
                            onHandleChange(e);
                            changeHandleripa(e);
                            setCreateUpdateCalled(true);
                          }}
                          className="max-w-lg block w-28 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 cursor-pointer"
                        />
                        {Para && (
                          <p id="para" className="fontPoppinsRegularXs" style={{ color: '#EA0322' }}>
                            {Para}
                          </p>
                        )}
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
        <div className=" ml-2 border-t border-#e2e5ec-200" style={{ width: '104%', marginLeft: '-18px' }}>
          <div className="flex justify-end" style={{ marginRight: '24px', paddingTop: '9px' }}>
            <button
              type="reset"
              className="gray-btn mr-3"
              style={{ marginRight: '24px' }}
              onClick={closePopupFunction}
              onMouseDown={(e) => e.preventDefault()}
            >
              Cancel
            </button>

            <button type="submit" className="primary-btn" id="buttonId" onClick={doubleError}>
              {props.button}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProjectCreatePage;
