import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import CommonDrawer from '@src/common/atoms/CommonDrawer';
import * as yup from 'yup';
import { DEFECT_CONSTANTS } from '@src/common/ui-constants';
import { getAllModuleTreeReq, postSlackIssue } from '@api/api_services';
import styles from '@pages/configuration/DefectConfig/defect-config.module.scss';
import { Label } from '@src/common/atoms/LabelComponent';
import { SLACK_ISSUE_DRAWER } from '@pages/configuration/DefectConfig/defect-constants';
import CommonButton from '@src/common/button/Button';
import SelectBox from '@src/common/atoms/SelectComponent';
import { DefectIssueFields } from './FireFlinkIssueDrawerFields';
import { Grid } from '@mui/material';
import MultiAutocomplete from '@src/pages/test-development/script/modules/module/modals/labels-multiselect-dropdown';
import { FORM_FIELDS, FORM_FIELDS_ARRAY, MESSAGES } from '@pages/defects/defectMgmtConstants';
import { checkValidationForAlphanumericSpecialCharacters } from '@pages/analytics/common/util';
import moment from 'moment';
import { getDefectDescription } from '@src/pages/defects/defect-utils';
import { useAlert } from '@src/pages/common/alert_service/useAlert';
import { getSelectedProject } from '@src/service/common_service';
import { DEFECT_ATTACTMENT_FILE_ALLOWED, UI_VALIDATIONS } from '@src/util/validations';
import { isEmptyValue, validateFileExtension } from '@src/util/common_utils';

const { TEXTFIELD, TEXTBOX, CHECKBOX, LINK, ATTACHMENT, DATE } = FORM_FIELDS;

const SlackIssueDrawer = (props) => {
  const {
    closeDrawer,
    openDrawer,
    allInstances,
    reloadTable,
    failedStepsData,
    drawerType,
    defectMgmtData,
    selectedDefectData,
    selectedModule,
    setSelectedDefectData,
    reloadHandler = () => {},
    initDefectData = () => {},
    history,
    cleanUpFunction,
  } = props;
  const {
    CREATE_SUCCESS,
    ALPHA_ERR_MSG,
    CREATE_DEFECT_ERR,
    REQUIRED_ERR_MSG,
    NO_SPACE_AT_START_AND_END_MSG,
    YES,
    VALID_URL,
    MAX_NO_OF_FILES_ALLOWED,
    DUPLICATE_FILE,
    MAX_FILE_SIZE_ALLOWED,
    ADD,
    NO,
  } = MESSAGES;
  const { MAX_FILE_UPLOAD_COUNT } = UI_VALIDATIONS;
  const { steps, envDetails, isFromSteps } = failedStepsData || {};
  const { AlertContatiner, MyAlert } = useAlert();
  const [slackInstances, setSlackInstances] = useState([]);
  const [selectedInstance, setSelectedInstance] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [defectDetails, setDefectDetails] = useState([]);
  const [moduleTree, setModuleTree] = useState([]);
  const [moduleLevelScriptCount, setModuleLevelScriptCount] = useState(0);
  const [initialValues, setInitialValues] = useState({});
  const [defaultSelectedModule, setDefaultSelectedModule] = useState([]);
  const [validateSchema, setValidateSchema] = useState({});
  const [projectUsers, setProjectUsers] = useState([]);
  const [newFilesToBeUploaded, setNewFilesToBeUploaded] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [tempUploadFiles, setTempUploadFiles] = useState([]);
  const [resetLabels, setResetLabels] = useState(false);
  const [labelResponseObject, setLabelResponseObject] = useState([]);

  const [labelSelectedOptions, setLabelSelectedOptions] = useState([]);
  let project = getSelectedProject();

  let tempInitialValues = {};
  let tempValidateSchema = '';
  const projectLabelsObject = [];

  useEffect(() => {
    createValidationSchema();
  }, []);
  function createYupSchema(schema, config) {
    const { id, validationType, validations = [] } = config;
    if (!yup[validationType]) {
      return schema;
    }
    let validator = yup[validationType]();
    validations.forEach((validation) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    });
    schema[id] = validator;
    return schema;
  }
  useEffect(() => {
    (async function () {
      try {
        const response = await getAllModuleTreeReq(false);
        if (response?.data?.responseObject?.moduleTree?.length) {
          setModuleTree(response.data.responseObject.moduleTree);
          setModuleLevelScriptCount(response?.data?.responseObject?.moduleLevelScriptCount);
        } else {
          setModuleTree([]);
        }
      } catch (err) {
        console.error('GET_MODULE_TREE :', err);
      }
    })();
  }, []);
  const sortBasedOnOrder = (defectTemplateData) => {
    return defectTemplateData?.sort((a, b) => {
      return a?.order - b?.order;
    });
  };

  const applyValidationType = (element) => {
    if (FORM_FIELDS_ARRAY.includes(element.type)) {
      if ([CHECKBOX, ATTACHMENT].includes(element.type)) {
        element.validationType = 'array';
      } else if (element.type === DATE) {
        element.validationType = ['Created On', 'Modified On'].includes(element.label) ? 'string' : DATE;
      } else if (['Modified By', 'Created By'].includes(element.label)) {
        element.validationType = 'object';
      } else {
        element.validationType = 'string';
      }
    }
  };

  const applyFieldValidations = (element, validations) => {
    if ([TEXTFIELD, TEXTBOX].includes(element.type)) {
      validations.push({ type: 'trim', params: [NO_SPACE_AT_START_AND_END_MSG] }, { type: 'strict', params: [true] });

      if (element.type === TEXTFIELD) {
        validations.push({ type: 'matches', params: [checkValidationForAlphanumericSpecialCharacters, ALPHA_ERR_MSG] });
      }

      if (element.label === 'Summary') {
        validations.push(
          { type: 'max', params: [250, `${element.label} ${DEFECT_CONSTANTS.MAX_CHARACTER_VALIDATION}`] },
          { type: 'min', params: [3, `${element.label} ${DEFECT_CONSTANTS.MIN_CHARACTER_VALIDATION}`] }
        );
      }
    }

    if (element.minLength) {
      validations.push({
        type: 'min',
        params: [element.minLength, `${DEFECT_CONSTANTS.MIN_LENGTH_ERR_MSG(element.label, element.minLength)}`],
      });
    }

    if (element.maxLength) {
      validations.push({
        type: 'max',
        params: [element.maxLength, `${DEFECT_CONSTANTS.MAX_LENGTH_ERR_MSG(element.label, element.maxLength)}`],
      });
    }

    if (element.mandatory === YES) {
      validations.push({ type: 'required', params: [MESSAGES.REQUIRED_ERR_MSG(element.label)] });

      if ([CHECKBOX, ATTACHMENT].includes(element.type)) {
        validations.push({ type: 'min', params: [1, MESSAGES.REQUIRED_ERR_MSG(element.label)] });
      }
    }

    if (element.type === LINK) {
      validations.push({ type: 'url', params: [VALID_URL] });
    }
  };

  const createValidationSchema = async () => {
    const defectDetailsTemplate = defectMgmtData?.defect_details_templates[0]?.defectDetails || [];
    const defectTemplateData = sortBasedOnOrder(defectDetailsTemplate).map((element) => {
      let validations = [];
      if (element?.label === 'State' && drawerType === ADD) {
        element.enable = NO;
      }
      applyValidationType(element);

      applyFieldValidations(element, validations);

      element.validations = validations;
      element.id = element.label;

      return element;
    });

    createFormikSchema(defectTemplateData);
  };

  function createFormikSchema(defectTemplateData, allUsers) {
    const { defectDetails } = selectedDefectData;
    defectTemplateData.forEach((item) => {
      if (item?.label === 'State') {
        if (drawerType === ADD) {
          item.options = defectMgmtData?.defect_life_cycle_templates[0]?.states || [];
        }
        if (drawerType === ADD) {
          tempInitialValues[item.label] = item?.options?.[0] || '';
        }
      } else if (item?.label === 'Channel') {
        item.options = [...slackInstances];
        tempInitialValues[item.label] = '';
      } else if (item?.label?.toLowerCase() === 'description') {
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        } else if (steps || envDetails) {
          tempInitialValues[item.label] = `{"blocks":${getDefectDescription(
            steps,
            envDetails,
            isFromSteps
          )},"entityMap":{}}`;
        } else {
          tempInitialValues[item.label] = '';
        }
      } else if (item?.label?.toLowerCase() === 'module') {
        if (selectedModule) {
          const [defaultCheckedNodeName, defaultCheckedNodeId] = selectedModule.split(':');
          setDefaultSelectedModule([{ key: 'key', value: defaultCheckedNodeId || '' }]);
          tempInitialValues[item.label] = selectedModule || '';
        } else {
          const [defaultCheckedNodeName, defaultCheckedNodeId] =
            defectDetails && defectDetails[item.label] ? defectDetails[item.label].split(':') : ['', ''];
          setDefaultSelectedModule([{ key: 'key', value: defaultCheckedNodeId || '' }]);
          tempInitialValues[item.label] = defectDetails?.[item?.label] || '';
        }
      } else if ([CHECKBOX, ATTACHMENT].includes(item?.type)) {
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        } else {
          tempInitialValues[item.label] = [];
        }
      } else if ([TEXTFIELD, TEXTBOX].includes(item?.type)) {
        if (defectDetails && defectDetails[item.label]) {
          tempInitialValues[item.label] = defectDetails[item.label];
        } else {
          tempInitialValues[item.label] = item?.defaultValue || '';
        }
      } else if (item?.type === DATE) {
        if (defectDetails && defectDetails[item.label]?.length > 0) {
          if (['Created On', 'Modified On'].includes(item.label)) {
            tempInitialValues[item.label] = defectDetails[item.label];
          } else {
            const myDate = moment(
              defectDetails[item.label],
              item?.dateFormat && typeof item.dateFormat === 'string' ? item?.dateFormat?.toUpperCase() : 'DD-MM-YYYY'
            ).toDate();
            if (myDate instanceof Date && !isNaN(myDate)) {
              tempInitialValues[item.label] = myDate;
            } else {
              tempInitialValues[item.label] = '';
            }
          }
        } else {
          tempInitialValues[item.label] = '';
        }
      } else {
        tempInitialValues[item.label] = (defectDetails && defectDetails[item.label]) || '';
      }
    });

    setInitialValues(tempInitialValues);
    setDefectDetails(defectTemplateData);
    const yepSchema = defectTemplateData.reduce(createYupSchema, {});
    tempValidateSchema = yup.object().shape(yepSchema);
    setValidateSchema(tempValidateSchema);
  }

  const validateFileAlreadyExists = (newUploadedFiles, files) => {
    for (let i = 0; i < newUploadedFiles.length; i++) {
      for (let j = 0; j < files.length; j++) {
        if (files[j].name === newUploadedFiles[i].name) {
          return true;
        }
      }
    }
    return false;
  };
  const validateFilesSize = (existingFiles, newUploadedFiles) => {
    let fileSizeInBytes = 0;
    for (let i = 0; i < existingFiles?.length; i++) {
      fileSizeInBytes += existingFiles[i]?.sizeInBytes;
    }
    for (let i = 0; i < newUploadedFiles?.length; i++) {
      fileSizeInBytes += newUploadedFiles[i]?.size;
    }
    return fileSizeInBytes <= 31457280;
  };
  const onLoad = (event, data, setFieldValue, value) => {
    let allAtachmentValue = [];
    defectDetails.forEach((el) => {
      if (el.type === 'attachment') {
        allAtachmentValue.push(...formik?.values[el.id]);
      }
    });
    const allFiles = tempUploadFiles;
    const existingFiles = [...value];
    const newUploadedFiles = event?.target?.files;
    const newFilesToBeUploadedCopy = [...newFilesToBeUploaded];
    if (existingFiles?.length + newUploadedFiles?.length > MAX_FILE_UPLOAD_COUNT) {
      MyAlert.info(MAX_NO_OF_FILES_ALLOWED);
      event.target.value = '';
      return;
    }

    const existPlusNew = [...allAtachmentValue, ...existingFiles];
    if (validateFileAlreadyExists(newUploadedFiles, existPlusNew)) {
      MyAlert.info(DUPLICATE_FILE);
      event.target.value = '';
      return;
    }

    if (!validateFilesSize(existingFiles, newUploadedFiles)) {
      MyAlert.info(MAX_FILE_SIZE_ALLOWED);
      event.target.value = '';
      return;
    }
    let file;
    let unsupportedFile = [];
    for (let i = 0; i < newUploadedFiles.length; i++) {
      file = newUploadedFiles[i];
      if (file && validateFileExtension(newUploadedFiles[i], DEFECT_ATTACTMENT_FILE_ALLOWED)) {
        const obj = {
          name: file?.name,
          sizeInBytes: file?.size,
        };
        for (let j = 0; j < existPlusNew.length; j++) {
          if (existPlusNew[j].name === file?.name) {
            MyAlert.info(DUPLICATE_FILE);
            return;
          }
        }
        allFiles.push(obj);
        existingFiles.push(obj);
        newFilesToBeUploadedCopy.push({
          name: file?.name,
          sizeInBytes: file?.size,
          file: file,
        });
      } else {
        unsupportedFile.push(newUploadedFiles[i]);
      }
    }

    if (!isEmptyValue(unsupportedFile)) {
      MyAlert.warning(`${unsupportedFile?.length} unsupported file. Please check the file format.`);
    }
    setFieldValue(data?.label, existingFiles);
    setTempUploadFiles(allFiles);
    setNewFilesToBeUploaded(newFilesToBeUploadedCopy);

    event.target.value = '';
  };

  const deleteFile = (file) => {
    let files = tempUploadFiles;
    files = files.filter((fileVal) => {
      return file?.name !== fileVal?.name;
    });
    setTempUploadFiles(files);
    let newFilesToBeUploadedCopy = [...newFilesToBeUploaded];
    newFilesToBeUploadedCopy = newFilesToBeUploadedCopy.filter((fileVal) => {
      return file?.name !== fileVal?.name;
    });
    setNewFilesToBeUploaded(newFilesToBeUploadedCopy);
    const tempDeletedFiles = [...deletedFiles];
    tempDeletedFiles.push(file);
    setDeletedFiles(tempDeletedFiles);
  };

  const handleSearchableSelectOnChange = (value, label) => {
    formik.setFieldTouched(label, true);
    if (value?.length) {
      const channelIdToUpdate = value[0]?.channelId;
      formik.setFieldValue('channelId', channelIdToUpdate);
      formik.setFieldValue('oauthToken', value[0]?.oauthToken);
      const fieldValueToUpdate = value[0]?.label;
      formik.setFieldValue(label, fieldValueToUpdate);
    } else {
      formik.setFieldValue(label, '');
      formik.setFieldValue('channelId', '');
    }
  };
  const handleChannelIdOnChange = (value, label) => {
    formik.setFieldTouched(label, true);
    if (value?.length) {
      const fieldValueToUpdate = value[0]?.label;
      formik.setFieldValue(label, fieldValueToUpdate);
    } else {
      formik.setFieldValue(label, '');
    }
  };

  function formatInput(input) {
    const parsedInput = JSON.parse(input);

    let output = '';

    parsedInput.blocks.forEach((block, index) => {
      const text = block.text.replace(/:/g, '');

      output += `${text}\n`;
    });

    return output;
  }

  function convertObjectToString(obj) {
    let string = '';
    for (const key in obj) {
      if (
        obj.hasOwnProperty(key) &&
        !['Created By', 'Created On', 'Modified By', 'Modified On', 'ID', 'Linked Defects', 'Comments'].includes(key)
      ) {
        if (key === 'Description') {
          string += `${key}: ${formatInput(obj.Description)}\n`;
        } else if (key === 'Module') {
          const moduleValue = obj[key].split(':')[0];
          string += `${key}: ${moduleValue}\n`;
        } else if (Array.isArray(obj[key])) {
          const arrayString = obj[key]
            .map((item) => {
              return `{${Object.entries(item)
                .map(([k, v]) => `${k}: ${v}`)
                .join(', ')}}`;
            })
            .join(', ');
          string += `${key}: [${arrayString}]\n`;
        } else {
          string += `${key}: ${obj[key]}\n`;
        }
      }
    }
    return string.trim().replace(/\n/g, '\n');
  }

  const handleOnSubmit = async (values) => {
    const formData = new FormData();
    const data = {
      scriptRunDetails: failedStepsData?.scriptRunDetails,
      channelId: formik.values.channelId,
      oauthToken: formik.values.oauthToken,
      summary: values.Summary,
    };
    const formValues = { ...values };
    delete formValues.channelId;
    delete formValues.oauthToken;
    delete formValues.Summary;
    delete formValues.Channel;
    delete formValues['Attach Video'];
    delete formValues['Attach multiple images'];

    const videoFiles = newFilesToBeUploaded.filter((file) => {
      const fileName = file.name.toLowerCase();
      return (
        fileName.endsWith('.mp4') ||
        fileName.endsWith('.mov') ||
        fileName.endsWith('.avi') ||
        fileName.endsWith('.mkv') ||
        fileName.endsWith('.wmv') ||
        fileName.endsWith('.flv') ||
        fileName.endsWith('.webm')
      );
    });
    if (videoFiles.length > 0) {
      data['Attach Video'] = videoFiles;
    }

    const imageFiles = newFilesToBeUploaded.filter((file) => {
      if (file.type) {
        return file.type.startsWith('image/');
      } else {
        const fileName = file.name.toLowerCase();
        return (
          fileName.endsWith('.jpg') ||
          fileName.endsWith('.jpeg') ||
          fileName.endsWith('.png') ||
          fileName.endsWith('.gif')
        );
      }
    });

    if (imageFiles.length > 0) {
      data['Attach multiple images'] = imageFiles;
    }
    if (drawerType === ADD) {
      formValues['Created By'] = {};
      formValues['Modified By'] = {};
    }

    defectMgmtData?.defect_details_templates[0]?.defectDetails?.forEach((data) => {
      if (data) {
        if (
          formValues.hasOwnProperty(data.label) &&
          formValues[data.label] &&
          data.type === 'date' &&
          !['Created On', 'Modified On'].includes(data.label)
        ) {
          formValues[data.label] = moment(formValues[data.label]).format(
            data.dateFormat && typeof data.dateFormat === 'string' ? data.dateFormat.toUpperCase() : 'DD-MM-YYYY'
          );
        }
      }
    });
    data.labels = projectLabelsObject.map((label) => label.name);
    data.text = convertObjectToString(formValues);
    data.state = formValues['State'];
    formData.append('data', JSON.stringify(data));
    newFilesToBeUploaded.forEach((data) => {
      formData.append('file', data?.file);
    });

    try {
      let response;
      setIsLoading(true);
      if (drawerType === ADD) {
        try {
          response = await postSlackIssue(project?.id, formData,'Defects');

          if (response?.data?.responseCode === 507) {
            MyAlert.info(response?.data?.message);
            setIsLoading(false);
            return;
          }
        } catch (error) {
          MyAlert.info(`Failed to upload File(s)`);
        }
      } else {
        const fileIds = [];
        deletedFiles.forEach((fileData) => {
          if (fileData?.id) {
            fileIds.push(fileData.id);
          }
        });
      }
      if (response?.data?.responseCode === 200) {
        if (drawerType === ADD) {
          props.MyAlert.success(`${response?.data?.responseObject?.defectId} ${CREATE_SUCCESS}`);
          closeDrawer(true);
          reloadTable(true);
        }
        tempInitialValues = {};
        tempValidateSchema = '';
        setSelectedDefectData({});
        initDefectData();
        reloadHandler(
          response?.data?.responseMap?.totalDefectsCount,
          response?.data?.responseObject,
          selectedDefectData?.defectDetails?.ID
        );
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(CREATE_DEFECT_ERR, err);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validateSchema,
    onSubmit: async (values) => {
      await handleOnSubmit(values);
    },
  });
  useEffect(() => {
    if (allInstances?.length) {
      setSlackInstances([
        ...allInstances.map((instance) => ({
          ...instance,
          label: `${instance.instanceName} - ${instance.channelName}`,
          name: `${instance.instanceName} - ${instance.channelName}`,
        })),
      ]);
    } else {
      setSlackInstances([]);
    }
  }, [allInstances]);

  const checkFieldError = (fieldName) => (formik.errors?.[fieldName] && formik.touched?.[fieldName] ? true : false);

  const handleSelectChange =
    (name) =>
    ([value]) => {
      if (name === 'channelId') {
        setSelectedInstance(value ? [{ ...value }] : []);
        formik.setFieldValue(name, value ? value.channelId : '', true);
      }
    };

  const renderFooterContent = () => {
    return (
      <div className="flex flex-row justify-end -mt-3">
        <div>
          <CommonButton btnType="secondary" label={DEFECT_CONSTANTS.CANCEL} onClick={closeDrawer} />
        </div>
        <div className="ml-4">
          <CommonButton
            disabled={isLoading}
            label={DEFECT_CONSTANTS.CREATE_ISSUE}
            type="submit"
            form={SLACK_ISSUE_DRAWER.FORM_ID}
          />
        </div>
      </div>
    );
  };
  const labelDataPassToParent = (labelData) => {
    setLabelSelectedOptions(labelData);
  };
  labelSelectedOptions?.forEach((labelValue) => {
    labelResponseObject?.forEach((objectValue) => {
      if (objectValue.name === labelValue) {
        projectLabelsObject.push(objectValue);
      }
    });
  });
  const shouldRenderData = (data) => {
    const forbiddenLabels = ['channel', 'labels'];
    return !forbiddenLabels.includes(data?.label.toLowerCase()) && data;
  };
  const defectMgmtDetailElements = (data, props, error, index) => {
    return (
      <>
        <div className="flex flex-col justify-center">
          <div className="flex">
            {data?.label && (
              <Label
                label={data?.label}
                required={data?.mandatory === 'yes'}
                className={
                  data?.label.toLowerCase() === 'labels'
                    ? 'ml-1 mt-4'
                    : data?.label.toLowerCase() !== 'channel'
                    ? 'truncate mt-4'
                    : ''
                }
              />
            )}
          </div>
          <div className="contentDataStyle mt-1 relative">
            {data?.label.toLowerCase() === 'channel' ? (
              <>
                <div className="">
                  <div variant="standard" className="w-full">
                    <div className="">
                      <SelectBox
                        showLabel={false}
                        name={data.id}
                        options={slackInstances}
                        disabled={data.enable && data.enable === NO ? true : false}
                        error={formik.errors[data.id] && formik.touched[data.id] ? true : false}
                        errMsg={error}
                        placeholder={data.placeholder ? data.placeholder : ''}
                        handleOptionChange={(value) => {
                          handleSearchableSelectOnChange(value, data?.label);
                        }}
                        selectedOption={[]}
                      />
                    </div>
                    {formik.errors[data.id] && formik.touched[data.id] && (
                      <p className={styles['errorText']}>{error}</p>
                    )}
                  </div>
                </div>
              </>
            ) : (
              data?.label.toLowerCase() === 'labels' && (
                <div className="-ml-1.5">
                  <MultiAutocomplete
                    labelSelectedOptions={labelDataPassToParent}
                    labelResponse={setLabelResponseObject}
                    defects={true}
                    MyAlert={MyAlert}
                    resetLabels={resetLabels}
                    history={history}
                    cleanUpFunction={cleanUpFunction}
                  />
                </div>
              )
            )}

            <DefectIssueFields
              data={shouldRenderData(data)}
              formikProps={props}
              error={error}
              formik={formik}
              deleteFile={deleteFile}
              onLoad={onLoad}
              drawerType={drawerType}
              moduleTree={moduleTree}
              selectedModule={selectedModule}
              defaultSelectedModule={defaultSelectedModule}
              moduleLevelScriptCount={moduleLevelScriptCount}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <CommonDrawer
      isDrawerOpen={openDrawer}
      onDrawerClose={closeDrawer}
      titleText={SLACK_ISSUE_DRAWER.FORM_LABEL}
      drawerWidth={SLACK_ISSUE_DRAWER.DRAWER_WIDTH}
      footerContent={renderFooterContent()}
      backButton={props?.backButtonRequired}
      onBackButtonClick={closeDrawer}
    >
      <form
        id={SLACK_ISSUE_DRAWER.FORM_ID}
        onSubmit={(e) => {
          formik.handleSubmit(e);
        }}
      >
        {defectMgmtData?.defect_details_templates[0]?.defectDetails && (
          <div className={styles['drawer-body-height']}>
            <div className={styles['alert-custom-style']}>
              <AlertContatiner></AlertContatiner>
            </div>
            <div className="p-4 justify-center items-center">
              {defectDetails?.length > 0 &&
                defectDetails?.map((data, index) => {
                  let error = formik.errors.hasOwnProperty(data.id) && formik.errors[data.id];
                  return [
                    'ID',
                    'Modified By',
                    'Created By',
                    'Created On',
                    'Modified On',
                    'Comments',
                    'Assign to',
                  ].includes(data?.label) ? (
                    <></>
                  ) : (
                    <Grid item xs={4} sm={8} md={12} key={data?.id}>
                      {defectMgmtDetailElements(data, formik, error, index)}
                    </Grid>
                  );
                })}
            </div>
          </div>
        )}
      </form>
    </CommonDrawer>
  );
};

export default SlackIssueDrawer;
