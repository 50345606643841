export const WEBSERVICE = {
  CONTENT_TYPE_RESPONSE_HEADERS: 'Content-Type',
  APPLICATION_JSON_WITH_CHARSET: 'application/json; charset=utf-8',
  TEXT_JSON_WITHOUT_CHARSET: 'text/json',
  TEXT_JSON_WITH_CHARSET: 'text/json; charset=utf-8',
  APPLICATION_JSON_WITHOUT_CHARSET: 'application/json',
  APPLICATION_XML_WITH_CHARSET: 'application/xml; charset=utf-8',
  APPLICATION_XML_WITHOUT_CHARSET: 'application/xml',
  TEXT_XML_WITHOUT_CHARSET: 'text/xml',
  TEXT_XML_WITH_CHARSET: 'text/xml; charset=utf-8',
  APPLICATION_HTML_WITH_CHARSET: 'application/html; charset=utf-8',
  APPLICATION_HTML_WITHOUT_CHARSET: 'application/html',
  TEXT_HTML_WITHOUT_CHARSET: 'text/html',
  TEXT_HTML_WITH_CHARSET: 'text/html; charset=utf-8',
  VALIDATION_REQUEST_NAME: '*Request name is required',
  VALIDATION_REQUEST_TYPE: 'Request type is required',
  VALIDATION_REQUEST_URL: '*Request url is required',
  VALIDATION_REQUEST_NAME_WHITESPACE: 'Only Whitespace is not allowed',
  VALIDATION_REQUEST_NAME_ALPHANUMERIC: 'Name should be alphanumeric',
  VALIDATION_REQUEST_NAME_MAX_LENGTH :'Maximum 300 Characters Allowed',
  VALIDATION_REQUEST_NAME_MIN_LENGTH: 'Minimum 3 Characters Required',
  PASS_MESSAGE: 'pass message',
  PASS_MESSAGE_WITH_VALUE: '#{value} executed successfully',
  FAIL_MESSAGE_WITH_VALUE: '#{value} failed to execute',
  FAIL_MESSAGE: 'fail message',
  VALIDATION_NAME: 'Name is required',
  VALIDATION_PASS_MESSAGE: 'Pass message is required',
  VALIDATION_FAIL_MESSAGE: 'Fail message is required',
  CAPTURE_GET_STATUS_CODE: 'Get Status Code',
  CAPTURE_GET_RESPONSE_TIME: 'Get Response Time',
  CAPTURE_GET_RESPONSE_BODY: 'Get Response Body',
  CAPTURE_GET_RESPONSE: 'Get Response',
  CAPTURE_GET_HEADERS: 'Get Headers',
  CAPTURE_GET_JSON_PATH: 'Get Value of JSON Path',
  CAPTURE_GET_HEADER_KEY: 'Get Header for a Key',
  CAPTURE_GET_CONTENT_TYPE: 'Get Content Type',
  CAPTURE_GET_COOKIE: 'Get Cookie',
  CAPTURE_GET_COOKIES: 'Get Cookies',
  CAPTURE_GET_DETAILED_COOKIE: 'Get Detailed Cookie',
  CAPTURE_GET_DETAILED_COOKIES: 'Get Detailed Cookies',
  CAPTURE_GET_SESSION_ID: 'Get Session ID',
  CAPTURE_GET_STATUS_LINE: 'Get Status Line',
  CAPTURE_GET_TIME: 'Get Time',
  DEFAULT_VERIFY_STATUS_CODE: 'Verify status code',
  DEFAULT_RESPONSE_TIME: 'Verify response time',
  DEFAULT_VERIFY_JSON_PATH: 'Verify JSON path',
  DEFAULT_VERIFY_XML_PATH: 'Verify XML path',
  DEFAULT_VERIFY_HEADER_KEY: 'Verify header key',
  DEFAULT_VERIFY_RESPONSE_BODY: 'Verify response body',
  DEFAULT_VERIFY_RESPONSE_SCHEMA: 'Verify response schema',
  DEFAULT_VERIFY_RESPONSE_TYPE: 'Verify content type',
  DEFAULT_VERIFY_HEADER: 'Verify header value',
  HAWK_AUTH_ID: 'hawk auth ID is required',
  HAWK_AUTH_KEY: 'hawk auth key is required',
  HAKW_ALGORITHM: 'Algorithm is required',
  HAKW_USER_NAME: 'User Name is required',
  HAKW_NONCE: 'Nonce is required',
  HAKW_TIME_STAMP_KEY: 'Time Stamp Key is required',
  HAKW_EXRA: 'Extra is required',
  HAKW_APPLICATION_ID: 'Application ID is required',
  HAKW_DELEGATED: 'Delegated is required',
  AWS_ACCESS_KEY: 'Access Key is required',
  AWS_SECRET_KEY: 'Secret Key is required',
  AWS_ALGORITHM: 'Algorithm   is required',
  OAUTH1_SIGNATURE_METHOD_ID: 'signatureMethod ID is required',
  OAUTH1_ACCESS_TOKEN: 'Access Token is required',
  OAUTH1_SECRET_TOKEN: 'Token Secret is required',
  OAUTH1_CONSUMER_KEY: 'Consumer Key is required',
  OAUTH1_CONSUMER_SECRET: 'Consumer Secret is required',
  BASIC_USER_NAME: 'Username is required',
  BASIC_PASSWORD: 'Password is required',
  DEFAULT_VERIFY_STATUS_MESSAGE: 'Verify status message',
  SEND_BUTTON_TEXT: 'Send',
  LOADING_SENDING: 'Sending...',
  SAVE_AS_STEP: 'Save as Step',
  UPDATE_AS_STEP: 'Update Step',
  SAVE_API_REQUEST_AS_STEP: 'Save API Request as Step',
  UPDATE_API_REQUEST_AS_STEP: 'Update API Request as Step',
  SAVE_API_REQUEST_NAME: 'Name',
  SAVE_API_REQUEST_IF_FAILED: 'If Failed',
  SAVE_API_REQUEST_PASS_MESSAGE: 'Pass Message',
  SAVE_API_REQUEST_FAIL_MESSAGE: 'Fail Message',
  SAVE_API_REQUEST_CANCEL: 'Cancel',
  SAVE_API_REQUEST_SAVE_CONTINUE: 'Save & Continue',
  SAVE_API_REQUEST_SAVE: 'Save',
  SAVE_API_REQUEST_UPDATE: 'Update',
  CREATE_VARIABLE_CANCEL: 'Cancel',
  CREATE_VARIABLE_CREATE: 'Create',
  CREATE_VARIABLE_UPDATE: 'Update',
  DISABLE_REQUEST_HEADER_INDEXES: [0, 1, 3, 4, 5],
  UPDATE_MODAL_MESSAGE: 'Update the Step',
  SAVE_MODAL_MESSAGE: 'Save it as a Step',
  CLOSE_TEXT: 'Close',
  COPY_CURL_TEXT: 'Copy cURL',
  CURL_COPY_SUCCESS: 'cURL copied successfully.',
  CURL_TEXT: 'cURL',
  CURL_WARNING_INFO: 'Warning: Importing cURL will override the existing data.',
  IMPORT_TEXT: 'Import',
  IMPORT: 'import',
  IMPORT_CURL_TEXT: 'Import cURL',
  OPEN_OPTIONS_TEXT: 'Open options',
  DEFAULT_EXPORT_CURL: 'cURL is generating...',
  DEFAULT_IMPORT_CURL: 'Paste cURL ...',
  EXPORT_CURL_TEXT: 'Export cURL',
  DEFAULT_REQUEST_HEADERS: [
    {
      id: 0,
      idx: 0,
      name: 'Cache-Control',
      value: 'no-cache',
      isKeySearchEnable: false,
      isValueSearchEnable: false,
      canRemove: false,
      isRowshowInputKey: false,
      isRowshowInputValue: false,
      isEnabled: true,
      toolTip:
        " 'Cache-Control': 'no-cache' is added as a precautionary measure to prevent the server from returning stale response when you make repeated requests. You can remove this header from the headers table or enter a new one with a different value.",
    },
    {
      id: 1,
      idx: 1,
      name: 'Host',
      value: 'calculated when request is sent',
      isKeySearchEnable: false,
      isValueSearchEnable: false,
      canRemove: false,
      isRowshowInputKey: false,
      isRowshowInputValue: false,
      isEnabled: true,
      toolTip:
        'The host header is added to identify the domain name for which a request is being sent to the server. This header is implicitly sent by every HTTP client. You can remove the header or enter a new one with a different value. It is most likely that without this header, your request will return an HTTP 400 error.',
    },
    {
      id: 2,
      idx: 2,
      name: 'User-Agent',
      value: 'FireFlink',
      isKeySearchEnable: false,
      isValueSearchEnable: false,
      isRowshowInputKey: false,
      canRemove: false,
      isRowshowInputValue: false,
      isEnabled: true,
      toolTip:
        'The User-Agent header is added to help the server identify FireFlink as the HTTP requesting application or client. It is recommended that this header be sent, but you can remove the header or enter a new one with a different value.',
    },
    {
      id: 3,
      idx: 3,
      name: 'Accept',
      value: '*/*',
      isRowshowInputKey: false,
      isKeySearchEnable: false,
      isValueSearchEnable: false,
      canRemove: false,
      isRowshowInputValue: false,
      isEnabled: true,
      toolTip:
        "The 'Accept: */*' header is added to tell the server that FireFlink can understand and process all forms of response content types. Its is recommended that this header be sent, but you can remove the header or enter a new one with different values.",
    },
    {
      id: 4,
      idx: 4,
      name: 'Accept-Encoding',
      value: 'gzip, deflate, br',
      isKeySearchEnable: false,
      isValueSearchEnable: false,
      canRemove: false,
      isRowshowInputKey: false,
      isRowshowInputValue: false,
      isEnabled: true,
      toolTip:
        'The Accept-Encoding header is added to indicate to the server that FireFlink HTTP client supports a defined list of content-encoding or compression algorithm as response. You can remove the header or enter a new one with a different value. Doing that may not accurately render the response within the app.',
    },
    {
      id: 5,
      idx: 5,
      name: 'Connection',
      value: 'keep-alive',
      isKeySearchEnable: false,
      isValueSearchEnable: false,
      canRemove: false,
      isRowshowInputKey: false,
      isRowshowInputValue: false,
      isEnabled: true,
      toolTip:
        "Connection header is added to indicate the server to keep the underlying network connection open once the current response is received. This allows FireFlink to reuse the same subsequent requests to the same server. You can remove this header or enter a new one with a different value, such as 'Connection: Close' to control this behavior.",
    },
    {
      id: 6,
      idx: 6,
      name: 'Content-Type',
      value: 'application/json',
      isKeySearchEnable: false,
      isValueSearchEnable: false,
      canRemove: false,
      isRowshowInputKey: false,
      isRowshowInputValue: false,
      isEnabled: true,
      toolTip:
        "The Content-Type header is added to help the server to identify the media type of the request body which is selected by the user in 'Content-Type' dropdown in this request. Use the request body tab to control the data or to remove this header.",
    },
  ],
  REQUEST_NAME_ERROR: 'Enter Request Name',
  FOR_LOOP_TITLE: 'FORLOOP',
  FOR_LOOP_INITIAL_VALUE: 'for:initialValue',
  FOR_LOOP_DISPLAY_VALUE: '${FLV_for:initialValue}',
  FOR_LOOP_VALUE: 'initialValue',
  LOCAL_VAR_LABEL: 'Local Variable',
  GLOBAL_VAR_LABEL: 'Global Variable',
  PROJECT_ENV_VAR_LABEL: 'Project Environment Variable',
  DATA_PROVIDER_VAR_LABEL: 'Data Provider Variable',
  STEP_GROUP_VAR_LABEL: 'Step Group Variable',
  STEP_GROUP_PARAM_VAR_LABEL: 'Step Group Parameter',
  FOR_LOOP_VAR_LABEL: 'For Loop Variable',
  RESPONSE_BODY: 'responseBody',
  DEFAULT_ARRAY_LENGTH: 8,
  VALIDATION_SAVE_STEP_NAME: 'Enter Request Name',
  CUSTOM_VALIDATION: 'custom-validation',
  IMPORT_CURL_SUCCESS: 'cURL imported successfully',
  IMPORT_CURL_FAILURE: 'cURL importing failure, unable to parse.',
  IMPORT_CURL_ERROR: 'Error converting curl command to JSON:',
  PREVENT_BROWSER_USERNAME_AUTOFILL: 'new-username',
  PREVENT_BROWSER_PASSWORD_AUTOFILL: 'new-password',
  ONCHANGE: 'onChange',
  ONPASTE: 'onPaste',
  INVALID_IMPORT_CURL: 'Provided cURL format is not supported',
  DEFAULT_FORM_DATA: [{ isSelected: false, isEnabled: false, isModified: false, name: '', value: '', type: 'text' }],
  DEFAULT_URL_FORM_DATA: [{ isEnabled: false, name: '', value: '' }],
  DEFAULT_HEADER_ROW: 7,
  HEADERS: 'headers',
  PAYLOADHASH: 'payloadhash',
  INITIAL_PROJECT_TYPE_LIST: [{ name: '', value: '', id: 0, idx: 0, isEnabled: false }],
  BUTTON_STATUS: {
    success: 'Passed',
    failure: 'Failed',
    skipped: 'Skipped',
  },
  MS: 'ms',
  NA: 'NA',
  OAUTH_1: ['HMAC-SHA1', 'HMAC-SHA256', 'HMAC-SHA512', 'RSA-SHA1', 'RSA-SHA256', 'RSA-SHA512', 'PLAINTEXT'],
  OAUTH_2_LEFT_SIDE_MENU: [
    {
      tab: 'Authorization Code',
      value: 'authorization_code',
    },
    { tab: 'Authorization Code', value: 'authorization_code_pkce' },
    {
      tab: 'Implicit',
      value: 'implicit',
    },
    { tab: 'Client Credentials', value: 'client_credentials' },
    { tab: 'Password Credentials', value: 'password' },
  ],
  REQUEST_BODY_BUTTONS: ['JSON', 'Text', 'HTML'],
  REQUEST_URL_PREFIXES: ['http://', 'https://', '${'],
  ITERATION_PREFIX_REGEX: new RegExp(`\\$\\{(FLV_|DPV_)`),
  REQUEST_URL_ERROR: 'Enter Valid request url',
  DEFAULT_RAW_DATA: { isEnabled: true, isModified: false, name: '', id: '', folderId: '', value: '', type: 'text' },
  ENABLED_QUERY_PARAM_ROW: [{ name: '', value: '', id: 0, idx: 0, isEnabled: true }],
  UNSUPPORTED_METHOD: ['HEAD', 'OPTIONS'],
  MAX_REQUEST_NAME_LENGTH: 22,
  MAX_URL_LENGTH: 40,
  HISTORY_SCRIPT_ID: 'scriptId',
  HISTORY_MODULE_ID: 'moduleId',
  LOCAL_VARIABLE_PREFIX: 'LV_',
  GLOBAL_VARIABLE_PREFIX: 'GV_',
  PROJECT_ENV_VARIABLE_PREFIX: 'PEV_',
  REQUEST_NAME: 'REQUEST NAME',
  REQUEST_TYPE: 'REQUEST TYPE',
  URL: 'URL',
  STATUS: 'STATUS',
  SCRIPT_NAME: 'SCRIPT NAME',
  MODIFIED_BY: 'MODIFIED BY',
  TIME_STAMP: 'TIME STAMP',
  ACTION: 'ACTION',
  JSON_PATH_TITLE: 'JSON Path',
  HEADER_KEY_TITLE: 'Header Key',
  NO_VARIABLE_FOUND: 'No Variables Found',
  TITLE_CONDITION_ARRAY: ['Get Value of JSON Path', 'Get Header for a Key'],
  NAME_CONDITION_ARRAY: ['JSON path', 'Header Key'],
  LHS_CONDITION_ARRAY: [
    '',
    'responseBody',
    "(headers[?name==''].value)[0]",
    "(headers[?name=='Content-Type'].value)[0]",
  ],
  BOTTOM_SCREEN_SIZE: 365,
  OPEN_UP_SIZE: 240,
  OPEN_DOWN_SIZE: 20,
  DROPDOWN_WIDTH: 330,
  INPUT_CHAR_HEIGHT: 7,
  URL_INPUT_END: 885,
  API_KEY_INITIAL_VALUES: {
    key: '',
    value: '',
    action: 'Header',
  },
  API_KEY_INPUT_NAME: 'key',
  API_VALUE_INPUT_NAME: 'value',
  API_KEY_HEADER: 'Header',
  API_KEY_ACTION_LIST: [
    { label: 'Header', value: 'Header' },
    { label: 'Query Params', value: 'QueryParam' },
  ],
  VARIABLE_TYPE_ARRAY: ['PROJECT_ENVIRONMENT', 'GLOBAL', 'LOCAL'],
  DISABLE_STEP_PARAM_API_KEY: 'api-key', 
  DISABLE_STEP_PARAM_API_VALUE: 'api-value', 
  WEBSERVICE_TITLE: 'Web Service',
};
