import cx from 'classnames';
import style from '@pages/licenses/styles/licenses.module.scss';
import LicenseRowCard from '@pages/licenses/components/LicenseRowCard';
import LicensePaymentHistory from './components/paymentHistoryRowCard';
import { LICENSE_PERIOD_STATUS, getLicensePeriodStatus, changeInLicensesEvent } from '@util/licenseUtils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import PlaceholderLicenseRowCard from '@pages/licenses/components/PlaceholderLicenseRowCard';
import ExpiredLicensePopup from '@pages/licenses/components/ExpiredLicensePopup';
import {
  getAllLicensesForUser,
  updateLicense,
  updateUserData,
  getPrivilegeReq,
  refreshUserToken,
  getTransactionStatus,
  getPendingTransactions,
  getFailedTransactions,
  getAlltransactionDetails,
} from '@api/api_services';
import RenewLicensePopup from '@pages/licenses/components/RenewLicensePopup';
import { useAlert } from '@pages/common/alert_service/useAlert';
import {
  getUserStatus,
  getCurrentLicenseId,
  getUserEmailId,
  getRefreshToken,
  logger,
  isEmptyValue,
  isValueIncludes,
  encode,
} from '@util/common_utils';
import SearchBar from '../analytics/common/search-bar';
import LicenseComponentRouter from './LicenseComponentRouter';
import LicenseRowCardStyle from '@pages/licenses/styles/license-row-card.module.scss';
import NoResultsFound from '../common/Table/NoResultFound';
import PaymentPopup from './components/PaymentPopup';
import SwitchLicensePopup from './components/SwitchLicensePopup';
import { ReactComponent as NoHistory } from '@assets/svg-imports/no-payment-history.svg';
import { PAYMENT_HISTORY_CONSTANTS } from '@src/common/ui-constants';
import { ReactComponent as KeywordNotFoundIcon } from '@assets/results_not_found_icon.svg';

const BUTTON_FILTERS = {
  ACTIVE: 'Active',
  EXPIRED: 'Expired',
};

function getLicenseTableRowDisplayFields(licenseData) {
  const { licenseName, licenseType, licenseStatus, licensePlan, licenseUsers, licenseOwner, licenseTypeDisplay } =
    licenseData;
  const ownerDetails = licenseUsers.find(({ emailId }) => emailId === licenseOwner) || {};

  const user = JSON.parse(localStorage.getItem('test-optimize-user') || '{}');
  const userDetails = licenseUsers.find(({ emailId }) => user.userName === emailId) || {};

  return {
    licenseName,
    userPrivilege: userDetails.privilege,
    licenseType: licenseTypeDisplay || licenseType,
    licensePlan: licensePlan,
    licenseStatus,
    managedBy: ownerDetails.name,
  };
}

function Licenses() {
  let allowedProject = JSON.parse(localStorage.getItem('allowedProjects'));
  let freeTrialData = JSON.parse(localStorage.getItem('free-trial-data'));
  let licenseTransactionAllowed = JSON.parse(localStorage.getItem('allowedProjects'))?.features?.licenses;
  const [defaultLicenseId, setDefaultLicenseId] = useState('');
  const [licenses, setLicenses] = useState([]);
  const [filteredLicenses, setFilteredLicenses] = useState([]);
  const [buttonFilter, setButtonFilter] = useState('');
  const [searchFilterValue, setSearchFilterValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [isDefaultLicenseExpired, setIsDefaultLicenseExpired] = useState(false);
  const [renewLicenseData, setRenewLicenseData] = useState(null);
  const { AlertContatiner, MyAlert } = useAlert();
  const userStatus = getUserStatus();
  let [searchTextHighlight, setSearchTextHighlight] = useState('');
  let [openSearch, setOpenSearch] = useState(false);
  let [orderDetails, setOrderDetails] = useState(null);
  const [openPaymentPopup, setOpenPaymentPopup] = useState(false);
  const [openSwitchPopup, setOpenSwitchPopup] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState(null);
  const [previousLicense, setPreviousLicense] = useState(null);
  const [switchToLicense, setSwitchToLicense] = useState(null);
  const [historyData, setHistoryData] = useState();
  const [paymentData, setPaymentData] = useState([]);
  let isFailedTransactions = JSON.parse(localStorage.getItem('failed-transactions'));
  const [failedTransactions, setFailedTransactions] = useState([]);
  let isLicenseSuperAdmin = JSON.parse(localStorage.getItem('licenseListWhereUserIsSuperAdmin'));
  const [licenseListWhereUserIsSuperAdmin, setLicenseListWhereUserIsSuperAdmin] = useState([]);
  const [showLicenseHistory, setShowLicenseHistory] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const history = useHistory();
  const activeTab = history.location.pathname.split('/')[2];
  const keepDefaultLicenseAtTop = (list) => [
    ...list?.filter((license) => license?.id === defaultLicenseId),
    ...list?.filter((license) => license?.id !== defaultLicenseId),
  ];
  let orderId = JSON?.parse(localStorage?.getItem('orderId'));

  function handleSwitchLicensePopupClose(isChanged = false) {
    setOpenSwitchPopup(false);
    if (!isChanged) {
      const license = previousLicense;
      setSelectedLicense(license);
      setPreviousLicense(null);
    }
  }

  const getFailedTransactionsList = async () => {
    try {
      let { data } = await getFailedTransactions();
      if (data?.responseObject.length) {
        localStorage.setItem('failed-transactions', JSON.stringify(data?.responseObject));
      }
      setFailedTransactions(data?.responseObject);
    } catch (error) {
      console.error(error);
    }
  };

  const getLicenseWhereUserIsSuperAdmin = () => {
    let list = filteredLicenses
      ?.map((license) => {
        if (license?.displayData?.userPrivilege === 'Super Admin') {
          return license?.id;
        }
        return null;
      })
      .filter((license) => license !== null && license !== undefined);
    if (list.length) {
      localStorage.setItem('licenseListWhereUserIsSuperAdmin', JSON.stringify(list));
    }
    setLicenseListWhereUserIsSuperAdmin(list);
  };

  let getOrderDetails = async (orderId) => {
    let { data } = await getTransactionStatus(orderId?.orderId);
    setOrderDetails(data?.responseObject);
    localStorage.removeItem('orderId');
    sessionStorage.removeItem('orderId');
    setOpenPaymentPopup(true);
  };
  // TODO :: handle Search filter :: START
  useEffect(() => {
    if (orderId) {
      getOrderDetails(orderId);
    }
    if (freeTrialData) {
      setOpenPaymentPopup(true);
    }
    getFailedTransactionsList();
  }, []);

  useEffect(() => {
    getLicenseWhereUserIsSuperAdmin();
  }, [filteredLicenses]);

  useEffect(() => {
    if (
      (failedTransactions?.length ||
        licenseListWhereUserIsSuperAdmin?.length ||
        isFailedTransactions?.length ||
        isLicenseSuperAdmin?.length) &&
      licenseTransactionAllowed
    ) {
      setShowLicenseHistory(true);
    } else {
      setShowLicenseHistory(false);
    }
    if (licenseListWhereUserIsSuperAdmin.length) {
      setShowButtons(true);
    } else {
      setShowButtons(false);
    }
  }, [failedTransactions, licenseListWhereUserIsSuperAdmin, isFailedTransactions, isLicenseSuperAdmin]);

  useEffect(() => {
    if (history?.location?.pathname === '/licenses/payment-history') {
      getPendingTransactions();
    }
  }, [history?.location.pathname]);

  useEffect(() => {
    if (openSearch && !searchFilterValue) {
      setFilteredLicenses(keepDefaultLicenseAtTop(licenses));
      setSearchTextHighlight('');
    }
  }, [searchFilterValue]);

  useEffect(() => {
    if (openSearch && !searchFilterValue) {
      setPaymentData(historyData);
      setSearchTextHighlight('');
    }
  }, [searchFilterValue]);

  useEffect(() => {
    setPaymentData(historyData);
    if (openSearch && searchFilterValue) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [historyData]);

  let handleSearchClose = () => {
    setOpenSearch(false);
    setSearchFilterValue('');
    setFilteredLicenses(keepDefaultLicenseAtTop(licenses));
    setPaymentData(historyData);
    setSearchTextHighlight('');
  };

  let handleSearchOpen = () => {
    setOpenSearch(true);
  };

  let handleSearchSubmit = (e) => {
    if (e?.key === 'Enter' || (typeof e === 'string' && !isEmptyValue(e))) {
      if (e?.target?.value?.trim() !== '' || (typeof e === 'string' && !isEmptyValue(e))) {
        if (activeTab === 'payment-history') {
          const newHistoryData = historyData?.filter((data) => {
            return (
              isValueIncludes(e, data?.paymentMode) ||
              isValueIncludes(e, data?.status) ||
              isValueIncludes(e, data?.license?.licenseName) ||
              isValueIncludes(e, data?.amount)
            );
          });
          setPaymentData(newHistoryData);
        } else {
          const newData = licenses?.filter(
            ({ displayData }) =>
              isValueIncludes(e, displayData?.licenseName) || isValueIncludes(e, displayData?.managedBy)
          );
          setFilteredLicenses(keepDefaultLicenseAtTop(newData));
        }

        setSearchTextHighlight(e?.target?.value?.trim() || e?.trim());
      }
    } else if (e?.key === 'Escape') {
      handleSearchClose();
    }
  };

  // TODO :: handle Search filter :: END
  const getLicenses = () => {
    getAllLicensesForUser()
      .then((response) => {
        const retrivedLicensesWithFormattedData = response.data.responseObject.map((licenseData) => ({
          ...licenseData,
          displayData: getLicenseTableRowDisplayFields(licenseData),
        }));
        setLicenses(retrivedLicensesWithFormattedData);
      })
      .catch((error) => console.error('Failed to fetch the licenses for landing page', error))
      .finally(() => setLoading(false));
  };

  const getTransactionData = (licenseSuperAdminList) => {
    getAlltransactionDetails(licenseSuperAdminList)
      .then((response) => {
        const paymentHistory = response?.data?.responseObject;
        setHistoryData(paymentHistory);
      })
      .catch((error) => console.error('Failed to fetch the payment history for landing page', error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setOpenSearch(false); // TODO ::NOTE ::  reset search sated on tab change
    setSearchFilterValue('');
    setSearchTextHighlight('');

    if (activeTab === 'payment-history') {
      getTransactionData(isLicenseSuperAdmin || licenseListWhereUserIsSuperAdmin);
    } else if (activeTab === 'fireflink-license') {
      getLicenses();
    }
  }, [activeTab]);

  useEffect(() => {
    if (buttonFilter) {
      const updatedFilteredLicenses = licenses.filter((license) => {
        let isMatchingButtonFilter = true;

        if (buttonFilter) {
          const licensePeriodStatus = getLicensePeriodStatus(license);

          isMatchingButtonFilter =
            (buttonFilter === BUTTON_FILTERS.ACTIVE &&
              [LICENSE_PERIOD_STATUS.ACTIVE, LICENSE_PERIOD_STATUS.WILL_EXPIRE].includes(licensePeriodStatus)) ||
            (buttonFilter === BUTTON_FILTERS.EXPIRED && licensePeriodStatus === LICENSE_PERIOD_STATUS.EXPIRED);
        }

        return isMatchingButtonFilter;
      });

      setFilteredLicenses(keepDefaultLicenseAtTop(updatedFilteredLicenses));
    } else {
      setFilteredLicenses(keepDefaultLicenseAtTop(licenses));
    }
    if (openSearch && searchFilterValue) {
      handleSearchSubmit(searchTextHighlight);
    }
  }, [licenses, buttonFilter]);

  const handleContinue = (licenseId) => {
    localStorage.removeItem('free-trial-data');
    setSwitchToLicense(licenseId);
    getLicenses();
    setOpenSwitchPopup(true);
    setOpenPaymentPopup(false);
  };

  const handleTryAgain = () => {
    if (orderDetails && ['upgrade', 'renew']?.includes(orderDetails?.license?.action?.toLowerCase())) {
      localStorage.setItem('try-again-data', JSON.stringify(orderDetails));
      history.push(
        `/licenses/${encode(
          orderDetails?.license?.id
        )}/FireFlink License/${orderDetails?.license?.action?.toLowerCase()}`
      );
    } else {
      localStorage.setItem('try-again-data', JSON.stringify(orderDetails));
      history.push('/licenses/fireflink-license/buy');
    }
  };

  const handleLicenseNameChange = useCallback(
    (newName, licenseId) => {
      return new Promise((resolve, reject) => {
        const isNameExists = licenses.find(({ licenseName }) => licenseName?.toLowerCase() === newName.toLowerCase());
        if (isNameExists) {
          reject(new Error('License name already exists.'));
          return;
        }

        const payload = {
          licenseName: newName,
        };

        updateLicense(licenseId, payload)
          .then(() => {
            let updatedLicense = {};
            const updatedLicenses = licenses.map((license) => {
              if (license.id === licenseId) {
                updatedLicense = {
                  ...license,
                  licenseName: newName,
                  displayData: { ...license.displayData, licenseName: newName },
                };
                return updatedLicense;
              }
              return license;
            });

            setLicenses(updatedLicenses);

            document.dispatchEvent(changeInLicensesEvent);

            resolve('changed');
          })
          .catch((error) => {
            console.error('Failed to update the license name through API', error);
            reject(error);
          });
      });
    },
    [licenses]
  );

  function changeDefaultLicense(license) {
    const currentDefaultLicenseId = defaultLicenseId;
    const user = JSON.parse(localStorage.getItem('test-optimize-user') || {});
    const payload = {
      defaultLicenseId: license.id,
    };
    setDefaultLicenseId(license.id);
    updateUserData(user.id, payload)
      .then(({ data: response }) => {
        if (response.responseCode === 200) {
          const user = JSON.parse(localStorage.getItem('test-optimize-user') || {});
          localStorage.setItem(
            'test-optimize-user',
            JSON.stringify({
              ...user,
              defaultLicenseId: license.id,
            })
          );
          MyAlert.success(response.message);
        }
      })
      .catch((error) => {
        console.error('Failed to change the default license through API', error);
        setDefaultLicenseId(currentDefaultLicenseId);
      });
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('test-optimize-user') || {});
    setDefaultLicenseId(user.defaultLicenseId || '');
    if (user.licenseStatus === 'EXPIRED') {
      setIsDefaultLicenseExpired(true);
    }
  }, []);

  const currentLicenseId = getCurrentLicenseId();
  const refreshToken = getRefreshToken();

  let payload = {
    currentLicenseId,
    refreshToken,
  };

  useEffect(() => {
    const handleAsync = async () => {
      //TODO :: why we need to call getPrivilegeReq again here
      const allowedProject = await getPrivilegeReq();
      const privilegeResponse = allowedProject?.data?.responseObject;
      if (privilegeResponse === null && currentLicenseId != null) {
        refreshUserToken(payload).then((response1) => {
          if (response1.status === 200 && response1?.data?.access_token) {
            const user = localStorage.getItem('test-optimize-user');
            const userSession = user ? JSON.parse(user) : {};
            userSession['access_token'] = response1.data.access_token;
            userSession['refresh_token'] = response1.data.refresh_token;
            localStorage.setItem('test-optimize-user', JSON.stringify(userSession));
            logger('TokenData Updated!', response1);
            getAllowedstorage();
          }
        });
      }
    };
    handleAsync();
  }, [currentLicenseId]);

  const getAllowedstorage = async () => {
    const allowedProject = await getPrivilegeReq();
    const response = allowedProject?.data?.responseObject;
    localStorage.setItem('allowedProjects', JSON.stringify(response));
  };
  const loggedInUserData = useMemo(() => JSON.parse(localStorage.getItem('test-optimize-user') || '{}'), []);

  function handleRenewLicensePopupClose() {
    if (
      renewLicenseData.id === loggedInUserData.licenseId &&
      getLicensePeriodStatus(renewLicenseData) === LICENSE_PERIOD_STATUS.EXPIRED
    ) {
      setIsDefaultLicenseExpired(true);
    }
    setRenewLicenseData(null);
  }
  return (
    <>
      <LicenseComponentRouter showLicenseHistory={showLicenseHistory} />
      <div id="license-scroll" className={cx('w-full h-full mx-auto', style['license-page'])}>
        <SwitchLicensePopup
          licenses={licenses}
          open={openSwitchPopup}
          onClose={() => handleSwitchLicensePopupClose(false)}
          licenseId={switchToLicense}
        />
        {activeTab === 'payment-history' && (
          <div className="flex items-center justify-between bg-white pt-2 px-2">
            <h1 className="fontPoppinsSemiboldMd  ml-2">{PAYMENT_HISTORY_CONSTANTS.PAYMENT_HISTORY}</h1>
            <div className="">
              <SearchBar
                open={openSearch}
                placeholder="Search"
                searchValue={searchFilterValue || ''}
                handleSearch={(e) => {
                  setSearchFilterValue(e.target.value);
                }}
                onSearchClose={handleSearchClose}
                handleSearchOpen={handleSearchOpen}
                enterKeyHandler={handleSearchSubmit}
                disable={isEmptyValue(historyData)}
              />
            </div>
          </div>
        )}
        {renewLicenseData && (
          <RenewLicensePopup
            open
            onClose={handleRenewLicensePopupClose}
            licenseData={renewLicenseData}
            allowOutsideClick={
              !(
                renewLicenseData.id === loggedInUserData.licenseId &&
                getLicensePeriodStatus(renewLicenseData) === LICENSE_PERIOD_STATUS.EXPIRED
              )
            }
          />
        )}
        <div className={style['default-license-alert']}>
          <AlertContatiner />
        </div>
        {activeTab === 'fireflink-license' && userStatus !== 'DISABLED' && userStatus != null && (
          <div className="flex items-center bg-white pt-2">
            <h1 className="fontPoppinsSemiboldSm w-3/12">License List</h1>
            <div className="ml-10 flex items-center justify-center w-4/12">
              <div className={cx(style['filter-buttons'])}>
                <button
                  type="button"
                  className={cx(style['active'], {
                    [style['selected']]: buttonFilter === BUTTON_FILTERS.ACTIVE,
                  })}
                  onClick={() => setButtonFilter(BUTTON_FILTERS.ACTIVE)}
                >
                  {BUTTON_FILTERS.ACTIVE}
                </button>
                <button
                  type="button"
                  className={cx(style['expired'], {
                    [style['selected']]: buttonFilter === BUTTON_FILTERS.EXPIRED,
                  })}
                  onClick={() => setButtonFilter(BUTTON_FILTERS.EXPIRED)}
                >
                  {BUTTON_FILTERS.EXPIRED}
                </button>
              </div>
              <Tooltip
                title="Clear Filters"
                disableHoverListener={userStatus === 'DISABLED'}
                classes={{
                  popper: cx(LicenseRowCardStyle['ToolTipRenderer'], 'fontPoppinsRegularXs'),
                }}
              >
                <span>
                  <button
                    type="button"
                    className={cx(style['clear-filters-button'])}
                    onClick={() => setButtonFilter('')}
                    disabled={!buttonFilter}
                  >
                    <FilterAltOffOutlinedIcon />
                  </button>
                </span>
              </Tooltip>
            </div>
            <div className="flex items-center justify-end gap-2 w-2/5">
              <SearchBar
                open={openSearch}
                placeholder="Search"
                searchValue={searchFilterValue || ''}
                handleSearch={(e) => {
                  setSearchFilterValue(e.target.value);
                }}
                onSearchClose={handleSearchClose}
                handleSearchOpen={handleSearchOpen}
                enterKeyHandler={handleSearchSubmit}
                disable={isEmptyValue(licenses)}
              />
              {licenseTransactionAllowed && (
                <Link
                  to="/licenses/fireflink-license/buy"
                  className={cx(
                    style.primaryBtn,
                    'fontPoppinsRegularXs px-6 py-2.5 text-white rounded-md ml-2 inline-flex items-center'
                  )}
                >
                  Buy New License
                </Link>
              )}
            </div>
          </div>
        )}

        {!filteredLicenses.length && loading && (
          <>
            <PlaceholderLicenseRowCard />
            <PlaceholderLicenseRowCard />
            <PlaceholderLicenseRowCard />
          </>
        )}
        {((!licenses.length && userStatus === 'DISABLED') ||
          (!licenses.length && userStatus === null && allowedProject === null)) && (
          <div className="w-full h-full flex flex-col items-center justify-center">
            <KeywordNotFoundIcon />
            <p className="fontPoppinsRegularSm">No License Found</p>
            <p className="fontPoppinsRegularSm">
              For any queries please reach out to the{' '}
              <a
                className={style['support-link']}
                href="https://app.support.fireflink.com/servicedesk/customer/portals"
                target="_blank"
                rel="noopener noreferrer"
              >
                Support Team
              </a>
            </p>
          </div>
        )}

        {!licenses.length &&
          !loading &&
          activeTab === 'fireflink-license' &&
          userStatus !== 'DISABLED' &&
          (licenseTransactionAllowed || allowedProject === null) && (
            <>
              <div className="fontPoppinsRegularMd text-sm no-steps">
                <div>
                  <p className="font-bold mb-4">Quick Start</p>
                  <p className="mb-4">
                    <span className="font-bold">Step 1 :</span> Click
                    <Link
                      to="/licenses/fireflink-license/buy"
                      className={cx(
                        style.primaryBtn,
                        'fontPoppinsRegularXs px-6 py-2.5 text-white rounded-md ml-2 inline-flex items-center'
                      )}
                    >
                      Buy New License
                    </Link>{' '}
                    &nbsp; to start adding License
                  </p>
                </div>
              </div>
            </>
          )}
        {activeTab === 'payment-history' && (paymentData?.length === 0 || paymentData == null) && (
          <div className="items-center no-steps">
            <span className="">
              {' '}
              <NoHistory className="no-history-icon" />
            </span>
            <span className={cx(style['no-payment-history'], 'fontPoppinsSemiboldXX2 mt-2')}>
              {PAYMENT_HISTORY_CONSTANTS.NO_PAYMENT_HISTORY}
            </span>
          </div>
        )}
        {activeTab === 'fireflink-license' && (
          <div className={cx(style['licenseRowCardContainer'], style['license-scroll'])}>
            {openPaymentPopup && (
              <PaymentPopup
                licenseDetails={freeTrialData}
                licenses={filteredLicenses}
                open
                onClose={() => {
                  getLicenses();
                  setOpenPaymentPopup(false);
                  localStorage.removeItem('free-trial-data');
                }}
                action="buy"
                orderDetails={orderDetails}
                handleContinue={handleContinue}
                handleTryAgain={handleTryAgain}
              />
            )}
            {filteredLicenses?.map((licenseData, licenseIndex) => (
              <LicenseRowCard
                isSelected={defaultLicenseId === licenseData.id}
                onLicenseSelect={changeDefaultLicense}
                data={licenseData}
                key={`license-row-card-${licenseIndex}`}
                onNameChange={handleLicenseNameChange}
                onRenew={setRenewLicenseData}
                userSearched={searchTextHighlight}
                showButtons={showButtons}
              />
            ))}
            {openSearch && isEmptyValue(filteredLicenses) && <NoResultsFound className="mt-16" />}
            {buttonFilter && !openSearch && isEmptyValue(filteredLicenses) && <NoResultsFound className="mt-16" />}
          </div>
        )}
        {activeTab === 'payment-history' && (
          <div className={cx(style['licenseRowCardContainer'], style['license-scroll'])}>
            {!loading &&
              paymentData?.map((data, dataIndex) => (
                <LicensePaymentHistory
                  isSelected={defaultLicenseId === data.id}
                  onLicenseSelect={changeDefaultLicense}
                  data={data}
                  key={`license-row-card-${dataIndex}`}
                  onNameChange={handleLicenseNameChange}
                  onRenew={setRenewLicenseData}
                  userSearched={searchTextHighlight}
                />
              ))}
            {openSearch && isEmptyValue(paymentData) && searchTextHighlight && <NoResultsFound className="mt-16" />}
            {buttonFilter && !openSearch && isEmptyValue(paymentData) && <NoResultsFound className="mt-16" />}
          </div>
        )}

        {isDefaultLicenseExpired && (
          <ExpiredLicensePopup
            openOne
            handleClose={setIsDefaultLicenseExpired}
            data={filteredLicenses}
            filteredLicenses={filteredLicenses}
            onRenew={setRenewLicenseData}
            licensesData={licenses}
          />
        )}
      </div>
    </>
  );
}

export default Licenses;
